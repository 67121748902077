import React from "react";
import PropTypes from "prop-types";

import { Item } from "../Item/Item";

export function MemberRole({ members, defaultMembers, roleName }) {
  const abbreviateName = ({ name }) => {
    if (!name) return "";

    const arr = name.trim().replace(/^\./, "").split(" ");
    if (arr.length === 1) {
      return arr[0];
    }

    return `${arr[0]} ${arr[arr.length - 1][0]}.`;
  };

  const fullName = ({ name }) => {
    return name;
  };

  if (members?.length === 0 && defaultMembers?.length === 0)
    return <Item data-test="member-row" text={roleName} subtext="Unassigned" />;

  if (members?.length > 0)
    return (
      <Item
        data-test="member-row"
        title={members?.map(fullName)?.join(", ")}
        text={roleName}
        subtext={members?.map(abbreviateName)?.join(", ")}
        color="#000000de"
      />
    );

  return (
    <Item
      data-test="member-row"
      text={roleName}
      subtext={defaultMembers?.map(abbreviateName)?.join(", ")}
      tooltipTitle={
        defaultMembers?.length > 1
          ? "These are the Default Settings Role assignees"
          : "This is the Default Settings Role assignee"
      }
    />
  );
}

MemberRole.propTypes = {
  members: PropTypes.array,
  defaultMembers: PropTypes.array,
  roleName: PropTypes.string,
};

MemberRole.defaultProps = {
  members: [],
  defaultMembers: [],
  roleName: "",
};

export default MemberRole;
