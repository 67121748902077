import React, { useState } from "react";
import PropTypes from "prop-types";

import { Button, Dropdown, Icon, Input, Segment } from "semantic-ui-react";

import { useBooleanState } from "hooks";
import DropdownWithIcon from "components/DropdownWithIcon";

const AddItems = ({
  loading,
  onClickAdd,
  placeholder,
  icons,
  options,
  defaultOption,
}) => {
  const [title, setTitle] = useState("");
  const [selectedOption, setSelectedOption] = useState(defaultOption);
  const [icon, setIcon] = useState(icons.length ? icons[0] : "users");
  const [isOpen, open, close] = useBooleanState();

  const iconOptions = icons.map((ic) => {
    return {
      key: ic,
      value: ic,
      image: (
        <Icon
          color="grey"
          size="large"
          name={ic}
          style={{ transform: "translateX(-9px)" }}
        />
      ),
    };
  });

  return (
    <React.Fragment>
      <div>
        {isOpen ? (
          <Segment style={{ marginBottom: "1em" }}>
            <div
              style={{
                display: "flex",
                marginBottom: "0.5em",
                alignItems: "center",
              }}
            >
              <Dropdown
                defaultUpward
                disabled={loading || icons.length === 1}
                selection
                icon={
                  <Icon
                    name={icon}
                    size="large"
                    color="grey"
                    style={{ margin: "0px" }}
                  />
                }
                options={iconOptions}
                onChange={(_, data) => {
                  setIcon(data.value);
                }}
                style={{
                  marginRight: "1em",
                  padding: "0.5em",
                  minWidth: "40px",
                  transform: "translateY(-2px)",
                }}
              />
              <Input
                disabled={loading}
                autoComplete="off"
                value={title}
                placeholder={placeholder}
                style={{ minWidth: "308px", marginRight: "1em" }}
                onChange={(_, { value }) => {
                  setTitle(value);
                }}
              />
              <div data-test="add-service-role-dropdown">
                <DropdownWithIcon
                  selected={selectedOption}
                  disabled={loading}
                  options={options}
                  onChange={setSelectedOption}
                />
              </div>
            </div>
            <div>
              <Button
                positive
                content="Add"
                onClick={() => {
                  onClickAdd(title, selectedOption).finally(() => {
                    setTitle("");
                    setSelectedOption(defaultOption);
                    close();
                  });
                }}
                loading={loading}
                disabled={loading || !title}
              />
              <Icon
                link
                color="grey"
                name="delete"
                onClick={() => {
                  setTitle("");
                  close();
                }}
                size="large"
                disabled={loading}
              />
            </div>
          </Segment>
        ) : (
          <Button basic loading={loading} disabled={loading} onClick={open}>
            Add
          </Button>
        )}
      </div>
    </React.Fragment>
  );
};

AddItems.propTypes = {
  onClickAdd: PropTypes.func.isRequired,
  icons: PropTypes.array,
  placeholder: PropTypes.string,
  loading: PropTypes.bool,
  options: PropTypes.array,
  defaultOption: PropTypes.object,
};

AddItems.defaultProps = {
  placeholder: "Item Name",
  loading: false,
  options: [],
  defaultOption: null,
  icons: [
    "calendar check",
    "file alternate",
    "dollar sign",
    "heartbeat",
    "database",
    "graduation",
    "exclamation circle",
    "medkit",
    "credit card",
    "list alternate",
    "map signs",
    "home",
    "shield",
    "write",
    "suitcase",
    "lock open",
    "lock",
    "sync",
    "chart bar",
    "edit outline",
    "handshake outline",
    "trophy",
  ],
};

export default AddItems;
