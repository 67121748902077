import React from "react";
import PropTypes from "prop-types";

import { Icon } from "semantic-ui-react";

export function PlaceholderIcon({
  icon,
  showIcon,
  searchInput,
  searchPlaceholder,
}) {
  if (icon === "") {
    return null;
  }
  return (
    <React.Fragment>
      {showIcon && (
        <div
          style={{
            position: "relative",
            zIndex: 111,
            height: 0,
          }}
        >
          <Icon
            name={icon}
            style={{
              position: "absolute",
              zIndex: 111,
              top: "0.5em",
              left: "0.6em",
              color: "#00000033",
            }}
          />
        </div>
      )}
      {!showIcon && searchInput === "" && (
        <div
          style={{
            position: "relative",
            zIndex: 109,
            height: 0,
          }}
        >
          <div
            style={{
              width: "250px",
              position: "absolute",
              top: "0.5em",
              left: "0.6em",
              color: "#00000055",
            }}
            className="default text"
          >
            {searchPlaceholder}
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

PlaceholderIcon.defaultProps = {
  searchPlaceholder: "",
  searchInput: "",
  icon: "",
  showIcon: false,
};

PlaceholderIcon.propTypes = {
  icon: PropTypes.string,
  showIcon: PropTypes.bool,
  searchInput: PropTypes.string,
  searchPlaceholder: PropTypes.string,
};

export default PlaceholderIcon;
