import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import makeAdvisorSelector from "data/advisor/selector";
import { SetRemindersVisibilityFilter } from "data/filters/actions";

import Options from "components/OptionList";

export function AssigneeFilter({
  advisors,
  defaultSelection,
  currentSelection,
  onSelected,
}) {
  const options = [
    { key: "SHOW_ALL", value: "SHOW_ALL", text: "Any User" },
    { key: "CURRENT_USER", value: "CURRENT_USER", text: "Current User" },
  ].concat(
    advisors.map((advisor) => {
      return {
        key: advisor.id,
        value: advisor.id,
        text: advisor.displayName,
      };
    })
  );

  const toType = (value) => {
    return value === "SHOW_ALL" || value === "CURRENT_USER" ? value : "BY_USER";
  };

  return (
    <Options.Item
      title="Assignee"
      description="Filter by advisor"
      options={options}
      currentValue={(currentSelection || defaultSelection).value}
      onClear={() => {
        return onSelected(defaultSelection);
      }}
      onSelection={(_, { value }) => {
        return onSelected({ type: toType(value), value: value });
      }}
    />
  );
}

AssigneeFilter.propTypes = {
  onSelected: PropTypes.func.isRequired,
  advisors: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ),
  currentSelection: PropTypes.shape({
    value: PropTypes.string.isRequired,
  }),
  defaultSelection: PropTypes.shape({
    value: PropTypes.string.isRequired,
  }),
};

AssigneeFilter.defaultProps = {
  advisors: [],
  defaultSelection: { type: "SHOW_ALL", value: "SHOW_ALL" },
  currentSelection: { type: "SHOW_ALL", value: "SHOW_ALL" },
};

const makeMapStateToProps = () => {
  const advisorSelector = makeAdvisorSelector();
  return (state, props) => {
    return {
      advisors: advisorSelector(state, props),
      currentSelection: state.hubly.data.filters.remindersVisibilityFilter,
      defaultSelection: { type: "CURRENT_USER", value: "CURRENT_USER" },
    };
  };
};

const mapDispatchToProps = (dispatch, { onSelected }) => {
  return {
    onSelected: (filter) => {
      dispatch(SetRemindersVisibilityFilter(filter));
      if (onSelected) onSelected(filter);
    },
  };
};

export default connect(makeMapStateToProps, mapDispatchToProps)(AssigneeFilter);
