import React from "react";
import PropTypes from "prop-types";

import { Header, Input } from "semantic-ui-react";

import ColorPicker from "components/ColorPicker";

export function ProcessInput({
  process: { name, description, color },
  onInputChange,
}) {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ display: "flex" }}>
        <div style={{ flexGrow: 1 }}>
          <Header as="h5">Name</Header>
          <Input
            fluid
            autoComplete="off"
            name="name"
            value={name}
            placeholder="Process Name"
            onChange={(_, data) => {
              return onInputChange(data);
            }}
          />
        </div>
        <div style={{ marginLeft: "1em", marginRight: "1em" }}>
          <Header as="h5">Color</Header>
          <ColorPicker
            color={color}
            setColor={(c) => {
              return onInputChange({ name: "color", value: c });
            }}
          />
        </div>
      </div>
      <div style={{ marginTop: "1em" }}>
        <Header as="h5">Description</Header>
        <Input
          fluid
          autoComplete="off"
          name="description"
          value={description}
          placeholder="Process description"
          onChange={(_, data) => {
            return onInputChange(data);
          }}
        />
      </div>
    </div>
  );
}

ProcessInput.propTypes = {
  process: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    color: PropTypes.string,
  }).isRequired,
  onInputChange: PropTypes.func,
};

ProcessInput.defaultProps = {
  onInputChange: () => {},
};

export default ProcessInput;
