export function selectedToggles(togglesObj = {}) {
  return Object.entries(togglesObj).filter(([_, checked]) => {
    return !!checked;
  });
}

export function disableRedundantToggles(
  notificationMediums = {},
  notificationSubjects = {}
) {
  const selectedMediums = selectedToggles(notificationMediums);
  const selectedSubjects = selectedToggles(notificationSubjects);
  const newSettings = {};

  if (selectedMediums.length === 0) {
    selectedSubjects.forEach(([key, _]) => {
      newSettings[key] = false;
    });
  }

  if (selectedSubjects.length === 0) {
    selectedMediums.forEach(([key, _]) => {
      newSettings[key] = false;
    });
  }

  return newSettings;
}

export function convertToPreferenceObject(arr = [], preferences = {}) {
  return arr.reduce((acc, { key }) => {
    return { ...acc, [key]: preferences[key] || false };
  }, {});
}

export default disableRedundantToggles;
