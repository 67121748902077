import React from "react";

import "./style.css";

const privacyModeOn = (className = "") => {
  return `blur-text ${className}`.trimEnd();
};
const privacyModeOff = (className = "") => {
  return `${className}`;
};

export function privacyMode(isOn = false) {
  return isOn ? { piiMask: privacyModeOn } : { piiMask: privacyModeOff };
}

export const PrivacyModeContext = React.createContext(privacyMode(false));

export default PrivacyModeContext;
