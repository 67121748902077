import React from "react";
import DatePicker from "react-datepicker/es";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import fileDownload from "js-file-download";
import Moment from "moment";
import {
  Button,
  Checkbox,
  Grid,
  Header,
  Icon,
  Input,
  Label,
  Modal,
  Popup,
} from "semantic-ui-react";

import { GetClientAudit } from "data/libs/clients";

import { SetAlert } from "components/Alerts/actions";

import ToggleAuditOpen from "./actions";

// Field or Timeframe
const ALL = "All";

// Timeframes
const LAST_30_DAYS = "30-Days";
const CUSTOM = "Custom";

class ExportAuditTrail extends React.Component {
  static propTypes = {
    auditOpen: PropTypes.bool.isRequired,
    client: PropTypes.shape({
      id: PropTypes.string.isRequired,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }).isRequired,
    setAlert: PropTypes.func.isRequired,
    toggleAuditOpen: PropTypes.func.isRequired,
  };

  state = {
    downloadInProgress: false,
    selectedTimeframe: ALL,
    startDate: null,
    endDate: null,
    includeIncompleteTasks: true,
  };

  handleChangeStart = (date) => {
    this.setState({ startDate: date });
  };

  handleChangeEnd = (date) => {
    this.setState({ endDate: date });
  };

  setTimeframe = (selectedTimeframe) => {
    // Set default dates when selecting custom, clear out otherwise
    if (selectedTimeframe === CUSTOM) {
      const previousDate = new Date();
      this.setState({
        startDate: new Date(previousDate.setDate(previousDate.getDate() - 90)),
        endDate: new Date(),
        selectedTimeframe: selectedTimeframe,
      });
    } else {
      this.setState({
        startDate: null,
        endDate: null,
        selectedTimeframe: selectedTimeframe,
      });
    }
  };

  getFilename = (fileType) => {
    const { selectedTimeframe } = this.state;
    const { client } = this.props;
    const today = Moment().format("MMM-DD-YYYY");
    return `${
      client.lastName || client.firstName
    }_${selectedTimeframe}_${today}.${fileType}`;
  };

  export = (fileType) => {
    const { endDate, selectedTimeframe, startDate, includeIncompleteTasks } =
      this.state;
    const { client, setAlert, toggleAuditOpen } = this.props;
    const query = {};
    if (selectedTimeframe === LAST_30_DAYS) {
      const previousDate = new Date();
      query.startDate = new Date(
        previousDate.setDate(previousDate.getDate() - 30)
      ).toISOString();
      query.endDate = new Date().toISOString();
    } else if (selectedTimeframe === CUSTOM) {
      query.startDate = startDate.toISOString();
      query.endDate = endDate.toISOString();
    }

    query.fields = ALL;
    query.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    query.fileType = fileType;
    query.timeframe = selectedTimeframe;
    query.includeIncompleteTasks = includeIncompleteTasks;
    this.setState({ downloadInProgress: true });
    GetClientAudit(client.id, query)
      .then((response) => {
        fileDownload(response, this.getFilename(fileType));
        this.setState({ downloadInProgress: false });
        toggleAuditOpen();
      })
      .catch((error) => {
        this.setState({ downloadInProgress: false });
        console.error(error);
        setAlert({ type: "error", text: "Failed to export client report." });
      });
  };

  render() {
    const {
      downloadInProgress,
      endDate,
      selectedTimeframe,
      startDate,
      includeIncompleteTasks,
    } = this.state;
    const { auditOpen, client, toggleAuditOpen } = this.props;
    return (
      <Modal
        size="tiny"
        on="click"
        open={auditOpen}
        onClose={toggleAuditOpen}
        onOpen={toggleAuditOpen}
        style={{ minWidth: "24em" }}
      >
        <Modal.Header style={{ marginBottom: "0em" }}>
          <Icon
            link
            onClick={toggleAuditOpen}
            name="close"
            color="grey"
            style={{ float: "right" }}
          />
          Export Client Report
        </Modal.Header>
        <Modal.Content>
          <Header as="h5" style={{ marginBottom: "0.5em" }}>
            Client
          </Header>
          <Header as="h3" style={{ marginTop: "0" }}>
            {`${client.firstName} ${client.lastName ? client.lastName : ""}`}
          </Header>
          <Header as="h5">Dates</Header>
          <Button.Group basic fluid>
            <Button
              active={selectedTimeframe === ALL}
              onClick={() => {
                this.setTimeframe(ALL);
              }}
            >
              All
            </Button>
            <Button
              active={selectedTimeframe === LAST_30_DAYS}
              onClick={() => {
                this.setTimeframe(LAST_30_DAYS);
              }}
            >
              Last 30 Days
            </Button>
            <Button
              active={selectedTimeframe === CUSTOM}
              onClick={() => {
                this.setTimeframe(CUSTOM);
              }}
            >
              Custom
            </Button>
          </Button.Group>
          <br />
          {selectedTimeframe === CUSTOM && (
            <Grid style={{ marginTop: "0.5em" }}>
              <Grid.Column width={7}>
                <DatePicker
                  selected={startDate}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  customInput={
                    <Input labelPosition="right">
                      <input style={{ maxWidth: "8em", paddingRight: "0" }} />
                      <Label>
                        <Icon fitted name="calendar alternate outline" />
                      </Label>
                    </Input>
                  }
                  onChange={this.handleChangeStart}
                  disabled={selectedTimeframe !== CUSTOM}
                />
              </Grid.Column>
              <Grid.Column textAlign="center" verticalAlign="middle" width={2}>
                to
              </Grid.Column>
              <Grid.Column textAlign="right" width={7}>
                <DatePicker
                  selected={endDate}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  customInput={
                    <Input labelPosition="right">
                      <input style={{ maxWidth: "8em", paddingRight: "0" }} />
                      <Label>
                        <Icon fitted name="calendar alternate outline" />
                      </Label>
                    </Input>
                  }
                  onChange={this.handleChangeEnd}
                  minDate={startDate}
                  disabled={selectedTimeframe !== CUSTOM}
                />
              </Grid.Column>
            </Grid>
          )}
          <Header as="h5">Options</Header>
          <Checkbox
            checked={includeIncompleteTasks}
            onChange={() => {
              this.setState({
                includeIncompleteTasks: !includeIncompleteTasks,
              });
            }}
            toggle
            label="Include incomplete tasks in report"
            // style={{ marginTop: "1em" }}
          />
        </Modal.Content>
        <Modal.Actions style={{ overflow: "auto" }}>
          <Popup
            flowing
            on="click"
            popperModifiers={{
              preventOverflow: { boundariesElement: "window", enabled: false },
            }}
            position="bottom center"
            style={{ padding: "0" }}
            trigger={
              <Button primary floated="right" loading={downloadInProgress}>
                Export
              </Button>
            }
          >
            <Button.Group basic vertical>
              <Button
                content="Excel"
                onClick={() => {
                  this.export("xlsx");
                }}
                style={{
                  textAlign: "left",
                  paddingLeft: "0.5em",
                  width: "12em",
                }}
              />
              <Button
                content="Comma Separated"
                onClick={() => {
                  this.export("csv");
                }}
                style={{
                  textAlign: "left",
                  paddingLeft: "0.5em",
                  width: "12em",
                }}
              />
            </Button.Group>
          </Popup>
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    auditOpen: state.hubly.scenes.hubly.hubModals.audit.auditOpen,
    client: state.hubly.data.hub.clients.activeClients[ownProps.clientId],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAlert: (alert) => {
      dispatch(SetAlert(alert));
    },
    toggleAuditOpen: () => {
      dispatch(ToggleAuditOpen());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExportAuditTrail);
