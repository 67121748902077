/* eslint-disable import/extensions, react/jsx-filename-extension */
// Internet Explorer Polyfills
// Actual application code
import React from "react";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";

import "@babel/polyfill";
import "isomorphic-fetch";
import "semantic-ui-less/semantic.less";

import Amplify from "aws-amplify";
import { datadogRum } from "@datadog/browser-rum";
import ReactDOM from "@hot-loader/react-dom";

import "./index.css";

import history from "data/history";

import config from "./config";
import QueryClientProvider from "./scenes/Hubly/HublyQueryClientProvider";
import App from "./App";
import awsExports from "./aws-exports";
import * as serviceWorker from "./serviceWorker";
import Store from "./store";

Amplify.configure(awsExports);

datadogRum.init(config.datadog);

ReactDOM.render(
  <Provider store={Store}>
    <QueryClientProvider>
      <Router history={history}>
        <App />
      </Router>
    </QueryClientProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
