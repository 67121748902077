import React, { useMemo } from "react";
import PropTypes from "prop-types";

import { Paper, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles({
  dropdownClass: {
    "& .MuiOutlinedInput-root": {
      "&": {
        backgroundColor: "white",
        position: "relative",
        margin: "18px 0",
        paddingRight: "25px !important",
      },
      "& fieldset": {
        fontSize: "12.25px",
        border: "1px solid #dddedf",
        padding: 0,
      },
      "&:hover fieldset": {
        border: "1px solid #dddedf",
      },
      "&.Mui-focused fieldset": {
        border: "1px solid #95c8da",
      },
      "& MuiInputBase-input": {
        fontSize: ".8rem",
      },
    },
  },
  paper: {
    border: "1px solid #95c8da",
    fontSize: "1rem",
    fontFamily:
      "'Helvetica Neue', 'Helvetica Neue', Arial, Helvetica, sans-serif",
  },
});

function PermissionDropdown({
  value,
  roleData,
  disabled,
  onChangeCb,
  ...restProps
}) {
  const classes = useStyles();
  const roleOptions = useMemo(() => {
    return roleData.map(({ id, title }) => {
      return {
        key: id,
        value: id,
        text: title,
      };
    });
  }, [roleData]);

  const dropdownValue = useMemo(() => {
    if (!value || !roleOptions.length) {
      return {
        key: "",
        value: "",
        text: "",
      };
    }
    return roleOptions.find((o) => {
      return o.value === value;
    });
  }, [value, roleOptions]);

  return (
    <Autocomplete
      {...restProps}
      className={classes.dropdownClass}
      disableClearable
      blurOnSelect
      size="small"
      disabled={disabled}
      options={roleOptions}
      getOptionSelected={(option) => {
        return option.value === value;
      }}
      getOptionLabel={(option) => {
        return option.text;
      }}
      value={dropdownValue}
      onChange={(event, newValue) => {
        onChangeCb(newValue.value);
      }}
      PaperComponent={(props) => {
        return <Paper {...props} className={classes.paper} elevation={0} />;
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              style: { fontSize: ".8rem" },
            }}
            fullWidth
            variant="outlined"
          />
        );
      }}
    />
  );
}

PermissionDropdown.propTypes = {
  value: PropTypes.string,
  roleData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  disabled: PropTypes.bool,
  onChangeCb: PropTypes.func,
};

PermissionDropdown.defaultProps = {
  value: null,
  roleData: [],
  disabled: false,
  onChangeCb: () => {},
};

export default PermissionDropdown;
