import React from "react";
import PropTypes from "prop-types";

import { Dropdown, Icon } from "semantic-ui-react";

import { useBooleanState } from "hooks";

export function DropdownWithIcon({ disabled, selected, options, onChange }) {
  const [isEditing, edit, close] = useBooleanState();

  const toOption = ({ key, text, node }) => {
    return {
      key: key,
      value: key,
      text: text,
      content: node,
    };
  };

  if (options.length === 0) {
    return null;
  }

  if (isEditing && !disabled) {
    return (
      <Dropdown
        data-test="dropdown-with-icon"
        defaultValue={selected?.key}
        defaultOpen
        selection
        selectOnBlur={false}
        fluid
        style={{ minWidth: "200px", maxWidth: "280px" }}
        options={options.map(toOption)}
        onChange={(_, { value }) => {
          onChange(
            options.find(({ key }) => {
              return key === value;
            })
          );
        }}
        onClose={close}
      />
    );
  }

  return (
    <div style={{ color: "#767676", cursor: "pointer" }} onClick={edit}>
      <React.Fragment>{selected?.node}</React.Fragment>
      <Icon name="caret down" style={{ marginLeft: "0.3em" }} />
    </div>
  );
}

DropdownWithIcon.propTypes = {
  selected: PropTypes.shape({
    key: PropTypes.string,
    text: PropTypes.string,
    node: PropTypes.node,
  }),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      text: PropTypes.string,
      node: PropTypes.node,
    })
  ),
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

DropdownWithIcon.defaultProps = {
  selected: null,
  options: [],
  disabled: false,
};

export default DropdownWithIcon;
