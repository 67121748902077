import React, { useState } from "react";
import PropTypes from "prop-types";

import fileDownload from "js-file-download";
import Moment from "moment";
import { Button, Popup } from "semantic-ui-react";

import { GetStreamReport } from "data/libs/streams";
import { GetTagReport } from "data/libs/tags";

export function ExportStreamOrTagButton({ disabled, style, tag, stream }) {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const getFilename = (fileType) => {
    const name = tag ? tag.name : stream.name;
    const today = Moment().format("MMM-DD-YYYY");
    return `${name}_${today}.${fileType}`;
  };

  const exportFile = (type) => {
    setLoading(true);
    const getReport = tag ? GetTagReport : GetStreamReport;
    const id = tag ? tag.id : stream.id;
    getReport(id, {
      fileType: type,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    })
      .then((response) => {
        fileDownload(response, getFilename(type));
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Popup
      flowing
      on="click"
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      position="bottom center"
      style={{ padding: "0" }}
      trigger={
        <Button
          loading={loading}
          disabled={disabled || loading}
          style={style}
          icon="file excel outline"
          content="Export Client List"
        />
      }
    >
      <Button.Group basic vertical>
        <Button
          content="Excel"
          onClick={() => {
            exportFile("xlsx");
            setOpen(false);
          }}
          style={{ textAlign: "left", paddingLeft: "0.5em", width: "12em" }}
        />
        <Button
          content="Comma Separated"
          onClick={() => {
            exportFile("csv");
            setOpen(false);
          }}
          style={{ textAlign: "left", paddingLeft: "0.5em", width: "12em" }}
        />
      </Button.Group>
    </Popup>
  );
}

ExportStreamOrTagButton.defaultProps = {
  style: {},
  tag: null,
  stream: null,
  disabled: false,
};

ExportStreamOrTagButton.propTypes = {
  style: PropTypes.object,
  tag: PropTypes.object,
  disabled: PropTypes.bool,
  stream: PropTypes.object,
};

export default ExportStreamOrTagButton;
