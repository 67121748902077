import { useContext, useEffect, useRef, useState } from "react";

import {
  HubContext,
  ToasterContext,
} from "scenes/Hubly/components/Workspace/Provider";

import { useServiceRoleSyncableAction } from ".";

export function useServiceRoleAction(onDone = () => {}) {
  const mounted = useRef(false);
  const { fireSuccess, fireError } = useContext(ToasterContext);
  const {
    addServiceRole,
    renameServiceRole,
    reorderServiceRoles,
    deleteServiceRoles,
  } = useContext(HubContext);
  const { updateServiceRoleSyncable } = useServiceRoleSyncableAction();
  const [isRunningAction, setRunning] = useState(null);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  const makeCall = (id) => {
    return async (action) => {
      if (mounted.current) {
        setRunning(id);
        const resp = await action();
        if (mounted.current) {
          setRunning(null);
          return resp;
        }
      }
      return null;
    };
  };

  const showError = (defaultMsg = "Failed to updated.") => {
    return (e) => {
      fireError(
        Array.isArray(e) && e[0]?.message ? e[0]?.message : defaultMsg,
        e
      );
    };
  };

  const actionable = (serviceRole) => {
    const call = makeCall(serviceRole.id);
    return {
      ...serviceRole,
      isProcessing: isRunningAction === serviceRole.id,
      rename: (newName) => {
        return call(() => {
          return renameServiceRole({
            id: serviceRole.id,
            name: newName,
          })
            .then(() => {
              fireSuccess(`Successfully renamed service role to ${newName}`);
            })
            .catch(
              showError(
                `Failed to rename service role to ${newName}, please try again or contact support for assistance.`
              )
            )
            .finally(onDone);
        });
      },
      delete: () => {
        return call(() => {
          return deleteServiceRoles({ serviceRoleIds: [serviceRole.id] })
            .then(() => {
              fireSuccess(
                `Successfully deleted service role: ${serviceRole.name}`
              );
            })
            .catch(
              showError(
                `Failed to delete service role: ${serviceRole.name}, please try again or contact support for assistance.`
              )
            )
            .finally(onDone);
        });
      },
      updateSyncable: (name) => {
        return call(() => {
          return updateServiceRoleSyncable({
            serviceRoleId: serviceRole.id,
            syncableName: name,
          })
            .then(({ errors = null } = {}) => {
              if (errors) {
                showError("Failed to update synced role to CRM.")(errors);
              } else {
                fireSuccess("Successfully synced Role to CRM.");
              }
            })
            .catch(showError("Failed to update synced role to CRM."))
            .finally(onDone);
        });
      },
    };
  };

  return {
    add: (name, syncableName) => {
      const call = makeCall("add-service-role");
      return call(() => {
        return addServiceRole({ name, syncableName })
          .then(({ id }) => {
            fireSuccess(`Successfully added service role: ${name}`);
            return id;
          })
          .catch(
            showError(
              `Failed to add service role: ${name}, please try again or contact support for assistance.`
            )
          )
          .finally(onDone);
      });
    },
    isAdding: isRunningAction === "add-service-role",
    reorder: (params) => {
      const call = makeCall("reorder-service-role");
      return call(() => {
        return reorderServiceRoles(params)
          .catch(
            showError(
              "Failed to reorder service role, please try again or contact support for assistance."
            )
          )
          .finally(onDone);
      });
    },
    isReordering: isRunningAction === "reorder-service-role",
    actionable: actionable,
  };
}

export default useServiceRoleAction;
