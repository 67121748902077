export const shorterName = (name = "", maxLen = 50) => {
  return name.length > maxLen ? `${name.substr(0, maxLen)}...` : name;
};

export const displayName = (name = "", clientWorkflow) => {
  if (clientWorkflow?.archived) {
    return `${name} (archived)`;
  } else if (clientWorkflow?.completed) {
    return `${name} (completed)`;
  }

  return `${name} `;
};

export const generateDateContent = (
  createdAt,
  completed = false,
  completedAt = undefined
) => {
  if (!createdAt) return "";

  const dateOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  const start = new Date(createdAt).toLocaleDateString("en-US", dateOptions);
  if (completedAt && completed) {
    const end = new Date(completedAt).toLocaleDateString("en-US", dateOptions);
    return `${start} - ${end}`;
  } else {
    return `Started on ${start}`;
  }
};

export const generateCompledFractionText = (tasks = []) => {
  const completedTaskCount = tasks?.reduce((accumulator, currentValue) => {
    return currentValue.completed ? accumulator + 1 : accumulator;
  }, 0);

  return `(${completedTaskCount}/${tasks?.length})`;
};

export const generateCWFContent = (clientWorkflow) => {
  if (!clientWorkflow) return "";

  const { name, tasks, createdAt, completed, completedAt, startedByText } =
    clientWorkflow;

  const completedFraction = generateCompledFractionText(tasks);
  const archived = `${clientWorkflow.archived ? " (Archived)" : ""}`;
  const dateContent = generateDateContent(createdAt, completed, completedAt);
  let startedByContent = startedByText ? ` By ${startedByText}` : "";
  if (startedByText && completed) {
    startedByContent = ` (Started by ${startedByText})`;
  }

  return `${shorterName(
    name,
    40
  )} ${completedFraction} ${dateContent}${startedByContent}${archived}`;
};

export default generateCWFContent;
