import React from "react";
import PropTypes from "prop-types";

import { Grid, Placeholder } from "semantic-ui-react";

import { FeatureFlag } from "components/FeatureFlag";

import ToggleFilter from "./components/ToggleFilter";

export function MoreFilters({
  loading,
  assigneeDisplayName,
  filterEmptyWorkflows,
  filterAssigneeIncludeServiceRole,
  filterServiceRoleUnassignedOnly,
  filterNextTaskForAssignee,
  assigneeFilterType,
  onUpdateFilter,
}) {
  if (loading) {
    return (
      <div style={{ padding: "2em 1em 0em 0em" }}>
        <Placeholder>
          <Placeholder.Header>
            <Placeholder.Line />
          </Placeholder.Header>
          <Placeholder.Paragraph>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Paragraph>
        </Placeholder>
      </div>
    );
  }

  return (
    <div style={{ padding: "1.5em 1em 0em 0em" }}>
      <div style={{ margin: "0 0 1.5em 0", fontWeight: "bold" }}>
        Display Options
      </div>
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <ToggleFilter
              filter={filterEmptyWorkflows}
              label="Hide workflows with no clients"
              onChange={(checked) => {
                onUpdateFilter({ filterEmptyWorkflows: checked });
              }}
              data-test="filter-menu-hide-empty-workflow-toggle"
            />
          </Grid.Column>
        </Grid.Row>
        {assigneeDisplayName && (
          <React.Fragment>
            <FeatureFlag id="service-team-settings">
              {(assigneeFilterType === "ASSIGNEE" ||
                assigneeFilterType === "CURRENT") && (
                <Grid.Row>
                  <Grid.Column>
                    <ToggleFilter
                      filter={filterAssigneeIncludeServiceRole}
                      label={`Include tasks assigned to ${assigneeDisplayName.possessive} role(s)`}
                      onChange={(checked) => {
                        onUpdateFilter({
                          filterAssigneeIncludeServiceRole: checked,
                        });
                      }}
                      data-test="filter-menu-assignee-role-toggle"
                    />
                  </Grid.Column>
                </Grid.Row>
              )}
              {assigneeFilterType === "SERVICE_ROLE" && (
                <Grid.Row>
                  <Grid.Column>
                    <ToggleFilter
                      filter={filterServiceRoleUnassignedOnly}
                      label={`Only show ${assigneeDisplayName.subject} tasks not assigned to a user`}
                      onChange={(checked) => {
                        onUpdateFilter({
                          filterServiceRoleUnassignedOnly: checked,
                        });
                      }}
                      data-test="filter-menu-unassigned-only-toggle"
                    />
                  </Grid.Column>
                </Grid.Row>
              )}
            </FeatureFlag>
            {assigneeFilterType !== "ALL" &&
              assigneeFilterType !== "UNASSIGNED" && (
                <Grid.Row>
                  <Grid.Column>
                    <ToggleFilter
                      filter={filterNextTaskForAssignee}
                      label={`Only show workflows where the next task is assigned to ${assigneeDisplayName.subject}`}
                      onChange={(checked) => {
                        onUpdateFilter({ filterNextTaskForAssignee: checked });
                      }}
                      data-test="filter-menu-next-task-toggle"
                    />
                  </Grid.Column>
                </Grid.Row>
              )}
          </React.Fragment>
        )}
      </Grid>
    </div>
  );
}

MoreFilters.defaultProps = {
  filterEmptyWorkflows: false,
  filterAssigneeIncludeServiceRole: true,
  filterNextTaskForAssignee: false,
  filterServiceRoleUnassignedOnly: true,
  assigneeDisplayName: null,
  assigneeFilterType: "ALL",
  loading: false,
};

MoreFilters.propTypes = {
  loading: PropTypes.bool,
  filterEmptyWorkflows: PropTypes.bool,
  filterAssigneeIncludeServiceRole: PropTypes.bool,
  filterNextTaskForAssignee: PropTypes.bool,
  filterServiceRoleUnassignedOnly: PropTypes.bool,
  assigneeDisplayName: PropTypes.string,
  assigneeFilterType: PropTypes.string,
  onUpdateFilter: PropTypes.func.isRequired,
};

export default MoreFilters;
