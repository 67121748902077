import React, { useContext, useState } from "react";
import PropTypes from "prop-types";

import { Button, Checkbox } from "semantic-ui-react";

import { useAccessTypeStore } from "scenes/Hubly/components/Workspace/components/hooks";
import {
  HubContext,
  ToasterContext,
} from "scenes/Hubly/components/Workspace/Provider";

import { TaskLayout, TaskTitle } from "../Task/components";
import { getTaskInitialState } from "../Task/helper";
import { Assigner, TaskAddLoader } from "./components";

export function TaskAdd({
  workflowId,
  numTasks,
  showAssignedAdvisor,
  isClientWorkflow,
  onTaskAdded,
  children,
}) {
  const { addTask } = useContext(HubContext);
  const { fireError } = useContext(ToasterContext);
  const [applyToExistingClient, setApplyToExistingClient] = useState(true);
  const [adding, setAdding] = useState(false);
  const [task, setTask] = useState(getTaskInitialState(workflowId));
  const [loading, setLoading] = useState(false);
  const { hasAccess } = useAccessTypeStore();

  const onTaskConfigChange = (data) => {
    setTask((prevState) => {
      return {
        ...prevState,
        ...data,
      };
    });
  };

  const saveTask = async () => {
    try {
      setLoading(true);
      const result = await addTask({
        ...task,
        order: numTasks + 1,
        applyToExistingClient: applyToExistingClient,
      });
      setTask(getTaskInitialState(workflowId));
      setLoading(false);
      onTaskAdded(result);
    } catch (error) {
      fireError("Failed to create task", error);
      setLoading(false);
    }
  };

  if (!hasAccess()) {
    return null;
  }

  if (!adding) {
    return (
      <Button
        basic
        onClick={() => {
          setAdding(true);
        }}
      >
        Add Task
      </Button>
    );
  }

  if (loading) {
    return <TaskAddLoader />;
  }

  return (
    <TaskLayout
      data-test="task-add-form"
      addingMode
      disableHover
      assignedAdvisor={
        showAssignedAdvisor && (
          <Assigner
            size="small"
            currentAssignee={task.assignedAdvisor}
            onAssign={(id, assignedAdvisor) => {
              onTaskConfigChange({
                assigneeId: id === "unassigned" ? null : id,
                assignedAdvisor: assignedAdvisor,
              });
            }}
          />
        )
      }
      taskTitle={
        <TaskTitle
          title={task.title}
          loading={loading}
          editMode
          existingClientCheckbox={
            !isClientWorkflow && (
              <Checkbox
                checked={applyToExistingClient}
                onChange={() => {
                  setApplyToExistingClient(!applyToExistingClient);
                }}
              />
            )
          }
          onTaskNameChange={(name) => {
            setTask((prevState) => {
              return {
                ...prevState,
                title: name,
              };
            });
          }}
          onSave={saveTask}
          onClose={() => {
            setAdding(false);
          }}
        >
          {children({ task, numTasks, onTaskConfigChange })}
        </TaskTitle>
      }
    />
  );
}

TaskAdd.propTypes = {
  workflowId: PropTypes.string.isRequired,
  showAssignedAdvisor: PropTypes.bool,
  isClientWorkflow: PropTypes.bool,
  onTaskAdded: PropTypes.func.isRequired,
  children: PropTypes.func,
  numTasks: PropTypes.number,
};

TaskAdd.defaultProps = {
  isClientWorkflow: false,
  showAssignedAdvisor: false,
  numTasks: 0,
  children: () => {},
};

export default TaskAdd;
