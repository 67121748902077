import React from "react";
import PropTypes from "prop-types";

import { Header } from "semantic-ui-react";

import { RangeInput, ToggleItem } from "./components";

export function AppearanceController({ value, onChange, onMessage }) {
  return (
    <div style={{ fontSize: "12pt" }}>
      <Header as="h4">Tile Display Settings</Header>
      {[
        { type: "Progress bar", key: "showProgress" },
        { type: "Streams", key: "showStreams" },
        { type: "Workflow's Name", key: "presentWorkflowName" },
        { type: "Icons", key: "showIcons" },
      ].map(({ type, key }) => {
        return (
          <ToggleItem
            key={key}
            title={type}
            value={value[key]}
            onChange={(newValue) => {
              return onChange({ ...value, [key]: newValue });
            }}
          />
        );
      })}

      <div>
        Preview &nbsp;
        <RangeInput
          min={0}
          max={5}
          size="small"
          style={{ maxWidth: "41px" }}
          placeholder="2"
          value={value.numberOfTasks}
          onChange={(newValue, message) => {
            if (message) onMessage(message);
            return onChange({ ...value, numberOfTasks: newValue });
          }}
        />
        &nbsp; tasks on tile
      </div>
    </div>
  );
}

AppearanceController.propTypes = {
  onMessage: PropTypes.func,
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

AppearanceController.defaultProps = {
  onMessage: () => {},
};

export default AppearanceController;
