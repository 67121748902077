import React from "react";
import PropTypes from "prop-types";

import Skeleton from "@material-ui/lab/Skeleton";
import { Modal as OriginalModal } from "semantic-ui-react";

const ModalSkeleton = () => {
  return (
    <React.Fragment>
      <OriginalModal.Header>
        <Skeleton />
      </OriginalModal.Header>
      <OriginalModal.Content>
        <Skeleton variant="rect" height={120} />
      </OriginalModal.Content>
      <OriginalModal.Actions>
        <Skeleton />
      </OriginalModal.Actions>
    </React.Fragment>
  );
};

const Modal = ({ isModalOpen, isLoading, onClose, children, ...props }) => {
  return (
    <OriginalModal open={isModalOpen} {...props} onClose={onClose}>
      {isLoading ? <ModalSkeleton /> : children}
    </OriginalModal>
  );
};

Modal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  children: PropTypes.node,
  onClose: PropTypes.func,
};

Modal.defaultProps = {
  children: null,
  isLoading: false,
  onClose: () => {},
};

export default Modal;
