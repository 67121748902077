import { filter, groupBy } from "lodash";

import { createSelector } from "reselect";

const getAllClients = (state) => {
  return state?.hubly?.data?.hub?.clients?.allClientNames || [];
};
const getSelectedClientInFilter = (state) => {
  return state?.hubly?.data?.filters?.clientFilter || [];
};
const getSelectedWorkflows = (state) => {
  return state?.hubly?.data?.filters?.workflows || [];
};
const getClientWorkflows = (state) => {
  return state?.hubly?.data?.hub?.clientWorkflows;
};

function notNull(obj) {
  return !!obj;
}
function or(p1, p2) {
  return (elm) => {
    return p1(elm) || p2(elm);
  };
}

function activeClientWorkflow({ completed, isHidden, archived }) {
  return !completed && !isHidden && !archived;
}

export function visibleClients(
  selectedClientWorkflows = [],
  selectedClients = []
) {
  function clientsInSelectedWorkflows() {
    return ({ id }) => {
      return selectedClientWorkflows.some(({ clientId }) => {
        return clientId === id;
      });
    };
  }

  function clientIncludeInFilter() {
    return ({ id }) => {
      return selectedClients.includes(id);
    };
  }

  return or(clientsInSelectedWorkflows(), clientIncludeInFilter());
}

export function makeGetVisibleClient() {
  return createSelector(
    [
      getAllClients,
      getSelectedClientInFilter,
      getSelectedWorkflows,
      getClientWorkflows,
    ],
    (allClients, selectedClients, selectedWorkflows, clientWorkflows) => {
      const allCWsByWfId = groupBy(
        filter(clientWorkflows, activeClientWorkflow),
        "workflowId"
      );
      const selectedCWs = selectedWorkflows
        .flatMap(({ id }) => {
          return allCWsByWfId[id];
        })
        .filter(notNull);

      return allClients.filter(visibleClients(selectedCWs, selectedClients));
    }
  );
}

export default makeGetVisibleClient;
