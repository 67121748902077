import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Button, Dropdown, Header } from "semantic-ui-react";

import { SetActiveClient } from "data/hub/clients/actions";
import {
  EditClientTask,
  SetClientWorkflow,
} from "data/hub/clientWorkflows/actions";
import { EditWorkflowTask, SetWorkflow } from "data/hub/workflows/actions";
import { EditClientWorkflow } from "data/libs/clientWorkflows";
import { EditTask } from "data/libs/tasks";
import { EditWorkflow } from "data/libs/workflows";

import { SetAlert } from "components/Alerts/actions";
import { ToggleAssignedAdvisorPopup } from "components/AssignedAdvisorPopup/actions";
import Popper from "components/Popper";

import UserAvatar from "../UserAvatar";

class AssignedAdvisorPopup extends React.Component {
  static defaultProps = {
    addingTask: false,
    currentObject: { id: "" },
    editTaskState: () => {},
    defaultDropdownValue: undefined,
    iconProperties: {
      addIconSize: "20px",
      circleFontSize: "10px",
      circleSize: "20px",
    },
    modalObjectId: "",
    isNextWorkflow: false,
    editNextWorkflowTask: () => {},
  };

  static propTypes = {
    addingTask: PropTypes.bool,
    currentObject: PropTypes.object,
    currentObjectType: PropTypes.string.isRequired,
    editClientTask: PropTypes.func.isRequired,
    editTaskState: PropTypes.func,
    editWorkflowTask: PropTypes.func.isRequired,
    iconProperties: PropTypes.shape({
      addIconSize: PropTypes.string,
      circleFontSize: PropTypes.string,
      circleSize: PropTypes.string,
    }),
    isAssignedAdvisorOpen: PropTypes.bool.isRequired,
    modalObjectId: PropTypes.string,
    objectType: PropTypes.string.isRequired,
    selectedHub: PropTypes.shape({
      advisors: PropTypes.array.isRequired,
    }).isRequired,
    defaultDropdownValue: PropTypes.string,
    setClientWorkflow: PropTypes.func.isRequired,
    setWorkflow: PropTypes.func.isRequired,
    toggleAssignedAdvisorPopup: PropTypes.func.isRequired,
    isNextWorkflow: PropTypes.bool,
    editNextWorkflowTask: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      highlightedAdvisorId:
        props.currentObject.assignedAdvisor?.id || "unassigned",
      loading: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.defaultDropdownValue &&
      props.defaultDropdownValue !== state.highlightedAdvisorId
    ) {
      return {
        highlightedAdvisorId: props.defaultDropdownValue,
      };
    }

    return null;
  }

  addToAdvisor = () => {
    const { highlightedAdvisorId } = this.state;
    const {
      addingTask,
      editClientTask,
      editTaskState,
      editWorkflowTask,
      objectType,
      selectedHub,
      setClientWorkflow,
      setWorkflow,
      toggleAssignedAdvisorPopup,
      isNextWorkflow,
      editNextWorkflowTask,
      currentObject,
    } = this.props;

    const request = {
      assignedAdvisorId:
        highlightedAdvisorId !== "unassigned" ? highlightedAdvisorId : "",
    };

    this.setState({ loading: true });
    if (addingTask && objectType === "TASK") {
      editTaskState("assignedAdvisorId", request.assignedAdvisorId, "edit");
      const foundAdvisor = selectedHub.advisors.find((advisor) => {
        return advisor.id === request.assignedAdvisorId;
      });
      if (isNextWorkflow) {
        editNextWorkflowTask(null, foundAdvisor);
      }
      editTaskState("assignedAdvisor", foundAdvisor, "edit");
      toggleAssignedAdvisorPopup();
      this.setState({ highlightedAdvisorId: "unassigned", loading: false });
    } else if (!addingTask && objectType === "TASK") {
      EditTask(currentObject.id, request)
        .then((response) => {
          // We're in the client card
          if (response.clientWorkflowId) {
            editClientTask(response);
          } else if (isNextWorkflow) {
            editNextWorkflowTask(response);
          } else {
            editWorkflowTask(response);
          }

          toggleAssignedAdvisorPopup();
          this.setState({ highlightedAdvisorId: "unassigned", loading: false });
        })
        .catch((error) => {
          console.error(error);
          this.setState({ loading: false });
        });
    } else if (objectType === "WORKFLOW") {
      EditWorkflow(currentObject.id, request)
        .then((response) => {
          setWorkflow(response);
          toggleAssignedAdvisorPopup();
          this.setState({ loading: false });
        })
        .catch((error) => {
          console.error(error);
          this.setState({ loading: false });
        });
    } else if (
      objectType === "CLIENT_WORKFLOW" ||
      objectType === "CLIENT_TILE"
    ) {
      EditClientWorkflow(currentObject.id, request)
        .then((response) => {
          setClientWorkflow(response);
          toggleAssignedAdvisorPopup();
          this.setState({ loading: false });
        })
        .catch((error) => {
          console.error(error);
          this.setState({ loading: false });
        });
    } else {
      console.error("Object type not specified, could not assign to advisor");
      this.setState({ loading: false });
    }
  };

  getAssignedAdvisorOptions = () => {
    const { selectedHub } = this.props;
    const sortedAdvisors = selectedHub.advisors.sort((a) => {
      return a.first_name < a.last_name;
    });
    const dropdownOptions = sortedAdvisors.map((advisor, i) => {
      return {
        key: advisor.id,
        text: `${advisor.firstName} ${advisor.lastName}`,
        value: advisor.id,
        icon: "user circle",
      };
    });
    dropdownOptions.push({
      key: "unassigned",
      text: "Unassigned",
      value: "unassigned",
      icon: "user circle outline",
    });
    return dropdownOptions;
  };

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };

  isOpen = () => {
    const {
      currentObject,
      currentObjectType,
      isAssignedAdvisorOpen,
      modalObjectId,
      objectType,
    } = this.props;
    return (
      currentObject.id === modalObjectId &&
      currentObjectType === objectType &&
      isAssignedAdvisorOpen
    );
  };

  // prevent old highlighted state from persisting when you switch between workflows on Client card
  refreshHighlighted = () => {
    const { currentObject } = this.props;
    this.setState({
      highlightedAdvisorId: (
        currentObject.assignedAdvisor || { id: "unassigned" }
      ).id,
    });
  };

  renderTrigger() {
    const { currentObject, currentObjectType, toggleAssignedAdvisorPopup } =
      this.props;
    return (
      <div
        data-test="assigner"
        data-dd-action-name="Assigner"
        onClick={(e) => {
          e.stopPropagation();
          this.refreshHighlighted();
          toggleAssignedAdvisorPopup(currentObject.id, true, currentObjectType);
        }}
      >
        <UserAvatar user={currentObject.assignedAdvisor} />
      </div>
    );
  }

  render() {
    const { loading } = this.state;
    let { highlightedAdvisorId } = this.state;
    if (highlightedAdvisorId === "unassigned") highlightedAdvisorId = null;

    const { currentObject, currentObjectType, toggleAssignedAdvisorPopup } =
      this.props;
    const dropdownOptions = this.getAssignedAdvisorOptions();
    const currentAssignedAdvisorId = (
      currentObject.assignedAdvisor || { id: "unassigned" }
    ).id;
    if (!this.isOpen()) {
      return this.renderTrigger();
    } else {
      return (
        <Popper
          data-test="assigner-popup"
          offset="-10px, 0"
          on="click"
          open
          onClick={(e) => {
            e.stopPropagation();
          }}
          onClose={() => {
            toggleAssignedAdvisorPopup();
          }}
          onOpen={() => {
            toggleAssignedAdvisorPopup(
              currentObject.id,
              true,
              currentObjectType
            );
          }}
          position="bottom left"
          trigger={this.renderTrigger()}
        >
          <div style={{ width: "max-content" }}>
            <div style={{ display: "flex" }}>
              <Header as="h4" style={{ marginBottom: "1em" }}>
                Edit Team Member
              </Header>
            </div>
            <div>
              <Dropdown
                defaultValue={highlightedAdvisorId || currentAssignedAdvisorId}
                disabled={loading}
                fluid
                loading={loading}
                name="highlightedAdvisorId"
                onChange={this.handleChange}
                options={dropdownOptions}
                placeholder="Select a Team Member"
                selection
                selectOnBlur={false}
                style={{ minWidth: "200px" }}
              />
              <Button
                disabled={highlightedAdvisorId === currentAssignedAdvisorId}
                fluid
                loading={loading}
                onClick={(e) => {
                  e.stopPropagation();
                  this.addToAdvisor();
                }}
                positive
                style={{ marginTop: "1em" }}
              >
                Save
              </Button>
            </div>
          </div>
        </Popper>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    isAssignedAdvisorOpen: state.hubly.assignedAdvisor.isAssignedAdvisorOpen,
    modalObjectId: state.hubly.assignedAdvisor.modalObjectId,
    objectType: state.hubly.assignedAdvisor.objectType,
    selectedHub: state.hubly.data.hub.selected.hub,
    defaultDropdownValue: state.hubly.assignedAdvisor.defaultDropdownValue,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    editClientTask: (task) => {
      dispatch(EditClientTask(task));
    },
    editWorkflowTask: (task) => {
      dispatch(EditWorkflowTask(task));
    },
    setAlert: (alert) => {
      dispatch(SetAlert(alert));
    },
    setActiveClient: (client) => {
      dispatch(SetActiveClient(client));
    },
    setClientWorkflow: (clientWorkflow) => {
      dispatch(SetClientWorkflow(clientWorkflow));
    },
    setWorkflow: (workflow) => {
      dispatch(SetWorkflow(workflow));
    },
    toggleAssignedAdvisorPopup: (taskId, isOpen, type) => {
      dispatch(ToggleAssignedAdvisorPopup(taskId, isOpen, type));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssignedAdvisorPopup);
