export const initialState = {
  loginErrorMessage: "",
  loginInfoMessage: null, // takes markup not just string
  signingIn: false,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_LOGIN_ERROR_MESSAGE":
      return {
        ...state,
        loginErrorMessage: action.message,
      };
    case "SET_LOGIN_INFO_MESSAGE":
      return {
        ...state,
        loginInfoMessage: action.message,
      };
    case "SET_LOGIN_SIGNING_IN":
      return {
        ...state,
        signingIn: action.signingIn,
      };
    default:
      return state;
  }
};
export default reducer;
