import { useContext, useEffect, useRef, useState } from "react";

import {
  HubContext,
  ToasterContext,
} from "scenes/Hubly/components/Workspace/Provider";

export function useServiceTeamAction(onDone = () => {}) {
  const mounted = useRef(false);
  const { fireSuccess, fireError } = useContext(ToasterContext);
  const { addServiceTeam, editServiceTeam, mergeServiceTeam, findServiceTeam } =
    useContext(HubContext);

  const [isRunningAction, setRunning] = useState(null);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  const makeCall = (id) => {
    return async (action) => {
      if (mounted.current) {
        setRunning(id);
        const resp = await action();
        if (mounted.current) {
          setRunning(null);
          return resp;
        }
      }
      return null;
    };
  };

  return {
    add: ({ name, teamMembers }) => {
      const call = makeCall("add-service-team");
      return call(() => {
        return addServiceTeam({ name, teamMembers })
          .then(() => {
            fireSuccess(`Successfully added Service Team`);
          })
          .catch((error) => {
            fireError(
              error[0]?.message
                ? error[0]?.message
                : `Failed to add Service Team, please try again or contact support for assistance.`,
              error
            );
          })
          .finally(onDone);
      });
    },
    edit: ({ id, name, teamRoleIds, teamMembers }) => {
      const call = makeCall("edit-service-team");
      return call(() => {
        return editServiceTeam({ id, name, teamRoleIds, teamMembers })
          .then(() => {
            fireSuccess(`Successfully edited Service Team`);
          })
          .catch((error) => {
            fireError(
              error[0]?.message
                ? error[0]?.message
                : `Failed to edit Service Team, please try again or contact support for assistance.`,
              error
            );
          })
          .finally(onDone);
      });
    },
    merge: ({ sourceId, targetId, sourceTeamRoleIds, teamMembers }) => {
      const call = makeCall("merge-service-team");
      return call(() => {
        return mergeServiceTeam({
          sourceId,
          targetId,
          sourceTeamRoleIds,
          teamMembers,
        })
          .then(() => {
            fireSuccess(`Successfully merged Service Team`);
          })
          .catch((error) => {
            fireError(
              error[0]?.message
                ? error[0]?.message
                : `Failed to merge Service Team, please try again or contact support for assistance.`,
              error
            );
          })
          .finally(onDone);
      });
    },
    find: ({
      serviceTeamName = null,
      serviceTeamId = null,
      serviceTeamRoleIds = null,
      teamMembers = [],
      filterEmptyMember = true,
    }) => {
      const call = makeCall("find-matching-team");
      return filterEmptyMember &&
        teamMembers.filter(({ members }) => {
          return members.length > 0;
        }).length === 0
        ? Promise.resolve()
        : call(() => {
            return findServiceTeam({
              serviceTeamName,
              serviceTeamId,
              serviceTeamRoleIds,
              teamMembers,
            })
              .then((resp) => {
                return resp;
              })
              .catch((error) => {
                fireError(
                  error[0]?.message
                    ? error[0]?.message
                    : `Failed to find matching service team, please try again or contact support for assistance.`,
                  error
                );
                onDone();
              });
          });
    },
    loading: isRunningAction !== null,
  };
}

export default useServiceTeamAction;
