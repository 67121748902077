import React from "react";
import PropTypes from "prop-types";

import useNewHublyApp from "./useNewHublyApp";

export function LegacyAppIndicator({ indicator }) {
  const { newApp } = useNewHublyApp();

  if (newApp) return null;

  return <span>{indicator}</span>;
}

LegacyAppIndicator.propTypes = { indicator: PropTypes.string };
LegacyAppIndicator.defaultProps = { indicator: "Legacy" };

export default LegacyAppIndicator;
