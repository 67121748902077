import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { v4 } from "uuid";

import {
  Checkbox,
  Header,
  Icon,
  Input,
  List,
  Modal,
  Popup,
  Progress,
} from "semantic-ui-react";

import { SetAlert } from "components/Alerts/actions";
import Expandable from "components/Expandable";

import TileIcon from "./ClientTile/components/TileIcon";
import { SetTileSettings } from "../actions";

class EditTileSettingsModal extends React.Component {
  static propTypes = {
    workflow: PropTypes.object.isRequired,
    setTileSettings: PropTypes.func.isRequired,
    setEditTilesModal: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const { workflow } = props;
    this.state = {
      numberOfTasks: workflow.numberOfTasks,
      showIcons: workflow.showIcons,
      showProgress: workflow.showProgress,
      showStreams: workflow.showStreams,
      showName: workflow.options?.presentWorkflowName,
    };
  }

  toggleShowProgress = () => {
    const { workflow } = this.props;
    const { showProgress } = this.state;
    const settings = { showProgress: !showProgress };
    this.props.setTileSettings(workflow.id, settings);
    this.setState({ showProgress: !showProgress });
  };

  toggleShowStreams = () => {
    const { workflow } = this.props;
    const { showStreams } = this.state;
    const settings = { showStreams: !showStreams };
    this.props.setTileSettings(workflow.id, settings);
    this.setState({ showStreams: !showStreams });
  };

  toggleShowIcons = () => {
    const { workflow } = this.props;
    const { showIcons } = this.state;
    const settings = { showIcons: !showIcons };
    this.props.setTileSettings(workflow.id, settings);
    this.setState({ showIcons: !showIcons });
  };

  toggleShowName = () => {
    const { workflow } = this.props;
    const { showName } = this.state;
    const settings = { showName: !showName };
    this.props.setTileSettings(workflow.id, settings);
    this.setState({ showName: !showName });
  };

  invalidInput = () => {
    this.props.setAlert({
      type: "warning",
      text: "Unable to save input value. Please enter a number between zero and five.",
    });
  };

  setNumTasksToShow = (e) => {
    let { value } = e.target;
    if (value.length === 0 || !Number.isNaN(+value)) {
      if (value > 5) {
        value = 5;
        this.invalidInput();
      }
      this.setState({ numberOfTasks: value });
      if (value.length === 0) {
        value = 2;
      }
      const { workflow } = this.props;
      this.props.setTileSettings(workflow.id, { numberOfTasks: value });
    }
  };

  // negative indicates the modal has just opened
  closeModal = () => {
    this.props.setEditTilesModal(null);
  };

  toggleItem = (right, left) => {
    return (
      <div data-test={`toggle-item-${left}`} style={{ display: "block" }}>
        <div style={{ height: "50px", display: "flex", alignItems: "center" }}>
          <div>{left}</div>
          <div style={{ marginLeft: "auto" }}>
            <span>{right}</span>
          </div>
        </div>
      </div>
    );
  };

  exampleClientTile = () => {
    const reminderInfo = {
      statusText: "In Progress",
      statusBar: "green",
      number: 3,
      message: "May 5th",
    };
    const { showProgress, showIcons, showStreams, numberOfTasks, showName } =
      this.state;
    const nextTasks = [
      "Example task one",
      "Example task two",
      "Example task three",
      "Example task four",
      "Example task five",
    ].slice(0, numberOfTasks);
    return (
      <div
        className="ui segment"
        style={{
          display: "flex",
          padding: 0,
          width: "225px",
        }}
      >
        <div
          data-test="example-client-tile"
          className="ui mini_card"
          style={{
            flex: "1 1 auto",
            display: "flex",
            flexFlow: "column",
            padding: "0.5em",
            paddingRight: 0,
          }}
        >
          <div style={{ display: "flex", flexFlow: "column" }}>
            <div>
              <div style={{ display: "flex" }}>
                <Header
                  as="h2"
                  style={{
                    marginBottom: "0px",
                    fontWeight: "300",
                    fontSize: "16pt",
                  }}
                >
                  Example
                </Header>
                <div style={{ marginLeft: "5px", marginTop: "3px" }}>
                  {showStreams && (
                    <React.Fragment>
                      <Popup
                        basic
                        inverted
                        content="Example Stream"
                        size="mini"
                        style={{
                          padding: "0.5em",
                        }}
                        trigger={
                          <Icon name="circle" style={{ color: "#ffc107" }} />
                        }
                      />
                      <Popup
                        basic
                        inverted
                        content="Example Stream 2"
                        size="mini"
                        style={{
                          padding: "0.5em",
                        }}
                        trigger={
                          <Icon name="circle" style={{ color: "#8bc34a" }} />
                        }
                      />
                    </React.Fragment>
                  )}
                </div>
              </div>
              <Header
                as="h5"
                style={{
                  marginTop: "0px",
                  fontWeight: "300",
                  fontSize: "11pt",
                }}
              >
                Client
              </Header>
            </div>
            {showName && (
              <div>
                <Header
                  as="h5"
                  style={{
                    marginTop: "1px",
                    fontWeight: "300",
                    fontSize: "11pt",
                  }}
                >
                  Workflow Name
                </Header>
              </div>
            )}
          </div>
          <div
            style={{
              display: "flex",
              marginTop: "5px",
              flexDirection: "column",
            }}
          >
            {showProgress && (
              <React.Fragment>
                <Header
                  as="h5"
                  style={{
                    color: "grey",
                    marginBottom: 0,
                    fontSize: "10pt",
                    fontWeight: "500",
                  }}
                >
                  {reminderInfo.statusText}
                </Header>
                <Progress
                  percent={40}
                  size="tiny"
                  color={reminderInfo.statusBar}
                  style={{ marginRight: "1em", marginBottom: 0, marginTop: 0 }}
                />
              </React.Fragment>
            )}
            {nextTasks.length > 0 && (
              <List
                bulleted
                style={{
                  color: "grey",
                  paddingLeft: "0.5em",
                  marginTop: "0.5em",
                  marginRight: "1em",
                }}
              >
                {nextTasks.map((t) => {
                  return (
                    <List.Item key={v4()}>
                      <Expandable>{t}</Expandable>
                    </List.Item>
                  );
                })}
              </List>
            )}
          </div>
          {showIcons && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginRight: "1em",
                marginTop: "1em",
              }}
            >
              {reminderInfo.number > 0 && (
                <TileIcon
                  icon="bell outline"
                  text={reminderInfo.number.toString()}
                />
              )}
              <TileIcon icon="check square outline" text="2/7" />
              <TileIcon icon="attach" dotColor="blue" text="3" />
            </div>
          )}
        </div>
      </div>
    );
  };

  render() {
    const { workflow } = this.props;
    const { numberOfTasks, showProgress, showStreams, showIcons, showName } =
      this.state;
    const settings = {
      showProgress,
      showIcons,
      showStreams,
      numberOfTasks,
      showName,
    };

    return (
      <Modal
        data-test="edit-tile-settings-modal"
        open
        style={{ width: "auto" }}
      >
        <Modal.Header>
          <Icon
            link
            style={{ float: "right" }}
            color="grey"
            name="delete"
            onClick={this.closeModal}
          />
          {`Edit tile for "${workflow.name}"`}
        </Modal.Header>
        <Modal.Content image>
          <div
            style={{
              display: "flex",
              minHeight: "297px",
              flexDirection: "row",
              alignItems: "center",
              padding: "1em 3em",
            }}
          >
            <div
              style={{
                marginRight: "6em",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {this.exampleClientTile()}
            </div>
            <div>
              <div style={{ fontSize: "12pt" }}>
                <Header as="h4">Tile Display Settings:</Header>
                {this.toggleItem(
                  <Checkbox
                    toggle
                    onChange={this.toggleShowProgress}
                    checked={settings.showProgress}
                  />,
                  "Progress bar"
                )}
                {this.toggleItem(
                  <Checkbox
                    toggle
                    onChange={this.toggleShowStreams}
                    checked={settings.showStreams}
                  />,
                  "Streams"
                )}
                {this.toggleItem(
                  <Checkbox
                    toggle
                    onChange={this.toggleShowName}
                    checked={settings.showName}
                  />,
                  "Workflow's Name"
                )}
                {this.toggleItem(
                  <Checkbox
                    toggle
                    onChange={this.toggleShowIcons}
                    checked={settings.showIcons}
                  />,
                  "Icons"
                )}
                <div>
                  Preview &nbsp;
                  <Input
                    onChange={this.setNumTasksToShow}
                    placeholder="2"
                    size="small"
                    maxLength={1}
                    style={{ maxWidth: "41px" }}
                    value={numberOfTasks}
                  />
                  &nbsp; tasks on tile
                </div>
              </div>
            </div>
          </div>
        </Modal.Content>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    advisor: state.hubly.data.advisor,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setTileSettings: (id, settings) => {
      dispatch(SetTileSettings(id, settings));
    },
    setAlert: (alert) => {
      dispatch(SetAlert(alert));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditTileSettingsModal);
