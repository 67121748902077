import { API } from "aws-amplify";

import { getAPIReq } from "data/libs/authLib";

import toGlobalId from "./helper";

export const getAdvisorDefaultView = async (hubId, advisorId) => {
  const req = await getAPIReq(
    {
      query: `query getAdvisorDefaultView($id: ID!) {
          advisor(id: $id) {
            defaultView
          }
        }`,
      variables: {
        id: toGlobalId("Advisor", advisorId),
      },
    },
    { hub: hubId }
  );
  const {
    data: { advisor },
  } = await API.post("HublyAPI", `/graphql`, req);

  return [advisor.defaultView];
};

export default getAdvisorDefaultView;
