import React from "react";

import { CreateClientOption, DropdownItemSection, SyncOption } from "..";

function WithSearch(Component) {
  return ({
    hideSyncAndCreate,
    searchInput,
    disabled,
    integration,
    contactProps,
    streamProps,
    tagProps,
    onCreateClientModal,
  }) => {
    const allProps = {
      allContacts: contactProps,
      streams: streamProps,
      tags: tagProps,
      sync:
        integration && !hideSyncAndCreate ? { disabled, integration } : null,
      createClient: !hideSyncAndCreate
        ? {
            disabled: disabled,
            onClick: () => {
              onCreateClientModal(true);
            },
          }
        : null,
    };

    if (searchInput === "") {
      return (
        <React.Fragment>
          {allProps.createClient && (
            <CreateClientOption
              {...allProps.createClient}
              searchInput={searchInput}
            />
          )}
          {allProps.sync && <SyncOption {...allProps.sync} />}
          {allProps.streams && <Component {...allProps.streams} />}
          {allProps.tags && <Component {...allProps.tags} />}
          {allProps.allContacts && <Component {...allProps.allContacts} />}
        </React.Fragment>
      );
    } else {
      const contacts = allProps.allContacts?.items || [];
      const streams = allProps.streams?.items || [];
      const tags = allProps.tags?.items || [];

      if (contacts.length === 0 && streams.length > 0 && tags.length === 0) {
        return (
          <React.Fragment>
            <Component {...allProps.streams} items={streams} emptyText="" />
            {allProps.createClient && (
              <CreateClientOption
                {...allProps.createClient}
                searchInput={searchInput}
              />
            )}
            {allProps.sync && <SyncOption {...allProps.sync} />}
          </React.Fragment>
        );
      } else if (
        contacts.length === 0 &&
        streams.length === 0 &&
        tags.length > 0
      ) {
        return (
          <React.Fragment>
            <Component {...allProps.tags} items={tags} emptyText="" />
            {allProps.createClient && (
              <CreateClientOption
                {...allProps.createClient}
                searchInput={searchInput}
              />
            )}
            {allProps.sync && <SyncOption {...allProps.sync} />}
          </React.Fragment>
        );
      } else if (
        contacts.length === 0 &&
        streams.length > 0 &&
        tags.length > 0
      ) {
        return (
          <React.Fragment>
            <Component {...allProps.streams} items={streams} />
            <Component {...allProps.tags} items={tags} />
            {allProps.createClient && (
              <CreateClientOption
                {...allProps.createClient}
                searchInput={searchInput}
              />
            )}
            {allProps.sync && <SyncOption {...allProps.sync} />}
          </React.Fragment>
        );
      }
      return (
        <React.Fragment>
          {allProps.allContacts && (
            <Component {...allProps.allContacts} items={contacts} />
          )}
          {allProps.createClient && (
            <CreateClientOption
              {...allProps.createClient}
              searchInput={searchInput}
            />
          )}
          {allProps.sync && <SyncOption {...allProps.sync} />}
          {allProps.streams && (
            <Component {...allProps.streams} items={streams} />
          )}
          {allProps.tags && <Component {...allProps.tags} items={tags} />}
        </React.Fragment>
      );
    }
  };
}

export const DropdownSectionWithSearchMode = WithSearch(DropdownItemSection);
export default DropdownSectionWithSearchMode;
