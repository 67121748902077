import { SetHub } from "data/hub/actions";
import {
  AddTagToClient,
  RemoveTagFromClient,
  SetActiveClient,
} from "data/hub/clients/actions";
import {
  CreateClientTag,
  DeleteClientTag,
  GetClient,
  UpdateClientTag,
} from "data/libs/clients";
import { GetTags } from "data/libs/tags";

import { SetAlert } from "components/Alerts/actions";

export const SetTags = (tags) => {
  return {
    type: "SET_TAGS",
    tags: tags,
  };
};

export const SetTag = (tag) => {
  return {
    type: "SET_TAG",
    tag: tag,
  };
};

export const RemoveTag = (tagId) => {
  return {
    type: "REMOVE_TAG",
    tagId: tagId,
  };
};

export const RefreshTags = (hub) => {
  return (dispatch, getState) => {
    GetTags(hub).then((resp) => {
      const tags = {};
      resp.forEach((tag) => {
        tags[tag.id] = tag;
      });
      dispatch(SetTags(tags));
    });
  };
};

export function AddClientTag(clientId, tagName, callback) {
  return (dispatch, getState) => {
    const { hub } = getState().hubly.data.hub.selected;
    const allTags = getState().hubly.data.hub.tags;
    const client = getState().hubly.data.hub.clients.activeClients[clientId];
    // If the client is not in activeClients, it will not have the tag, otherwise use the found client to see if the tag exists
    let foundTag = null;
    if (client) {
      foundTag = (client.tags || []).find((tagId) => {
        const tag = allTags[tagId];
        return tag?.name === tagName;
      });
    }
    if (!foundTag) {
      const request = {
        hubId: hub.id,
        name: tagName, // name of new tag
      };
      CreateClientTag(clientId, request)
        .then((response) => {
          dispatch(SetTag(response));
          if (!client) {
            GetClient(clientId).then((clientResponse) => {
              dispatch(SetActiveClient(clientResponse));
            });
          } else {
            dispatch(AddTagToClient(clientId, response.id));
          }
          dispatch(
            SetAlert({
              type: "success",
              text: `Successfully added tag`,
            })
          );

          // Update hub.tags so it appears in the Tag Manager
          const newHub = { ...hub };
          if (
            !(newHub.tags || []).find((t) => {
              return response.id === t.id;
            })
          ) {
            newHub.tags.push(response);
            dispatch(SetHub(newHub));
          }
          callback(response, clientId);
        })
        .catch((error) => {
          console.warn(error);
          dispatch(
            SetAlert({ type: "error", text: `Failed to add tag to client.` })
          );
          callback(null);
        });
    } else {
      callback(null);
      dispatch(
        SetAlert({
          type: "warning",
          text: `Client already has tag ${tagName}. Please verify and try again.`,
        })
      );
    }
  };
}

export function RemoveClientTag(client, tag, callback) {
  return (dispatch, getState) => {
    DeleteClientTag(client.id, tag.id)
      .then((response) => {
        dispatch(RemoveTagFromClient(client.id, tag.id));
        dispatch(
          SetAlert({ type: "success", text: `Successfully removed tag` })
        );
        if (callback) callback(true);
      })
      .catch((error) => {
        console.warn(error);
        dispatch(SetAlert({ type: "error", text: `Failed to delete tag.` }));
        if (callback) callback(false);
      });
  };
}

export function UpdateTagForClient(client, tag, callback) {
  return (dispatch, getState) => {
    UpdateClientTag(client.id, tag.id, tag)
      .then((response) => {
        dispatch(SetTag(response));
        dispatch(
          SetAlert({ type: "success", text: `Successfully updated tag` })
        );

        if (callback) {
          callback();
        }
      })
      .catch((error) => {
        console.warn(error);
        dispatch(SetAlert({ type: "error", text: `Failed to edit tag.` }));
      });
  };
}
