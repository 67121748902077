import React from "react";
import PropTypes from "prop-types";

import { Icon } from "semantic-ui-react";

export function EmptyFeed({ children }) {
  return (
    <div
      style={{
        width: "100%",
        textAlign: "center",
        marginTop: "65%",
        color: "grey",
      }}
    >
      <Icon name="bell slash outline" size="huge" />
      <br />
      <br />
      {children}
    </div>
  );
}

EmptyFeed.propTypes = {
  children: PropTypes.node,
};

EmptyFeed.defaultProps = {
  children: "Empty",
};

export default EmptyFeed;
