import { mapValues } from "lodash";

export const useSize = (s = "small", factor = 0) => {
  const makeBaseSize = () => {
    switch (String(s).toLowerCase()) {
      case "small":
        return { fontSize: 10, circleSize: 20, iconSize: 20 };
      case "medium":
        return { fontSize: 12, circleSize: 25, iconSize: 25 };
      case "large":
        return { fontSize: 16, circleSize: 32, iconSize: 32 };
      case "big":
        return { fontSize: 18, circleSize: 30, iconSize: 35 };
      default:
        return { fontSize: 10, circleSize: 20, iconSize: 20 };
    }
  };

  const px = (num) => {
    return `${num}px`;
  };

  const { fontSize, ...size } = makeBaseSize();
  return mapValues({ ...size, fontSize: fontSize - factor * 2 }, px);
};

export default useSize;
