import React from "react";
import PropTypes from "prop-types";

import { Icon } from "semantic-ui-react";

import Tooltip from "components/Tooltip";

export function Item({
  text,
  subtext,
  clickable,
  color,
  icon,
  onClick,
  tooltipTitle,
  ...props
}) {
  return (
    <div
      className={`member-row ${clickable ? "member-row-hover" : ""}`}
      style={{ cursor: clickable ? "pointer" : "default" }}
      onClick={onClick}
      {...props}
    >
      <div style={{ width: "15px", marginRight: "0.5em" }}>
        <Icon name={icon} color="grey" />
      </div>
      <strong style={{ fontSize: "1rem", marginRight: ".5rem" }}>
        {text}
        {subtext ? ":" : ""}
      </strong>
      <Tooltip
        title={tooltipTitle}
        position="top center"
        style={{
          padding: "0.6em",
          fontSize: "12px",
          fontWeight: "normal",
          maxWidth: "fit-content",
        }}
      >
        <span style={{ color }}>{subtext}</span>
      </Tooltip>
    </div>
  );
}

Item.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  subtext: PropTypes.string,
  clickable: PropTypes.bool,
  icon: PropTypes.string,
  color: PropTypes.string,
  tooltipTitle: PropTypes.string,
};

Item.defaultProps = {
  onClick: undefined,
  subtext: "",
  clickable: false,
  icon: "user",
  color: "grey",
  tooltipTitle: "",
};

export default Item;
