import React from "react";
import PropTypes from "prop-types";

import { Dropdown } from "semantic-ui-react";

import ClientSyncButton from "../ClientSyncButton";

export const SyncOption = ({ disabled, integration }) => {
  return (
    <Dropdown.Item
      key="sync"
      value="sync"
      disabled={disabled}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <ClientSyncButton integrationType={integration.type} />
    </Dropdown.Item>
  );
};

SyncOption.defaultProps = {
  disabled: false,
};

SyncOption.propTypes = {
  integration: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
};

export default SyncOption;
