import React from "react";

import { Container, Placeholder } from "semantic-ui-react";

export function Skeleton() {
  return (
    <Container style={{ padding: "1em 0em 1em 3em" }}>
      <Placeholder fluid>
        <Placeholder.Paragraph>
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Paragraph>
      </Placeholder>
    </Container>
  );
}

export default Skeleton;
