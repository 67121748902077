import SetAdvisor from "data/advisor/actions";
import { SetAdvisorPreferences } from "data/libs/advisor";

import { SetAlert } from "components/Alerts/actions";

export const SetNotificationPreferences = (notificationPreferences) => {
  return async (dispatch, getState) => {
    const { advisor } = getState()?.hubly?.data;

    try {
      const resp = await SetAdvisorPreferences(advisor?.id, {
        key: "notifications",
        value: notificationPreferences,
      });

      dispatch(SetAdvisor({ ...advisor, preferences: resp }));
    } catch (e) {
      dispatch(
        SetAlert({
          type: "error",
          text: "Failed to update Notification settings.",
        })
      );
      console.error(e);
      console.error(`Parameters passed: ${notificationPreferences}`);
    }
  };
};

export default SetNotificationPreferences;
