import React from "react";
import DatePicker from "react-datepicker/es";
import PropTypes from "prop-types";

import { Input, Select } from "semantic-ui-react";

import { getFutureReminderOptions } from "../../helper";

export function FutureReminderControl({
  futureReminderDelay,
  name,
  value,
  onChange,
  disabled,
}) {
  if (futureReminderDelay !== "delayed") {
    return null;
  }

  const { futureReminderNumber, futureReminderType, futureReminderTime } =
    value;

  const updateValue = (type, val) => {
    onChange(null, {
      name: name,
      value: { ...value, [type]: val },
    });
  };

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <div style={{ marginBottom: "1em" }}>
        <Input
          name="futureReminderNumber"
          type="number"
          onChange={(_, { name: type, value: val }) => {
            updateValue(type, val);
          }}
          defaultValue={futureReminderNumber}
          style={{ width: "5em", marginRight: "0.5em" }}
          min="1"
          disabled={disabled}
        />
        <Select
          name="futureReminderType"
          compact
          options={getFutureReminderOptions(futureReminderNumber)}
          defaultValue={futureReminderType}
          onChange={(_, { name: type, value: val }) => {
            updateValue(type, val);
          }}
          style={{ marginRight: "0.5em" }}
          disabled={disabled}
        />
      </div>

      <div style={{ marginBottom: "1em" }}>
        {futureReminderDelay === "delayed" && futureReminderType !== "hour" && (
          <React.Fragment>
            <strong>at&nbsp;</strong>
            <DatePicker
              name="futureReminderTime"
              selected={futureReminderTime}
              onChange={(date) => {
                updateValue("futureReminderTime", date);
              }}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              dateFormat="h:mm aa"
              timeCaption="Time"
              customInput={<Input style={{ maxWidth: "7em" }} />}
              disabled={disabled}
            />
          </React.Fragment>
        )}
      </div>
    </div>
  );
}

FutureReminderControl.propTypes = {
  name: PropTypes.string.isRequired,
  futureReminderDelay: PropTypes.string.isRequired,
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

FutureReminderControl.defaultProps = {
  disabled: false,
};

export default FutureReminderControl;
