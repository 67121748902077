import { SetWorkflow } from "data/hub/workflows/actions";
import { AddReminder, DeleteReminder, EditReminder } from "data/libs/reminders";
import {
  AddSequentialReminder,
  ClearSequentialReminder,
} from "data/libs/tasks";

// all of these functions operate on the assumption that there is only one reminder per task and so
// just modify [0], if you want to work with more they all need to be altered

export const AddReminderToWorkflow = (
  workflowId,
  taskId,
  futureReminderNumber,
  futureReminderType,
  futureReminderTime,
  callback,
  submit = () => {
    return AddReminder({
      futureReminderNumber,
      futureReminderType,
      futureReminderTime,
      taskId,
    });
  },
  updateTask = (t, r) => {
    t.reminders = [r];
  }
) => {
  return (dispatch, getState) => {
    submit()
      .then((response) => {
        const workflowCopy = {
          ...getState().hubly.data.hub.workflows[workflowId],
        };
        if (!workflowCopy) {
          console.warn(
            "AddReminderToWorkflow, Workflow ID ",
            workflowId,
            " does not exist"
          );
          return;
        }

        const foundTask = workflowCopy.tasks.find((task) => {
          return task.id === taskId;
        });

        if (!foundTask) {
          console.warn(
            "AddReminderToWorkflow, Task ID ",
            taskId,
            " does not exist"
          );
          return;
        }
        updateTask(foundTask, response);
        dispatch(SetWorkflow(workflowCopy));
        callback(true, "Successfully added reminder");
      })
      .catch((error) => {
        console.warn("Failed to add reminder");
        console.error(error);
        callback(false, "Failed to add reminder");
      });
  };
};

export const EditWorkflowReminder = (
  workflowId,
  taskId,
  reminderId,
  futureReminderNumber,
  futureReminderType,
  futureReminderTime,
  callback
) => {
  return (dispatch, getState) => {
    const request = {
      futureReminderType,
      futureReminderNumber,
      futureReminderTime,
    };
    EditReminder(reminderId, request)
      .then((response) => {
        const workflowCopy = {
          ...getState().hubly.data.hub.workflows[workflowId],
        };
        if (!workflowCopy) {
          console.warn(
            "EditReminder, Workflow ID ",
            workflowId,
            " does not exist"
          );
          return;
        }

        const foundTask = workflowCopy.tasks.find((task) => {
          return task.id === taskId;
        });

        if (!foundTask) {
          console.warn("EditReminder, Task ID ", taskId, " does not exist");
          return;
        }

        foundTask.reminders = [response];
        dispatch(SetWorkflow(workflowCopy));
        callback(true, "Successfully edited reminder");
      })
      .catch((error) => {
        console.warn("Failed to edit reminder");
        console.error(error);
        callback(false, "Failed to edit reminder");
      });
  };
};

export const DeleteWorkflowReminder = (
  workflowId,
  taskId,
  reminderId,
  callback,
  submit = () => {
    return DeleteReminder(reminderId);
  },
  updateTask = (t) => {
    t.reminders = [];
  }
) => {
  return (dispatch, getState) => {
    submit()
      .then((response) => {
        const workflowCopy = {
          ...getState().hubly.data.hub.workflows[workflowId],
        };
        if (!workflowCopy) {
          console.warn(
            "EditReminder, Workflow ID ",
            workflowId,
            " does not exist"
          );
          return;
        }

        const foundTask = workflowCopy.tasks.find((task) => {
          return task.id === taskId;
        });

        if (!foundTask) {
          console.warn("EditReminder, Task ID ", taskId, " does not exist");
          return;
        }

        updateTask(foundTask, response);
        dispatch(SetWorkflow(workflowCopy));
        callback(true, "Successfully deleted reminder");
      })
      .catch((error) => {
        console.warn("Failed to delete reminder");
        console.error(error);
        callback(false, "Failed to delete reminder");
      });
  };
};

export const AddSequentialReminderToWorkflow = (
  workflowId,
  taskId,
  futureReminderNumber,
  futureReminderType,
  futureReminderTime,
  callback
) => {
  const data = {
    time: futureReminderTime,
    type: futureReminderType,
    number: futureReminderNumber,
  };
  return AddReminderToWorkflow(
    workflowId,
    taskId,
    futureReminderNumber,
    futureReminderType,
    futureReminderTime,
    callback,
    () => {
      return AddSequentialReminder(taskId, data);
    },
    (task, res) => {
      task.options = { ...res };
    }
  );
};

export const DeleteWorkflowSequentialReminder = (
  workflowId,
  taskId,
  reminderId,
  callback
) => {
  return DeleteWorkflowReminder(
    workflowId,
    taskId,
    reminderId,
    callback,
    () => {
      return ClearSequentialReminder(taskId);
    },
    (task, res) => {
      task.options = { ...res };
    }
  );
};
