import React from "react";
import PropTypes from "prop-types";

import ScriptLoader from "components/ScriptLoader";

import CannyComponent from "./CannyComponent";

export function CannyContainer({ config, ...options }) {
  if (!config.appId) {
    if (process.env.REACT_APP_LOG_ENABLED) {
      // eslint-disable-next-line no-console
      console.log("Canny is disabled");
    }

    return null;
  }

  return (
    <ScriptLoader scriptSrc="https://canny.io/sdk.js">
      <CannyComponent appId={config.appId} {...options} />
    </ScriptLoader>
  );
}

CannyContainer.propTypes = {
  config: PropTypes.shape({
    appId: PropTypes.string,
  }),
};

CannyContainer.defaultProps = {
  config: {
    appId: undefined,
  },
};

export default CannyContainer;
