import React from "react";
import PropTypes from "prop-types";

export function Layout({
  active,
  children,
  excludeItemLabels,
  onEdit,
  ruleMenu,
  ...props
}) {
  return (
    <div
      className="grey_on_hover rounded p25"
      style={{ display: "flex", marginBottom: "0.5em", cursor: "pointer" }}
      onDoubleClick={onEdit}
      {...props}
    >
      <div style={{ opacity: active ? "1" : "0.4" }}>
        {children}
        &nbsp;
        {excludeItemLabels}
        {!active && " (Paused)"}
      </div>
      <div style={{ display: "flex", marginLeft: "0.25em" }}>{ruleMenu}</div>
    </div>
  );
}

Layout.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
  excludeItemLabels: PropTypes.node,
  onEdit: PropTypes.func.isRequired,
  ruleMenu: PropTypes.node,
};

Layout.defaultProps = {
  active: false,
  children: null,
  ruleMenu: null,
  excludeItemLabels: null,
};

export default Layout;
