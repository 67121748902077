import React from "react";
import PropTypes from "prop-types";

import { Icon } from "semantic-ui-react";

export function TaskIcon({
  hasValue,
  title,
  iconName,
  iconNameOutline,
  onClick,
  expanded,
  expandedColor,
  visible,
  children,
}) {
  if (!visible) return <div style={{ width: "15px", flexGrow: 1 }} />;

  return (
    <div
      style={{
        display: "flex",
        flexGrow: 1,
        width: "15px",
        padding: "0",
        marginTop: "3px",
      }}
    >
      {hasValue ? (
        <Icon.Group>
          <Icon
            color={expanded && expandedColor ? expandedColor : "grey"}
            name={iconName}
            onClick={onClick}
            link
            title={title}
          />
          {children}
        </Icon.Group>
      ) : (
        <Icon.Group
          className={!expanded ? "show_on_hover dark_grey_on_hover" : undefined}
          style={{ fontSize: "13pt" }}
          onClick={onClick}
          title={title}
        >
          <Icon
            link
            name={iconNameOutline || `${iconName} outline`}
            style={expanded ? { color: "#aaaaaa", margin: 0 } : { margin: 0 }}
          />
          <Icon
            link
            corner
            name="add"
            style={
              expanded
                ? { color: "#aaaaaa" }
                : { color: "#aaaaaa", textShadow: "none" }
            }
          />
        </Icon.Group>
      )}
    </div>
  );
}

TaskIcon.propTypes = {
  hasValue: PropTypes.bool,
  iconName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  iconNameOutline: PropTypes.string,
  expandedColor: PropTypes.string,
  expanded: PropTypes.bool,
  visible: PropTypes.bool,
  children: PropTypes.node,
};

TaskIcon.defaultProps = {
  hasValue: false,
  iconNameOutline: "",
  expanded: false,
  expandedColor: null,
  visible: true,
  children: null,
  onClick: () => {},
};
export default TaskIcon;
