import React, { useState } from "react";
import PropTypes from "prop-types";

import { Button, Icon } from "semantic-ui-react";

import Selector from "components/Selector";

export function RuleAdd({ onConfigure }) {
  const [adding, setAdding] = useState(false);
  const [selectedRule, setSelectedRule] = useState("REPEATING_RULE");
  if (!adding) {
    return (
      <Button
        basic
        onClick={() => {
          setAdding(true);
        }}
      >
        Add Rule
      </Button>
    );
  }

  return (
    <React.Fragment>
      <div data-test="workflow-rule-dropdown" style={{ marginRight: "0.5em" }}>
        <Selector
          name="automation-rules"
          dropdownProps={{
            search: false,
          }}
          selection={[
            {
              key: "REPEATING_RULE",
              text: "Repeating Rule",
              value: "REPEATING_RULE",
            },
            {
              key: "IMPORTANT_DATE_RULE",
              text: "Important Client Date Rule",
              value: "IMPORTANT_DATE_RULE",
            },
            {
              key: "ADD_ATTRIBUTES",
              text: "Add or Remove Stream/Tag Rule",
              value: "ADD_ATTRIBUTES",
            },
            { key: "AGE_RULE", text: "Age Based Rule", value: "AGE_RULE" },
          ]}
          multiple={false}
          selected={selectedRule}
          onSelect={setSelectedRule}
        />
      </div>
      <div style={{ marginTop: "0.5em", marginBottom: "0.5em" }}>
        <Button
          compact
          positive
          content="Configure"
          onClick={() => {
            onConfigure(selectedRule);
            setAdding(false);
          }}
        />
        <Icon
          name="delete"
          color="grey"
          size="large"
          link
          onClick={() => {
            setAdding(false);
          }}
        />
      </div>
    </React.Fragment>
  );
}

RuleAdd.propTypes = {
  onConfigure: PropTypes.func.isRequired,
};

export default RuleAdd;
