import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Divider } from "semantic-ui-react";

import Access from "components/Access";

import CancelAccount from "./components/CancelAccount";
import HubInformationForm from "./components/HubInformationForm";
import PracticeInformationForm from "./components/PracticeInformationForm";

class AccountSettings extends React.Component {
  static propTypes = {
    advisor: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  };

  render() {
    const { advisor } = this.props;
    return (
      <React.Fragment>
        <PracticeInformationForm advisor={advisor} />
        <HubInformationForm advisor={advisor} />
        <Divider style={{ marginTop: "2em", marginBottom: "1em" }} />
        <Access required={["Administrator"]}>
          <CancelAccount />
        </Access>
        <div style={{ height: "100px" }} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    advisor: state.hubly.data.advisor,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountSettings);
