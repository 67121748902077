import clone from "rfdc";

const deepClone = clone();

export const initialState = {};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_WORKFLOWS":
      return {
        ...action.workflows,
      };
    case "SET_WORKFLOW": {
      const newState = { ...state };
      newState[action.workflow.id] = action.workflow;
      return newState;
    }
    case "ADD_WORKFLOW_CLIENT_WORKFLOW":
      try {
        const newState = { ...state };
        newState[action.workflowId] = { ...newState[action.workflowId] };
        newState[action.workflowId].clients.push(action.clientWorkflowId);
        return newState;
      } catch (e) {
        console.error(e);
        return state;
      }
    case "DELETE_WORKFLOW_CLIENT_WORKFLOW":
      try {
        const newState = { ...state };
        newState[action.workflowId].clients.filter((c) => {
          return c.id !== action.clientWorkflowId;
        });
        return newState;
      } catch (e) {
        console.error(e);
        return state;
      }
    case "ADD_WORKFLOW":
      try {
        return {
          ...state,
          [action.workflow.id]: action.workflow,
        };
      } catch (e) {
        console.error(e);
        return state;
      }
    case "EDIT_WORKFLOW":
      try {
        const newState = { ...state };
        newState[action.workflow.id] = action.workflow;
        return newState;
      } catch (e) {
        console.error(e);
        return state;
      }
    case "DELETE_WORKFLOW":
      try {
        const newState = { ...state };
        delete newState[action.workflowId];
        return newState;
      } catch (e) {
        console.error(e);
        return state;
      }
    case "ADD_WORKFLOW_TASK":
      try {
        const newState = { ...state };
        const workflow = deepClone(newState[action.task.workflowId]);
        workflow.tasks.push(action.task);
        newState[action.task.workflowId] = workflow;
        return newState;
      } catch (error) {
        console.error(error);
        return state;
      }
    case "EDIT_WORKFLOW_TASK":
      try {
        const newState = { ...state };
        const workflow = deepClone(newState[action.task.workflowId]);
        Object.assign(
          workflow.tasks.find((t) => {
            return t.id === action.task.id;
          }),
          action.task
        );
        newState[action.task.workflowId] = workflow;
        return newState;
      } catch (error) {
        console.error(error);
        return state;
      }
    case "DELETE_WORKFLOW_TASK":
      try {
        const newState = { ...state };
        const workflow = deepClone(newState[action.workflowId]);
        workflow.tasks = workflow.tasks.filter((t) => {
          return t.id !== action.taskId;
        });
        newState[action.workflowId] = workflow;
        return newState;
      } catch (error) {
        console.error(error);
        return state;
      }
    case "BULK_UPDATE_WORKFLOWS":
      try {
        const newState = { ...state };
        action.workflows.forEach((wf) => {
          newState[wf.id] = wf;
        });
        return newState;
      } catch (e) {
        console.error(e);
        return state;
      }
    case "BULK_UPDATE_WORKFLOW_TASKS":
      try {
        const newState = { ...state };
        const workflow = deepClone(newState[action.tasks[0].workflowId]);
        workflow.tasks = action.tasks;
        newState[action.tasks[0].workflowId] = workflow;
        return newState;
      } catch (e) {
        console.error(e);
        return state;
      }
    default:
      return state;
  }
};

export default reducer;
