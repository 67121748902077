import React from "react";
import ReactQuill from "react-quill";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { datadogRum } from "@datadog/browser-rum";
import { renderToStaticMarkup } from "@hot-loader/react-dom/server";
import getMiliSecondDuration from "analytics/helper";
import parse from "html-react-parser";
import { Button, Header, Icon, Segment } from "semantic-ui-react";

import { SetClientWorkflow } from "data/hub/clientWorkflows/actions";
import { AddComment } from "data/libs/comments";

import { SetAlert } from "components/Alerts/actions";
import Expandable from "components/Expandable";
import { PrivacyModeContext } from "components/PrivacyMode/Context";
import { SetCommentTaskId } from "components/Task/actions";

function AutoFocusEditor(props) {
  const reactQuillRef = React.useRef();

  React.useEffect(() => {
    reactQuillRef.current.getEditor().focus();
  }, [reactQuillRef]);

  return (
    <ReactQuill
      {...props}
      ref={(el) => {
        reactQuillRef.current = el;
      }}
    />
  );
}

class NewComment extends React.Component {
  static contextType = PrivacyModeContext;

  static defaultProps = {
    onSubmitComment: () => {},
  };

  static propTypes = {
    advisor: PropTypes.object.isRequired,
    selectedHub: PropTypes.object.isRequired,
    client: PropTypes.object.isRequired,
    clientWorkflowId: PropTypes.string.isRequired,
    clientWorkflows: PropTypes.object.isRequired,
    externalId: PropTypes.string.isRequired,
    hub: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
    setAlert: PropTypes.func.isRequired,
    setCommentTaskId: PropTypes.func.isRequired,
    taskId: PropTypes.string.isRequired,
    onSubmitComment: PropTypes.func,
    setClientWorkflow: PropTypes.func.isRequired,
  };

  state = {
    body: "",
    adding: false,
    loading: false,
    addCommentTime: 0,
  };

  modules = {
    clipboard: {
      matchVisual: false,
    },
  };

  handleBodyHTML = (content) => {
    this.setState({
      body: content,
    });
  };

  setAddingOpen = (open) => {
    this.setState({
      adding: open,
      body: "",
    });
  };

  removeTaskId = () => {
    const { setCommentTaskId } = this.props;
    setCommentTaskId("");
  };

  updateTaskState = (comment) => {
    const { taskId, clientWorkflowId, clientWorkflows, setClientWorkflow } =
      this.props;
    const clientWorkflow = clientWorkflows[clientWorkflowId];
    if (clientWorkflow) {
      const workflowCopy = { ...clientWorkflow };

      const foundTaskIndex = workflowCopy.tasks.findIndex((task) => {
        return task.id === taskId;
      });
      if (foundTaskIndex < 0) {
        console.warn("EditTaskDetails, Task ID ", taskId, " does not exist");
        return;
      }
      workflowCopy.tasks[foundTaskIndex].comments.push(comment);
      setClientWorkflow(workflowCopy);
    }
  };

  addComment = () => {
    this.setState({
      addCommentTime: new Date().getTime(),
    });
    const { advisor, client, setAlert, taskId, selectedHub, onSubmitComment } =
      this.props;
    const { body } = this.state; // comment content

    // Include the advisor name in comments because they don't show up otherwise
    const isRedtail =
      advisor.integrations.find((i) => {
        return i.hubId === selectedHub.id;
      })?.type === "redtail";
    const commentHeader = isRedtail
      ? `<b>${advisor.firstName && advisor.firstName} ${
          advisor.lastName && advisor.lastName
        } Wrote:</b><br/><br/>`
      : "";
    const commentBody = commentHeader + body;

    const parsedBody = parse(commentBody, {
      replace: (domNode) => {
        // Add // to links without http or https at start
        if (domNode.attribs && domNode.attribs.href) {
          let url = domNode.attribs.href;
          if (
            !domNode.attribs.href.startsWith("http://") &&
            !domNode.attribs.href.startsWith("https://") &&
            !domNode.attribs.href.startsWith("//") &&
            !domNode.attribs.href.startsWith("mailto:")
          ) {
            url = `//${domNode.attribs.href}`;
          }
          return React.createElement(
            "a",
            { href: url, target: "_blank" },
            domNode.children.length > 0 ? domNode.children[0].data : url
          );
        }
        return domNode;
      },
    });

    const clientExternalId = (client.externalId || "").toString();
    this.setState({ loading: true });
    const request = {
      creator: advisor.externalId,
      content: renderToStaticMarkup(parsedBody),
      clientExternalId: clientExternalId,
      taskId: taskId,
      clientId: client.id,
    };

    AddComment(request)
      .then((response) => {
        this.updateTaskState(response);
        this.setAddingOpen(false);
        this.removeTaskId();
        onSubmitComment(response);
      })
      .catch(() => {
        setAlert({ type: "error", text: "Failed to add comment" });
      })
      .finally(() => {
        datadogRum.addAction("hubly_timespent_newcomment_add_comment", {
          duration: getMiliSecondDuration(this.state.addCommentTime),
        });
        this.setState({
          loading: false,
          addCommentTime: 0,
        });
      });
  };

  render() {
    const { piiMask } = this.context;
    const { body, adding, loading } = this.state;
    const { clientWorkflowId, clientWorkflows, taskId } = this.props;

    let foundTask = {};
    // Hack until we also do separate tasks from client workflows, need to pass in client workflow IDs
    if (taskId && clientWorkflowId) {
      const foundClientWorkflow = clientWorkflows[clientWorkflowId];
      if (foundClientWorkflow) {
        foundTask = clientWorkflows[clientWorkflowId].tasks.find((task) => {
          return task.id === taskId;
        });
      }
    }

    return (
      <React.Fragment>
        {adding || foundTask.title ? (
          <div style={{ marginLeft: "3em", marginTop: "1em" }}>
            <Header as="h5" style={{ marginBottom: "0.25em" }}>
              Add New Comment
            </Header>
            <AutoFocusEditor
              className={piiMask("fs-block dd-privacy-mask")}
              data-test="comment-input"
              name="body"
              value={body}
              onChange={(content) => {
                this.handleBodyHTML(content);
              }}
              modules={this.modules}
              style={!foundTask.title ? { marginBottom: "0.5em" } : {}}
              readOnly={loading}
            />
            {foundTask.title && (
              // Hacking in the marginTop since the quill settings all require custom CSS, so it appears to not
              // have a rounded radius for the bottom by overlapping with a negative margin and we'll most likely
              // be redoing most of this with the CSS upgrade
              <Segment
                style={{
                  margin: "-0.5em 0 0.5em 0",
                  border: "1px solid #ccc",
                  borderRadius: "0 0 5 5",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexWrap: "nowrap",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ display: "flex", flexWrap: "nowrap" }}>
                    <div style={{ marginTop: "0.25em" }}>
                      <Icon name="tasks" />
                    </div>
                    <div
                      style={{ marginTop: "0.4em", lineHeight: "1.14285714em" }}
                    >
                      <Expandable lines={1}>{foundTask.title}</Expandable>
                    </div>
                    <div style={{ marginLeft: "0.5em" }}>
                      <Button
                        data-test="delete-task-link-to-comment"
                        disabled={loading}
                        basic
                        size="mini"
                        icon="delete"
                        onClick={() => {
                          this.setAddingOpen(true);
                          this.removeTaskId();
                        }}
                      />
                    </div>
                  </div>
                </div>
              </Segment>
            )}
            <Button
              data-test="add-comment-button"
              loading={loading}
              disabled={loading}
              positive
              content="Add"
              onClick={this.addComment}
            />
            <Icon
              data-test="close-add-comment"
              disabled={loading}
              link
              size="large"
              color="grey"
              name="delete"
              onClick={() => {
                this.setAddingOpen(false);
                this.removeTaskId();
              }}
            />
          </div>
        ) : (
          <span>
            <Icon
              data-test="add-comment-icon"
              className="grey_on_hover_circle"
              link
              name="plus"
              onClick={() => {
                this.setAddingOpen(true);
              }}
              style={{ float: "right" }}
              title="Add Comment"
            />
          </span>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    advisor: state.hubly.data.advisor,
    client: state.hubly.data.hub.clients.activeClients[ownProps.clientId],
    clientWorkflowId: state.hubly.task.clientWorkflowId,
    clientWorkflows: state.hubly.data.hub.clientWorkflows,
    comments: state.hubly.data.hub.clientCard.comments,
    taskId: state.hubly.task.selectedTaskIdForComment,
    selectedHub: state.hubly.data.hub.selected.hub,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAlert: (alert) => {
      dispatch(SetAlert(alert));
    },
    setCommentTaskId: (taskId) => {
      dispatch(SetCommentTaskId(taskId));
    },
    setClientWorkflow: (workflows) => {
      dispatch(SetClientWorkflow(workflows));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewComment);
