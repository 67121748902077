import React from "react";

export const useDelay = (delayInMilliseconds = 100) => {
  const ref = React.useRef();

  React.useEffect(() => {
    return () => {
      if (ref.current) clearTimeout(ref.current);
    };
  }, []);

  return (callback) => {
    if (ref.current) clearTimeout(ref.current);
    ref.current = setTimeout(callback, delayInMilliseconds);
  };
};

export default useDelay;
