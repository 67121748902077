import React from "react";
import PropTypes from "prop-types";

import { Loader } from "semantic-ui-react";

export class Spinner extends React.Component {
  static defaultProps = {
    inverted: false,
    size: "small",
    style: {},
    children: null,
  };

  static propTypes = {
    active: PropTypes.bool.isRequired,
    inverted: PropTypes.bool,
    size: PropTypes.string,
    style: PropTypes.object,
    children: PropTypes.node,
  };

  render() {
    const { active, children, inverted, size, style } = this.props;
    return (
      <Loader
        style={style}
        active={active}
        inline
        inverted={inverted}
        size={size}
      >
        {children}
      </Loader>
    );
  }
}

export default Spinner;
