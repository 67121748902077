import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

import Modal from "components/Modal";
import MultipleStepsWrapper from "components/MultipleStepsWrapper";

import useAddUser from "./hooks/useAddUser";
import useQueryNotInvitedUsers from "./hooks/useQueryNotInvitedUsers";
import NewUserInfoPage from "./components/NewUserInfoPage";
import SubscriptionInformationPage from "./components/SubscriptionInformationPage";

const useStyles = makeStyles({
  modal: {
    "&": {
      minHeight: "500px",
      height: "80%",
    },
    "&.modal .content": {
      display: "flex",
      flexDirection: "column",
      height: "80%",
    },
    "&.modal form": {
      flex: "1",
      height: "80%",
    },
  },
});
function AddUserModal({ isModalOpen, onSubmitCb, onClose }) {
  const classes = useStyles();
  const {
    onSubmit,
    loading: submitting,
    userInfos,
    setUserInfos,
  } = useAddUser();
  const { userList, reloadNotInvitedUsers } = useQueryNotInvitedUsers();

  useEffect(() => {
    if (isModalOpen) {
      reloadNotInvitedUsers();
    } else {
      setUserInfos([]);
    }
  }, [isModalOpen, reloadNotInvitedUsers, setUserInfos]);

  useEffect(() => {
    const emptyUserInfo = {
      firstName: "",
      lastName: "",
      email: "",
      roleId: "",
      inviteNow: false,
    };
    if (userList.length > 0) {
      setUserInfos([...userList, emptyUserInfo]);
    } else {
      setUserInfos([emptyUserInfo]);
    }
  }, [userList, setUserInfos]);

  return (
    <Modal
      data-test="add-users-modal"
      className={classes.modal}
      size="large"
      isModalOpen={isModalOpen}
      onClose={() => {
        onClose();
      }}
    >
      <Modal.Header onClose={onClose}>Invite Users</Modal.Header>
      <MultipleStepsWrapper>
        <NewUserInfoPage
          defaultNewUserInfo={userInfos}
          onChange={setUserInfos}
          onClose={onClose}
        />
        <SubscriptionInformationPage
          inviteCount={
            userInfos.filter((userInfo) => {
              return userInfo.inviteNow;
            }).length
          }
          loading={submitting}
          onSubmit={async () => {
            await onSubmit();
            onSubmitCb();
          }}
          onClose={onClose}
        />
      </MultipleStepsWrapper>
    </Modal>
  );
}

AddUserModal.propTypes = {
  isModalOpen: PropTypes.bool,
  onSubmitCb: PropTypes.func,
  onClose: PropTypes.func,
};

AddUserModal.defaultProps = {
  isModalOpen: false,
  onSubmitCb: () => {},
  onClose: () => {},
};

export default AddUserModal;
