import React, { Component } from "react";
import connect from "react-redux/es/connect/connect";
import { withRouter } from "react-router";
import PropTypes from "prop-types";

import { Dropdown, Header, Icon, Popup } from "semantic-ui-react";

import { SetMenuFilters } from "data/filters/actions";

const maxDropdownHeight = 400;
const reminderOptions = [
  {
    color: "#ed6e6e",
    icon: "circle",
    key: "Overdue",
    text: "Overdue Reminders",
    type: "reminder",
    value: "Overdue",
  },
  {
    color: "#f4cf65",
    icon: "circle",
    key: "Today",
    text: "Reminders Due Today",
    type: "reminder",
    value: "Today",
  },
  {
    color: "#49b676",
    icon: "circle",
    key: "Upcoming",
    text: "Upcoming Reminders",
    type: "reminder",
    value: "Upcoming",
  },
  {
    color: "#c4c4c4",
    icon: "clock icon",
    key: "ThisWeek",
    text: "Reminders Due This Week",
    type: "reminder",
    value: "ThisWeek",
  },
  {
    color: "#c4c4c4",
    icon: "clock icon",
    key: "NextWeek",
    text: "Reminders Due Next Week",
    type: "reminder",
    value: "NextWeek",
  },
  {
    color: "#c4c4c4",
    icon: "bell slash",
    key: "NoReminder",
    text: "No Reminders Set",
    type: "reminder",
    value: "NoReminder",
  },
  {
    color: "#c4c4c4",
    icon: "user",
    key: "WaitingOnClient",
    text: "Waiting On Client",
    type: "workflows",
    value: "WaitingOnClient",
  },
  {
    color: "#c4c4c4",
    icon: "user",
    key: "NotWaitingOnClient",
    text: "Not Waiting On Client",
    type: "workflows",
    value: "NotWaitingOnClient",
  },
];

class ReminderFilters extends Component {
  static propTypes = {
    filters: PropTypes.object.isRequired,
    setFilters: PropTypes.func.isRequired,
  };

  state = {
    searchInput: "",
    selectedIndex: 0,
  };

  reminderTypeSelected = (type) => {
    const { filters, setFilters } = this.props;
    const filtersCopy = { ...filters };
    const filteredReminderTypes = filtersCopy ? filtersCopy.reminderTypes : [];
    filteredReminderTypes.push(type);
    setFilters(filtersCopy);
    this.setState({ searchInput: "", selectedIndex: 0 });
  };

  scrollMenu = () => {
    // handle scrolling when navigating by key presses
    const menu = document.getElementById("filtering_dropdown_menu");
    const selectedItem = document.getElementById(
      "filtering_dropdown_menu_selected_item"
    );
    if (!selectedItem) return;
    const menuY = menu.getBoundingClientRect().y;
    const selectedY = selectedItem.getBoundingClientRect().y;
    const selectedHeight = selectedItem.getBoundingClientRect().height;
    const selectedDist = selectedY - menuY;
    if (selectedDist + selectedHeight > maxDropdownHeight) {
      menu.scrollTop += selectedDist - maxDropdownHeight + selectedHeight;
    } else if (selectedDist < 0) {
      menu.scrollTop += selectedDist;
    }
  };

  handleKeyDown = (e) => {
    // handle key down and enter key presses
    const { selectedIndex } = this.state;
    const reminderTypes = this.reminderTypesDropdownList;
    if (reminderTypes.length < 1) return;
    if (e.key === "Enter") {
      this.reminderTypeSelected(reminderTypes[selectedIndex].value);
    } else if (e.key === "ArrowUp") {
      this.setState((state) => {
        return { selectedIndex: Math.max(selectedIndex - 1, 0) };
      }, this.scrollMenu);
    } else if (e.key === "ArrowDown") {
      this.setState((state) => {
        return { selectedIndex: (selectedIndex + 1) % reminderTypes.length };
      }, this.scrollMenu);
    }
  };

  handleTyping = (e) => {
    this.setState({ searchInput: e.target.value, selectedIndex: 0 });
  };

  dropdownOnChange = (data) => {
    const { filters, setFilters } = this.props;
    const filtersCopy = { ...filters };
    filtersCopy.reminderTypes = data.value;
    setFilters(filtersCopy);
  };

  clearFilter = () => {
    const { filters, setFilters } = this.props;
    const filtersCopy = { ...filters };
    filtersCopy.reminderTypes = [];
    setFilters(filtersCopy);
  };

  filterByInput = (list) => {
    const { searchInput } = this.state;
    const filterText = searchInput.toLowerCase();
    return list.filter((item) => {
      const itemText = item.text.toLowerCase();
      return itemText.includes(filterText);
    });
  };

  selectorMarkup = () => {
    const { searchInput, selectedIndex } = this.state;
    const { filters } = this.props;
    const { reminderTypes, filterWaitingOnClient } = filters;
    const options = reminderOptions;
    const filteredOptions = options.filter(({ key, type }) => {
      switch (type) {
        case "workflows":
          return key.includes("WaitingOnClient")
            ? !filterWaitingOnClient.isFilterOn
            : true;
        default:
          return !reminderTypes.find((r) => {
            return r === key;
          });
      }
    });
    this.reminderTypesDropdownList = this.filterByInput(filteredOptions);
    return (
      <div
        style={{
          padding: "1px 1em",
          height: "max-content",
        }}
      >
        <div
          style={{
            width: "400px",
            maxHeight: "400px",
          }}
        >
          <Dropdown
            data-test="filter-menu-reminders"
            className="selection" // Can't have children and use selection prop, so to get styling just add the className instead
            placeholder="Select workflow status"
            fluid
            deburr
            search
            onChange={(event, data) => {
              this.dropdownOnChange(data);
            }}
            searchInput={{ value: searchInput }}
            onClose={() => {
              this.setState({ searchInput: "" });
            }}
            onKeyDown={this.handleKeyDown}
            onSearchChange={this.handleTyping}
            value={reminderTypes}
            options={options}
            style={{
              marginTop: "1px",
              position: "relative",
              width: "calc(100%)",
            }}
            renderLabel={(item, index, object) => {
              object.content = item.text;
              object.style = { backgroundColor: item.color, color: "white" };
              return object;
            }}
            multiple
            selection
          >
            <Dropdown.Menu
              id="filtering_dropdown_menu"
              style={{
                paddingBottom: "1em",
                maxHeight: `${maxDropdownHeight}px`,
              }}
            >
              <Dropdown.Header
                content="Workflow Status"
                style={{ fontSize: "10pt" }}
              />
              {this.reminderTypesDropdownList.length > 0 ? (
                this.reminderTypesDropdownList.map((reminderType, index) => {
                  const selected = index === selectedIndex;
                  return (
                    <Dropdown.Item
                      icon={
                        <Icon
                          name={reminderType.icon || "circle"}
                          style={{ color: reminderType.color }}
                        />
                      }
                      key={reminderType.id}
                      text={reminderType.text}
                      onClick={() => {
                        this.reminderTypeSelected(reminderType.value);
                      }}
                      selected={selected}
                      id={
                        selected ? "filtering_dropdown_menu_selected_item" : ""
                      }
                    />
                  );
                })
              ) : (
                <span style={{ marginLeft: "2em", color: "grey" }}>
                  No matching reminder types
                </span>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div>
            <Header as="h4" style={{ display: "inline", marginRight: "0.5em" }}>
              Status
            </Header>
          </div>
          <div style={{ marginRight: "0.5em" }} />
          <div />
        </div>
        {this.selectorMarkup()}
        <div>
          <Popup
            content="Clear"
            on="hover"
            position="top center"
            trigger={
              <Icon
                data-test="filter-menu-reminders-clear"
                name="delete"
                onClick={this.clearFilter}
                link
                color="grey"
              />
            }
          />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    hub: state.hubly.data.hub.selected.hub,
    filters: state.hubly.data.filters,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setFilters: (filters) => {
      dispatch(SetMenuFilters(filters));
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ReminderFilters)
);
