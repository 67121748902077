import React from "react";
import PropTypes from "prop-types";

import { FeatureExperimentContext } from "./Context";

const getOfElse = (map, key, func) => {
  return map.has(key) ? map.get(key) : func(key);
};

const EMPTY_MAP = new Map();
const getOrEmpty = (map, key) => {
  return getOfElse(map, key, () => {
    return EMPTY_MAP;
  });
};

function getExperiment(featureKnowledge, featureId) {
  const feature = getOrEmpty(featureKnowledge, featureId);

  return {
    isEnabledFor: (contextId, enabledOnMissing) => {
      const featureForContext = feature.get(contextId) || feature.get("others");
      return featureForContext
        ? featureForContext.toLowerCase() === "enabled"
        : enabledOnMissing;
    },
  };
}

export function FeatureExperiment({
  id,
  contextId,
  enabledOnMissing,
  children,
}) {
  return (
    <FeatureExperimentContext.Consumer>
      {(featureKnowledge) => {
        return getExperiment(featureKnowledge, id).isEnabledFor(
          contextId,
          enabledOnMissing
        )
          ? children
          : null;
      }}
    </FeatureExperimentContext.Consumer>
  );
}

FeatureExperiment.propTypes = {
  id: PropTypes.string,
  contextId: PropTypes.string,
  enabledOnMissing: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

FeatureExperiment.defaultProps = {
  id: "",
  contextId: undefined,
  enabledOnMissing: false,
};

export default FeatureExperiment;
