import React from "react";
import PropTypes from "prop-types";

import Popper from "components/Popper";

export function Colorize({ content, color }) {
  return (
    <Popper
      data-test="colorize-popup"
      on={["hover"]}
      position="top left"
      trigger={
        <div
          data-test="colorize"
          style={{
            width: "6px",
            height: "18px",
            backgroundColor: color,
            position: "absolute",
            left: "0px",
            transform: "translateY(3px)",
          }}
        />
      }
    >
      {content}
    </Popper>
  );
}

Colorize.propTypes = {
  content: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};

Colorize.defaultProps = {};

export default Colorize;
