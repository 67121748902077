import { SetActiveClients } from "data/hub/clients/actions";
import { SetClientWorkflow } from "data/hub/clientWorkflows/actions";
import { SetWorkflow } from "data/hub/workflows/actions";
import { GetClient } from "data/libs/clients";
import { EditClientWorkflow } from "data/libs/clientWorkflows";
import { InsertReminder, SetReminders } from "data/reminders/actions";

export const AddClientToWorkflow = (clientWorkflow) => {
  return (dispatch, getState) => {
    const clientWorkflowCopy = { ...clientWorkflow };
    dispatch(SetClientWorkflow(clientWorkflowCopy));

    const clientsCopy = Object.values(
      getState().hubly.data.hub.clients.activeClients
    );
    const foundClient = clientsCopy.find((client) => {
      return client.id === clientWorkflow.clientId;
    });
    if (!foundClient) {
      GetClient(clientWorkflow.clientId).then((response) => {
        clientsCopy.push(response);
        dispatch(SetActiveClients(clientsCopy));
      });
    } else if (clientWorkflow.id) {
      foundClient.workflows.push(clientWorkflow.id);
      dispatch(SetActiveClients(clientsCopy));
    }

    const workflowCopy = {
      ...getState().hubly.data.hub.workflows[clientWorkflow.workflowId],
    };
    workflowCopy.clients.push(clientWorkflow.id);
    dispatch(SetWorkflow(workflowCopy));

    const { reminders } = getState().hubly.data;
    const remindersCopy = reminders.slice();
    (clientWorkflow.tasks || []).forEach((task) => {
      task.reminders.forEach((reminder) => {
        InsertReminder(reminder, remindersCopy);
      });
    });
    dispatch(SetReminders(remindersCopy));
  };
};

export const HideClientWorkflow = (clientWorkflowId) => {
  return (dispatch, getState) => {
    const remindersCopy = [...getState().hubly.data.reminders];

    const foundClientWorkflow = {
      ...getState().hubly.data.hub.clientWorkflows[clientWorkflowId],
    };

    // Want to remove from workflows, as it's no longer shown
    const workflowCopy = {
      ...getState().hubly.data.hub.workflows[foundClientWorkflow.workflowId],
    };
    workflowCopy.clients = workflowCopy.clients.filter((id) => {
      return id !== clientWorkflowId;
    });

    // Want to hide in the client objects as it will still appear as a completed workflow in the client card
    foundClientWorkflow.completed = true;
    foundClientWorkflow.archived = true;
    foundClientWorkflow.isHidden = true;

    // Want to dismiss all reminders for this client workflow
    (foundClientWorkflow.tasks || []).forEach((task) => {
      remindersCopy.forEach((reminder) => {
        if (task.id === reminder.taskId) {
          reminder.dismissed = true;
          reminder.completedAt = new Date().toISOString();
        }
      });
    });
    dispatch(SetReminders(remindersCopy));
    dispatch(SetWorkflow(workflowCopy));
    dispatch(SetClientWorkflow(foundClientWorkflow));
  };
};

// marks a client workflow as not being new once the client card is opened
export const MakeNotNew = (clientWorkflowId) => {
  return async (dispatch, getState) => {
    try {
      await EditClientWorkflow(clientWorkflowId, { isNew: false });
      const clientWorkflow = {
        ...getState().hubly.data.hub.clientWorkflows[clientWorkflowId],
      };
      clientWorkflow.isNew = false;
      dispatch(SetClientWorkflow(clientWorkflow));
    } catch (error) {
      console.error(error);
    }
  };
};
