import { useAssignmentStrategyAction, useGetAssignmentStrategies } from ".";

export function useAssignmentStrategy() {
  const {
    loading,
    data: { assignmentStrategies = [], hub = {}, me = {} },
    reloadData,
  } = useGetAssignmentStrategies();
  const { updating, updateAssignmentStrategy } =
    useAssignmentStrategyAction(reloadData);

  return {
    loading: loading || updating,
    selected: hub.assignmentStrategy,
    strategies: assignmentStrategies,
    isAdmin: me.isAdmin,
    updateAssignmentStrategy: (strategy) => {
      return updateAssignmentStrategy(hub, strategy);
    },
  };
}

export default useAssignmentStrategy;
