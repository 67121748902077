import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  modalBody: {
    position: "relative",
  },
  form: {
    position: "relative",
    paddingBottom: "36px",
  },
  table: {
    "&": {
      minHeight: "150px",
      height: "calc(100% - 1.5rem)",
      marginBottom: "1.5rem",
    },
    "& .MuiTableCell-head": {
      zIndex: "20",
    },
  },
  cell: {
    position: "relative",
  },
  addButton: {
    position: "absolute",
    bottom: 0,
    left: 0,
  },
  subscriptionInfoSection: {
    position: "absolute",
    bottom: 0,
    right: 0,
    display: "inline-block",
  },
});
export default useStyles;
