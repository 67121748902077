import React, { useEffect, useState } from "react";
import connect from "react-redux/es/connect/connect";
import { withRouter } from "react-router";
import PropTypes from "prop-types";

import { datadogRum } from "@datadog/browser-rum";
import { getMilliseconds } from "date-fns";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Button, Header, Icon, Input, Popup } from "semantic-ui-react";

import {
  HideShowAllWorkflows,
  HideShowProcess,
  HideShowWorkflow,
  OnEnterLoadWorkFlows,
  ShowActiveWorkflows,
} from "data/filters/actions";
import { sortWorkflow } from "data/hub/workflows/actions";
import makeGetFilteredReminders from "data/reminders/selector";

import { SetAlert } from "components/Alerts/actions";
import { SetConfirmationModal } from "components/ConfirmationModal/actions";
import DragDropList from "components/DragDropList";

import ConfigureProcessModal from "./components/ConfigureProcessModal";
import HideShowTile from "./components/HideShowTile";
import ProcessTile from "./components/ProcessTile";
import AddClientDropdown from "../../ClientTile/components/AddClientDropdown";
import {
  RemoveProcess,
  UpdateProcessOrder,
  UpdateWorkflowOrder,
} from "./actions";
import {
  extractWF,
  filterNameByKeyword,
  getCombinedWorkflows,
  getNumberOfClientFromWorkflow,
  isWorkflowHidden,
} from "./helpers";
import { selectProcessTileProps } from "./selector";

export function WorkflowFilters({ filters, workflows, processes, ...props }) {
  const [disabled, setDisabled] = useState(false);
  const [configureProcessModalOpen, setConfigureProcessModalOpen] =
    useState(false);
  const [popupOpen, setPopupOpen] = useState(false);
  const [showActiveLoading, setShowActiveLoading] = useState(false);
  const [hideShowLoading, setHideShowLoading] = useState(false);
  const [editedProcess, setEditedProcess] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [spentTimeOnWorkfilteOpen, setSpentTimeOnWorkfilteOpen] = useState(0);
  const [processTileState, setProcessTitleState] = useState({});
  const [hasUserPressedEnterKey, setHasUserPressedEnterKey] = useState(false);

  const enableOnEnterLoadWorkflows =
    useFlags()["on-enter-load-filtered-workflows"] || false;

  const onProcessTileStateUpdated = (processId, state) => {
    setProcessTitleState({ ...processTileState, [processId]: state });
  };
  const [spentTimeWorkflowFilterOpen, setSpentTimeWorkflowFilterOpen] =
    useState(0);

  const createProcess = () => {
    setPopupOpen(false);
    setConfigureProcessModalOpen(true);
  };

  const editProcess = (process) => {
    setEditedProcess(process);
    setPopupOpen(false);
    setConfigureProcessModalOpen(true);
  };

  const toggleProcess = (process, active) => {
    const { hideShowProcess, setAlert } = props;
    hideShowProcess(process, active, (success) => {
      if (!success) {
        setAlert({ type: "error", text: "Failed to toggle process." });
      }
    });
  };

  const deleteProcess = (process) => {
    const { removeProcess, setAlert } = props;
    removeProcess(process.id, (success) => {
      if (!success) {
        setAlert({ type: "error", text: "Failed to delete process." });
      } else {
        setAlert({ type: "success", text: "Successfully deleted process." });
      }
    });
  };

  const onProcessReordered = ({
    items,
    previousOrderedItems,
    draggedItemIndex,
  }) => {
    const { setAlert, updateProcessOrder } = props;
    updateProcessOrder(
      {
        targetIndex: draggedItemIndex,
        processList: items,
        oldProcessList: previousOrderedItems,
      },
      (result) => {
        if (!result) {
          setAlert({ type: "error", text: "Failed to reorder process." });
        }
      }
    );
  };

  const confirmDeleteProcess = (process) => {
    const { setConfirmationModal } = props;
    const params = {
      title: "Delete Process",
      message: `This will delete "${process.name}". This cannot be undone.`,
      icon: "delete",
      buttons: [
        {
          text: "Cancel",
        },
        {
          text: "Delete",
          callBack: () => {
            deleteProcess(process);
          },
          color: "red",
        },
      ],
    };
    setConfirmationModal(params);
  };

  const hideShowAllCallback = () => {
    setDisabled(false);
    setHideShowLoading(false);
  };
  const showAll = () => {
    const { hideShowAllWorkflows } = props;
    if (disabled) return;
    setDisabled(true);
    setHideShowLoading(true);
    hideShowAllWorkflows(
      false,
      hideShowAllCallback,
      getCombinedWorkflows(processes, workflows)
    );
  };

  const hideAll = () => {
    const { hideShowAllWorkflows } = props;
    if (disabled) return;
    setDisabled(true);
    setHideShowLoading(true);
    hideShowAllWorkflows(
      true,
      hideShowAllCallback,
      getCombinedWorkflows(processes, workflows)
    );
  };

  const showActive = () => {
    const { showActiveWorkflows } = props;
    if (disabled) return;
    setShowActiveLoading(true);
    setDisabled(true);

    showActiveWorkflows(() => {
      setShowActiveLoading(false);
      setDisabled(false);
    }, getCombinedWorkflows(processes, workflows));
  };

  const toggleWorkflow = (workflow, hidden) => {
    const { hideShowWorkflow } = props;
    hideShowWorkflow(workflow, !hidden);
  };

  const onWorkflowReordered = ({
    items,
    previousOrderedItems,
    draggedItemIndex,
  }) => {
    const { setAlert, updateWorkflowOrder } = props;

    updateWorkflowOrder(
      {
        targetIndex: draggedItemIndex,
        workflowList: items,
        oldWorkflowList: previousOrderedItems,
      },
      (result) => {
        if (!result) {
          setAlert({ type: "error", text: "Failed to reorder workflow." });
        }
      }
    );
  };

  const getHideShowTileComponent = (workflow, dragHandleProps) => {
    const hidden = isWorkflowHidden(workflow, filters);
    return (
      <HideShowTile
        key={workflow.id}
        workflowName={workflow.name}
        totalTask={workflow.tasks.length}
        totalClient={getNumberOfClientFromWorkflow(workflow)}
        hidden={hidden}
        filteredKeyword={searchInput}
        processColor={workflow.process ? workflow.process.color : null}
        showRuleIndicator={
          workflow.workflowRules && workflow.workflowRules.length > 0
        }
        onClick={(isHidden) => {
          toggleWorkflow(workflow, isHidden);
        }}
        dragHandleProps={dragHandleProps}
      >
        <AddClientDropdown
          workflowId={workflow.id}
          opened
          style={{
            width: "250px",
            alignItems: "center",
          }}
          onClientAdded={(success) => {
            if (success && hidden) {
              toggleWorkflow(workflow, hidden);
            }
          }}
        />
      </HideShowTile>
    );
  };

  const workflowsLength = Object.keys(workflows).length;

  const workflowsWithNoProcess = Object.values(workflows)
    .filter((workflow) => {
      return (
        !workflow.process && filterNameByKeyword(workflow.name, searchInput)
      );
    })
    .sort(sortWorkflow);

  const filteredProcess = processes.filter((processTileProps) => {
    const { process, processWorkflows } = processTileProps;
    if (filterNameByKeyword(process.name, searchInput)) return true;

    // Process name did not match the search keyword, search the workflow name
    // for match
    return processWorkflows.some((workflow) => {
      return filterNameByKeyword(workflow.name, searchInput);
    });
  });

  const onMountHandler = () => {
    datadogRum.addAction(
      "hubly_timespent_workflowfilter_display_workflowprocesmenu",
      {
        duration: getMilliseconds(spentTimeOnWorkfilteOpen),
      }
    );
    setSpentTimeOnWorkfilteOpen(0);
  };
  const onOpenHandler = () => {
    setSpentTimeOnWorkfilteOpen(new Date().getTime());
    setPopupOpen(true);
    setEditedProcess(null);
    setSpentTimeWorkflowFilterOpen(new Date().getTime());
  };

  const onCloseHandler = (event) => {
    const legacyOnClose = () => {
      // If we're clicking in the nested dropdown, don't close the popup!
      if (
        event.target.closest("#hide-show-popup") ||
        event.target.closest("#add-client-popup") ||
        event.target.closest("div.ui.small.modal.visible.active")
      ) {
        return;
      }

      setPopupOpen(false);
      setEditedProcess(null);
    };

    const emitMetric = () => {
      datadogRum.addAction("hubly_timespent_workflowfilter_open_state", {
        duration: getMilliseconds(spentTimeWorkflowFilterOpen),
      });
      if (hasUserPressedEnterKey) {
        datadogRum.addAction("hubly_count_workflowfilter_enterkey_close_menu");
      }
      setSpentTimeWorkflowFilterOpen(0);
      setHasUserPressedEnterKey(false);
    };

    legacyOnClose();
    emitMetric();
  };
  const handleKeyPress = (e) => {
    if (!enableOnEnterLoadWorkflows) {
      return;
    }
    const { onEnterLoadWorkFlows } = props;

    const reduceCallBack = (accumulatedProcesses, { processWorkflows }) => {
      return extractWF(accumulatedProcesses, processWorkflows, searchInput);
    };

    if (e.key === "Enter" && searchInput !== "") {
      onEnterLoadWorkFlows(
        workflowsWithNoProcess.concat(
          filteredProcess.reduce(reduceCallBack, [])
        )
      );
      setHasUserPressedEnterKey(true);
    }
  };
  const numShown = filters.workflows
    ? filters.workflows.length
    : workflowsLength;
  const isShowAll = numShown < workflowsLength;

  useEffect(() => {
    if (searchInput.length > 0) {
      datadogRum.addAction("hubly_count_workflow_search");
    }
  }, [searchInput]);

  return (
    <React.Fragment>
      <div style={{ flex: "0 1 auto", margin: "0" }}>
        <Popup
          on="click"
          open={popupOpen}
          onOpen={onOpenHandler}
          onClose={onCloseHandler}
          onMount={onMountHandler}
          style={{
            paddingRight: 0,
            paddingLeft: 0,
            height: "auto",
            zIndex: "1000",
          }}
          trigger={
            <Button
              id="filter-workflow-button"
              minWidth={1051}
              basic
              primary={numShown > 0}
              style={{ margin: "0 0.5em 0 0", minWidth: "max-content" }}
            >
              <Icon name="list ul" />
              {numShown > 0
                ? `${numShown} Workflow${numShown !== 1 ? "s" : ""}`
                : "Workflows"}
            </Button>
          }
          data-test="workflow-filters-popup"
        >
          <div
            style={{
              maxHeight: "80vh",
              overflowY: "scroll",
              width: "600px",
              display: "flex",
              flexFlow: "row wrap",
              alignContent: "stretch",
              padding: "1px 1em",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                icon={isShowAll ? "eye" : "eye slash"}
                content={isShowAll ? "Show All" : "Hide All"}
                basic
                style={{
                  width: "32%",
                  boxSizing: "border-container",
                  margin: "0px",
                  marginRight: "1em",
                  marginBottom: "1em",
                }}
                onClick={isShowAll ? showAll : hideAll}
                disabled={disabled || workflowsLength === 0}
                loading={hideShowLoading}
              />
              <Button
                icon="check circle"
                content="Show Active"
                basic
                style={{
                  width: "32%",
                  boxSizing: "border-container",
                  margin: "0px",
                  marginRight: "1em",
                  marginBottom: "1em",
                }}
                onClick={showActive}
                disabled={disabled || workflowsLength === 0}
                loading={showActiveLoading}
              />
              <Button
                icon="plus"
                content="Create Process"
                basic
                style={{
                  width: "32%",
                  boxSizing: "border-container",
                  margin: "0px",
                  marginBottom: "1em",
                }}
                onClick={createProcess}
                disabled={disabled || workflowsLength === 0}
              />
            </div>
            {workflowsLength !== 0 && (
              <div
                style={{
                  width: "100%",
                  paddingBottom: "1em",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Input
                  icon="search"
                  iconPosition="left"
                  fluid
                  placeholder="Find a workflow or process"
                  autoFocus
                  onChange={(_, { value }) => {
                    setSearchInput(value);
                    setProcessTitleState({});
                  }}
                  onKeyPress={handleKeyPress}
                  style={{ flexGrow: 1 }}
                  value={searchInput}
                />
                <div style={{ marginLeft: "1em" }}>
                  <Popup
                    content="Clear"
                    on="hover"
                    position="top center"
                    trigger={
                      <Icon
                        name="delete"
                        color="grey"
                        link
                        style={{ fontSize: "12pt" }}
                        onClick={() => {
                          setSearchInput("");
                        }}
                      />
                    }
                  />
                </div>
              </div>
            )}

            <Header style={{ width: "100%", margin: "0 0 1em 0" }} as="h4">
              Processes
            </Header>
            {filteredProcess.length === 0 && (
              <div style={{ color: "grey", margin: "auto" }}>
                {searchInput !== ""
                  ? "No matching process found."
                  : "You have no process."}
              </div>
            )}
            <DragDropList
              id="dragDropProcessList"
              items={filteredProcess}
              onListReordered={onProcessReordered}
              isDropDisabled={searchInput !== ""}
              onBeforeCapture={() => {
                if (searchInput) {
                  setSearchInput("");
                }
              }}
            >
              {(processProps, processDragHandleProps) => {
                const { process } = processProps;
                return (
                  <ProcessTile
                    key={process.id}
                    isWorkflowListShown={(() => {
                      if (process.id in processTileState)
                        return processTileState[process.id].isWorkflowListShown;
                      return searchInput.length > 0;
                    })()}
                    filteredKeyword={searchInput}
                    editProcess={editProcess}
                    onWorkflowClicked={toggleWorkflow}
                    onWorkflowReordered={onWorkflowReordered}
                    onProcessTileStateUpdated={onProcessTileStateUpdated}
                    toggleProcess={toggleProcess}
                    deleteProcess={confirmDeleteProcess}
                    dragHandleProps={processDragHandleProps}
                    {...processProps}
                  >
                    {(workflow, workflowDragHandleProps) => {
                      return getHideShowTileComponent(
                        workflow,
                        workflowDragHandleProps
                      );
                    }}
                  </ProcessTile>
                );
              }}
            </DragDropList>

            <Header style={{ width: "100%", margin: "0em 0 1em 0" }} as="h4">
              Workflows
            </Header>

            {workflowsLength === 0 && (
              <div style={{ color: "grey", margin: "auto" }}>
                You have no workflows. Start by adding one!
              </div>
            )}
            {workflowsLength !== 0 && workflowsWithNoProcess.length === 0 && (
              <div style={{ color: "grey", margin: "auto" }}>
                {searchInput !== ""
                  ? "No matching workflows found."
                  : "All of your workflows have been added to the process."}
              </div>
            )}
            <div
              data-test="workflow-with-no-process-list"
              style={{ width: "100%" }}
            >
              <DragDropList
                id="dragDropWorkflowNoProcessList"
                items={workflowsWithNoProcess}
                onListReordered={onWorkflowReordered}
                isDropDisabled={searchInput !== ""}
                onBeforeCapture={() => {
                  if (searchInput) {
                    setSearchInput("");
                  }
                }}
              >
                {(workflow, dragHandleProps) => {
                  return getHideShowTileComponent(workflow, dragHandleProps);
                }}
              </DragDropList>
            </div>
            <div style={{ height: "0.5em", width: "100%" }} />
          </div>
        </Popup>
      </div>
      {configureProcessModalOpen && (
        <ConfigureProcessModal
          close={() => {
            setConfigureProcessModalOpen(false);
          }}
          workflows={workflows}
          process={editedProcess}
        />
      )}
    </React.Fragment>
  );
}

WorkflowFilters.propTypes = {
  filters: PropTypes.shape({
    workflows: PropTypes.array.isRequired,
  }).isRequired,
  hideShowAllWorkflows: PropTypes.func.isRequired,
  hideShowWorkflow: PropTypes.func.isRequired,
  showActiveWorkflows: PropTypes.func.isRequired,
  workflows: PropTypes.object.isRequired,
  processes: PropTypes.array.isRequired,
  hideShowProcess: PropTypes.func.isRequired,
  removeProcess: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  setConfirmationModal: PropTypes.func.isRequired,
  updateWorkflowOrder: PropTypes.func.isRequired,
  updateProcessOrder: PropTypes.func.isRequired,
  onEnterLoadWorkFlows: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const reminderFilter = makeGetFilteredReminders();
  const processesSelector = selectProcessTileProps();
  const reminders = reminderFilter(state);

  return {
    filters: state.hubly.data.filters,
    processes: processesSelector(state, { reminders }),
    workflows: state.hubly.data.hub.workflows,
    clientWorkflows: state.hubly.data.hub.clientWorkflows,
    reminders: reminders,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    hideShowAllWorkflows: (hidden, callback, orderedWorkflows) => {
      dispatch(HideShowAllWorkflows(hidden, callback, orderedWorkflows));
    },
    hideShowWorkflow: (workflow, hidden) => {
      dispatch(HideShowWorkflow(workflow, hidden));
    },
    showActiveWorkflows: (callback, orderedWorkflows) => {
      dispatch(ShowActiveWorkflows(callback, orderedWorkflows));
    },
    removeProcess: (id, callback) => {
      dispatch(RemoveProcess(id, callback));
    },
    updateProcessOrder: (props, callback) => {
      dispatch(UpdateProcessOrder(props, callback));
    },
    updateWorkflowOrder: (props, callback) => {
      dispatch(UpdateWorkflowOrder(props, callback));
    },
    hideShowProcess: (process, hidden, callback) => {
      dispatch(HideShowProcess(process, hidden, callback));
    },
    setAlert: (alert) => {
      dispatch(SetAlert(alert));
    },
    setConfirmationModal: (params) => {
      dispatch(SetConfirmationModal(params));
    },
    onEnterLoadWorkFlows: (params) => {
      dispatch(OnEnterLoadWorkFlows(params));
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(WorkflowFilters)
);
