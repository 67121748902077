import React from "react";
import PropTypes from "prop-types";

import Selector from "components/Selector";

import {
  getDefaultMeetingDateType,
  getImportantDateOptions,
} from "../../helper";

export function ImportantDateSelector({
  dateType,
  meetingDateTypeId,
  dateTypes,
  meetingDateTypes,
  onBeforeAfter,
  onChange,
}) {
  return (
    <div style={{ marginTop: "1em", marginBottom: "1em", width: "350px" }}>
      <div data-test="ruleModal-dropdown-importantDate">
        <Selector
          name="dateTypeId"
          dropdownProps={{
            loading: dateTypes.length === 0,
            disabled: dateTypes.length === 0,
            search: false,
            placeholder: "Important Date",
          }}
          selection={getImportantDateOptions(dateTypes, onBeforeAfter)}
          multiple={false}
          selected={dateType?.id || "meeting"}
          onSelect={(val) => {
            if (val === "meeting") {
              // meetingDateType must be assigned a default value when importantDate === meeting.
              onChange({
                dateType: { id: null, name: val },
                ...(!meetingDateTypeId
                  ? {
                      meetingDateTypeId:
                        getDefaultMeetingDateType(meetingDateTypes),
                    }
                  : {}),
              });
            } else {
              onChange({
                dateType: dateTypes.find(({ id }) => {
                  return id === val;
                }),
                meetingDateTypeId: undefined,
              });
            }
          }}
        />
      </div>
      {!dateType?.id && (
        <div
          data-test="ruleModal-dropdown-meetingDateType"
          style={{ marginTop: "1em" }}
        >
          <Selector
            name="meetingDateTypeId"
            dropdownProps={{
              placeholder: "Select Meeting Type",
              loading: meetingDateTypes.length === 0,
              disabled: meetingDateTypes.length === 0,
            }}
            selection={meetingDateTypes.map(({ id, name: dtName }) => {
              return { value: id, key: id, text: dtName };
            })}
            multiple={false}
            selected={
              meetingDateTypeId || getDefaultMeetingDateType(meetingDateTypes)
            }
            onSelect={(val) => {
              onChange({ meetingDateTypeId: val });
            }}
          />
        </div>
      )}
    </div>
  );
}
ImportantDateSelector.propTypes = {
  dateType: PropTypes.object,
  meetingDateTypeId: PropTypes.string,
  onBeforeAfter: PropTypes.string,
  meetingDateTypes: PropTypes.array,
  dateTypes: PropTypes.array,
  onChange: PropTypes.func.isRequired,
};

ImportantDateSelector.defaultProps = {
  dateType: null,
  meetingDateTypeId: null,
  meetingDateTypes: [],
  dateTypes: [],
  onBeforeAfter: "BEFORE",
};

export default ImportantDateSelector;
