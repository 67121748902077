import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import axios from "axios";
import { Button, Checkbox, Icon, List } from "semantic-ui-react";

import {
  CopyAttachmentToAllClients,
  CreateAttachment,
  DeleteAttachment,
  GetAttachments,
} from "data/libs/workflows";

import { SetAlert } from "components/Alerts/actions";
import { PrivacyModeContext } from "components/PrivacyMode/Context";
import { Spinner } from "components/Spinner";

class WorkflowAttachments extends React.Component {
  static contextType = PrivacyModeContext;

  static propTypes = {
    workflow: PropTypes.object.isRequired,
    setAlert: PropTypes.func.isRequired,
  };

  uploadRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      attachments: [],
      uploads: [],
      loading: true,
      deletingAttachments: [],
      onlyNew: false,
    };
    GetAttachments(props.workflow.id).then((response) => {
      this.setState({
        attachments: response.data.result.attachments,
        loading: false,
      });
    });
  }

  deleteAttachment = (attachmentName) => {
    // Handling delete here, rather than in Attachment, as we need to get the updated list after deleting with the
    //  proper number of attachments
    const { deletingAttachments } = this.state;
    const { workflow, setAlert } = this.props;
    // Handling delete attachments as an array, as more than one can be in the process of being deleted
    let deleteAttachmentsTmp = deletingAttachments;
    deleteAttachmentsTmp.push(attachmentName);
    this.setState((state) => {
      return { deletingAttachments: deleteAttachmentsTmp };
    });
    DeleteAttachment(workflow.id, { attachmentName: attachmentName })
      .then(() => {
        GetAttachments(workflow.id).then((response) => {
          deleteAttachmentsTmp = deletingAttachments;
          deleteAttachmentsTmp.splice(
            deleteAttachmentsTmp.indexOf(attachmentName),
            1
          );
          this.setState((state) => {
            return {
              attachments: response.data.result.attachments,
              deletingAttachments: deleteAttachmentsTmp,
            };
          });
        });
      })
      .catch((error) => {
        deleteAttachmentsTmp = deletingAttachments;
        deleteAttachmentsTmp.splice(
          deleteAttachmentsTmp.indexOf(attachmentName),
          1
        );
        this.setState((state) => {
          return {
            deletingAttachments: deleteAttachmentsTmp,
          };
        });
        console.warn(error);
        setAlert({
          type: "error",
          text: "Failed to delete workflow attachment",
        });
      });
  };

  applyToAllClients = (attachmentName) => {
    const { onlyNew } = this.state;
    const { workflow, setAlert } = this.props;
    if (onlyNew) return;
    const request = { attachmentName: attachmentName };
    CopyAttachmentToAllClients(workflow.id, request)
      .then(() => {
        GetAttachments(workflow.id);
      })
      .catch((error) => {
        console.warn(error);
        setAlert({
          type: "error",
          text: "Failed to copy workflow attachments",
        });
      });
  };

  addAttachment = (selectorFiles) => {
    console.warn("Adding attachment...");
    const { workflow, setAlert } = this.props;
    const uploads = [];
    Array.from(selectorFiles).forEach((file) => {
      uploads.push({
        name: file.name,
        uploadTime: file.lastModifiedDate,
        size: file.size,
      });
      this.setState({ uploads: uploads });
      if (file.size > 26214400) {
        setAlert({
          type: "warning",
          text: "Unable to upload file sizes 25MB or greater. Please verify file size before retrying.",
        });
        this.setState({ uploads: [] });
      } else {
        CreateAttachment(workflow.id, { fileName: file.name })
          .then((response) => {
            axios
              .put(response.data.url, file, {
                headers: { "Content-Type": file.type },
              })
              .then(() => {
                GetAttachments(workflow.id).then((getResponse) => {
                  this.setState({
                    attachments: getResponse.data.result.attachments,
                    uploads: [],
                  });
                  this.applyToAllClients(file.name);
                });
              });
          })
          .catch((error) => {
            console.warn(error);
          });
      }
    });
  };

  renderAttachment = (attachment, uploading) => {
    const { piiMask } = this.context;
    const { deletingAttachments } = this.state;
    const loading =
      uploading ||
      deletingAttachments.find((a) => {
        return a === attachment.name;
      }) != null;
    return (
      <List.Item
        className="grey_on_hover rounded p25"
        style={{ width: "100%", overflow: "hidden" }}
        key={`${attachment.id}`}
      >
        {!loading ? (
          <Icon
            color="grey"
            link
            name="times"
            style={{ float: "right", marginTop: "3px" }}
            onClick={() => {
              this.deleteAttachment(attachment.name);
            }}
          />
        ) : (
          <Spinner
            active
            style={{ float: "right", marginRight: "3px", marginTop: "3px" }}
            size="tiny"
          />
        )}
        <div
          className={piiMask("fs-block dd-privacy-mask")}
          style={{
            color: loading ? "grey" : "black",
            maxWidth: "calc(100% - 25px)",
            overflow: "hidden",
            lineHeight: "21px",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {attachment.name}
        </div>
      </List.Item>
    );
  };

  render() {
    const { loading, attachments, uploads, onlyNew } = this.state;
    return (
      <div>
        <div>
          {loading && (
            <div style={{ margin: "auto", width: "170px", color: "grey" }}>
              Loading Attachments
              <Spinner
                size="tiny"
                active={loading}
                style={{ marginLeft: "1em" }}
              />
            </div>
          )}
          {attachments.length === 0 && !loading && uploads.length === 0 && (
            <span style={{ color: "grey" }}>No attachments</span>
          )}
          <List style={{ marginTop: "0em", marginBottom: "0em" }}>
            {attachments.map((attachment) => {
              return this.renderAttachment(attachment, false);
            })}
            {uploads.map((attachment) => {
              return this.renderAttachment(attachment, true);
            })}
          </List>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div>
            <Button
              basic
              content="Add Attachment"
              compact
              onClick={() => {
                this.uploadRef.click();
              }}
            />
          </div>
          <div>
            <Checkbox
              style={{ paddingTop: "0.5em" }}
              checked={onlyNew}
              onChange={() => {
                this.setState((state) => {
                  return { onlyNew: !state.onlyNew };
                });
              }}
              label="Only add to new clients"
            />
            <input
              type="file"
              multiple
              hidden
              ref={(r) => {
                this.uploadRef = r;
              }}
              onChange={(e) => {
                this.addAttachment(e.target.files);
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    workflow: state.hubly.data.hub.workflows[ownProps.workflowId],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAlert: (alert) => {
      dispatch(SetAlert(alert));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkflowAttachments);
