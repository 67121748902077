import React, { useState } from "react";
import PropTypes from "prop-types";

import { Input } from "semantic-ui-react";

import Selector from "components/Selector";

import {
  getFrequencyOptions,
  getIntervalMultiplierFromBeforeAfter,
  getOnBeforeAfterValue,
  onBeforeAfterOptions,
} from "../../../../helper";
import { ImportantDateSelector } from "..";

export function DateSelector({ name, value, onChange, ...props }) {
  const { interval, frequency, dateType, meetingDateTypeId } = value || {};

  const [onBeforeAfter, setOnBeforeAfter] = useState(
    getOnBeforeAfterValue(interval)
  );

  const updateValue = (val) => {
    onChange(null, {
      name: name,
      value: {
        ...value,
        ...val,
      },
    });
  };

  return (
    <React.Fragment>
      {onBeforeAfter !== "ON" && (
        <React.Fragment>
          <div style={{ marginRight: "1em" }}>
            <Input
              name="interval"
              data-test="ruleModal-dropdown-interval"
              value={Math.abs(interval) || ""}
              placeholder="#"
              style={{ width: "5em" }}
              onChange={(_, { value: val }) => {
                updateValue({
                  interval:
                    val !== ""
                      ? getIntervalMultiplierFromBeforeAfter(onBeforeAfter) *
                        parseInt(val)
                      : val,
                });
              }}
            />
          </div>
          <div
            data-test="ruleModal-dropdown-frequency"
            style={{ marginRight: "1em" }}
          >
            <Selector
              name="frequency"
              dropdownProps={{
                search: false,
                compact: true,
              }}
              selection={getFrequencyOptions(Math.abs(interval), {
                withYear: true,
                withDay: true,
              })}
              multiple={false}
              selected={frequency}
              onSelect={(val) => {
                updateValue({ frequency: val });
              }}
            />
          </div>
        </React.Fragment>
      )}
      <div
        data-test="ruleModal-dropdown-beforeOrAfter"
        style={{ marginRight: "1em" }}
      >
        <Selector
          name="onBeforeAfter"
          dropdownProps={{
            search: false,
            compact: true,
            style: { width: "7em" },
          }}
          selection={onBeforeAfterOptions}
          multiple={false}
          selected={onBeforeAfter}
          onSelect={(val) => {
            setOnBeforeAfter(val);
            updateValue({
              interval:
                getIntervalMultiplierFromBeforeAfter(val) *
                Math.abs(interval || 1),
            });
          }}
        />
      </div>
      <div style={{ marginRight: "1em" }}>their</div>
      <ImportantDateSelector
        meetingDateTypeId={meetingDateTypeId}
        dateType={dateType}
        onBeforeAfter={onBeforeAfter}
        onChange={updateValue}
        {...props}
      />
    </React.Fragment>
  );
}

DateSelector.propTypes = {
  name: PropTypes.string,
  value: PropTypes.object,
  onChange: PropTypes.func,
};
DateSelector.defaultProps = {
  name: "",
  value: null,
  onChange: () => {},
};

export default DateSelector;
