import React from "react";

import { Icon, Popup } from "semantic-ui-react";

const IncludeInfoPopup = () => {
  return (
    <Popup
      on="hover"
      position="right center"
      trigger={<Icon color="grey" name="question circle" />}
    >
      Clients will be added that have ALL of the selected streams/tags.
    </Popup>
  );
};

export default IncludeInfoPopup;
