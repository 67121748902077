import React from "react";
import PropTypes from "prop-types";

import Assigner from "../../../../../Assigner";

function NextTaskAssigner({ tasks, onAssign }) {
  if (tasks.length === 0) return null;

  return (
    <Assigner
      currentAssignee={tasks[0].assignee}
      onAssign={(newAssignedId) => {
        return onAssign({
          assignee: newAssignedId,
          taskId: tasks[0].id,
        });
      }}
    />
  );
}

NextTaskAssigner.propTypes = {
  tasks: PropTypes.array.isRequired,
  onAssign: PropTypes.func.isRequired,
};

export default NextTaskAssigner;
