import React from "react";
import PropTypes from "prop-types";
import { range } from "lodash";
import { v4 } from "uuid";

import { Placeholder, Segment } from "semantic-ui-react";

function ModalContentLoadingPlaceholder({ num }) {
  return (
    <div style={{ paddingTop: "1rem" }}>
      {range(num).map(() => {
        return (
          <Segment
            key={v4()}
            style={{
              display: "grid",
              gridTemplateColumns: "100%",
              alignItems: "center",
              paddingTop: "1.25rem",
              paddingBottom: "1.25rem",
            }}
          >
            <Placeholder>
              <Placeholder.Header>
                <Placeholder.Line length="full" />
              </Placeholder.Header>
            </Placeholder>
          </Segment>
        );
      })}
    </div>
  );
}

ModalContentLoadingPlaceholder.propTypes = {
  num: PropTypes.number,
};

ModalContentLoadingPlaceholder.defaultProps = {
  num: 7,
};

export default ModalContentLoadingPlaceholder;
