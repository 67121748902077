import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import { Icon, Modal as OriginalModal } from "semantic-ui-react";

const useHeaderStyles = makeStyles({
  header: {
    "&": {
      position: "relative",
      textAlign: ({ titleAlign }) => {
        return titleAlign;
      },
    },
    "& > i": {
      position: "absolute",
      right: "17px",
      cursor: "pointer",
    },
  },
});

const ModalHeader = ({ titleAlign, children, onClose }) => {
  const headerClasses = useHeaderStyles({ titleAlign });
  return (
    <OriginalModal.Header className={headerClasses.header}>
      {children}
      <Icon name="close" onClick={onClose} />
    </OriginalModal.Header>
  );
};

ModalHeader.propTypes = {
  titleAlign: PropTypes.oneOf(["left", "center"]),
  children: PropTypes.node,
  onClose: PropTypes.func,
};

ModalHeader.defaultProps = {
  titleAlign: "left",
  children: null,
  onClose: () => {},
};

export default ModalHeader;
