import { useCallback, useMemo } from "react";

import { useQuery } from "hooks";

export function useAdvisors(crmUserId) {
  const queryFactory = useCallback(() => {
    const query = `query getAdvisors ($missingCrmUserId: ID ) {
            advisors (missingCrmUserId: $missingCrmUserId) {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }`;

    const resolver = ({ data: { advisors } }) => {
      return advisors.edges.map(({ node }) => {
        return { ...node };
      });
    };

    return { query, resolver };
  }, []);

  const { reloadData, data, ...queryProps } = useQuery(queryFactory, {
    variables: useMemo(() => {
      return { missingCrmUserId: crmUserId };
    }, [crmUserId]),
    options: { fetchOnInit: false },
  });

  return { ...queryProps, advisors: data, fetch: reloadData };
}

export default useAdvisors;
