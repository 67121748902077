import { createSelector } from "reselect";

import { sortWorkflow } from "data/hub/workflows/actions";

import { sortProcess } from "scenes/Hubly/components/Workflows/components/HubMenu/WorkflowFilters/helpers";

const selectWorkflows = (state) => {
  return state.hubly.data.hub.workflows;
};

const selectProcesses = (state) => {
  return state.hubly.data.hub.selected.hub.processes;
};

export const selectOrderedWorkflows = () => {
  return createSelector(
    [selectWorkflows, selectProcesses],
    (workflows, processes) => {
      const workflowsWithProcesses = processes
        .map((process) => {
          const processWorkflows = Object.values(workflows).filter(
            (workflow) => {
              return workflow.process && workflow.process.id === process.id;
            }
          );

          return {
            process: process,
            processWorkflows: processWorkflows.sort(sortWorkflow),
          };
        })
        .sort(sortProcess)
        .reduce((workflowList, { processWorkflows }) => {
          return [...workflowList, ...processWorkflows];
        }, []);

      const workflowsWithNoProcess = Object.values(workflows)
        .filter((workflow) => {
          return !workflow.process;
        })
        .sort(sortWorkflow);

      return [...workflowsWithProcesses, ...workflowsWithNoProcess];
    }
  );
};

export default selectOrderedWorkflows;
