import React from "react";
import PropTypes from "prop-types";

export function Layout({ children, ...props }) {
  return (
    <div style={{ marginTop: "1rem" }} {...props}>
      {children}
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node,
};

Layout.defaultProps = {
  children: null,
};

export default Layout;
