import React from "react";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import moment from "moment";
import { Button, Icon, Popup } from "semantic-ui-react";

import { SetActiveClient } from "data/hub/clients/actions";
import {
  CreateClientDate,
  DeleteClientDate,
  EditClientDate,
} from "data/libs/clientDates";

import { PrivacyModeContext } from "components/PrivacyMode/Context";

import { SetAlert } from "../Alerts/actions";

class DateEditableField extends React.Component {
  static contextType = PrivacyModeContext;

  static propTypes = {
    icon: PropTypes.string.isRequired,
    iconCorner: PropTypes.string,
    minDate: PropTypes.instanceOf(Date),
    name: PropTypes.string.isRequired,
    showAddTitle: PropTypes.bool,
    showTitle: PropTypes.bool,
    isForNextWorkflow: PropTypes.bool,
    style: PropTypes.object,
    text: PropTypes.string.isRequired,
    time: PropTypes.bool,
    title: PropTypes.string,
    year: PropTypes.bool,
    client: PropTypes.object.isRequired,
    dateObject: PropTypes.object.isRequired,
    dateType: PropTypes.object.isRequired,
    setActiveClient: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired,
    updateAttribute: PropTypes.func,
    displayTitle: PropTypes.string,
    integrationType: PropTypes.string,
  };

  static defaultProps = {
    iconCorner: "",
    minDate: null,
    showAddTitle: true,
    showTitle: true,
    isForNextWorkflow: false,
    style: { marginBottom: "0.1em", paddingLeft: "1em" },
    time: false,
    title: "",
    integrationType: "",
    year: true,
    updateAttribute: undefined,
    displayTitle: undefined,
  };

  state = {
    editing: false,
    loading: false,
    menuOpen: false,
    inputValue: "",
    pickerOpen: false,
  };

  updateDate = (date, callback) => {
    const { client, dateType, dateObject, setActiveClient, setAlert } =
      this.props;
    if (dateObject) {
      if (date == null) {
        // DELETE
        DeleteClientDate(dateObject.id)
          .then(() => {
            const clientCopy = { ...client };
            clientCopy.clientDates = clientCopy.clientDates.filter((cd) => {
              return cd.id !== dateObject.id;
            });
            setActiveClient(clientCopy);
          })
          .catch((error) => {
            console.error(error);
            setAlert({ type: "error", text: "Failed to clear date" });
          })
          .finally(() => {
            if (callback) callback();
          });
      } else {
        // EDIT
        EditClientDate(dateObject.id, {
          date: date,
        })
          .then((response) => {
            const clientCopy = { ...client };
            const foundIndex = clientCopy.clientDates.findIndex((cd) => {
              return cd.id === response.id;
            });
            if (foundIndex >= 0) {
              clientCopy.clientDates[foundIndex] = response;
              setActiveClient(clientCopy);
            } else {
              setAlert({ type: "error", text: "Failed to set date" });
            }
          })
          .catch((error) => {
            console.error(error);
            setAlert({ type: "error", text: "Failed to set date" });
          })
          .finally(() => {
            if (callback) callback();
          });
      }
    } else {
      // CREATE
      CreateClientDate({
        clientId: client.id,
        dateTypeId: dateType.id,
        date: date,
      })
        .then((response) => {
          const clientCopy = { ...client };
          clientCopy.clientDates.push(response);
          setActiveClient(clientCopy);
        })
        .catch((error) => {
          console.error(error);
          setAlert({ type: "error", text: "Failed to set Date" });
        })
        .finally(() => {
          if (callback) callback();
        });
    }
  };

  handleDateChange = (date, key) => {
    const { time } = this.props;

    this.setState({
      loading: true,
    });

    if (time) {
      this.update(moment(date).toISOString(), this.updated);
    } else {
      this.update(moment(date).format("YYYY-MM-DD"), this.updated);
    }
  };

  update = (date, callback) => {
    const { isForNextWorkflow, updateAttribute, name } = this.props;
    if (updateAttribute && isForNextWorkflow)
      updateAttribute(name, date, callback);
    else this.updateDate(date, callback);
  };

  renderTrigger = () => {
    const {
      icon,
      iconCorner,
      showAddTitle,
      showTitle,
      style,
      text,
      title,
      dateType,
      isForNextWorkflow,
      displayTitle = title,
      integrationType,
      ...restProps
    } = this.props;
    const { loading } = this.state;
    return (
      <div
        className="grey_on_hover clickable"
        onClick={() => {
          this.setState({ menuOpen: true, inputValue: text });
        }}
        style={{ ...style, borderRadius: "5px" }}
        {...restProps}
      >
        <div style={{ display: "flex" }}>
          <div>
            {dateType?.isSynced && (
              <div
                style={{
                  width: "0px",
                  height: "0px",
                  overflow: "visible",
                  transform: "translate(-12px,-1px)",
                  opacity: "0.4",
                }}
                title="This date is synced with your CRM"
              >
                <Icon
                  className={integrationType ? `${integrationType}_color` : ""}
                  size="small"
                  name="rss"
                />
              </div>
            )}
            {iconCorner ? (
              <Icon.Group style={{ marginRight: "0.5em" }}>
                <Icon
                  color="grey"
                  name={loading ? "circle notch" : icon}
                  loading={loading}
                />
                <Icon corner name={iconCorner} />
              </Icon.Group>
            ) : (
              <Icon
                color="grey"
                style={{ marginRight: "0.5em" }}
                name={loading ? "circle notch" : icon}
                loading={loading}
              />
            )}
          </div>
          {showTitle && (showAddTitle || text) && (
            <div
              className="overflow_ellipsis"
              style={{ maxWidth: "150px" }}
              title={title}
            >
              <strong style={{ marginRight: "0.5em" }}>
                {displayTitle}
                {isForNextWorkflow && ":"}
              </strong>
            </div>
          )}
          {isForNextWorkflow ? (
            <div data-test="date-value">
              {text || <span style={{ color: "grey" }}>Add Start Date</span>}
            </div>
          ) : (
            <div
              data-test="date-value"
              style={{
                minWidth: "80px",
                marginLeft: "auto",
                textAlign: "center",
              }}
            >
              {text || <span style={{ color: "lightgrey" }}>Set Date</span>}
            </div>
          )}
        </div>
      </div>
    );
  };

  updated = () => {
    this.setState({ editing: false, loading: false, menuOpen: false });
  };

  render() {
    const { minDate, name, text, title, year } = this.props;
    const { editing, menuOpen, inputValue, pickerOpen } = this.state;
    const { piiMask } = this.context;
    if (editing) {
      return (
        <DatePicker
          calendarclassName={piiMask("fs-block dd-privacy-mask")}
          customInput={
            <Popup
              className="hubly_bars_menu"
              on="click"
              onClose={() => {
                this.setState({ menuOpen: false, pickerOpen: false });
              }}
              open={menuOpen}
              popperModifiers={{
                preventOverflow: {
                  boundariesElement: "window",
                  enabled: false,
                },
              }}
              position="right center"
              trigger={this.renderTrigger()}
            >
              <Button.Group
                basic
                vertical
                labeled
                icon
                style={{ border: "none" }}
              >
                <Button
                  icon="edit"
                  content={`Edit ${title}`}
                  onClick={() => {
                    this.setState({
                      editing: true,
                      menuOpen: false,
                      pickerOpen: true,
                    });
                  }}
                />
                {!!inputValue && (
                  <Button
                    icon="close"
                    content={`Clear ${title}`}
                    onClick={() => {
                      this.update(null, this.updated);
                      this.setState({
                        loading: true,
                        menuOpen: false,
                        pickerOpen: false,
                      });
                    }}
                  />
                )}
              </Button.Group>
            </Popup>
          }
          // Arbitrarily only allow dates up to one year in the future?
          maxDate={moment(new Date()).add(50, "y").toDate()}
          minDate={minDate}
          open={pickerOpen}
          onCalendarClose={() => {
            this.setState({ pickerOpen: false });
          }}
          onClickOutside={() => {
            this.setState({ pickerOpen: false });
          }}
          onCalendarOpen={() => {
            this.setState({ pickerOpen: true });
          }}
          onClick
          onChange={(date) => {
            this.setState({ pickerOpen: false });
            this.handleDateChange(date, name);
          }}
          scrollableYearDropdown={year}
          // Need to use moment so that the dates without a time will not be set to the previous day when timezone
          selected={text ? moment(text).toDate() : minDate}
          showYearDropdown={year}
          yearDropdownItemNumber={120}
        />
      );
      // If in the Add state (empty text), allow advisor to directly go to calendar to edit the date
    } else if (!text) {
      return (
        <DatePicker
          calendarclassName={piiMask("fs-block dd-privacy-mask")}
          customInput={this.renderTrigger()}
          // Arbitrarily only allow dates up to one year in the future?
          maxDate={moment(new Date()).add(50, "y").toDate()}
          minDate={minDate}
          open={pickerOpen}
          onCalendarClose={() => {
            this.setState({ pickerOpen: false });
          }}
          onClickOutside={() => {
            this.setState({ pickerOpen: false });
          }}
          onCalendarOpen={() => {
            this.setState({ pickerOpen: true });
          }}
          onClick
          onChange={(date) => {
            this.setState({ pickerOpen: false });
            this.handleDateChange(date, name);
          }}
          scrollableYearDropdown={year}
          selected={text ? new Date(text) : minDate}
          showYearDropdown={year}
          yearDropdownItemNumber={120}
        />
      );
    } else {
      return (
        <Popup
          className="hubly_bars_menu"
          on="click"
          onClose={() => {
            this.setState({ menuOpen: false, pickerOpen: false });
          }}
          open={menuOpen}
          popperModifiers={{
            preventOverflow: { boundariesElement: "window", enabled: false },
          }}
          position="right center"
          trigger={this.renderTrigger()}
        >
          <Button.Group basic vertical labeled icon style={{ border: "none" }}>
            <Button
              icon="edit"
              content={`Edit ${title}`}
              onClick={() => {
                this.setState({
                  editing: true,
                  menuOpen: false,
                  pickerOpen: true,
                });
              }}
            />
            <Button
              icon="close"
              content={`Clear ${title}`}
              onClick={() => {
                this.update(null, this.updated);
                this.setState({
                  loading: true,
                  menuOpen: false,
                  pickerOpen: false,
                });
              }}
            />
          </Button.Group>
        </Popup>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    setActiveClient: (client) => {
      dispatch(SetActiveClient(client));
    },
    setAlert: (alert) => {
      dispatch(SetAlert(alert));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DateEditableField);
