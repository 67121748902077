import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Moment from "moment";
import { Button, Modal, Popup } from "semantic-ui-react";

import DatePickerContainer from "components/DatePickerContainer";
import {
  SetTaskComplete,
  ToggleTaskTimeCompletedAtModal,
} from "components/Task/actions";

class TaskView extends React.Component {
  static defaultProps = {
    modalTaskId: "",
  };

  static propTypes = {
    clientWorkflow: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
    isTaskTimeCompletedAtOpen: PropTypes.bool.isRequired,
    modalTaskId: PropTypes.string,
    setTaskComplete: PropTypes.func.isRequired,
    task: PropTypes.object.isRequired,
    toggleTaskTimeCompletedAtModal: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    let defaultTaskEditedDate = new Date();

    // If completed, set the default task edited completed at date
    if (props.task.completedAt) {
      defaultTaskEditedDate = new Date(props.task.completedAt);
    }

    defaultTaskEditedDate.setMinutes(0);
    defaultTaskEditedDate.setSeconds(0);
    defaultTaskEditedDate.setMilliseconds(0);

    this.state = {
      setTaskEditedDate: defaultTaskEditedDate,
    };
  }

  handleTaskEditedChange = (date) => {
    this.setState({
      setTaskEditedDate: date,
    });
  };

  updateTimestamp = () => {
    const { setTaskEditedDate } = this.state;
    const {
      clientWorkflow,
      setTaskComplete,
      task,
      toggleTaskTimeCompletedAtModal,
    } = this.props;
    setTaskComplete(clientWorkflow, task.id, setTaskEditedDate.toISOString());
    toggleTaskTimeCompletedAtModal();
  };

  renderTrigger() {
    const { task, toggleTaskTimeCompletedAtModal } = this.props;
    if (task.advisorDefinedCompletedAt) {
      return (
        <Popup
          on="hover"
          position="left center"
          popperModifiers={{
            preventOverflow: { boundariesElement: "window", enabled: false },
          }}
          style={{ padding: "0.6em", fontSize: "11px", fontWeight: "bold" }}
          trigger={
            <Button
              onClick={() => {
                toggleTaskTimeCompletedAtModal(task.id, true);
              }}
              style={{ fontSize: "10px", marginLeft: "auto" }}
              data-test="task-time-completedAt-edited"
            >
              {Moment(task.advisorDefinedCompletedAt).format(
                "MMM Do, YYYY h:mm a"
              )}{" "}
              <i>(edited)</i>
            </Button>
          }
        >
          {Moment(task.completedAt).format("MMM Do, YYYY h:mm a")}{" "}
          <i>(original)</i>
        </Popup>
      );
    } else {
      return (
        <Button
          className="clickable"
          style={{ fontSize: "10px", marginLeft: "auto" }}
          onClick={() => {
            toggleTaskTimeCompletedAtModal(task.id, true);
          }}
          data-test="task-time-completedAt"
        >
          {Moment(task.completedAt).format("MMM Do, YYYY h:mm a")}
        </Button>
      );
    }
  }

  render() {
    const {
      isTaskTimeCompletedAtOpen,
      modalTaskId,
      task,
      toggleTaskTimeCompletedAtModal,
    } = this.props;
    const { setTaskEditedDate } = this.state;
    if (task.id !== modalTaskId) {
      return this.renderTrigger();
    } else {
      return (
        <React.Fragment>
          {this.renderTrigger()}
          <Modal
            size="mini"
            on="click"
            open={isTaskTimeCompletedAtOpen}
            onClose={() => {
              toggleTaskTimeCompletedAtModal();
            }}
            onOpen={() => {
              toggleTaskTimeCompletedAtModal(task.id);
            }}
            style={{ minWidth: "24em" }}
            data-test="task-time-completedAt-modal"
          >
            <Modal.Header>Edit the Task Completion Timestamp</Modal.Header>
            <Modal.Content
              style={{ display: "flex", justifyContent: "center" }}
            >
              <DatePickerContainer
                setDate={setTaskEditedDate}
                handleChange={(date) => {
                  this.handleTaskEditedChange(date);
                }}
              />
            </Modal.Content>
            <Modal.Actions>
              <Button
                basic
                floated="left"
                onClick={toggleTaskTimeCompletedAtModal}
              >
                Cancel
              </Button>
              <Button basic primary onClick={this.updateTimestamp}>
                Update Task Timestamp
              </Button>
            </Modal.Actions>
          </Modal>
        </React.Fragment>
      );
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    clientWorkflow:
      state.hubly.data.hub.clientWorkflows[ownProps.task.clientWorkflowId],
    isTaskTimeCompletedAtOpen: state.hubly.task.isTaskTimeCompletedAtOpen,
    modalTaskId: state.hubly.task.modalTaskId,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    setTaskComplete: (workflow, taskId, editedTime) => {
      dispatch(SetTaskComplete(workflow, taskId, editedTime));
    },
    toggleTaskTimeCompletedAtModal: (taskId, isOpen) => {
      dispatch(ToggleTaskTimeCompletedAtModal(taskId, isOpen));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskView);
