import React from "react";
import PropTypes from "prop-types";

import NumericBadge from "components/NumericBadge";

export function ReminderStats({ numOverdue, numDueToday }) {
  return (
    <React.Fragment>
      {numOverdue > 0 && (
        <NumericBadge
          data-test="hubfeed-badge-red"
          color="red"
          value={numOverdue}
          style={{
            margin: "auto",
            marginBottom: "0.5em",
            display: "inline-block",
          }}
        />
      )}
      {numDueToday > 0 && (
        <NumericBadge
          data-test="hubfeed-badge-yellow"
          color="yellow"
          value={numDueToday}
        />
      )}
    </React.Fragment>
  );
}

ReminderStats.propTypes = {
  numOverdue: PropTypes.number.isRequired,
  numDueToday: PropTypes.number.isRequired,
};

ReminderStats.defaultProps = {};

export default ReminderStats;
