import React from "react";
import PropTypes from "prop-types";

import { Icon, Label } from "semantic-ui-react";

export function ConditionList({ streams, tags, type, children }) {
  const isAttributeType =
    type === "ADD_ATTRIBUTES" || type === "REMOVE_ATTRIBUTES";

  const renderInitialPrefix = () => {
    return (
      <React.Fragment>
        {!isAttributeType && <span>Clients with&nbsp;</span>}
      </React.Fragment>
    );
  };

  const renderLabelPrefix = (labels, singularPrefix, pluralPrefix) => {
    return (
      <React.Fragment>
        {labels.length > 0 && isAttributeType && (
          <span>
            {labels.length === 1 ? singularPrefix : pluralPrefix}&nbsp;
          </span>
        )}
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      {renderInitialPrefix()}
      {streams.length <= 0 && tags.length <= 0 && (
        <span
          style={{ color: "grey" }}
          title="No stream(s) or tag(s) found. Please specify new stream(s) or tag(s) to resume rule."
        >
          N/A
        </span>
      )}

      {(streams.length > 0 || tags.length > 0) && isAttributeType && (
        <span>The&nbsp;</span>
      )}

      {renderLabelPrefix(streams, "stream", "streams")}
      {streams.map((stream) => {
        return (
          <Label
            key={stream.id}
            style={{
              backgroundColor: stream.color,
              color: "#fff",
              padding: "0.3em 0.4em",
              margin: "0.1em",
            }}
          >
            {stream.name}
          </Label>
        );
      })}

      {streams.length > 0 && tags.length > 0 && isAttributeType && (
        <span>&nbsp;and&nbsp;</span>
      )}

      {renderLabelPrefix(tags, "tag", "tags")}
      {tags.map((tag) => {
        return (
          <Label
            key={tag.id}
            style={{
              backgroundColor: "grey",
              color: "#fff",
              padding: "0.3em 0.4em",
              margin: "0.1em",
            }}
          >
            <Icon fitted name="hashtag" />
            &nbsp;
            {tag.name}
          </Label>
        );
      })}
      {children}
    </React.Fragment>
  );
}
ConditionList.propTypes = {
  streams: PropTypes.array,
  tags: PropTypes.array,
  type: PropTypes.string,
  children: PropTypes.node,
};

ConditionList.defaultProps = {
  streams: [],
  tags: [],
  type: "",
  children: null,
};

export default ConditionList;
