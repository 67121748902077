export const initialState = {
  addClientToWorkflow: "",
  createClientModalOpen: false,
  clientName: "",
  createCallback: null,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "OPEN_CREATE_CLIENT_MODAL":
      return {
        ...state,
        addClientToWorkflow: action.addClientToWorkflow,
        clientName: action.clientName,
        createClientModalOpen: true,
        isClientWorkflow: action.isClientWorkflow,
        createCallback: action.createCallback,
      };
    case "CLOSE_CREATE_CLIENT_MODAL":
      return {
        ...state,
        createClientModalOpen: false,
      };
    default:
      return state;
  }
};
export default reducer;
