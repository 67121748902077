import { useCallback, useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { differenceWith } from "lodash";

import { yupResolver } from "@hookform/resolvers/yup";
import { yup } from "utils";

const schema = yup.object().shape({
  newUserInfo: yup
    .array()
    .of(
      yup.object().shape({
        firstName: yup
          .string()
          .test(
            "isRequired",
            "First name is required",
            function firstNameValTest(value) {
              const { lastName, email } = this.parent;
              if (lastName || email) {
                return !!value;
              }
              return true;
            }
          ),
        lastName: yup
          .string()
          .test(
            "isRequired",
            "Last name is required",
            function lastNameValTest(value) {
              const { firstName, email } = this.parent;
              if (firstName || email) {
                return !!value;
              }
              return true;
            }
          ),
        email: yup
          .string()
          .email("Email format is not correct")
          .test(
            "isRequired",
            "Email is required",
            function emailValTest(value) {
              const { firstName, lastName } = this.parent;
              if (firstName || lastName) {
                return !!value;
              }
              return true;
            }
          ),
        inviteNow: yup.boolean().required(),
      })
    )
    .uniqueProperty("email", "Email must be unique"),
});

const useNewUserInfoForm = ({ defaultNewUserInfo }) => {
  const [inviteCount, setInviteCount] = useState(0);
  const {
    control,
    setError,
    handleSubmit,
    formState: { isSubmitting, errors },
    clearErrors,
    getValues,
    reset,
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: { newUserInfo: defaultNewUserInfo },
  });

  const updateInviteCount = useCallback(() => {
    setInviteCount(
      getValues("newUserInfo").filter(
        ({ firstName, lastName, email, inviteNow }) => {
          return (
            (firstName.length || lastName.length || email.length) && inviteNow
          );
        }
      ).length
    );
  }, [setInviteCount, getValues]);

  useEffect(() => {
    reset({ newUserInfo: defaultNewUserInfo });
    updateInviteCount();
  }, [defaultNewUserInfo, reset, updateInviteCount]);

  const { fields, append, remove } = useFieldArray({
    control: control,
    name: "newUserInfo",
  });

  const backfill = useCallback((info) => {
    const d = {
      firstName: "",
      lastName: "",
      email: "",
      roleId: "",
      inviteNow: false,
    };

    return (
      info?.map?.((i) => {
        return { ...d, ...i };
      }) || { ...d, ...info }
    );
  }, []);

  const appendBackfill = useCallback(
    (v) => {
      return append(backfill(v));
    },
    [append, backfill]
  );

  const uniqueFormValues = useCallback(
    (info) => {
      const currentValues = getValues("newUserInfo");
      return differenceWith(info, currentValues, (newV, currentV) => {
        const emptyIgnored = {
          firstName: newV.firstName || currentV.firstName,
          lastName: newV.lastName || currentV.lastName,
          email: newV.email || currentV.email,
        };

        return (
          emptyIgnored.firstName === currentV.firstName &&
          emptyIgnored.lastName === currentV.lastName &&
          emptyIgnored.email === currentV.email
        );
      });
    },
    [getValues]
  );

  const getPrunedUserInfo = useCallback(
    (defaultRole) => {
      const newUserInfo = getValues("newUserInfo");
      return newUserInfo
        .map((userInfo) => {
          return { ...userInfo, roleId: userInfo.roleId || defaultRole.id };
        })
        .filter(({ firstName, lastName, email }) => {
          return firstName && lastName && email;
        });
    },
    [getValues]
  );

  return {
    control: control,
    setError: setError,
    handleSubmit: handleSubmit,
    isSubmitting: isSubmitting,
    errors: errors,
    clearErrors: clearErrors,
    fields: fields,
    update: useCallback(
      (v) => {
        const formValues = Array.isArray(v) ? v : [v];
        appendBackfill(uniqueFormValues(formValues));
      },
      [uniqueFormValues, appendBackfill]
    ),
    append: appendBackfill,
    remove: remove,
    inviteCount: inviteCount,
    updateInviteCount: updateInviteCount,
    getPrunedUserInfo: getPrunedUserInfo,
  };
};

export default useNewUserInfoForm;
