import React from "react";
import PropTypes from "prop-types";

export function LayoutHeader({ children }) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        marginBottom: "0.5em",
        marginTop: "1em",
      }}
    >
      {children}
    </div>
  );
}

LayoutHeader.propTypes = {
  children: PropTypes.node,
};

LayoutHeader.defaultProps = {
  children: null,
};

export default LayoutHeader;
