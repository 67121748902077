import { groupByKeys } from "utils";

import { getCombinedStreamsTagsAndClient } from "../../helper";

export const getAgeRuleInitialValue = (workflowRule) => {
  return {
    selectedStreamsTags: getCombinedStreamsTagsAndClient(
      workflowRule?.streams,
      workflowRule?.tags
    ),
    excludeItems: getCombinedStreamsTagsAndClient(
      workflowRule?.streamsExclude,
      workflowRule?.tagsExclude,
      workflowRule?.clientsExclude
    ),
    onlyHouseholdHead:
      workflowRule && "onlyHouseholdHead" in workflowRule
        ? workflowRule.onlyHouseholdHead
        : false,
    ageSelector: {
      age: workflowRule?.age || "55",
      interval:
        workflowRule?.interval !== undefined ? workflowRule?.interval : 1,
      frequency: workflowRule?.frequency || "MONTHLY",
    },
  };
};

export const getAgeRuleModalProp = (workflowRule) => {
  return {
    initialValue: getAgeRuleInitialValue(workflowRule),
    title: "Age Based Rule",
    testId: "ageRule-modal",
    showHouseholdHead: true,
    showExcludeItems: true,
    formValidator: ({ selectedStreamsTags, ageSelector }) => {
      const { interval, age } = ageSelector;
      return selectedStreamsTags.length > 0 && interval !== "" && age !== "";
    },
  };
};

export const formatAgeRuleRuleRequest = ({
  excludeItems,
  ageSelector,
  selectedStreamsTags,
  onlyHouseholdHead,
}) => {
  const {
    streams: streamsExclude,
    tags: tagsExclude,
    clients: clientsExclude,
  } = groupByKeys(excludeItems, ["stream", "tag", "client"]);

  return {
    ...ageSelector,
    ...groupByKeys(selectedStreamsTags, ["stream", "tag"]),
    type: "AGE_RULE",
    onlyHouseholdHead: onlyHouseholdHead,
    streamsExclude: streamsExclude,
    tagsExclude: tagsExclude,
    clientsExclude: clientsExclude,
  };
};

export default getAgeRuleInitialValue;
