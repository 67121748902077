import React from "react";
import PropTypes from "prop-types";

import useSubscriptionInfo from "../../hooks/useSubscriptionInfo";
import { Information, InformationButtons, Modal } from "./components";

export function SubscriptionInformationPage({
  inviteCount,
  loading,
  toPrev,
  onClose,
  onSubmit,
}) {
  const { loading: loadingInfo, data } = useSubscriptionInfo(inviteCount);
  const { seatsNeeded = 0 } = data;

  return (
    <React.Fragment>
      <Modal.Body>
        <Information loading={loadingInfo} info={data} />
      </Modal.Body>
      <Modal.Footer>
        <InformationButtons
          title={seatsNeeded === 0 ? "Finish" : "Agree"}
          disabled={loading || loadingInfo}
          loading={loading || loadingInfo}
          toPrev={toPrev}
          onClose={onClose}
          onSubmit={onSubmit}
        />
      </Modal.Footer>
    </React.Fragment>
  );
}

SubscriptionInformationPage.propTypes = {
  inviteCount: PropTypes.number,
  loading: PropTypes.bool,
  toPrev: PropTypes.func,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

SubscriptionInformationPage.defaultProps = {
  inviteCount: 0,
  loading: false,
  toPrev: () => {},
  onSubmit: () => {},
  onClose: () => {},
};

export default SubscriptionInformationPage;
