import React, { useState } from "react";
import PropTypes from "prop-types";

import { useAccessTypeStore } from "scenes/Hubly/components/Workspace/components/hooks";

import { TaskIcon } from "..";
import { EmailModal } from "./components";

export function TaskEmailTemplate({ task, onTaskConfigChange, ...props }) {
  const { hasAccess } = useAccessTypeStore();
  const { id = null, emailTemplate = null } = task || {};
  const [emailModalProps, showEmailModal] = useState(null);
  const hasEmailTemplate = Boolean(
    emailTemplate?.to || emailTemplate?.subject || emailTemplate?.body
  );

  return (
    <React.Fragment>
      <TaskIcon
        hasValue={hasEmailTemplate}
        iconName="mail"
        title={
          hasEmailTemplate
            ? "This task has an email template saved"
            : "Email Template"
        }
        onClick={() => {
          showEmailModal({
            taskId: id,
            isCreating: !hasEmailTemplate,
            emailTemplate: hasEmailTemplate ? emailTemplate : null,
          });
        }}
        visible={hasAccess() || hasEmailTemplate}
      />

      {emailModalProps && (
        <EmailModal
          {...props}
          {...emailModalProps}
          onClose={showEmailModal}
          onEmailTemplateUpdated={(data) => {
            showEmailModal(null);
            onTaskConfigChange({ ...task, emailTemplate: data });
          }}
          disabled={!hasAccess()}
        />
      )}
    </React.Fragment>
  );
}

TaskEmailTemplate.propTypes = {
  task: PropTypes.object,
  onTaskConfigChange: PropTypes.func.isRequired,
};

TaskEmailTemplate.defaultProps = {
  task: null,
};

export default TaskEmailTemplate;
