import React from "react";
import PropTypes from "prop-types";

import moment from "moment";
import { RRule } from "rrule";

export function RepeatingRuleText({
  workflowRule: { repeat, dateStart, frequency, weekStartDay, interval },
}) {
  // Can be run the next time the workflow rules sync is run, approximating to be 10:25 UTC for prod
  const workflowRulesSync = new RRule({
    freq: RRule.DAILY,
    count: 2,
    byhour: 10,
    byminute: 25,
    dtstart: new Date(),
  });
  const workflowRulesSyncTime = new Date(workflowRulesSync.after(new Date()));

  let text = "";
  if (repeat === "ONCE") {
    text = ` will be added on ${moment(dateStart).format("MMMM Do YYYY")}`;
  } else if (repeat === "EVERY") {
    // rrule takes a date start in 20120201T093000Z format: https://www.npmjs.com/package/rrule
    const last = moment(dateStart).utc().format("YYYYMMDDTHHmmss");
    // Set the start date to when last run or when the date start was selected.
    let ruleString = `DTSTART:${last}\nRRULE:FREQ=${frequency};INTERVAL=${interval};COUNT=52`;
    if (frequency === "WEEKLY") {
      ruleString += `;BYDAY=${weekStartDay}`;
    }
    const rRule = RRule.fromString(ruleString);
    const nextDate = rRule.after(workflowRulesSyncTime);
    text = ` will next be added on ${moment(nextDate).format(
      "dddd, MMMM Do YYYY"
    )}`;
  }

  return <React.Fragment>{text}</React.Fragment>;
}
RepeatingRuleText.propTypes = {
  workflowRule: PropTypes.object,
};

RepeatingRuleText.defaultProps = {
  workflowRule: {},
};
export default RepeatingRuleText;
