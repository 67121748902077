export const getWaitingOnClientStatus = ({
  waitingOnClient = false,
  waitingOnClientDays = 0,
}) => {
  if (!waitingOnClient) return "";

  const formattedDaysText = (num) => {
    return num > 1 ? ` (${num} days)` : ` (${num} day)`;
  };

  return `Waiting On Client${
    waitingOnClientDays > 0 ? formattedDaysText(waitingOnClientDays) : ""
  }`;
};

export function makeDisplaySettings({
  showStreams = true,
  showProgress = true,
  showIcons = true,
  numberOfTasks = 5,
  presentWorkflowName = false,
} = {}) {
  return {
    showStreams(streams) {
      return showStreams ? streams : [];
    },
    showProgress(node) {
      return showProgress ? node : null;
    },
    showIcons(node) {
      return showIcons ? node : null;
    },
    presentWorkflowName(name) {
      return presentWorkflowName ? name : undefined;
    },
    displayTasks() {
      return (_, i) => {
        return i < numberOfTasks;
      };
    },
  };
}

export default getWaitingOnClientStatus;
