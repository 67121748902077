import { CreateNextWorkflow } from "data/libs/nextWorkflows";
import { CreateProcess } from "data/libs/processes";
import { DuplicateWorkflow, EditWorkflow } from "data/libs/workflows";

import { SetAlert } from "components/Alerts/actions";

export function DuplicateProcessToAnotherHub({
  hubId,
  process,
  workflowIds,
  nextWorkflows,
}) {
  return async (dispatch, getState) => {
    const copyWorkflows = async () => {
      return Promise.all(
        workflowIds.map(async (wfId) => {
          // eslint-disable-next-line no-return-await
          const newWf = await DuplicateWorkflow(wfId, hubId);
          return { newWf: newWf, copiedFrom: wfId };
        })
      );
    };
    const createProcess = async (copyOps) => {
      return CreateProcess({
        hubId: hubId,
        name: process.name,
        description: process.description,
        color: process.color,
        workflows: copyOps.map((o) => {
          return o.newWf.id;
        }),
      });
    };
    const assignNextWorkflow = async (copyOps) => {
      const newNextWorkflows = Object.entries(nextWorkflows).reduce(
        (dic, [k, v]) => {
          const newKey = copyOps.find((o) => {
            return o.copiedFrom === k;
          }).newWf.id;
          const newValue = copyOps.find((o) => {
            return o.copiedFrom === v;
          }).newWf.id;
          return { ...dic, [newKey]: newValue };
        },
        {}
      );

      return Promise.all(
        copyOps.map(async (o) => {
          const nextWorkflowId = newNextWorkflows[o.newWf.id];

          if (nextWorkflowId) {
            // eslint-disable-next-line no-return-await
            return await CreateNextWorkflow({
              workflowId: o.newWf.id,
              nextWorkflowId: nextWorkflowId,
            });
          } else {
            return Promise.resolve({});
          }
        })
      );
    };
    const cleanCopyFromWorkflowTitle = async (copyOps) => {
      copyOps
        .map((o) => {
          const {
            newWf: { id, name },
          } = o;
          return {
            workflowId: id,
            request: { name: name.replace(" (Copy)", "") },
          };
        })
        .forEach(async ({ workflowId, request }) => {
          await EditWorkflow(workflowId, request);
        });
    };

    try {
      const copyOps = await copyWorkflows();
      dispatch(
        SetAlert({ type: "success", text: "Workflows were duplicated" })
      );

      if (process) {
        await createProcess(copyOps);
        dispatch(
          SetAlert({ type: "success", text: "Process has been created" })
        );
      }

      await assignNextWorkflow(copyOps);
      dispatch(
        SetAlert({
          type: "success",
          text: "Next Workflows have been Assigned",
        })
      );

      await cleanCopyFromWorkflowTitle(copyOps);
      dispatch(SetAlert({ type: "success", text: "Cleaned Copy from title" }));
    } catch (error) {
      dispatch(SetAlert({ type: "error", text: `Error ${error}` }));
    }
  };
}

export default DuplicateProcessToAnotherHub;
