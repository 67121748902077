import React from "react";
import PropTypes from "prop-types";

export function Layout({ children }) {
  return (
    <div
      style={{
        backgroundColor: "white",
        height: "calc(100% + 13px)",
        width: "100%",
        overflow: "hidden",
        overflowY: "auto",
      }}
    >
      {children}
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node,
};

Layout.defaultProps = {
  children: null,
};

export default Layout;
