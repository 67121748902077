import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Dropdown, Header, Icon, Popup } from "semantic-ui-react";

import { SetMenuFilters } from "data/filters/actions";

class AssigneeFilters extends React.Component {
  static propTypes = {
    hub: PropTypes.shape({
      advisors: PropTypes.array.isRequired,
    }).isRequired,
    filters: PropTypes.object.isRequired,
    selectedHub: PropTypes.object.isRequired,
    setFilters: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const { filters } = props;
    this.assigneeOptions = [
      { key: "ALL", value: "ALL", text: "Any User" },
      { key: "UNASSIGNED", value: "UNASSIGNED", text: "Unassigned" },
      { key: "CURRENT", value: "CURRENT", text: "Current User" },
    ];
    let assigneeExists = false;
    props.selectedHub.advisors.forEach((advisor) => {
      if (filters.assignee && filters.assignee === advisor.id)
        assigneeExists = true;
      this.assigneeOptions.push({
        key: advisor.id,
        value: advisor.id,
        text: `${advisor.firstName} ${advisor.lastName}`,
      });
    });
    if (
      filters.assignee !== "ALL" &&
      filters.assignee !== "CURRENT" &&
      !assigneeExists
    ) {
      // that advisor may have been removed from HUB
      this.assigneeOptions.push({
        key: filters.assignee,
        value: filters.assignee,
        text: "Non Hub Advisor",
      });
    }
  }

  clearFilter = () => {
    const { filters, setFilters } = this.props;
    const filtersCopy = { ...filters };
    filtersCopy.assignee = "ALL";
    setFilters(filtersCopy);
  };

  showAssignFilterMenu = () => {
    const { hub } = this.props;
    return hub.advisors.length >= 2;
  };

  toggleEmptyWorkflows = () => {
    const { filters, setFilters } = this.props;
    const filtersCopy = { ...filters };
    filtersCopy.filterEmptyWorkflows = !filtersCopy.filterEmptyWorkflows;
    setFilters(filtersCopy);
  };

  toggleAssigneeFilter = (event, data) => {
    const { filters, setFilters } = this.props;
    const filtersCopy = { ...filters };
    filtersCopy.assignee = data.value;
    setFilters(filtersCopy);
  };

  render() {
    const { filters } = this.props;
    if (!this.showAssignFilterMenu()) return null;
    return (
      <React.Fragment>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div>
            <Header as="h4" style={{ display: "inline" }}>
              Assignee
            </Header>
          </div>
        </div>
        <div style={{ margin: "0em 1em 0em 1em" }}>
          <Dropdown
            data-test="filter-menu-assignee"
            compact
            name="assignee"
            onChange={this.toggleAssigneeFilter}
            options={this.assigneeOptions}
            selection
            value={filters.assignee}
            fluid
          />
        </div>
        <div>
          <Popup
            content="Clear"
            on="hover"
            position="top center"
            trigger={
              <Icon
                data-test="filter-menu-assignee-clear"
                name="delete"
                onClick={this.clearFilter}
                link
                color="grey"
              />
            }
          />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    hub: state.hubly.data.hub.selected.hub,
    selectedHub: state.hubly.data.hub.selected.hub,
    filters: state.hubly.data.filters,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setFilters: (filters) => {
      dispatch(SetMenuFilters(filters));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AssigneeFilters);
