import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";

import { Input } from "semantic-ui-react";

import useCheckAdvisorEmailExist from "../../../../hooks/useCheckAdvisorEmailExist";

function EmailInput({
  name,
  value,
  error,
  onChange: onChangeCb,
  onBlur: onBlurCb,
  onVerifying,
  setError,
  clearErrors,
  ...props
}) {
  const [email, setEmail] = useState(value);
  const [variables, setVariables] = useState({ advisorEmail: value || "" });

  const {
    emailExists,
    loading: emailLoading,
    reloadData,
  } = useCheckAdvisorEmailExist(variables);

  const { setRegisteredError, clearRegisteredError } = useMemo(() => {
    return {
      setRegisteredError: () => {
        setError(name, {
          type: "registered",
          message: "This email was registered",
        });
      },
      clearRegisteredError: () => {
        clearErrors(name);
      },
    };
  }, [name, setError, clearErrors]);

  useEffect(() => {
    if (emailExists) {
      setRegisteredError();
    }
    return () => {
      clearRegisteredError();
    };
  }, [emailExists, setRegisteredError, clearRegisteredError]);

  useEffect(() => {
    onVerifying(emailLoading);
  }, [emailLoading, onVerifying]);

  return (
    <Input
      {...props}
      value={email || ""}
      disabled={emailLoading}
      error={Boolean(error)}
      onFocus={() => {
        clearErrors(name);
      }}
      onChange={(e) => {
        setEmail(e.target.value);
        onChangeCb(e.target.value);
      }}
      onBlur={async (e) => {
        const emailValue = e.target.value.trim();
        if (emailValue.length === 0) {
          clearErrors(name);
        } else if (emailValue === variables.advisorEmail) {
          await reloadData();
          if (emailExists) {
            setRegisteredError();
          }
        } else {
          setVariables({ advisorEmail: emailValue });
        }
        onBlurCb(emailValue);
      }}
    />
  );
}

EmailInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  error: PropTypes.object,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onVerifying: PropTypes.func,
  setError: PropTypes.func,
  clearErrors: PropTypes.func,
};

EmailInput.defaultProps = {
  value: "",
  error: null,
  onChange: () => {},
  onBlur: () => {},
  onVerifying: () => {},
  setError: () => {},
  clearErrors: () => {},
};

export default React.forwardRef(EmailInput);
