import { SetActiveClient } from "data/hub/clients/actions";
import { AddEvent, EditEvent as EditEventAPI } from "data/libs/events";

import { SetAlert } from "components/Alerts/actions";

export const AddNewEvent = (client, request) => {
  return async (dispatch) => {
    try {
      const response = await AddEvent(request);
      dispatch(
        SetActiveClient({ ...client, events: [...client.events, response] })
      );
    } catch (error) {
      dispatch(SetAlert({ type: "error", text: `Failed to add meeting.` }));
    }
  };
};

export const EditEvent = (client, event, request) => {
  return async (dispatch) => {
    try {
      if (!event || !("id" in event)) {
        throw new Error("Event is not defined");
      }

      const response = await EditEventAPI(event.id, request);
      const otherEvents = [...client.events].filter((e) => {
        return e.id !== event.id;
      });
      dispatch(
        SetActiveClient({ ...client, events: [...otherEvents, response] })
      );
      return response;
    } catch (error) {
      dispatch(SetAlert({ type: "error", text: `Failed to add meeting.` }));
      return null;
    }
  };
};

export default {};
