import { API } from "aws-amplify";

import SyncedAPI from "data/libs/syncedAPI";

import { getAPIReq } from "./authLib";

export const CreateNextWorkflow = async (request) => {
  const req = await getAPIReq(request);
  return SyncedAPI.post("HublyAPI", `/nextWorkflows/`, req);
};

export const EditNextWorkflow = async (id, request) => {
  const req = await getAPIReq(request);
  return SyncedAPI.put("HublyAPI", `/nextWorkflows/${id}/`, req);
};

export const GetNextWorkflow = async (id) => {
  const req = await getAPIReq();
  return API.get("HublyAPI", `/nextWorkflows/${id}/`, req);
};

export const DeleteNextWorkflow = async (id, request = null) => {
  const req = await getAPIReq(request);
  return SyncedAPI.del("HublyAPI", `/nextWorkflows/${id}/`, req);
};
