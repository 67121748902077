import React from "react";

const initialState = [];

function reducer(state, { type, value }) {
  switch (type) {
    case "set":
      return value;
    case "add":
      return [...state, value];
    case "edit":
      return state.map((item) => {
        return item.id === value.id
          ? {
              ...item,
              ...value,
            }
          : item;
      });
    case "remove":
      return state.filter(({ id }) => {
        return id !== value;
      });
    default:
      return initialState;
  }
}

export const useItemState = () => {
  return React.useReducer(reducer, initialState);
};

export default useItemState;
