import React from "react";

import { Dropdown, Table } from "semantic-ui-react";

import { useHub, useSetHub } from "data/hub/useHub";
import { EditHub } from "data/libs/hubs";

import { useAsyncCall } from "hooks";
import { useAccess } from "components/Access";

export function HubOptions() {
  const hub = useHub();
  const setHub = useSetHub();

  const hasAccess = useAccess();
  const [call, running] = useAsyncCall();

  const editHub = (value) => {
    return call(async () => {
      const res = await EditHub(hub.id, { sortByLastName: value });
      setHub(res);
    });
  };

  return (
    <Table selectable={hasAccess(["Administrator"])} stackable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Setting</Table.HeaderCell>
          <Table.HeaderCell>Options</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <Table.Row>
          <Table.Cell>Client ordered by</Table.Cell>
          <Table.Cell>
            <Dropdown
              selection
              options={[
                { text: "First Name", value: false, key: "first" },
                { text: "Last Name", value: true, key: "last" },
              ]}
              value={hub.sortByLastName}
              onChange={(e, { value }) => {
                editHub(value);
              }}
              style={{ minWidth: "150px" }}
              loading={running}
              disabled={!hasAccess(["Administrator"]) || running}
            />
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
}

HubOptions.propTypes = {};

export default HubOptions;
