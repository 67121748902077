import React from "react";
import PropTypes from "prop-types";

import MuiBadge from "@material-ui/core/Badge";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  badgeColor: {
    color: "white",
    backgroundColor: ({ color }) => {
      return color;
    },
  },
});

export function Badge({ children, content, color, ...props }) {
  const { badgeColor } = useStyles({ color });

  return (
    <MuiBadge
      data-test="badge"
      style={{ cursor: "default" }}
      classes={{ badge: badgeColor }}
      overlap="rectangular"
      badgeContent={content}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      {...props}
    >
      {children}
    </MuiBadge>
  );
}

Badge.propTypes = {
  children: PropTypes.node,
  content: PropTypes.node,
  color: PropTypes.string,
};

Badge.defaultProps = {
  children: null,
  content: null,
  color: "grey",
};

export default Badge;
