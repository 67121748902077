export const initialState = {
  stream: {},
  isManageStream: false,
  confirmAddDisabled: false,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "TOGGLE_STREAM_MODAL":
      return {
        ...state,
        stream: action.stream,
        isManageStream: action.isManageStream,
      };
    case "DISABLE_STREAM_CONFIRMATION":
      return {
        ...state,
        confirmAddDisabled: true,
      };
    default:
      return state;
  }
};
export default reducer;
