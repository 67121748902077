import { differenceInCalendarDays } from "date-fns";

export const getFirstIncompleteTaskIndex = (tasks = []) => {
  return tasks.findIndex(({ completed }) => {
    return !completed;
  });
};

export const daysSinceTaskDue = (firstIncompleteIndex = 0, tasks = []) => {
  if (firstIncompleteIndex <= 0) return null;

  const prevTask = tasks[firstIncompleteIndex - 1];
  const prevTaskCompletedAt =
    prevTask?.advisorDefinedCompletedAt || prevTask?.completedAt;
  if (!prevTaskCompletedAt) return null;
  return differenceInCalendarDays(Date.now(), new Date(prevTaskCompletedAt));
};

export default getFirstIncompleteTaskIndex;
