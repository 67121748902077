import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {
  Button,
  Dropdown,
  Grid,
  Header,
  Icon,
  Input,
  Modal,
  Popup,
} from "semantic-ui-react";

import history from "data/history";
import { AddClientTag, RemoveClientTag } from "data/hub/tags/actions";
import {
  GetClient,
  getOrderedClientName,
  getOrderedFullClientName,
  sortClients,
} from "data/libs/clients";
import { ClientsWithTagsInTag } from "data/libs/tags";

import { SetConfirmationModal } from "components/ConfirmationModal/actions";
import { PrivacyModeContext } from "components/PrivacyMode/Context";

import ClientInTag from "./components/ClientInTag";
import ExportStreamOrTagButton from "../ExportStreamOrTagButton/ExportStreamOrTagButton";
import {
  DeleteTagAction,
  DisableConfirmationModal,
  EditTagAction,
  ToggleTagModal,
} from "./actions";

export function TagModal({ tag, tags, hub, ...props }) {
  const context = useContext(PrivacyModeContext);
  const { piiMask } = context;

  const [adding, setAdding] = useState(false);
  const [numLoading, setNumLoading] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const [tagName, setTagName] = useState(tag.name);
  const [clientsInTag, setClientsInTag] = useState([]);

  useEffect(() => {
    setNumLoading(1);
    ClientsWithTagsInTag(tag.id).then((clientResponse) => {
      setNumLoading(0);
      setClientsInTag(sortClients(clientResponse));
    });
  }, [tag]);

  const updateClientInTags = ({ targetClient, added = true }) => {
    setClientsInTag((prevData) => {
      if (
        added &&
        prevData.some((c) => {
          return c.id === targetClient.id;
        })
      ) {
        // making sure no duplciate entries are added
        return prevData;
      }
      return sortClients(
        added
          ? [...prevData, targetClient]
          : prevData.filter((client) => {
              return client.id !== targetClient.id;
            })
      );
    });
  };

  const onClientTagRemoved = (client) => {
    const { removeClientTag } = props;
    removeClientTag(client, tag, (result) => {
      if (result) {
        updateClientInTags({ targetClient: client, added: false });
      }
    });
  };

  const addingCallback = (tagAdded, clientId) => {
    const { toggleTagModal, activeClients } = props;
    if (tagAdded) {
      // Need to set the tag for the modal, in case the external ID has been updated
      toggleTagModal(tagAdded);
      setNumLoading(numLoading - 1);
      setSearchInput("");

      if (clientId) {
        const foundClient = activeClients[clientId];
        if (!foundClient) {
          GetClient(clientId).then((newClient) => {
            updateClientInTags({ targetClient: newClient });
          });
        } else {
          updateClientInTags({ targetClient: foundClient });
        }
      }
    } else {
      setNumLoading(numLoading - 1);
      setSearchInput("");
    }
  };

  const householdConfirm = (client) => {
    const {
      addClientTag,
      households,
      setConfirmationModal,
      confirmAddIsDisabled,
      disableConfirmation,
    } = props;

    // skip the intercept
    if (confirmAddIsDisabled) {
      addClientTag(client.id, tag.name, addingCallback);
      return;
    }

    if (client.householdId) {
      const name = client.lastName
        ? `${client.firstName} ${client.lastName}`
        : client.firstName;
      const params = {
        title: `Apply Tag to Household`,
        message: `Would you like to apply this tag to all household members or only to ${name}?`,
        icon: "users",
        buttons: [
          {
            text: "Cancel",
          },
          {
            text: `Apply to ${name}`,
            callBack: (checks) => {
              if (checks.disabled) {
                disableConfirmation();
              }
              setNumLoading(numLoading + 1);
              addClientTag(client.id, tag.name, addingCallback);
            },
          },
          {
            color: "green",
            text: `Apply to All`,
            callBack: (checks) => {
              if (checks.disabled) {
                disableConfirmation();
              }
              const householdMembers =
                client.householdId in households
                  ? households[client.householdId].householdMembers
                  : [];
              householdMembers.forEach((member) => {
                setNumLoading(numLoading + 1);
                addClientTag(member.client.id, tag.name, addingCallback);
              });
            },
          },
        ],
        checks: [
          {
            text: "Don't ask me again for this session",
            info: "Checking will cause future selected client to immediately be added to tag",
            name: "disabled",
          },
        ],
      };
      setConfirmationModal(params);
    } else {
      setNumLoading(1);
      addClientTag(client.id, tag.name, addingCallback);
    }
  };

  const householdIntercept = (clientId) => {
    const { activeClients } = props;
    // Add to active clients if they're not already in the array
    const foundClient = activeClients[clientId];
    if (!foundClient) {
      GetClient(clientId).then((r) => {
        householdConfirm(r);
      });
    } else {
      householdConfirm(foundClient);
    }
  };

  const clientSelected = (e, { value }) => {
    if (e.type === "click" || (e.type === "keydown" && e.key === "Enter")) {
      householdIntercept(value);
    }
  };

  const createClientList = (clients) => {
    const { allClientNames } = props;
    const options = [];
    let sortedClients = sortClients(allClientNames, hub);
    sortedClients = sortedClients.filter((client) => {
      const clientIds = (clients || []).map((c) => {
        return c.id;
      });
      return !clientIds.includes(client.id);
    });
    sortedClients.forEach((client) => {
      options.push({
        text: getOrderedFullClientName(client, hub),
        key: client.id,
        value: client.id,
        onClick: clientSelected,
      });
    });
    return options;
  };

  const deleteTag = () => {
    const { toggleTagModal, deleteTagAction } = props;

    setNumLoading(1);
    deleteTagAction(tag, (result) => {
      if (result) {
        toggleTagModal();
      } else {
        setNumLoading(0);
      }
    });
  };

  const confirmDelete = () => {
    const { setConfirmationModal } = props;
    const params = {
      title: `Delete "${tag.name}" Tag`,
      message: `This will delete "${tag.name}" and remove this tag from all associated clients. This cannot be undone.`,
      icon: "delete",
      buttons: [
        {
          text: "Cancel",
        },
        {
          text: "Delete Tag",
          callBack: deleteTag,
          color: "red",
        },
      ],
    };
    setConfirmationModal(params);
  };

  const editTag = () => {
    const { toggleTagModal, editTagAction } = props;

    setNumLoading(1);
    editTagAction(tag, { tagName }, (result) => {
      if (result) {
        toggleTagModal();
      } else {
        setNumLoading(0);
      }
    });
  };

  const handleKeyUp = (e) => {
    // handles key press for adding tags
    if (e.keyCode === 13) {
      // Enter pressed
      editTag();
    }
  };

  const viewClientCard = (clientId) => {
    const { toggleTagModal } = props;
    history.push(`/hub/${hub.hubId}/clients/${clientId}`);
    toggleTagModal();
  };

  const sortClientTags = (a, b) => {
    if (a === tag.id) return -1;
    if (b === tag.id) return 1;
    if (tags[a].name.toLowerCase() < tags[b].name.toLowerCase()) return -1;
    if (tags[a].name.toLowerCase() > tags[b].name.toLowerCase()) return 1;
    return 0;
  };

  const { advisor, toggleTagModal } = props;
  const clientOptions = createClientList(clientsInTag);
  const disableSaving = numLoading > 0 || !tagName || tagName.match(/^ *$/);
  // TODO may need to change to work for Redtail as well, depending on whether tags can be created/deleted/edited directly
  const isWealthboxUser =
    advisor.integrations.filter((integration) => {
      return integration.type === "wealthbox" && integration.hubId === hub.id;
    }).length >= 1;
  // We can't create tags directly in Wealthbox without a client, so need to create locally, then sync after added to 1 or more clients
  // We need to add a warning message for the client, this boolean checks to see if the warning should appear
  const noWealthboxExternalId = isWealthboxUser && !tag.externalId;
  return (
    <Modal
      data-test="tag-modal"
      onClose={() => {
        toggleTagModal();
      }}
      open
    >
      <Modal.Header>
        <Icon
          name="delete"
          link
          color="grey"
          style={{ float: "right", position: "relative" }}
          onClick={() => {
            toggleTagModal();
          }}
        />
        Manage Tag
      </Modal.Header>
      <Modal.Content>
        <Header as="h5" content="Name" />
        {isWealthboxUser ? (
          <span>{tagName}</span>
        ) : (
          <Input
            className="search"
            fluid
            value={tagName}
            onChange={(e) => {
              setTagName(e.target.value);
            }}
            onKeyUp={handleKeyUp}
          />
        )}
        {noWealthboxExternalId && (
          <div style={{ color: "orange", marginTop: "0.5em" }}>
            <Icon name="question circle outline" />
            This Tag will be synced to your CRM once a client is assigned.
          </div>
        )}
        <Header as="h5" style={{ marginBottom: "0.5em" }}>
          Members ({clientsInTag.length})
        </Header>
        <div style={{ paddingBottom: "1.5em" }}>
          {adding ? (
            <React.Fragment>
              <Dropdown
                className={piiMask("fs-block dd-privacy-mask")}
                disabled={numLoading > 0}
                loading={numLoading > 0}
                onBlur={() => {
                  setSearchInput("");
                }}
                onChange={clientSelected}
                onSearchChange={(e) => {
                  setSearchInput(e.target.value);
                }}
                options={clientOptions}
                placeholder="Search for a client"
                search
                searchInput={{ autoComplete: "no-autofill", autoFocus: true }}
                selection
                value={searchInput}
              />
              <Icon
                color="grey"
                link
                name="cancel"
                onClick={() => {
                  setAdding(false);
                }}
                style={{
                  transform: "translate(3px, 0px)",
                  fontSize: "12pt",
                }}
              />
            </React.Fragment>
          ) : (
            <Button
              basic
              compact
              content="Add"
              disabled={numLoading > 0}
              loading={numLoading > 0}
              onClick={() => {
                setAdding(true);
              }}
            />
          )}
        </div>
        <Grid columns="equal" style={{ maxHeight: "40vh", overflowY: "auto" }}>
          {clientsInTag.map((client) => {
            const clientCopy = { ...client };
            clientCopy.fullName = getOrderedClientName(client, hub);
            clientCopy.tags = (clientCopy.tags || [])
              .sort(sortClientTags)
              .map((tagId) => {
                return { id: tagId, name: tags[tagId].name };
              });
            return (
              <ClientInTag
                client={clientCopy}
                key={client.id}
                className={piiMask("fs-block dd-privacy-mask")}
                onClientTagRemoved={onClientTagRemoved}
                viewClientCard={viewClientCard}
              />
            );
          })}
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        {isWealthboxUser ? (
          <React.Fragment>
            <Popup
              content="Edit or delete the tag in Wealthbox.  A manual sync is required for the changes to appear in the Tag Manager."
              on="hover"
              popperModifiers={{
                preventOverflow: {
                  boundariesElement: "window",
                  enabled: false,
                },
              }}
              position="bottom center"
              style={{
                padding: "0.6em",
                fontSize: "11px",
                fontWeight: "bold",
              }}
              trigger={
                <Button
                  onClick={() => {
                    const win = window.open(
                      `https://app.crmworkspace.com/settings/tags`,
                      "_blank"
                    );
                    win.focus();
                  }}
                  primary
                  loading={numLoading > 0}
                  disabled={disableSaving}
                >
                  Manage in Wealthbox
                </Button>
              }
            />
            <ExportStreamOrTagButton style={{ float: "left" }} tag={tag} />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Button
              negative
              onClick={() => {
                confirmDelete();
              }}
              loading={numLoading > 0}
              disabled={disableSaving}
            >
              Delete
            </Button>
            <Button
              positive
              onClick={() => {
                editTag();
              }}
              loading={numLoading > 0}
              disabled={disableSaving}
            >
              Save
            </Button>
            <ExportStreamOrTagButton style={{ float: "left" }} tag={tag} />
          </React.Fragment>
        )}
      </Modal.Actions>
    </Modal>
  );
}

TagModal.propTypes = {
  activeClients: PropTypes.object.isRequired,
  addClientTag: PropTypes.func.isRequired,
  advisor: PropTypes.shape({
    integrations: PropTypes.array.isRequired,
  }).isRequired,
  allClientNames: PropTypes.array.isRequired,
  households: PropTypes.object.isRequired,
  hub: PropTypes.shape({
    hubId: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    tags: PropTypes.array.isRequired,
  }).isRequired,
  setConfirmationModal: PropTypes.func.isRequired,
  tag: PropTypes.object.isRequired,
  tags: PropTypes.object.isRequired,
  toggleTagModal: PropTypes.func.isRequired,
  confirmAddIsDisabled: PropTypes.bool.isRequired,
  disableConfirmation: PropTypes.func.isRequired,
  removeClientTag: PropTypes.func.isRequired,
  editTagAction: PropTypes.func.isRequired,
  deleteTagAction: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    activeClients: state.hubly.data.hub.clients.activeClients,
    advisor: state.hubly.data.advisor,
    allClientNames: state.hubly.data.hub.clients.allClientNames,
    households: state.hubly.data.hub.households,
    hub: state.hubly.data.hub.selected.hub,
    tag: state.hubly.tags.tag,
    tags: state.hubly.data.hub.tags,
    confirmAddIsDisabled: state.hubly.tags.confirmAddDisabled,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addClientTag: (clientId, tagName, callback) => {
      dispatch(AddClientTag(clientId, tagName, callback));
    },
    removeClientTag: (client, tag, callback) => {
      dispatch(RemoveClientTag(client, tag, callback));
    },
    editTagAction: (tag, updatedParams, callback) => {
      dispatch(EditTagAction(tag, updatedParams, callback));
    },
    deleteTagAction: (tag, callback) => {
      dispatch(DeleteTagAction(tag, callback));
    },
    setConfirmationModal: (id) => {
      dispatch(SetConfirmationModal(id));
    },
    toggleTagModal: (tag) => {
      dispatch(ToggleTagModal(tag));
    },
    disableConfirmation: () => {
      dispatch(DisableConfirmationModal());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TagModal);
