import Moment from "moment";

const getOverdueInfo = (time) => {
  if (!time) {
    return { overdue: false, iconColor: "grey", textColor: "grey" };
  }
  const date = Moment(time);
  const duration = Moment.duration(date.diff());
  const overdueInfo = { overdue: false };
  if (new Date() > new Date(time)) {
    overdueInfo.textColor = "#db2828";
    overdueInfo.iconColor = "red";
    overdueInfo.highlightColor = "#fce6e6";
    overdueInfo.overdue = true;
  } else if (
    duration.years() === 0 &&
    duration.months() === 0 &&
    duration.days() === 0 &&
    duration.hours() <= 12 &&
    duration > 0
  ) {
    overdueInfo.iconColor = "yellow";
    overdueInfo.textColor = "#fbbd08";
    overdueInfo.highlightColor = "#fdf7e4";
  } else {
    overdueInfo.iconColor = "grey";
    overdueInfo.textColor = "grey";
    overdueInfo.highlightColor = "rgba(0,0,0,0)";
  }
  return overdueInfo;
};

export default getOverdueInfo;
