import React from "react";

import { InviteNowTooltip, PermissionTooltip } from "./components";

const newUserInfoColumns = [
  {
    id: "firstName",
    label: "First Name",
  },
  {
    id: "lastName",
    label: "Last Name",
  },
  { id: "email", label: "Email" },
  {
    id: "userRole",
    label: (
      <span>
        Permissions <PermissionTooltip roleTitle="Administrator" />
      </span>
    ),
  },
  {
    id: "inviteNow",
    label: (
      <span>
        Invite Now <InviteNowTooltip />
      </span>
    ),
  },
  { id: "deleteRow", label: "" },
];

export default newUserInfoColumns;
