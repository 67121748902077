import { useCallback } from "react";

import { useQuery } from "hooks";

export const useGetUserSuggestionsInfo = () => {
  const factory = useCallback(() => {
    const query = `query getUserSuggestionsInfo {
      userSuggestions {
        info
        link {
          content
          href
        }
      }
    }
    `;

    const resolver = ({ data }) => {
      return data;
    };

    return { query, resolver };
  }, []);

  return useQuery(factory, {
    options: { cached: true },
  });
};

export default useGetUserSuggestionsInfo;
