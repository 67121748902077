import { API } from "aws-amplify";
import { add, startOfWeek } from "date-fns";

import { getAPIReq } from "./authLib";

export const CreateFilter = async (filter) => {
  const req = await getAPIReq(filter);
  return API.post("HublyAPI", `/filters/`, req);
};

export const GetFilters = async () => {
  const req = await getAPIReq();
  return API.get("HublyAPI", `/filters/`, req);
};

export const GetFilter = async (id) => {
  const req = await getAPIReq();
  return API.get("HublyAPI", `/filters/${id}/`, req);
};

export const EditFilter = async (id, request) => {
  const req = await getAPIReq(request);
  return API.put("HublyAPI", `/filters/${id}/`, req);
};

export const DeleteFilter = async (id) => {
  const req = await getAPIReq();
  return API.del("HublyAPI", `/filters/${id}/`, req);
};

// filters clients according to streams and tags and ANY/ALL rules
export const filterClientWorkflowsByStreamsAndTags = (
  clientWorkflowIds,
  activeClients,
  filters,
  clientWorkflows
) => {
  let filteredClients = clientWorkflowIds.map((cwid) => {
    const clientWorkflow = clientWorkflows[cwid];
    const source = activeClients[clientWorkflow.clientId] || clientWorkflow;

    return {
      clientWorkflowId: cwid,
      tags: source?.tags || [],
      streams: source?.streams || [],
    };
  });

  const { streams, tags } = filters;
  if (tags.length === 0 && streams.length === 0) {
    return filteredClients;
  }

  if (streams.length) {
    if (filters.streamFilterType === "ANY") {
      filteredClients = filteredClients.filter((client) => {
        const clientStreams = client.streams;
        for (let i = 0; i < streams.length; i += 1) {
          if (
            clientStreams.find((stream) => {
              return (stream?.id ? stream.id : stream) === streams[i].id;
            })
          )
            return true;
        }
        return false;
      });
    } else {
      filteredClients = filteredClients.filter((client) => {
        const clientStreams = client.streams;
        for (let i = 0; i < streams.length; i += 1) {
          if (
            !clientStreams.find((stream) => {
              return (stream?.id ? stream.id : stream) === streams[i].id;
            })
          )
            return false;
        }
        return true;
      });
    }
  }

  if (tags.length) {
    if (filters.tagFilterType === "ANY" && tags.length) {
      filteredClients = filteredClients.filter((client) => {
        const clientTags = client.tags;
        for (let i = 0; i < tags.length; i += 1) {
          if (
            clientTags.find((tag) => {
              return tag === tags[i];
            })
          )
            return true;
        }
        return false;
      });
    } else {
      filteredClients = filteredClients.filter((client) => {
        const clientTags = client.tags;
        for (let i = 0; i < tags.length; i += 1) {
          if (
            !clientTags.find((tag) => {
              return tag === tags[i];
            })
          )
            return false;
        }
        return true;
      });
    }
  }

  return filteredClients;
};

export const getClientWorkflowReminders = (tasks = []) => {
  return tasks
    .filter((task) => {
      return !task.completed && task.reminders;
    })
    .map((task) => {
      let earliestTaskReminder = null;
      task.reminders.forEach((r) => {
        if (!r.completedAt && !r.dismissed) {
          earliestTaskReminder = r.time;
        }
      });
      return earliestTaskReminder;
    })
    .filter((t) => {
      return !!t;
    });
};

export const shouldSatisfyReminderFilterCriterion = (
  reminderTime,
  filterReminderType
) => {
  if (!reminderTime || !filterReminderType) return false;
  const now = Date.now();
  const inThirteenHours = add(now, { hours: 13 });
  const startOfThisWeek = startOfWeek(now, { weekStartsOn: 1 });
  const startOfNextWeek = add(startOfThisWeek, { weeks: 1 });
  const weekAfterNextWeek = add(startOfThisWeek, { weeks: 2 });

  const reminder = new Date(reminderTime);

  switch (filterReminderType) {
    case "Overdue":
      return reminder <= now;
    case "Today":
      return reminder > now && reminder <= inThirteenHours;
    case "Upcoming":
      return reminder >= inThirteenHours;
    case "ThisWeek":
      return reminder >= startOfThisWeek && reminder < startOfNextWeek;
    case "NextWeek":
      return reminder >= startOfNextWeek && reminder < weekAfterNextWeek;
    default:
      return false;
  }
};

export const shouldSatisfyFilterCriteria = (
  reminders = [],
  filterReminderTypes
) => {
  return (
    (filterReminderTypes.includes("NoReminder") && reminders.length === 0) ||
    reminders.some((reminderTime) => {
      return filterReminderTypes.some((filterReminderType) => {
        return shouldSatisfyReminderFilterCriterion(
          reminderTime,
          filterReminderType
        );
      });
    })
  );
};

export const filterClientWorkflowsByReminders = (
  reminderTypes = [],
  clientWorkflowIds = [],
  clientWorkflows
) => {
  if (reminderTypes.length === 0) return clientWorkflowIds;
  return clientWorkflowIds.filter((cw) => {
    const clientWorkflow = clientWorkflows[cw.clientWorkflowId];

    return shouldSatisfyFilterCriteria(
      getClientWorkflowReminders(clientWorkflow?.tasks),
      reminderTypes
    );
  });
};

// filter client workflows by visibility and streams and tags
export const filterClientWorkflows = (
  clientWorkflowIds,
  allClientWorkflows,
  activeClients,
  filters
) => {
  const visibleClientWorkflowIds = clientWorkflowIds.filter(
    (clientWorkflowId) => {
      const clientWorkflow = allClientWorkflows[clientWorkflowId];
      return clientWorkflow && !clientWorkflow.isHidden;
    }
  );

  let filteredClientWorkflows = filterClientWorkflowsByStreamsAndTags(
    visibleClientWorkflowIds,
    activeClients,
    filters,
    allClientWorkflows
  );
  filteredClientWorkflows = filterClientWorkflowsByReminders(
    filters?.reminderTypes?.filter((f) => {
      return !f.includes("WaitingOnClient");
    }),
    filteredClientWorkflows,
    allClientWorkflows
  );
  filteredClientWorkflows = filteredClientWorkflows.map((clientMeta) => {
    return allClientWorkflows[clientMeta.clientWorkflowId];
  });
  filteredClientWorkflows = filteredClientWorkflows.filter((clientWorkflow) => {
    // Filter out any clients that are null, as these have no assigned tasks from the previous map
    return !!clientWorkflow;
  });

  return filteredClientWorkflows;
};
