// for finding the coresponding icon to a file extension

/* eslint-disable */
const types = [
  { icon: "file word outline", extensions: ["doc", "docx", "docm", "abw", "odt"] }, // documents
  { icon: "file powerpoint outline", extensions: ["ppt", "pptx", "odp"] }, // presentations
  { icon: "file excel outline", extensions: ["xlsx", "xls", "xlsm", "xlsb", "xlsx", "xlt", "ods"] }, // spreadsheets
  { icon: "file archive outline", extensions: ["zip", "rar", "7z", "arc", "bz", "bz2", "jar", "tar", "gz"] }, // archive
  { icon: "file video outline", extensions: ["3gp", "3g2", "avi", "mpeg", "ogv", "webm", "wmv", "mov", "mkv", "flv", "vob", "gifv", "qt", "mp4", "m4p", "mpeg", "m4v"] }, // video
  { icon: "file audio outline", extensions: ["aa", "aac", "flac", "m4a", "m4b", "m4p", "mp3", "ogg", "oga", "wav", "wma"] }, // audio
  { icon: "file pdf outline", extensions: ["pdf"] }, // pdf
  { icon: "file image outline", extensions: ["jpeg", "jpg", "tiff", "gif", "bmp", "png", "svg"] }, // images
  { icon: "file alternate outline", extensions: ["txt", "csv", "rtf" ] }, // text
];

export default types;
