module.exports = (advisor) => {
  const advisorEmailPrefix = () => {
    return advisor.email.match(/\.(.*)-/)[1];
  };
  const TEST_DOMAIN = "@inbox.testmail.app";
  if (advisor.email.indexOf(TEST_DOMAIN) > 0) {
    return {
      key: advisorEmailPrefix(),
      email: `${advisorEmailPrefix()}${TEST_DOMAIN}`,
      name: advisorEmailPrefix(),
    };
  } else {
    return {
      key: advisor.id,
      email: advisor.email,
      firstName: advisor.firstName,
      lastName: advisor.lastName,
      custom: {
        hubId: advisor.hubs?.[0]?.hubId,
      },
    };
  }
};
