import React from "react";

import { useFeatures } from "../FeatureFlag";
import { FeedbackForm } from "./FeedbackForm";

/*
  This Component connects the component FeedbackForm to Launchdarkly so it can be configured in runtime.

  How it works?
  A Launchdarkly JSON type variation is used as React component's props which are passed to FeedbackForm
  Thanks to the push mechanism of LaunchDarkly any changes to variations trigger a render of FeedbackForm

  Example of a JSON value returned by a LD variation:
  {
    "id": "rQkLx06y"
    "color": "#536DFE",
    "title": "Try Me!"
    "iconHref": "http://dev.myhubly.com/icon.svg",
  }

  See https://app.launchdarkly.com/default/develop/features/typeform-config/variations
*/
export function DynamicallyConfiguredFeedbackForm(props) {
  const formProps = useFeatures("typeform-config", { id: "" });

  return <FeedbackForm id="" {...props} {...formProps} />;
}

DynamicallyConfiguredFeedbackForm.propTypes = {};
DynamicallyConfiguredFeedbackForm.defaultProps = {};

export default DynamicallyConfiguredFeedbackForm;
