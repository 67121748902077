import { useCallback, useEffect } from "react";

import { useBooleanState, useQuery } from "hooks";

export function useHubSpotToken() {
  const queryFactory = useCallback(() => {
    const query = `query hubspotIdentification {
      hubspotIdentification {
        token
        portalId
      }
    }`;

    const resolver = ({ data: { hubspotIdentification }, errors }) => {
      if (errors?.length > 0) throw errors;

      return hubspotIdentification;
    };

    return { query, resolver };
  }, []);

  return useQuery(queryFactory, { options: { cached: true } });
}

export function useHubSpot() {
  const [apiLoaded, setApiLoaded, setApiNotLoaded] = useBooleanState(false);

  useEffect(() => {
    window.hsConversationsOnReady = [setApiLoaded];
    window.hsConversationsSettings = { loadImmediately: false };

    return () => {
      window.hsConversationsOnReady = [];
      setApiNotLoaded();
    };
  }, [setApiLoaded, setApiNotLoaded]);

  return {
    apiLoaded: apiLoaded,
    loadWidget: (token, email) => {
      window.hsConversationsSettings = {
        ...window.hsConversationsSettings,
        identificationEmail: email,
        identificationToken: token,
      };

      window.HubSpotConversations.widget.load();
    },
  };
}

export default useHubSpot;
