import React from "react";
import PropTypes from "prop-types";

import Popper from "components/Popper";

export function HoverEffect({ children, content, ...props }) {
  if (!content) return children;

  return (
    <Popper
      on="hover"
      position="top center"
      style={{ padding: "0.6em", fontSize: "11px", fontWeight: "bold" }}
      trigger={<div style={{ margin: "0 0.25em" }}>{children}</div>}
      data-test="hover-effect"
      {...props}
    >
      {content}
    </Popper>
  );
}

HoverEffect.propTypes = {
  content: PropTypes.node,
  children: PropTypes.node,
};

HoverEffect.defaultProps = {
  children: null,
  content: undefined,
};

export default HoverEffect;
