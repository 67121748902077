import React from "react";
import PropTypes from "prop-types";

import { Dropdown, Icon, Popup } from "semantic-ui-react";

import OptionLayout from "./Layout";

export default function OptionItem({
  title,
  hint,
  description,
  onClear,
  options,
  currentValue,
  onSelection,
  dropDownProps,
  hideClearIcon,
  disabled,
}) {
  return (
    <OptionLayout
      title={title}
      control={
        !hideClearIcon && (
          <Popup
            content="Clear"
            on="hover"
            position="top center"
            trigger={
              <Icon
                name="delete"
                link
                color="grey"
                disabled={disabled}
                onClick={onClear}
              />
            }
          />
        )
      }
      hint={hint}
    >
      <Dropdown
        data-test="hubfeed-settings-assignee-input"
        search
        selection
        placeholder={description}
        onChange={onSelection}
        options={options}
        value={currentValue}
        disabled={disabled}
        selectOnBlur={false}
        {...dropDownProps}
      />
    </OptionLayout>
  );
}

OptionItem.propTypes = {
  title: PropTypes.node.isRequired,
  description: PropTypes.string,
  hint: PropTypes.node,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    })
  ),
  currentValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
    .isRequired,
  onClear: PropTypes.func,
  onSelection: PropTypes.func.isRequired,
  dropDownProps: PropTypes.object,
  hideClearIcon: PropTypes.bool,
  disabled: PropTypes.bool,
};

OptionItem.defaultProps = {
  options: [],
  dropDownProps: {},
  onClear: () => {},
  hideClearIcon: false,
  disabled: false,
  description: "Select an advisor",
  hint: null,
};
