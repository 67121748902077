import React from "react";
import PropTypes from "prop-types";

import { Icon } from "semantic-ui-react";

export function EmptyCircle({
  title,
  size,
  disabled,
  onClick,
  ...buttonProps
}) {
  return (
    <div data-test="empty-circle">
      <Icon.Group
        style={{ fontSize: size }}
        title={title}
        onClick={disabled ? () => {} : onClick}
        {...buttonProps}
      >
        <Icon
          link={!disabled}
          name="user circle"
          style={{ color: !disabled ? "#aaaaaa" : "#cccccc", margin: 0 }}
        />
        {!disabled && (
          <Icon
            link
            corner
            name="add"
            style={{ color: "#aaaaaa", textShadow: "none" }}
          />
        )}
      </Icon.Group>
    </div>
  );
}

EmptyCircle.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string,
  disabled: PropTypes.bool,
  size: PropTypes.string,
};

EmptyCircle.defaultProps = {
  title: undefined,
  disabled: false,
  size: "25px",
  onClick: undefined,
};

export default EmptyCircle;
