import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";

import { FeatureFlag } from "components/FeatureFlag";

import { useUpdateUserInformation } from "./hooks";
import {
  Access,
  AdvisorPermissionDropdown,
  Button,
  Form,
  Input,
  Modal,
  PermissionText,
} from "./components";

function EditUserInformationModal({ user, onCloseCb, onSaveCb }) {
  const {
    loading,
    showLastAdminWarning,
    disableButton,
    isSubmitSuccess,
    register,
    setShowLastAdminWarning,
    onSubmit,
  } = useUpdateUserInformation(
    user.id,
    {
      firstName: user.firstName,
      lastName: user.lastName,
      roleId: user.role.id,
    },
    onCloseCb
  );

  const userRoleRegister = useMemo(() => {
    const { name, value, onChange, ...rest } = register({
      name: "roleId",
      required: true,
    });
    return {
      ...rest,
      name: name,
      roleId: value,
      onRoleChange: (_, { value: onRoleChangeValue }) => {
        onChange(_, {
          name: name,
          value: onRoleChangeValue,
        });
        setShowLastAdminWarning(false);
      },
    };
  }, [register, setShowLastAdminWarning]);

  useEffect(() => {
    if (isSubmitSuccess) onSaveCb();
  }, [isSubmitSuccess, onSaveCb]);

  return (
    <Modal
      data-test="edit-user-info-modal"
      onClose={onCloseCb}
      size="mini"
      open
      closeOnDimmerClick={false}
      style={{ minWidth: "360px" }}
    >
      <Modal.Header>Edit User Information</Modal.Header>
      <Modal.Content>
        <Modal.Description style={{ paddingBottom: "10px" }}>
          <Form>
            <Form.Field>
              <label>First Name</label>
              <Input
                {...register({ name: "firstName", required: true })}
                disabled={loading}
              />
            </Form.Field>
            <Form.Field>
              <label>Last Name</label>
              <Input
                {...register({ name: "lastName", required: true })}
                disabled={loading}
              />
            </Form.Field>
            <Form.Field>
              <label>Email</label>
              <Input disabled name="email" value={user.email} />
              <div
                style={{
                  color: "grey",
                  margin: "auto",
                  width: "max-content",
                  marginTop: "0.5em",
                }}
              >
                Users can change their own email address
              </div>
            </Form.Field>
            <FeatureFlag id="admin-permissions-settings">
              <Access
                required={["Administrator"]}
                fallback={
                  <Form.Field>
                    <label>Permissions</label>
                    <Input disabled name="role" value={user.role.title} />
                    <div
                      style={{
                        color: "grey",
                        margin: "auto",
                        width: "max-content",
                        marginTop: "0.5em",
                      }}
                    >
                      Only Administrators can update Permissions
                    </div>
                    <PermissionText roleTitle={user.role.title} />
                  </Form.Field>
                }
              >
                <Form.Field>
                  <AdvisorPermissionDropdown
                    {...userRoleRegister}
                    disabled={loading}
                  />
                  {showLastAdminWarning && (
                    <p
                      style={{
                        color: "grey",
                        margin: "auto",
                        width: "max-content",
                        marginTop: "0.5em",
                      }}
                    >
                      There should be at least one admin in the practice
                    </p>
                  )}
                </Form.Field>
              </Access>
            </FeatureFlag>
          </Form>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          content="Discard Changes"
          basic
          disabled={loading}
          onClick={onCloseCb}
        />
        <Button
          positive
          content="Save Changes"
          onClick={onSubmit}
          disabled={loading || showLastAdminWarning || disableButton}
          loading={loading}
        />
      </Modal.Actions>
    </Modal>
  );
}

EditUserInformationModal.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    role: PropTypes.shape({ id: PropTypes.string, title: PropTypes.string }),
  }).isRequired,
  onCloseCb: PropTypes.func,
  onSaveCb: PropTypes.func,
};

EditUserInformationModal.defaultProps = {
  onCloseCb: () => {},
  onSaveCb: () => {},
};
export default EditUserInformationModal;
