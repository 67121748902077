import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Controller } from "react-hook-form";
import PropTypes from "prop-types";

import useNewUserInfoForm from "./hooks/useNewUserInfoForm";
import useStyles from "./hooks/useNewUserInfoStyles";
import SubscriptionInfoSection from "./components/SubscriptionInfoSection/SubscriptionInfoSection";
import useGetRoles from "../../../hooks/useGetRoles";
import {
  Button,
  Checkbox,
  EmailInput,
  ErrorMessage,
  Icon,
  Input,
  Modal,
  PermissionDropdown,
  Table,
} from "./components";
import LoadCRMSuggestions from "./LoadCRMSuggestions";
import newUserInfoColumns from "./NewUserInfoColumns";

function NewUserInfoPage({
  defaultNewUserInfo,
  onChange: updateNewUserInfo,
  toNext,
  onClose,
}) {
  const classes = useStyles();

  const { data: roleData, loading: roleLoading } = useGetRoles();
  const defaultRole = useMemo(() => {
    return roleData.find((d) => {
      return d.title === "User";
    });
  }, [roleData]);

  const [isVerifying, setIsVerifying] = useState(false);
  const {
    inviteCount,
    updateInviteCount,
    control,
    setError,
    handleSubmit,
    isSubmitting,
    errors,
    clearErrors,
    fields,
    update,
    append,
    remove,
    getPrunedUserInfo,
  } = useNewUserInfoForm({ defaultNewUserInfo });

  const [isAllowNext, setIsAllowNext] = useState(true);

  const setAllowToProceed = useCallback(() => {
    const validUserInfoRows = getPrunedUserInfo(defaultRole);
    setIsAllowNext(validUserInfoRows.length > 0);
  }, [defaultRole, getPrunedUserInfo, setIsAllowNext]);

  useEffect(() => {
    if (defaultNewUserInfo && defaultRole) {
      setAllowToProceed();
    }
  }, [defaultNewUserInfo, defaultRole, setAllowToProceed]);

  const getFieldError = (rowIndex, fieldName) => {
    return errors.newUserInfo && errors.newUserInfo[rowIndex]
      ? errors.newUserInfo[rowIndex][fieldName]
      : null;
  };

  const newUsers = fields.map((item, i) => {
    return {
      id: item.id,
      firstName: (
        <div className={classes.cell}>
          <Controller
            render={({ field: { onChange, ...restFields } }) => {
              return (
                <Input
                  {...restFields}
                  error={Boolean(getFieldError(i, "firstName"))}
                  className={classes.cell}
                  onChange={(e) => {
                    onChange(e);
                    setAllowToProceed();
                  }}
                  onBlur={() => {
                    updateInviteCount();
                  }}
                />
              );
            }}
            name={`newUserInfo.${i}.firstName`}
            control={control}
          />
          {getFieldError(i, "firstName") && (
            <ErrorMessage message={errors.newUserInfo[i].firstName.message} />
          )}
        </div>
      ),
      lastName: (
        <div className={classes.cell}>
          <Controller
            render={({ field: { onChange, ...restField } }) => {
              return (
                <Input
                  {...restField}
                  error={Boolean(getFieldError(i, "lastName"))}
                  className={classes.cell}
                  onChange={(e) => {
                    onChange(e);
                    setAllowToProceed();
                  }}
                  onBlur={() => {
                    updateInviteCount();
                  }}
                />
              );
            }}
            name={`newUserInfo.${i}.lastName`}
            control={control}
          />
          {getFieldError(i, "lastName") && (
            <ErrorMessage message={errors.newUserInfo[i].lastName.message} />
          )}
        </div>
      ),
      email: (
        <div className={classes.cell}>
          <Controller
            render={({ field: { onChange, ...restFields } }) => {
              return (
                <EmailInput
                  {...restFields}
                  error={getFieldError(i, "email")}
                  onVerifying={setIsVerifying}
                  setError={setError}
                  clearErrors={clearErrors}
                  onChange={(e) => {
                    onChange(e);
                    setAllowToProceed();
                  }}
                  onBlur={() => {
                    updateInviteCount();
                  }}
                />
              );
            }}
            name={`newUserInfo.${i}.email`}
            control={control}
          />
          {getFieldError(i, "email") && (
            <ErrorMessage message={errors.newUserInfo[i].email.message} />
          )}
        </div>
      ),
      userRole: (
        <Controller
          render={({ field: { value, onChange } }) => {
            return (
              <PermissionDropdown
                data-test="add-user-role-dropdown"
                disabled={roleLoading}
                value={value || defaultRole?.id}
                roleData={roleData}
                onChangeCb={onChange}
              />
            );
          }}
          name={`newUserInfo.${i}.roleId`}
          control={control}
        />
      ),
      inviteNow: (
        <Controller
          render={({ field: { value, onChange, ...restFields } }) => {
            return (
              <Checkbox
                className={classes.cell}
                toggle
                checked={value}
                onChange={(_, { checked }) => {
                  onChange(checked);
                  updateInviteCount();
                }}
                {...restFields}
              />
            );
          }}
          name={`newUserInfo.${i}.inviteNow`}
          control={control}
        />
      ),
      deleteRow: item.advisorId ? (
        <Icon className={classes.cell} size="large" name="" />
      ) : (
        <Icon
          className={classes.cell}
          style={{ cursor: "pointer" }}
          size="large"
          name="remove"
          onClick={() => {
            remove(i);
            updateInviteCount();
            setAllowToProceed();
          }}
        />
      ),
    };
  });

  return (
    <React.Fragment>
      <Modal.Body className={classes.modalBody}>
        <p>
          Quickly add and invite members of your team.
          <LoadCRMSuggestions onSuggestions={update} />
        </p>
        <form
          id="addUserForm"
          className={classes.form}
          onSubmit={handleSubmit(() => {
            const prunedUserInfo = getPrunedUserInfo(defaultRole);
            if (prunedUserInfo.length === 0) {
              return;
            }
            updateNewUserInfo([...prunedUserInfo]);
            toNext();
          })}
        >
          <Table className={classes.table}>
            <Table.Content>
              <Table.Header columns={newUserInfoColumns} />
              <Table.Body
                isLoading={false}
                columns={newUserInfoColumns}
                data={newUsers}
              />
            </Table.Content>
          </Table>
          <Button
            className={classes.addButton}
            circular
            type="button"
            icon="add"
            onClick={() => {
              return append({
                firstName: "",
                lastName: "",
                email: "",
                roleId: "",
                inviteNow: false,
              });
            }}
          />
          <SubscriptionInfoSection
            className={classes.subscriptionInfoSection}
            inviteCount={inviteCount}
          />
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          content="Discard Changes"
          basic
          type="button"
          disabled={isVerifying}
          onClick={() => {
            onClose();
          }}
        />
        <Button
          color="blue"
          content="Next"
          type="submit"
          form="addUserForm"
          disabled={
            isVerifying ||
            isSubmitting ||
            roleLoading ||
            Boolean(errors?.newUserInfo?.length) ||
            !isAllowNext
          }
          loading={isVerifying || isSubmitting}
        />
      </Modal.Footer>
    </React.Fragment>
  );
}

NewUserInfoPage.propTypes = {
  defaultNewUserInfo: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      email: PropTypes.string,
      roleId: PropTypes.string,
      inviteNow: PropTypes.bool,
    })
  ),
  onChange: PropTypes.func,
  toNext: PropTypes.func,
  onClose: PropTypes.func,
};

NewUserInfoPage.defaultProps = {
  defaultNewUserInfo: [
    {
      firstName: "",
      lastName: "",
      email: "",
      roleId: "",
      inviteNow: true,
    },
  ],
  onChange: () => {},
  toNext: () => {},
  onClose: () => {},
};

export default NewUserInfoPage;
