import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { ConfirmSignUp } from "aws-amplify-react";
import { Button, Form, Input } from "semantic-ui-react";

import Anchor from "components/Anchor";

import { SetLoginErrorMessage } from "../actions";
import LoginContainer from "./LoginContainer";

class HublyConfirmSignUp extends ConfirmSignUp {
  static propTypes = {
    setLoginErrorMessage: PropTypes.func.isRequired,
  };

  state = {
    codeLabel: false,
  };

  confirm = () => {
    this.props.setLoginErrorMessage("");
    const { code } = this.inputs;
    let valid = true;
    if (!code || code.length < 1) {
      valid = false;
    }
    if (!valid) {
      return;
    }
    super.confirm();
  };

  showComponent() {
    return (
      <LoginContainer title="Confirm Sign Up">
        <Form style={{ overflow: "auto" }}>
          Check your email for the confirmation code
          <Form.Field>
            <label>Confirmation Code</label>
            <Input
              placeholder="Enter your Code"
              key="code"
              name="code"
              autoComplete="off"
              onChange={this.handleInputChange}
            />
          </Form.Field>
          <Anchor onClock={super.resend} style={{ display: "block" }}>
            Resend Verification Code
          </Anchor>
          <Button
            data-test="finish-registration-button"
            floated="right"
            primary
            onClick={this.confirm}
          >
            Finish Registration
          </Button>
          <Button
            basic
            onClick={() => {
              this.props.setLoginErrorMessage("");
              this.changeState("signIn");
            }}
          >
            Back to Sign in
          </Button>
        </Form>
      </LoginContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginErrorMessage: (message) => {
      dispatch(SetLoginErrorMessage(message));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HublyConfirmSignUp);
