import { API } from "aws-amplify";

import { getAPIReq } from "./authLib";

export const GetPractice = async (integrationId) => {
  const req = await getAPIReq();
  return API.get("HublyAPI", `/practices/${integrationId}/`, req);
};

export const CreatePractice = async (request) => {
  const req = await getAPIReq(request);
  return API.post("HublyAPI", `/practices/`, req);
};

export const EditPractice = async (practiceId, request) => {
  const req = await getAPIReq(request);
  return API.put("HublyAPI", `/practices/${practiceId}/`, req);
};
