import React from "react";
import PropTypes from "prop-types";

import { Button } from "semantic-ui-react";

export function AddRemoveButton({
  disabled,
  loading,
  isCreating,
  addText,
  saveText,
  removeText,
  onRemove,
  onAdd,
  children,
  style,
  saveButtonTestId,
  removeButtonTestId,
}) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: isCreating ? "flex-end" : "space-between",
        marginTop: "1em",
        ...style,
      }}
    >
      {!isCreating && (
        <Button
          content={removeText}
          disabled={disabled}
          loading={loading}
          negative
          onClick={onRemove}
          date-test={removeButtonTestId}
        />
      )}
      <div>
        {children}
        <Button
          content={isCreating ? addText : saveText}
          floated="right"
          loading={loading}
          disabled={disabled}
          onClick={onAdd}
          positive
          data-test={saveButtonTestId}
        />
      </div>
    </div>
  );
}

AddRemoveButton.propTypes = {
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  isCreating: PropTypes.bool,
  children: PropTypes.node,
  onRemove: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  addText: PropTypes.string,
  saveText: PropTypes.string,
  removeText: PropTypes.string,
  style: PropTypes.object,
  saveButtonTestId: PropTypes.string,
  removeButtonTestId: PropTypes.string,
};

AddRemoveButton.defaultProps = {
  addText: "Add",
  saveText: "Save",
  removeText: "Remove",
  disabled: false,
  loading: false,
  isCreating: true,
  children: null,
  style: {},
  saveButtonTestId: "add-button",
  removeButtonTestId: "remove-button",
};

export default AddRemoveButton;
