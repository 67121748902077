import React, { useRef, useState } from "react";
import PropTypes from "prop-types";

import { Checkbox } from "semantic-ui-react";

import CompactButton from "components/CompactButton";

const exceedsMaxFileSizeLimit = (fileSize) => {
  return fileSize > 26214400;
};
export function AttachmentAdd({ disabled, fireError, onAdd }) {
  const inputFileRef = useRef(null);
  const [onlyAddToNewClients, setOnlyAddToNewClients] = useState(false);

  return (
    <React.Fragment>
      <CompactButton
        minWidth={0}
        disabled={disabled}
        onClick={() => {
          inputFileRef.current.click();
        }}
      >
        Add Attachment
      </CompactButton>
      <div>
        <Checkbox
          style={{ paddingTop: "0.5em" }}
          checked={onlyAddToNewClients}
          onChange={() => {
            setOnlyAddToNewClients(!onlyAddToNewClients);
          }}
          label="Only add to new clients"
        />
      </div>
      <input
        type="file"
        multiple
        hidden
        ref={inputFileRef}
        onChange={(e) => {
          const uploads = [];
          Array.from(e.target.files).forEach((file) => {
            const { name, lastModifiedDate, size } = file;
            if (exceedsMaxFileSizeLimit(size)) {
              fireError(
                `Unable to upload file ${name}, file size is over 25MB or greater. Please verify file size before retrying.`,
                Error("File size is larger than 25MB")
              );
            } else {
              uploads.push({
                id: name,
                name: name,
                uploadTime: lastModifiedDate,
                size: size,
                file: file,
                inProgress: true,
              });
            }
          });
          if (uploads.length > 0) {
            onAdd(uploads, onlyAddToNewClients);
          }
        }}
      />
    </React.Fragment>
  );
}

AttachmentAdd.propTypes = {
  disabled: PropTypes.bool,
  onAdd: PropTypes.func.isRequired,
  fireError: PropTypes.func,
};

AttachmentAdd.defaultProps = {
  disabled: false,
  fireError: () => {},
};

export default AttachmentAdd;
