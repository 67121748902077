import React from "react";
import DatePicker from "react-datepicker/es";
import PropTypes from "prop-types";

import { add } from "date-fns";
import { Input } from "semantic-ui-react";

import Selector from "components/Selector";

import {
  daysOptions,
  getFrequencyOptions,
  getIntervalOptions,
  repeatOptions,
} from "../../../../helper";

export function RepeatSelector({ name, value, onChange }) {
  const {
    repeat = "EVERY",
    interval = "1",
    frequency = "YEARLY",
    weekStartDay = "MO",
    dateStart,
  } = value || {};

  const updateValue = (val) => {
    onChange(null, {
      name: name,
      value: {
        ...value,
        ...val,
      },
    });
  };

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <div style={{ marginTop: "1em", marginRight: "1em" }}>
          <Selector
            name="repeat"
            dropdownProps={{
              search: false,
              compact: true,
            }}
            selection={repeatOptions}
            multiple={false}
            selected={repeat}
            onSelect={(val) => {
              updateValue({ repeat: val });
            }}
          />
        </div>
        {repeat === "EVERY" && (
          <React.Fragment>
            <div style={{ marginTop: "1em", marginRight: "1em" }}>
              <Selector
                name="interval"
                selection={getIntervalOptions()}
                dropdownProps={{
                  search: false,
                  compact: true,
                }}
                selected={interval}
                multiple={false}
                onSelect={(val) => {
                  updateValue({ interval: val });
                }}
              />
            </div>
            <div style={{ marginTop: "1em", marginRight: "1em" }}>
              <Selector
                name="frequency"
                selection={getFrequencyOptions(interval)}
                dropdownProps={{
                  search: false,
                  compact: true,
                }}
                multiple={false}
                selected={frequency}
                onSelect={(val) => {
                  updateValue({ frequency: val });
                }}
              />
            </div>
            {frequency === "WEEKLY" && (
              <React.Fragment>
                <div
                  style={{
                    marginTop: "1.7em",
                    marginRight: "1em",
                  }}
                >
                  on
                </div>
                <div
                  style={{
                    marginTop: "1em",
                    marginRight: "1em",
                  }}
                >
                  <Selector
                    name="weekStartDay"
                    selection={daysOptions}
                    dropdownProps={{
                      search: false,
                      compact: true,
                    }}
                    multiple={false}
                    selected={weekStartDay}
                    onSelect={(val) => {
                      updateValue({ weekStartDay: val });
                    }}
                  />
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
        <div style={{ marginBottom: "1em", marginTop: "1em" }}>
          <span style={{ marginRight: "1em" }}>
            {repeat === "EVERY" ? "starting" : "on"}
          </span>
          <DatePicker
            selected={dateStart}
            onChange={(val) => {
              updateValue({ dateStart: val });
            }}
            customInput={<Input />}
            minDate={add(new Date(), { days: 1 })}
          />
        </div>
      </div>
    </div>
  );
}

RepeatSelector.propTypes = {
  name: PropTypes.string,
  value: PropTypes.object,
  onChange: PropTypes.func,
};
RepeatSelector.defaultProps = {
  name: "",
  value: null,
  onChange: () => {},
};

export default RepeatSelector;
