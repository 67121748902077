import React from "react";
import PropTypes from "prop-types";

import { ColumnSpinner } from "./components";

export function WorkflowColumnLoader({ children, active }) {
  return (
    <div
      style={{
        display: "flex",
        flexFlow: "row",
        justifyContent: "space-between",
      }}
    >
      <span>{children}</span>
      <ColumnSpinner style={{ marginRight: "6px" }} active={active} />
    </div>
  );
}

WorkflowColumnLoader.propTypes = {
  children: PropTypes.node,
  active: PropTypes.bool,
};

WorkflowColumnLoader.defaultProps = {
  children: null,
  active: false,
};

export default WorkflowColumnLoader;
