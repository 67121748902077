import React from "react";
import PropTypes from "prop-types";

import { MultipleClientDropdown } from "components/ClientDropdown";

import { AgeRuleLayout, AgeSelector } from "./components";

export function AgeRuleForm({ register, data }) {
  return (
    <AgeRuleLayout
      ageSelector={<AgeSelector {...register({ name: "ageSelector" })} />}
      ruleClientDropdown={
        <MultipleClientDropdown
          {...register({
            name: "selectedStreamsTags",
            required: true,
            errortext: "Select streams or tags",
          })}
          data-test="ruleModal-dropdown-condition"
          placeholder="Select streams or tags"
          exclude={data.excludeItems.map(({ id }) => {
            return id;
          })}
          showStreams
          showTags
          dropdownStyle={{ marginBottom: 0 }}
        />
      }
    />
  );
}

AgeRuleForm.propTypes = {
  register: PropTypes.func.isRequired,
  data: PropTypes.object,
};

AgeRuleForm.defaultProps = {
  data: null,
};

export default AgeRuleForm;
