import React from "react";

import Sidebar from "components/Sidebar";

import { useFeedFilters, useReminders } from "./hooks";
import { FeedContent, FeedSettings, ReminderStats } from "./components";

export function HubFeed() {
  const [currentFilters, setFilters, setToDefault] = useFeedFilters();
  const [counts, reminders] = useReminders(currentFilters);

  return (
    <Sidebar
      title="Hub Feed"
      tab={<ReminderStats {...counts} />}
      onOpening={reminders.fetch}
      onOpened={reminders.setShown}
      onClosed={reminders.setNotShown}
    >
      <FeedSettings
        filters={currentFilters}
        onChange={setFilters}
        onClear={setToDefault}
      />
      <FeedContent reminders={reminders} />
    </Sidebar>
  );
}

export default HubFeed;
