import { API } from "aws-amplify";

import { getAPIReq } from "./authLib";

export const AddTemplate = async (taskId, request) => {
  const req = await getAPIReq(request);
  return API.post("HublyAPI", `/tasks/${taskId}/emailTemplates/`, req);
};

export const EditTemplate = async (id, request) => {
  const req = await getAPIReq(request);
  return API.put("HublyAPI", `/emailTemplates/${id}/`, req);
};

export const GetTemplate = async (id) => {
  const req = await getAPIReq();
  return API.get("HublyAPI", `/emailTemplates/${id}/`, req);
};

export const DeleteTemplate = async (id) => {
  const req = await getAPIReq();
  return API.del("HublyAPI", `/emailTemplates/${id}/`, req);
};
