import { useMemo, useState } from "react";

import { useQuery } from "hooks";

import { getAdvisors } from "./query";
import { useSelectedTeamMembers, useServiceTeamAction } from ".";

export function useServiceTeam({ serviceTeam, serviceRoles }) {
  const [matchedServiceTeam, setMatchedServiceTeam] = useState(null);
  const [teamNameExists, setTeamNameExistence] = useState(false);
  const [teamName, setTeamName] = useState(null);

  const advisorsPage = useQuery(getAdvisors);
  const selectedMembers = useSelectedTeamMembers(
    useMemo(() => {
      return {
        serviceTeamMembers: serviceTeam?.serviceTeamMembers || [],
        serviceRoles: serviceRoles || [],
      };
    }, [serviceTeam, serviceRoles])
  );
  const { loading, add, edit, merge, find } = useServiceTeamAction();

  const withAdvisorData = (role) => {
    return {
      ...role,
      assignedAdvisors: selectedMembers.getByRole(role.id),
      advisorsPage: advisorsPage,
    };
  };

  return {
    loading: loading,
    totalClient: serviceTeam?.numActiveClients,
    totalMatchedTeamClient: matchedServiceTeam?.numActiveClients,
    roles: serviceRoles.map(withAdvisorData),
    hasMatchedTeam: () => {
      return Boolean(matchedServiceTeam?.id);
    },
    hasDuplicateTeamName: () => {
      return teamNameExists;
    },
    editMode: Boolean(serviceTeam?.id),
    isDefaultTeam: serviceTeam?.isDefaultSettings,
    name: teamName || serviceTeam?.name,
    setTeamName: (name) => {
      setTeamName(name);
      return find({ serviceTeamName: name, filterEmptyMember: false }).then(
        (resp) => {
          setTeamNameExistence(Boolean(resp?.id));
        }
      );
    },
    disableActionButton: () => {
      const isNameModified = () => {
        return teamName && teamName !== serviceTeam?.name;
      };
      const disableInEditMode = () => {
        return !isNameModified() && !selectedMembers.isModified;
      };
      const disableInAddMode = () => {
        return (
          !isNameModified() ||
          !selectedMembers.isModified ||
          Boolean(matchedServiceTeam?.id)
        );
      };
      const disable = () => {
        return serviceTeam?.id ? disableInEditMode() : disableInAddMode();
      };

      return selectedMembers.isEmpty || teamNameExists || disable();
    },
    add: () => {
      return add({ name: teamName, teamMembers: selectedMembers.all() });
    },
    edit: () => {
      return edit({
        id: serviceTeam.id,
        name: teamName,
        teamRoleIds: serviceTeam.serviceTeamRoleIds,
        teamMembers: selectedMembers.modified(),
      });
    },
    merge: () => {
      return merge({
        sourceId: serviceTeam.id,
        targetId: matchedServiceTeam.id,
        sourceTeamRoleIds: serviceTeam.serviceTeamRoleIds,
        teamMembers: selectedMembers.modified(),
      });
    },
    updateSelection: (role) => {
      const updatedMembers = selectedMembers.setByRole(role.id, role);
      return find({
        serviceTeamId: serviceTeam?.id,
        serviceTeamRoleIds: serviceTeam?.serviceTeamRoleIds,
        teamMembers: updatedMembers,
      }).then((resp) => {
        setMatchedServiceTeam(serviceTeam?.id !== resp?.id ? resp : null);
      });
    },
    clear: () => {
      selectedMembers.clear();
      setMatchedServiceTeam(null);
    },
  };
}

export default useServiceTeam;
