import { useCallback, useEffect } from "react";

import { useQuery } from "hooks";
import useItemState from "components/ManageListModal/hooks/useItemState";

import { useServiceRoleAction, useSyncableRoleOptions } from ".";

const getServiceRolesFactory = () => {
  const query = `query getServiceRoles {
        serviceRoles {
          pageInfo {
            hasNextPage
            endCursor
          }
          edges {
              node {
                id
                  name
                  isSynced
                  showMore
                  syncableName
              }
          }
        }
        hub {
          integrationSet(first: 1) {
            edges {
              node {
                type
              }
            }
          }
        }
        availableSyncableNames
      }`;

  const resolver = ({ data }) => {
    return {
      serviceRoles: data.serviceRoles.edges.map(({ node }) => {
        return { ...node };
      }),
      hubIntegrations: data.hub.integrationSet.edges.map(({ node }) => {
        return node.type;
      }),
      availableSyncableNames: data.availableSyncableNames,
    };
  };

  return { query, resolver };
};

const useManageServiceRoles = () => {
  const filterShowMore = (val) => {
    return ({ showMore }) => {
      return showMore === val;
    };
  };

  const [visibleItems, dispatchVisibleItems] = useItemState();
  const [showMoreItems, dispatchShowMoreItems] = useItemState();

  const dispatch = useCallback(
    (data) => {
      dispatchVisibleItems({
        type: "set",
        value: data.filter(filterShowMore(false)),
      });
      dispatchShowMoreItems({
        type: "set",
        value: data.filter(filterShowMore(true)),
      });
    },
    [dispatchVisibleItems, dispatchShowMoreItems]
  );

  const {
    loading,
    data: { serviceRoles, availableSyncableNames = [], hubIntegrations = [] },
    reloadData: refetch,
    numOfFetches,
  } = useQuery(useCallback(getServiceRolesFactory, []));

  useEffect(() => {
    if (serviceRoles) dispatch(serviceRoles);
  }, [serviceRoles, dispatch]);

  const { actionable, ...otherProps } = useServiceRoleAction(refetch);
  const { optionable, ...otherOptions } = useSyncableRoleOptions(
    availableSyncableNames,
    hubIntegrations
  );

  return {
    ...otherProps,
    ...otherOptions,
    loading: loading && numOfFetches === 0,
    visibleItems: visibleItems.map(actionable).map(optionable),
    showMoreItems: showMoreItems.map(actionable).map(optionable),
    dispatchVisibleItems: dispatchVisibleItems,
    dispatchShowMoreItems: dispatchShowMoreItems,
  };
};

export default useManageServiceRoles;
