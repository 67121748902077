import React, { useContext, useState } from "react";
import PropTypes from "prop-types";

import { useDropdownData } from "hooks";
import { ClientDropdown } from "components/ClientDropdown";
import {
  clientOptionProps,
  excludeDropdownData,
  streamOptionProps,
  tagOptionProps,
} from "components/ClientDropdown/helper";

import { HubContext } from "scenes/Hubly/components/Workspace/Provider";

export function MultipleClientDropdown({
  name,
  defaultSection,
  value,
  prefetch,
  placeholder,
  showStreams,
  showTags,
  showClients,
  onChange,
  dropdownStyle,
  closeOnClick,
  exclude,
  ...props
}) {
  const { fetchTags, fetchStreams, searchClients } = useContext(HubContext);
  const [searchText, setSearchText] = useState("");

  const clientsData = useDropdownData({
    searchText: showClients ? searchText : "",
    type: "client",
    fetchFunction: searchClients,
    initialLoad: showClients && (prefetch || (value && value.length !== 0)),
  });

  const tagsDropdownData = useDropdownData({
    searchText: searchText,
    type: "tag",
    fetchFunction: fetchTags,
    initialLoad: showTags && (prefetch || (value && value.length !== 0)),
  });
  const tagsData = excludeDropdownData(exclude, tagsDropdownData);

  const streamsDropdownData = useDropdownData({
    searchText: searchText,
    type: "stream",
    fetchFunction: fetchStreams,
    initialLoad: showStreams && (prefetch || (value && value.length !== 0)),
  });
  const streamsData = excludeDropdownData(exclude, streamsDropdownData);

  const selectedItemValues = (value || []).map(({ id }) => {
    return id;
  });

  const options = new Set(
    streamsData.data
      .map(streamOptionProps)
      .concat(tagsData.data.map(tagOptionProps))
      .concat(clientsData.data.map(clientOptionProps))
  );

  value.forEach((val) => {
    if (val.type === "stream") {
      options.add(streamOptionProps(val));
    } else if (val.type === "tag") {
      options.add(tagOptionProps(val));
    } else if (val.type === "client") {
      options.add(clientOptionProps(val));
    }
  });

  return (
    <ClientDropdown
      defaultSection={defaultSection}
      selectedItemValues={selectedItemValues}
      placeholder={placeholder}
      streamsData={showStreams ? streamsData : null}
      clientsData={showClients ? clientsData : null}
      tagsData={showTags ? tagsData : null}
      closeOnClick={closeOnClick}
      onSearchChange={setSearchText}
      dropdownProps={{
        ...props,
        multiple: true,
        clearable: true,
        selection: true,
        options: Array.from(options),
        style: { width: "380px", marginBottom: "1em", ...dropdownStyle },
        renderLabel: (item, _, object) => {
          object.content = item.name;
          if (item.type === "client") {
            object.style = {};
          } else {
            object.style = {
              backgroundColor: item.icon.color,
              color: "white",
            };
            if (item.type === "tag") {
              object.className = "rule_modal_dropdown_tag_label";
              object.icon = "hashtag";
            }
          }
          return object;
        },
        onChange: (_, { value: selectedValues }) => {
          // dropdownOnChange is only called when removing items from the list since it is a controlled component
          // Thus we only need to filter our selectedItem to remove the removed element(s)
          onChange(null, {
            name: name,
            value: value.filter(({ id }) => {
              return selectedValues.indexOf(id) >= 0;
            }),
          });
        },
      }}
      onSyncProgressUpdated={() => {}}
      onClientClick={(client) => {
        if (!selectedItemValues.includes(client.id)) {
          onChange(null, {
            name: name,
            value: [...(value || []), { ...client, type: "client" }],
          });
        }
      }}
      onStreamOrTagClick={({ obj, type }) => {
        if (!selectedItemValues.includes(obj.id)) {
          onChange(null, {
            name: name,
            value: [...(value || []), { ...obj, type: type }],
          });
        }
      }}
    />
  );
}

MultipleClientDropdown.defaultProps = {
  prefetch: false,
  showStreams: false,
  showTags: false,
  showClients: false,
  placeholder: "",
  defaultSection: "Streams",
  dropdownStyle: {},
  closeOnClick: true,
  exclude: [],
};

MultipleClientDropdown.propTypes = {
  prefetch: PropTypes.bool,
  name: PropTypes.string.isRequired,
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  showStreams: PropTypes.bool,
  showTags: PropTypes.bool,
  showClients: PropTypes.bool,
  placeholder: PropTypes.string,
  dropdownStyle: PropTypes.object,
  exclude: PropTypes.array,
  defaultSection: PropTypes.string,
  closeOnClick: PropTypes.bool,
};

export default MultipleClientDropdown;
