import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {
  Button,
  Checkbox,
  Header,
  Icon,
  Input,
  Modal,
  Segment,
} from "semantic-ui-react";

import { SetAlert } from "components/Alerts/actions";
import ColorPicker from "components/ColorPicker";

import { AlterProcess, NewProcess } from "../actions";

class ConfigureProcessModal extends React.Component {
  static defaultProps = {
    process: null,
  };

  static propTypes = {
    process: PropTypes.object,
    close: PropTypes.func.isRequired,
    workflows: PropTypes.object.isRequired,
    newProcess: PropTypes.func.isRequired,
    alterProcess: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    const { process, workflows } = props;
    const processWorkflows = new Set(); // workflows in this process
    this.unavailableWorkflows = new Set(); // workflows in other processes
    const existingProcessNames = [];
    Object.values(workflows).forEach((workflow) => {
      if (workflow.process) {
        existingProcessNames.push(workflow.process.name);
        if (process && workflow.process.id === process.id) {
          processWorkflows.add(workflow.id);
        } else {
          this.unavailableWorkflows.add(workflow.id);
        }
      }
    });

    this.sortedWorkflows = Object.values(workflows).sort((a, b) => {
      let A = 0;
      let B = 0;
      if (processWorkflows.has(a.id)) A -= 1;
      if (processWorkflows.has(b.id)) B -= 1;
      if (this.unavailableWorkflows.has(a.id)) A += 1;
      if (this.unavailableWorkflows.has(b.id)) B += 1;
      return A - B;
    });

    this.state = {
      loading: false,
      title: process ? process.name : "",
      description: process ? process.description : "",
      color: process ? process.color : null,
      workflows: processWorkflows,
      processNames: existingProcessNames,
    };
  }

  toggleInclusion = (id) => {
    const { workflows } = this.state;
    if (workflows.has(id)) {
      workflows.delete(id);
    } else {
      workflows.add(id);
    }
    this.setState({ workflows });
  };

  save = async () => {
    const { process, newProcess, alterProcess, setAlert } = this.props;
    const { title, description, workflows, color, processNames } = this.state;

    let complete = true;
    if (!process && processNames.includes(title)) {
      setAlert({
        type: "warning",
        text: `Process ${title} already exists. Please verify process name and try again.`,
      });
      complete = false;
    }
    if (title.length === 0) {
      setAlert({
        type: "warning",
        text: "Input field cannot be empty. Please verify process name and try again.",
      });
      complete = false;
    }
    if (!color || color.length === 0) {
      setAlert({
        type: "warning",
        text: "Process color not set. Please select a color and try again.",
      });
      complete = false;
    }
    if (!complete) return;

    this.setState({ loading: true });
    const request = {
      name: title,
      description: description,
      color: color,
      workflows: [...workflows],
    };

    const callback = (success) => {
      const { close } = this.props;
      this.setState({ loading: false });
      if (success) {
        close();
      } else {
        setAlert({ type: "error", text: "Failed to configure process." });
      }
    };

    if (process) {
      // editing
      alterProcess(process.id, request, callback);
    } else {
      // creating
      newProcess(request, callback);
    }
  };

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };

  renderWorkflow = (workflow) => {
    const { workflows } = this.state;
    const unavailable = this.unavailableWorkflows.has(workflow.id);
    return (
      <Segment
        style={{
          display: "flex",
          margin: "0 0 0.5em 0",
          padding: "0.5em",
          paddingRight: "0em",
          alignItems: "center",
          opacity: unavailable ? "0.4" : 1,
        }}
      >
        <Checkbox
          checked={workflows.has(workflow.id)}
          style={{ width: "5%" }}
          onChange={() => {
            this.toggleInclusion(workflow.id);
          }}
          disabled={unavailable}
        />
        <div
          style={{
            paddingRight: "1em",
            width: "75%",
            fontWeight: "bold",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {workflow.name}
        </div>
        <div
          style={{
            width: "20%",
            borderLeft: "1px solid #dedede",
            color: "grey",
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <div>
            <Icon name="user" color="grey" />
            {workflow.clients.length}
            &nbsp;Client
            {workflow.clients.length !== 1 && "s"}
          </div>
        </div>
        <div
          style={{
            width: "20%",
            borderLeft: "1px solid #dedede",
            color: "grey",
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <div>
            <Icon name="check square" color="grey" />
            {workflow.tasks.length}
            &nbsp;Task
            {workflow.tasks.length !== 1 && "s"}
          </div>
        </div>
      </Segment>
    );
  };

  render() {
    const { loading, title, description, color } = this.state;
    const { close } = this.props;

    return (
      <Modal open onClose={close} size="small">
        <Modal.Header>
          Configure Process
          <Icon
            link
            color="grey"
            name="delete"
            style={{ float: "right" }}
            onClick={close}
          />
        </Modal.Header>
        <Modal.Content>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex" }}>
              <div style={{ flexGrow: 1 }}>
                <Header as="h5">Name</Header>
                <Input
                  fluid
                  autoComplete="off"
                  name="title"
                  value={title}
                  placeholder="Process title"
                  onChange={this.handleChange}
                />
              </div>
              <div style={{ marginLeft: "1em", marginRight: "1em" }}>
                <Header as="h5">Color</Header>
                <ColorPicker
                  color={color}
                  setColor={(c) => {
                    this.setState({ color: c });
                  }}
                />
              </div>
            </div>
            <div style={{ marginTop: "1em" }}>
              <Header as="h5">Description</Header>
              <Input
                fluid
                autoComplete="off"
                name="description"
                value={description}
                placeholder="Process description"
                onChange={this.handleChange}
              />
            </div>
            <div style={{ marginTop: "1em" }}>
              <Header as="h5">Workflows</Header>
              <div
                style={{
                  width: "100%",
                  maxHeight: "300px",
                  overflowY: "scroll",
                }}
              >
                {this.sortedWorkflows.map((workflow) => {
                  return this.renderWorkflow(workflow);
                })}
              </div>
            </div>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button
            positive
            content="Save"
            loading={loading}
            disabled={loading || title.length === 0 || !color}
            onClick={this.save}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    newProcess: (request, callback) => {
      dispatch(NewProcess(request, callback));
    },
    alterProcess: (id, request, callback) => {
      dispatch(AlterProcess(id, request, callback));
    },
    setAlert: (alert) => {
      dispatch(SetAlert(alert));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfigureProcessModal);
