import React from "react";
import PropTypes from "prop-types";

import { Icon } from "semantic-ui-react";

import NumericBadge from "components/NumericBadge/NumericBadge";

import ReminderSection from "../ReminderSection";
import TotalSection from "../TotalSection";

export const SummaryRow = ({
  active,
  opened,
  onClick,
  clientLabel,
  workflowLabel,
  totalClients,
  totalWorkflow,
  totalOverdue,
  totalComingDue,
  totalUpcoming,
  collapsible,
}) => {
  const ChevronIcon = () => {
    return (
      <div
        style={{
          color: "black",
          fontWeight: "bold",
        }}
      >
        <Icon
          name={opened ? "chevron down" : "chevron right"}
          style={{ fontSize: "15pt" }}
        />
      </div>
    );
  };

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        alignItems: "center",
        opacity: active ? "1" : "0.5",
      }}
      onClick={onClick}
    >
      {totalWorkflow > 0 && collapsible && <ChevronIcon opened={opened} />}
      <TotalSection
        label={workflowLabel}
        total={totalWorkflow}
        data-test="process-workflows-total"
        icon={
          <Icon
            name="align left"
            style={{ fontSize: "15pt", transform: "rotate(90deg)" }}
          />
        }
      />
      <TotalSection
        label={clientLabel}
        total={totalClients}
        data-test="process-clients-total"
        icon={<Icon name="clone outline" style={{ fontSize: "15pt" }} />}
      />
      <ReminderSection style={{ justifyContent: "flex-end" }}>
        <NumericBadge
          data-test="total-reminder-overdue"
          color="red"
          value={totalOverdue}
        />
        <NumericBadge
          data-test="total-reminder-coming-due"
          color="yellow"
          value={totalComingDue}
        />
        <NumericBadge
          data-test="total-reminder-upcoming"
          color="green"
          value={totalUpcoming}
        />
      </ReminderSection>
    </div>
  );
};

SummaryRow.defaultProps = {
  active: true,
  opened: false,
  collapsible: false,
  clientLabel: "Client",
  workflowLabel: "Workflow",
};

SummaryRow.propTypes = {
  collapsible: PropTypes.bool,
  active: PropTypes.bool,
  opened: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  totalClients: PropTypes.number.isRequired,
  totalWorkflow: PropTypes.number.isRequired,
  totalOverdue: PropTypes.number.isRequired,
  totalComingDue: PropTypes.number.isRequired,
  totalUpcoming: PropTypes.number.isRequired,
  clientLabel: PropTypes.string,
  workflowLabel: PropTypes.string,
};

export default SummaryRow;
