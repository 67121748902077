import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { useBooleanState } from "hooks";

import {
  Badge,
  Loader,
  useAssignees,
  UserAvatar,
  UserSelector,
} from "./components";

export function UncontrolledAssigner({
  isOpen,
  onClick,
  onClose,
  loading,
  disabled,
  advisedAssigneeId,
  currentAssignee,
  size,
  fetchAssignees,
  style,
  onAssign,
  children,
}) {
  const [fetch, loadingAssignees, assignees] = useAssignees(fetchAssignees);
  const [waitingOnSubmit, setWaitingOnSubmit, stopWaiting] = useBooleanState();

  useEffect(() => {
    if (isOpen) fetch();
  }, [isOpen, fetch]);

  if (loading || loadingAssignees) {
    return (
      <div style={{ ...style }}>
        <Loader size={size} active inline />
      </div>
    );
  }

  return (
    <div
      data-test="assigner"
      data-dd-action-name="Assigner"
      style={{ ...style }}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <UserSelector
        onClose={onClose}
        onOpen={onClick}
        open={isOpen}
        disabled={disabled}
        data-test="assigner-popup"
        offset="-7px, 0"
        position="bottom left"
        loading={waitingOnSubmit}
        initSelectedUserId={
          advisedAssigneeId || currentAssignee?.id || "unassigned"
        }
        users={assignees}
        forceEnabled={Boolean(advisedAssigneeId)}
        onSelect={async (assignee) => {
          setWaitingOnSubmit();
          try {
            await onAssign(assignee.id, assignee);
            if (onClose) onClose();
          } finally {
            stopWaiting();
          }
        }}
      >
        <Badge content={children}>
          <UserAvatar user={currentAssignee} disabled={disabled} size={size} />
        </Badge>
      </UserSelector>
    </div>
  );
}

UncontrolledAssigner.propTypes = {
  onAssign: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  onClick: PropTypes.func,
  isOpen: PropTypes.bool,
  fetchAssignees: PropTypes.func,
  size: PropTypes.oneOf(["small", "medium", "large", "big"]),
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  style: PropTypes.object,
  advisedAssigneeId: PropTypes.string,
  currentAssignee: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
};

UncontrolledAssigner.defaultProps = {
  children: null,
  fetchAssignees: undefined,
  currentAssignee: undefined,
  advisedAssigneeId: undefined,
  onClick: undefined,
  onClose: undefined,
  isOpen: false,
  loading: false,
  disabled: false,
  size: "medium",
  style: {},
};

export function Assigner(props) {
  const [isOpen, open, close] = useBooleanState();

  return (
    <UncontrolledAssigner
      {...props}
      onClick={open}
      onClose={close}
      isOpen={isOpen}
    />
  );
}

export default Assigner;
