/* eslint-disable max-len */
export default {
  meta: {
    creationDate: "2019-09-24",
    version: "1.0.0",
  },
  templates: [
    {
      id: 0,
      name: "Client Prospecting",
      description:
        "Client prospecting workflows are used in your sales funnel when nurturing new leads, emailing content, and conducting your initial consultation with a prospect. These workflows are integral to building an efficient, well-managed sales process that helps you hone in on the right types of clients and create an amazing experience right from the start.",
      processes: [
        {
          name: "New Client Prospecting",
          color: "#CFFAF9",
          description: "",
          workflows: [
            "New Client 1: Lead/Referral",
            "New Client 2: Initial Meeting/Consultation",
          ],
        },
      ],
      workflows: [
        {
          id: 0,
          name: "New Client 1: Lead/Referral",
          description:
            "This workflow is used for leads and new referrals. These are tasks that will ensure that each lead is properly nurtured and converted into a client.",
          tasks: [
            {
              name: "Send introductory email",
              reminders: [
                {
                  type: "day",
                  number: 1,
                  hour: 9,
                },
              ],
            },
            {
              name: "If Referral: Send Referral Welcome Email",
              reminders: [
                {
                  type: "day",
                  number: 1,
                  hour: 9,
                },
              ],
            },
            {
              name: "Add client to monthly newsletter",
              reminders: [
                {
                  type: "day",
                  number: 1,
                  hour: 9,
                },
              ],
            },
            {
              name: "Follow-up to book initial meeting (if req'd)",
              reminders: [
                {
                  type: "day",
                  number: 3,
                  hour: 9,
                },
              ],
            },
            {
              name: "Add to drip marketing campaign",
              reminders: [],
            },
            {
              name: "Initial Meeting Booked",
              reminders: [],
            },
            {
              name: "Update next meeting date in Hubly",
              reminders: [],
            },
          ],
        },
        {
          id: 1,
          name: "New Client 2: Initial Meeting/Consultation",
          description:
            "This workflow is for when a lead has scheduled their initial meeting/consultation with you.",
          tasks: [
            {
              name: "Send pre-meeting reminder",
              reminders: [
                {
                  type: "immediate",
                },
              ],
            },
            {
              name: "Initial meeting/consultation complete",
              reminders: [],
            },
            {
              name: "If not qualified: Send template email giving backup options",
              reminders: [],
            },
            {
              name: "If not qualified: archive from workflow",
              reminders: [],
            },
            {
              name: "Update streams & tags to match prospect's service interests and needs",
              reminders: [],
            },
            {
              name: "Record goals, interests and other pertinent information as a Pinned Note in the client card",
              reminders: [],
            },
          ],
        },
      ],
    },
    {
      id: 1,
      name: "Client Onboarding",
      description:
        "Client onboarding workflows are designed to improve and streamline the client onboarding experience. Onboarding workflows help you structure document collection, account set up, and other key items. A well-structured onboarding process not only creates a great client experience but will save you time and set you up for scale long-term.",
      processes: [
        {
          name: "New Client Onboarding",
          color: "#CFFAF9",
          description: "",
          workflows: [
            "New Client 3: Discovery/Data Collection",
            "New Client 4: Topic Prep",
            "New Client 4.5: Topic Meeting",
            "New Client 5: Financial Plan Creation",
            "New Client 5.5: Review Meeting",
            "New Client 6: Billing",
            "New Client 7: Paperwork & Account Opening",
            "New Client 8: Assets",
          ],
        },
      ],
      workflows: [
        {
          id: 2,
          name: "New Client 3: Discovery/Data Collection",
          description:
            "This workflow is crucial to understanding who your clients are and collecting all important documents required to build a complete financial picture.",
          tasks: [
            {
              name: 'Send "Welcome" email with ADV, advisor agreement and data gathering checklist',
              reminders: [
                {
                  type: "day",
                  number: 1,
                  hour: 9,
                },
              ],
            },
            {
              name: "Send reminder email for documents (if req'd)",
              reminders: [
                {
                  type: "day",
                  number: 7,
                  hour: 9,
                },
              ],
            },
            {
              name: "Set-up client profile in financial planning software and send invite",
              reminders: [],
            },
            {
              name: "Collect Income Documentation",
              reminders: [],
            },
            {
              name: "Collect Budget or Income and Expense Statement",
              reminders: [],
            },
            {
              name: "Collect Mortgage Statement",
              reminders: [],
            },
            {
              name: "Collect Property Tax Appraisal Notice",
              reminders: [],
            },
            {
              name: "Sync Accounts",
              reminders: [],
            },
            {
              name: "Student Loan: Collect Statements",
              reminders: [],
            },
            {
              name: "Collect Tax Returns",
              reminders: [],
            },
            {
              name: "Collect Employee Stock Plan info",
              reminders: [],
            },
            {
              name: "Collect Employee Benefits Guide",
              reminders: [],
            },
            {
              name: "Collect Insurance: L & D",
              reminders: [],
            },
            {
              name: "Collect Insurance: Health",
              reminders: [],
            },
            {
              name: "Collect Insurance: P & C",
              reminders: [],
            },
            {
              name: "Collect Insurance: Long-term Care",
              reminders: [],
            },
            {
              name: "Collect Estate Documents: Will, Trust, POA, Etc",
              reminders: [],
            },
            {
              name: "Collect Estate Documents: Beneficiaries",
              reminders: [],
            },
            {
              name: "Retirement: Social Security Statement",
              reminders: [],
            },
            {
              name: "Retirement: Pensions",
              reminders: [],
            },
            {
              name: "Business Owners: Collect any Business Entity Documents",
              reminders: [],
            },
            {
              name: "Check Credit Score",
              reminders: [],
            },
            {
              name: "Update streams & tags, household info, important dates, pinned notes with new information gathered",
              reminders: [],
            },
            {
              name: "If not qualified: archive from workflow",
              reminders: [],
            },
          ],
        },
        {
          id: 3,
          name: "New Client 4: Topic Prep",
          description:
            "This workflow is to structure the preparation for your first topic deep-dive meeting with a new client. Topic meetings help you address a client's most important/pressing issues.",
          tasks: [
            {
              name: "Prep work is topic dependent (add prep work as req'd)",
              reminders: [],
            },
            {
              name: "Topic Meeting Booked (Update next meeting date)",
              reminders: [],
            },
          ],
        },
        {
          id: 4,
          name: "New Client 4.5: Topic Meeting",
          description:
            "This workflow is for when a new client has scheduled their first topic meeting with you.",
          tasks: [
            {
              name: "Send pre-meeting reminder",
              reminders: [
                {
                  type: "immediate",
                },
              ],
            },
            {
              name: "Topic Meeting Complete",
              reminders: [],
            },
            {
              name: "Add Meeting Notes and Tasks",
              reminders: [],
            },
            {
              name: "Add back to topic meeting prep (if next meeting req'd)",
              reminders: [],
            },
          ],
        },
        {
          id: 5,
          name: "New Client 5: Financial Plan Creation",
          description:
            "This workflow is to help you structure the financial plan creation process for a new client.",
          tasks: [
            {
              name: "[Insert your financial plan creation steps here]",
              reminders: [],
            },
            {
              name: "Financial Plan Ready",
              reminders: [],
            },
            {
              name: "Review Meeting Booked (update next meeting date)",
              reminders: [],
            },
          ],
        },
        {
          id: 6,
          name: "New Client 5.5: Review Meeting",
          description:
            "This workflow is for when a new client has scheduled the financial plan review meeting with you.",
          tasks: [
            {
              name: "Send pre-meeting reminder",
              reminders: [
                {
                  type: "immediate",
                },
              ],
            },
            {
              name: "Review Meeting Complete",
              reminders: [],
            },
            {
              name: "Add Meeting Notes and Tasks",
              reminders: [],
            },
            {
              name: "Update streams & tags, household info, important dates, pinned notes with new information gathered",
              reminders: [],
            },
            {
              name: "Add to Billing, Account Opening, and Assets workflows",
              reminders: [],
            },
          ],
        },
        {
          id: 7,
          name: "New Client 6: Billing",
          description:
            "This workflow is to help you ensure that a new client is setup in your billing platform.",
          tasks: [
            {
              name: "Prepare and send client bill",
              reminders: [],
            },
            {
              name: "Confirm payment was received",
              reminders: [],
            },
            {
              name: "Follow-up (if required)",
              reminders: [
                {
                  type: "day",
                  number: 7,
                  hour: 9,
                },
              ],
            },
          ],
        },
        {
          id: 8,
          name: "New Client 7: Paperwork & Account Opening",
          description:
            "This workflow is to help you ensure that a new client is setup on all relevant platforms in your software suite.",
          tasks: [
            {
              name: "Send Financial Planning Agreement",
              reminders: [],
            },
            {
              name: "Create investment policy statement",
              reminders: [],
            },
            {
              name: "Account opening/transfer forms collected",
              reminders: [],
            },
            {
              name: "Invite client to create an account on any other relevant platforms",
              reminders: [],
            },
            {
              name: "Confirm client has activated accounts and completed profiles",
              reminders: [
                {
                  type: "day",
                  number: 7,
                  hour: 9,
                },
              ],
            },
          ],
        },
        {
          id: 9,
          name: "New Client 8: Assets",
          description:
            "This workflow is to help you ensure that a new client's investment accounts are setup and transferred in.",
          tasks: [
            {
              name: "Send risk tolerance questionnaire",
              reminders: [],
            },
            {
              name: "Set-up client portfolio(s) in rebalancing software",
              reminders: [],
            },
            {
              name: "Back-office setup completed",
              reminders: [],
            },
            {
              name: "Broker Dealer/Custodian setup completed",
              reminders: [],
            },
          ],
        },
      ],
    },
    {
      id: 2,
      name: "Client Check-In",
      description:
        "Client check-in workflows help you stay on top of ongoing client meetings. Client check-in workflows are designed to help you easily manage scheduling, preparation, and meeting follow-up items while being able to see exactly where each client is at in each stage. A well-structured client check-in process will not only save you time but will give you peace of mind knowing that you’re not forgetting anything.",
      processes: [
        {
          name: "Client Check-In Workflows",
          color: "#5DADE2",
          description: "",
          workflows: [
            "Client Check-In Scheduling",
            "Client Check-In Preparation",
            "Client Check-In Meeting",
            "Client Check-In Follow Up",
          ],
        },
      ],
      workflows: [
        {
          id: 10,
          name: "Client Check-In Scheduling",
          description: "",
          tasks: [
            {
              name: "Email meeting request with scheduling link",
              reminders: [
                {
                  type: "immediate",
                },
              ],
            },
            {
              name: "Follow-up (if required)",
              reminders: [
                {
                  type: "day",
                  number: 4,
                  hour: 9,
                },
              ],
            },
            {
              name: "Confirm meeting/send event (if not automated)",
              reminders: [],
            },
            {
              name: "Update next meeting date in the client card",
              reminders: [],
            },
          ],
        },
        {
          id: 11,
          name: "Client Check-In Preparation",
          description: "",
          tasks: [
            {
              name: "Review action items from previous meeting & add to client's review workflow",
              reminders: [],
              description:
                "<p>We suggest reviewing the previous meeting agenda, follow-up client email/thread, and Client Check-In Follow Up workflow from the last check-in.</p>",
            },
            {
              name: "Review client's streams & tags to ensure services & products/details are up-to-date",
              reminders: [],
            },
            {
              name: "Review important dates to ensure IPS, fee and privacy offerings are compliant",
              reminders: [],
            },
            {
              name: "Send pre-meeting email to client with unresolved action items and next meeting agenda",
              reminders: [],
            },
            {
              name: "Send client pre-meeting data gathering form (if applicable)",
              reminders: [],
            },
            {
              name: "Create new document collection link in Hubly (if applicable)",
              reminders: [],
              description:
                "<p>If you collect the questionnaire response as an attachment, consider uploading the document to the Client Card as an easy place to store and organize files for short-term clients.</p><p><br></p><p>If you collect the questionnaire response in a web form, you can save time by <strong>adding it as link to this task</strong> in this task's <strong>Task Details</strong> view to bookmark the client's response.</p>",
            },
            {
              name: "Extra: Are there referrals that need to be discussed?",
              reminders: [],
            },
            {
              name: "Extra: Search for abandoned client accounts",
              reminders: [],
              links: [
                {
                  url: "http://unclaimed.org",
                  title: "Search Uncalimed.org",
                },
                {
                  url: "http://www.missingmoney.com",
                  title: "Search MissingMoney.com",
                },
              ],
            },
            {
              name: "Send meeting reminder email",
              reminders: [],
            },
          ],
        },
        {
          id: 12,
          name: "Client Check-In Meeting",
          description: "",
          tasks: [
            {
              name: "Monthly/AUM fee reviewed",
              reminders: [],
            },
            {
              name: "Financial plan reviewed (if applicable)",
              reminders: [],
            },
            {
              name: "Cashflow/budget reviewed (if applicable) -- See Cashflow Review workflow",
              reminders: [],
            },
            {
              name: "ASP (automatic savings plan) reviewed",
              reminders: [],
            },
            {
              name: "Investable cash holdings reviewed",
              reminders: [],
            },
            {
              name: "Non-revenue generating assets reviewed",
              reminders: [],
            },
            {
              name: "Roll overs/Roth conversions reviewed",
              reminders: [],
            },
            {
              name: "Annuities reviewed/serviced",
              reminders: [],
            },
            {
              name: "Life & disability insurance policies reviewed",
              reminders: [],
            },
            {
              name: "Health & travel insurance policies reviewed",
              reminders: [],
            },
            {
              name: "Funding for IRA/Roth IRA, SEP IRA, and individual 401(k) reviewed",
              reminders: [],
            },
            {
              name: "Pension reviewed",
              reminders: [],
            },
          ],
        },
        {
          id: 13,
          name: "Client Check-In Follow Up",
          description: "",
          tasks: [
            {
              name: "Schedule next workflow to start 1 month before the next review time for this client",
              reminders: [],
            },
            {
              name: "Review meeting notes and update action items (consider making a draft next meeting agenda)",
              reminders: [],
              description:
                "<p>Preparing the next check-in agenda is an effective way to capture action items and discussion points while they're still top of mind.</p><p><br></p><p>Consider uploading the document to the Client Card in Hubly as an easy place to store and organize files for clients, accessible by all team members.</p>",
            },
            {
              name: "Add action items to this workflow and assign to team members (if applicable)",
              reminders: [],
            },
            {
              name: "Review streams & tags and update to reflect current needs (for all members of the household, if applicable)",
              reminders: [],
            },
            {
              name: "Update pinned notes for goals/other important information that should be kept top of mind",
              reminders: [],
            },
            {
              name: "Send meeting follow-up email to the client",
              reminders: [],
              description:
                "<p>Include summary, action items, other notes as applicable</p>",
            },
            {
              name: "Update external/internal service professionals with changes to client information and action items (if applicable)",
              reminders: [],
              description:
                "<p>Ensure that all data sharing consent documents are up-to-date.</p><p><br></p><p>Provided that you've received client consent, it is important to ensure that all members of the client's service team have the most up to date information (ie. accountant/tax preparer, insurance agent, lawyer/attorney, etc).</p>",
            },
          ],
        },
      ],
    },
    {
      id: 3,
      name: "Ongoing Service Workflows",
      description:
        "Ongoing service workflows are designed to help you track the types of reviews you conduct for ongoing clients regularly throughout the year. These service workflows are designed to break down the review process into individual workflows that make it easier to see where every client is at. Ongoing service workflows work together with seasonal service workflows to help you create a comprehensive dashboard and ensure that every client receives the level of service that they need.",
      processes: [
        {
          name: "Ongoing Service Workflows",
          color: "#C4FAF8",
          description: "",
          workflows: ["Investment Review", "Cashflow Review", "Debt Review"],
        },
      ],
      workflows: [
        {
          id: 14,
          name: "Investment Review",
          description: "",
          tasks: [],
        },
        {
          id: 15,
          name: "Cashflow Review",
          description: "",
          tasks: [],
        },
        {
          id: 16,
          name: "Debt Review",
          description: "",
          tasks: [],
        },
      ],
    },
    {
      id: 4,
      name: "Seasonal Service Workflows",
      description:
        "Season service workflows are designed to help you track the types of reviews you conduct for ongoing clients once a year. These service workflows are designed to break down the seasonal review process into individual workflows that make it easier to see where every client is at. Seasonal service workflows work together with ongoing service workflows to help you create a comprehensive dashboard and ensure that every client receives the level of service that they need.",
      processes: [
        {
          name: "Investment Workflows",
          color: "#45B39D",
          description: "",
          workflows: [
            "RMDs",
            "Roth Conversions",
            "Backdoor Roth Conversions",
            "Form 1099",
            "401k Rebalancing",
            "Contributions Review",
          ],
        },
        {
          name: "Insurance",
          color: "#F7DC6F",
          description: "",
          workflows: ["L+D Insurance Review", "P+C Insurance Review"],
        },
      ],
      workflows: [
        {
          id: 17,
          name: "RMDs",
          description: "",
          tasks: [],
        },
        {
          id: 18,
          name: "Roth Conversions",
          description: "",
          tasks: [],
        },
        {
          id: 19,
          name: "Backdoor Roth Conversions",
          description: "",
          tasks: [],
        },
        {
          id: 20,
          name: "Form 1099",
          description: "",
          tasks: [],
        },
        {
          id: 21,
          name: "401k Rebalancing",
          description: "",
          tasks: [],
        },
        {
          id: 22,
          name: "Contributions Review",
          description: "",
          tasks: [],
        },
        {
          id: 23,
          name: "L+D Insurance Review",
          description: "",
          tasks: [],
        },
        {
          id: 24,
          name: "P+C Insurance Review",
          description: "",
          tasks: [],
        },
        {
          id: 25,
          name: "Financial Plan Review",
          description: "",
          tasks: [],
        },
        {
          id: 26,
          name: "Retirement Plan Review",
          description: "",
          tasks: [],
        },
        {
          id: 27,
          name: "Employee Benefits/Open Enrollment",
          description: "",
          tasks: [],
        },
        {
          id: 28,
          name: "Estate Plan Review",
          description: "",
          tasks: [],
        },
        {
          id: 29,
          name: "Beneficiary Review",
          description: "",
          tasks: [],
        },
        {
          id: 30,
          name: "EOY Charitable Giving Review",
          description: "",
          tasks: [],
        },
        {
          id: 31,
          name: "Credit Score Review",
          description: "",
          tasks: [],
        },
        {
          id: 32,
          name: "Salary Benchmarking Review",
          description: "",
          tasks: [],
        },
      ],
    },
    {
      id: 5,
      name: "Tax Filing",
      description:
        "These tax preparation and filing workflows break a complicated process into manageable, and transparent steps. Whether you offer tax services in-house or work with an external tax preparer, the tax filing process makes it easy to track progress and see where every client is at. A well-structured tax filing process saves you time and helps you create a delightful experience for every client, even at tax time.",
      processes: [
        {
          name: "Tax Prep & Filing Workflows",
          color: "#F0B27A",
          description: "",
          workflows: [
            "Tax Filing 1: Opt-In Call",
            "Tax Filing 2: Document Collection",
            "Tax Filing 3: Review and Filing",
            "Tax Filing 4: Billing",
          ],
        },
      ],
      workflows: [
        {
          id: 33,
          name: "Tax Filing 1: Opt-In Call",
          description: "",
          tasks: [
            {
              name: "Email invitation if client has NOT yet opted-in to tax filing service",
              reminders: [
                {
                  type: "immediate",
                },
              ],
            },
            {
              name: "If not interested, archive from workflow",
              reminders: [],
              description:
                'Note: Make sure not to "Mark Workflow Complete" as that would automatically add the "Tax Filing" stream to the client.',
            },
            {
              name: "Review tax preparation/filing process, timeline and fees with the client",
              reminders: [],
            },
            {
              name: "Create appropriate expectations on timing for draft review and filing",
              reminders: [],
            },
            {
              name: "Add all household members to Document Collection Workflow (if required)",
              reminders: [],
            },
          ],
        },
        {
          id: 34,
          name: "Tax Filing 2: Document Collection",
          description: "",
          tasks: [
            {
              name: "Collect consent form (filing or information sharing)",
              reminders: [
                {
                  type: "immediate",
                },
              ],
              description:
                "<p>If you collect the consent form as an attachment, consider uploading the template PDF to the Client Card as an easy place to store and organize files for short-term clients.</p><p><br></p><p>If you collect the consent form as a signing platform, you can save time by <strong>adding a link for the signing document to this task</strong> in the <strong>Task Details</strong>.</p>",
            },
            {
              name: "Send client data gathering form/questionnaire",
              reminders: [],
              description:
                "<p>We recommend that you use a standardized form or questionnaire with a checklist of items that a client will need to provide for the tax preparation process.</p><p><br></p><p>If you collect the questionnaire response as an attachment, consider uploading the document to the Client Card as an easy place to store and organize files for short-term clients.</p><p><br></p><p>If you collect the questionnaire response in a web form, you can save time by <strong>adding it as link to this task</strong> in this task's <strong>Task Details</strong> view to bookmark the client's response.</p>",
            },
            {
              name: "Create document collection link in Hubly (if required)",
              reminders: [],
              description:
                '<p>A document collect link can be generated from the <strong>Attachments section </strong>of a Client Card.</p><p><br></p><p>Simple navigate to the "+" icon and copy the unique link for that client\'s secure document portal.</p><p><br></p><p>Documents can only be uploaded through the portal and the user can see a list of documents successfully uploaded during that single session. Documents are stored securely and only made available to download in the Client Card. A document icon will appear on the Client Tile on your Hub to indicate when a document is available.</p>',
            },
            {
              name: "Send follow-up for missing/incomplete information (if applicable)",
              reminders: [],
            },
            {
              name: "~~ Tax Prep Checklist ~~",
              reminders: [],
            },
            {
              name: "All type(s) of income identified for previous tax year",
              reminders: [],
              description:
                "<p>Which of the following types of income does each member of the household need to include in their return?</p><ul><li>Salary &amp; wages</li><li>Self-employed/contractor income</li><li>Investment income</li><li>K-1 income</li><li>Rental income</li><li>International tax items</li><li>Other</li><li>Business tax filing (corporation, partnership, non-profit)</li></ul><p><br></p>",
            },
            {
              name: "Client information reviewed up-to-date",
              reminders: [],
              description:
                "<p>The following information is required for each member of the household to complete their return.</p><ul><li>First &amp; last name</li><li>Social security number</li><li>Date of birth</li><li>Mailing address</li><li>Residency</li><li>State(s) where primary source of income received previous year</li><li>Marital status</li><li>Additional details (as req'd)</li></ul><p><br></p>",
            },
            {
              name: "Supporting documents collected for: Primary income",
              reminders: [],
              description:
                "<p>The following supporting documents will need to be provided for <strong>Primary Income</strong>:</p><p><br></p><ul><li>W-2,</li><li>1099 forms,</li><li>invoices/receipts for jobs if contractor</li></ul><p><br></p>",
            },
            {
              name: "Supporting documents collected for: Other/misc income",
              reminders: [],
              description:
                "<p>The following supporting documents will need to be provided for <strong>Other/misc Income:</strong></p><p><br></p><ul><li>Income from sale of stock or property (1099-B)</li><li>Interest income (1099-INT),</li><li>Dividend income (1099-DIV)</li><li>Rental income (1099-K or receipts/estimates)</li><li>Partnership income (schedule K-1)</li><li>Gambling income (W-2G)</li><li>State tax refund or unemployment income (1099-G)</li><li>HSA and long-term care reimbursements (1099-SA or 1099-LTC)</li></ul><p><br></p>",
            },
            {
              name: "Supporting documents collected for: Expenses",
              reminders: [],
              description:
                "<p>The following supporting documents will need to be provided for <strong>Expenses</strong></p><p><br></p><ul><li>Records of mortgage interest payments (form 1098)</li><li>Business expenses (if you’re a contractor/self-employed)</li><li>Charitable donations</li><li>Medical expenses</li><li>Proof of health insurance (form 1095)</li><li>Educational expenses (form 1098-T)</li><li>Student loan interest (form 1098-E)</li><li>State and local taxes paid</li><li>Estimated tax payments made (form 1040ES)</li></ul><p><br></p>",
            },
            {
              name: "Other documents collected for: Last year's tax return, ID, SSN",
              reminders: [],
              description:
                "<ul><li>A copy of last year’s tax return will help streamline the process. If you don’t have a copy, don’t sweat it at all.</li><li>Most states now require a driver’s license for electronic filing of taxes (so as to combat identity and refund theft)</li></ul>",
            },
            {
              name: "Supporting documents collected for: Business filing (if applicable)",
              reminders: [],
              description:
                "<p>Supporting documentation will need to be provided for <strong>Business filings</strong>, depending if the business is a:</p><p><br></p><ul><li>Corporation</li><li>Partnership</li><li>Non-profit</li></ul><p><br></p>",
            },
          ],
        },
        {
          id: 35,
          name: "Tax Filing 3: Review and Filing",
          description: "",
          tasks: [
            {
              name: "Provide assembled client documents to external accountant/tax preparer (if applicable)",
              reminders: [
                {
                  type: "immediate",
                },
              ],
            },
            {
              name: "Review initial draft filing",
              reminders: [],
            },
            {
              name: "Schedule draft review call with client",
              reminders: [],
              description:
                "<p>A meeting to assist the client with a review of the <strong>initial draft</strong> prior to filing.</p><p><br></p><p><strong><em>Consider</em></strong><em> creating an email template with a calendar scheduling link to save time scheduling this review call.</em></p>",
            },
            {
              name: "Make changes/revisions (if required)",
              reminders: [],
            },
            {
              name: "Schedule Final Draft review call with client (if required)",
              reminders: [],
            },
            {
              name: "Organize filed return and collected documents for future years",
              reminders: [],
              description:
                "<p>Add/organize all documents for the filed return in your main client folder.</p><p><br></p><p><strong><em>Consider</em></strong><em> uploading the filed return to the Client Card in Hubly for easy access in future years.</em></p>",
            },
          ],
        },
        {
          id: 36,
          name: "Tax Filing 4: Billing",
          description: "",
          tasks: [
            {
              name: "Prepare and send client bill",
              reminders: [
                {
                  type: "immediate",
                },
              ],
            },
            {
              name: "Confirm payment was received",
              reminders: [
                {
                  type: "days",
                  number: 5,
                  hour: 9,
                },
              ],
            },
            {
              name: "Follow-up (if required)",
              reminders: [],
            },
            {
              name: "Send tax filing payment to external accountant/tax preparer (if applicable)",
              reminders: [],
            },
          ],
        },
      ],
    },
    {
      id: 6,
      name: "Team Collaboration Workflows",
      description:
        "Team collaboration workflows are useful when managing a firm with a growing team of staff and external service partners. Team collaboration workflows help you capture urgent and important action items, and bring them to the attention of the right member of the service team. A well-structured process for managing internal hand-offs and reviews will ensure that nothing is forgotten and make it easy to identify bottlenecks.",
      processes: [
        {
          name: "Requires Review Workflows",
          color: "#F1948A",
          description: "",
          workflows: [
            "Requires Review by Advisor",
            "Requires Review by Staff",
            "Requires Review by Tax Accountant",
            "Requires Review by Insurance Agent",
            "Requires Review by Mortgage Broker",
            "Requires Review by Estate Planner/Attorney",
          ],
        },
      ],
      workflows: [
        {
          id: 37,
          name: "Requires Review by Advisor",
          description: "",
          tasks: [],
        },
        {
          id: 38,
          name: "Requires Review by Staff",
          description: "",
          tasks: [],
        },
        {
          id: 39,
          name: "Requires Review by Tax Accountant",
          description: "",
          tasks: [],
        },
        {
          id: 40,
          name: "Requires Review by Insurance Agent",
          description: "",
          tasks: [],
        },
        {
          id: 41,
          name: "Requires Review by Mortgage Broker",
          description: "",
          tasks: [],
        },
        {
          id: 42,
          name: "Requires Review by Estate Planner/Attorney",
          description: "",
          tasks: [],
        },
      ],
    },
  ],
};
