import { datadogRum } from "@datadog/browser-rum";

export const clientOptionProps = (client) => {
  return {
    name: client.name,
    key: `${client.id}`,
    value: client.id,
    firstname: client.firstName,
    lastname: client.lastName,
    middlename: client.middleName,
    nickname: client.nickname,
    type: "client",
  };
};

export const streamOptionProps = (stream) => {
  return {
    key: stream.id,
    value: `${stream.id}`,
    name: stream.name,
    icon: { name: "circle", color: stream.color },
    type: "stream",
  };
};

export const tagOptionProps = (tag) => {
  return {
    key: tag.id,
    value: `${tag.id}`,
    name: tag.name,
    icon: { name: "hashtag", color: "grey" },
    type: "tag",
  };
};

export const createDropdownItemSectionProps = ({
  headerText,
  emptyText = "",
  openList,
  items,
  optionProps,
  filterKeyword,
  collapsible = true,
  onItemClick,
  selectedItemValues,
  setSectionOpened = () => {},
}) => {
  return {
    openList: openList,
    emptyText: emptyText,
    filterKeyword: filterKeyword,
    collapsible: collapsible,
    headerText: headerText,
    loading: items.loading,
    items: items.data
      .filter(({ id }) => {
        return !selectedItemValues.includes(id);
      })
      .map(optionProps),
    showLoadMore: items.hasMoreData,
    onSectionExpanded: (section) => {
      if (section !== "") items.loadData();
      setSectionOpened(section);
    },
    onItemClick: onItemClick,
  };
};

export const excludeDropdownData = (excludeList, dropdownData) => {
  return {
    ...dropdownData,
    data: dropdownData.data.filter(({ id }) => {
      return !excludeList.includes(id);
    }),
  };
};

export const recordCustomAction = (source, createdTimestamp) => {
  const actionPrefix = "hubly_contact_find";
  const duration = new Date().getTime() - parseInt(createdTimestamp, 10);
  datadogRum.addAction(`${actionPrefix}_${source}`, { duration: duration });
};

export default createDropdownItemSectionProps;
