import React, { Component } from "react";
import connect from "react-redux/es/connect/connect";
import { withRouter } from "react-router";
import PropTypes from "prop-types";

import {
  Button,
  Dropdown,
  Header,
  Icon,
  Label,
  Popup,
} from "semantic-ui-react";

import { SetMenuFilters } from "data/filters/actions";

const maxDropdownHeight = 400;

class StreamFilters extends Component {
  static propTypes = {
    hub: PropTypes.object.isRequired,
    filters: PropTypes.object.isRequired,
    setFilters: PropTypes.func.isRequired,
    streamFilterType: PropTypes.string.isRequired,
  };

  state = {
    searchInput: "",
    selectedIndex: 0,
  };

  streamSelected = (id) => {
    const { hub, filters, setFilters } = this.props;
    const { streams } = hub;
    const filtersCopy = { ...filters };
    const filteredStreams = filtersCopy ? filtersCopy.streams : [];
    const foundStream = streams.find((stream) => {
      return stream.id === id;
    });
    filteredStreams.push(foundStream);
    setFilters(filtersCopy);
    this.setState({ searchInput: "", selectedIndex: 0 });
  };

  scrollMenu = () => {
    // handle scrolling when navigating by key presses
    const menu = document.getElementById("filtering_dropdown_menu");
    const selectedItem = document.getElementById(
      "filtering_dropdown_menu_selected_item"
    );
    if (!selectedItem) return;
    const menuY = menu.getBoundingClientRect().y;
    const selectedY = selectedItem.getBoundingClientRect().y;
    const selectedHeight = selectedItem.getBoundingClientRect().height;
    const selectedDist = selectedY - menuY;
    if (selectedDist + selectedHeight > maxDropdownHeight) {
      menu.scrollTop += selectedDist - maxDropdownHeight + selectedHeight;
    } else if (selectedDist < 0) {
      menu.scrollTop += selectedDist;
    }
  };

  handleKeyDown = (e) => {
    // handle key down and enter key presses
    const { selectedIndex } = this.state;
    const streams = this.streamsDropdownList;
    if (streams.length < 1) return;
    if (e.key === "Enter") {
      this.streamSelected(streams[selectedIndex].id);
    } else if (e.key === "ArrowUp") {
      this.setState((state) => {
        return { selectedIndex: Math.max(selectedIndex - 1, 0) };
      }, this.scrollMenu);
    } else if (e.key === "ArrowDown") {
      this.setState((state) => {
        return { selectedIndex: (selectedIndex + 1) % streams.length };
      }, this.scrollMenu);
    }
  };

  handleTyping = (e) => {
    this.setState({ searchInput: e.target.value, selectedIndex: 0 });
  };

  getStreamOptions = () => {
    const { hub } = this.props;
    const streamOptions = [];

    hub.streams
      .sort((a, b) => {
        return a.name > b.name ? 1 : -1;
      })
      .forEach((stream) => {
        streamOptions.push({
          color: stream.color,
          content: (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Label
                empty
                circular
                style={{ backgroundColor: stream.color, marginRight: "1em" }}
              />
              <div>{stream.name}</div>
            </div>
          ),
          key: stream.id,
          text: stream.name,
          type: "stream",
          value: stream.id,
        });
      });
    return streamOptions;
  };

  generateStreamsList = () => {
    const { hub, filters } = this.props;
    const { streams } = hub;
    const filteredStreams = filters ? filters.streams : [];
    const dropdownList = [];
    streams.forEach((stream) => {
      const filtered =
        filteredStreams.find((t) => {
          return t.id === stream.id;
        }) != null;
      if (!filtered) {
        dropdownList.push(stream);
      }
    });
    return dropdownList;
  };

  dropdownOnChange = (data) => {
    const { filters, setFilters } = this.props;
    const filtersCopy = { ...filters };
    let { streams } = filtersCopy;

    streams = streams.filter((item) => {
      return data.value.indexOf(item.id) >= 0;
    });
    filtersCopy.streams = streams;
    setFilters(filtersCopy);
  };

  clearFilter = () => {
    const { filters, setFilters } = this.props;
    const filtersCopy = { ...filters };
    filtersCopy.streams = [];
    setFilters(filtersCopy);
  };

  filterByInput = (list) => {
    const { searchInput } = this.state;
    const filterText = searchInput.toLowerCase();
    return list.filter((item) => {
      const itemText = item.name.toLowerCase();
      return itemText.includes(filterText);
    });
  };

  setFilterType = (type) => {
    const { filters, setFilters } = this.props;
    const filtersCopy = { ...filters };
    filtersCopy.streamFilterType = type;
    setFilters(filtersCopy);
  };

  selectorMarkup = () => {
    const { searchInput, selectedIndex } = this.state;
    const { filters } = this.props;
    const { streams } = filters;
    this.streamsDropdownList = this.filterByInput(this.generateStreamsList());
    return (
      <div
        style={{
          padding: "1px 1em",
          height: "max-content",
        }}
      >
        <div
          style={{
            width: "400px",
            maxHeight: "400px",
          }}
        >
          <Dropdown
            data-test="filter-menu-streams"
            className="selection" // Can't have children and use selection prop, so to get styling just add the className instead
            placeholder="Select Streams"
            fluid
            deburr
            search
            onChange={(event, data) => {
              this.dropdownOnChange(data);
            }}
            searchInput={{ value: searchInput }}
            onClose={() => {
              this.setState({ searchInput: "" });
            }}
            onKeyDown={this.handleKeyDown}
            onSearchChange={this.handleTyping}
            options={this.getStreamOptions()}
            value={streams.map((s) => {
              return s.id;
            })}
            style={{
              marginTop: "1px",
              position: "relative",
              width: "calc(100%)",
            }}
            renderLabel={(item, index, object) => {
              object.content = item.text;
              object.style = { backgroundColor: item.color, color: "white" };
              return object;
            }}
            multiple
            selection
          >
            <Dropdown.Menu
              id="filtering_dropdown_menu"
              style={{
                paddingBottom: "1em",
                maxHeight: `${maxDropdownHeight}px`,
              }}
            >
              <Dropdown.Header content="Streams" style={{ fontSize: "10pt" }} />
              {this.streamsDropdownList.length > 0 ? (
                this.streamsDropdownList.map((stream, index) => {
                  const selected = index === selectedIndex;
                  return (
                    <Dropdown.Item
                      icon={
                        <Icon name="circle" style={{ color: stream.color }} />
                      }
                      key={stream.id}
                      text={stream.name}
                      onClick={() => {
                        this.streamSelected(stream.id);
                      }}
                      selected={selected}
                      id={
                        selected ? "filtering_dropdown_menu_selected_item" : ""
                      }
                    />
                  );
                })
              ) : (
                <span style={{ marginLeft: "2em", color: "grey" }}>
                  No matching streams
                </span>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    );
  };

  render() {
    const { filters } = this.props;
    const { streamFilterType } = filters;
    return (
      <React.Fragment>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div>
            <Header as="h4" style={{ display: "inline", marginRight: "0.5em" }}>
              Streams
            </Header>
          </div>
          <div
            style={{ marginRight: "0.5em" }}
            data-test="filter-streams-all-any"
          >
            <Button.Group>
              <Button
                primary={streamFilterType === "ALL"}
                compact
                onClick={() => {
                  this.setFilterType("ALL");
                }}
              >
                ALL
              </Button>
              <Button
                primary={streamFilterType === "ANY"}
                compact
                onClick={() => {
                  this.setFilterType("ANY");
                }}
              >
                ANY
              </Button>
            </Button.Group>
          </div>
          <div>
            <Popup
              on="hover"
              wide
              position="right center"
              trigger={<Icon color="grey" name="question circle" />}
            >
              Use ALL to display client cards where all selected filters apply.
              Use ANY to display client cards where any of the selected filters
              apply
            </Popup>
          </div>
        </div>
        {this.selectorMarkup()}
        <div>
          <Popup
            content="Clear"
            on="hover"
            position="top center"
            trigger={
              <Icon
                data-test="filter-menu-streams-clear"
                name="delete"
                onClick={this.clearFilter}
                link
                color="grey"
              />
            }
          />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    hub: state.hubly.data.hub.selected.hub,
    filters: state.hubly.data.filters,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setFilters: (filters) => {
      dispatch(SetMenuFilters(filters));
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(StreamFilters)
);
