import React from "react";
import PropTypes from "prop-types";

import { Icon } from "semantic-ui-react";

import useCurrentUser from "data/advisor/useCurrentUser";

import { useAccess } from "components/Access";
import { RenderWhenAny } from "components/ConditionalRendering";
import PopupMenu from "components/PopupMenu";

function UserInfoActionMenu({
  userId,
  isOpen,
  onMenuOpen,
  onMenuClose,
  children,
}) {
  const advisor = useCurrentUser();
  const hasAccess = useAccess();
  return (
    <RenderWhenAny
      conditions={[hasAccess(["Administrator"]), userId === advisor.id]}
    >
      <PopupMenu
        id="UserInformationPopup"
        trigger={
          <Icon
            color="grey"
            style={{ float: "right" }}
            name="ellipsis horizontal"
            link
            onClick={onMenuOpen}
          />
        }
        open={isOpen}
        onClose={onMenuClose}
      >
        {children}
      </PopupMenu>
    </RenderWhenAny>
  );
}

UserInfoActionMenu.propTypes = {
  userId: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  onMenuOpen: PropTypes.func,
  onMenuClose: PropTypes.func,
  children: PropTypes.node,
};

UserInfoActionMenu.defaultProps = {
  isOpen: false,
  onMenuOpen: () => {},
  onMenuClose: () => {},
  children: null,
};
export default UserInfoActionMenu;
