import React from "react";
import PropTypes from "prop-types";

import { faGripVertical } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Icon, Segment } from "semantic-ui-react";

import MarkedText from "components/MarkedText";
import Popper from "components/Popper";

import AddClientPopUp from "./components/AddClientPopUp";

export function WorkflowTile({
  name,
  numActiveClients,
  numTasks,
  processColor,
  numWorkflowRules,
  disabled,
  filteredKeyword,
  onClick,
  dragHandleProps,
  children,
}) {
  return (
    <Segment.Group
      horizontal
      style={{
        width: "100%",
        color: "grey",
        margin: "0.5em 0em",
        opacity: disabled ? 0.5 : 1,
      }}
      className="clickable grey_on_hover no_select"
      data-test={`workflows-list-${name}`}
      onClick={onClick}
    >
      <Segment style={{ width: "55%", padding: "0.75em 1em" }}>
        <div style={{ display: "flex" }}>
          {Object.keys(dragHandleProps).length !== 0 && (
            <div data-test={`workflow-draggable-${name}`} {...dragHandleProps}>
              <FontAwesomeIcon
                className="dark_grey_on_hover"
                color="rgb(218, 218, 218)"
                icon={faGripVertical}
                style={{
                  marginRight: "1em",
                }}
              />
            </div>
          )}
          {processColor && (
            <div
              data-test="process-color"
              style={{
                height: "19px",
                width: "8px",
                marginRight: "0.5em",
                backgroundColor: processColor,
              }}
            />
          )}
          <div
            className="overflow_ellipsis"
            style={{
              margin: 0,
              color: "rgba(0, 0, 0, 0.8)",
              flexGrow: 1,
            }}
          >
            <MarkedText text={name} keyword={filteredKeyword} />
          </div>
          {numWorkflowRules > 0 && (
            <Popper
              content={
                "This workflow has active rules applied to it." +
                " View rules by selecting 'Edit Workflow' in the workflow header menu."
              }
              position="right center"
              wide
              trigger={
                <Icon
                  name="settings"
                  style={{
                    float: "right",
                    fontSize: "12pt",
                    margin: "0 0 0px 1em",
                    transform: "scaleX(-1)",
                    color: "darkgrey",
                  }}
                />
              }
            />
          )}
        </div>
      </Segment>
      <Segment style={{ width: "20%", textAlign: "center", padding: "0.75em" }}>
        <Icon color="grey" name="check square" />
        {`${numTasks} Task${numTasks > 1 ? "s" : ""}`}
      </Segment>
      <AddClientPopUp totalClient={numActiveClients}>{children}</AddClientPopUp>
    </Segment.Group>
  );
}

WorkflowTile.defaultProps = {
  processColor: "",
  name: "",
  numActiveClients: 0,
  numTasks: 0,
  filteredKeyword: "",
  numWorkflowRules: 0,
  dragHandleProps: {},
  disabled: false,
  children: () => {},
};

WorkflowTile.propTypes = {
  processColor: PropTypes.string,
  name: PropTypes.string,
  numActiveClients: PropTypes.number,
  numTasks: PropTypes.number,
  numWorkflowRules: PropTypes.number,
  filteredKeyword: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  dragHandleProps: PropTypes.object,
  disabled: PropTypes.bool,
  children: PropTypes.func,
};

export default WorkflowTile;
