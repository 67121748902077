import { keyBy } from "lodash";

import moment from "moment";

import { SetAlert } from "components/Alerts/actions";

import { SetFilters } from "../../filters/actions";
import { AddTask, DeleteTask, EditTask } from "../../libs/tasks";
import {
  DeleteWorkflow,
  EditWorkflow,
  GetWorkflows,
} from "../../libs/workflows";
import {
  AddExistingReminderToClientObject,
  SetReminders,
} from "../../reminders/actions";
import { SetViews } from "../../views/actions";
import { SetClientWorkflow } from "../clientWorkflows/actions";

export const sortWorkflow = (a, b) => {
  const aSortOrder = a.sortOrder;
  const bSortOrder = b.sortOrder;
  if (aSortOrder > bSortOrder) {
    return 1;
  }
  if (aSortOrder < bSortOrder) {
    return -1;
  }
  return 0;
};

export const AddWorkflow = (workflow) => {
  return {
    type: "ADD_WORKFLOW",
    workflow: workflow,
  };
};

export const AddClientToWorkflow = (workflowId, clientWorkflowId) => {
  return {
    type: "ADD_WORKFLOW_CLIENT_WORKFLOW",
    workflowId: workflowId,
    clientWorkflowId: clientWorkflowId,
  };
};

export const BulkUpdateWorkflowTasks = (tasks) => {
  return {
    type: "BULK_UPDATE_WORKFLOW_TASKS",
    tasks: tasks,
  };
};

export const DeleteWorkflowState = (workflowId) => {
  return {
    type: "DELETE_WORKFLOW",
    workflowId: workflowId,
  };
};

export const AddWorkflowTask = (task) => {
  return {
    type: "ADD_WORKFLOW_TASK",
    task: task,
  };
};

export const DeleteWorkflowTask = (taskId, workflowId) => {
  return {
    type: "DELETE_WORKFLOW_TASK",
    taskId: taskId,
    workflowId: workflowId,
  };
};

export const EditWorkflowTask = (task) => {
  return {
    type: "EDIT_WORKFLOW_TASK",
    task: task,
  };
};

export const SetWorkflow = (workflow) => {
  return {
    type: "SET_WORKFLOW",
    workflow: workflow,
  };
};

export const SetWorkflows = (workflows) => {
  return {
    type: "SET_WORKFLOWS",
    workflows: keyBy(workflows.sort(sortWorkflow), "id"),
  };
};

export function RemoveWorkflow(workflowId, deleteClients) {
  return (dispatch, getState) => {
    DeleteWorkflow(workflowId, deleteClients)
      .then(() => {
        const workflowCopy = {
          ...getState().hubly.data.hub.workflows[workflowId],
        };
        // Want to dismiss all reminders for all clients in this workflow
        let remindersCopy = [...getState().hubly.data.reminders];
        workflowCopy.clients.forEach((client) => {
          const taskIds = (client.tasks || []).map((task) => {
            return task.id;
          });
          remindersCopy = remindersCopy.filter((reminder) => {
            return !taskIds.includes(reminder.taskId);
          });
        });

        // Set the filters to make it not appear that a workflow filter is active (when it isn't)
        const oldFilters = getState().hubly.data.filters;
        const newFilters = { ...oldFilters };
        newFilters.workflows = newFilters.workflows.filter((workflow) => {
          return workflow.id !== workflowId;
        });

        // Need to delete any workflows that are in existing views
        const viewsCopy = [...getState().hubly.data.views.views];
        viewsCopy.forEach((view) => {
          view.filter.workflows = view.filter.workflows.filter((workflow) => {
            return workflow.id !== workflowId;
          });
        });

        dispatch(
          SetAlert({
            type: "success",
            text: `Successfully deleted workflow ${workflowCopy.name}`,
          })
        );
        dispatch(SetFilters(newFilters));
        dispatch(SetReminders(remindersCopy));
        dispatch(SetViews(viewsCopy));
        dispatch(DeleteWorkflowState(workflowId));
      })
      .catch((error) => {
        console.warn("Failed to delete workflow", workflowId);
        console.warn(error);
        dispatch(
          SetAlert({ type: "error", text: "Failed to delete workflow" })
        );
      });
  };
}

export function SetWorkflowOptions(options, workflowId) {
  const obtainWorkflow = (id, state) => {
    return state.hubly.data.hub.workflows[id];
  };
  const assignOptions = (workflow) => {
    return { options: { ...workflow.options, ...options } };
  };

  return (dispatch, getState) => {
    EditWorkflow(workflowId, {
      ...assignOptions(obtainWorkflow(workflowId, getState())),
    })
      .then((returnedWorkflow) => {
        dispatch(SetWorkflow(returnedWorkflow));

        dispatch(
          SetAlert({
            type: "success",
            text: "Successfully set workflow options",
          })
        );
      })
      .catch(() => {
        dispatch(
          SetAlert({
            type: "error",
            text: "Failed to set options for workflow",
          })
        );
      });
  };
}

export function SetWorkflowTitle(title, workflowId) {
  return (dispatch, getState) => {
    const request = { name: title };
    EditWorkflow(workflowId, request)
      .then((/* response */) => {
        dispatch(
          SetAlert({ type: "success", text: "Successfully set workflow name" })
        );
        const workflowCopy = {
          ...getState().hubly.data.hub.workflows[workflowId],
        };
        workflowCopy.name = title;
        dispatch(SetWorkflow(workflowCopy));
      })
      .catch((error) => {
        console.warn("Failed to set name for workflow", workflowId);
        console.warn(error);
        dispatch(
          SetAlert({ type: "error", text: "Failed to set name for workflow" })
        );
      });
  };
}

function meetsConditions(clientId, request, getState) {
  if (!request.taskCondition) return true;
  try {
    const client = getState().hubly.data.hub.clients.activeClients[clientId];
    const { taskCondition } = request;
    const condStreams = taskCondition.streams; // id list
    const condTags = taskCondition.tags; // id list
    const clientStreams = client.streams; // object list
    const clientTags = client.tags; // object list
    const { conditionType } = taskCondition;
    // streams
    for (let i = 0; i < condStreams.length; i += 1) {
      const condStream = condStreams[i];
      let found = false;
      for (let j = 0; j < clientStreams.length; j += 1) {
        const clientStream = clientStreams[j];
        if (condStream === clientStream.id) {
          if (conditionType === "ANY") {
            return true;
          } else {
            found = true;
            break;
          }
        }
      }
      if (!found && conditionType === "ALL") return false;
    }
    // tags
    for (let i = 0; i < condTags.length; i += 1) {
      const condTag = condTags[i];
      let found = false;
      for (let j = 0; j < clientTags.length; j += 1) {
        const clientTag = clientTags[j];
        if (condTag === clientTag) {
          if (conditionType === "ANY") {
            return true;
          } else {
            found = true;
            break;
          }
        }
      }
      if (!found && conditionType === "ALL") return false;
    }

    if (conditionType === "ANY") return false;
    return true;
  } catch (error) {
    console.error(error);
  }
  return false;
}

function addTaskToAll(request, dispatch, getState) {
  const state = getState();
  const { clientWorkflows } = state.hubly.data.hub;
  const { hub } = state.hubly.data.hub.selected;
  const { activeClients } = state.hubly.data.hub.clients;

  Object.values(clientWorkflows).forEach((clientWorkflow) => {
    if (
      !clientWorkflow.completed &&
      !clientWorkflow.archived &&
      clientWorkflow.workflowId === request.workflowId &&
      meetsConditions(clientWorkflow.clientId, request, getState)
    ) {
      const clientRequest = JSON.parse(JSON.stringify(request));
      // make time estimates actual estimates
      if (clientRequest.timeEstimates) {
        clientRequest.timeEstimates.forEach((te) => {
          delete te.id;
          te.autoAddedInd = true;
          te.addedBy = null;
        });
      }
      // make reminders work
      clientRequest.reminders = clientRequest.reminders.map((reminder) => {
        const time = moment();
        if (reminder.futureReminderType !== "immediate") {
          time.add(
            reminder.futureReminderNumber,
            `${reminder.futureReminderType}s`
          );
          if (reminder.futureReminderType !== "hour") {
            const futureReminderTime = moment(reminder.futureReminderTime);
            time.hours(futureReminderTime.hours());
            time.minutes(futureReminderTime.minutes());
            time.seconds(0);
          }
        }
        return {
          title: reminder.title,
          time: time,
          clientId: clientWorkflow.clientId,
        };
      });
      // make it a client workflow request
      delete clientRequest.workflowId;
      clientRequest.clientWorkflowId = clientWorkflow.id;
      AddTask(clientRequest, clientWorkflow.clientId)
        .then((response) => {
          const client = activeClients[clientWorkflow.clientId];
          response.reminders.forEach((reminder) => {
            dispatch(AddExistingReminderToClientObject(client, reminder, true));
          });
          clientWorkflow.tasks.push(response);
          dispatch(SetClientWorkflow(clientWorkflow));
        })
        .catch((error) => {
          console.error(
            "Failed to apply to all clients. Failed on clientId:",
            clientWorkflow.clientId
          );
          console.error(error);
        });
    }
  });

  GetWorkflows(hub).then((response) => {
    dispatch(SetWorkflows(response));
  });
}

export function CreateWorkflowTask(request, applyToAll, callback) {
  return (dispatch, getState) => {
    AddTask(request)
      .then((response) => {
        dispatch(AddWorkflowTask(response));
        dispatch(
          SetAlert({ type: "success", text: "Successfully added task" })
        );
        if (applyToAll) {
          addTaskToAll(request, dispatch, getState);
        }
      })
      .catch((error) => {
        console.warn("Failed to add task");
        console.warn(error);
        dispatch(SetAlert({ type: "error", text: "Failed to add task" }));
      })
      .finally(() => {
        if (callback) {
          callback();
        }
      });
  };
}

// workflow tasks only
export function RenameWorkflowTask(task, title) {
  return (dispatch, getState) => {
    const request = { title: title };

    const oldTask = { ...task };
    task.title = title;
    dispatch(EditWorkflowTask(task));
    EditTask(task.id, request)
      .then((response) => {
        dispatch(SetAlert({ type: "success", text: "Task renamed" }));
      })
      .catch((error) => {
        console.warn(error);
        dispatch(EditWorkflowTask(oldTask));
        dispatch(SetAlert({ type: "error", text: "Failed to rename task" }));
      });
  };
}

// workflow tasks only
export function RemoveWorkflowTask(taskId, workflowId) {
  return (dispatch, getState) => {
    DeleteTask(taskId, workflowId)
      .then((/* response */) => {
        dispatch(DeleteWorkflowTask(taskId, workflowId));
        dispatch(
          SetAlert({ type: "success", text: "Successfully deleted task" })
        );
      })
      .catch((error) => {
        console.warn(error);
        dispatch(SetAlert({ type: "error", text: "Failed to delete task" }));
      });
  };
}
