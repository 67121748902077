import React, { useState } from "react";
import PropTypes from "prop-types";

export function InfiniteScroll(props) {
  const { onLoadMore, loadingPage, hasMorePages, children, delay } = props;
  const { loadingElement, pullNextPageElement, noMoreElement } = props;
  const [lastBottomLoad, setLastBottomLoad] = useState(0);

  const isAtTheBottom = (e, factor = 0.8) => {
    return e.scrollTop + e.clientHeight >= factor * e.scrollHeight;
  };

  const handleLoadMore = (e) => {
    if (loadingPage || !hasMorePages) return e;

    if (
      // conditions for loading more at the bottom
      Date.now() - lastBottomLoad > delay && // dont load more than once per second, momentum scroll would cause issues
      e.deltaY > 0 && // only if event is a down scroll
      isAtTheBottom(e.currentTarget)
    ) {
      setLastBottomLoad(Date.now());
      if (onLoadMore) onLoadMore();
    }

    return e;
  };

  return (
    <div onWheel={handleLoadMore}>
      {children}

      {loadingPage && loadingElement}
      {hasMorePages && !loadingPage && pullNextPageElement}
      {!hasMorePages && !loadingPage && noMoreElement}
    </div>
  );
}

InfiniteScroll.propTypes = {
  onLoadMore: PropTypes.func.isRequired,
  loadingPage: PropTypes.bool,
  hasMorePages: PropTypes.bool,
  delay: PropTypes.number,
  loadingElement: PropTypes.node,
  pullNextPageElement: PropTypes.node,
  noMoreElement: PropTypes.node,
  children: PropTypes.array,
};

InfiniteScroll.defaultProps = {
  delay: 2000,
  loadingPage: false,
  hasMorePages: true,
  children: [],
  loadingElement: null,
  pullNextPageElement: null,
  noMoreElement: null,
};

export default InfiniteScroll;
