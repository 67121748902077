import React, { useContext } from "react";
import PropTypes from "prop-types";

import { Divider, Header } from "semantic-ui-react";

import { useDelay } from "hooks";
import { PopupButton } from "components/PopupButton/PopupButton";

import { useAssignees } from "scenes/Hubly/components/Workspace/components/Assigner/components";
import { HubContext } from "scenes/Hubly/components/Workspace/Provider";

import AssigneeFilters from "./components/AssigneeFilters";
import ClientFilters from "./components/ClientFilters";
import ManyToManyFilters from "./components/ManyToManyFilters";
import MoreFilters from "./components/MoreFilters";
import ReminderFilters from "./components/ReminderFilters";

export function Filters({ highlight, selectedFilters, onChange }) {
  const { fetchStreams, fetchTags } = useContext(HubContext);
  const [fetch, loadingAssignees, assignees] = useAssignees();

  const delayCallback = useDelay(500);
  const handleUpdateFilter = (slicedFilter) => {
    delayCallback(() => {
      onChange({ ...selectedFilters, ...slicedFilter });
    });
  };

  const getAssigneeName = () => {
    if (selectedFilters?.assigneeFilterType === "CURRENT")
      return { subject: "you", possessive: "my" };

    const assigneeName = assignees.find(({ id }) => {
      return id === selectedFilters?.assignee;
    })?.name;

    return assigneeName
      ? { subject: assigneeName, possessive: `${assigneeName}'s` }
      : null;
  };

  return (
    <PopupButton
      label="Filters"
      icon="filter"
      highlight={highlight}
      data-test="filter-menu-button"
      onOpen={fetch}
    >
      <Header
        as="h1"
        style={{ padding: "10px 15px", fontSize: "14pt", margin: "0px" }}
      >
        Filters
      </Header>
      <Divider style={{ margin: "0px" }} />
      <div style={{ padding: "15px" }}>
        <AssigneeFilters
          assigneeFilterType={selectedFilters.assigneeFilterType}
          assignee={selectedFilters.assignee}
          onUpdateFilter={handleUpdateFilter}
          loadingAssignees={loadingAssignees}
          assignees={assignees}
        />
        <ClientFilters onUpdateFilter={handleUpdateFilter} />
        <ReminderFilters
          reminderTypes={selectedFilters.reminderTypes}
          onUpdateFilter={handleUpdateFilter}
        />
        <ManyToManyFilters
          type="stream"
          fetchData={fetchStreams}
          selected={selectedFilters.streams}
          filterType={selectedFilters.streamFilterType}
          onUpdateFilter={handleUpdateFilter}
        />
        <ManyToManyFilters
          type="tag"
          fetchData={fetchTags}
          selected={selectedFilters.tags}
          filterType={selectedFilters.tagFilterType}
          onUpdateFilter={handleUpdateFilter}
        />
        <MoreFilters
          filterNextTaskForAssignee={selectedFilters.filterNextTaskForAssignee}
          filterEmptyWorkflows={selectedFilters.filterEmptyWorkflows}
          filterAssigneeIncludeServiceRole={
            selectedFilters.filterAssigneeIncludeServiceRole
          }
          filterServiceRoleUnassignedOnly={
            selectedFilters.filterServiceRoleUnassignedOnly
          }
          assigneeFilterType={selectedFilters.assigneeFilterType}
          onUpdateFilter={handleUpdateFilter}
          assigneeDisplayName={getAssigneeName()}
        />
      </div>
    </PopupButton>
  );
}

Filters.defaultProps = {
  highlight: false,
};

Filters.propTypes = {
  onChange: PropTypes.func.isRequired,
  selectedFilters: PropTypes.object.isRequired,
  highlight: PropTypes.bool,
};

export default Filters;
