import React, { useContext, useState } from "react";
import PropTypes from "prop-types";

import { Button, Popup } from "semantic-ui-react";

import { useDropdownData } from "hooks";
import { excludeDropdownData } from "components/ClientDropdown/helper";

import {
  InputControl,
  ManyToManyList,
  PopupLayout,
} from "scenes/Hubly/components/Workspace/components/ManyToManyManager/components";
import {
  HubContext,
  ToasterContext,
} from "scenes/Hubly/components/Workspace/Provider";

export function StreamPopup({ onAdd, onSelect, excludeIds }) {
  const { fireWarning } = useContext(ToasterContext);
  const { fetchStreams } = useContext(HubContext);
  const [searchText, setSearchText] = useState("");
  const [popupOpen, setPopupOpen] = useState(false);

  const dropdownData = useDropdownData({
    searchText: searchText,
    type: "stream",
    fetchFunction: fetchStreams,
  });
  const {
    loading,
    data: streamData,
    loadData,
    loadMoreData,
    hasMoreData,
  } = excludeDropdownData(excludeIds, dropdownData);

  return (
    <Popup
      data-test="stream-popup"
      position="top right"
      style={{ paddingLeft: 0, paddingRight: 0 }}
      open={popupOpen}
      onClose={(e) => {
        if (e.type !== "mouseleave") {
          setPopupOpen(false);
          setSearchText("");
        }
      }}
      trigger={
        <Button
          icon="plus"
          onClick={() => {
            setPopupOpen(!popupOpen);
            loadData();
          }}
          size="mini"
          style={{ marginLeft: "0.5em" }}
        />
      }
    >
      <PopupLayout
        title="Stream"
        loading={loading}
        inputControl={
          <InputControl
            placeholder="Add a Stream"
            loading={loading}
            onChange={(text) => {
              setSearchText(text);
              if (text === "") loadData();
            }}
            onAdd={(streamName) => {
              if (streamName === "") {
                fireWarning(
                  `Input field cannot be empty. Please verify stream name and try again.`
                );
              }
              onAdd(streamName);
              setPopupOpen(false);
              setSearchText("");
            }}
          />
        }
        list={
          <ManyToManyList
            loading={loading}
            showLoadMore={hasMoreData}
            loadMoreData={loadMoreData}
            type="stream"
            items={streamData}
            onClick={({ id }) => {
              onSelect(id);
              setPopupOpen(false);
              setSearchText("");
            }}
          />
        }
      />
    </Popup>
  );
}

StreamPopup.defaultProps = {
  excludeIds: [],
};

StreamPopup.propTypes = {
  onAdd: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  excludeIds: PropTypes.array,
};

export default StreamPopup;
