export const SetAttachments = (attachments) => {
  return {
    type: "SET_ATTACHMENTS",
    attachments: attachments,
  };
};
export const SetAttachmentsLoading = (loading) => {
  return {
    type: "SET_ATTACHMENTS_LOADING",
    attachmentsLoading: loading,
  };
};
export const SetComments = (comments) => {
  return {
    type: "SET_COMMENTS",
    comments: comments,
  };
};
