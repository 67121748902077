import React from "react";
import PropTypes from "prop-types";

import { ConfirmationModal } from "components/ConfirmationModal";

export function HouseholdConfirmationModal({
  client,
  type,
  onAddClient,
  onBulkAddClients,
  onDisableHouseholdConfirm,
  onClose,
}) {
  const { id, name } = client;

  return (
    <ConfirmationModal
      title={`Apply ${type} to Household`}
      message={`Would you like to apply this ${type} to all household members or only to ${name}?`}
      icon="users"
      buttons={[
        {
          text: "Cancel",
          callBack: () => {
            onClose(null);
          },
        },
        {
          text: `Apply to ${name}`,
          callBack: (checks) => {
            if (checks.disabled) {
              onDisableHouseholdConfirm();
            }
            onAddClient(id);
          },
        },
        {
          color: "green",
          text: `Apply to All`,
          callBack: (checks) => {
            if (checks.disabled) {
              onDisableHouseholdConfirm();
            }
            onBulkAddClients(id);
          },
        },
      ]}
      checks={[
        {
          text: "Add the selected client and don’t ask me again for this session",
          info: "Checking this option will add selected clients immediately to this stream during this browsing session.",
          name: "disabled",
        },
      ]}
    />
  );
}

HouseholdConfirmationModal.propTypes = {
  client: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  onAddClient: PropTypes.func.isRequired,
  onBulkAddClients: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onDisableHouseholdConfirm: PropTypes.func.isRequired,
};

export default HouseholdConfirmationModal;
