import moment from "moment";
import { createSelector } from "reselect";

const sortDateTypesByOrder = (dateTypes = []) => {
  return dateTypes.sort((a, b) => {
    return a.order - b.order;
  });
};

const getSortedDateTypes = (state) => {
  return sortDateTypesByOrder(
    state?.hubly?.data?.hub?.selected?.hub?.dateTypes
  );
};

const getClient = (state, props) => {
  return props.client;
};

export const calculateAge = (birthday) => {
  const age = moment().diff(birthday, "years", false);
  return age && age >= 0 ? age : undefined;
};

export const getAgeParam = (clientDate, dateType) => {
  return dateType === "Birthday" ? calculateAge(clientDate) : undefined;
};

export const displayTitleFromDate = (dateType, age) => {
  return `${dateType || ""}${age ? ` (age ${age})` : ""}`;
};

const accumulateImportantDates = (accumulator, dt, clientDates) => {
  const foundDate = clientDates.find((cd) => {
    return cd.dateTypeId === dt.id;
  });
  const age = getAgeParam(foundDate?.date, dt?.name);
  const displayTitle = displayTitleFromDate(dt?.name, age);
  return [
    ...accumulator,
    {
      ...dt,
      text: foundDate?.date,
      dateObject: foundDate,
      displayTitle: displayTitle,
    },
  ];
};

export const makeGetImportantClientDates = () => {
  return createSelector(
    [getSortedDateTypes, getClient],
    (dateTypes = [], client = { clientDates: [] }) => {
      const { clientDates = [] } = client;
      return dateTypes
        .filter((dt) => {
          return !dt.isHidden;
        })
        .reduce((acc, dt) => {
          return accumulateImportantDates(acc, dt, clientDates);
        }, []);
    }
  );
};

export default makeGetImportantClientDates;
