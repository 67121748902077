export const initialState = {};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_CLIENT_WORKFLOWS": {
      return action.clientWorkflows;
    }
    case "SET_CLIENT_WORKFLOW": {
      const nextState = { ...state };
      nextState[action.clientWorkflow.id] = action.clientWorkflow;
      return nextState;
    }
    case "DELETE_CLIENT_WORKFLOW": {
      const nextState = { ...state };
      delete nextState[action.clientWorkflowId];
      return nextState;
    }
    case "ADD_CLIENT_TASK":
      try {
        const nextState = { ...state };
        const wf = { ...nextState[action.task.clientWorkflowId] };
        nextState[action.task.clientWorkflowId] = {
          ...wf,
          tasks: [...wf.tasks, action.task],
        };
        return nextState;
      } catch (error) {
        console.error(error);
        return state;
      }
    case "EDIT_CLIENT_TASK":
      try {
        const nextState = { ...state };
        const originalWF = nextState[action.task.clientWorkflowId];
        // Deep clone causes weird problems
        const wf = JSON.parse(JSON.stringify(originalWF));
        const index = wf.tasks.findIndex((t) => {
          return t.id === action.task.id;
        });
        wf.tasks[index] = action.task;
        nextState[action.task.clientWorkflowId] = wf;
        return nextState;
      } catch (error) {
        console.error(error);
        return state;
      }
    case "DELETE_CLIENT_TASK":
      try {
        const wf = { ...state[action.clientWorkflowId] };
        wf.tasks = [
          ...wf.tasks.filter((t) => {
            return t.id !== action.taskId;
          }),
        ];
        const nextState = { ...state };
        nextState[action.clientWorkflowId] = wf;
        return nextState;
      } catch (error) {
        console.error(error);
        return state;
      }
    case "BULK_UPDATE_CLIENT_TASKS":
      try {
        const nextState = { ...state };
        nextState[action.tasks[0].clientWorkflowId].tasks = action.tasks;
        return nextState;
      } catch (e) {
        console.error(e);
        return state;
      }
    case "BULK_UPDATE_CLIENT_WORKFLOWS":
      try {
        const nextState = { ...state };
        action.clientWorkflows.forEach((cw) => {
          nextState[cw.id] = cw;
        });
        return nextState;
      } catch (e) {
        console.error(e);
        return state;
      }
    case "UPDATE_TASK_REMINDER":
      try {
        const nextState = { ...state };
        const updatedTasks = nextState[
          action.reminder.clientWorkflowId
        ].tasks.map((task) => {
          return task.id === action.reminder.taskId
            ? {
                ...task,
                reminders: task.reminders.map((r) => {
                  if (r.id === action.reminder.id) {
                    return action.reminder;
                  } else {
                    return r;
                  }
                }),
              }
            : task;
        });
        nextState[action.reminder.clientWorkflowId] = {
          ...nextState[action.reminder.clientWorkflowId],
          tasks: updatedTasks,
        };
        return nextState;
      } catch (e) {
        console.error(e);
        return state;
      }
    default:
      return state;
  }
};

export const clientWorkflowLoader = (
  state = { loading: false, error: null },
  action
) => {
  switch (action.type) {
    case "FETCHING_CLIENT_WITH_WORKFLOWS": {
      return action.value;
    }
    default: {
      return state;
    }
  }
};

export default reducer;
