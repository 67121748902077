import React from "react";
import PropTypes from "prop-types";

import { Header } from "semantic-ui-react";

export function WorkflowColumnHeader({ children }) {
  return (
    <Header
      data-test={`workflow-view-title-${children}`}
      style={{ display: "inline" }}
      as="h3"
    >
      {children}
    </Header>
  );
}

WorkflowColumnHeader.propTypes = {
  children: PropTypes.string,
};

WorkflowColumnHeader.defaultProps = {
  children: "Untitled",
};

export default WorkflowColumnHeader;
