export const OpenManageHouseholdModal = (householdId) => {
  return {
    type: "OPEN_MANAGE_HOUSEHOLD_MODAL",
    householdId: householdId,
  };
};
export const CloseManageHouseholdModal = () => {
  return {
    type: "CLOSE_MANAGE_HOUSEHOLD_MODAL",
  };
};
