import React, { useState } from "react";
import PropTypes from "prop-types";

import "./ServiceTeamList.css";

import { CrmAdvisorMatcher, MemberRole } from "./components";

export function ServiceTeamList({
  serviceTeam,
  serviceRoles,
  defaultTeam,
  onMissingAdvisorAssigned,
}) {
  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  const byRole = (role) => {
    return (member) => {
      return member.serviceRole.name === role.name;
    };
  };

  const renderServiceRole = (role) => {
    const members = serviceTeam?.serviceTeamMembers?.filter(byRole(role));
    const defaultMembers = defaultTeam?.serviceTeamMembers?.filter(
      byRole(role)
    );

    return (
      <div style={{ display: "flex", alignItems: "baseline" }} key={role.name}>
        <MemberRole
          members={members}
          defaultMembers={defaultMembers}
          roleName={role.name}
        />
        {members?.length === 1 && (
          <CrmAdvisorMatcher
            member={members[0]}
            onUpdated={onMissingAdvisorAssigned}
          />
        )}
      </div>
    );
  };

  const visible = (v) => {
    return (role) => {
      return role.showMore !== v;
    };
  };

  if (!serviceRoles || !serviceRoles.length) {
    return <div>No Service Roles</div>;
  }

  const showMoreList = serviceRoles
    .filter(visible(false))
    .map(renderServiceRole);

  return (
    <div data-test="service-team-list">
      {serviceRoles.filter(visible(true)).map(renderServiceRole)}
      {showMore && showMoreList}
      {showMoreList.length > 0 ? (
        <div
          style={{
            color: "grey",
            textAlign: "center",
            cursor: "pointer",
            fontSize: "13.3px",
          }}
          onClick={toggleShowMore}
        >
          <span className="show-more">
            {!showMore ? "Show More" : "Show Less"}
          </span>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

ServiceTeamList.propTypes = {
  serviceTeam: PropTypes.object.isRequired,
  serviceRoles: PropTypes.array.isRequired,
  defaultTeam: PropTypes.object.isRequired,
  onMissingAdvisorAssigned: PropTypes.func.isRequired,
};

export default ServiceTeamList;
