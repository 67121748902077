import React from "react";
import PropTypes from "prop-types";

import { useManageServiceTeam } from "./hooks";
import { ServiceTeamModal } from "./components";

export default function ManageServiceTeamModal({
  serviceRoles,
  serviceTeam,
  defaultSettingsTeam,
  onClose,
}) {
  const team = useManageServiceTeam({ serviceTeam, serviceRoles });

  const saveButtonText = () => {
    if (!team.editMode) {
      return "Add";
    }
    return team.hasMatchedTeam() && !team.isDefaultTeam ? "Merge" : "Save";
  };

  const renderWarningMessage = () => {
    const renderMergeText = () => {
      return team.hasMatchedTeam() && team.editMode
        ? `Click "Merge" below to merge these ${team.totalClient} contacts with the existing Service Team.`
        : "Please select additional team members or configure additional roles to create a new team.";
    };

    const hasDuplicateMember = () => {
      return team.hasMatchedTeam() && !team.isDefaultTeam
        ? `This Service Team already exists with ${
            team.totalMatchedTeamClient
          } contacts. ${renderMergeText()}`
        : "";
    };

    return team.hasDuplicateTeamName()
      ? "The Service Team name already exists and is unable to be saved."
      : hasDuplicateMember();
  };

  const renderDescriptionMessage = () => {
    const renderMessage = () => {
      if (!team.editMode)
        return "Quickly create a new Service Team by adding users to the roles below.";
      else if (team.isDefaultTeam)
        return `These assignments will be used by contacts that don't have a Service Team assigned, 
        as well as for all Service Teams when no user is assigned to a Role.`;
      else
        return `Manage user role assignments for this team. These changes will apply to ${
          team.totalClient
        } contact${team.totalClient === 1 ? "" : "s"}.`;
    };
    return serviceRoles.length === 0 ? undefined : renderMessage();
  };

  return (
    <ServiceTeamModal
      loading={team.loading}
      name={team.name}
      roles={team.roles}
      editMode={team.editMode}
      isDefaultTeamSelected={team.isDefaultTeam}
      defaultTeamMembers={defaultSettingsTeam.serviceTeamMembers}
      description={renderDescriptionMessage()}
      duplicateMessage={renderWarningMessage()}
      actionText={saveButtonText()}
      disableActionButton={team.disableActionButton()}
      onChangeAdvisor={team.updateSelection}
      onSave={() => {
        const selectUpdate = () => {
          return team.hasMatchedTeam() && !team.isDefaultTeam
            ? team.merge
            : team.edit;
        };

        const action = team.editMode ? selectUpdate() : team.add;
        action().then(onClose);
      }}
      onNameChange={team.setTeamName}
      onClose={onClose}
    />
  );
}

ManageServiceTeamModal.propTypes = {
  serviceTeam: PropTypes.object,
  serviceRoles: PropTypes.array,
  defaultSettingsTeam: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

ManageServiceTeamModal.defaultProps = {
  serviceTeam: null,
  serviceRoles: [],
};
