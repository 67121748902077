import React from "react";
import PropTypes from "prop-types";

import { Popup } from "semantic-ui-react";

import { isTargetPartOfTheNestedPopup } from "../../helper";

export function Layout({
  opened,
  trigger,
  onOpen,
  onClose,
  onMount,
  children,
}) {
  return (
    <React.Fragment>
      <div style={{ flex: "0 1 auto", margin: "0" }}>
        <Popup
          data-test="workflow-filters-popup"
          on="click"
          open={opened}
          onOpen={onOpen}
          onClose={(event) => {
            // If we're clicking in the nested components, don't close the popup!
            if (isTargetPartOfTheNestedPopup(event.target)) return;
            onClose();
          }}
          onMount={onMount}
          style={{
            padding: "0.8em 0em",
            height: "auto",
            zIndex: "1000",
          }}
          trigger={<div>{trigger}</div>}
        >
          <div className="workflow-menu-container">
            {children}
            <div style={{ height: "0.5em", width: "100%" }} />
          </div>
        </Popup>
      </div>
    </React.Fragment>
  );
}

Layout.propTypes = {
  opened: PropTypes.bool,
  trigger: PropTypes.node,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  onMount: PropTypes.func,
  children: PropTypes.node,
};

Layout.defaultProps = {
  opened: false,
  trigger: null,
  onOpen: () => {},
  onClose: () => {},
  onMount: () => {},
  children: null,
};

export default Layout;
