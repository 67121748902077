import { v4 } from "uuid";

export const AddAlertToQueue = (alert) => {
  return {
    type: "ADD_ALERT_TO_QUEUE",
    alert: alert,
  };
};

export const RemoveAlertFromQueueById = (alertId) => {
  return {
    type: "REMOVE_ALERT_FROM_QUEUE_BY_ID",
    alertId: alertId,
  };
};

export const SetAlert = (alert) => {
  alert.id = v4();
  if (alert.type) {
    switch (alert.type) {
      case "error":
        alert.linger = 5000;
        alert.icon = "delete";
        alert.color = "red";
        break;
      case "success":
        alert.linger = 2000;
        alert.icon = "check";
        alert.color = "green";
        break;
      case "warning":
        alert.linger = 2000;
        alert.icon = "warning";
        alert.color = "orange";
        break;
      default:
        console.warn(
          "Warning. You must assign your Alerts with a 'type'. One of [error, success, warning]"
        );
    }
  }

  return (dispatch) => {
    dispatch(AddAlertToQueue(alert));
    setTimeout(() => {
      dispatch(RemoveAlertFromQueueById(alert.id));
    }, alert.linger);
  };
};
