// these correspond to the same colors in checkboxColors.css
const colors = {
  "#D98880": 1,
  "#F1948A": 2,
  "#D7BDE2": 3,
  "#BB8FCE": 4,
  "#7FB3D5": 5,
  "#5DADE2": 6,
  "#C4FAF8": 7,
  "#48C9B0": 8,
  "#45B39D": 9,
  "#F7DC6F": 10,
  "#F8C471": 11,
  "#F0B27A": 12,
};

export const bonusColor = "#d1eaa3";

export default colors;
