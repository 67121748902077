/* eslint-disable react/prop-types */
import React from "react";

export function WithScrollToLoad(Component) {
  return ({ loadMoreData, showLoadMore, loading, children, ...otherProps }) => {
    const handleScrollEvent = ({
      target: { scrollHeight, clientHeight, scrollTop },
    }) => {
      if (
        showLoadMore &&
        !loading &&
        scrollTop + clientHeight === scrollHeight
      ) {
        loadMoreData();
      }
    };
    return (
      <Component onScroll={handleScrollEvent} {...otherProps}>
        {children}
      </Component>
    );
  };
}

export default WithScrollToLoad;
