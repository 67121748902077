import React from "react";
import PropTypes from "prop-types";

import { Dropdown, Icon } from "semantic-ui-react";

const PureRuleClientsDropdown = ({
  clientOptionsFiltered,
  streamOptionsFiltered,
  tagOptionsFiltered,
  selectDropdownItem,
  showClients,
  ...dropdownProps
}) => {
  return (
    <Dropdown
      clearable
      multiple
      placeholder="Select streams or tags"
      renderLabel={(item, index, object) => {
        object.content = item.text;
        object.style = { backgroundColor: item.color, color: "white" };
        if (item.type === "tag") {
          object.className = "rule_modal_dropdown_tag_label";
          object.icon = "hashtag";
        } else if (item.type === "client") {
          object.style = {};
        }
        return object;
      }}
      search
      selection
      {...dropdownProps}
    >
      <Dropdown.Menu
        id="filtering_dropdown_menu"
        style={{ paddingBottom: "1em" }}
      >
        {showClients && (
          <React.Fragment>
            <Dropdown.Header content="Clients" style={{ fontSize: "10pt" }} />
            {clientOptionsFiltered.length > 0 ? (
              clientOptionsFiltered.map((client) => {
                return (
                  <Dropdown.Item
                    key={client.key}
                    onClick={() => {
                      selectDropdownItem(client, "client");
                    }}
                    value={client.key}
                  >
                    {client.text}
                  </Dropdown.Item>
                );
              })
            ) : (
              <span style={{ marginLeft: "2em", color: "grey" }}>
                No matching clients
              </span>
            )}
          </React.Fragment>
        )}
        <Dropdown.Header content="Streams" style={{ fontSize: "10pt" }} />
        {streamOptionsFiltered.length > 0 ? (
          streamOptionsFiltered.map((stream) => {
            return (
              <Dropdown.Item
                key={stream.key}
                onClick={() => {
                  selectDropdownItem(stream, "stream");
                }}
              >
                <Icon name="circle" style={{ color: stream.color }} />
                {stream.text}
              </Dropdown.Item>
            );
          })
        ) : (
          <span style={{ marginLeft: "2em", color: "grey" }}>
            No matching streams
          </span>
        )}
        <Dropdown.Header content="Tags" style={{ fontSize: "10pt" }} />
        {tagOptionsFiltered.length > 0 ? (
          tagOptionsFiltered.map((tag) => {
            return (
              <Dropdown.Item
                key={tag.key}
                onClick={() => {
                  selectDropdownItem(tag, "tag");
                }}
              >
                <Icon name="hashtag" />
                {tag.text}
              </Dropdown.Item>
            );
          })
        ) : (
          <span style={{ marginLeft: "2em", color: "grey" }}>
            No matching tags
          </span>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

PureRuleClientsDropdown.propTypes = {
  clientOptionsFiltered: PropTypes.array,
  streamOptionsFiltered: PropTypes.array,
  tagOptionsFiltered: PropTypes.array,
  selectDropdownItem: PropTypes.func.isRequired,
  showClients: PropTypes.bool,
};

PureRuleClientsDropdown.defaultProps = {
  clientOptionsFiltered: [],
  streamOptionsFiltered: [],
  tagOptionsFiltered: [],
  showClients: false,
};

export default PureRuleClientsDropdown;
