import { useState } from "react";

export function useSyncDecider() {
  const [updated, setUpdated] = useState({});

  return {
    shouldSync: () => {
      return Object.values(updated).some((name) => {
        return Boolean(name);
      });
    },
    updateDecision: (id, value) => {
      if (!id) return;
      setUpdated((prevValue) => {
        return { ...prevValue, [id]: value };
      });
    },
    clearDecision: () => {
      setUpdated({});
    },
  };
}

export default useSyncDecider;
