import { Layout } from "./Layout";
import { LayoutBody } from "./LayoutBody";
import { LayoutHeader } from "./LayoutHeader";
import { LayoutIcon } from "./LayoutIcon";

Layout.Icon = LayoutIcon;
Layout.Body = LayoutBody;
Layout.Header = LayoutHeader;

export { Layout };
export default Layout;
