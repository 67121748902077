import React, { useContext, useState } from "react";
import PropTypes from "prop-types";

import { Button } from "semantic-ui-react";

import SimpleFormModal from "components/SimpleFormModal";

import { useFilterComparator } from "./hooks";
import { HubContext, ToasterContext } from "../../Provider";
import { useAccessTypeStore } from "../hooks";

export function SaveView({
  filters,
  currentView,
  selectedWorkflows,
  onCreateView,
  onUpdateView,
}) {
  const { createView, editFilter } = useContext(HubContext);
  const { fireError, fireSuccess } = useContext(ToasterContext);

  const [showCreateViewModal, setShowCreateViewModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { hasAccess } = useAccessTypeStore();

  const { modified } = useFilterComparator(currentView);

  if (!modified || (!hasAccess() && currentView)) {
    return null;
  }

  const getFilterRequest = () => {
    return {
      ...filters,
      assignee: filters.assignee
        ? filters.assignee
        : filters.assigneeFilterType,
      clientFilter: filters.clients.map(({ id }) => {
        return id;
      }),
      workflows: selectedWorkflows,
      streams: filters.streams,
      tags: filters.tags.map(({ id }) => {
        // Unlike streams, tags must be sent as array of strings instead
        // of array of objects
        return id;
      }),
    };
  };

  const create = async (view) => {
    setLoading(true);
    try {
      const { id } = await createView(getFilterRequest(), view);
      fireSuccess("Successfully created view.");
      setShowCreateViewModal(false);
      setLoading(false);
      onCreateView({
        ...view,
        id: id,
        filters: filters,
        workflows: selectedWorkflows,
      });
    } catch (error) {
      fireError("Failed to create view.", error);
      setLoading(false);
    }
  };

  const update = () => {
    setLoading(true);
    editFilter(filters.id, getFilterRequest())
      .then(() => {
        setLoading(false);
        fireSuccess("Successfully edited view.");
        onUpdateView({
          ...currentView,
          filters: filters,
          workflows: selectedWorkflows,
        });
      })
      .catch((error) => {
        fireError("Failed to edit view.", error);
        setLoading(false);
      });
  };

  return (
    <React.Fragment>
      <Button
        compact
        primary={!currentView?.id}
        content={currentView?.id ? "Update View" : "Create View"}
        loading={loading}
        onClick={() => {
          if (currentView?.id) {
            update();
          } else {
            setShowCreateViewModal(true);
          }
        }}
        basic={!!currentView?.id}
        style={{ marginRight: "0.5em", minWidth: "max-content" }}
      />

      {showCreateViewModal && (
        <SimpleFormModal
          title="View"
          loading={loading}
          onClickSave={create}
          onClosed={() => {
            setShowCreateViewModal(false);
          }}
        />
      )}
    </React.Fragment>
  );
}

SaveView.defaultProps = {
  currentView: null,
  selectedWorkflows: [],
};

SaveView.propTypes = {
  currentView: PropTypes.object,
  filters: PropTypes.object.isRequired,
  selectedWorkflows: PropTypes.array,
  onCreateView: PropTypes.func.isRequired,
  onUpdateView: PropTypes.func.isRequired,
};

export default SaveView;
