import React from "react";
import PropTypes from "prop-types";

import { Dropdown } from "semantic-ui-react";

import useGetRoles from "../../../hooks/useGetRoles";
import PermissionText from "../PermissionText";

function AdvisorPermissionDropdown({
  roleId,
  disabled,
  onRoleChange,
  ...props
}) {
  const { data, loading } = useGetRoles();
  const dropdownOptions = data.map(({ id, title }) => {
    return {
      key: id,
      value: id,
      text: title,
    };
  });

  return (
    <React.Fragment>
      <label>Permissions</label>
      <Dropdown
        data-test="role-dropdown"
        name="role"
        fluid
        search
        selection
        loading={loading}
        disabled={disabled}
        value={roleId}
        options={dropdownOptions}
        onChange={onRoleChange}
        {...props}
      />
      <PermissionText
        roleTitle={
          dropdownOptions.find((r) => {
            return r.value === roleId;
          })?.text
        }
      />
    </React.Fragment>
  );
}

AdvisorPermissionDropdown.propTypes = {
  roleId: PropTypes.string,
  disabled: PropTypes.bool,
  onRoleChange: PropTypes.func,
};

AdvisorPermissionDropdown.defaultProps = {
  roleId: "",
  disabled: false,
  onRoleChange: () => {},
};
export default AdvisorPermissionDropdown;
