import { API } from "aws-amplify";

import { getAPIReq } from "data/libs/authLib";

import toGlobalId from "./helper";

export const getUpcomingReminders = async ({ hubId, clientId }) => {
  const req = await getAPIReq(
    {
      query: `query getUpcomingReminders($client: ID) {
        reminders(orderBy: "time", client: $client, completedAt_Isnull: true, dismissed: false) {
          edges {
            node {
              uuid
              title
              time
              task {
                title
                uuid
                clientWorkflow {
                  uuid
                  workflow {
                    uuid
                  }
                }
              }
              client {
                uuid
                firstName
                lastName
              }
            }
          }
        }
      }`,
      variables: {
        client: toGlobalId("Client", clientId),
      },
    },
    { hub: hubId }
  );

  const {
    data: { reminders },
  } = await API.post("HublyAPI", `/graphql`, req);
  return reminders.edges.map(({ node }) => {
    return {
      ...node,
      id: node.uuid,
      client: {
        ...node.client,
        id: node.client.uuid,
      },
      task: !node.task
        ? undefined
        : {
            ...node.task,
            clientWorkflow: {
              ...node.task.clientWorkflow,
              id: node.task.clientWorkflow.uuid,
            },
            id: node.task.uuid,
          },
    };
  });
};
export const getReminders = async ({
  hubId,
  pageSize = 100,
  offset = 0,
  after = "",
  assignee,
  orderBy = "time",
  completedAtIsNull = true,
  dismissed = false,
  includeUnassigned = false,
}) => {
  const req = await getAPIReq(
    {
      query: `
      query getReminders(
        $pageSize: Int,
        $offset: Int,
        $after: String,
        $assigneeId: ID,
        $orderBy: String,
        $completedAtIsNull: Boolean, 
        $dismissed: Boolean,
        $includeUnassigned: Boolean
        ) {
        reminders(
          first: $pageSize,
          offset: $offset,
          after: $after,
          assigneeId: $assigneeId
          orderBy: $orderBy, 
          completedAt_Isnull: $completedAtIsNull, 
          dismissed: $dismissed,
          includeUnassigned: $includeUnassigned
          ) {
          edges {
            node {
              uuid,
              title
              dismissed
              completedAt
              time
              task {
                uuid
                clientWorkflow {
                  uuid
                  workflow {
                    uuid
                  }
                }
                assignee {
                  name
                  group
                }
              }
              client {
                uuid,
                name
                firstName
                lastName
              }
            }
            cursor
          }
          pageInfo {
            hasPreviousPage
            hasNextPage
            endCursor
          }
        }
      }`,
      variables: {
        pageSize: pageSize,
        offset: offset,
        after: after,
        assigneeId: assignee ? toGlobalId("Assignee", assignee) : null,
        orderBy: orderBy,
        completedAtIsNull: completedAtIsNull,
        dismissed: dismissed,
        includeUnassigned: includeUnassigned,
      },
    },
    { hub: hubId }
  );
  const {
    data: { reminders },
  } = await API.post("HublyAPI", `/graphql`, req);

  return {
    data: reminders.edges.map(({ node }) => {
      return {
        ...node,
        id: node.uuid,
        client: {
          ...node.client,
          id: node.client.uuid,
        },
        task: node.task
          ? {
              ...node.task,
              id: node.task.uuid,
              clientWorkflow: {
                id: node.task.clientWorkflow.uuid,
                workflow: {
                  id: node.task.clientWorkflow.workflow.uuid,
                },
              },
            }
          : undefined,
      };
    }),
    pageInfo: reminders.pageInfo,
  };
};

export const getRemindersCount = async ({
  hubId,
  assignee,
  includeUnassigned,
}) => {
  const req = await getAPIReq(
    {
      query: `query getRemindersCount($assigneeId: ID, $includeUnassigned: Boolean) {
                remindersCount(assigneeId: $assigneeId, includeUnassigned: $includeUnassigned) {
                  numOverdue
                  numDueToday
                }
              }      
        `,
      variables: {
        assigneeId: assignee ? toGlobalId("Assignee", assignee) : null,
        includeUnassigned: includeUnassigned,
      },
    },
    { hub: hubId }
  );
  const {
    data: { remindersCount },
  } = await API.post("HublyAPI", `/graphql`, req);

  return [remindersCount];
};

export default getUpcomingReminders;
