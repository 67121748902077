import React from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import { datadogRum } from "@datadog/browser-rum";
import getMiliSecondDuration from "analytics/helper";
import moment from "moment";
import {
  Button,
  Dropdown,
  Header,
  Icon,
  Popup,
  Progress,
} from "semantic-ui-react";

import history from "data/history";
import { SetAttachments } from "data/hub/clientCard/actions";
import { SetActiveClient, SetActiveClients } from "data/hub/clients/actions";
import {
  ArchiveClientWorkflow,
  RemoveClientWorkflow,
  SetClientWorkflow,
  SetClientWorkflows,
  ToggleClientWorkflowComplete,
} from "data/hub/clientWorkflows/actions";
import {
  AddClientToWorkflow,
  SetWorkflow,
  SetWorkflows,
} from "data/hub/workflows/actions";
import { GetClient } from "data/libs/clients";
import {
  CreateClientWorkflow,
  DeleteClientWorkflow,
  MoveClientWorkflow,
  ReassignWorkflowsTasks,
} from "data/libs/clientWorkflows";
import { AddComment } from "data/libs/comments";
import { DeleteNextWorkflow } from "data/libs/nextWorkflows";
import { BulkUpdateTasks } from "data/libs/tasks";
import {
  CompareReminders,
  getNextReminderInfo,
  InsertReminder,
  SetReminders,
} from "data/reminders/actions";

import Access from "components/Access";
import { SetAlert } from "components/Alerts/actions";
import AssignedAdvisorPopup from "components/AssignedAdvisorPopup";
import { SetConfirmationModal } from "components/ConfirmationModal/actions";
import EditableField from "components/EditableField";
import { FeatureFlag } from "components/FeatureFlag";
import InitialsCircle from "components/InitialsCircle";
import { PrivacyModeContext } from "components/PrivacyMode/Context";
import Task from "components/Task";
import { SetAllTasksComplete } from "components/Task/actions";
import TaskAdd from "components/Task/components/TaskAdd";

import DropdownClientWorkflows from "./components/DropdownClientWorkflows";
import ToggleAuditOpen from "./components/ExportAuditTrail/actions";
import ScrollToTaskWrapper from "./components/ScrollToTaskWrapper";
import { CreateNewWorkflow } from "../../../../../Workflows/actions";
import MultiTasksAssigners from "../../../../../Workspace/components/MultiTasksAssigners";
import { SetNameClientWorkflow, ToggleHideCompleteTasks } from "../../actions";
import { generateCWFContent } from "./helpers";
import withWorkflowResolver from "./WithWorkflowResolver";

const getCardWorkflowsStyle = (isDraggingOver) => {
  return {
    background: isDraggingOver ? "none" : "none",
  };
};

class CardWorkflows extends React.Component {
  static contextType = PrivacyModeContext;

  static propTypes = {
    addClientToWorkflow: PropTypes.func.isRequired,
    createNewWorkflow: PropTypes.func.isRequired,
    allClientNames: PropTypes.array.isRequired,
    reminders: PropTypes.array.isRequired,
    activeClients: PropTypes.array.isRequired,
    hub: PropTypes.object.isRequired,
    client: PropTypes.object.isRequired,
    advisor: PropTypes.object.isRequired,
    clientWorkflows: PropTypes.object.isRequired,
    comments: PropTypes.array.isRequired,
    households: PropTypes.object.isRequired,
    newCommentRef: PropTypes.object.isRequired,
    removeClientWorkflow: PropTypes.func.isRequired,
    setNameClientWorkflow: PropTypes.func.isRequired,
    setActiveClients: PropTypes.func.isRequired,
    setActiveClient: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired,
    setAttachments: PropTypes.func.isRequired,
    setClientWorkflow: PropTypes.func.isRequired,
    reassignTasks: PropTypes.func.isRequired,
    setClientWorkflows: PropTypes.func.isRequired,
    setConfirmationModal: PropTypes.func.isRequired,
    setReminders: PropTypes.func.isRequired,
    setWorkflow: PropTypes.func.isRequired,
    toggleHideCompleteTasks: PropTypes.func.isRequired,
    updateTasks: PropTypes.func.isRequired,
    workflows: PropTypes.object.isRequired,
    workflowId: PropTypes.string.isRequired,
    updateClient: PropTypes.func.isRequired,
    toggleAuditOpen: PropTypes.func.isRequired,
    toggleClientWorkflowComplete: PropTypes.func.isRequired,
    completeAllTasks: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    taskRefs: PropTypes.object.isRequired,
    archiveClientWorkflow: PropTypes.func.isRequired,
    fetchWorkflows: PropTypes.func.isRequired,
  };

  state = {
    addingTask: false,
    popupOpen: false,
    adding: false,
    dropdownValue: "",
    searchValue: "",
    loading: false,
    completionLoading: false,
    dropdownOpen: false,
    renamingClientWorkflow: false,
    timeSpentMarkWorkflowComplete: 0,
  };

  componentDidMount() {
    const { hub, fetchWorkflows } = this.props;

    this.setState({ loading: true });
    fetchWorkflows(hub).then(() => {
      this.setState({ loading: false });
    });
  }

  getSnapshotBeforeUpdate(prevProps, prevState) {
    const { workflowId } = this.props;
    if (workflowId !== prevProps.workflowId) {
      return {
        startTime: new Date().getTime(),
      };
    }
    return {
      startTime: 0,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (snapshot.startTime) {
      datadogRum.addAction(
        `hubly_timespent_cardworkflows_load_rightworkflows`,
        {
          duration: getMiliSecondDuration(snapshot.startTime),
        }
      );
    }
  }

  onDragEnd = (result) => {
    const { clientWorkflows, setClientWorkflow, workflowId } = this.props;

    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const clientWorkflow = Object.values(clientWorkflows).find((cw) => {
      return cw.id === workflowId;
    });

    const tasks = this.reorder(
      clientWorkflow.tasks,
      result.source.index,
      result.destination.index
    );

    const tasksRequest = {
      tasks: [],
    };

    const max = this.getMaxOrderValue(tasks);

    tasks.forEach((task, i) => {
      task.order = max + i + 1;
      tasksRequest.tasks.push({
        id: task.id,
        order: max + i + 1,
      });
    });

    clientWorkflow.tasks = tasks;
    setClientWorkflow(clientWorkflow);
    BulkUpdateTasks(tasksRequest, null, clientWorkflow.id);
  };

  getMaxOrderValue = (items) => {
    let max = 0;
    items.forEach((item) => {
      if (item.order && item.order > max) {
        max = item.order;
      }
    });

    return max;
  };

  // a little function to help us with reordering the result
  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  handleChange = (e, { name, value }) => {
    const { hub, client } = this.props;
    this.setState({ [name]: value });
    if (name === "workflowId") {
      history.push(`/hub/${hub.hubId}/clients/${client.id}/workflows/${value}`);
    }
  };

  togglePopup = () => {
    this.setState((state) => {
      return { popupOpen: !state.popupOpen };
    });
  };

  moveToNextWorkflows = (clientWorkflowId) => {
    const {
      addClientToWorkflow,
      hub,
      client,
      clientWorkflows,
      reminders,
      setAlert,
      setActiveClient,
      setClientWorkflow,
      setReminders,
      workflowId,
    } = this.props;

    const foundClientWorkflow = clientWorkflows[clientWorkflowId];
    // check if already in workflow
    foundClientWorkflow.nextWorkflows.forEach((nextWorkflow, index) => {
      if (nextWorkflow.startDate) return; // skip workflows with set start dates

      if (
        client.workflows.find((id) => {
          const cw = clientWorkflows[id];
          return (
            !cw.completed &&
            !cw.archived &&
            cw.workflowId === nextWorkflow.nextWorkflowId
          );
        })
      ) {
        setAlert({
          type: "warning",
          text: `${client.firstName} is already in ${nextWorkflow.nextWorkflowName}.`,
        });
        DeleteNextWorkflow(nextWorkflow.id).catch((e) => {
          console.error(e);
        });
        return;
      }

      this.setState({ loading: true });
      const request = {
        nextWorkflowId: nextWorkflow.id,
        completed: true,
        isHidden: true,
        previousWorkflowId: workflowId,
      };
      MoveClientWorkflow(clientWorkflowId, request)
        .then((response) => {
          foundClientWorkflow.nextWorkflows.splice(index, 1);
          setClientWorkflow(foundClientWorkflow);
          setClientWorkflow(response);
          const clientCopy = { ...client };
          clientCopy.workflows.push(response.id);
          setActiveClient(clientCopy);

          // Need to set reminders for the Hub Feed as well
          const remindersCopy = reminders.slice();
          (response.tasks || []).forEach((task) => {
            task.reminders.forEach((reminder) => {
              remindersCopy.push(reminder);
            });
          });
          const sortedReminders = remindersCopy.sort(CompareReminders);
          setReminders(sortedReminders);

          addClientToWorkflow(response.workflowId, response.id);
          history.push(
            `/hub/${hub.hubId}/clients/${clientCopy.id}/workflows/${response.id}`
          );
        })
        .catch((error) => {
          console.error(error);
          setAlert({
            type: "error",
            text: `Failed to start ${nextWorkflow.nextWorkflowName}, it might be deleted`,
          });
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    });
  };

  dropdownItemStyle = (completed, value) => {
    const { workflowId } = this.props;
    const style = {};
    if (workflowId === value) {
      style.fontWeight = "bold";
      style.fontSize = "13pt";
    }
    if (completed) {
      style.fontStyle = "italic";
    }
    return style;
  };

  toggleWorkflow = (completed) => {
    const { client, toggleClientWorkflowComplete, workflowId } = this.props;
    this.setState({
      completionLoading: true,
      timeSpentMarkWorkflowComplete: new Date().getTime(),
    });
    toggleClientWorkflowComplete(client.id, workflowId, completed, () => {
      datadogRum.addAction("hubly_timespent_cardworkflows_mark_complete", {
        duration: getMiliSecondDuration(
          this.state.timeSpentMarkWorkflowComplete
        ),
      });
      this.setState({
        completionLoading: false,
        timeSpentMarkWorkflowComplete: 0,
      });
      if (completed) {
        this.createCommentRecord();
      }
    });
  };

  renameClientWorkflow = (newName) => {
    const { setNameClientWorkflow, clientWorkflows, workflowId } = this.props;

    setNameClientWorkflow({
      clientWorkflowId: clientWorkflows[workflowId]?.id,
      newName: newName,
      onSuccess: () => {
        this.setState({ renamingClientWorkflow: false });
      },
    });
  };

  createCommentRecord = () => {
    const { client, advisor, setAlert, hub, clientWorkflows, workflowId } =
      this.props;

    if (!hub.logCompletedWorkflowsInCrm) return;

    const isCRMIntegrated =
      advisor.integrations.find((integration) => {
        return (
          integration.hubId === hub.id &&
          (integration.type === "redtail" || integration.type === "wealthbox")
        );
      }) !== undefined;

    if (!isCRMIntegrated) return; // no reason to create a comment

    const workflow = clientWorkflows[workflowId];
    if (!workflow) {
      // cant find the workflow...
      setAlert({ type: "error", text: "Failed to log workflow on synced CRM" });
      return;
    }
    const content = `<p>
                        Workflow <b>${workflow.name}</b> has been completed by
                        <b>${advisor.firstName || ""} ${
      advisor.lastName || ""
    }</b>
                        on ${moment().format("MMMM Do Y")} for <b>${
      client.name
    }</b>.
                        <a href="${window.location.origin}/hub/${
      hub.hubId
    }/clients/${client.id}/workflows/${workflow.id}">
                          View this workflow in Hubly.
                        </a>
                     </p>`;

    const request = {
      creator: advisor.externalId,
      content: content,
      clientExternalId: client.externalId,
      clientId: client.id,
      isCrmOnly: true,
    };

    AddComment(request)
      .catch(() => {
        setAlert({
          type: "error",
          text: "Failed to log workflow on synced CRM",
        });
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  };

  deleteClientWorkflow = (clientWorkflow) => {
    const { hub, client, removeClientWorkflow, setConfirmationModal } =
      this.props;
    const remove = () => {
      DeleteClientWorkflow(clientWorkflow.id, clientWorkflow.workflowId).then(
        () => {
          removeClientWorkflow(clientWorkflow);
          history.push(`/hub/${hub.hubId}/clients/${client.id}`);
        }
      );
    };
    const params = {
      title: "Delete Client Workflow",
      message: "Do you want to delete this workflow?  This cannot be undone.",
      icon: "delete",
      buttons: [
        { text: "Cancel" },
        {
          text: "Delete Client Workflow",
          callBack: remove,
          color: "red",
        },
      ],
    };
    setConfirmationModal(params);
    this.setState({ popupOpen: false });
  };

  archiveClientWorkflow = (clientWorkflow) => {
    const { client, archiveClientWorkflow, setAlert } = this.props;
    archiveClientWorkflow(clientWorkflow, () => {
      setAlert({
        type: "success",
        text: `${client.firstName} ${
          client.lastName || ""
        } was archived from "${clientWorkflow?.name || ""}"`,
      });
    });
    this.setState({ popupOpen: false });
  };

  completeAllConfirm = (clientWorkflow) => {
    const { setConfirmationModal, completeAllTasks } = this.props;
    const complete = () => {
      completeAllTasks(clientWorkflow);
    };
    const params = {
      title: "Mark All Tasks Complete",
      message: "Do you want to mark all tasks in this workflow as complete?",
      icon: "check square outline",
      buttons: [
        { text: "Cancel" },
        {
          text: "Mark All Complete",
          callBack: complete,
          color: "white",
        },
      ],
    };
    setConfirmationModal(params);
    this.setState({ popupOpen: false });
  };

  renderWorkflows = () => {
    const {
      addingTask,
      popupOpen,
      loading,
      completionLoading,
      renamingClientWorkflow,
    } = this.state;
    const {
      client,
      clientWorkflows,
      comments,
      hub,
      reassignTasks,
      toggleAuditOpen,
      toggleHideCompleteTasks,
      newCommentRef,
      workflowId,
      reminders,
    } = this.props;

    const clientWorkflow = clientWorkflows[workflowId];
    if (!clientWorkflow) {
      // Should not hit this state.  Will render no Workflows (rather than let the section disappear)
      console.warn("Could not find Client Workflow with ID ", workflowId);
      return this.renderNoWorkflows();
    }
    const completedTasks = clientWorkflow.tasks.reduce((accumulator, x) => {
      return x.completed ? accumulator + 1 : accumulator;
    }, 0);

    const tasks = clientWorkflow.tasks || [];

    const reminderInfo = getNextReminderInfo(client, clientWorkflow, reminders);
    const nextWorkflows = clientWorkflow.nextWorkflows || [];
    const previousWorkflow = clientWorkflow.previousWorkflow
      ? clientWorkflows[clientWorkflow.previousWorkflow]
      : null;

    return (
      <div>
        <div
          className="client-card-workflow"
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "1em",
            width: "100%",
          }}
        >
          <div
            data-test="client-card-workflow-header"
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{ display: "flex", alignItems: "center", width: "100%" }}
            >
              <Icon name="tasks" size="large" style={{ fontSize: "20pt" }} />
              <FeatureFlag id="reassign-tasks">
                <MultiTasksAssigners
                  style={{ margin: "8px" }}
                  tasks={clientWorkflow.tasks}
                  onAssign={reassignTasks(clientWorkflow)}
                  badge={(numOfIncomplete, numOfComplete) => {
                    return `${numOfComplete}/${
                      numOfIncomplete + numOfComplete
                    }`;
                  }}
                />
              </FeatureFlag>
              <FeatureFlag id="reassign-tasks" showOnDisabled>
                {hub.advisors.length >= 2 && (
                  <AssignedAdvisorPopup
                    currentObject={clientWorkflow}
                    currentObjectType="CLIENT_WORKFLOW"
                    iconProperties={{
                      addIconSize: "32px",
                      circleFontSize: "16px",
                      circleSize: "32px",
                    }}
                  />
                )}
              </FeatureFlag>
              {renamingClientWorkflow && (
                <EditableField
                  name="client-workflow-name"
                  initValue={clientWorkflow.name}
                  onConfirmValue={(value) => {
                    this.renameClientWorkflow(value);
                  }}
                  onCancel={() => {
                    this.setState({ renamingClientWorkflow: false });
                  }}
                />
              )}
              {!renamingClientWorkflow && (
                <div>
                  <DropdownClientWorkflows
                    workflows={client.workflows}
                    clientWorkflowId={workflowId}
                    handleChange={this.handleChange}
                  />
                  <Popup
                    className="hubly_bars_menu"
                    hideOnScroll
                    open={popupOpen}
                    on="click"
                    onClose={this.togglePopup}
                    onOpen={this.togglePopup}
                    popperModifiers={{
                      preventOverflow: {
                        boundariesElement: "window",
                        enabled: false,
                      },
                    }}
                    position="right center"
                    data-test="card-workflows-optionsMenu"
                    trigger={
                      <Icon
                        link
                        name="ellipsis horizontal"
                        color="grey"
                        style={{ transform: "translateY(-2px)" }}
                        data-test="card-workflows-optionsMenu-trigger"
                      />
                    }
                  >
                    <Button.Group basic vertical labeled icon>
                      {completedTasks < tasks.length && (
                        <Button
                          icon="check square outline"
                          content="Mark all tasks complete"
                          className="client_card_component_sub_heading"
                          onClick={(event) => {
                            event.stopPropagation(); // Stop trigger open/close of active workflow
                            this.completeAllConfirm(clientWorkflow);
                          }}
                        />
                      )}
                      {completedTasks > 0 && (
                        <Button
                          icon={
                            !clientWorkflow.hideCompletedTasks
                              ? "hide"
                              : "unhide"
                          }
                          content={
                            !clientWorkflow.hideCompletedTasks
                              ? "Hide completed items"
                              : "Show completed items"
                          }
                          style={{ borderTop: "none " }}
                          onClick={(event) => {
                            event.stopPropagation(); // Stop trigger open/close of active workflow
                            toggleHideCompleteTasks(
                              client.id,
                              clientWorkflow.id
                            );
                            this.togglePopup();
                          }}
                          className="client_card_component_sub_heading"
                        />
                      )}
                      {!renamingClientWorkflow && (
                        <Button
                          icon="edit"
                          content="Rename"
                          onClick={(event) => {
                            event.stopPropagation();
                            this.setState({
                              renamingClientWorkflow: true,
                              popupOpen: false,
                            });
                          }}
                          className="client_card_component_sub_heading"
                        />
                      )}
                      {!clientWorkflow?.archived && (
                        <Button
                          icon="archive"
                          content="Archive"
                          disabled={loading}
                          loading={loading}
                          onClick={(event) => {
                            event.stopPropagation();
                            this.archiveClientWorkflow(clientWorkflow);
                          }}
                          className="client_card_component_sub_heading"
                        />
                      )}
                      <Access required={["Administrator"]}>
                        <Button
                          icon="trash alternate outline"
                          content="Delete"
                          disabled={loading}
                          loading={loading}
                          onClick={(event) => {
                            event.stopPropagation(); // Stop trigger open/close of active workflow
                            this.deleteClientWorkflow(clientWorkflow);
                          }}
                          className="client_card_component_sub_heading red_background"
                        />
                      </Access>
                    </Button.Group>
                  </Popup>
                </div>
              )}
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div className="grey_on_hover_circle">
                <Icon.Group
                  link
                  onClick={toggleAuditOpen}
                  title="Client Report"
                  style={{ transform: "translateX(8px)" }}
                >
                  <Icon fitted link name="file outline" />
                  <Icon corner fitted link name="user" />
                </Icon.Group>
              </div>
              {!loading && (
                <div>
                  <Icon
                    className="grey_on_hover_circle"
                    link
                    name="plus"
                    onClick={() => {
                      this.setState({ adding: true, dropdownValue: "" });
                    }}
                    title="Assign to Workflow"
                  />
                </div>
              )}
            </div>
          </div>
          <div style={{ marginLeft: "3em" }}>
            {previousWorkflow && (
              <div
                style={{
                  maxWidth: "100%",
                  marginBottom: "1em",
                  marginLeft: "3.25em",
                }}
                onClick={() => {
                  history.push(
                    `/hub/${hub.hubId}/clients/${client.id}/workflows/${previousWorkflow.id}`
                  );
                }}
                data-test="card-workflows-previousWorkflows"
              >
                <div style={{ color: "grey", fontSize: "9pt" }}>
                  Previous Workflow
                </div>
                <div
                  style={{
                    fontWeight: "bold",
                    color: "#828282",
                    padding: "0.25em 0.5em",
                    display: "inline-flex",
                    alignItems: "center",
                    fontSize: "11pt",
                    maxWidth: "100%",
                  }}
                  className="grey_BG dark_grey_bg_on_hover clickable rounded"
                >
                  {previousWorkflow.assignedAdvisor && (
                    <div style={{ marginRight: "0.5em" }}>
                      <InitialsCircle
                        first={previousWorkflow.assignedAdvisor.firstName}
                        last={previousWorkflow.assignedAdvisor.lastName}
                      />
                    </div>
                  )}
                  <div className="overflow_ellipsis">
                    {generateCWFContent(clientWorkflows[previousWorkflow?.id])}
                  </div>
                </div>
              </div>
            )}
            <div
              style={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                marginTop: "0.5em",
              }}
            >
              <div
                style={{
                  paddingRight: "1em",
                  color: "grey",
                  fontWeight: "bold",
                }}
              >
                {completedTasks}/{clientWorkflow.tasks.length}
              </div>
              <div style={{ flexGrow: "3" }}>
                <Progress
                  color={reminderInfo.statusBar}
                  size="medium"
                  value={completedTasks}
                  total={clientWorkflow.tasks.length}
                  style={{
                    margin: "0px",
                    marginRight: "30px",
                  }}
                  data-test="card-workflows-progress"
                />
              </div>
            </div>
            <DragDropContext onDragEnd={this.onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => {
                  return (
                    <div
                      role="list"
                      className="ui middle aligned list tasks_list"
                      ref={provided.innerRef}
                      style={{
                        ...getCardWorkflowsStyle(snapshot.isDraggingOver),
                        maxWidth: "100%",
                      }}
                      {...provided.droppableProps}
                    >
                      {tasks.length === 0 && (
                        <span style={{ color: "grey" }}>
                          This workflow contains no tasks
                        </span>
                      )}
                      <ScrollToTaskWrapper workflowId={workflowId}>
                        {tasks.map((task, taskIndex) => {
                          return {
                            taskId: task.id,
                            renderComponent: (
                              <Task
                                client={client}
                                clientWorkflow={clientWorkflow}
                                comments={comments}
                                key={`${client.id}_${task.id}`}
                                newCommentRef={newCommentRef}
                                task={task}
                                taskIndex={taskIndex}
                              />
                            ),
                          };
                        })}
                      </ScrollToTaskWrapper>
                      {provided.placeholder}
                    </div>
                  );
                }}
              </Droppable>
            </DragDropContext>
            {addingTask ? (
              <TaskAdd
                client={client}
                closeAddTask={() => {
                  this.setState({ addingTask: false });
                }}
                currentWorkflow={clientWorkflow}
                isClientWorkflow
              />
            ) : (
              <div style={{ display: "inline-block" }}>
                {nextWorkflows.length > 0 && clientWorkflow.completed ? (
                  <React.Fragment>
                    <Button
                      basic
                      loading={loading}
                      floated="right"
                      disabled={completionLoading || loading}
                      onClick={() => {
                        this.moveToNextWorkflows(clientWorkflow.id);
                      }}
                    >
                      Move to Next Workflows
                    </Button>
                  </React.Fragment>
                ) : null}
                <Button
                  basic
                  content="Add Task"
                  onClick={() => {
                    this.setState({
                      addingTask: true,
                    });
                  }}
                />
                {clientWorkflow.completed ? (
                  <Button
                    data-test="mark-incomplete-button"
                    basic
                    floated="left"
                    onClick={() => {
                      this.toggleWorkflow(false);
                    }}
                    loading={completionLoading}
                    disabled={completionLoading}
                  >
                    Mark Incomplete
                  </Button>
                ) : (
                  <Button
                    data-test="mark-completed-button"
                    basic
                    floated="left"
                    onClick={() => {
                      this.toggleWorkflow(true);
                    }}
                    loading={completionLoading}
                    disabled={completionLoading}
                  >
                    Mark Completed
                  </Button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  isHouseholdMember = () => {
    const { client, households, setConfirmationModal, workflows } = this.props;
    const { dropdownValue } = this.state;

    const workflow = { ...workflows[dropdownValue] };

    let head;
    let householdMember;

    if (client.householdId in households) {
      households[client.householdId].householdMembers.forEach((member) => {
        if (member.client.id === client.id && member.title !== "Head") {
          householdMember = member;
        }

        if (member.title === "Head") {
          head = member;
        }
      });

      if (!householdMember || !head) {
        this.addClientToWorkflow(client.id);
        return;
      }

      if (
        workflow.clients.find((_client) => {
          return _client.clientId === head.client.id;
        })
      ) {
        const params = {
          title: `${head.client.name} is the head of this household and already in this workflow`,
          message: `Are you sure that you want to add ${householdMember.client.name} as well?`,
          icon: "tasks",
          buttons: [
            { text: "Cancel" },
            {
              text: `Add ${householdMember.client.name}`,
              callBack: () => {
                this.addClientToWorkflow(householdMember.client.id);
              },
            },
          ],
        };
        setConfirmationModal(params);
      } else {
        const params = {
          title: `${head.client.name} is currently the head of this household`,
          message: `Are you sure that you want to add ${householdMember.client.name} instead?`,
          icon: "tasks",
          buttons: [
            { text: "Cancel" },
            {
              text: `Add ${householdMember.client.name}`,
              callBack: () => {
                this.addClientToWorkflow(householdMember.client.id);
              },
            },
            {
              text: `Add ${head.client.name}`,
              callBack: () => {
                this.addClientToWorkflow(head.client.id);
              },
              color: "green",
            },
          ],
        };
        setConfirmationModal(params);
      }
    } else {
      this.addClientToWorkflow(client.id);
    }
  };

  createNewWorkflow = () => {
    const { searchValue } = this.state;
    const { createNewWorkflow } = this.props;
    this.setState({ loading: true });

    const callback = (newWorkflow) => {
      this.setState({ loading: false, searchValue: "" });
      if (!newWorkflow) return;
      this.setState({ dropdownValue: newWorkflow.id }, () => {
        this.isHouseholdMember();
      });
    };
    createNewWorkflow(callback, searchValue);
  };

  renderAddWorkflow = () => {
    const { piiMask } = this.context;
    const { client, workflows, clientWorkflows } = this.props;
    const { loading, searchValue, dropdownOpen } = this.state;
    const value = searchValue?.toLocaleLowerCase() || "";
    const options = [];
    const clientCurrentWorkflows = new Set();
    client.workflows.forEach((wf) => {
      const workflow = clientWorkflows[wf];
      if (!workflow.completed && !workflow.archived)
        clientCurrentWorkflows.add(workflow.workflowId);
    });
    Object.values(workflows).forEach((workflow) => {
      if (
        (!clientCurrentWorkflows.has(workflow.id) ||
          workflow.options?.multipleTimePerClient) &&
        workflow.name.toLocaleLowerCase().includes(value)
      ) {
        options.push({
          value: workflow.id,
          key: workflow.id,
          text: workflow.name,
        });
      }
    });

    const create = options.length === 0 && searchValue?.length > 0;

    return (
      <div>
        <Header as="h3" style={{ marginBottom: "0.5em", display: "inline" }}>
          <Icon name="tasks" />
          Workflows
        </Header>
        <div style={{ marginLeft: "3em" }}>
          <span
            className={piiMask("fs-block dd-privacy-mask")}
            style={{ marginBottom: "0.5em", color: "grey", display: "block" }}
          >
            {`Add ${client.firstName} ${
              client.lastName ? client.lastName : ""
            } to workflow:`}
          </span>
          <Dropdown
            search
            closeOnChange
            selection
            loading={loading}
            disabled={loading && Object.values(workflows).length === 0}
            placeholder="Select a workflow"
            style={{ marginBottom: "0.5em", minWidth: "60%" }}
            onSearchChange={(e) => {
              this.setState({ searchValue: e.target.value || "" });
            }}
            searchQuery={searchValue}
            searchInput={{ autoFocus: true }}
            open={dropdownOpen}
            onClose={() => {
              this.setState({ dropdownOpen: false });
            }}
            onOpen={() => {
              this.setState({ dropdownOpen: true });
            }}
            data-test="card-workflows-addToWorkflow"
          >
            <Dropdown.Menu>
              {create && (
                <Dropdown.Item onClick={this.createNewWorkflow}>
                  <b>+ Create</b>
                  &nbsp;
                  {searchValue}
                </Dropdown.Item>
              )}
              {options.map((item) => {
                return (
                  <Dropdown.Item
                    onClick={() => {
                      this.setState({
                        dropdownOpen: false,
                        searchValue: item.text,
                        dropdownValue: item.value,
                      });
                    }}
                  >
                    {item.text}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
          <br />
          <Button
            loading={loading}
            positive
            content="Add"
            disabled={create || loading}
            onClick={this.isHouseholdMember}
          />
          <Icon
            name="delete"
            disabled={loading}
            size="large"
            color="grey"
            link
            onClick={() => {
              this.setState({ adding: false });
            }}
          />
        </div>
      </div>
    );
  };

  renderNoWorkflows = () => {
    const { client } = this.props;
    const { loading } = this.state;
    return (
      <div>
        <Header as="h3" style={{ marginBottom: "0.5em", display: "inline" }}>
          <Icon name="tasks" />
          Workflows
        </Header>
        {!loading && (
          <Icon
            className="grey_on_hover_circle"
            link
            name="plus"
            onClick={() => {
              this.setState({ adding: true, dropdownValue: "" });
            }}
            style={{ float: "right" }}
            title="Assign to Workflow"
          />
        )}
        <div style={{ marginLeft: "3em" }}>
          <span style={{ color: "grey" }}>
            {`${
              client.firstName || client.lastName || "The client"
            } has not been added to any workflows.`}
          </span>
        </div>
      </div>
    );
  };

  addClientToWorkflow = (clientId) => {
    const {
      setReminders,
      activeClients,
      hub,
      client,
      setActiveClient,
      setAlert,
      setClientWorkflow,
      setWorkflow,
      workflows,
      reminders,
    } = this.props;
    const { dropdownValue, loading } = this.state;

    if (!dropdownValue) {
      setAlert({
        type: "warning",
        text: `Unable to save next workflow. Please verify dropdown selection and try again.`,
      });
      return;
    }

    if (loading) {
      return;
    }
    this.setState({ loading: true });

    const workflow = { ...workflows[dropdownValue] };
    if (
      workflow.clients.find((id) => {
        return id === client.id;
      })
    ) {
      this.setState({ loading: false });
      return;
    }

    const nextWorkflows = Object.assign([], workflow.nextWorkflows);
    nextWorkflows.forEach((nextWorkflow) => {
      delete nextWorkflow.clientWorkflowId;
    });
    const request = {
      workflowId: workflow.id,
      clientId: clientId,
    };
    CreateClientWorkflow(request)
      .then((response) => {
        this.setState({
          searchValue: "",
          loading: false,
          adding: false,
          dropdownValue: "",
        });
        setClientWorkflow(response);
        const workflowCopy = { ...workflows[workflow.id] };
        workflowCopy.clients.unshift(response.id);
        setWorkflow(workflowCopy);
        // Add to active clients if they're not already in the array
        const foundClient = activeClients[clientId];
        if (!foundClient) {
          GetClient(response.clientId).then((clientResponse) => {
            setActiveClient(clientResponse);
            history.push(
              `/hub/${hub.hubId}/clients/${clientId}/workflows/${response.id}`
            );
          });
        } else {
          foundClient.workflows.push(response.id);
          setActiveClient(foundClient);
          history.push(
            `/hub/${hub.hubId}/clients/${clientId}/workflows/${response.id}`
          );
        }
        const remindersCopy = reminders.slice();
        (response.tasks || []).forEach((task) => {
          task.reminders.forEach((reminder) => {
            InsertReminder(reminder, remindersCopy);
          });
        });
        setReminders(remindersCopy);
      })
      .catch((error) => {
        setAlert({
          type: "error",
          text: error?.response?.data[0] || "Unable to add client to workflow",
        });
        this.setState({ loading: false });
      });
  };

  render() {
    const { client } = this.props;
    const { adding } = this.state;

    if (adding) return this.renderAddWorkflow();
    else if (client.workflows.length === 0) return this.renderNoWorkflows();
    return this.renderWorkflows();
  }
}

const mapStateToProps = () => {
  return (state, ownProps) => {
    return {
      allClientNames: state.hubly.data.hub.clients.allClientNames,
      activeClients: state.hubly.data.hub.clients.activeClients,
      clientWorkflows: state.hubly.data.hub.clientWorkflows,
      households: state.hubly.data.hub.households,
      hub: state.hubly.data.hub.selected.hub,
      workflows: state.hubly.data.hub.workflows,
      advisor: state.hubly.data.advisor,
    };
  };
};

const mapDispatchToProps = (dispatch, { resolveAllWorkflows }) => {
  return {
    addClientToWorkflow: (workflowId, clientWorkflowId) => {
      dispatch(AddClientToWorkflow(workflowId, clientWorkflowId));
    },
    setAttachments: (attachments) => {
      dispatch(SetAttachments(attachments));
    },
    setNameClientWorkflow: (data) => {
      dispatch(SetNameClientWorkflow(data));
    },
    toggleHideCompleteTasks: (clientId, clientWorkflowId) => {
      dispatch(ToggleHideCompleteTasks(clientId, clientWorkflowId));
    },
    removeClientWorkflow: (clientWorkflow) => {
      dispatch(RemoveClientWorkflow(clientWorkflow));
    },
    setConfirmationModal: (id) => {
      dispatch(SetConfirmationModal(id));
    },
    toggleClientWorkflowComplete: (
      clientId,
      workflowId,
      completed,
      callback
    ) => {
      dispatch(
        ToggleClientWorkflowComplete(clientId, workflowId, completed, callback)
      );
    },
    setActiveClients: (clients) => {
      dispatch(SetActiveClients(clients));
    },
    setActiveClient: (client) => {
      dispatch(SetActiveClient(client));
    },
    setClientWorkflow: (clientWorkflow) => {
      dispatch(SetClientWorkflow(clientWorkflow));
    },
    setClientWorkflows: (clientWorkflows) => {
      dispatch(SetClientWorkflows(clientWorkflows));
    },
    setAlert: (alert) => {
      dispatch(SetAlert(alert));
    },
    setReminders: (reminders) => {
      dispatch(SetReminders(reminders));
    },
    setWorkflow: (workflow) => {
      dispatch(SetWorkflow(workflow));
    },
    toggleAuditOpen: () => {
      dispatch(ToggleAuditOpen());
    },
    updateClient: (client) => {
      dispatch(SetActiveClient(client));
    },
    completeAllTasks: (clientWorkflow, callback) => {
      dispatch(SetAllTasksComplete(clientWorkflow, callback));
    },
    createNewWorkflow: (callback, name) => {
      dispatch(CreateNewWorkflow(callback, name));
    },
    archiveClientWorkflow: (clientWorkflow, callback) => {
      dispatch(ArchiveClientWorkflow(clientWorkflow, callback));
    },
    reassignTasks: (clientWorkflow) => {
      return ({ currentAssigned, newAssigned }) => {
        ReassignWorkflowsTasks(clientWorkflow.id, {
          fromAssignee: currentAssigned?.id || "unassigned",
          toAssignee: newAssigned?.id || "unassigned",
        }).then((response) => {
          dispatch(SetClientWorkflow(response));
        });
      };
    },
    fetchWorkflows: (hub) => {
      return resolveAllWorkflows(hub).then(([workflows, shouldDispatch]) => {
        if (shouldDispatch) dispatch(SetWorkflows(workflows));
      });
    },
  };
};

export default withWorkflowResolver(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(CardWorkflows))
);
