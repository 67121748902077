import moment from "moment";

export const useReminderStatus = (reminderDate) => {
  const date = moment(reminderDate);
  const duration = moment.duration(date.diff());

  if (new Date() > new Date(date)) {
    return { statusText: "Overdue Reminder", statusBar: "red" };
  }
  if (duration.days() === 0 && duration.hours() <= 12 && duration > 0) {
    return { statusText: "Upcoming Reminder", statusBar: "yellow" };
  }

  return { statusText: "In Progress", statusBar: "green" };
};

export default useReminderStatus;
