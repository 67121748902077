import React from "react";
import PropTypes from "prop-types";

import { Header } from "semantic-ui-react";

export function LayoutHeader({ children }) {
  return (
    <div>
      <Header style={{ display: "inline" }}>{children}</Header>
    </div>
  );
}

LayoutHeader.propTypes = { children: PropTypes.node };
LayoutHeader.defaultProps = { children: null };

export default LayoutHeader;
