import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Button, Header, Icon, Input, Message, Modal } from "semantic-ui-react";

import { UpdateAdvisor } from "data/advisor/actions";
import { SetFilters } from "data/filters/actions";
import { CreateFilter } from "data/libs/filters";
import { CreateView, EditView } from "data/libs/views";
import { SetCurrentView, SetViews } from "data/views/actions";

import { SetAlert } from "components/Alerts/actions";

import ColorPicker from "../ColorPicker";
import { SetViewModalOpen } from "./actions";

class ViewModal extends React.Component {
  static propTypes = {
    advisor: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
    hub: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
    currentView: PropTypes.shape({
      id: PropTypes.string.isRequired,
      description: PropTypes.string,
      name: PropTypes.string.isRequired,
    }).isRequired,
    editView: PropTypes.shape({
      id: PropTypes.string.isRequired,
      description: PropTypes.string,
      name: PropTypes.string.isRequired,
    }).isRequired,
    filters: PropTypes.shape({
      isFilterUpdated: PropTypes.bool.isRequired,
    }).isRequired,
    setAlert: PropTypes.func.isRequired,
    setCurrentView: PropTypes.func.isRequired,
    setFilters: PropTypes.func.isRequired,
    setViewModalOpen: PropTypes.func.isRequired,
    setViews: PropTypes.func.isRequired,
    updateAdvisor: PropTypes.func.isRequired,
    views: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);
    const { editView = { id: "", description: "", name: "", color: null } } =
      props;
    this.state = {
      description: editView.description,
      loading: false,
      name: editView.name,
      validName: true,
      color: editView.color,
    };
  }

  createView = () => {
    const { description, name, color } = this.state;
    const {
      advisor,
      hub,
      filters,
      setAlert,
      setCurrentView,
      setFilters,
      setViewModalOpen,
      setViews,
      updateAdvisor,
      views,
    } = this.props;
    if (!name) {
      setAlert({ type: "warning", text: "View name is required." });
      return;
    }
    const listOfNames = (views || []).map((view) => {
      return view.name;
    });
    if (listOfNames.includes(name)) {
      setAlert({ type: "warning", text: "View name already exists." });
      return;
    }
    CreateFilter(filters)
      .then((response) => {
        const request = {
          hubId: hub.id,
          description: description,
          name: name,
          filterId: response.id,
          color: color,
        };
        CreateView(request)
          .then((viewResponse) => {
            const viewsCopy = [...views];
            viewsCopy.push(viewResponse);
            setViews(viewsCopy);
            updateAdvisor(advisor.id, { defaultView: viewResponse.id });
            setCurrentView(viewResponse);
            setFilters(response, false);
            setAlert({ type: "success", text: "Successfully created view." });
            setViewModalOpen(false);
          })
          .catch((error) => {
            console.error(error);
            setAlert({ type: "error", text: "Failed to create view." });
          });
      })
      .catch((error) => {
        console.error(error);
        setAlert({ type: "error", text: "Failed to create view." });
      });
  };

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };

  updateView = () => {
    const { description, name, color } = this.state;
    const {
      currentView,
      editView,
      setAlert,
      setCurrentView,
      setViewModalOpen,
      setViews,
      views,
    } = this.props;
    if (!name) {
      setAlert({
        type: "warning",
        text: "Input field cannot be empty. Please verify view name and try again.",
      });
      return;
    }
    const listOfNames = (views || []).map((view) => {
      return view.name;
    });
    if (listOfNames.includes(name) && name !== editView.name) {
      setAlert({
        type: "warning",
        text: "View name already exists. Please verify input and try again.",
      });
      return;
    }
    const request = {
      description: description,
      name: name,
      color: color,
    };
    EditView(editView.id, request)
      .then((response) => {
        const viewsCopy = [...views];
        const foundView = viewsCopy.find((view) => {
          return view.id === editView.id;
        });
        if (!foundView) {
          console.warn(`Could not find view with ID: ${editView.id}`);
          return;
        }
        // Update current/toggled view if that is what we are editing, otherwise can leave it
        if (currentView.id === editView.id) {
          setCurrentView(response);
        }
        foundView.description = response.description;
        foundView.name = response.name;
        foundView.color = response.color;
        setViews(viewsCopy);
        setAlert({ type: "success", text: "Successfully edited view." });
        setViewModalOpen(false);
      })
      .catch((error) => {
        console.error(error);
        setAlert({ type: "error", text: "Failed to edit view." });
      });
  };

  render() {
    const { description, loading, name, validName, color } = this.state;
    const { editView, setViewModalOpen } = this.props;
    return (
      <Modal
        data-test="create-view-modal"
        open
        onClose={() => {
          setViewModalOpen(false);
        }}
        size="small"
      >
        <Modal.Header>
          {editView.id ? "Edit View" : "Create View"}
          <Icon
            link
            color="grey"
            name="delete"
            style={{ float: "right" }}
            onClick={() => {
              setViewModalOpen(false);
            }}
          />
        </Modal.Header>
        <Modal.Content>
          <div style={{ display: "flex", width: "100%" }}>
            <div style={{ width: "95%" }}>
              <Header as="h5">Name</Header>
              <Input
                fluid
                autoComplete="off"
                name="name"
                value={name}
                placeholder="View Name"
                onChange={this.handleChange}
              />
              <Message
                error
                header="Missing View Name"
                hidden={validName}
                list={["View Name cannot be blank."]}
                negative
              />
            </div>
            <div style={{ marginLeft: "1em" }}>
              <Header as="h5">Color</Header>
              <ColorPicker
                allowNone
                color={color}
                setColor={(c) => {
                  this.setState({ color: c });
                }}
              />
            </div>
          </div>
          <div style={{ marginTop: "1em" }}>
            <Header as="h5">Description</Header>
            <Input
              autoComplete="off"
              fluid
              name="description"
              value={description}
              placeholder="View Description"
              onChange={this.handleChange}
            />
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button
            positive
            content="Save"
            loading={loading}
            disabled={loading || name.trim().length === 0}
            onClick={
              editView.id
                ? () => {
                    this.updateView();
                  }
                : () => {
                    this.createView();
                  }
            }
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    advisor: state.hubly.data.advisor,
    hub: state.hubly.data.hub.selected.hub,
    currentView: state.hubly.data.views.currentView,
    editView: state.hubly.viewModal.editView,
    filters: state.hubly.data.filters,
    views: state.hubly.data.views.views,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAlert: (alert) => {
      dispatch(SetAlert(alert));
    },
    setCurrentView: (currentView) => {
      dispatch(SetCurrentView(currentView));
    },
    setFilters: (filters, isFilterUpdated) => {
      dispatch(SetFilters(filters, isFilterUpdated));
    },
    setViewModalOpen: (open) => {
      dispatch(SetViewModalOpen(open));
    },
    setViews: (views) => {
      dispatch(SetViews(views));
    },
    updateAdvisor: (id, request, callback) => {
      dispatch(UpdateAdvisor(id, request, callback));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewModal);
