import React from "react";
import PropTypes from "prop-types";

import { TablePagination } from "@material-ui/core";

function PaginationControl({
  isLoading,
  pageSizeOptions,
  totalCount,
  pageSize,
  page,
  onPageChange,
  onPageSizeChange,
  ...props
}) {
  return isLoading ? null : (
    <TablePagination
      data-test="table-pagination-control"
      rowsPerPageOptions={pageSizeOptions}
      component="div"
      count={totalCount}
      rowsPerPage={pageSize}
      page={page}
      onPageChange={(_, newPage) => {
        onPageChange({ page: newPage });
      }}
      onRowsPerPageChange={(event) => {
        onPageSizeChange({
          page: 0,
          pageSize: parseInt(event.target.value, 10),
        });
      }}
      {...props}
    />
  );
}

PaginationControl.propTypes = {
  isLoading: PropTypes.bool,
  totalCount: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  pageSizeOptions: PropTypes.array.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onPageSizeChange: PropTypes.func.isRequired,
};

PaginationControl.defaultProps = {
  isLoading: false,
};

export default PaginationControl;
