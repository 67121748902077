import React from "react";
import PropTypes from "prop-types";

import { set, swap, unset } from "utils";

const ClientWorkflowStore = React.createContext();

export function useClientWorkflowReducer() {
  const initialState = {};

  const reducer = (state, { type, value }) => {
    switch (type) {
      case "set-cw":
        return set(state, value.workflowId, value.data);
      case "unset-cw":
        return unset(state, value.workflowId);
      case "add-cw":
        return set(
          state,
          value.workflowId,
          (state[value.workflowId] || []).concat(value.data)
        );
      case "swap-cw":
        return set(
          state,
          value.wfId,
          swap(state[value.wfId], value.src, value.des)
        );
      default:
        return initialState;
    }
  };

  return React.useReducer(reducer, initialState);
}

export function useClientWorkflowFetch() {
  const initialState = {};

  const reducer = (state, { type, value }) => {
    switch (type) {
      case "set":
        return set(state, value.workflowId, true);
      case "unset":
        return set(state, value.workflowId, false);
      default:
        return initialState;
    }
  };

  return React.useReducer(reducer, initialState);
}

export function Provider({ children, value }) {
  return (
    <ClientWorkflowStore.Provider value={value}>
      {children}
    </ClientWorkflowStore.Provider>
  );
}

Provider.propTypes = {
  value: PropTypes.array.isRequired,
  children: PropTypes.node,
};

Provider.defaultProps = {
  children: null,
};

export function useClientWorkflows(workflowId) {
  const [[allCws, dispatch], [allCwsFetch, fetchDispatch]] =
    React.useContext(ClientWorkflowStore);

  const refetch = React.useCallback(
    (shouldFetch = true) => {
      fetchDispatch({
        type: shouldFetch ? "set" : "unset",
        value: { workflowId },
      });
    },
    [workflowId, fetchDispatch]
  );

  return [
    allCws[workflowId] || [],
    dispatch,
    allCwsFetch[workflowId] || false,
    refetch,
  ];
}

export default Provider;
