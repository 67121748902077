import React, { useState } from "react";
import PropTypes from "prop-types";

import { Button, Input, Item } from "semantic-ui-react";

import { useDelay } from "hooks";

export function InputControl({ placeholder, loading, onChange, onAdd }) {
  const [input, setInput] = useState("");
  const delayCallback = useDelay(300);
  const triggerOnChange = (text) => {
    setInput(text);
    delayCallback(() => {
      onChange(text);
    });
  };

  const onKeyUp = (e) => {
    // handles key press for adding item
    if (e.keyCode === 13) {
      onAdd(input);
      if (input !== "") triggerOnChange("");
    }
  };

  return (
    <Item key="add-input" style={{ padding: "1em 1em" }}>
      <div style={{ display: "flex" }}>
        <Input
          autoFocus
          autoComplete="off"
          onChange={(e) => {
            triggerOnChange(e.target.value);
          }}
          onKeyUp={onKeyUp}
          placeholder={placeholder}
          value={input}
        />
        <div>
          <Button
            disabled={loading}
            loading={loading}
            icon="plus"
            style={{ marginLeft: "0.25em", marginRight: 0 }}
            onClick={() => {
              onAdd(input);
              if (input !== "") triggerOnChange("");
            }}
          />
        </div>
      </div>
    </Item>
  );
}

InputControl.defaultProps = {
  loading: false,
  placeholder: "",
};

InputControl.propTypes = {
  placeholder: PropTypes.string,
  loading: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
};

export default InputControl;
