import { useCallback } from "react";

import { useQuery } from "hooks";

export default function usePolicies() {
  const getAdvisorAcceptedPolicies = useCallback(() => {
    const query = `query getAdvisorPolicies {
        me {
          id
          advisorpolicySet {
            edges {
              node {
                id
                acceptedTime
                policy {
                  name
                  text
                }
              }
            }
          }                   
        }
      }
      `;

    const resolver = ({
      data: {
        me: {
          advisorpolicySet: { edges },
        },
      },
    }) => {
      return edges.map(({ node: { id, acceptedTime, policy } }) => {
        return { id, acceptedTime, policy };
      });
    };

    return { query, resolver };
  }, []);

  return useQuery(getAdvisorAcceptedPolicies, {
    options: { fetchOnInit: true },
  });
}
