export const getConfirmModalButtons = ({ workflow, cbFunc }) => {
  const clients = workflow?.clients.length || 0;
  if (!clients) {
    return {
      title: "Delete Workflow",
      message: `Are you sure you wish to delete "${workflow.name}"? This cannot be undone.`,
      icon: "delete",
      buttons: [
        { text: "Cancel" },
        {
          text: "Delete Workflow",
          callBack: () => {
            return cbFunc(false);
          },
          color: "red",
        },
      ],
    };
  }

  const clntStr = clients > 1 ? "clients that are" : "client that is";
  return {
    title: "Delete Workflow",
    message: `What would you like to do with the ${clients} ${clntStr} currently in the "${workflow.name}"? This cannot be undone.`,
    icon: "delete",
    buttons: [
      { text: "Cancel" },
      {
        text: "Archive",
        callBack: () => {
          return cbFunc(false);
        },
      },
      {
        text: "Delete From Workflow",
        callBack: () => {
          return cbFunc(true);
        },
        color: "red",
      },
    ],
  };
};

export default getConfirmModalButtons;
