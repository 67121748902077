import useCurrentUser from "data/advisor/useCurrentUser";

export function useAccess() {
  const { role } = useCurrentUser();

  return (required) => {
    return [role.title].some((userRole) => {
      return required.includes(userRole);
    });
  };
}

export default useAccess;
