import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Icon } from "semantic-ui-react";

import { RemoveClientTask } from "data/hub/clientWorkflows/actions";
import { RemoveWorkflowTask } from "data/hub/workflows/actions";

import { SetConfirmationModal } from "components/ConfirmationModal/actions";
import PopupMenu from "components/PopupMenu";
import {
  SetCommentTaskId,
  ToggleTaskConditionPopup,
  ToggleTaskDetailsModal,
} from "components/Task/actions";

class TaskPopupMenu extends React.Component {
  static defaultProps = {
    client: {
      id: "",
      comments: [],
      householdId: "",
      reminders: [],
    },
    currentWorkflow: {
      id: "",
    },
    newCommentRef: {},
  };

  static propTypes = {
    currentWorkflow: PropTypes.shape({
      id: PropTypes.string,
    }),
    client: PropTypes.shape({
      id: PropTypes.string,
      comments: PropTypes.array,
      householdId: PropTypes.string,
      reminders: PropTypes.array,
    }),
    hub: PropTypes.shape({
      advisors: PropTypes.array.isRequired,
      id: PropTypes.string.isRequired,
    }).isRequired,
    isClientWorkflow: PropTypes.bool.isRequired,
    newCommentRef: PropTypes.object,
    removeClientTask: PropTypes.func.isRequired,
    removeWorkflowTask: PropTypes.func.isRequired,
    setCommentTaskId: PropTypes.func.isRequired,
    setConfirmationModal: PropTypes.func.isRequired,
    task: PropTypes.object.isRequired,
    toggleTaskDetailsModal: PropTypes.func.isRequired,
    integrations: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
    };
  }

  taskHasComment = () => {
    const { task } = this.props;
    return !task.completed && task.comments?.length > 0;
  };

  toggleCommentTask = () => {
    const { currentWorkflow, setCommentTaskId, task, newCommentRef } =
      this.props;
    setCommentTaskId(task.id, currentWorkflow.id);

    // scroll to the comment area, timeout is required to ensure that the new comment area has been rendered before you scroll to it
    setTimeout(() => {
      if (
        newCommentRef &&
        newCommentRef.current &&
        newCommentRef.current.children.length > 0
      ) {
        newCommentRef.current.children[0].scrollIntoView({
          behavior: "smooth",
        });
      }
    }, 200);
  };

  deleteTask = () => {
    const {
      currentWorkflow,
      isClientWorkflow,
      removeClientTask,
      removeWorkflowTask,
      task,
      setConfirmationModal,
    } = this.props;
    const remove = () => {
      if (isClientWorkflow) {
        removeClientTask(task.id, currentWorkflow.id);
      } else {
        removeWorkflowTask(task.id, currentWorkflow.id);
      }
    };
    const params = {
      title: "Are you sure?",
      message: `Are you sure you want to delete ${task.title} and it's configurations? This cannot be undone.`,
      icon: "delete",
      buttons: [
        {
          text: "Cancel",
        },
        {
          text: "Delete Task",
          callBack: remove,
          color: "red",
        },
      ],
    };
    setConfirmationModal(params);
    this.setState({ menuOpen: false });
  };

  render() {
    const {
      isClientWorkflow,
      task,
      toggleTaskDetailsModal,
      integrations,
      hub,
    } = this.props;
    const { menuOpen } = this.state;

    const taskHasComment = this.taskHasComment();
    const isRedtailIntegrated =
      integrations.find((integration) => {
        return integration.hubId === hub.id && integration.type === "redtail";
      }) !== undefined;

    return (
      <PopupMenu
        buttons={[
          ...(isClientWorkflow && !isRedtailIntegrated
            ? [
                {
                  content: "Add Comment",
                  icon: taskHasComment ? "comment" : "comment outline",
                  onClick: () => {
                    this.toggleCommentTask();
                    this.setState({ menuOpen: false });
                  },
                },
              ]
            : []),
          {
            content: "Edit Task Details",
            icon: "align left",
            onClick: () => {
              toggleTaskDetailsModal(task.id, true);
              this.setState({ menuOpen: false });
            },
          },
          {
            content: "Delete Task",
            icon: "delete",
            onClick: () => {
              this.deleteTask();
            },
          },
        ]}
        onOpen={() => {
          this.setState({ menuOpen: true });
        }}
        onClose={() => {
          this.setState({ menuOpen: false });
        }}
        open={menuOpen}
        popperModifiers={{
          preventOverflow: { boundariesElement: "window", enabled: false },
        }}
        data-test={`task-popup-menu-${task.title}`}
        trigger={
          <Icon
            data-test={`task-popup-menu-trigger-${task.title}`}
            className="show_on_hover dark_grey_on_hover"
            name="ellipsis horizontal"
            link
            color="grey"
          />
        }
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    hub: state.hubly.data.hub.selected.hub,
    integrations: state.hubly.data.advisor.integrations,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    removeClientTask: (taskId, clientWorkflowId) => {
      dispatch(RemoveClientTask(taskId, clientWorkflowId));
    },
    removeWorkflowTask: (taskId, workflowId) => {
      dispatch(RemoveWorkflowTask(taskId, workflowId));
    },
    setCommentTaskId: (taskId, clientWorkflowId) => {
      dispatch(SetCommentTaskId(taskId, clientWorkflowId));
    },
    setConfirmationModal: (params) => {
      dispatch(SetConfirmationModal(params));
    },
    toggleTaskConditionPopup: (taskId, isOpen) => {
      dispatch(ToggleTaskConditionPopup(taskId, isOpen));
    },
    toggleTaskDetailsModal: (taskId, isOpen) => {
      dispatch(ToggleTaskDetailsModal(taskId, isOpen));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskPopupMenu);
