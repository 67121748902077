import React from "react";
import PropTypes from "prop-types";

/* Children of TreeList are expected to be divs */

class TreeList extends React.Component {
  static defaultProps = {
    children: [],
    style: {},
  };

  static propTypes = {
    children: PropTypes.array,
    style: PropTypes.object,
  };

  border = "2px solid #e0e0e0";

  radius = "15px";

  shift = `translate(-4px, calc(${this.radius} / -2 + 2px))`;

  curveBorder = {
    borderLeft: this.border,
    borderBottom: this.border,
    borderBottomLeftRadius: this.radius,
    height: this.radius,
    width: this.radius,
    alignSelf: "center",
    transform: this.shift,
  };

  downBorder = {
    borderLeft: this.border,
    transform: "translate(-2px)",
  };

  down = {
    width: this.radius,
    display: "flex",
  };

  renderListItem = (item, index = 0, n = 0) => {
    const { curveBorder, down, downBorder } = this;
    return (
      <div style={{ display: "flex" }}>
        <div style={down}>
          <div
            style={{
              ...downBorder,
              maxHeight:
                index === n ? `calc(50% - ${this.radius} / 2 - 3px)` : "105%",
            }}
          />
          <div style={curveBorder} />
        </div>
        {item}
      </div>
    );
  };

  render() {
    const { children, style } = this.props;
    if (!children) return null;

    // it is important to flatten (just one level in this case) nested lists since we are iterating over the children
    // children = [ [...], [...], {...}, {...} ]  ==flatten==>  [ {...}, {...}, {...}, .....]
    // also need to handle the case that children is just a single non list child
    let items = []; // the flattened array
    if (!Array.isArray(children)) items.push(children);
    // children is not a list
    else {
      // children is a list
      children.forEach((child) => {
        if (!Array.isArray(child)) items.push(child);
        else items = [...items, ...child];
      });
    }

    return (
      <div style={{ display: "flex", flexDirection: "column", ...style }}>
        {items.map((child, index) => {
          return this.renderListItem(child, index, items.length - 1);
        })}
      </div>
    );
  }
}

export default TreeList;
