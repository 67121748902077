import React from "react";
import PropTypes from "prop-types";
import { find } from "lodash";

import FilterLayout from "components/FilterLayout";
import withPrivacyMode from "components/PrivacyMode/WithPrivacyMode";
import Selector from "components/Selector";

import useClients from "./hooks/useClients";

export function ClientFilters({ onUpdateFilter, className }) {
  const { page, setSearchText, options, selected } = useClients();

  return (
    <FilterLayout
      title="Clients"
      onClearFilter={() => {
        onUpdateFilter({ clients: [] });
      }}
    >
      <Selector
        name="Clients"
        dropdownProps={{
          placeholder: "Select client",
          noResultsMessage: page.loading ? "" : "No more clients",
          loading: page.loading,
          className: `fs-block dd-privacy-mask  ${className}`,
          onSearchChange: (e) => {
            setSearchText(e.target.value);
          },
        }}
        selection={options}
        selected={selected}
        showLoadMore={page.hasMorePages}
        loadMoreData={page.readNext}
        onOpen={page.refetch}
        onSelect={(values) => {
          const toClient = (v) => {
            const { key, value } = find(options, { key: v });
            return {
              id: key,
              name: value,
            };
          };
          setSearchText("");
          onUpdateFilter({ clients: values.map(toClient) });
        }}
      />
    </FilterLayout>
  );
}

ClientFilters.propTypes = {
  className: PropTypes.string,
  onUpdateFilter: PropTypes.func.isRequired,
};

ClientFilters.defaultProps = {
  className: "",
};

export default withPrivacyMode(ClientFilters);
