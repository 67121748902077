import React from "react";
import ReactQuill from "react-quill";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import config from "config";

import { renderToStaticMarkup } from "@hot-loader/react-dom/server";
import parse from "html-react-parser";
import Moment from "moment";
import sanitizeHtml from "sanitize-html";
import {
  Button,
  Card,
  Checkbox,
  Container,
  Dimmer,
  Header,
  Icon,
  Popup,
  Segment,
} from "semantic-ui-react";

import { DeleteComment, EditComment, HideComment } from "data/libs/comments";

import { SetAlert } from "components/Alerts/actions";
import { SetConfirmationModal } from "components/ConfirmationModal/actions";
import Expandable from "components/Expandable";
import { Loading } from "components/Loading";
import { PrivacyModeContext } from "components/PrivacyMode/Context";
import {
  RemoveCommentTaskId,
  SetCommentTaskId,
  SetTaskComplete,
  SetTaskIncomplete,
} from "components/Task/actions";

class CardComment extends React.Component {
  static contextType = PrivacyModeContext;

  static defaultProps = {
    task: {},
    clientWorkflow: {},
    onEditClientComment: () => {},
    onDeleteClientComment: () => {},
  };

  static propTypes = {
    advisor: PropTypes.shape({
      id: PropTypes.string.isRequired,
      integrations: PropTypes.array.isRequired,
    }).isRequired,
    hub: PropTypes.object.isRequired,
    externalId: PropTypes.string.isRequired,
    comment: PropTypes.object.isRequired,
    onEditClientComment: PropTypes.func,
    onDeleteClientComment: PropTypes.func,
    clientWorkflow: PropTypes.shape({
      id: PropTypes.string,
    }),
    removeCommentTaskId: PropTypes.func.isRequired,
    setConfirmationModal: PropTypes.func.isRequired,
    setTaskComplete: PropTypes.func.isRequired,
    setTaskIncomplete: PropTypes.func.isRequired,
    task: PropTypes.shape({
      id: PropTypes.string,
      completed: PropTypes.bool,
      title: PropTypes.string,
      completedAt: PropTypes.string,
    }),
    setAlert: PropTypes.func.isRequired,
  };

  state = {
    body: "",
    editing: false,
    loading: false,
    menuOpen: false,
  };

  modules = {
    clipboard: {
      matchVisual: false,
    },
  };

  toggleCompletion = () => {
    const { task, clientWorkflow, setTaskComplete, setTaskIncomplete } =
      this.props;
    if (!task.completed) {
      setTaskComplete(clientWorkflow, task.id);
    } else {
      setTaskIncomplete(clientWorkflow, task.id);
    }
  };

  toggleTask = () => {
    const { task, setConfirmationModal } = this.props;

    if (!task.completed) {
      this.toggleCompletion();
      return;
    }

    const markTaskIncomplete = () => {
      this.toggleCompletion();
    };

    const params = {
      title: "Uncheck Task",
      message: `This task was marked completed on
        ${Moment(task.completedAt).format("MMM Do, YYYY [at] h:mm a")}.
        Are you sure you want to remove this timestamp?`,
      icon: "square outline",
      buttons: [
        {
          text: "Cancel",
        },
        {
          text: "Confirm",
          callBack: markTaskIncomplete,
          color: "red",
        },
      ],
    };

    setConfirmationModal(params);
    this.setState({ menuOpen: false });
  };

  handleBodyHTML = (content) => {
    this.setState({ body: content });
  };

  deleteComment = () => {
    const {
      comment,
      setAlert,
      onDeleteClientComment,
      setConfirmationModal,
      removeCommentTaskId,
      task,
    } = this.props;
    const remove = () => {
      this.setState({ loading: true });
      DeleteComment(comment.id)
        .then(() => {
          if (onDeleteClientComment) onDeleteClientComment(comment);
          removeCommentTaskId(comment, task);
        })
        .catch((error) => {
          console.warn(error);
          setAlert({ type: "error", text: "Failed to delete comment." });
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    };
    const cancel = () => {
      this.setState({ menuOpen: false, loading: false });
    };
    const params = {
      title: "Delete Comment",
      message: "Do you want to delete the comment?  This cannot be undone.",
      icon: "delete",
      buttons: [
        {
          text: "Cancel",
          callBack: cancel,
        },
        {
          text: "Delete Comment",
          callBack: remove,
          color: "red",
        },
      ],
    };
    setConfirmationModal(params);
    this.setState({ menuOpen: false });
  };

  hideEditor = () => {
    this.setState((state) => {
      return {
        editing: !state.editing,
        body: "",
        menuOpen: false,
      };
    });
  };

  editComment = () => {
    const { advisor, externalId, comment, hub } = this.props;

    const isRedtailIntegrated =
      advisor.integrations.find((integration) => {
        return integration.hubId === hub.id && integration.type === "redtail";
      }) !== undefined;

    if (isRedtailIntegrated) {
      const url = `${config.redtailUrl}/contacts/${externalId}`;
      window.open(url, "_blank");
    } else {
      this.setState((state) => {
        return {
          editing: !state.editing,
          body: comment.content,
        };
      });
    }
  };

  hideComment = () => {
    const { comment, setAlert, onEditClientComment } = this.props;
    const request = {
      isHidden: true,
    };

    this.setState({ menuOpen: false });

    HideComment(comment.id, request)
      .then((response) => {
        if (onEditClientComment) onEditClientComment(response);
        setAlert({ type: "success", text: "Comment hidden." });
      })
      .catch((error) => {
        console.warn(error);
        setAlert({ type: "error", text: "Failed to hide comment." });
      });
  };

  updateComment = () => {
    const { body } = this.state; // comment content
    const { comment, onEditClientComment, setAlert } = this.props;
    this.setState({ loading: true });

    const parsedBody = parse(body, {
      replace: (domNode) => {
        // Add // to links without http or https at start
        if (domNode.attribs && domNode.attribs.href) {
          let url = domNode.attribs.href;
          if (
            !domNode.attribs.href.startsWith("http://") &&
            !domNode.attribs.href.startsWith("https://") &&
            !domNode.attribs.href.startsWith("//") &&
            !domNode.attribs.href.startsWith("mailto:")
          ) {
            url = `//${domNode.attribs.href}`;
          }
          return React.createElement(
            "a",
            { href: url, target: "_blank" },
            domNode.children.length > 0 ? domNode.children[0].data : url
          );
        }
        return domNode;
      },
    });
    const request = {
      content: renderToStaticMarkup(parsedBody),
    };
    EditComment(comment.id, request)
      .then((response) => {
        this.hideEditor();
        // Temporarily set the new Date to now() - this will get updated to the proper date on next comment sync
        response.externalUpdatedAt = new Date();
        onEditClientComment(response);
      })
      .catch((error) => {
        console.warn(error);
        setAlert({ type: "error", text: "Failed to edit comment." });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  toggleMenu = () => {
    this.setState((state) => {
      return { menuOpen: !state.menuOpen };
    });
  };

  removeTaskId = () => {
    const { comment, removeCommentTaskId, task, setAlert } = this.props;
    const request = {
      taskId: null,
    };
    EditComment(comment.id, request)
      .then((response) => {
        setAlert({
          type: "success",
          text: `Successfully removed task from comment`,
        });
        removeCommentTaskId(comment, task);
      })
      .catch((error) => {
        setAlert({
          type: "error",
          text: `Failed to remove task from comment.`,
        });
      });
  };

  editingMarkup = () => {
    const { piiMask } = this.context;
    const { task } = this.props;
    const { body, loading } = this.state;
    // nbsp breaks quill, need to replace before parsing
    // Also need to convert Wealthbox emojis to another object, as they don't work as is
    let parsedBody = parse(body.replace("&nbsp;", " "), {
      replace: (domNode) => {
        // Parsing Wealthbox emojis to convert to a span with the alt unicode
        if (
          domNode.attribs &&
          domNode.attribs.class === "js-emoji editor-emoji fr-fic fr-dii"
        ) {
          return React.createElement("span", {}, domNode.attribs.alt);
        }
        return domNode;
      },
    });
    // Render to static markup convert <br> to <br/>, which Quill removes.  Need to convert back to <br>.
    parsedBody = renderToStaticMarkup(parsedBody).replace(/<br\/>/g, "<br>");
    // Need to decode all html entitles, as they break Quill
    // The next 3 lines are a trick to get decode all html entities
    const txt = document.createElement("textarea");
    txt.innerHTML = parsedBody;
    parsedBody = txt.value;
    return (
      <React.Fragment>
        <div style={{ marginTop: "1em" }} id={task.id}>
          <Header as="h5" style={{ marginBottom: "0.25em" }}>
            Edit Comment
          </Header>
          <ReactQuill
            className={piiMask("fs-block dd-privacy-mask")}
            name="body"
            data-test="update-comment-input"
            value={parsedBody}
            onChange={(content) => {
              this.handleBodyHTML(content);
            }}
            modules={this.modules}
            style={!task.title ? { marginBottom: "0.5em" } : {}}
            readOnly={loading}
          />
          {task.title && (
            // Hacking in the marginTop since the quill settings all require custom CSS, so it appears to not
            // have a rounded radius for the bottom by overlapping with a negative margin and we'll most likely
            // be redoing most of this with the CSS upgrade
            <Segment
              style={{
                margin: "-0.5em 0 0.5em 0",
                border: "1px solid #ccc",
                borderRadius: "0px 0px 5px 5px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexWrap: "nowrap",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ display: "flex", flexWrap: "nowrap" }}>
                  <div style={{ marginTop: "0.25em" }}>
                    <Icon name="tasks" />
                  </div>
                  <div
                    style={{ marginTop: "0.4em", lineHeight: "1.14285714em" }}
                  >
                    <Expandable lines={1}>{task.title}</Expandable>
                  </div>
                  <div style={{ marginLeft: "0.5em" }}>
                    <Button
                      data-test="remove-task-icon"
                      disabled={loading}
                      basic
                      size="mini"
                      icon="delete"
                      onClick={() => {
                        this.removeTaskId();
                      }}
                    />
                  </div>
                </div>
              </div>
            </Segment>
          )}
          <Button
            loading={loading}
            data-test="save-comment-button"
            color="green"
            content="Save"
            onClick={this.updateComment}
          />
          <Icon
            link
            data-test="delete-comment-button"
            disabled={loading}
            color="grey"
            size="large"
            name="delete"
            onClick={this.hideEditor}
          />
        </div>
      </React.Fragment>
    );
  };

  displayMarkup = () => {
    const { piiMask } = this.context;
    const { loading, menuOpen } = this.state;
    const { advisor, comment, hub, task } = this.props;
    const textStyle = task.completed
      ? {
          textDecorationLine: "line-through",
          textDecorationStyle: "solid",
          lineHeight: "1.14285714em",
        }
      : { lineHeight: "1.14285714em" };
    const createdAt = comment.externalCreatedAt
      ? comment.externalCreatedAt
      : comment.createdAt;
    const updatedAt = comment.externalUpdatedAt
      ? comment.externalUpdatedAt
      : comment.updatedAt;
    return (
      <Dimmer.Dimmable as={Container} dimmed={loading}>
        <Loading active={loading} message="Deleting comment..." inverted />
        <Card
          data-test="client-card-comment"
          fluid
          style={{ wordWrap: "break-word", flex: "inherit" }}
          id={task.id}
        >
          <Card.Content>
            <Header>
              <Header.Content
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div style={{ display: "flex" }} data-test="comment-created-by">
                  {comment.createdBy
                    ? `${comment.createdBy.firstName || ""} ${
                        comment.createdBy.lastName || ""
                      }`
                    : "Imported Comment"}
                </div>
                <div style={{ fontWeight: "bold", display: "inline" }}>
                  <div style={{ float: "right" }}>
                    <Popup
                      className="hubly_bars_menu"
                      flowing
                      popperModifiers={{
                        preventOverflow: {
                          boundariesElement: "window",
                          enabled: false,
                        },
                      }}
                      position="right center"
                      on="click"
                      onClose={this.toggleMenu}
                      onOpen={this.toggleMenu}
                      open={menuOpen}
                      trigger={
                        <Icon
                          data-test="comment-menu"
                          color="grey"
                          link
                          name="ellipsis horizontal"
                        />
                      }
                    >
                      <Button.Group
                        basic
                        vertical
                        labeled
                        icon
                        style={{ border: "none" }}
                      >
                        <Button
                          icon="edit outline"
                          data-test="edit-comment-menu-item"
                          content="Edit Comment"
                          onClick={this.editComment}
                        />
                        <Button
                          icon="eye slash"
                          data-test="hide-comment-menu-item"
                          content="Hide Comment"
                          onClick={this.hideComment}
                        />
                        {advisor.integrations.filter((i) => {
                          return i.hubId === hub.id;
                        }).length === 0 && (
                          <Button
                            data-test="delete-comment-menu-item"
                            icon="delete"
                            content="Delete Comment"
                            onClick={() => {
                              this.deleteComment();
                            }}
                          />
                        )}
                      </Button.Group>
                    </Popup>
                  </div>
                </div>
              </Header.Content>
              <Header.Subheader style={{ paddingTop: "0.25em" }}>
                {`Created on ${Moment(createdAt).format("MMMM D, YYYY")}. `}
                {`Last edited `}
                {comment.lastEditedBy
                  ? `by ${comment.lastEditedBy.firstName || ""} ${
                      comment.lastEditedBy.lastName || ""
                    } ` // The space is necessary
                  : ""}
                {`on ${Moment(updatedAt).format("MMMM D, YYYY")}.`}
              </Header.Subheader>
            </Header>
          </Card.Content>
          <Card.Content>
            <div
              className={piiMask("fs-block dd-privacy-mask")}
              data-test="comment-content"
            >
              {parse(
                sanitizeHtml(comment.content, {
                  allowedTags: sanitizeHtml.defaults.allowedTags.concat([
                    "h1",
                    "h2",
                    "img",
                    "u",
                  ]),
                  allowedAttributes: { img: ["alt"], a: ["href"] },
                  // Allow Wealthbox emoji class which we can use to replace emojis img tag
                  allowedClasses: { img: ["js-emoji"] },
                }),
                {
                  replace: (domNode) => {
                    // Parsing Wealthbox emojis to convert to a span with the alt unicode
                    if (
                      domNode.attribs &&
                      domNode.attribs.class === "js-emoji"
                    ) {
                      return React.createElement(
                        "span",
                        {},
                        domNode.attribs.alt
                      );
                    }
                    // Add // to links without http or https at start
                    if (domNode.attribs && domNode.attribs.href) {
                      let url = domNode.attribs.href;
                      if (
                        !domNode.attribs.href.startsWith("http://") &&
                        !domNode.attribs.href.startsWith("https://") &&
                        !domNode.attribs.href.startsWith("//") &&
                        !domNode.attribs.href.startsWith("mailto:")
                      ) {
                        url = `//${domNode.attribs.href}`;
                      }
                      return React.createElement(
                        "a",
                        { href: url, target: "_blank" },
                        domNode.children.length > 0
                          ? domNode.children[0].data
                          : url
                      );
                    }
                    return domNode;
                  },
                }
              )}
            </div>
          </Card.Content>
          {task.title && (
            <Card.Content>
              <div
                style={{
                  display: "flex",
                  flexWrap: "nowrap",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ display: "flex", flexWrap: "nowrap" }}>
                  <div>
                    <Checkbox
                      data-test="comment-task-completed-checkbox"
                      onClick={this.toggleTask}
                      checked={task.completed}
                      style={{
                        marginRight: "1em",
                      }}
                    />
                  </div>
                  <div style={textStyle}>
                    <Expandable lines={1}>{task.title}</Expandable>
                  </div>
                </div>
              </div>
            </Card.Content>
          )}
        </Card>
      </Dimmer.Dimmable>
    );
  };

  render() {
    const { comment } = this.props;
    const { editing } = this.state;
    if (!comment.isHidden && !comment.isCrmOnly) {
      return editing ? this.editingMarkup() : this.displayMarkup();
    }
    return <React.Fragment />;
  }
}
const mapStateToProps = (state) => {
  return {
    advisor: state.hubly.data.advisor,
    hub: state.hubly.data.hub.selected.hub,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    removeCommentTaskId: (comment, task) => {
      dispatch(RemoveCommentTaskId(comment, task));
    },
    setTaskComplete: (workflow, taskId) => {
      dispatch(SetTaskComplete(workflow, taskId));
    },
    setTaskIncomplete: (workflow, taskId) => {
      dispatch(SetTaskIncomplete(workflow, taskId));
    },
    setCommentTaskId: (taskId) => {
      dispatch(SetCommentTaskId(taskId));
    },
    setConfirmationModal: (id) => {
      dispatch(SetConfirmationModal(id));
    },
    setAlert: (alert) => {
      dispatch(SetAlert(alert));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CardComment);
