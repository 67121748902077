import React from "react";
import PropTypes from "prop-types";

import { Header } from "semantic-ui-react";

export function OptionLayout({ title, control, children, hint }) {
  return (
    <React.Fragment>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div>
          <Header as="h4" style={{ display: "inline" }}>
            {title}
          </Header>
        </div>
      </div>
      <div style={{ margin: "0em 1em 0em 1em" }}>{children}</div>
      <div>{control}</div>
      <div style={{ display: "flex", justifyContent: "flex-start" }}>
        <div>{hint}</div>
      </div>
    </React.Fragment>
  );
}

OptionLayout.propTypes = {
  title: PropTypes.node,
  children: PropTypes.node,
  control: PropTypes.node,
  hint: PropTypes.node,
};

OptionLayout.defaultProps = {
  title: undefined,
  children: undefined,
  control: undefined,
  hint: undefined,
};

export default OptionLayout;
