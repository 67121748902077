import Amplify from "aws-amplify";

import config from "./config";

const awsExports = Amplify.configure({
  Auth: {
    region: "us-east-1",
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
  },
  API: {
    endpoints: [
      {
        name: "HublyAPI",
        endpoint: config.gateway.URL,
      },
    ],
  },
});

export default awsExports;
