import { isEmail, isMobilePhone } from "validator";

// Get the name from the client search dropdown when clicking on add
export function getSearchName(clientName) {
  // If there is only one name entered, populate first name field
  // If more than one, populate first word into first name and remainder into last name
  let searchFirstName = "";
  let searchLastName = "";
  if (clientName.indexOf(" ") === -1) {
    searchFirstName = clientName;
  } else {
    searchFirstName = clientName.substr(0, clientName.indexOf(" "));
    searchLastName = clientName.substr(clientName.indexOf(" ") + 1);
  }
  return { searchFirstName, searchLastName };
}

export const isEmailValid = (clientEmail) => {
  return (
    clientEmail.trim().length === 0 ||
    (clientEmail.trim().length > 0 && isEmail(clientEmail.toLowerCase()))
  );
};

export const isPhoneValid = (clientPhone) => {
  return (
    clientPhone.trim().length === 0 ||
    (clientPhone.trim().length > 0 && isMobilePhone(clientPhone))
  );
};

export default getSearchName;
