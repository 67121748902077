import React from "react";
import PropTypes from "prop-types";

export function Item({ children }) {
  return (
    <div
      style={{
        width: "100%",
        color: "grey",
        textAlign: "center",
        paddingBottom: "2.7em",
        minHeight: "60px",
        lineHeight: "60px",
      }}
    >
      {children}
    </div>
  );
}

Item.propTypes = {
  children: PropTypes.node,
};

Item.defaultProps = {
  children: null,
};

export default Item;
