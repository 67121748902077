import { useCallback, useEffect, useMemo } from "react";

import { useSubscribe } from "data/messages/hooks";

import { useQuery } from "hooks";

import toGlobalId from "../../../api/helper";
import { useAccessTypeStore, useFilterContext } from "../../hooks";

export function useWorkflowEditor(workflowId, isVisible = true) {
  const { setAccessStatus } = useAccessTypeStore();

  const queryFactory = useCallback(() => {
    const query = `query getWorkflowEditorData($id: ID!) {
      workflow(id: $id) {
        uuid
        name
        numActiveClients
        numActiveClientWorkflows
        numTasks
        numWorkflowRules
        multipleTimePerClient
        canEdit
        clientTileOptions {
          showProgress
          showIcons
          showStreams
          numberOfTasks
          presentWorkflowName
        }
        assignedAdvisor {
          uuid
          firstName
          lastName
        }
        editors {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }`;

    const resolver = ({ data: { workflow } }) => {
      return [
        {
          ...workflow,
          id: workflow.uuid,
          assignedAdvisor: workflow.assignedAdvisor
            ? { ...workflow.assignedAdvisor, id: workflow.assignedAdvisor.uuid }
            : null,
          editors: workflow.editors.edges.map(({ node: tNode }) => {
            return { ...tNode };
          }),
        },
      ];
    };

    return { query, resolver };
  }, []);

  const [, dispatchFilter] = useFilterContext();

  const {
    loading,
    reloadData,
    data: [wfData],
  } = useQuery(queryFactory, {
    variables: useMemo(() => {
      return { id: toGlobalId("Workflow", workflowId) };
    }, [workflowId]),
    options: { fetchOnInit: false },
  });

  useSubscribe(
    "workflows",
    useCallback(
      (wfsToUpdate) => {
        if (isVisible && wfsToUpdate.has(workflowId)) reloadData();
      },
      [workflowId, isVisible, reloadData]
    )
  );

  useEffect(() => {
    if (!loading && wfData) {
      dispatchFilter({
        type: "update-workflow",
        value: wfData,
      });
      setAccessStatus(wfData.canEdit);
    }
  }, [loading, wfData, setAccessStatus, dispatchFilter]);

  return {
    loadingWorkflowForEditor: loading,
    workflowWithEditorData: wfData,
    loadWorkflowDataForEditor: reloadData,
  };
}

export default useWorkflowEditor;
