import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Header } from "semantic-ui-react";

import DragDropList from "components/DragDropList";
import Spinner from "components/Spinner";

import {
  HubContext,
  ToasterContext,
} from "scenes/Hubly/components/Workspace/Provider";

import AddClientDropdown from "../../../AddClientDropdown";
import { isWorkflowDisabled, searchInputFilter } from "../../helper";
import { WorkflowTile } from "..";

export function WorkflowList({
  id,
  loading,
  placeholder,
  showHeader,
  workflows,
  filteredKeyword,
  selectedWorkflows,
  emptyText,
  onReordered,
  onChange,
  onClientsAdded,
}) {
  const { reOrderWorkflow } = useContext(HubContext);
  const { fireError } = useContext(ToasterContext);
  const [componentWorkflows, setComponentWorkflows] = useState([]);

  useEffect(() => {
    setComponentWorkflows(workflows);
  }, [workflows]);

  const toggleWorkflow = (showWorkflow, workflow) => {
    if (showWorkflow) {
      onChange([...selectedWorkflows, workflow]);
    } else {
      onChange(
        selectedWorkflows.filter((w) => {
          return w.id !== workflow.id;
        })
      );
    }
  };

  const updateWorkflowOrder = ({
    draggedItemIndex,
    items,
    previousOrderedItems,
  }) => {
    const targetWorkflow = items[draggedItemIndex];
    const nextWorkflow =
      draggedItemIndex + 1 < items.length ? items[draggedItemIndex + 1] : null;
    onReordered(items);
    reOrderWorkflow(targetWorkflow.id, nextWorkflow?.id)
      .then(() => {
        setComponentWorkflows(items);
      })
      .catch((error) => {
        fireError("Failed to reorder workflow.", error);
        setComponentWorkflows(previousOrderedItems);
        onReordered(previousOrderedItems);
      });
  };

  const filteredWorkflows = componentWorkflows.filter((workflow) => {
    return searchInputFilter(filteredKeyword, workflow);
  });
  return (
    <React.Fragment>
      {showHeader && (
        <React.Fragment>
          <div
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Header style={{ margin: "0.5em 0 0.8em 0" }} as="h4">
              Workflows
            </Header>
            <Spinner active={loading} size="tiny" />
          </div>

          {!placeholder && filteredWorkflows.length === 0 && emptyText && (
            <div style={{ color: "grey", margin: "auto" }}>{emptyText}</div>
          )}
        </React.Fragment>
      )}
      {placeholder}
      <div data-test={`${id}-list`} style={{ width: "100%" }}>
        {filteredWorkflows.length > 0 && (
          <DragDropList
            id={`drag-and-drop-${id}-list`}
            items={filteredWorkflows}
            onListReordered={updateWorkflowOrder}
            isDropDisabled={filteredKeyword !== ""}
          >
            {(workflow, dragHandleProps) => {
              const {
                id: wfId,
                process = null,
                numActiveClients,
                ...workflowProps
              } = workflow;
              const disabled = isWorkflowDisabled(selectedWorkflows, wfId);
              return (
                <WorkflowTile
                  data-test="workflow-tile"
                  key={wfId}
                  disabled={disabled}
                  processColor={process?.color}
                  filteredKeyword={filteredKeyword}
                  dragHandleProps={dragHandleProps}
                  numActiveClients={numActiveClients}
                  {...workflowProps}
                  onClick={() => {
                    toggleWorkflow(disabled, workflow);
                  }}
                >
                  {(onClearIconClicked) => {
                    return (
                      <AddClientDropdown
                        opened
                        workflowId={wfId}
                        style={{
                          width: "300px",
                          alignItems: "center",
                        }}
                        onClearIconClicked={onClearIconClicked}
                        onClientsAdded={(clients) => {
                          onClearIconClicked();
                          onClientsAdded({
                            workflow: {
                              ...workflow,
                              numActiveClients:
                                numActiveClients + clients.length,
                            },
                            clients: clients,
                          });
                          if (disabled) {
                            onChange([...selectedWorkflows, workflow]);
                          }
                        }}
                        onSyncProgressUpdated={() => {}}
                      />
                    );
                  }}
                </WorkflowTile>
              );
            }}
          </DragDropList>
        )}
      </div>
    </React.Fragment>
  );
}

WorkflowList.defaultProps = {
  showHeader: false,
  loading: false,
  emptyText: "",
  filteredKeyword: "",
  workflows: [],
  placeholder: null,
  onReordered: () => {},
};

WorkflowList.propTypes = {
  id: PropTypes.string.isRequired,
  showHeader: PropTypes.bool,
  loading: PropTypes.bool,
  emptyText: PropTypes.string,
  filteredKeyword: PropTypes.string,
  selectedWorkflows: PropTypes.array.isRequired,
  workflows: PropTypes.array,
  onReordered: PropTypes.func,
  placeholder: PropTypes.node,
  onChange: PropTypes.func.isRequired,
  onClientsAdded: PropTypes.func.isRequired,
};

export default WorkflowList;
