import React from "react";
import PropTypes from "prop-types";

import { MiniButton } from "./components";

export function ShowMoreButton({ children, visiable, onClick }) {
  if (!visiable) return null;

  return (
    <MiniButton
      data-test="card-reminders-expand-toggle-button"
      onClick={onClick}
    >
      {children}
    </MiniButton>
  );
}

ShowMoreButton.propTypes = {
  visiable: PropTypes.bool,
  children: PropTypes.node,
  onClick: PropTypes.func.isRequired,
};

ShowMoreButton.defaultProps = {
  visiable: true,
  children: undefined,
};

export default ShowMoreButton;
