export const OpenCreateClientModal = (
  clientName,
  isClientWorkflow,
  addClientToWorkflow,
  createCallback
) => {
  return {
    type: "OPEN_CREATE_CLIENT_MODAL",
    clientName: clientName,
    isClientWorkflow: isClientWorkflow,
    addClientToWorkflow: addClientToWorkflow,
    createCallback: createCallback,
  };
};
export const CloseCreateClientModal = () => {
  return {
    type: "CLOSE_CREATE_CLIENT_MODAL",
  };
};
