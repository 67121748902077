import React from "react";
import PropTypes from "prop-types";

export function MenuLayoutRight({ children }) {
  return <React.Fragment>{children}</React.Fragment>;
}

MenuLayoutRight.propTypes = {
  children: PropTypes.node,
};

MenuLayoutRight.defaultProps = {
  children: null,
};

export default MenuLayoutRight;
