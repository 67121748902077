import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { Button, Icon } from "semantic-ui-react";

import Tooltip from "components/Tooltip";

import { useGetUserSuggestions, useGetUserSuggestionsInfo } from "./hooks";
import { SuggestionsTips } from "./components";

export function LoadCRMSuggestions({ onSuggestions }) {
  const info = useGetUserSuggestionsInfo();
  const { data, loading, reloadData } = useGetUserSuggestions();

  useEffect(() => {
    if (data?.suggestedUsers) {
      onSuggestions(data.suggestedUsers);
    }
  }, [data, onSuggestions]);

  if (info.loading || !info.data?.userSuggestions) return null;

  return (
    <span data-test="suggested-crm-users">
      <Button
        basic
        disabled={loading}
        loading={loading}
        onClick={() => {
          return data?.suggestedUsers
            ? onSuggestions(data.suggestedUsers)
            : reloadData();
        }}
        style={{
          marginLeft: "10px",
          height: "30px",
          padding: "5px 10px",
        }}
      >
        Load CRM Suggestions
      </Button>
      <Tooltip
        position="top center"
        hoverable
        flowing
        title={<SuggestionsTips suggestions={info.data.userSuggestions} />}
      >
        <Icon color="grey" name="info circle" data-test="info-icon" />
      </Tooltip>
    </span>
  );
}

LoadCRMSuggestions.propTypes = {
  onSuggestions: PropTypes.func.isRequired,
};

LoadCRMSuggestions.defaultProps = {};

export default LoadCRMSuggestions;
