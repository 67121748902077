function makeKey({ query }, v) {
  if (!query) throw Error("not a cacheable query");
  return `${query}_${JSON.stringify(v)}`;
}

export class Cache {
  constructor(client, store) {
    this.store = store;
    this.client = client;
  }

  async query(v, q, force = false) {
    const k = makeKey(q, v);
    if (force) return this.store.set(k, await this.client.query(v, q)).get(k);
    if (this.store.has(k)) return this.store.get(k);
    return this.store.set(k, await this.client.query(v, q)).get(k);
  }
}

export function createCache(client) {
  return new Cache(client, new Map());
}

export default createCache;
