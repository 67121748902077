import React, { useState } from "react";
import PropTypes from "prop-types";

import { Button } from "semantic-ui-react";

import { PopupMenu, SortingMenu } from "./components";

export function WorkflowMenu({
  viewOnly,
  onClickSort,
  onClickEditWorkflow,
  onClickModifyTitle,
  onClickDuplicateWorkflow,
  onClickHideWorkflow,
}) {
  const [isSortingMenuOpen, setOpenSortingMenu] = useState(false);
  const [isSortingType, setIsSortingType] = useState("");
  const [isMainMenuOpen, setOpenMainMenu] = useState(false);

  if (isSortingMenuOpen) {
    return (
      <PopupMenu
        data-test="workflow-menu-sorting-popup"
        hasBackButton
        open={isSortingMenuOpen}
        onOpen={() => {
          return setOpenSortingMenu(true);
        }}
        onClose={() => {
          return setOpenSortingMenu(false);
        }}
        onBack={() => {
          setOpenSortingMenu(false);
          setOpenMainMenu(true);
        }}
      >
        <SortingMenu
          isSorting={isSortingType}
          onClickSort={(type) => {
            setIsSortingType(type);
            onClickSort(type)
              .then(() => {
                setOpenSortingMenu(false);
              })
              .finally(() => {
                setIsSortingType("");
              });
          }}
        />
      </PopupMenu>
    );
  }

  return (
    <PopupMenu
      open={isMainMenuOpen}
      onOpen={() => {
        return setOpenMainMenu(true);
      }}
      onClose={() => {
        return setOpenMainMenu(false);
      }}
    >
      <React.Fragment>
        <Button
          icon="settings"
          content={viewOnly ? "View Settings" : "Edit Workflow"}
          onClick={() => {
            onClickEditWorkflow();
            setOpenMainMenu(false);
          }}
        />
        <Button
          icon="sort content ascending"
          content="Sort By..."
          onClick={() => {
            setOpenMainMenu(false);
            setOpenSortingMenu(true);
          }}
        />
        <Button
          icon="th"
          content="Edit Appearance"
          onClick={() => {
            onClickModifyTitle();
            setOpenMainMenu(false);
          }}
        />
        <Button
          icon="copy"
          content="Duplicate Workflow"
          onClick={() => {
            onClickDuplicateWorkflow();
            setOpenMainMenu(false);
          }}
        />
        <Button
          icon="hide"
          content="Hide From View"
          onClick={() => {
            onClickHideWorkflow();
            setOpenMainMenu(false);
          }}
        />
      </React.Fragment>
    </PopupMenu>
  );
}

WorkflowMenu.propTypes = {
  viewOnly: PropTypes.bool,
  onClickSort: PropTypes.func.isRequired,
  onClickEditWorkflow: PropTypes.func.isRequired,
  onClickModifyTitle: PropTypes.func.isRequired,
  onClickDuplicateWorkflow: PropTypes.func.isRequired,
  onClickHideWorkflow: PropTypes.func.isRequired,
};

WorkflowMenu.defaultProps = { viewOnly: false };

export default WorkflowMenu;
