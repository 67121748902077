import React from "react";
import PropTypes from "prop-types";

import Scheduler from "components/Scheduler";

import ReminderTile from "scenes/Hubly/components/Workspace/components/ReminderTile";

import { ReminderOptions } from "./components";

export function Reminder({ r, processing }) {
  const [isSchedulerOpen, openScheduler] = React.useState(false);

  return (
    <React.Fragment>
      <ReminderOptions
        onReschedule={() => {
          openScheduler(true);
        }}
        onDismiss={r.dismiss}
        onCompleteTask={r.task?.complete}
        onOpenClientCard={r.viewClientCard}
      >
        <ReminderTile reminder={r} spinning={processing} />
      </ReminderOptions>

      {isSchedulerOpen && (
        <Scheduler.Dialog
          title={r.title}
          value={r.time}
          onSave={(newDate) => {
            r.reschedule(newDate);
            openScheduler(false);
          }}
          onCancel={() => {
            openScheduler(false);
          }}
        />
      )}
    </React.Fragment>
  );
}

Reminder.propTypes = {
  r: PropTypes.shape({
    title: PropTypes.string.isRequired,
    time: PropTypes.string.isRequired,
    dismiss: PropTypes.func.isRequired,
    viewClientCard: PropTypes.func.isRequired,
    reschedule: PropTypes.func.isRequired,
    task: PropTypes.object,
  }).isRequired,
  processing: PropTypes.bool,
};

Reminder.defaultProps = {
  processing: false,
};

export default Reminder;
