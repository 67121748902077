import {
  CreateNextWorkflow,
  DeleteNextWorkflow,
} from "data/libs/nextWorkflows";

export const addNextWorkflow = CreateNextWorkflow;

export const deleteNextWorkflow = (id) => {
  return DeleteNextWorkflow(id, { bulk_remove_related_cwfs: true });
};

export default addNextWorkflow;
