import React from "react";
import PropTypes from "prop-types";

import { Checkbox } from "semantic-ui-react";

export function ToggleFilter({ filter, label, onChange, ...props }) {
  return (
    <Checkbox
      toggle
      checked={filter}
      label={label}
      onChange={(_e, { checked }) => {
        onChange(checked);
      }}
      {...props}
    />
  );
}

ToggleFilter.propTypes = {
  filter: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ToggleFilter;
