export const getAdvisors = () => {
  const query = `query getAdvisors {
    advisors {
      edges {
        node {
          uuid
          email
          firstName
          lastName
          name
        }
      }
    }
  }`;

  const resolver = ({ data }) => {
    return Object.entries(data).flatMap(([type, { edges }]) => {
      return edges.map(({ node }) => {
        return {
          ...node,
          id: node.uuid,
          type: type,
          group: type === "serviceRoles",
        };
      });
    });
  };

  return { query, resolver };
};

export const getAssignees = () => {
  const query = `query getAssignees {
    serviceRoles(orderBy:"name") {
      edges {
        node {
          uuid
          name
        }
      }
    }
    advisors {
      edges {
        node {
          uuid
          email
          firstName
          lastName
          name
        }
      }
    }
  }`;

  const resolver = ({ data }) => {
    return Object.entries(data).flatMap(([type, { edges }]) => {
      return edges.map(({ node }) => {
        return {
          ...node,
          id: node.uuid,
          type: type,
          group: type === "serviceRoles",
        };
      });
    });
  };

  return { query, resolver };
};

export default getAdvisors;
