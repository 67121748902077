import React, { useRef } from "react";
import PropTypes from "prop-types";

import { Header, Icon } from "semantic-ui-react";

import { useDelay } from "hooks";
import Tooltip from "components/Tooltip";

import { Input, Options } from "..";

export function TeamNameInput({
  title,
  loading,
  maxLength,
  value,
  onChange,
  isDefaultTeamSelected,
}) {
  const inputRef = useRef();

  const delayCallback = useDelay(300);
  const onDelayChange = (text) => {
    delayCallback(() => {
      onChange(text);
    });
  };

  return (
    <Options.Layout
      title={title}
      hint={
        isDefaultTeamSelected ? null : (
          <Header as="h4" style={{ display: "flex" }}>
            Default Settings
            <div>
              <Tooltip
                data-test="default-team-info-popup"
                title="For Service Team Role assignees, default settings will be used when no users are selected"
                position="top center"
              >
                <Icon
                  data-test="default-team-info-icon"
                  name="info circle"
                  color="grey"
                  style={{ marginLeft: "0.5em" }}
                />
              </Tooltip>
            </div>
          </Header>
        )
      }
    >
      <Input
        ref={inputRef}
        autoFocus={!value}
        data-test="team-name"
        fluid
        maxLength={maxLength}
        defaultValue={value}
        loading={loading}
        disabled={isDefaultTeamSelected}
        style={{ opacity: isDefaultTeamSelected ? ".45" : "1" }}
        onClick={() => {
          inputRef.current.select();
        }}
        onChange={(_, { value: newValue }) => {
          onDelayChange(newValue);
        }}
      />
    </Options.Layout>
  );
}

TeamNameInput.propTypes = {
  title: PropTypes.string,
  loading: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  maxLength: PropTypes.number,
  isDefaultTeamSelected: PropTypes.bool,
};

TeamNameInput.defaultProps = {
  title: "Team Name",
  loading: false,
  value: "",
  maxLength: 64,
  isDefaultTeamSelected: false,
};

export default TeamNameInput;
