import React, { useContext } from "react";
import PropTypes from "prop-types";

import { PrivacyModeContext } from "components/PrivacyMode/Context";

export function Layout({ name, popup, action, ...props }) {
  const { piiMask } = useContext(PrivacyModeContext);
  return (
    <div
      className={`grey_on_hover rounded p25 ${piiMask(
        "fs-block dd-privacy-mask"
      )}`}
      style={{
        display: "flex",
        paddingTop: "0.25em",
        paddingBottom: "0.25em",
        marginLeft: "1em",
        marginRight: "1em",
      }}
      {...props}
    >
      <div style={{ width: "40%" }}>{name}</div>
      <div style={{ flexGrow: 1 }}>{popup}</div>
      <div style={{ width: "100px", textAlign: "right" }}>{action}</div>
    </div>
  );
}

Layout.propTypes = {
  name: PropTypes.node,
  popup: PropTypes.node,
  action: PropTypes.node,
};

Layout.defaultProps = {
  name: undefined,
  popup: undefined,
  action: undefined,
};

export default Layout;
