import React from "react";
import PropTypes from "prop-types";
import { uniqBy } from "lodash";

import { Container, Dropdown, Header, Icon } from "semantic-ui-react";

import Tooltip from "components/Tooltip";

export function EditorDropdown({
  selected,
  editors,
  loading,
  disabled,
  onChange,
  onOpen,
}) {
  const toOptions = ({ id, name }) => {
    return { value: id, key: id, text: name };
  };

  return (
    <React.Fragment>
      <Container style={{ marginTop: "1em" }}>
        <Header
          as="h5"
          style={{
            marginBottom: "0.5em",
            display: "flex",
            alignItems: "baseline",
          }}
        >
          Editors
          <Tooltip title="Admins can edit all workflows">
            <Icon
              style={{ fontSize: "1em", paddingLeft: "0.4rem" }}
              name="info circle"
              color="grey"
              size="small"
            />
          </Tooltip>
        </Header>
        <Dropdown
          data-test="workflow-editors-dropdown"
          placeholder="Workflow Editors"
          disabled={disabled || loading}
          fluid
          multiple
          search
          selection
          loading={loading}
          closeOnChange
          value={selected.map(({ id }) => {
            return id;
          })}
          options={uniqBy(selected.concat(editors).map(toOptions), "key")}
          onChange={(_, { value }) => {
            onChange(value);
          }}
          onOpen={onOpen}
        />
      </Container>
    </React.Fragment>
  );
}

EditorDropdown.propTypes = {
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  selected: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string, name: PropTypes.string })
  ),
  editors: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string, name: PropTypes.string })
  ),
  onChange: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
};

EditorDropdown.defaultProps = {
  loading: false,
  disabled: false,
  selected: [],
  editors: [],
};

export default EditorDropdown;
