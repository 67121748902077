import React, { useEffect } from "react";
import PropTypes from "prop-types";

import userflow from "userflow.js";

export function UserFlow({ user, appToken }) {
  useEffect(() => {
    userflow.init(appToken);
    userflow.identify(user.userId, user);
  }, [user, appToken]);

  return <React.Fragment />;
}

UserFlow.propTypes = {
  user: PropTypes.object.isRequired,
  appToken: PropTypes.string.isRequired,
};

UserFlow.defaultProps = {};

export default UserFlow;
