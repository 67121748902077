import clone from "rfdc";

import { FetchActiveClient, SetActiveClient } from "data/hub/clients/actions";
import { SetWorkflow } from "data/hub/workflows/actions";
import { AddTask, DeleteTask, EditTask } from "data/libs/tasks";
import {
  dismissRemindersFromTasks,
  InsertReminder,
  SetReminders,
} from "data/reminders/actions";

import { SetAlert } from "components/Alerts/actions";
import { SetConfirmationModal } from "components/ConfirmationModal/actions";

import { getClientWorkflowsByClientId } from "scenes/Hubly/components/Workspace/api/clients";

import {
  EditClientWorkflow,
  GetClientWorkflows,
  GetClientWorkflowsByClientID,
} from "../../libs/clientWorkflows";

const deepClone = clone();

export const sortClientWorkflow = (a, b, clientWorkflows) => {
  const aSortOrder = clientWorkflows[a].sortOrder;
  const bSortOrder = clientWorkflows[b].sortOrder;
  if (aSortOrder > bSortOrder) {
    return 1;
  }
  if (aSortOrder < bSortOrder) {
    return -1;
  }
  return 0;
};

export const UpdateTaskReminder = (reminder) => {
  return {
    type: "UPDATE_TASK_REMINDER",
    reminder: reminder,
  };
};

export const EditClientTask = (task) => {
  return {
    type: "EDIT_CLIENT_TASK",
    task: task,
  };
};

export const SetClientWorkflow = (clientWorkflow) => {
  return {
    type: "SET_CLIENT_WORKFLOW",
    clientWorkflow: clientWorkflow,
  };
};

export const AddClientTask = (request, client, currentWorkflow) => {
  return (dispatch, getState) => {
    AddTask(request, currentWorkflow.clientId)
      .then((response) => {
        dispatch({ type: "ADD_CLIENT_TASK", task: response });
        if (response.reminders.length > 0) {
          const clientCopy = { ...client };
          clientCopy.reminders.push(response.reminders[0]);
          dispatch(SetActiveClient(clientCopy));

          const { reminders } = getState().hubly.data;
          const remindersCopy = Object.assign([], reminders);
          InsertReminder(response.reminders[0], remindersCopy);
          dispatch(SetReminders(remindersCopy));
        }

        dispatch(
          SetAlert({ type: "success", text: "Successfully created new task." })
        );
      })
      .catch((error) => {
        console.error(error);
        dispatch(SetAlert({ type: "error", text: `Failed to add task.` }));
      });
  };
};

export const ToggleClientWorkflowComplete = (
  clientId,
  clientWorkflowId,
  completed,
  callback
) => {
  return (dispatch, getState) => {
    const time = new Date().toISOString();
    // const foundWorkflow = { ...getState().hubly.data.hub.clientWorkflows[clientWorkflowId] };
    //
    // foundWorkflow.isHidden = completed;
    // if (!foundWorkflow.completed && completed) foundWorkflow.completedAt = (new Date()).toISOString();
    // else if (foundWorkflow.completedAt && !completed) foundWorkflow.completedAt = null;
    // foundWorkflow.completed = completed;
    // foundWorkflow.archived = archived;
    //
    // dispatch(SetClientWorkflow(foundWorkflow));

    const request = {
      isHidden: completed,
      completed: completed,
      archived: false,
      completedAt: time,
    };
    const str = completed ? "complete" : "incomplete";

    EditClientWorkflow(clientWorkflowId, request)
      .then((response) => {
        dispatch(SetClientWorkflow(response));
        dispatch(FetchActiveClient(clientId));
        dispatch(
          SetAlert({
            type: "success",
            text: `Successfully set client workflow ${str}`,
          })
        );
      })
      .catch((error) => {
        console.warn(`Failed to set client workflow ${str}`, clientWorkflowId);
        console.warn(error);
        dispatch(
          SetAlert({
            type: "error",
            text: `Failed to set client workflow ${str}}`,
          })
        );
      })
      .finally(() => {
        if (callback) callback();
      });
  };
};

export const SortClientWorkflows = (clientWorkflows, allClientWorkflows) => {
  // Sort the client workflows by reverse chronological order, with the completed workflows at the end
  const sortedClientWorkflows = Object.values(clientWorkflows) || [];
  if (
    sortedClientWorkflows.length === 0 ||
    Object.keys(allClientWorkflows).length === 0
  ) {
    return -1;
  }
  // Sort by reverse chronological order
  sortedClientWorkflows.sort((a, b) => {
    return (
      new Date(allClientWorkflows[b]?.createdAt).getTime() -
      new Date(allClientWorkflows[a]?.createdAt).getTime()
    );
  });
  // Sort the completed workflows to the end of the list
  sortedClientWorkflows.sort((a, b) => {
    // If both true/false, equal, otherwise if completed, set further down (1) and near the beginning (-1) if false
    if (allClientWorkflows[a]?.completed === allClientWorkflows[b]?.completed) {
      return 0;
    } else {
      return allClientWorkflows[a]?.completed ? 1 : -1;
    }
  });
  return sortedClientWorkflows;
};

// If a workflow ID is set, find last workflow (don't check if completed or not)
// Otherwise, find the last client workflow that is not completed
export const FindLastClientWorkflowId = (
  client,
  workflowId,
  clientWorkflows
) => {
  const sortedClient = SortClientWorkflows(client.workflows, clientWorkflows);
  if (workflowId && sortedClient && sortedClient.workflows) {
    const clientWorkflow = sortedClient.workflows.find((workflow) => {
      return workflow.workflowId === workflowId;
    });

    if (clientWorkflow) {
      return clientWorkflow.id;
    }

    // If we make it here, the client has never been added into this workflow (this is a failure state)
    // Let the error fall through though, so the caller will at least get a workflow returned
    console.warn(
      "FindLastClientWorkflowId, Client ID ",
      client.id,
      " does not have a workflow",
      workflowId
    );
  }

  // Return the first client workflow ID as it's already sorted reverse chronologically and incomplete at the top
  // of the list
  if (client.workflows && client.workflows.length > 0) {
    return client.workflows[0];
  } else {
    return null;
  }
};

export const RemoveClientWorkflow = (clientWorkflow) => {
  return (dispatch, getState) => {
    const clientCopy = {
      ...getState().hubly.data.hub.clients.activeClients[
        clientWorkflow.clientId
      ],
    };
    let remindersCopy = [...getState().hubly.data.reminders];

    // Want to remove from workflows, as it's no longer shown
    const workflowCopy = {
      ...getState().hubly.data.hub.workflows[clientWorkflow.workflowId],
    };
    workflowCopy.clients = workflowCopy.clients.filter((cw) => {
      return cw !== clientWorkflow.id;
    });

    // Want to remove from client workflows
    clientCopy.workflows = clientCopy.workflows.filter((cwId) => {
      return cwId !== clientWorkflow.id;
    });

    // Want to dismiss all reminders for this client workflow
    const taskIds = (clientWorkflow.tasks || []).map((task) => {
      return task.id;
    });
    remindersCopy = remindersCopy.filter((reminder) => {
      return !taskIds.includes(reminder.taskId);
    });

    dispatch(SetReminders(remindersCopy));
    dispatch(SetWorkflow(workflowCopy));
    dispatch(SetActiveClient(clientCopy));
  };
};

export function removeClientFromWorkflow(workflow, clientWorkflowId) {
  const updatedClients = workflow?.clients?.filter((id) => {
    return id !== clientWorkflowId;
  });

  if (!workflow || !updatedClients) return workflow;
  return { ...workflow, clients: updatedClients };
}

export const HideClientWorkflow = (clientWorkflowId) => {
  return (dispatch, getState) => {
    const remindersCopy = [...getState().hubly.data.reminders];
    const foundClientWorkflow = {
      ...getState().hubly.data.hub.clientWorkflows[clientWorkflowId],
    };
    const workflowCopy = {
      ...getState().hubly.data.hub.workflows[foundClientWorkflow.workflowId],
    };

    // Want to hide in the client objects as it will still appear as a completed workflow in the client card
    foundClientWorkflow.completed = true;
    foundClientWorkflow.archived = true;
    foundClientWorkflow.isHidden = true;

    dispatch(
      SetReminders(
        dismissRemindersFromTasks(foundClientWorkflow?.tasks, remindersCopy)
      )
    );
    dispatch(
      SetWorkflow(removeClientFromWorkflow(workflowCopy, clientWorkflowId))
    );
    dispatch(SetClientWorkflow(foundClientWorkflow));
  };
};

export const RemoveClientTask = (taskId, clientWorkflowId) => {
  return (dispatch, getState) => {
    DeleteTask(taskId, null, clientWorkflowId)
      .then((response) => {
        // Need to copy in order to trigger re-render.  Not needed if setting the state after a successful call
        const foundClientWorkflow = {
          ...getState().hubly.data.hub.clientWorkflows[clientWorkflowId],
        };

        // This fuckery is to capture the task we remove from the workflow
        let deletedTask;
        for (let i = 0; i < foundClientWorkflow.tasks.length; i += 1) {
          if (foundClientWorkflow.tasks[i].id === taskId) {
            deletedTask = foundClientWorkflow.tasks.splice(i, 1);
          }
        }

        dispatch({
          type: "DELETE_CLIENT_TASK",
          taskId: taskId,
          clientWorkflowId: clientWorkflowId,
        });

        // So that we can remove all the reminders from the state for it, to ensure the Hub feed renders correctly
        let reminders = [...getState().hubly.data.reminders];
        if (deletedTask.length === 1) {
          deletedTask[0].reminders.forEach((reminder) => {
            reminders = reminders.filter((r) => {
              return r.id !== reminder.id;
            });
          });
          dispatch(SetReminders(deepClone(reminders)));
        }
        dispatch(
          SetAlert({ type: "success", text: "Successfully deleted task." })
        );
      })
      .catch((error) => {
        console.error(error);
        dispatch(SetAlert({ type: "error", text: `Failed to delete task.` }));
      });
  };
};

export const RenameClientTask = (taskId, clientWorkflowId, taskTitle) => {
  return (dispatch, getState) => {
    const foundClientWorkflow = {
      ...getState().hubly.data.hub.clientWorkflows[clientWorkflowId],
    };
    const taskIndex = foundClientWorkflow.tasks.findIndex((task) => {
      return task.id === taskId;
    });
    if (taskIndex < 0) {
      console.warn("RenameClientTask, Task ID ", taskId, " does not exist");
    }
    foundClientWorkflow.tasks[taskIndex].title = taskTitle;
    dispatch(SetClientWorkflow(foundClientWorkflow));

    const request = {
      title: taskTitle,
      clientWorkflowId: clientWorkflowId,
    };
    EditTask(taskId, request, foundClientWorkflow.clientId)
      .then((response) => {
        dispatch(EditClientTask(response));
        // Rename all associated reminder titles
        const reminders = [...getState().hubly.data.reminders];
        const remindersCopy = reminders.slice();
        remindersCopy.forEach((reminder) => {
          if (reminder.taskId === taskId) {
            reminder.title = taskTitle;
          }
        });
        dispatch(SetReminders(remindersCopy));
      })
      .catch((error) => {
        console.error(error);
        dispatch(SetAlert({ type: "error", text: `Failed to edit task.` }));
      });
  };
};

export const SetClientWorkflows = (clientWorkflows) => {
  const workflows = {};
  clientWorkflows.forEach((clientWorkflow) => {
    workflows[clientWorkflow.id] = clientWorkflow;
  });
  return {
    type: "SET_CLIENT_WORKFLOWS",
    clientWorkflows: workflows,
  };
};

export const DeleteClientWorkflow = (clientWorkflowId) => {
  return {
    type: "DELETE_CLIENT_WORKFLOW",
    clientWorkflowId: clientWorkflowId,
  };
};

export const GetAllClientWorkflows = async (hub) => {
  let clientWorkflows = [];
  const limit = 100;

  return new Promise((resolve, reject) => {
    GetClientWorkflows(hub, limit, 0).then((response) => {
      if (!response.next) {
        // If there's only one page, just return it
        resolve(response.results);
      } else {
        const requests = [];
        clientWorkflows = response.results;
        const pages = response.count / limit;
        for (let i = 1; i < pages; i += 1) {
          requests.push(GetClientWorkflows(hub, limit, i * limit));
        }

        Promise.all(requests).then((allResponses) => {
          for (let i = 0; i < allResponses.length; i += 1) {
            clientWorkflows = clientWorkflows.concat(allResponses[i].results);
          }

          resolve(clientWorkflows);
        });
      }
    });
  });
};

export const ArchiveClientWorkflow = (clientWorkflow, callback) => {
  return (dispatch, getState) => {
    const remove = () => {
      return EditClientWorkflow(clientWorkflow.id, {
        completed: true,
        isHidden: true,
        archived: true,
      }).then(() => {
        dispatch(HideClientWorkflow(clientWorkflow.id));
        callback();
      });
    };

    const params = {
      title: "Archive Client",
      message: "Do you want to archive the selected client from this workflow?",
      icon: "archive",
      buttons: [
        { text: "Cancel" },
        {
          text: "Archive",
          callBack: remove,
          color: "red",
        },
      ],
    };

    dispatch(SetConfirmationModal(params));
  };
};

export const GetAllClientWorkflowsByClientId = async (hub, clientId) => {
  let clientWorkflows = [];
  const limit = 100;

  return new Promise((resolve, reject) => {
    GetClientWorkflowsByClientID(hub, clientId, limit, 0).then((response) => {
      if (!response.next) {
        // If there's only one page, just return it
        resolve(response.results);
      } else {
        const requests = [];
        clientWorkflows = response.results;
        const pages = response.count / limit;
        for (let i = 1; i < pages; i += 1) {
          requests.push(
            GetClientWorkflowsByClientID(hub, clientId, limit, i * limit)
          );
        }

        Promise.all(requests).then((allResponses) => {
          allResponses.forEach((res) => {
            clientWorkflows = clientWorkflows.concat(res.results);
          });
          resolve(clientWorkflows);
        });
      }
    });
  });
};

export const UpdateClientWorkFlows = (clientWorkflows) => {
  return {
    type: "BULK_UPDATE_CLIENT_WORKFLOWS",
    clientWorkflows: clientWorkflows,
  };
};

export const SetClientWorkflowLoader = (fetching) => {
  return {
    type: "FETCHING_CLIENT_WITH_WORKFLOWS",
    value: fetching,
  };
};

export const fetchClientWorkflows = (clientId) => {
  return (dispatch, getState) => {
    const { hub } = getState().hubly.data.hub.selected;

    getClientWorkflowsByClientId({ hubId: hub.hubId, clientId: clientId })
      .then((clientWorkflows) => {
        dispatch(UpdateClientWorkFlows(clientWorkflows));
      })
      .catch((error) => {
        console.error(error);
        dispatch(SetClientWorkflowLoader({ loading: false, error: error }));
      });
  };
};
