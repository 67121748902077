export const SetCurrentView = (currentView) => {
  return {
    type: "SET_CURRENT_VIEW",
    currentView: currentView,
  };
};

export const SetViews = (views) => {
  return {
    type: "SET_VIEWS",
    views: views,
  };
};
export default SetViews;
