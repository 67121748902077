import { useCallback, useContext, useEffect, useMemo } from "react";

import { useQuery } from "hooks";

import { ToasterContext } from "scenes/Hubly/components/Workspace/Provider";

export default function useSubscriptionInfo(inviteCount) {
  const { fireError } = useContext(ToasterContext);

  const getSubscriptionInfoFactory = useCallback(() => {
    const query = `
      query subscriptionInfo($inviteCount: Int!){
        subscriptionInfo(inviteCount: $inviteCount){
          billingPeriod
          invitedUserCount
          legacySeats
          legacyCharges
          regularSeats
          regularPrice
          regularCharges
          seatsAvailable
          currentSeats
          currentCharges
          seatsNeeded
          additionalCharges
          newTotalSeats
          newTotalCharges
        }
      }
    `;

    const resolver = ({ data: { subscriptionInfo }, errors }) => {
      if (errors?.length > 0) throw errors;
      return subscriptionInfo;
    };

    return { query, resolver };
  }, []);

  const { loading, data, isEmpty, error } = useQuery(
    getSubscriptionInfoFactory,
    useMemo(() => {
      return {
        variables: { inviteCount },
        options: { refetchOnVarChange: true },
      };
    }, [inviteCount])
  );

  useEffect(() => {
    if (!loading && error) {
      fireError(error[0]?.message || "Failed to load subscription information");
    }
  }, [loading, error, fireError]);

  return {
    loading: loading,
    isEmpty: isEmpty,
    data: Array.isArray(data) ? {} : data,
  };
}
