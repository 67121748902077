import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import ConfirmationModal from "components/ConfirmationModal";
import CreateClientModal from "components/CreateClientModal";
import WealthboxIntegrationModal from "components/WealthboxIntegrationModal";

import NewUserModal from "./components/Integrations/NewUserModal";
import PrivacyPolicyModal from "./components/PrivacyPolicy";
import { SetPolicyModal } from "./components/PrivacyPolicy/actions";
import { GetPolicies } from "../../data/libs/policy";

class Modals extends React.Component {
  static defaultProps = {
    confirmationParams: null,
    createClientModalOpen: false,
    policyModalOpen: false,
    wealthboxIntegrationModalOpen: false,
  };

  static propTypes = {
    advisor: PropTypes.object.isRequired,
    confirmationParams: PropTypes.object,
    createClientModalOpen: PropTypes.bool,
    policyModalOpen: PropTypes.bool,
    setPolicyModal: PropTypes.func.isRequired,
    wealthboxIntegrationModalOpen: PropTypes.bool,
  };

  componentDidMount() {
    // If the advisor has completed their setup and not agreed to the active policy, show policy (new users will have policy in setup)
    const { advisor, setPolicyModal } = this.props;

    GetPolicies().then((response) => {
      this.allPolicies = response;
      const foundNewPolicy = !!response.find((p) => {
        return !advisor.policies.find((ap) => {
          return ap.id === p.id;
        });
      });
      if (advisor.setupComplete && foundNewPolicy) {
        setPolicyModal(true);
      }
    });
  }

  render() {
    const {
      advisor,
      confirmationParams = null,
      createClientModalOpen = false,
      policyModalOpen = false,
      wealthboxIntegrationModalOpen = false,
    } = this.props;
    return (
      <React.Fragment>
        <NewUserModal advisor={advisor} />
        {confirmationParams && <ConfirmationModal />}
        {createClientModalOpen && <CreateClientModal />}
        {policyModalOpen && (
          <PrivacyPolicyModal
            allPolicies={this.allPolicies}
            advisor={advisor}
          />
        )}
        {wealthboxIntegrationModalOpen && <WealthboxIntegrationModal />}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    advisor: state.hubly.data.advisor,
    confirmationParams: state.hubly.confirmation.confirmationParams,
    createClientModalOpen: state.hubly.createClient.createClientModalOpen,
    policyModalOpen: state.hubly.scenes.modals.policy.policyModalOpen,
    wealthboxIntegrationModalOpen:
      state.hubly.wealthboxIntegration.wealthboxIntegrationModalOpen,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPolicyModal: (open) => {
      dispatch(SetPolicyModal(open));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Modals);
