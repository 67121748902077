import React from "react";
import PropTypes from "prop-types";

import { Button } from "semantic-ui-react";

import toGlobalId from "scenes/Hubly/components/Workspace/api/helper";

import { useSendInvitationEmail } from "../hooks/useSendInvitationEmail";

function SendInvitationButton({ user, onInvitationSent }) {
  const { sendInvitationEmail, loading } = useSendInvitationEmail();

  if (user.status === "Invite not sent") return null;

  return (
    <Button
      key="resend-invitation-email"
      icon="location arrow"
      disabled={loading}
      content="Re-send Email Invite"
      onClick={async () => {
        const name = `${user.firstName} ${user.lastName}`;
        await sendInvitationEmail(
          { id: toGlobalId("Advisor", user.id) },
          {
            defaultErrorMsg: `Failed to sent invitation email${
              name ? ` to ${name}` : ""
            }.`,
          }
        );
        onInvitationSent();
      }}
    />
  );
}

SendInvitationButton.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    status: PropTypes.string.isRequired,
  }).isRequired,
  onInvitationSent: PropTypes.func,
};

SendInvitationButton.defaultProps = {
  onInvitationSent: () => {},
};

export default SendInvitationButton;
