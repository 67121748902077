import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { RequireNewPassword } from "aws-amplify-react";
import { Button, Form, Icon, Input, Message } from "semantic-ui-react";

import { SetLoginErrorMessage, SetLoginInfoMessage } from "../actions";
import LoginContainer from "./LoginContainer";

class HublyRequireNewPassword extends RequireNewPassword {
  static propTypes = {
    setLoginErrorMessage: PropTypes.func.isRequired,
    setLoginInfoMessage: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      codeLabel: false,
      email: "",
      inputValue: "",
    };
  }

  confirm = () => {
    const { setLoginErrorMessage } = this.props;
    setLoginErrorMessage("");
    const { code } = this.inputs;
    let valid = true;
    if (!code || code.length < 1) {
      valid = false;
    }
    if (!valid) {
      return;
    }
    super.confirm();
  };

  row = (checked, message) => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <Icon
          name={checked ? "check" : "delete"}
          color={checked ? "green" : "grey"}
          size="small"
        />
        <div>{message}</div>
      </div>
    );
  };

  handleInputChange = (a, b) => {
    super.handleInputChange(a, b);
    this.setState({ inputValue: b.value });
  };

  showComponent() {
    const { inputValue } = this.state;
    const { emailAddress, setLoginErrorMessage, setLoginInfoMessage } =
      this.props;
    setLoginInfoMessage(
      <React.Fragment>
        <Message.Header>Welcome to Hubly!</Message.Header>
        <p>
          Please create a new password for&nbsp;
          <strong>{emailAddress}</strong>
        </p>
      </React.Fragment>
    );

    const checkOne = inputValue.length >= 8;
    const checkTwo = /[A-Z]/.test(inputValue);
    const checkThree = /[a-z]/.test(inputValue);
    const checkFour = /[0-9]/.test(inputValue);
    const checkFive = /(?=.*[!@#$%&/=?_.,:;\\-])/.test(inputValue);

    return (
      <LoginContainer title="Change Password">
        <Form style={{ overflow: "auto" }}>
          <Form.Field style={{ marginBottom: "0.5em" }}>
            <label>New Password</label>
            <Input
              autoFocus
              placeholder="New Password"
              key="password"
              name="password"
              type="password"
              onChange={this.handleInputChange}
            />
          </Form.Field>
          <div
            style={{
              color: "grey",
              marginBottom: "1em",
              fontSize: "9pt",
              lineHeight: "13pt",
            }}
          >
            Passwords must contain:
            {this.row(checkOne, "8 or more characters")}
            {this.row(checkTwo, "Uppercase letter")}
            {this.row(checkThree, "Lowercase letter")}
            {this.row(checkFour, "Number")}
            {this.row(checkFive, "Special character")}
          </div>
          <Button
            floated="right"
            primary
            onClick={this.change}
            disabled={
              !(checkOne && checkTwo && checkThree && checkFour && checkFive)
            }
          >
            Change
          </Button>
          <Button
            basic
            onClick={() => {
              setLoginErrorMessage("");
              setLoginInfoMessage(null);
              this.changeState("signIn");
              this.setState({
                inputValue: "",
              });
            }}
          >
            Back to Sign in
          </Button>
        </Form>
      </LoginContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginErrorMessage: (message) => {
      dispatch(SetLoginErrorMessage(message));
    },
    setLoginInfoMessage: (message) => {
      dispatch(SetLoginInfoMessage(message));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HublyRequireNewPassword);
