import React from "react";
import PropTypes from "prop-types";

import { Button, Icon, Item } from "semantic-ui-react";

import WithScrollToLoad from "components/WithScrollToLoad";

import { Layout as ListLayout } from "./components/Layout";

const LayoutWithScrollToLoad = WithScrollToLoad(ListLayout);
export function ManyToManyList({
  loading,
  items,
  loadMoreData,
  showLoadMore,
  type,
  onClick,
}) {
  return (
    <LayoutWithScrollToLoad
      loadMoreData={loadMoreData}
      showLoadMore={showLoadMore}
      loading={loading}
    >
      {items.map((item) => {
        const { id, name, color = "" } = item;
        return (
          <Item
            key={`${id}-${type}`}
            data-test={`${type}-item`}
            className="grey_on_hover"
            style={{ padding: ".5em 1em" }}
            onClick={() => {
              onClick(item);
            }}
          >
            <Button
              fluid
              style={
                type === "stream"
                  ? {
                      backgroundColor: `${color}`,
                      color: "white",
                    }
                  : {}
              }
              icon={type === "tag"}
            >
              {type === "tag" && <Icon name="hashtag" />}
              {name}
            </Button>
          </Item>
        );
      })}
    </LayoutWithScrollToLoad>
  );
}

ManyToManyList.defaultProps = {
  loading: false,
  items: [],
  loadMoreData: () => {},
  showLoadMore: false,
};

ManyToManyList.propTypes = {
  loading: PropTypes.bool,
  items: PropTypes.array,
  loadMoreData: PropTypes.func,
  showLoadMore: PropTypes.bool,
  type: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ManyToManyList;
