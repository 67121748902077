import React from "react";
import PropTypes from "prop-types";

import { faGripVertical } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useDraggable } from "components/DragDrop";

export function Layout({
  addingMode,
  taskCheckbox,
  taskTitle,
  taskDetailsContent,
  assignedAdvisor,
  editMode,
  leftSideMinWidth,
  disableHover,
  children,
  ...props
}) {
  const { draggableProps, dragHandleProps } = useDraggable();
  const assignedAdvisorMarginLeft = assignedAdvisor ? "0.5em" : 0;

  return (
    <div
      className={disableHover ? "" : "grey_on_hover"}
      style={{
        display: "flex",
        flexDirection: "column",
        borderRadius: "5px",
        padding: "0.25em 0.5em",
      }}
      {...draggableProps}
      {...props}
    >
      <div style={{ display: "flex", flexFlow: "row", alignItems: "center" }}>
        <div
          style={{
            minWidth: leftSideMinWidth,
            display: "flex",
            flexFlow: "row",
            alignItems: "center",
            flexGrow: "1",
          }}
        >
          {dragHandleProps && !addingMode ? (
            <div
              style={{
                alignSelf: "flex-start",
                marginTop: "0.2em",
              }}
              {...dragHandleProps}
            >
              <FontAwesomeIcon
                className="dark_grey_on_hover"
                color="rgb(218, 218, 218)"
                icon={faGripVertical}
                style={{ marginLeft: "0.5em" }}
              />
            </div>
          ) : (
            <div style={{ width: "1.1em" }} />
          )}
          <div
            style={{
              flexGrow: 1,
              display: "flex",
              flexFlow: "row",
              marginLeft: taskCheckbox ? "0.5em" : 0,
              alignItems: "center",
            }}
          >
            {!editMode && (
              <div style={{ marginTop: "0.3em", marginLeft: "0.25em" }}>
                {taskCheckbox}
              </div>
            )}
            {!editMode && (
              <div
                style={{
                  alignSelf: "flex-start",
                  marginLeft: assignedAdvisorMarginLeft,
                  marginTop: assignedAdvisor && addingMode ? "0.4em" : 0,
                }}
              >
                {assignedAdvisor}
              </div>
            )}
            <div style={{ marginLeft: "0.4em", flexGrow: 1 }}>{taskTitle}</div>
          </div>
        </div>
        {!editMode && children && (
          <div style={{ minWidth: 120, display: "flex" }}>{children}</div>
        )}
      </div>
      <div
        className="taskDescriptionDiv"
        style={{
          marginTop: "0.25em",
          marginLeft: "5em",
          marginRight: "2em",
        }}
      >
        {taskDetailsContent}
      </div>
    </div>
  );
}

Layout.propTypes = {
  taskCheckbox: PropTypes.node,
  taskTitle: PropTypes.node,
  assignedAdvisor: PropTypes.node,
  editMode: PropTypes.bool,
  leftSideMinWidth: PropTypes.number,
  dragHandleProps: PropTypes.object,
  taskDetailsContent: PropTypes.node,
  disableHover: PropTypes.bool,
  addingMode: PropTypes.bool,
  children: PropTypes.node,
};

Layout.defaultProps = {
  addingMode: false,
  taskCheckbox: null,
  taskTitle: null,
  assignedAdvisor: null,
  editMode: false,
  leftSideMinWidth: 75,
  dragHandleProps: null,
  disableHover: false,
  children: null,
  taskDetailsContent: null,
};

export default Layout;
