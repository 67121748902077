import React from "react";
import { connect } from "react-redux";
import Truncate from "react-truncated-component";
import PropTypes from "prop-types";

import { datadogRum } from "@datadog/browser-rum";
import {
  faCommentAlt,
  faGripVertical,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import getMiliSecondDuration from "analytics/helper";
import copy from "copy-to-clipboard";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import Moment from "moment";
import {
  Checkbox,
  Divider,
  Header,
  Icon,
  Label,
  List,
  Popup,
} from "semantic-ui-react";

import {
  EditClientTask,
  SetClientWorkflow,
} from "data/hub/clientWorkflows/actions";
import { EditTask } from "data/libs/tasks";

import { SetAlert } from "components/Alerts/actions";
import AssignedAdvisorPopup from "components/AssignedAdvisorPopup";
import { ToggleAssignedAdvisorPopup } from "components/AssignedAdvisorPopup/actions";
import { SetConfirmationModal } from "components/ConfirmationModal/actions";
import { FeatureFlag } from "components/FeatureFlag";
import { PrivacyModeContext } from "components/PrivacyMode/Context";
import {
  SetCommentTaskId,
  SetTaskComplete,
  SetTaskIncomplete,
} from "components/Task/actions";

import { UncontrolledAssigner as Assigner } from "scenes/Hubly/components/Workspace/components/Assigner";

import TaskCondition from "../TaskConfigurations/components/TaskCondition";
import TaskDetails from "../TaskConfigurations/components/TaskDetails";
import TaskEmailTemplate from "../TaskConfigurations/components/TaskEmailTemplate";
import TaskFutureReminder from "../TaskConfigurations/components/TaskFutureReminders";
import TaskPopupMenu from "../TaskConfigurations/components/TaskPopupMenu";
import TaskReminder from "../TaskConfigurations/components/TaskReminder";
import TaskTimeCompletedAt from "../TaskConfigurations/components/TaskTimeCompletedAt";
import TaskTimeEstimate from "../TaskConfigurations/components/TaskTimeEstimate";
import TaskTimeSpent from "../TaskConfigurations/components/TaskTimeSpent";

class TaskView extends React.Component {
  static contextType = PrivacyModeContext;

  static defaultProps = {
    client: {
      id: "",
      comments: [],
      householdId: "",
      reminders: [],
    },
    currentWorkflow: {
      id: "",
    },
    newCommentRef: {},
    advisorId: undefined,
  };

  static propTypes = {
    addLinkToTask: PropTypes.func.isRequired,
    addLinkToWorkflowTask: PropTypes.func.isRequired,
    currentWorkflow: PropTypes.shape({
      id: PropTypes.string,
    }),
    client: PropTypes.shape({
      id: PropTypes.string,
      comments: PropTypes.array,
      householdId: PropTypes.string,
      name: PropTypes.string,
      reminders: PropTypes.array,
    }),
    deleteLinkFromTask: PropTypes.func.isRequired,
    deleteLinkFromWorkflowTask: PropTypes.func.isRequired,
    dragHandleProps: PropTypes.object.isRequired,
    editLinkInTask: PropTypes.func.isRequired,
    editLinkInWorkflowTask: PropTypes.func.isRequired,
    editingStart: PropTypes.func.isRequired,
    editTaskDetails: PropTypes.func.isRequired,
    editTask: PropTypes.func.isRequired,
    editWorkflowTaskDetails: PropTypes.func.isRequired,
    isClientWorkflow: PropTypes.bool.isRequired,
    newCommentRef: PropTypes.object,
    selectedHub: PropTypes.shape({
      advisors: PropTypes.array.isRequired,
    }).isRequired,
    advisorId: PropTypes.string,
    setAlert: PropTypes.func.isRequired,
    setCommentTaskId: PropTypes.func.isRequired,
    setConfirmationModal: PropTypes.func.isRequired,
    setTaskComplete: PropTypes.func.isRequired,
    setTaskIncomplete: PropTypes.func.isRequired,
    task: PropTypes.object.isRequired,
    toggleTaskConditionPopup: PropTypes.func.isRequired,
    toggleTaskEmailTemplateModal: PropTypes.func.isRequired,
    toggleTaskFutureReminderPopup: PropTypes.func.isRequired,
    toggleTaskDetailsModal: PropTypes.func.isRequired,
    toggleTaskTimeCompletedAtModal: PropTypes.func.isRequired,
    toggleTaskTimeEstimatePopup: PropTypes.func.isRequired,
    toggleTaskTimeSpentPopup: PropTypes.func.isRequired,
    toggleAssignedAdvisorPopup: PropTypes.func.isRequired,
    flags: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      spentTimeOnTaskCompletion: 0,
    };
  }

  copyDescription = () => {
    const { setAlert, task } = this.props;
    const copyPlainText = (clipboardData) => {
      const node = document.createElement("div");
      node.innerHTML = task.description;
      clipboardData.setData("text/plain", node.innerText);
    };
    const success = copy(task.description, {
      format: "text/html",
      onCopy: copyPlainText,
    });
    if (success) {
      setAlert({
        text: "Description Copied to Clipboard",
        type: "success",
      });
    } else {
      setAlert({
        text: "Failed to copy description.",
        type: "error",
      });
    }
  };

  toggleCommentTask = () => {
    const { currentWorkflow, setCommentTaskId, task, newCommentRef } =
      this.props;
    setCommentTaskId(task.id, currentWorkflow.id);

    // scroll to the comment area, timeout is required to ensure that the new comment area has been rendered before you scroll to it
    setTimeout(() => {
      if (
        newCommentRef &&
        newCommentRef.current &&
        newCommentRef.current.children.length > 0
      ) {
        newCommentRef.current.children[0].scrollIntoView({
          behavior: "smooth",
        });
      }
    }, 200);
  };

  toggleAssigner = (open, advisedAssigneeId = undefined) => {
    const { task, toggleAssignedAdvisorPopup, advisorId } = this.props;
    toggleAssignedAdvisorPopup(task.id, open, "TASK", advisorId);
    this.setState({
      assignerOpen: open,
      advisedAssigneeId: open ? advisedAssigneeId : undefined,
    });
  };

  openEditAssigneePopup = () => {
    const { flags, task, advisorId } = this.props;
    if (flags["update-task-assignee-on-complete"] || false) {
      if (task.assignedAdvisorId !== advisorId) {
        this.toggleAssigner(true, advisorId);
      }
    }
  };

  toggleCompletion = () => {
    const { currentWorkflow, setTaskComplete, setTaskIncomplete, task } =
      this.props;
    if (!task.completed) {
      setTaskComplete(currentWorkflow, task.id, () => {
        datadogRum.addAction("hubly_timespent_taskview_taskcompletion", {
          duration: getMiliSecondDuration(this.state.spentTimeOnTaskCompletion),
        });
        this.setState(() => {
          return {
            expanded: false,
            spentTimeOnTaskCompletion: 0,
          };
        });
        this.openEditAssigneePopup();
      });
    } else {
      setTaskIncomplete(currentWorkflow, task.id);
    }
  };

  toggleTask = () => {
    this.setState({
      spentTimeOnTaskCompletion: new Date().getTime(),
    });
    const { task, setConfirmationModal } = this.props;

    if (!task.completed) {
      this.toggleCompletion();
      return;
    }

    const markTaskIncomplete = () => {
      this.toggleCompletion();
    };

    const params = {
      title: "Uncheck Task",
      message: `This task was marked completed on
        ${Moment(task.completedAt).format("MMM Do, YYYY [at] h:mm a")}.
        Are you sure you want to remove this timestamp?`,
      icon: "square outline",
      buttons: [
        {
          text: "Cancel",
        },
        {
          text: "Confirm",
          callBack: markTaskIncomplete,
          color: "red",
        },
      ],
    };

    setConfirmationModal(params);
  };

  copyURL = (url) => {
    const { setAlert } = this.props;
    const success = copy(url);
    if (success) {
      setAlert({
        text: "Link Copied to Clipboard",
        type: "success",
      });
    } else {
      setAlert({
        text: "Failed to copy client upload link.",
        type: "error",
      });
    }
  };

  showAssignAdvisorMenu = () => {
    const { selectedHub } = this.props;
    return selectedHub.advisors.length >= 2;
  };

  taskHasNonClientHouseholdMember = () => {
    const { client, task } = this.props;
    return task.householdMembers.find((householdMember) => {
      return householdMember.client.id !== client.id;
    });
  };

  taskHasDescription = () => {
    const { task } = this.props;
    return task.description && task.description.length > 0;
  };

  taskHasLink = () => {
    const { task } = this.props;
    return task.links && task.links.length > 0;
  };

  taskCommentMarkup = () => {
    const { task } = this.props;
    if (task.comments?.length > 0) {
      return (
        <FontAwesomeIcon
          data-test={`task-has-comment-${task.title}`}
          className="clickable"
          color="grey"
          icon={faCommentAlt}
          onClick={() => {
            // scroll to comment with the element id set to the task id
            const element = document.getElementById(task.id);
            if (element) {
              element.scrollIntoView({ behavior: "smooth" });
            }
          }}
          style={{ marginTop: "0.2em" }}
          title="This task has an associated comment"
        />
      );
    } else {
      return (
        <Icon.Group
          data-test={`task-add-comment-${task.title}`}
          className="show_on_hover dark_grey_on_hover"
          style={{ fontSize: "13pt" }}
          onClick={() => {
            this.toggleCommentTask();
          }}
          title="Comment"
        >
          <FontAwesomeIcon
            className="clickable"
            icon={faCommentAlt}
            style={{ paddingTop: "0.1em" }}
          />
          <Icon
            link
            corner
            name="add"
            style={{ color: "#aaaaaa", textShadow: "none" }}
          />
        </Icon.Group>
      );
    }
  };

  taskLinkMarkup = () => {
    const { task } = this.props;
    return (
      <List bulleted style={{ padding: 0 }}>
        {task.links.map((link) => {
          const isLinkProtocol = link.url.includes("://");
          return (
            <List.Item style={{ display: "flex" }}>
              <div
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                <a
                  href={isLinkProtocol ? link.url : `//${link.url}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {link.title || link.url}
                </a>
                {link.title && (
                  <span style={{ color: "grey" }}>
                    ,&nbsp;
                    {link.url}
                  </span>
                )}
              </div>
              <Icon
                color="grey"
                link
                name="copy outline"
                title="Copy Link"
                style={{ marginRight: "0.5em", marginLeft: "0.5em" }}
                onClick={() => {
                  this.copyURL(link.url);
                }}
              />
            </List.Item>
          );
        })}
      </List>
    );
  };

  taskHouseholdMemberMarkup = (nonClientHouseholdMember) => {
    const { piiMask } = this.context;

    return (
      <div>
        This task is for the following household member
        <Label
          key={nonClientHouseholdMember.id}
          className={piiMask("fs-block dd-privacy-mask")}
          color="grey"
          style={{ fontSize: "10px", marginRight: "4px", marginLeft: "4px" }}
        >
          {`${nonClientHouseholdMember.client.name}`}
        </Label>
      </div>
    );
  };

  taskTextMarkup = () => {
    const { task, editingStart } = this.props;
    const textStyle = task.completed
      ? {
          textDecorationLine: "line-through",
          textDecorationStyle: "solid",
          color: "grey",
        }
      : {};
    return (
      <div onClick={editingStart} style={textStyle} className="editable">
        <Truncate ellipsis="..." numberOfLines={1} lineHeight={22}>
          <p>{task.title}</p>
        </Truncate>
      </div>
    );
  };

  // Yuck
  // Make the children a tags have absolute links... or at least try to
  makeLinksAbsolute = (HTMLString) => {
    const root = document.createElement("span");
    root.innerHTML = HTMLString;
    const links = root.getElementsByTagName("a");
    links.forEach((l) => {
      try {
        const href = l.attributes.href.nodeValue;
        if (!href.includes("://")) {
          l.attributes.href.nodeValue = `//${href}`;
        }
      } catch (e) {
        console.error(e);
      }
    });
    return root.innerHTML;
  };

  render() {
    const {
      client,
      currentWorkflow,
      dragHandleProps,
      isClientWorkflow,
      newCommentRef,
      task,
      editTask,
    } = this.props;
    const { expanded, assignerOpen, advisedAssigneeId } = this.state;
    const { piiMask } = this.context;

    const nonClientHouseholdMember = this.taskHasNonClientHouseholdMember();
    const iconGridStyle = {
      display: "flex",
      flexGrow: 1,
      width: "15px",
      padding: "0",
      marginTop: "3px",
    };
    const showAssignAdvisor = this.showAssignAdvisorMenu();
    let leftSideMinWidth = 75;
    if (!isClientWorkflow) {
      leftSideMinWidth -= 25;
    }
    if (!showAssignAdvisor) {
      leftSideMinWidth -= 30;
    }

    const description = this.makeLinksAbsolute(task.description);

    return (
      <div
        className="grey_on_hover"
        style={{
          display: "flex",
          flexDirection: "column",
          borderRadius: "5px",
          padding: "0.25em 0.5em",
        }}
        data-test={`workflow-test-${task.title}`}
      >
        <div style={{ display: "flex", flexFlow: "row" }}>
          <div style={{ minWidth: leftSideMinWidth }}>
            <div style={{ display: "flex", flexFlow: "row" }}>
              <div {...dragHandleProps} style={iconGridStyle}>
                <FontAwesomeIcon
                  className="dark_grey_on_hover"
                  color="rgb(218, 218, 218)"
                  icon={faGripVertical}
                  style={{ marginTop: "0.1em", marginLeft: "0.5em" }}
                />
              </div>
              {isClientWorkflow && (
                <div style={iconGridStyle}>
                  <Checkbox
                    onClick={this.toggleTask}
                    checked={task.completed}
                    data-test={`workflow-task-checkbox-${task.title}`}
                  />
                </div>
              )}
              {showAssignAdvisor && (
                <div style={iconGridStyle}>
                  <FeatureFlag id="service-team-settings">
                    <Assigner
                      onClick={() => {
                        this.toggleAssigner(true);
                      }}
                      onClose={() => {
                        this.toggleAssigner(false);
                      }}
                      isOpen={assignerOpen}
                      size="small"
                      advisedAssigneeId={advisedAssigneeId}
                      currentAssignee={task.assignee}
                      onAssign={async (id) => {
                        return editTask(
                          task.id,
                          id === "unassigned" ? null : id
                        );
                      }}
                    />
                  </FeatureFlag>
                  <FeatureFlag id="service-team-settings" showOnDisabled>
                    <AssignedAdvisorPopup
                      currentObject={task}
                      currentObjectType="TASK"
                    />
                  </FeatureFlag>
                </div>
              )}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexGrow: 1,
              margin: "0.2em 0.5em 0 0.5em",
            }}
          >
            {this.taskTextMarkup()}
          </div>
          <div>
            {isClientWorkflow && task.completed && (
              <div
                style={{
                  display: "flex",
                  flexGrow: 1,
                  padding: "0",
                  marginRight: "0.5em",
                }}
              >
                <TaskTimeCompletedAt task={task} />
              </div>
            )}
            {(task.isClientTask || nonClientHouseholdMember) &&
              isClientWorkflow &&
              !task.completed && (
                <div
                  style={{
                    display: "flex",
                    flexGrow: 1,
                    justifyContent: "flex-end",
                    marginRight: "0.5em",
                    padding: "0",
                    width: 125,
                  }}
                >
                  <Popup
                    content="The client is responsible for completing this task"
                    on="hover"
                    position="top center"
                    disabled={!task.isClientTask}
                    style={{ whiteSpace: "nowrap" }}
                    trigger={
                      <Label
                        className={piiMask("fs-block dd-privacy-mask")}
                        color="grey"
                        style={{
                          fontSize: "10px",
                          marginRight: "4px",
                          marginLeft: "4px",
                        }}
                      >
                        {task.isClientTask && <Icon name="user" />}
                        {`${
                          nonClientHouseholdMember
                            ? nonClientHouseholdMember.client.name
                            : client.name
                        }`}
                      </Label>
                    }
                  />
                </div>
              )}
          </div>
          <div style={{ minWidth: 120 }}>
            <div style={{ display: "flex", flexFlow: "row" }}>
              <div style={iconGridStyle}>
                {isClientWorkflow ? (
                  <TaskReminder client={client} task={task} />
                ) : (
                  <TaskFutureReminder task={task} workflow={currentWorkflow} />
                )}
              </div>
              <div style={iconGridStyle}>
                <TaskEmailTemplate
                  client={client}
                  workflow={currentWorkflow}
                  task={task}
                  emailToField={!!isClientWorkflow}
                />
              </div>
              <div style={iconGridStyle}>
                {isClientWorkflow ? (
                  <TaskTimeSpent task={task} />
                ) : (
                  <TaskTimeEstimate task={task} workflow={currentWorkflow} />
                )}
              </div>
              <div style={iconGridStyle}>
                <TaskDetails
                  client={client}
                  currentWorkflow={currentWorkflow}
                  isClientWorkflow={isClientWorkflow}
                  expanded={expanded}
                  task={task}
                  toggleExpand={() => {
                    this.setState((state) => {
                      return { expanded: !state.expanded };
                    });
                  }}
                />
              </div>
              {isClientWorkflow && (
                <div style={iconGridStyle}>{this.taskCommentMarkup()}</div>
              )}
              {!isClientWorkflow && (
                <div style={iconGridStyle}>
                  <TaskCondition task={task} workflow={currentWorkflow} />
                </div>
              )}
              <div style={iconGridStyle}>
                <TaskPopupMenu
                  currentWorkflow={currentWorkflow}
                  isClientWorkflow={isClientWorkflow}
                  newCommentRef={newCommentRef}
                  task={task}
                />
              </div>
            </div>
          </div>
        </div>
        {expanded && (
          <div
            className="taskDescriptionDiv"
            style={{
              marginTop: "0.25em",
              marginLeft: "5em",
              marginRight: "2em",
            }}
          >
            {this.taskHasDescription() && (
              <div style={{ display: "flex" }}>
                <div
                  className="ql-editor display_description"
                  style={{ color: "grey", fontSize: "10pt" }}
                  dangerouslySetInnerHTML={{ __html: description }}
                />
                <div
                  style={{
                    marginLeft: "auto",
                    marginTop: "0.5em",
                    marginRight: "0.75em",
                  }}
                >
                  <Icon
                    color="grey"
                    link
                    name="copy outline"
                    title="Copy Description"
                    onClick={() => {
                      this.copyDescription();
                    }}
                  />
                </div>
              </div>
            )}
            {(task.isClientTask || nonClientHouseholdMember) && (
              <React.Fragment>
                {this.taskHasDescription() && (
                  <Divider style={{ margin: "0.5em 0em 0.5em 0em" }} />
                )}
                <Header as="h5" style={{ margin: "0.25em 0" }}>
                  Client Details
                </Header>
                <List bulleted style={{ padding: 0 }}>
                  {task.isClientTask && (
                    <List.Item>
                      The client is responsible for completing this task.
                    </List.Item>
                  )}
                  {nonClientHouseholdMember && (
                    <List.Item>
                      {this.taskHouseholdMemberMarkup(nonClientHouseholdMember)}
                    </List.Item>
                  )}
                </List>
              </React.Fragment>
            )}
            {this.taskHasLink() > 0 && (
              <React.Fragment>
                {(this.taskHasDescription() ||
                  task.isClientTask ||
                  nonClientHouseholdMember) && (
                  <Divider style={{ margin: "0.5em 0em 0.5em 0em" }} />
                )}
                {this.taskLinkMarkup()}
              </React.Fragment>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedHub: state.hubly.data.hub.selected.hub,
    advisorId: state.hubly.data.advisor?.id,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    setTaskComplete: (workflow, taskId, onSuccess) => {
      dispatch(SetTaskComplete(workflow, taskId, null, onSuccess));
    },
    setConfirmationModal: (id) => {
      dispatch(SetConfirmationModal(id));
    },
    setTaskIncomplete: (workflow, taskId) => {
      dispatch(SetTaskIncomplete(workflow, taskId));
    },
    setAlert: (alert) => {
      dispatch(SetAlert(alert));
    },
    setClientWorkflow: (clientWorkflow) => {
      dispatch(SetClientWorkflow(clientWorkflow));
    },
    setCommentTaskId: (taskId, clientWorkflowId) => {
      dispatch(SetCommentTaskId(taskId, clientWorkflowId));
    },
    editTask: async (taskId, assigneeId) => {
      const task = await EditTask(taskId, { assigneeId });
      dispatch(EditClientTask(task));
    },
    toggleAssignedAdvisorPopup: (
      taskId,
      isOpen,
      type,
      defaultDropdownValue
    ) => {
      dispatch(
        ToggleAssignedAdvisorPopup(taskId, isOpen, type, defaultDropdownValue)
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(withLDConsumer()(TaskView));
