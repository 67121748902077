import React from "react";
import PropTypes from "prop-types";
import { v4 as uuid } from "uuid";

export function Name({ fullName, suffixClassName }) {
  if (!fullName) return null;

  const [prefix, ...suffix] = fullName.split(" ").filter((t) => {
    return t !== "";
  });

  return (
    <span data-test="name">
      <span data-test="name-prefix">{prefix}</span>
      {suffix.map((s, i) => {
        return (
          <span
            data-test="name-suffix"
            key={uuid()}
            className={suffixClassName}
          >
            {` ${s}`}
          </span>
        );
      })}
    </span>
  );
}

Name.propTypes = {
  fullName: PropTypes.string,
  suffixClassName: PropTypes.string,
};

Name.defaultProps = {
  fullName: undefined,
  suffixClassName: undefined,
};

export default Name;
