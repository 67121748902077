import React from "react";
import PropTypes from "prop-types";

import { Header } from "semantic-ui-react";

export function Layout({ hasTimeEstimates, children }) {
  return (
    <div style={{ width: "max-content" }}>
      <div style={{ display: "flex" }}>
        <Header as="h4">
          {hasTimeEstimates ? "Edit Time Estimate" : " Add Time Estimate"}
        </Header>
      </div>
      {children}
    </div>
  );
}

Layout.propTypes = {
  hasTimeEstimates: PropTypes.bool,
  children: PropTypes.node,
};

Layout.defaultProps = {
  children: null,
  hasTimeEstimates: false,
};

export default Layout;
