import { useCallback, useEffect, useState } from "react";

import { useQuery } from "hooks";

import getClientsByServiceTeam from "./query";

const useManageClient = ({ serviceTeamId }) => {
  const [condition, setCondition] = useState({ page: 0, pageSize: 25 });

  const updateCondition = useCallback((newCondition) => {
    setCondition((prevCondition) => {
      return { ...prevCondition, ...newCondition };
    });
  }, []);

  const queryFactory = useCallback(() => {
    return getClientsByServiceTeam({ ...condition, serviceTeamId });
  }, [serviceTeamId, condition]);

  const {
    loading,
    data: { clients, totalCount },
    reloadData,
  } = useQuery(queryFactory);

  useEffect(() => {
    reloadData();
  }, [condition, reloadData]);

  return {
    condition: condition,
    updateCondition: updateCondition,
    loading: loading,
    totalCount: totalCount,
    clients: clients || [],
  };
};

export default useManageClient;
