export const getDefaultMeetingDateType = (meetingDateTypes = []) => {
  return (
    meetingDateTypes?.find(({ name }) => {
      return name === "Client Meeting";
    }) || (meetingDateTypes?.length > 0 ? meetingDateTypes[0] : undefined)
  );
};

export const filterMeetingDateTypes = (
  meetingDateTypes = [],
  searchQuery = ""
) => {
  return meetingDateTypes.filter(({ name }) => {
    return name
      .toLocaleLowerCase()
      .includes(searchQuery?.trim()?.toLocaleLowerCase());
  });
};

export const findMeetingDateTypeById = (meetingDateTypes, searchId) => {
  return meetingDateTypes?.find(({ id }) => {
    return id === searchId;
  });
};

export default getDefaultMeetingDateType;
