import { API } from "aws-amplify";

import { getAPIReq } from "data/libs/authLib";

import toGlobalId from "./helper";

export const getTags = async ({
  hubId,
  searchText = "",
  hasClients = null,
  first = 100,
  after = "",
}) => {
  const req = await getAPIReq(
    {
      query: `query getTags($searchText: String, $hasClients: Boolean, $first: Int, $after: String) {
            searchTags(searchText: $searchText, hasClients: $hasClients, first: $first, after: $after) {
              edges {
                node {
                  uuid
                  name
                  externalId
                }
              }
              pageInfo {
                hasNextPage
                endCursor
              }
            }
          }          
            `,
      variables: {
        searchText: searchText,
        hasClients: hasClients,
        first: first,
        after: after,
      },
    },
    { hub: hubId }
  );

  const {
    data: { searchTags },
  } = await API.post("HublyAPI", `/graphql`, req);
  return {
    data: searchTags.edges.map(({ node }) => {
      return {
        ...node,
        id: node.uuid,
      };
    }),
    pageInfo: searchTags.pageInfo,
  };
};

export const getTag = async ({ hubId, tagId }) => {
  const req = await getAPIReq(
    {
      query: `query getTag($tagId: ID!) {
        tag(id: $tagId) {
          uuid
          name
        }
      }`,
      variables: {
        tagId: toGlobalId("Tag", tagId),
      },
    },
    { hub: hubId }
  );

  const {
    data: { tag },
  } = await API.post("HublyAPI", `/graphql`, req);
  return {
    ...tag,
    id: tag.uuid,
  };
};

export const getClientsInTag = async ({
  hubId,
  iDs,
  householdTitle = null,
  pageSize = 100,
  after = "",
  offset = null,
}) => {
  const req = await getAPIReq(
    {
      query: `query getClientsInTag($tagIds: [UUID], $householdTitle: String, $first: Int, $after: String, $offset: Int) {
          clients(tags_Id_In: $tagIds, householdMember_Title:$householdTitle, first: $first, after: $after, offset: $offset) {
            edges {
              node {
                id
                uuid
                firstName
                lastName
                name
                household{
                  uuid
                }
                tags {
                  edges {
                    node {
                      id
                      uuid
                      name
                    }
                  }
                }
              }
            }
            totalCount
            pageInfo {
              hasNextPage
              endCursor
            }
          }
        }`,
      variables: {
        tagIds: iDs,
        householdTitle: householdTitle,
        first: pageSize,
        after: after,
        offset: offset,
      },
    },
    { hub: hubId }
  );

  const {
    data: { clients },
  } = await API.post("HublyAPI", `/graphql`, req);
  return {
    data: clients.edges.map(({ node }) => {
      return {
        ...node,
        id: node.uuid,
        household: node.household
          ? {
              id: node.household.uuid,
            }
          : {},
        name: node.name ? node.name : `${node.firstName} ${node.lastName}`,
        tags: node.tags.edges.map(({ node: tNode }) => {
          return {
            ...tNode,
            id: tNode.uuid,
          };
        }),
      };
    }),
    pageInfo: clients.pageInfo,
    totalCount: clients.totalCount,
  };
};

export const getTagCountHouseholdClient = async ({
  hubId,
  tagId,
  excludeWorkflowId,
}) => {
  const req = await getAPIReq(
    {
      query: ` query getTagCountHouseholdClient($tagId: ID!, $excludeWorkflowId: ID!){
        tagClientHouseholdCount(tagId: $tagId, excludeWorkflowId: $excludeWorkflowId) {
            numHouseholdHead
            numClient
            }
        }`,
      variables: {
        tagId: toGlobalId("Tag", tagId),
        excludeWorkflowId: toGlobalId("Workflow", excludeWorkflowId),
      },
    },
    { hub: hubId }
  );

  const {
    data: { tagClientHouseholdCount },
  } = await API.post("HublyAPI", `/graphql`, req);
  return tagClientHouseholdCount;
};

export default getTags;
