import useFlags from "./useFlags";

export function useFeatures(id, enableOnMissing = false) {
  const { loadingConfig, flags } = useFlags();

  if (loadingConfig) return enableOnMissing;
  if (id in flags) return flags[id];
  return enableOnMissing;
}

export default useFeatures;
