import React, { useCallback } from "react";
import PropTypes from "prop-types";

import useHub from "data/hub/useHub";

import {
  createCache,
  createClient,
  QueryClientProvider,
} from "components/QueryClient";

export function HublyQueryClientProvider({ children }) {
  const hub = useHub();

  const factory = useCallback(() => {
    return createCache(createClient({ hub: hub?.hubId }));
  }, [hub]);

  return (
    <QueryClientProvider clientFactory={factory}>
      {children}
    </QueryClientProvider>
  );
}

HublyQueryClientProvider.propTypes = {
  children: PropTypes.node,
};

HublyQueryClientProvider.defaultProps = {
  children: null,
};

export default HublyQueryClientProvider;
