import React, { useState } from "react";
import PropTypes from "prop-types";

import { faGripVertical } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Icon, Input, Segment } from "semantic-ui-react";

import { useBooleanState } from "hooks";
import { ConfirmationModal } from "components/ConfirmationModal";
import { useDraggable } from "components/DragDrop";
import DropdownWithIcon from "components/DropdownWithIcon";

export function ListTile({
  item,
  isEditable,
  inputPlaceholder,
  onClickSave,
  onClickDelete,
  onChangeOption,
}) {
  const { id, name, isProcessing, icon = "users" } = item;

  const [isEditing, enableEditMode, disableEditMode] = useBooleanState();
  const [confirmDelete, openDeleteConfirm, closeDeleteConfirm] =
    useBooleanState();
  const [input, setInput] = useState(name);
  const { draggableProps, dragHandleProps } = useDraggable();

  return (
    <div
      {...draggableProps}
      style={{ padding: "10px 0", ...draggableProps.style }}
    >
      <Segment
        key={id}
        style={{
          display: "grid",
          gridTemplateColumns: "3% 4% 42% 48% 3%",
          alignItems: "center",
        }}
      >
        <div data-test="draggable-element" {...dragHandleProps}>
          <FontAwesomeIcon
            className="dark_grey_on_hover"
            color="rgb(218, 218, 218)"
            icon={faGripVertical}
            style={{
              marginRight: "1em",
            }}
          />
        </div>

        <div>
          <Icon color="grey" name={icon} />
        </div>
        {isEditing ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Input
              placeholder={inputPlaceholder}
              autoComplete="off"
              value={input}
              onChange={(_, { value }) => {
                setInput(value);
              }}
              style={{ width: "220px", marginRight: "0.5em" }}
              autoFocus
            />
            <Button
              positive
              content="Save"
              disabled={isProcessing || !input || input === name}
              onClick={() => {
                onClickSave(item, input).finally(disableEditMode);
              }}
              loading={isProcessing}
              compact
            />
            <Icon
              link
              color="grey"
              name="delete"
              onClick={disableEditMode}
              size="large"
            />
          </div>
        ) : (
          <div
            data-test="list-tile"
            className="overflow_ellipsis"
            style={{
              fontWeight: "bold",
              marginRight: "0.5em",
              cursor: isEditable ? "pointer" : "default",
            }}
            onClick={() => {
              if (isEditable) enableEditMode();
            }}
          >
            {name}
          </div>
        )}
        <div data-test="syncable-name">
          <DropdownWithIcon
            disabled={item.isProcessing}
            selected={item.selected}
            options={item.options}
            onChange={(value) => {
              return onChangeOption(item, value);
            }}
          />
        </div>

        <div data-test="remove-role" style={{ marginLeft: "auto" }}>
          <Button
            icon="trash alternate outline"
            size="mini"
            basic
            disabled={isProcessing}
            loading={isProcessing}
            onClick={openDeleteConfirm}
          />
        </div>
      </Segment>
      {confirmDelete && (
        <ConfirmationModal
          title="Remove Role from Service Teams"
          message="Do you want to remove this role from all service teams? This cannot be undone."
          icon="delete"
          buttons={[
            {
              text: "Cancel",
              callBack: closeDeleteConfirm,
            },
            {
              text: `Remove Role`,
              color: "red",
              callBack: () => {
                closeDeleteConfirm();
                onClickDelete(item);
              },
            },
          ]}
        />
      )}
    </div>
  );
}

ListTile.propTypes = {
  inputPlaceholder: PropTypes.string,
  isEditable: PropTypes.bool,
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    icon: PropTypes.string,
    isProcessing: PropTypes.bool,
    selected: PropTypes.object,
    options: PropTypes.array,
  }).isRequired,
  onClickSave: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  onChangeOption: PropTypes.func.isRequired,
};

ListTile.defaultProps = {
  inputPlaceholder: "",
  isEditable: true,
};

export default ListTile;
