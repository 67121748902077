export const OpenWealthboxIntegrationModal = () => {
  return {
    type: "OPEN_WEALTHBOX_INTEGRATION_MODAL",
  };
};
export const CloseWealthboxIntegrationModal = () => {
  return {
    type: "CLOSE_WEALTHBOX_INTEGRATION_MODAL",
  };
};
