import React from "react";

import { useQuery } from "hooks";

import CRMIcon from "../../CRMIcon";
import { CrmAdvisorMatcher } from "../components";
import { getServiceTeams } from "./query";
import useDeleteServiceTeamAction from "./useDeleteServiceTeamAction";

export function useManageServiceTeamsTable() {
  const { loading, data, reloadData } = useQuery(getServiceTeams);
  const deleteServiceTeam = useDeleteServiceTeamAction(reloadData);

  const createTableData = ([serviceTeams, serviceRoles, hubIntegration]) => {
    const defaultSettingsTeam = serviceTeams.find((team) => {
      return team.isDefaultSettings;
    });

    const headerRow = serviceRoles.map(({ name }) => {
      return name;
    });

    const toCell = (member, i, roles, style) => {
      return (
        <div style={style} key={member.id}>
          {member.name || "Unassigned"}
          {i + 1 !== roles.length && ","}
          <CrmAdvisorMatcher member={member} onUpdated={reloadData} />
        </div>
      );
    };

    const byRole = (role) => {
      return ({ serviceRole }) => {
        return serviceRole.name === role;
      };
    };

    const toMembers = (serviceTeamMembers) => {
      return (role) => {
        const roleMembers = serviceTeamMembers.filter(byRole(role));

        if (roleMembers.length > 0) {
          return roleMembers.map((member, i, roles) => {
            return toCell(member, i, roles);
          });
        }

        return defaultSettingsTeam.serviceTeamMembers
          .filter(byRole(role))
          .map((member, i, roles) => {
            return toCell(member, i, roles, { color: "#c4c4c4" });
          });
      };
    };

    const toHeaderWithIcon = ({ name, isSynced }) => {
      return hubIntegration.length === 1 && isSynced ? (
        <React.Fragment>
          <CRMIcon crmType={hubIntegration[0]} />
          {name}
        </React.Fragment>
      ) : (
        name
      );
    };

    const setActions = ({
      totalClients,
      id: serviceTeamId,
      name,
      isDefaultSettings,
    }) => {
      const actions = [];

      if (!totalClients && !isDefaultSettings) {
        actions.push({
          icon: "trash alternate",
          content: "Delete",
          confirm: {
            title: "Are you sure?",
            message:
              "Are you sure you want to delete this service team? This cannot be undone.",
            buttons: [
              {
                color: "red",
                text: "Delete Team",
                callBack: (e) => {
                  deleteServiceTeam(serviceTeamId, name);
                },
              },
            ],
          },
        });
      }

      return actions;
    };

    const toTeams = () => {
      return ({
        name,
        numActiveClients,
        totalClients,
        serviceTeamMembers,
        id,
        isDefaultSettings,
      }) => {
        return {
          cells: [name].concat(headerRow.map(toMembers(serviceTeamMembers))),
          numActiveClients: numActiveClients,
          totalClients: totalClients,
          id: id,
          actions: setActions({
            totalClients,
            id,
            name,
            isDefaultSettings,
          }),
          roleHasMoreThanOneMember: headerRow.some((role) => {
            return serviceTeamMembers.filter(byRole(role)).length > 1;
          }),
          isDefaultSettings: isDefaultSettings,
        };
      };
    };

    return {
      teams: serviceTeams.map(toTeams()),
      headerRow: ["Team Name"]
        .concat(serviceRoles.map(toHeaderWithIcon))
        .concat(["", ""]),
    };
  };

  const [serviceTeams = [], serviceRoles = []] = data;
  const tableData =
    data.length === 0 ? { headerRow: [], teams: [] } : createTableData(data);

  return {
    ...tableData,
    loading: loading,
    reloadData: reloadData,
    serviceRoles: serviceRoles,
    serviceTeams: serviceTeams,
    teamRoleHasMultipleMember: tableData.teams.some(
      ({ roleHasMoreThanOneMember }) => {
        return roleHasMoreThanOneMember;
      }
    ),
  };
}

export default useManageServiceTeamsTable;
