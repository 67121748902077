import React, { useState } from "react";
import PropTypes from "prop-types";

import { Button, Dropdown } from "semantic-ui-react";

export function ConfirmationDropdown({
  items,
  initValue,
  loading,
  forceEnabled,
  placeholder,
  buttonLabel,
  onSelect,
  showSaveButton,
  ...props
}) {
  const [selectedItem, setSelectedItem] = useState(initValue);

  const checkDisable = () => {
    if (forceEnabled) return false;

    return (
      initValue === selectedItem &&
      items.some(({ key }) => {
        return initValue === key;
      })
    );
  };

  return (
    <React.Fragment>
      <Dropdown
        defaultValue={initValue}
        onChange={(_, { value }) => {
          if (showSaveButton) setSelectedItem(value);
          else onSelect(value);
        }}
        options={items}
        disabled={loading}
        loading={loading}
        searchInput={{ autoFocus: true }}
        fluid
        search
        placeholder={placeholder}
        selection
        selectOnBlur={false}
        style={{ minWidth: "200px" }}
        {...props}
      />
      {showSaveButton && (
        <Button
          disabled={loading || checkDisable()}
          loading={loading}
          fluid
          positive
          style={{ marginTop: "1em" }}
          onClick={(e) => {
            e.stopPropagation();
            onSelect(selectedItem);
          }}
        >
          {buttonLabel}
        </Button>
      )}
    </React.Fragment>
  );
}

ConfirmationDropdown.propTypes = {
  items: PropTypes.array,
  placeholder: PropTypes.string,
  buttonLabel: PropTypes.string,
  loading: PropTypes.bool,
  forceEnabled: PropTypes.bool,
  initValue: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  showSaveButton: PropTypes.bool,
};

ConfirmationDropdown.defaultProps = {
  items: [],
  buttonLabel: "Save",
  placeholder: undefined,
  loading: false,
  forceEnabled: false,
  showSaveButton: true,
};

export default ConfirmationDropdown;
