export const reminderOptions = [
  {
    color: "#ed6e6e",
    icon: "circle",
    key: "Overdue",
    text: "Overdue Reminders",
    type: "reminder",
    value: "Overdue",
  },
  {
    color: "#f4cf65",
    icon: "circle",
    key: "Today",
    text: "Reminders Due Today",
    type: "reminder",
    value: "Today",
  },
  {
    color: "#49b676",
    icon: "circle",
    key: "Upcoming",
    text: "Upcoming Reminders",
    type: "reminder",
    value: "Upcoming",
  },
  {
    color: "#c4c4c4",
    icon: "clock",
    key: "ThisWeek",
    text: "Reminders Due This Week",
    type: "reminder",
    value: "ThisWeek",
  },
  {
    color: "#c4c4c4",
    icon: "clock",
    key: "NextWeek",
    text: "Reminders Due Next Week",
    type: "reminder",
    value: "NextWeek",
  },
  {
    color: "#c4c4c4",
    icon: "bell slash",
    key: "NoReminder",
    text: "No Reminders Set",
    type: "reminder",
    value: "NoReminder",
  },
  {
    color: "#c4c4c4",
    icon: "user",
    key: "WaitingOnClient",
    text: "Waiting On Client",
    type: "workflows",
    value: "WaitingOnClient",
  },
  {
    color: "#c4c4c4",
    icon: "user",
    key: "NotWaitingOnClient",
    text: "Not Waiting On Client",
    type: "workflows",
    value: "NotWaitingOnClient",
  },
];

export const filterReminder = ({ key, type }, reminderTypes) => {
  if (type === "workflows") {
    if (
      !reminderTypes.includes("WaitingOnClient") &&
      !reminderTypes.includes("NotWaitingOnClient")
    )
      return true;
    return !!(key.includes("WaitingOnClient") && reminderTypes.includes(key));
  }
  return true;
};

export default reminderOptions;
