import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { SetWorkflowOptions } from "data/hub/workflows/actions";

import MultipleTimePerClientOption from "./MultipleTimePerClientOption";

function WorkflowOptions({ options, setToggle }) {
  return <MultipleTimePerClientOption onSetToggle={setToggle} {...options} />;
}

WorkflowOptions.propTypes = {
  options: PropTypes.object,
  setToggle: PropTypes.func.isRequired,
};

WorkflowOptions.defaultProps = {
  options: {},
};

const mapStateToProps = (state, { workflowId }) => {
  return {
    options: state?.hubly?.data?.hub?.workflows?.[workflowId]?.options,
  };
};

const mapDispatchToProps = (dispatch, { workflowId }) => {
  return {
    setToggle: (optionsUpdate) => {
      dispatch(SetWorkflowOptions(optionsUpdate, workflowId));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowOptions);
