import React from "react";
import PropTypes from "prop-types";

import Menu from "components/Menu";

export function ReminderOptions({
  children,
  onReschedule,
  onOpenClientCard,
  onDismiss,
  onCompleteTask,
}) {
  return (
    <Menu
      data-test="hubfeed-reminder-item-popup"
      position="left center"
      trigger={
        <div className="clickable grey_on_hover" style={{ display: "flex" }}>
          {children}
        </div>
      }
    >
      {({ closeMenu }) => {
        return (
          <React.Fragment>
            <Menu.Button
              icon="user"
              content="View Client Card"
              onClick={() => {
                onOpenClientCard();
                closeMenu();
              }}
            />
            <Menu.Button
              icon="clock"
              content="Reschedule Reminder"
              onClick={() => {
                onReschedule();
                closeMenu();
              }}
            />
            {onCompleteTask && (
              <Menu.Button
                icon="check circle"
                content="Complete Task"
                onClick={() => {
                  onCompleteTask();
                  closeMenu();
                }}
              />
            )}
            <Menu.Button
              icon="delete"
              content="Dismiss Reminder"
              onClick={() => {
                onDismiss();
                closeMenu();
              }}
            />
          </React.Fragment>
        );
      }}
    </Menu>
  );
}

ReminderOptions.propTypes = {
  children: PropTypes.node,
  onReschedule: PropTypes.func.isRequired,
  onOpenClientCard: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  onCompleteTask: PropTypes.func,
};

ReminderOptions.defaultProps = {
  children: null,
  onCompleteTask: undefined,
};

export default ReminderOptions;
