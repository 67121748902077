import { filter, get, isEmpty } from "lodash";

import * as yup from "yup";

yup.addMethod(yup.array, "uniqueProperty", function (propertyPath, message) {
  return this.test("unique", "", function (list) {
    const errors = [];

    list.forEach((item, index) => {
      const propertyValue = get(item, propertyPath);

      if (
        propertyValue &&
        filter(list, [propertyPath, propertyValue]).length > 1
      ) {
        errors.push(
          this.createError({
            path: `${this.path}[${index}].${propertyPath}`,
            message: message,
          })
        );
      }
    });

    if (!isEmpty(errors)) {
      throw new yup.ValidationError(errors);
    }

    return true;
  });
});

export default yup;
