import { combineReducers } from "redux";

import { reducer as clientsReducer } from "data/hub/clientCard/reducer";

import { reducer as hubModalsReducer } from "./components/HubModals/reducer";
import { reducer as profileReducer } from "./components/Profile/reducer";
import { reducer as workflowRulesReducer } from "./components/Workflows/components/WorkflowRules/reducer";

export const reducer = combineReducers({
  clients: clientsReducer,
  profile: profileReducer,
  hubModals: hubModalsReducer,
  workflowRules: workflowRulesReducer,
});
export default reducer;
