import React from "react";
import connect from "react-redux/es/connect/connect";
import { withRouter } from "react-router";
import PropTypes from "prop-types";

import { SetAdvisor } from "data/advisor/actions";

import { SetAlert } from "components/Alerts/actions";
import Digest from "components/Digest";

import useNewHublyApp from "scenes/Hubly/useNewHublyApp";

import { ContactSearch as NewContactSearch } from "../../Workspace/components";
import {
  HubContextProvider,
  ToasterContextProvider,
} from "../../Workspace/Provider";
import CannyChangelogButton from "./CannyChangelogButton";
import ContactSearch from "./ContactSearch";
import NavigationDropdown from "./NavigationDropdown";

export function WorkFlowsMenu({
  advisor,
  hub,
  loading,
  setAdvisor,
  fireToaster,
}) {
  const { loadingConfig, newApp } = useNewHublyApp();

  if (loadingConfig) {
    return null;
  }

  const integration = !hub
    ? null
    : advisor.integrations.find((i) => {
        return i.hubId === hub.id;
      });

  const showAllClientDropdopwn = !loading && hub && hub.id;
  return (
    <div
      style={{
        marginLeft: "auto",
        display: "flex",
        alignItems: "center",
        flexDirection: "row-reverse",
      }}
    >
      <NavigationDropdown />
      <CannyChangelogButton />
      <div>
        <Digest hub={hub} />
      </div>
      {showAllClientDropdopwn && (
        <div>
          {newApp ? (
            <HubContextProvider
              hubId={hub.id}
              hubShortId={hub.hubId}
              hubClientPoolId={hub.clientPool.id}
              sortByLastName={hub.sortByLastName}
              hubAdvisor={advisor}
              advisors={hub.advisors}
              integration={advisor.integrations.find((i) => {
                return i.hubId === hub.id;
              })}
            >
              <ToasterContextProvider fireToaster={fireToaster}>
                <NewContactSearch integration={integration} />
              </ToasterContextProvider>
            </HubContextProvider>
          ) : (
            <ContactSearch integration={integration} />
          )}
        </div>
      )}
    </div>
  );
}

WorkFlowsMenu.defaultProps = {
  hub: {},
};

WorkFlowsMenu.propTypes = {
  advisor: PropTypes.shape({
    id: PropTypes.string.isRequired,
    integrations: PropTypes.array.isRequired,
  }).isRequired,
  hub: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  setAdvisor: PropTypes.func.isRequired,
  fireToaster: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    advisor: state.hubly.data.advisor,
    hub: state.hubly.data.hub.selected.hub,
    loading: state.hubly.data.hub.selected.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAdvisor: (advisor) => {
      dispatch(SetAdvisor(advisor));
    },
    fireToaster: (alert) => {
      dispatch(SetAlert(alert));
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(WorkFlowsMenu)
);
