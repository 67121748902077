import React from "react";
import PropTypes from "prop-types";

import { Button } from "semantic-ui-react";

function InformationButtons({
  title,
  disabled,
  loading,
  toPrev,
  onClose,
  onSubmit,
}) {
  return (
    <React.Fragment>
      <Button style={{ float: "left" }} content="Back" basic onClick={toPrev} />
      <Button content="Discard Changes" basic onClick={onClose} />
      <Button
        color="blue"
        content={title}
        type="button"
        disabled={disabled}
        loading={loading}
        onClick={onSubmit}
      />
    </React.Fragment>
  );
}

InformationButtons.propTypes = {
  title: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  toPrev: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

InformationButtons.defaultProps = {
  title: "",
  disabled: false,
  loading: false,
};

export default InformationButtons;
