import React from "react";
import PropTypes from "prop-types";

import { Icon, Popup } from "semantic-ui-react";

export function InfoDot({ color, description, size }) {
  return (
    <Popup
      basic
      inverted
      content={description}
      size="mini"
      style={{
        padding: "0.5em",
      }}
      trigger={
        <Icon
          data-test="info-dot"
          name="circle"
          style={{ color: color, fontSize: size }}
        />
      }
    />
  );
}

InfoDot.propTypes = {
  color: PropTypes.string.isRequired,
  description: PropTypes.string,
  size: PropTypes.string,
};

InfoDot.defaultProps = {
  description: undefined,
  size: "1em",
};

export default InfoDot;
