import { useCallback, useContext, useEffect } from "react";

import { useQuery } from "hooks";

import { ToasterContext } from "scenes/Hubly/components/Workspace/Provider";

export const useGetUserSuggestions = () => {
  const { fireSuccess, fireError } = useContext(ToasterContext);

  const factory = useCallback(() => {
    const query = `query getUserSuggestions {
          suggestedUsers {
              firstName
              lastName
              email
          }
      }
      `;

    const resolver = ({ data, errors }) => {
      if (errors?.length > 0) throw errors;
      return data;
    };

    return { query, resolver };
  }, []);

  const { data, loading, error, numOfFetches, ...q } = useQuery(factory, {
    options: { fetchOnInit: false },
  });

  useEffect(() => {
    if (numOfFetches > 0 && !loading && data?.suggestedUsers?.length < 1) {
      fireSuccess(
        "No Suggestions available, All CRM users are currently Hubly users"
      );
    }
  }, [data, loading, numOfFetches, fireSuccess]);

  useEffect(() => {
    if (!loading && error) {
      fireError(error[0]?.message || "Failed to load suggestions", error);
    }
  }, [loading, error, fireError]);

  return { data, loading, error, numOfFetches, ...q };
};

export default useGetUserSuggestions;
