export const unset = (obj, key) => {
  delete obj[key];
  return { ...obj };
};

export const set = (obj, key, value) => {
  return { ...obj, [key]: value };
};

export const groupByKeys = (items, keys) => {
  const byType = (type) => {
    return (item) => {
      return item.type === type;
    };
  };

  return keys.reduce((agg, key) => {
    const group = `${key}s`;
    return { ...agg, [group]: items.filter(byType(key)) };
  }, {});
};

export default set;
