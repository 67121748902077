import React from "react";
import PropTypes from "prop-types";

import { ConfirmationDropdown, Header, WithPopper } from "./components";

export function CrmAdvisorSelector({ loading, title, advisors, onSelect }) {
  return (
    <div
      style={{ width: "max-content" }}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div style={{ display: "flex" }}>
        <Header as="h4" style={{ marginBottom: "1em", maxWidth: "200px" }}>
          {title}
        </Header>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ marginRight: "0.5em" }}>
          <ConfirmationDropdown
            data-test="assign-advisor-popup-dropdown"
            placeholder="Filter by assignee"
            position="bottom right"
            loading={loading}
            initValue=""
            onSelect={onSelect}
            items={advisors.map(({ id, name }) => {
              return {
                key: id,
                value: id,
                text: name,
                icon: "user circle",
              };
            })}
          />
        </div>
      </div>
    </div>
  );
}

CrmAdvisorSelector.propTypes = {
  loading: PropTypes.bool,
  title: PropTypes.string,
  advisors: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
};

CrmAdvisorSelector.defaultProps = {
  loading: false,
  title: "Map to User",
};

export default WithPopper(CrmAdvisorSelector);
