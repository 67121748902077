import { useCallback } from "react";

import { useQueryClient } from "components/QueryClient";

import { useFetch } from "./useFetch";

export function useQuery(queryFactory, more) {
  const client = useQueryClient();
  const { cached = false } = more?.options || {};

  const fetch = useCallback(
    (v) => {
      return client.query(v, queryFactory(), !cached);
    },
    [queryFactory, client, cached]
  );

  return useFetch(more?.options?.overrideFetch || fetch, more);
}

export default useQuery;
