import React, { useState } from "react";
import PropTypes from "prop-types";

import { Button, Icon } from "semantic-ui-react";

import Access from "components/Access";
import Popper from "components/Popper";

export function Menu({
  onClickCompleteTopTask,
  onClickMoveTop,
  onClickDelete,
  onClickArchive,
}) {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <Popper
      data-test="client-workflow-bars-menu"
      className="hubly_bars_menu"
      onClose={() => {
        setMenuOpen(false);
      }}
      onOpen={() => {
        setMenuOpen(true);
      }}
      open={menuOpen}
      flowing
      hideOnScroll
      position="right center"
      on="click"
      trigger={
        <Icon
          data-test="client-workflow-bars-menu-button"
          link
          name="ellipsis vertical"
          color="grey"
          style={{
            fontSize: "12pt",
            right: "0.25em",
            top: ".5em",
          }}
        />
      }
    >
      <Button.Group basic vertical labeled icon>
        {onClickCompleteTopTask && (
          <Button
            icon="check square outline"
            content="Mark Next Task Complete"
            onClick={() => {
              onClickCompleteTopTask();
              setMenuOpen(false);
            }}
          />
        )}
        <Button
          icon="level up alternate"
          content="Move To Top"
          onClick={() => {
            onClickMoveTop();
            setMenuOpen(false);
          }}
        />
        <Access required={["Administrator"]}>
          <Button
            icon="trash alternate"
            content="Delete"
            onClick={() => {
              onClickDelete();
              setMenuOpen(false);
            }}
          />
        </Access>
        <Button
          icon="archive"
          content="Archive"
          onClick={() => {
            onClickArchive();
            setMenuOpen(false);
          }}
        />
      </Button.Group>
    </Popper>
  );
}

Menu.propTypes = {
  onClickCompleteTopTask: PropTypes.func,
  onClickMoveTop: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  onClickArchive: PropTypes.func.isRequired,
};
Menu.defaultProps = {
  onClickCompleteTopTask: null,
};

export default Menu;
