import {
  CreateProcess,
  DeleteProcess,
  EditProcess,
  UpdateProcesSortOrder,
} from "data/libs/processes";
import { UpdateWorkflowSortOrder } from "data/libs/workflows";

import { wordsStartWith } from "components/MarkedText";

export const filterNameByKeyword = (workflowName, keyword) => {
  if (keyword === "") return true;
  return wordsStartWith(workflowName, keyword);
};

export const mapProcessToDraggableTileProps = (process = {}) => {
  const {
    name,
    description,
    color,
    numWorkflows,
    numActiveClients,
    numRed,
    numYellow,
    numGreen,
  } = process;
  return {
    type: "process",
    title: name,
    color: color,
    description: description,
    totalWorkflow: numWorkflows,
    totalClients: numActiveClients,
    totalUpcoming: numGreen,
    totalComingDue: numYellow,
    totalOverdue: numRed,
    collapsible: true,
  };
};

export const searchInputFilter = (searchInput, { name, workflows = [] }) => {
  if (filterNameByKeyword(name, searchInput)) return true;

  // For process, if the name did not match the search keyword,
  // search the workflow name for match
  return workflows.some((workflow) => {
    return filterNameByKeyword(workflow.name, searchInput);
  });
};

export const isWorkflowDisabled = (selectedWorkflows, workflowId) => {
  if (selectedWorkflows.length === 0) return true;
  return !selectedWorkflows.some(({ id: selectedWfId }) => {
    return selectedWfId === workflowId;
  });
};

export const isProcessDisabled = (selectedWorkflows, processWorkflows) => {
  if (selectedWorkflows.length === 0 || processWorkflows.length === 0)
    return true;
  const totalProcessWorkflowShownInTheHub = selectedWorkflows.reduce(
    (total, wf) => {
      return processWorkflows.some((pWf) => {
        return pWf.id === wf.id;
      })
        ? total + 1
        : total;
    },
    0
  );
  return !(totalProcessWorkflowShownInTheHub === processWorkflows.length);
};

export const doesProcessNameExist = (processes, { name, id = null }) => {
  return processes.some((process) => {
    return process.name === name && id !== process.id;
  });
};

export const getAllWorkflows = (processes, workflows) => {
  return [
    ...processes.flatMap(({ workflows: pWfs }) => {
      return pWfs;
    }),
    ...workflows,
  ];
};

export const getActiveWorkflows = (processes, workflowsWithNoProcess) => {
  const workflowsToDisplay = [];
  processes.forEach((process) => {
    const activeWorkflows = process.workflows.filter((w) => {
      return w.numActiveClients > 0;
    });
    workflowsToDisplay.push(...activeWorkflows);
  });
  workflowsToDisplay.push(
    ...workflowsWithNoProcess.filter((w) => {
      return w.numActiveClients > 0;
    })
  );
  return workflowsToDisplay;
};

export const getWorkflowsWithNoProcessEmptyText = (
  totalAllWorkflows,
  searchInput,
  totalWorkflowWithNoProcess
) => {
  if (totalAllWorkflows === 0)
    return "You have no workflows. Start by adding one!";
  if (searchInput !== "") return "No matching workflows found.";
  if (totalWorkflowWithNoProcess === 0)
    return "All of your workflows have been added to the process.";
  return "";
};

export const isTargetPartOfTheNestedPopup = (target) => {
  return (
    target.closest("#color-picker-popup") ||
    target.closest("#hide-show-popup") ||
    target.closest("#add-client-popup") ||
    target.closest("div.ui.small.modal.visible.active") ||
    target.closest("#create_new_client_modal")
  );
};

export const reOrderProcess = (id, placeBeforeItemId) => {
  return UpdateProcesSortOrder(id, {
    placeBeforeItem: placeBeforeItemId,
  });
};

export const reOrderWorkflow = (id, placeBeforeItemId) => {
  return UpdateWorkflowSortOrder(id, {
    placeBeforeItem: placeBeforeItemId,
  });
};

export const addProcess = (request) => {
  return CreateProcess(request);
};

export const editProcess = (id, request) => {
  return EditProcess(id, request);
};

export const deleteProcess = (id) => {
  return DeleteProcess(id);
};

export const getWorkflowsToDisplayAfterReorder = (
  hubWorkflows,
  orderedWorkflows
) => {
  // Remove existing workflows from the hubWorkflows list for
  // regrouping purpose
  let firstOccurenceIndex = -1;
  let workflowsShown = [...orderedWorkflows];

  const filtered = hubWorkflows.filter((workflow, index) => {
    const matchedWorkflow = orderedWorkflows.find((w) => {
      return w.id === workflow.id;
    });
    if (matchedWorkflow) {
      firstOccurenceIndex =
        firstOccurenceIndex === -1 ? index : firstOccurenceIndex;

      return false;
    } else {
      workflowsShown = workflowsShown.filter((w) => {
        return w.id !== workflow.id;
      });
    }
    return true;
  });

  if (firstOccurenceIndex !== -1) {
    // Update the filter with the new order if they are shown in the hub
    filtered.splice(firstOccurenceIndex, 0, ...workflowsShown);
    return filtered;
  }
  return hubWorkflows;
};

export default addProcess;
