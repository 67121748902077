import { useCallback, useMemo } from "react";

import { useQuery } from "hooks";

import { toGlobalId } from "scenes/Hubly/components/Workspace/api/helper";

export function useAvailableEditors(excludeWorkflowId) {
  const queryFactory = useCallback(() => {
    const query = `query getAdvisors($excludeWorkflowId: ID) {
      availableWorkflowEditors(excludeWorkflowId: $excludeWorkflowId)  {
        edges {
          node {
            id
            name
          }
        }
      }
    }
    `;

    const resolver = ({ data: { availableWorkflowEditors } }) => {
      return availableWorkflowEditors.edges.map(({ node }) => {
        return {
          ...node,
        };
      });
    };

    return { query, resolver };
  }, []);

  const { reloadData, loading, data } = useQuery(queryFactory, {
    variables: useMemo(() => {
      return { excludeWorkflowId: toGlobalId("Workflow", excludeWorkflowId) };
    }, [excludeWorkflowId]),
    options: { fetchOnInit: false },
  });

  return { fetch: reloadData, loadingEditors: loading, editors: data };
}

export default useAvailableEditors;
