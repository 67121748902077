import React, { Component } from "react";

import { Header } from "semantic-ui-react";

class CancelAccount extends Component {
  render() {
    return (
      <div style={{ marginTop: "1em" }}>
        <Header as="h1">Close Your Account</Header>
        <div>
          We&apos;re sorry to see you go. You can deactivate your account
          <a href="mailto:hello@myhubly.com?subject=Please Deactivate my Hubly Account">
            &nbsp;here.
          </a>
        </div>
      </div>
    );
  }
}

export default CancelAccount;
