import { useContext, useEffect, useRef, useState } from "react";

import {
  HubContext,
  ToasterContext,
} from "scenes/Hubly/components/Workspace/Provider";

export function useViewAction(onDone = () => {}) {
  const { fireError } = useContext(ToasterContext);
  const { updateDefaultView } = useContext(HubContext);
  const mounted = useRef(false);
  const [isRunningAction, setRunning] = useState(false);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  const call = async (action) => {
    setRunning(true);
    if (mounted.current) {
      await action();
      if (mounted.current) {
        setRunning(false);
      }
    }
  };

  return {
    isRunningAction: isRunningAction,
    updateDefaultView: (viewId = null) => {
      return call(async () => {
        try {
          await updateDefaultView(viewId);
          onDone(viewId);
        } catch (error) {
          fireError(`Failed to update current view`, error);
        }
      });
    },
  };
}

export default useViewAction;
