import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {
  Button,
  Dropdown,
  Icon,
  Input,
  Modal,
  Segment,
} from "semantic-ui-react";

import { SetHub } from "data/hub/actions";
import { BulkUpdateDateTypes, CreateDateType } from "data/libs/dateTypes";

import { SetAlert } from "../Alerts/actions";
import ImportantDate from "./ImportantDate";

const iconOptions = [
  "calendar check",
  "file alternate",
  "dollar sign",
  "heartbeat",
  "database",
  "graduation",
  "exclamation circle",
  "medkit",
  "credit card",
  "list alternate",
  "map signs",
  "home",
  "shield",
  "write",
  "suitcase",
  "lock open",
  "lock",
  "sync",
  "chart bar",
  "edit outline",
  "handshake outline",
  "trophy",
];

const portal = document.createElement("div");
portal.classList.add("dates-DND-portal");
document.body.appendChild(portal);

export class ImportantDatesModal extends React.Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    hub: PropTypes.object.isRequired,
    setAlert: PropTypes.func.isRequired,
    setHub: PropTypes.func.isRequired,
    advisor: PropTypes.object.isRequired,
  };

  state = {
    addingNewDateType: false,
    newDateTitle: "",
    newDateIcon: "suitcase",
    loading: false,
  };

  constructor(props) {
    super(props);
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  onDragEnd(result) {
    const dropOrder = result.destination.index;
    const { setHub, hub } = this.props;
    const hubCopy = { ...hub };
    let dateTypesCopy = [...hubCopy.dateTypes];
    dateTypesCopy.forEach((dt) => {
      if (result.draggableId === dt.id) {
        const showMore = result.destination.droppableId === "droppable_dates_2";
        dt.order = dropOrder;
        dt.showMore = showMore;
        if (result.destination.droppableId !== result.source.droppableId)
          dt.order -= 2;
      } else {
        let order = dt.order > dropOrder ? dt.order + 5 : dt.order - 5;
        if (dt.order === dropOrder) {
          const v = result.destination.index > result.source.index ? -1 : 1;
          order = dropOrder + v;
        }
        dt.order = order;
      }
    });
    dateTypesCopy = dateTypesCopy.sort((a, b) => {
      return a.order - b.order;
    }); // sort
    dateTypesCopy.forEach((dt, index) => {
      dt.order = index;
    }); // normalize the orders
    hubCopy.dateTypes = dateTypesCopy;
    setHub(hubCopy); // set right away to avoid popping
    BulkUpdateDateTypes({ date_types: hubCopy.dateTypes })
      .then((response) => {
        hubCopy.dateTypes = response;
        setHub(hubCopy); // set again for fun ??
      })
      .catch((error) => {
        console.error(error);
        setHub(hub); // change back if error
      });
  }

  addDateType = () => {
    const { newDateIcon, newDateTitle } = this.state;
    const { setAlert, setHub, hub } = this.props;
    if (newDateTitle.length < 1)
      setAlert({ type: "warning", text: "New date must have a title" });
    this.setState({ loading: true });
    let order = 0;
    hub.dateTypes.forEach((dt) => {
      if (dt.order > 0) order = dt.order;
    });
    CreateDateType({
      icon: newDateIcon,
      name: newDateTitle,
      hubId: hub.id,
      order: order + 1,
      isHidden: false,
      isSynced: false,
      showMore: true,
    })
      .then((response) => {
        const hubCopy = { ...hub };
        hubCopy.dateTypes.push(response);
        setHub(hubCopy);
        this.setState({ loading: false, addingNewDateType: false });
      })
      .catch((error) => {
        console.error(error);
        setAlert({ type: "error", text: "Failed to create new date" });
        this.setState({ loading: false });
      });
  };

  render() {
    const { closeModal, hub, advisor } = this.props;
    const { addingNewDateType, newDateIcon, newDateTitle, loading } =
      this.state;
    const { dateTypes } = hub;
    const visibleDateTypes = [];
    const showMoreDateTypes = [];
    dateTypes
      .sort((a, b) => {
        return a.order - b.order;
      })
      .forEach((dt) => {
        if (dt.showMore) showMoreDateTypes.push(dt);
        else visibleDateTypes.push(dt);
      });
    const integration = advisor.integrations.find((i) => {
      return i.hubId === hub.id;
    });
    const integrationType = integration ? integration.type : "";
    return (
      <Modal open onClose={closeModal} size="small">
        <Modal.Header>
          Manage Important Client Dates
          <Icon
            link
            style={{ float: "right" }}
            color="grey"
            name="delete"
            onClick={closeModal}
          />
        </Modal.Header>
        <Modal.Content scrolling style={{ maxHeight: "80vh" }}>
          <div>
            Changes will be applied to all client cards. Configue which
            important client dates will appear on the client card. Click the
            title of a custom date to edit it&apos;s title.
          </div>
          <div>
            <DragDropContext onDragEnd={this.onDragEnd}>
              <div>
                <Droppable droppableId="droppable_dates_1">
                  {(provided, snapshot) => {
                    return (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{ width: "100%" }}
                      >
                        {visibleDateTypes.map((dateType, index) => {
                          return (
                            <Draggable
                              draggableId={dateType.id}
                              key={dateType.id}
                              index={dateType.order}
                            >
                              {(providedDraggable, snapshotDraggable) => {
                                const { isDragging } = snapshotDraggable;
                                const child = (
                                  <div
                                    ref={providedDraggable.innerRef}
                                    {...providedDraggable.draggableProps}
                                    style={{
                                      ...providedDraggable.draggableProps.style,
                                      padding: "0.5em 0px",
                                    }}
                                  >
                                    <ImportantDate
                                      dateType={dateType}
                                      provided={providedDraggable}
                                      index={index}
                                      integrationType={integrationType}
                                    />
                                  </div>
                                );
                                if (!isDragging) {
                                  return child;
                                }
                                // If dragging - put the item in a portal
                                return ReactDOM.createPortal(child, portal);
                              }}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                      </div>
                    );
                  }}
                </Droppable>
              </div>
              <div
                style={{
                  marginTop: "1em",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{ borderBottom: "1px dashed darkgrey", width: "30%" }}
                />
                <div style={{ color: "darkgrey", margin: "0px 2em" }}>
                  Show More
                </div>
                <div
                  style={{ borderBottom: "1px dashed darkgrey", width: "30%" }}
                />
              </div>
              <div>
                <Droppable droppableId="droppable_dates_2">
                  {(provided, snapshot) => {
                    return (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{ width: "100%" }}
                      >
                        {showMoreDateTypes.map((dateType, index) => {
                          return (
                            <Draggable
                              draggableId={dateType.id}
                              key={dateType.id}
                              index={dateType.order}
                            >
                              {(providedDraggable, snapshotDraggable) => {
                                const { isDragging } = snapshotDraggable;
                                const child = (
                                  <div
                                    ref={providedDraggable.innerRef}
                                    {...providedDraggable.draggableProps}
                                    style={{
                                      ...providedDraggable.draggableProps.style,
                                      padding: "0.5em 0px",
                                    }}
                                  >
                                    <ImportantDate
                                      dateType={dateType}
                                      provided={providedDraggable}
                                      index={index}
                                      integrationType={integrationType}
                                    />
                                  </div>
                                );
                                if (!isDragging) {
                                  return child;
                                }
                                // If dragging - put the item in a portal
                                return ReactDOM.createPortal(child, portal);
                              }}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                        {showMoreDateTypes.length === 0 && (
                          <div style={{ width: "100%", height: "40px" }} />
                        )}
                      </div>
                    );
                  }}
                </Droppable>
              </div>
            </DragDropContext>
            {addingNewDateType ? (
              <Segment style={{ marginBottom: "1em" }}>
                <div>
                  <Dropdown
                    defaultUpward
                    selection
                    disabled={loading}
                    options={iconOptions}
                    value={newDateIcon}
                    style={{
                      marginRight: "1em",
                      padding: "0.5em",
                      minWidth: "15px",
                      transform: "translateY(-2px)",
                    }}
                    icon={
                      <Icon
                        name={newDateIcon}
                        size="large"
                        color="grey"
                        style={{ margin: "0px" }}
                      />
                    }
                  >
                    <Dropdown.Menu
                      className="noIconScrollBar"
                      style={{
                        maxHeight: "50vh",
                        scrollbarWidth: "none",
                        msOverflowStyle: "none",
                      }}
                    >
                      {iconOptions.map((icon) => {
                        return (
                          <Dropdown.Item
                            onClick={() => {
                              this.setState({ newDateIcon: icon });
                            }}
                          >
                            <Icon
                              color="grey"
                              size="large"
                              name={icon}
                              style={{ transform: "translateX(-9px)" }}
                            />
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                  <Input
                    disabled={loading}
                    autoComplete="off"
                    value={newDateTitle}
                    placeholder="Untitled Date"
                    style={{ minWidth: "350px", marginBottom: "0.5em" }}
                    onChange={(e, { value }) => {
                      this.setState({ newDateTitle: value });
                    }}
                  />
                </div>
                {integrationType && (
                  <div style={{ marginBottom: "0.5em", fontStyle: "italic" }}>
                    Custom dates cannot be synced to your CRM at this time.
                  </div>
                )}
                <div>
                  <Button
                    positive
                    content="Add"
                    onClick={this.addDateType}
                    loading={loading}
                    disabled={
                      loading ||
                      newDateTitle.length === 0 ||
                      !!dateTypes.find((dt) => {
                        return dt.name === newDateTitle;
                      })
                    }
                  />
                  <Icon
                    link
                    color="grey"
                    name="delete"
                    onClick={() => {
                      this.setState({ addingNewDateType: false });
                    }}
                    size="large"
                    disabled={loading}
                  />
                </div>
              </Segment>
            ) : (
              <Button
                style={{ marginTop: "1em", marginBottom: "1em" }}
                basic
                content="Add"
                onClick={() => {
                  this.setState({
                    addingNewDateType: true,
                    newDateTitle: "",
                    newDateIcon: "suitcase",
                  });
                }}
              />
            )}
          </div>
        </Modal.Content>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    hub: state.hubly.data.hub.selected.hub,
    advisor: state.hubly.data.advisor,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAlert: (alert) => {
      dispatch(SetAlert(alert));
    },
    setHub: (hub) => {
      dispatch(SetHub(hub));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImportantDatesModal);
