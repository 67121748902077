import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Checkbox } from "semantic-ui-react";

import { SetFilters } from "data/filters/actions";

export function ShowNextTaskFilter({ advisors, filters, setFilters }) {
  const { filterNextTaskForAssignee, assignee } = filters;

  const displayName = () => {
    if (assignee === "CURRENT") return "you";

    const { firstName, lastName } = advisors.find((a) => {
      return a.id === assignee;
    });
    return `${firstName} ${lastName}`;
  };

  if (
    assignee !== "CURRENT" &&
    !advisors.some(({ id }) => {
      return id === assignee;
    })
  ) {
    return null;
  }

  return (
    <Checkbox
      data-test="filter-menu-next-task-toggle"
      toggle
      checked={filterNextTaskForAssignee}
      label={`Only show workflows where the next task is assigned to ${displayName(
        assignee
      )}`}
      onChange={() => {
        setFilters({
          ...filters,
          filterNextTaskForAssignee: !filterNextTaskForAssignee,
        });
      }}
    />
  );
}

ShowNextTaskFilter.propTypes = {
  setFilters: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  advisors: PropTypes.array,
};

ShowNextTaskFilter.defaultProps = {
  advisors: [],
};

const mapStateToProps = (state) => {
  return {
    filters: state.hubly.data.filters,
    advisors: state.hubly.data.hub.selected.hub?.advisors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setFilters: (filters) => {
      dispatch(SetFilters(filters));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShowNextTaskFilter);
