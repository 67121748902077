import React from "react";

import { useFlags } from "launchdarkly-react-client-sdk";

export function withFeatureFlag(WrappedComponent, id, enableOnMissing = false) {
  const isFeatureEnabled = (flags = {}) => {
    if (id in flags) return flags[id];
    return enableOnMissing;
  };

  return (props) => {
    if (isFeatureEnabled(useFlags())) {
      return <WrappedComponent {...props} />;
    }

    return null;
  };
}

export default withFeatureFlag;
