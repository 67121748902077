import React from "react";
import { sortBy, uniqBy } from "lodash";

import CRMIcon from "../../CRMIcon";

export function useSyncableRoleOptions(
  availableSyncableNames,
  hubIntegrations,
  defaultText = "Not synced to CRM"
) {
  const crmType = hubIntegrations?.length === 1 ? hubIntegrations[0] : "";

  const defaultOption = { key: null, text: defaultText, node: defaultText };

  const toOption = (text = "") => {
    return (name) => {
      return {
        key: name,
        text: name,
        node: (
          <React.Fragment>
            <CRMIcon crmType={crmType} />
            {text ? `${text} ` : ""}
            {name}
          </React.Fragment>
        ),
      };
    };
  };

  const getAvailableOptions = ({ syncableName }) => {
    return syncableName
      ? uniqBy([syncableName].concat(availableSyncableNames))
      : availableSyncableNames;
  };

  const withIntegration = (options) => {
    return crmType ? [defaultOption].concat(options) : options;
  };

  return {
    optionable: (item) => {
      return {
        ...item,
        selected: item.syncableName
          ? toOption("Synced to")(item.syncableName)
          : defaultOption,
        options: withIntegration(
          sortBy(getAvailableOptions(item)).map(toOption())
        ),
      };
    },
    syncableNameOptions: withIntegration(
      availableSyncableNames.map(toOption())
    ),
    defaultOption: defaultOption,
  };
}

export default useSyncableRoleOptions;
