import { useEffect, useState } from "react";
import { isEqual, keyBy, sortBy } from "lodash";

import { set } from "utils";

export function useSelectedTeamMembers({ serviceRoles, serviceTeamMembers }) {
  const [selectedTeamMembers, setMembers] = useState({});

  const byRoleName = (name) => {
    return ({ roleName }) => {
      return name === roleName;
    };
  };

  const byNonEmptyMembers = ({ members }) => {
    return members.length > 0;
  };

  const toIdArray = ({ id }) => {
    return id;
  };

  const isModified = ({ initial, members }) => {
    return !isEqual(sortBy(initial), sortBy(members.map(toIdArray)));
  };

  useEffect(() => {
    if (serviceRoles) {
      const roles = serviceRoles.map(({ id, name, isSynced }) => {
        const roleMembers = serviceTeamMembers.filter(byRoleName(name));
        return {
          id: id,
          name: name,
          isSynced: isSynced,
          initial: roleMembers.map(toIdArray),
          members: roleMembers,
        };
      });

      setMembers(keyBy(roles, "id"));
    }
  }, [serviceTeamMembers, serviceRoles]);

  return {
    all: () => {
      return Object.values(selectedTeamMembers);
    },
    modified: () => {
      return Object.values(selectedTeamMembers).filter(isModified);
    },
    isEmpty:
      Object.values(selectedTeamMembers).filter(byNonEmptyMembers).length === 0,
    isModified: Object.values(selectedTeamMembers).some(isModified),
    getByRole: (roleId) => {
      return selectedTeamMembers[roleId]?.members || [];
    },
    setByRole: (roleId, updatedValue) => {
      const updated = set(selectedTeamMembers, roleId, {
        ...updatedValue,
        initial: selectedTeamMembers[roleId].initial,
      });
      setMembers(updated);
      return Object.values(updated).filter(isModified);
    },
    clear: () => {
      setMembers({});
    },
  };
}

export default useSelectedTeamMembers;
