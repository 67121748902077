import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { keyBy } from "lodash";

import moment from "moment";
import {
  Accordion,
  Divider,
  Dropdown,
  Header,
  Icon,
  Popup,
} from "semantic-ui-react";

import "./digest.css";

import history from "data/history";

import { GetDigest } from "../../data/libs/hubs";
import Spinner from "../Spinner";

class Digest extends React.Component {
  static propTypes = {
    selectedHub: PropTypes.object.isRequired,
    clients: PropTypes.object.isRequired,
  };

  state = {
    activeIndex: -1,
    digests: [],
    frame: "WEEK",
    loading: true,
    open: false,
  };

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  getTotal = (list) => {
    let total = 0;
    list.forEach((item) => {
      total += item.value;
    });
    return total;
  };

  formatAndSortDates = (list, fullDate) => {
    return list
      .sort((a, b) => {
        const A = new Date(a.value);
        const B = new Date(b.value);
        return A - B;
      })
      .map((item) => {
        const date = moment(item.value);
        return {
          ...item,
          value: fullDate
            ? date.format("h:mma dddd, MMMM Do, Y")
            : date.format("dddd, MMMM Do"),
        };
      });
  };

  sort = (list) => {
    return list.sort((a, b) => {
      return b - a;
    });
  };

  getName = (item) => {
    if (item.clientName) return item.clientName;

    const { selectedHub, clients } = this.props;
    if (item.clientId) {
      const client = clients[item.clientId];
      if (client) {
        return client.name;
      }
    } else if (item.advisorId) {
      const advisor = selectedHub.advisors.find((a) => {
        return a.id === item.advisorId;
      });
      if (advisor) {
        return `${advisor.firstName} ${advisor.lastName}`;
      }
    }
    return "";
  };

  loadDigest = async () => {
    const { selectedHub } = this.props;
    const { frame } = this.state;
    this.setState({ loading: true, activeIndex: -1 });
    const lookup = { TODAY: "day", WEEK: "isoWeek", MONTH: "month" };
    const start = moment().startOf(lookup[frame]).unix();
    const end = moment().endOf(lookup[frame]).unix();

    GetDigest(selectedHub.id, start, end)
      .then((response) => {
        const digests = [
          {
            title: "Tasks Completed",
            content: this.sort(response.tasksCompleted),
            total: this.getTotal(response.tasksCompleted),
          },
          {
            title: "Workflows Started",
            content: this.sort(response.workflowsStarted),
            total: this.getTotal(response.workflowsStarted),
          },
          {
            title: "Workflows Completed",
            content: this.sort(response.workflowsCompleted),
            total: this.getTotal(response.workflowsCompleted),
          },
          {
            title: "Birthdays",
            content: this.formatAndSortDates(response.birthdays, false),
            total: response.birthdays.length,
          },
          {
            title: "Meetings",
            content: this.formatAndSortDates(response.meetings, true),
            total: response.meetings.length,
          },
        ];
        this.setState({ loading: false, digests: digests });
      })
      .catch((error) => {
        console.error(error);
        this.setState({ loading: false });
      });
  };

  render() {
    const { selectedHub } = this.props;
    const { activeIndex, digests, frame, loading, open } = this.state;
    return (
      <Popup
        trigger={
          <div style={{ transform: "rotate(30deg", marginLeft: "0.5em" }}>
            <Icon data-test="hub-digest-button" name="bell" link size="large" />
          </div>
        }
        position="top right"
        on="click"
        open={open}
        offset="0px"
        style={{ padding: "0px" }}
        onClose={() => {
          this.setState({ open: false });
        }}
        onOpen={() => {
          this.setState({ open: true });
          this.loadDigest();
        }}
      >
        <div
          style={{ display: "flex", flexDirection: "column", width: "600px" }}
        >
          <div style={{ padding: "2em", paddingBottom: "0.5em" }}>
            <div>
              <Header as="h3">Your Hubly Digest</Header>
            </div>
            <div>
              <Dropdown
                value={frame}
                onChange={(e, d) => {
                  this.setState({ frame: d.value }, this.loadDigest);
                }}
                options={[
                  { key: "WEEK", value: "WEEK", text: "This Week" },
                  { key: "TODAY", value: "TODAY", text: "Today" },
                  { key: "MONTH", value: "MONTH", text: "This Month" },
                ]}
              />
            </div>
          </div>
          {loading ? (
            <div
              style={{
                width: "100%",
                height: "80px",
                flexDirection: "column",
                color: "grey",
              }}
              className="flexy"
            >
              <div>
                <Spinner active />
              </div>
              <div>Loading Digest</div>
            </div>
          ) : (
            <div>
              <Accordion>
                {digests.map((d, index) => {
                  return (
                    <React.Fragment>
                      <Accordion.Title
                        active={activeIndex === index}
                        index={index}
                        onClick={this.handleClick}
                        className={
                          activeIndex === index
                            ? "grey_on_hover grey_BG accordian_title"
                            : "grey_on_hover accordian_title"
                        }
                      >
                        <Icon
                          data-test={`hub-digest-title-${d.title}`}
                          style={{ marginRight: "1em" }}
                          name={
                            activeIndex === index
                              ? "chevron down"
                              : "chevron right"
                          }
                        />
                        {d.title}
                        <div
                          style={{
                            display: "inline",
                            float: "right",
                            color: "grey",
                            fontWeight: "normal",
                            fontSize: "12pt",
                            marginRight: "2.5em",
                          }}
                        >
                          {d.total}
                        </div>
                      </Accordion.Title>
                      <Accordion.Content
                        active={activeIndex === index}
                        className="grey_BG accordian_content"
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            color: "grey",
                            maxHeight: "350px",
                            overflowY: "scroll",
                          }}
                        >
                          {d.content.map((item) => {
                            return (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  marginRight: "5em",
                                  marginBottom: "0.5em",
                                }}
                              >
                                <div
                                  data-test="hub-digest-content-client"
                                  onClick={() => {
                                    if (item.clientId) {
                                      this.setState({ open: false });
                                      history.push(
                                        `/hub/${selectedHub.hubId}/clients/${item.clientId}`
                                      );
                                    }
                                  }}
                                  className={
                                    item.clientId &&
                                    "clickable underline_on_hover"
                                  }
                                >
                                  {this.getName(item)}
                                  {item.age && ` (${item.age})`}
                                </div>
                                <div>{item.value}</div>
                              </div>
                            );
                          })}
                        </div>
                      </Accordion.Content>
                      <Divider
                        style={{ margin: "0px", borderBottom: "none" }}
                      />
                    </React.Fragment>
                  );
                })}
              </Accordion>
            </div>
          )}
        </div>
      </Popup>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    clients: keyBy(state.hubly.data.hub.clients.allClientNames, "id"),
    selectedHub: state.hubly.data.hub.selected.hub,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Digest);
