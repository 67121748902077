import React from "react";
import PropTypes from "prop-types";
import { find } from "lodash";

import useMemberDropdown from "./hooks/useMemberDropdown";
import { Options } from "..";
import FilterLabel from "./components";

export function ServiceRoleFilter({
  loading,
  serviceRole,
  assignedAdvisors,
  advisorsPage,
  editable,
  onChangeAdvisor,
  defaultTeamMembers,
}) {
  const { dropdownOptions, selected } = useMemberDropdown(
    serviceRole,
    assignedAdvisors,
    advisorsPage.data
  );

  return (
    <Options.Item
      title={
        <FilterLabel
          data-test="service-role"
          isSynced={serviceRole.isSynced}
          name={serviceRole.name}
        />
      }
      description="Select a user"
      hint={defaultTeamMembers
        .filter(({ roleName }) => {
          return serviceRole.name === roleName;
        })
        .map((member, i, rolesArr) => {
          return (
            <div style={{ color: "#c4c4c4" }} key={member.id}>
              {member.name}
              {i + 1 !== rolesArr.length && ","}
            </div>
          );
        })}
      currentValue={selected}
      options={dropdownOptions}
      dropDownProps={{
        loading: loading,
        fluid: true,
        multiple: !serviceRole.isSynced,
      }}
      hideClearIcon={!editable}
      disabled={loading || !editable}
      onClear={() => {
        onChangeAdvisor({
          ...serviceRole,
          members: [],
        });
      }}
      onSelection={(_, { value, options }) => {
        const toAdvisor = (val) => {
          const { key, text } = find(options, { value: val });
          return {
            id: key,
            name: text,
          };
        };

        onChangeAdvisor({
          ...serviceRole,
          members: serviceRole.isSynced
            ? [toAdvisor(value)]
            : value.map(toAdvisor),
        });
      }}
    />
  );
}

ServiceRoleFilter.propTypes = {
  serviceRole: PropTypes.object.isRequired,
  assignedAdvisors: PropTypes.array.isRequired,
  advisorsPage: PropTypes.object.isRequired,
  editable: PropTypes.bool,
  loading: PropTypes.bool,
  onChangeAdvisor: PropTypes.func.isRequired,
  defaultTeamMembers: PropTypes.array,
};

ServiceRoleFilter.defaultProps = {
  loading: false,
  editable: true,
  defaultTeamMembers: [],
};

export default ServiceRoleFilter;
