import React from "react";
import PropTypes from "prop-types";

import { Loader } from "semantic-ui-react";

function withInvisibleBlock(WrappedComp, defaultInvisibleWidth = "10px") {
  const Decorator = (props) => {
    const { active, style } = props;

    if (!active) {
      return <div style={{ width: defaultInvisibleWidth, ...style }} />;
    }
    return <WrappedComp {...props} />;
  };

  Decorator.propTypes = {
    active: PropTypes.bool.isRequired,
    style: PropTypes.object,
  };

  Decorator.defaultProps = {
    style: undefined,
  };

  return Decorator;
}

export const ColumnSpinner = withInvisibleBlock((props) => {
  const style = { ...props.style, width: "10px", zIndex: "unset" };
  return <Loader {...props} size="tiny" inline style={style} />;
}, "10px");

export default ColumnSpinner;
