import { CreateSync, GetSync } from "data/libs/sync";

import { SetAlert } from "components/Alerts/actions";

import { SetInProgress } from "../actions";

export function ResetSyncProgress() {
  return (dispatch) => {
    dispatch(SetInProgress(false, 0));
  };
}

const FireError = (text) => {
  return (dispatch) => {
    dispatch(SetAlert({ type: "error", text: text }));
  };
};

const message = (e, defaultMsg) => {
  return e?.response?.data?.error || defaultMsg;
};

const calcPercentage = (current, total) => {
  return total ? (current / total) * 100 : 10;
};

const isTimeoutReached = (startDate, timeoutInMins) => {
  return (Date.now() - Date.parse(startDate)) / 1000 / 60 > timeoutInMins;
};

function CheckSyncStatus(syncId) {
  return async (dispatch) => {
    try {
      const { status, ...sync } = await GetSync(syncId);

      if (isTimeoutReached(sync.startTime, 15)) {
        dispatch(ResetSyncProgress());
        dispatch(
          FireError(
            "CRM Sync is taking longer than expected, Please contact support for assistant"
          )
        );
        return;
      }

      switch (status) {
        case "failed":
          dispatch(ResetSyncProgress());
          dispatch(FireError(sync.result));
          break;
        case "completed":
          dispatch(SetInProgress(false, 100));
          break;
        default: {
          const progress = calcPercentage(sync.currentPage, sync.totalPages);
          dispatch(SetInProgress(true, progress));
          setTimeout(() => {
            dispatch(CheckSyncStatus(syncId));
          }, 1000);
        }
      }
    } catch (e) {
      dispatch(ResetSyncProgress());
      dispatch(
        FireError(
          message(
            e,
            "Cannot determine CRM Sync progress, Please contact support for assistant"
          )
        )
      );
    }
  };
}

export function TriggerFullSync() {
  return async (dispatch, getState) => {
    const { advisor } = getState().hubly.data;
    const { hub } = getState().hubly.data.hub.selected;

    if (hub.clientPool.type === "hubly") {
      return;
    }

    dispatch(SetInProgress(true, 0));
    try {
      const { id } = await CreateSync({
        type: "contacts",
        advisor_id: advisor.id,
        hub_id: hub.id,
      });

      dispatch(CheckSyncStatus(id));
    } catch (e) {
      dispatch(ResetSyncProgress());
      dispatch(
        FireError(
          message(e, "Failed to initiate a CRM Sync, You may try again")
        )
      );
    }
  };
}

export default TriggerFullSync;
