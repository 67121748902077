import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { uniqBy } from "lodash";

import {
  Button,
  Dropdown,
  Form,
  Header,
  Icon,
  Input,
  Label,
  Modal,
  Table,
} from "semantic-ui-react";

import { SetAdvisor, UpdateAdvisor } from "data/advisor/actions";
import { SetHub } from "data/hub/actions";
import { GetAdvisor } from "data/libs/advisor";
import { CreateHubAdvisor, DeletehubAdvisor, EditHub } from "data/libs/hubs";

import Access from "components/Access";
import { SetAlert } from "components/Alerts/actions";
import PopupMenu from "components/PopupMenu";

class HubInformationForm extends Component {
  static defaultProps = {
    selectedHub: {},
  };

  static propTypes = {
    advisor: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      hubs: PropTypes.array.isRequired,
      id: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      practices: PropTypes.array.isRequired,
      role: PropTypes.object,
    }).isRequired,
    selectedHub: PropTypes.object,
    setAdvisor: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired,
    setHub: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      initialHubAdvisorIds: [],
      hubAdvisors: [],
      hubModalOpen: false,
      loading: false,
      name: "",
      popupOpenId: null,
    };
  }

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };

  save = () => {
    const { selectedHub, setAdvisor, setAlert, setHub, advisor } = this.props;
    const { id, initialHubAdvisorIds, hubAdvisors, name } = this.state;

    const advisorIdsToRemove = initialHubAdvisorIds.filter((i) => {
      return !hubAdvisors.includes(i);
    });
    const advisorIdsToAdd = hubAdvisors.filter((i) => {
      return !initialHubAdvisorIds.includes(i);
    });

    this.setState({ loading: true });
    EditHub(id, { name: name })
      .then((response) => {
        if (selectedHub && response.id === selectedHub.id) {
          setHub(response, advisor);
        }

        const promises = [];

        advisorIdsToRemove.forEach((advisorId) => {
          promises.push(DeletehubAdvisor(id, advisorId));
        });

        advisorIdsToAdd.forEach((advisorId) => {
          promises.push(CreateHubAdvisor(id, { id: advisorId }));
        });

        if (promises.length > 0) {
          Promise.all(promises)
            .then(() => {
              GetAdvisor()
                .then((advisorResponse) => {
                  setAdvisor(advisorResponse);
                  this.setState({ hubModalOpen: false, loading: false });
                })
                .catch((error) => {
                  // This state shouldn't really be possible... return them to settings without updating?
                  console.error(error);
                  this.setState({ hubModalOpen: false, loading: false });
                });
            })
            .catch((error) => {
              console.error(error);
              this.setState({ loading: false });
              setAlert({ type: "error", text: "Failed to update Hub users." });
            });
        } else {
          GetAdvisor()
            .then((advisorResponse) => {
              setAdvisor(advisorResponse);
              this.setState({ hubModalOpen: false, loading: false });
            })
            .catch((error) => {
              // This state shouldn't really be possible... return them to settings without updating?
              console.error(error);
              this.setState({ hubModalOpen: false, loading: false });
            });
        }
      })
      .catch((error) => {
        console.error(error);
        setAlert({ type: "error", text: "Failed to update Hub information." });
        this.setState({ loading: false });
      });
  };

  openHubModal = (hub) => {
    const hubAdvisors = hub.advisors.map((advisor) => {
      return advisor.id;
    });

    this.setState({
      hubAdvisors: hubAdvisors,
      hubModalOpen: true,
      initialHubAdvisorIds: [...hubAdvisors],
      id: hub.id,
      name: hub.name,
      popupOpenId: null,
    });
  };

  handleDropdownChange = (e, { value }) => {
    this.setState({
      hubAdvisors: value,
    });
  };

  hubPractice() {
    const { advisor, selectedHub } = this.props;

    return (
      advisor.practices.find((p) => {
        return p.id === selectedHub.practice;
      }) || {}
    );
  }

  myPracticeHubs() {
    const { advisor } = this.props;
    const { hubs = [] } = this.hubPractice();

    const advisorHubIds = advisor.hubs.map(({ id }) => {
      return id;
    });

    return hubs.filter((h) => {
      return advisorHubIds.includes(h.id);
    });
  }

  render() {
    const { hubAdvisors, hubModalOpen, loading, name, popupOpenId } =
      this.state;

    const { advisor } = this.props;
    const hubs = this.myPracticeHubs();

    return (
      <div style={{ marginTop: "1em" }}>
        <Header as="h1">Hub Information</Header>
        <div>
          <Table selectable={advisor.role.title === "Administrator"} stackable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Members</Table.HeaderCell>
                <Access required={["Administrator"]}>
                  <Table.HeaderCell />
                </Access>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {hubs
                .sort((a, b) => {
                  return a.createdAt < b.createdAt;
                })
                .map((hub) => {
                  return (
                    <Table.Row key={hub.id}>
                      <Table.Cell>
                        <Link to={`/hub/${hub.hubId}/settings/hub`}>
                          {hub.name}
                        </Link>
                      </Table.Cell>
                      <Table.Cell>
                        {hub.advisors.map((hubAdvisor) => {
                          return (
                            <Label
                              content={hubAdvisor.firstName}
                              icon="user circle"
                              key={hubAdvisor.id}
                              style={{ margin: "1px 2px 1px 0" }}
                            />
                          );
                        })}
                      </Table.Cell>
                      <Access required={["Administrator"]}>
                        <Table.Cell>
                          <PopupMenu
                            trigger={
                              <Icon
                                color="grey"
                                link
                                name="ellipsis horizontal"
                                onClick={() => {
                                  this.setState({ popupOpenId: hub.id });
                                }}
                                style={{ float: "right" }}
                              />
                            }
                            buttons={[
                              {
                                content: "Edit Hub",
                                icon: "edit",
                                onClick: () => {
                                  this.openHubModal(hub);
                                },
                              },
                            ]}
                            open={popupOpenId === hub.id}
                            onClose={() => {
                              this.setState({ popupOpenId: null });
                            }}
                          />
                        </Table.Cell>
                      </Access>
                    </Table.Row>
                  );
                })}
            </Table.Body>
          </Table>
        </div>
        <Modal
          onClose={() => {
            this.setState({ id: null, hubAdvisors: [], hubModalOpen: false });
          }}
          size="mini"
          open={hubModalOpen}
          closeOnDimmerClick={false}
          style={{ minWidth: "360px" }}
        >
          <Modal.Header>Edit Hub Information</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <Form>
                <Form.Field>
                  <label>Hub Name</label>
                  <Input
                    name="name"
                    value={name}
                    onChange={this.handleChange}
                    autoComplete="off"
                  />
                </Form.Field>
                {hubs.length > 1 && (
                  <Form.Field>
                    <label>Advisors</label>
                    <Dropdown
                      fluid
                      multiple
                      onChange={this.handleDropdownChange}
                      options={uniqBy(
                        this.hubPractice()?.advisors || [],
                        "id"
                      ).map((a) => {
                        return {
                          key: a.id,
                          text: `${a.firstName} ${a.lastName}`,
                          value: a.id,
                        };
                      })}
                      placeholder="Advisors"
                      selection
                      value={hubAdvisors}
                    />
                  </Form.Field>
                )}
              </Form>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              content="Discard Changes"
              basic
              disabled={loading}
              onClick={() => {
                this.setState({ hubModalOpen: false });
              }}
            />
            <Button
              positive
              content="Save Changes"
              onClick={this.save}
              disabled={loading}
              loading={loading}
            />
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedHub: state.hubly.data.hub.selected.hub,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateAdvisor: (id, request, callback) => {
      dispatch(UpdateAdvisor(id, request, callback));
    },
    setAdvisor: (advisor) => {
      dispatch(SetAdvisor(advisor));
    },
    setAlert: (alert) => {
      dispatch(SetAlert(alert));
    },
    setHub: (hub) => {
      dispatch(SetHub(hub));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HubInformationForm);
