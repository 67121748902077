import React, { useState } from "react";
import PropTypes from "prop-types";

import { Icon, Input, Popup } from "semantic-ui-react";

import { useDelay } from "hooks";

export function SearchInput({ value, onChange }) {
  const [input, setInput] = useState(value);

  const delayCallback = useDelay(300);
  const updateInput = (val) => {
    setInput(val);
    delayCallback(() => {
      onChange(val);
    });
  };

  return (
    <div
      style={{
        width: "100%",
        paddingBottom: "1em",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Input
        icon="search"
        iconPosition="left"
        fluid
        placeholder="Find a workflow or process"
        autoFocus
        onChange={(_, { value: inputVal }) => {
          updateInput(inputVal);
        }}
        style={{ flexGrow: 1 }}
        value={input}
      />
      <div style={{ marginLeft: "1em" }}>
        <Popup
          content="Clear"
          on="hover"
          position="top center"
          trigger={
            <Icon
              name="delete"
              color="grey"
              link
              style={{ fontSize: "12pt" }}
              onClick={() => {
                updateInput("");
              }}
            />
          }
        />
      </div>
    </div>
  );
}

SearchInput.defaultProps = {
  value: "",
};

SearchInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

export default SearchInput;
