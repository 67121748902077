import { useQuery } from "hooks";
import { useFeatures } from "components/FeatureFlag";

import { getAdvisors, getAssignees } from "./query";

export function useAssignees(legacyFetch) {
  const query = useFeatures("service-team-settings")
    ? getAssignees
    : getAdvisors;

  const { loading, data, reloadData } = useQuery(query, {
    options: { fetchOnInit: false, overrideFetch: legacyFetch, cached: true },
  });

  return [reloadData, loading, data];
}

export default useAssignees;
