export const taskHasOtherHouseholdMember = (task, client = null) => {
  if (client === null) {
    return false;
  }
  return task?.householdMembers.find((householdMember) => {
    return householdMember.client.id !== client.id;
  });
};

export const hasDetails = (task, client = null) => {
  return (
    task?.description?.length > 0 ||
    task?.links?.length > 0 ||
    task?.isClientTask ||
    taskHasOtherHouseholdMember(task, client)
  );
};
