import React from "react";
import PropType from "prop-types";

import { Button } from "semantic-ui-react";

import { useBooleanState } from "hooks";
import { ConfirmationModal } from "components/ConfirmationModal";
import PopupMenu from "components/PopupMenu";

import useTeamActionsConfirmationModal from "./hooks/useTeamActionsConfirmationModal";

const TeamActionsWrapper = ({ actions }) => {
  const [isOpen, open, close] = useBooleanState();
  const [isModalOpen, openModal, closeModal] = useBooleanState();
  const { setModalProps, props: confirmationModalProps } =
    useTeamActionsConfirmationModal(closeModal);
  return (
    <React.Fragment>
      {isModalOpen && <ConfirmationModal {...confirmationModalProps} />}
      {actions.length > 0 && (
        <PopupMenu
          data-test="team-actions-popup-menu"
          open={isOpen}
          onOpen={(e) => {
            e.stopPropagation();
            open();
          }}
          onClose={close}
        >
          <React.Fragment>
            {actions.map(({ icon, content, confirm }) => {
              return (
                <Button
                  key={content}
                  icon={icon}
                  content={content}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (confirm) {
                      setModalProps(confirm);
                      close();
                      openModal();
                    }
                  }}
                />
              );
            })}
          </React.Fragment>
        </PopupMenu>
      )}
    </React.Fragment>
  );
};

TeamActionsWrapper.propTypes = {
  actions: PropType.array.isRequired,
};

export default TeamActionsWrapper;
