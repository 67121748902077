import { SetActiveClient } from "data/hub/clients/actions";
import { SetClientWorkflow } from "data/hub/clientWorkflows/actions";
import { EditClient } from "data/libs/clients";
import { EditClientWorkflow } from "data/libs/clientWorkflows";
import {
  CreateNextWorkflow,
  DeleteNextWorkflow,
  EditNextWorkflow,
} from "data/libs/nextWorkflows";
import { AddTask } from "data/libs/tasks";
import { publishUpdate } from "data/messages/actions";

import { SetAlert } from "components/Alerts/actions";

export function UpdateNextWorkflowAttribute(
  nextWorkflow,
  attribute,
  value,
  callback,
  mute = false
) {
  return (dispatch, getState) => {
    const request = {};
    request[attribute] = value;
    EditNextWorkflow(nextWorkflow.id, request)
      .then((response) => {
        const clientWorkflowCopy = {
          ...getState().hubly.data.hub.clientWorkflows[
            nextWorkflow.clientWorkflowId
          ],
        };
        const i = clientWorkflowCopy.nextWorkflows.findIndex((nw) => {
          return nw.id === nextWorkflow.id;
        });
        clientWorkflowCopy.nextWorkflows[i] = response;
        dispatch(SetClientWorkflow(clientWorkflowCopy));
        if (!mute)
          dispatch(
            SetAlert({
              type: "success",
              text: `Successfully edited ${nextWorkflow.nextWorkflowName}`,
            })
          );
        if (callback) {
          callback();
        }
      })
      .catch((error) => {
        dispatch(
          SetAlert({ type: "error", text: `Failed to edit next workflow.` })
        );
        console.error(error);
      });
  };
}

export function AddNextWorkflow(
  clientWorkflow,
  addedWorkflowId,
  startDate,
  taskRequest
) {
  if (taskRequest) delete taskRequest.id;
  return (dispatch, getState) => {
    const { workflows } = getState().hubly.data.hub;
    const clientWorkflowCopy = { ...clientWorkflow };

    const nextWorkflows = clientWorkflowCopy.nextWorkflows || [];
    const nextWorkflow = { ...workflows[addedWorkflowId] };
    const request = {
      clientWorkflowId: clientWorkflowCopy.id,
      nextWorkflowId: addedWorkflowId,
      startDate: startDate,
    };
    CreateNextWorkflow(request)
      .then((response) => {
        nextWorkflows.push(response);
        dispatch(SetClientWorkflow(clientWorkflowCopy));
        dispatch(
          SetAlert({
            type: "success",
            text: `Successfully added ${nextWorkflow.name} to the next workflows list`,
          })
        );
        if (taskRequest) {
          taskRequest.nextWorkflowId = response.id;
          AddTask(taskRequest)
            .then((taskResponse) => {
              dispatch(
                UpdateNextWorkflowAttribute(
                  response,
                  "taskId",
                  taskResponse.id,
                  null,
                  true
                )
              );
            })
            .catch((error) => {
              console.error(error);
              dispatch(
                SetAlert({
                  type: "error",
                  text: `Failed to add task to next workflow.`,
                })
              );
            });
        }
      })
      .catch((error) => {
        console.error(error);
        dispatch(
          SetAlert({
            type: "error",
            text: `Failed to create next workflow.`,
          })
        );
      });
  };
}

export function RemoveNextWorkflow(
  clientWorkflowId,
  removeWorkflowId,
  nextWorkflowName
) {
  return (dispatch, getState) => {
    DeleteNextWorkflow(removeWorkflowId)
      .then((response) => {
        const clientWorkflowCopy = {
          ...getState().hubly.data.hub.clientWorkflows[clientWorkflowId],
        };
        clientWorkflowCopy.nextWorkflows =
          clientWorkflowCopy.nextWorkflows.filter((nextWorkflow) => {
            return nextWorkflow.id !== removeWorkflowId;
          });
        dispatch(SetClientWorkflow(clientWorkflowCopy));
        dispatch(
          SetAlert({
            type: "success",
            text: `Successfully removed ${nextWorkflowName} from the next workflows list`,
          })
        );
      })
      .catch((error) => {
        dispatch(
          SetAlert({ type: "error", text: `Failed to delete next workflow.` })
        );
      });
  };
}

export function ToggleHideCompleteTasks(clientId, clientWorkflowId) {
  return (dispatch, getState) => {
    const clientWorkflow = {
      ...getState().hubly.data.hub.clientWorkflows[clientWorkflowId],
    };
    const clientWorkflowCopy = { ...clientWorkflow };

    if (!clientWorkflow) {
      console.warn(
        "ToggleHideCompleteTasks, Client Workflow ID ",
        clientWorkflowId,
        "does not exist"
      );
      return;
    }

    clientWorkflow.hideCompletedTasks = !clientWorkflow.hideCompletedTasks;
    dispatch(SetClientWorkflow(clientWorkflow));
    const req = { hideCompletedTasks: clientWorkflow.hideCompletedTasks };
    EditClientWorkflow(clientWorkflowId, req)
      .then((response) => {})
      .catch((error) => {
        dispatch(SetClientWorkflow(clientWorkflowCopy));
        dispatch(
          SetAlert({
            type: "error",
            text: `Failed to toggle hide/show of complete tasks.`,
          })
        );
      });
  };
}

export function SetNameClientWorkflow({
  clientWorkflowId,
  newName,
  onSuccess = () => {},
}) {
  return (dispatch) => {
    EditClientWorkflow(clientWorkflowId, { name: newName })
      .then((response) => {
        dispatch(SetClientWorkflow(response));
        dispatch(
          SetAlert({ type: "success", text: `Renamed Workflow to ${newName}` })
        );

        onSuccess();
      })
      .catch((error) => {
        const errorMessage = JSON.stringify(
          error?.response?.data || "Unknown Error"
        );
        dispatch(
          SetAlert({
            type: "error",
            text: `Failed to rename workflow - ${errorMessage}`,
          })
        );
      });
  };
}

export function UpdateClientNote(clientId, note) {
  return (dispatch, getState) => {
    const { activeClients } = getState().hubly.data.hub.clients;
    const client = activeClients[clientId];

    if (client) {
      if (!note) {
        delete client.note;
      } else {
        client.note = note;
      }
    }
    const req = { note };
    EditClient(clientId, req).catch((e) => {
      console.warn(e);
      console.warn("UpdateClientNote, Client ID ", clientId, " does not exist");
    });
  };
}

export function UpdateClientAttribute(client, attribute, value, callback) {
  return (dispatch, getState) => {
    const req = {};
    req[attribute] = value;

    EditClient(client.id, req)
      .then((response) => {
        // Do something to update the client object
        dispatch(SetActiveClient(response));

        if (callback) {
          callback();
        }
      })
      .catch((e) => {
        console.warn(e);
        console.warn(
          "UpdateClientNote, Client ID ",
          client.id,
          " does not exist"
        );
      });
  };
}

export const publishClientWorkflowsUpdate = (clientIds) => {
  const obtain = (state) => {
    const getClient = (cid) => {
      return state.hubly.data.hub.clients.activeClients[cid];
    };

    return [getClient, state.hubly.data.hub.clientWorkflows];
  };

  const selectRelated = (cwfs = {}) => {
    return (id) => {
      return cwfs[id]?.workflowId;
    };
  };

  return (dispatch, getState) => {
    const [getClient, cwfs] = obtain(getState());
    const relatedWfs = clientIds.map(getClient).flatMap((client) => {
      return client?.workflows?.map(selectRelated(cwfs)) || [];
    });

    dispatch(publishUpdate("WORKFLOWS", relatedWfs));
  };
};
