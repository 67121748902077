import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {
  Button,
  Card,
  Dimmer,
  Form,
  Icon,
  Image,
  Input,
  List,
  Message,
  Modal,
  Segment,
  Step,
} from "semantic-ui-react";

import SetAdvisor from "data/advisor/actions";
import history from "data/history";
import { SetHub, SetInProgress } from "data/hub/actions";
import {
  GetAllClients,
  SetActiveClients,
  SetAllClientNames,
} from "data/hub/clients/actions";
import { SetHouseholds } from "data/hub/households/actions";
import { SetWorkflows } from "data/hub/workflows/actions";
import { EditAdvisor, GetAdvisor } from "data/libs/advisor";
import EditClientPool from "data/libs/clientPools";
import { GetAllClientNames } from "data/libs/clients";
import { GetHouseholds } from "data/libs/households";
import { GetHub } from "data/libs/hubs";
import { CreateIntegration } from "data/libs/integrations";
import { AcceptPolicy, GetPolicies } from "data/libs/policy";
import { CreatePractice } from "data/libs/practices";
import { CreateSync, GetSync } from "data/libs/sync";
import { GetWorkflows } from "data/libs/workflows";

import { SetAlert } from "components/Alerts/actions";
import { PrivacyModeContext } from "components/PrivacyMode/Context";
import Spinner from "components/Spinner";

import AgreementArea from "../PrivacyPolicy/components/AgreementArea";
import { SetNewUserModal } from "./actions";

class NewUserModal extends React.Component {
  static contextType = PrivacyModeContext;

  static defaultProps = {
    hub: {},
  };

  static propTypes = {
    advisor: PropTypes.shape({
      id: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      integrations: PropTypes.array.isRequired,
      hubs: PropTypes.arrayOf(
        PropTypes.shape({
          clientPool: PropTypes.shape({
            type: PropTypes.string.isRequired,
          }),
        })
      ),
      lastName: PropTypes.string.isRequired,
      policies: PropTypes.array.isRequired,
      practices: PropTypes.array.isRequired,
      setupComplete: PropTypes.bool.isRequired,
    }).isRequired,
    hub: PropTypes.object,
    setAdvisor: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired,
    setAllClientNames: PropTypes.func.isRequired,
    setActiveClients: PropTypes.func.isRequired,
    setHouseholds: PropTypes.func.isRequired,
    setHub: PropTypes.func.isRequired,
    setNewUserModal: PropTypes.func.isRequired,
    setWorkflows: PropTypes.func.isRequired,
    setInProgress: PropTypes.func.isRequired,
    switchHub: PropTypes.func.isRequired,
  };

  state = {
    activeTab: "privacypolicy",
    errorMessage: "",
    firstName: "",
    integrationChoice: "",
    lastName: "",
    loading: false,
    practiceName: "",
    policyLoaded: false,
    redtailPassword: "",
    redtailUsername: "",
    setupCompleteLoading: false,
    wealthboxApiKey: "",
    numAgreed: 0,
  };

  allPolicies = [];

  componentDidMount = () => {
    const { advisor } = this.props;

    GetPolicies().then((response) => {
      const policies = [];
      response.forEach((policy) => {
        if (
          advisor.policies.find((p) => {
            return p.id === policy.id;
          })
        )
          return;

        const policyMarkup = { __html: policy.text };

        const blob = new Blob([policy.text], { type: "text/html" });
        const blobLink = URL.createObjectURL(blob);

        policies.push({
          blob: blobLink,
          name: policy.name,
          type: policy.type,
          version: policy.version,
          markup: policyMarkup,
          id: policy.id,
          text: policy.text,
        });
      });
      this.allPolicies = policies;
      if (policies.length === 0) {
        this.setState({ activeTab: "about" });
      }
      this.setState({ policyLoaded: true });
    });

    // Fill in practice information if it exists and jump to about page
    if (advisor.practices.length > 0) {
      // Show the privacy policy section if one hasn't been accepted
      const policyAccepted =
        advisor.policies.length > 0 &&
        advisor.policies[advisor.policies.length - 1].active;
      this.setState({
        activeTab: policyAccepted ? "about" : "privacypolicy",
        firstName: advisor.firstName,
        lastName: advisor.lastName,
        practiceName: advisor.practices[0].name,
        integrationChoice: advisor.hubs?.[0]?.clientPool?.type || "",
        isAdvisorJoiningToExistingHub: Boolean(advisor?.hubs?.[0]),
      });
    }
  };

  agree = (policy, callback) => {
    const { numAgreed } = this.state;
    AcceptPolicy(policy.id)
      .then((response) => {
        if (callback) callback();
        this.setState({ numAgreed: numAgreed + 1 });
        if (numAgreed + 1 === this.allPolicies.length) {
          // all of them have been agreed to
          this.setState({ activeTab: "about" });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };

  showErrorMessage = () => {
    this.setState({
      errorMessage:
        "Something went wrong accessing with the key for this integration",
    });
  };

  createIntegration = () => {
    const {
      integrationChoice,
      redtailPassword,
      redtailUsername,
      wealthboxApiKey,
    } = this.state;
    const { advisor, hub, setAlert } = this.props;
    this.setState({ loading: true });

    let token = "";
    if (integrationChoice === "redtail") {
      token = `${redtailUsername}:${redtailPassword}`;
    } else if (integrationChoice === "wealthbox") {
      token = wealthboxApiKey;
    } else {
      setAlert({ type: "error", text: "Failed to create your integration." });
      return;
    }

    // TODO Add error checking for action
    const request = {
      type: integrationChoice,
      status: "connected",
      token: token,
      advisorId: advisor.id,
      hubId: hub.id,
    };
    // Create (and validate) integration
    CreateIntegration(request)
      .then(() => {
        this.startSync();
        this.setState({
          activeTab: "sync",
          loading: false,
        });
      })
      .catch((error) => {
        this.showErrorMessage();
        console.error(error);
        this.setState({ loading: false });
        // HTTP 409 CONFLICT, indicates there is already a matching integration (probably failed), so you can proceed with the sync
        if (error.response.status === 409) {
          this.startSync();
          this.setState({ activeTab: "sync" });
        }
      });
  };

  aboutFieldsAreValid = () => {
    const { firstName, lastName, practiceName } = this.state;
    return (
      firstName &&
      lastName &&
      practiceName &&
      firstName.trim().length &&
      lastName.trim().length &&
      practiceName.trim().length
    );
  };

  redtailFieldsAreValid = () => {
    const { redtailUsername, redtailPassword } = this.state;
    return redtailUsername && redtailPassword;
  };

  wealthboxFieldsAreValid = () => {
    const { wealthboxApiKey } = this.state;
    return wealthboxApiKey && wealthboxApiKey.trim().length === 32;
  };

  completeClientSync = () => {
    const {
      hub,
      setAdvisor,
      setAllClientNames,
      setActiveClients,
      setAlert,
      setHub,
      setHouseholds,
      setInProgress,
      setWorkflows,
    } = this.props;

    GetAdvisor().then((resp) => {
      setAdvisor(resp);
    });
    GetWorkflows(hub).then((resp) => {
      setWorkflows(resp);
      setInProgress(false, 0);
    });
    GetAllClients(hub).then((resp) => {
      setActiveClients(resp);
    });
    GetAllClientNames(hub).then((resp) => {
      setAllClientNames(resp);
    });
    GetHouseholds(hub).then((resp) => {
      setHouseholds(resp);
    });
    GetHub(hub.id).then((resp) => {
      setHub(resp);
    });
    this.setState({
      activeTab: "complete",
      loading: false,
    });
    setAlert({
      type: "success",
      text: `Client Sync Complete! Welcome to Hubly.`,
    });
  };

  syncClientsFailed = () => {
    const { setInProgress } = this.props;
    this.setState({
      activeTab: "failed",
      loading: false,
    });
    setInProgress(false, 0);
  };

  startSync = () => {
    const { advisor, hub, setNewUserModal, setInProgress } = this.props;
    setNewUserModal(true);
    this.setState({
      activeTab: "sync",
      loading: true,
    });

    const request = {
      type: "contacts",
      advisor_id: advisor.id,
      hub_id: hub.id,
      status: "in_progress",
    };

    setInProgress(true, 0);

    CreateSync(request)
      .then((response) => {
        const interval = setInterval(this.checkSyncComplete, 1000);
        this.syncId = response.id;
        this.pollInterval = interval;
      })
      .catch((error) => {
        this.syncClientsFailed();
      });
  };

  checkSyncComplete = () => {
    const { pollInterval, syncId } = this;
    const { setInProgress } = this.props;

    GetSync(syncId).then((response) => {
      let percent = 10; // Set to a default value until we know the number of total pages
      if (response.totalPages) {
        percent = (response.currentPage / response.totalPages) * 100;
      }
      setInProgress(true, percent);
      if (response.status === "failed") {
        clearInterval(pollInterval);
        this.syncClientsFailed();
      } else if (response.status === "completed") {
        clearInterval(pollInterval);
        this.completeClientSync();
      }
    });
  };

  setPracticeInfo = async () => {
    const { advisor, setHub } = this.props;
    const { firstName, lastName, practiceName } = this.state;
    this.setState({
      loading: true,
    });

    if (advisor.practices.length > 0) {
      EditAdvisor(advisor.id, { firstName, lastName })
        .then((resp) => {
          this.setState({
            activeTab: "integration",
            loading: false,
          });

          setHub(resp.hubs[0]);
        })
        .catch((error) => {
          console.error("Error saving the advisor information.");
          console.error(error);
        });
    } else {
      try {
        await EditAdvisor(advisor.id, { firstName, lastName });
        const response = await CreatePractice({ name: practiceName });
        setHub(response.hubs[0]);
        this.setState({
          activeTab: "integration",
          loading: false,
        });
      } catch (error) {
        console.error("Error saving the advisor and practice information.");
        console.error(error);
      }
    }
  };

  setSetupComplete = (type) => {
    const { advisor, hub, setAdvisor, setAlert, setNewUserModal, switchHub } =
      this.props;
    this.setState({
      setupCompleteLoading: true,
    });

    EditAdvisor(advisor.id, { setupComplete: true })
      .then((response) => {
        if (type) {
          const request = {
            type: type,
          };

          EditClientPool(hub.clientPool.id, request).then(() => {
            switchHub(hub);
            setAdvisor(response);
            setNewUserModal(false);
            setAlert({
              type: "success",
              text: `Setup Complete! Welcome to Hubly.`,
            });
            this.setState({
              setupCompleteLoading: false,
            });
          });
        } else {
          switchHub(hub);
          setAdvisor(response);
          setNewUserModal(false);
          setAlert({
            type: "success",
            text: `Setup Complete! Welcome to Hubly.`,
          });
          this.setState({
            setupCompleteLoading: false,
          });
        }
      })
      .catch((error) => {
        setAlert({ type: "error", text: `Failed to complete your setup.` });
        this.setState({
          setupCompleteLoading: false,
        });
        console.error("Error saving the setup complete status");
        console.error(error);
      });
  };

  selectRedtail = () => {
    const { hub } = this.props;
    const request = {
      type: "redtail",
    };

    EditClientPool(hub.clientPool.id, request).then(() => {
      this.setState({ integrationChoice: "redtail" });
    });
  };

  selectWealthbox = () => {
    const { hub } = this.props;
    const request = {
      type: "wealthbox",
    };

    EditClientPool(hub.clientPool.id, request).then(() => {
      this.setState({ integrationChoice: "wealthbox" });
    });
  };

  backToIntegrationChoice = () => {
    const { hub } = this.props;
    const request = {
      type: "",
    };

    EditClientPool(hub.clientPool.id, request).then(() => {
      this.setState({ integrationChoice: "" });
    });
  };

  render() {
    const {
      activeTab,
      errorMessage,
      firstName,
      integrationChoice,
      loading,
      lastName,
      practiceName,
      policyLoaded,
      redtailUsername,
      setupCompleteLoading,
      wealthboxApiKey,
      numAgreed,
      isAdvisorJoiningToExistingHub,
    } = this.state;
    const { advisor, hub } = this.props;
    const { piiMask } = this.context;
    const open = advisor && !advisor.setupComplete;
    let integrationName = "Wealthbox";
    if (integrationChoice === "redtail") {
      integrationName = "Redtail";
    }
    return (
      <Modal size="large" open={open} centered dimmer="inverted">
        <Modal.Header
          style={{
            padding: "0px",
          }}
        >
          <Step.Group
            size="large"
            fluid
            style={{
              borderBottomRightRadius: "0px",
              borderBottomLeftRadius: "0px",
              borderBottom: "none",
              width: "calc(100% + 2px)",
              transform: "translateX(-1px)",
            }}
          >
            {!policyLoaded ? (
              <Step
                active={activeTab === "privacypolicy"}
                style={{ padding: "1.4em 1em" }}
              >
                <Step.Content>
                  <Step.Title style={{ padding: "0" }}>
                    Loading Policies
                  </Step.Title>
                </Step.Content>
              </Step>
            ) : (
              this.allPolicies.map((p, index) => {
                return (
                  <Step
                    active={
                      index === numAgreed && activeTab === "privacypolicy"
                    }
                  >
                    <Step.Content>
                      <Step.Title style={{ padding: "0" }}>{p.name}</Step.Title>
                    </Step.Content>
                  </Step>
                );
              })
            )}
            <Step
              active={activeTab === "about"}
              style={{ padding: "1.4em 1em" }}
            >
              <Step.Content>
                <Step.Title style={{ padding: "0" }}>
                  Account Information
                </Step.Title>
              </Step.Content>
            </Step>
            <Step
              active={activeTab === "integration"}
              style={{ padding: "1.4em 1em" }}
            >
              <Step.Content>
                <Step.Title style={{ padding: "0" }}>Account Setup</Step.Title>
              </Step.Content>
            </Step>
            {integrationChoice !== "" && integrationChoice !== "hubly" && (
              <Step
                active={activeTab === "sync" || activeTab === "complete"}
                style={{ padding: "1.4em 1em" }}
              >
                <Step.Content>
                  <Step.Title style={{ padding: "0" }}>Client Sync</Step.Title>
                </Step.Content>
              </Step>
            )}
          </Step.Group>
        </Modal.Header>
        {activeTab === "privacypolicy" && (
          <React.Fragment>
            {policyLoaded ? (
              this.allPolicies.map((p, index) => {
                return (
                  <AgreementArea
                    policy={p}
                    agree={this.agree}
                    index={index}
                    numAgreed={numAgreed}
                  />
                );
              })
            ) : (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  height: "100px",
                }}
              >
                <div
                  style={{
                    margin: "auto",
                    width: "max-content",
                    color: "grey",
                  }}
                >
                  Loading...
                </div>
              </div>
            )}
          </React.Fragment>
        )}
        {activeTab !== "privacypolicy" && (
          <React.Fragment>
            <Modal.Content
              onScroll={this.scrolled}
              scrolling
              style={{ minHeight: "250px" }}
            >
              {activeTab === "about" && (
                <div>
                  <p>
                    Welcome to Hubly! Let’s get started by collecting your basic
                    account information.
                  </p>
                  <Form>
                    <Form.Field inline>
                      <label style={{ minWidth: "100px" }}>First Name</label>
                      <Input
                        autoComplete="off"
                        placeholder="First Name"
                        name="firstName"
                        value={firstName}
                        onChange={this.handleChange}
                        style={{ marginBottom: "0.5em" }}
                        disabled={loading}
                        loading={loading}
                      />
                    </Form.Field>
                    <Form.Field inline>
                      <label style={{ minWidth: "100px" }}>Last Name</label>
                      <Input
                        autoComplete="off"
                        placeholder="Last Name"
                        name="lastName"
                        value={lastName}
                        onChange={this.handleChange}
                        style={{ marginBottom: "0.5em" }}
                        disabled={loading}
                        loading={loading}
                      />
                    </Form.Field>
                    <Form.Field inline>
                      <label style={{ minWidth: "100px" }}>Practice Name</label>
                      <Input
                        autoComplete="off"
                        placeholder="Practice Name"
                        name="practiceName"
                        value={practiceName}
                        onChange={this.handleChange}
                        disabled={loading || advisor.practices.length > 0}
                        loading={loading}
                      />
                    </Form.Field>
                  </Form>
                </div>
              )}
              {activeTab === "integration" && hub && (
                <React.Fragment>
                  {hub.clientPool && hub.clientPool.type !== "hubly" ? (
                    <React.Fragment>
                      {!!integrationChoice && (
                        <p>
                          We import your clients automatically for you so you
                          don&apos;t have to. Please select your source of
                          import below
                        </p>
                      )}
                      {integrationChoice === "redtail" && (
                        <Dimmer.Dimmable dimmed={loading}>
                          <Segment basic fluid>
                            <Message
                              onDismiss={() => {
                                this.setState({ errorMessage: "" });
                              }}
                              hidden={errorMessage === ""}
                              error
                              header="Error!"
                              content="Something went wrong accessing Redtail with your username and password"
                            />
                            <Form>
                              <div style={{ paddingBottom: "1em" }}>
                                We keep your client data up-to-date by
                                integrating securely with Redtail CRM. Please
                                enter your username and password to allow us to
                                retrieve your Redtail User Key.
                              </div>
                              <Form.Group inline>
                                <Form.Field inline>
                                  <label>Username</label>
                                  <Input
                                    autoComplete="off"
                                    className={piiMask(
                                      "fs-block dd-privacy-mask"
                                    )}
                                    name="redtailUsername"
                                    value={redtailUsername}
                                    style={{ width: "300px" }}
                                    onChange={this.handleChange}
                                    placeholder="ex. frodobaggins"
                                  />
                                </Form.Field>
                                <Form.Field inline>
                                  <label>Password</label>
                                  <Input
                                    autoComplete="off"
                                    className={piiMask(
                                      "fs-block dd-privacy-mask"
                                    )}
                                    key="redtailPassword"
                                    name="redtailPassword"
                                    onChange={this.handleChange}
                                    placeholder="Password"
                                    type="password"
                                  />
                                </Form.Field>
                              </Form.Group>
                            </Form>
                            <p>
                              Providing this API key will grant Hubly access to
                              the following Redtail information:
                            </p>
                            <List relaxed>
                              <List.Item>
                                <Icon color="green" name="checkmark" />
                                <List.Content>
                                  Personal account information
                                </List.Content>
                              </List.Item>
                              <List.Item>
                                <Icon color="green" name="checkmark" />
                                <List.Content>
                                  All contact records and details
                                </List.Content>
                              </List.Item>
                              <List.Item>
                                <Icon color="green" name="checkmark" />
                                <List.Content>
                                  All comments and tags
                                </List.Content>
                              </List.Item>
                            </List>
                          </Segment>
                          {!isAdvisorJoiningToExistingHub && (
                            <Button
                              content="Back"
                              onClick={() => {
                                this.backToIntegrationChoice();
                              }}
                            />
                          )}
                        </Dimmer.Dimmable>
                      )}
                      {integrationChoice === "wealthbox" && (
                        <Dimmer.Dimmable dimmed={loading}>
                          <Segment basic fluid>
                            <Message
                              onDismiss={() => {
                                this.setState({ errorMessage: "" });
                              }}
                              hidden={errorMessage === ""}
                              error
                              header="Error!"
                              content="Something went wrong accessing CRM with this API key"
                            />
                            <Form>
                              <div style={{ paddingBottom: "1em" }}>
                                We keep your client data up-to-date by
                                integrating securely with Wealthbox CRM. Please
                                enter or retrieve your API key to get started.
                              </div>
                              <Form.Group inline>
                                <Form.Field inline>
                                  <label>API key</label>
                                  <Input
                                    autoComplete="off"
                                    className={piiMask(
                                      "fs-block dd-privacy-mask"
                                    )}
                                    name="wealthboxApiKey"
                                    value={wealthboxApiKey}
                                    style={{ width: "300px" }}
                                    onChange={this.handleChange}
                                    placeholder="ex. a6804e94f64042eaa8362ce31d89453f"
                                  />
                                </Form.Field>
                                <a
                                  href="https://app.crmworkspace.com/settings/access_tokens"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Click here to login to Wealthbox and retrieve
                                  your access token.
                                </a>
                              </Form.Group>
                            </Form>
                            <p>
                              Providing this API key will grant Hubly access to
                              the following Wealthbox information:
                            </p>
                            <List relaxed>
                              <List.Item>
                                <Icon color="green" name="checkmark" />
                                <List.Content>
                                  Personal account information
                                </List.Content>
                              </List.Item>
                              <List.Item>
                                <Icon color="green" name="checkmark" />
                                <List.Content>
                                  All contact records and details
                                </List.Content>
                              </List.Item>
                              <List.Item>
                                <Icon color="green" name="checkmark" />
                                <List.Content>
                                  All comments and tags
                                </List.Content>
                              </List.Item>
                            </List>
                          </Segment>
                          {!isAdvisorJoiningToExistingHub && (
                            <Button
                              content="Back"
                              onClick={() => {
                                this.backToIntegrationChoice();
                              }}
                            />
                          )}
                        </Dimmer.Dimmable>
                      )}
                      {integrationChoice === "" && (
                        <Card.Group>
                          <Card
                            data-test="wealthbox-card"
                            className="grey_on_hover p25"
                            onClick={() => {
                              this.selectWealthbox();
                            }}
                          >
                            <Card.Content>
                              <Image
                                floated="right"
                                size="mini"
                                src="/images/logos/wealthbox-logo.png"
                              />
                              <Card.Header>Wealthbox</Card.Header>
                              <Card.Meta>CRM</Card.Meta>
                              <Card.Description>
                                Modern CRM for Financial Advisors
                              </Card.Description>
                            </Card.Content>
                          </Card>
                          <Card
                            data-test="redtail-card"
                            className="grey_on_hover p25"
                            onClick={() => {
                              this.selectRedtail();
                            }}
                          >
                            <Card.Content>
                              <Image
                                floated="right"
                                size="mini"
                                src="/images/logos/redtail-logo.png"
                              />
                              <Card.Header>Redtail</Card.Header>
                              <Card.Meta>CRM</Card.Meta>
                              <Card.Description>
                                CRM tool to provide advisors anytime, anywhere
                                access to their client and calendar data
                              </Card.Description>
                            </Card.Content>
                          </Card>
                          <Card
                            data-test="hubly-card"
                            className="grey_on_hover p25"
                            onClick={() => {
                              this.setSetupComplete("hubly");
                            }}
                          >
                            <Card.Content>
                              <Image
                                floated="right"
                                size="mini"
                                src="/images/favicon.png"
                              />
                              <Card.Header>Hubly</Card.Header>
                              <Card.Meta>No Integration</Card.Meta>
                              <Card.Description>
                                Clients will need to be added manually when
                                using Hubly with an external CRM
                              </Card.Description>
                            </Card.Content>
                          </Card>
                        </Card.Group>
                      )}
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                          padding: "3em 0px",
                        }}
                      >
                        <div>
                          <Icon name="thumbs up outline" size="huge" />
                        </div>
                        <div
                          style={{ padding: "1em 0", fontSize: "1.42857143em" }}
                        >
                          Account setup complete! Welcome to Hubly.
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
              {activeTab === "sync" ? (
                <div
                  style={{
                    width: "100%",
                    minHeight: "200px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Spinner active size="huge" />
                    <div style={{ padding: "1em 0", fontSize: "1.42857143em" }}>
                      Integration Successful.
                    </div>
                    <div
                      style={{ fontSize: "1.42857143em" }}
                    >{`Your ${integrationName} clients are syncing.`}</div>
                  </div>
                </div>
              ) : null}
              {activeTab === "complete" && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    padding: "3em 0px",
                  }}
                >
                  <div>
                    <Icon name="check circle" size="huge" />
                  </div>
                  <div style={{ padding: "1em 0", fontSize: "1.42857143em" }}>
                    {`Successfully imported clients from ${integrationName}.`}
                  </div>
                </div>
              )}
              {activeTab === "failed" && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    padding: "3em 0px",
                  }}
                >
                  <div>
                    <Icon name="warning circle" size="huge" />
                  </div>
                  <div style={{ padding: "1em 0", fontSize: "1.42857143em" }}>
                    Something went wrong.
                  </div>
                  <div style={{ fontSize: "1.42857143em" }}>
                    Please try again.
                  </div>
                </div>
              )}
            </Modal.Content>
            <Modal.Actions
              style={{
                overflow: "auto",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <img src="/images/hubly.png" alt="hubly logo" height="25" />
              {activeTab === "about" && (
                <Button
                  data-test="create-practice-button"
                  content="Next"
                  primary
                  disabled={!this.aboutFieldsAreValid() || loading}
                  loading={loading}
                  onClick={this.setPracticeInfo}
                />
              )}
              {activeTab === "integration" && integrationChoice === "redtail" && (
                <div>
                  <span
                    style={{
                      color: "grey",
                      paddingRight: "1em",
                      fontSize: "10pt",
                    }}
                  >
                    By clicking “Next” you agree to grant Hubly access to the
                    Redtail details outlined above.
                  </span>
                  <Button
                    data-test="create-redtail-button"
                    content="Next"
                    primary
                    disabled={!this.redtailFieldsAreValid() || loading}
                    loading={loading}
                    onClick={this.createIntegration}
                  />
                </div>
              )}
              {activeTab === "integration" &&
                integrationChoice === "wealthbox" && (
                  <div>
                    <span
                      style={{
                        color: "grey",
                        paddingRight: "1em",
                        fontSize: "10pt",
                      }}
                    >
                      By clicking “Next” you agree to grant Hubly access to the
                      Wealthbox details outlined above.
                    </span>
                    <Button
                      data-test="create-wealthbox-button"
                      content="Next"
                      primary
                      disabled={!this.wealthboxFieldsAreValid() || loading}
                      loading={loading}
                      onClick={this.createIntegration}
                    />
                  </div>
                )}
              {activeTab === "integration" &&
                (integrationChoice === "" || integrationChoice === "hubly") &&
                hub?.clientPool?.type === "hubly" && (
                  <div>
                    <Button
                      data-test="enter-hub-button"
                      content="Enter Hub"
                      disabled={setupCompleteLoading}
                      loading={setupCompleteLoading}
                      primary
                      onClick={() => {
                        this.setSetupComplete("hubly");
                      }}
                    />
                  </div>
                )}
              {(activeTab === "sync" || activeTab === "complete") && (
                <div>
                  {activeTab === "sync" && (
                    <span
                      style={{
                        color: "grey",
                        paddingRight: "1em",
                        fontSize: "10pt",
                      }}
                    >
                      You will not be able to see your clients until the sync is
                      complete.
                    </span>
                  )}
                  <Button
                    data-test="enter-hub-button"
                    content="Enter Hub"
                    disabled={setupCompleteLoading}
                    loading={setupCompleteLoading}
                    primary
                    onClick={() => {
                      this.setSetupComplete(null);
                    }}
                  />
                </div>
              )}
              {activeTab === "failed" && (
                <div style={{ display: "flex" }}>
                  <div style={{ float: "left", marginRight: "1em" }}>
                    <Button
                      content="Skip Sync"
                      onClick={() => {
                        this.setSetupComplete(null);
                      }}
                    />
                  </div>
                  <div>
                    <Button
                      content="Try Again"
                      onClick={this.startSync}
                      primary
                    />
                  </div>
                </div>
              )}
            </Modal.Actions>
          </React.Fragment>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    advisor: state.hubly.data.advisor,
    hub: state.hubly.data.hub.selected.hub,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAdvisor: (advisor) => {
      dispatch(SetAdvisor(advisor));
    },
    setAlert: (alert) => {
      dispatch(SetAlert(alert));
    },
    setAllClientNames: (clients) => {
      dispatch(SetAllClientNames(clients));
    },
    setActiveClients: (clients) => {
      dispatch(SetActiveClients(clients));
    },
    setHouseholds: (households) => {
      dispatch(SetHouseholds(households));
    },
    setInProgress: (inProgress, percent) => {
      dispatch(SetInProgress(inProgress, percent));
    },
    setHub: (hub) => {
      dispatch(SetHub(hub));
    },
    setNewUserModal: (open) => {
      dispatch(SetNewUserModal(open));
    },
    setWorkflows: (workflows) => {
      dispatch(SetWorkflows(workflows));
    },
    switchHub: (hub) => {
      history.push(`/hub/${hub.hubId}`);
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewUserModal);
