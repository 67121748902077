import { SetClientWorkflows } from "data/hub/clientWorkflows/actions";
import { SetWorkflow } from "data/hub/workflows/actions";
import {
  AddTemplate,
  DeleteTemplate,
  EditTemplate,
} from "data/libs/emailTemplates";

export const AddEmailTemplate = (
  clientId,
  workflowId,
  taskId,
  to,
  subject,
  body,
  callback
) => {
  return (dispatch, getState) => {
    const request = {
      to: to,
      subject: subject,
      body: body,
    };
    AddTemplate(taskId, request)
      .then((response) => {
        if (clientId) {
          const { clientWorkflows } = getState().hubly.data.hub;
          const foundWorkflow = clientWorkflows[workflowId];
          const foundTask = foundWorkflow.tasks.find((task) => {
            return task.id === taskId;
          });

          foundTask.emailTemplate = response;
          dispatch(SetClientWorkflows(Object.values(clientWorkflows)));
        } else {
          const foundWorkflow = {
            ...getState().hubly.data.hub.workflows[workflowId],
          };
          if (!foundWorkflow) {
            console.warn(
              "AddEmailTemplate, Workflow ID ",
              workflowId,
              " does not exist"
            );
            return;
          }

          const foundTask = foundWorkflow.tasks.find((task) => {
            return task.id === taskId;
          });

          if (!foundTask) {
            console.warn(
              "AddEmailTemplate, Workflow Task ID ",
              taskId,
              " does not exist"
            );
            return;
          }

          foundTask.emailTemplate = response;
          dispatch(SetWorkflow(foundWorkflow));
        }

        callback(true);
      })
      .catch((error) => {
        console.error(error);
        callback(false);
      });
  };
};

export const EditEmailTemplate = (
  clientId,
  workflowId,
  taskId,
  templateId,
  to,
  subject,
  body,
  callback
) => {
  return (dispatch, getState) => {
    const request = {
      to: to,
      subject: subject,
      body: body,
    };
    EditTemplate(templateId, request)
      .then((response) => {
        if (clientId) {
          const { clientWorkflows } = getState().hubly.data.hub;
          const foundWorkflow = clientWorkflows[workflowId];
          const foundTask = foundWorkflow.tasks.find((task) => {
            return task.id === taskId;
          });

          foundTask.emailTemplate = response;
          dispatch(SetClientWorkflows(Object.values(clientWorkflows)));
        } else {
          const foundWorkflow = {
            ...getState().hubly.data.hub.workflows[workflowId],
          };
          if (!foundWorkflow) {
            console.warn(
              "AddEmailTemplate, Workflow ID ",
              workflowId,
              " does not exist"
            );
            return;
          }

          const foundTask = foundWorkflow.tasks.find((task) => {
            return task.id === taskId;
          });

          if (!foundTask) {
            console.warn(
              "AddEmailTemplate, Workflow Task ID ",
              taskId,
              " does not exist"
            );
            return;
          }

          foundTask.emailTemplate = response;
          dispatch(SetWorkflow(foundWorkflow));
        }

        callback(true);
      })
      .catch((error) => {
        console.error(error);
        callback(false);
      });
  };
};

export const RemoveEmailTemplate = (
  clientId,
  workflowId,
  taskId,
  templateId,
  callback
) => {
  return (dispatch, getState) => {
    DeleteTemplate(templateId)
      .then((response) => {
        if (clientId) {
          const { clientWorkflows } = getState().hubly.data.hub;
          const foundWorkflow = clientWorkflows[workflowId];
          const foundTask = foundWorkflow.tasks.find((task) => {
            return task.id === taskId;
          });

          foundTask.emailTemplate = response;
          dispatch(SetClientWorkflows(Object.values(clientWorkflows)));
        } else {
          const foundWorkflow = {
            ...getState().hubly.data.hub.workflows[workflowId],
          };
          if (!foundWorkflow) {
            console.warn(
              "AddEmailTemplate, Workflow ID ",
              workflowId,
              " does not exist"
            );
            return;
          }

          const foundTask = foundWorkflow.tasks.find((task) => {
            return task.id === taskId;
          });

          if (!foundTask) {
            console.warn(
              "AddEmailTemplate, Workflow Task ID ",
              taskId,
              " does not exist"
            );
            return;
          }

          foundTask.emailTemplate = response;
          dispatch(SetWorkflow(foundWorkflow));
        }

        callback(true);
      })
      .catch((error) => {
        console.error(error);
        callback(false);
      });
  };
};
