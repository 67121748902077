import React from "react";
import PropTypes from "prop-types";

import { Button } from "semantic-ui-react";

export function TopButtons({
  disabled,
  showAll,
  onShowAllClick,
  onShowActiveClick,
  onCreateProcessClick,
}) {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Button
        icon={showAll ? "eye" : "eye slash"}
        content={showAll ? "Show All" : "Hide All"}
        basic
        style={{
          width: "32%",
          boxSizing: "border-container",
          margin: "0px",
          marginRight: "1em",
          marginBottom: "1em",
        }}
        onClick={() => {
          onShowAllClick(showAll);
        }}
        disabled={disabled}
      />
      <Button
        icon="check circle"
        content="Show Active"
        basic
        style={{
          width: "32%",
          boxSizing: "border-container",
          margin: "0px",
          marginRight: "1em",
          marginBottom: "1em",
        }}
        onClick={onShowActiveClick}
        disabled={disabled}
      />
      <Button
        icon="plus"
        content="Create Process"
        basic
        style={{
          width: "32%",
          boxSizing: "border-container",
          margin: "0px",
          marginBottom: "1em",
        }}
        onClick={() => {
          onCreateProcessClick(true);
        }}
        disabled={disabled}
      />
    </div>
  );
}

TopButtons.defaultProps = {
  disabled: false,
  showAll: true,
};

TopButtons.propTypes = {
  disabled: PropTypes.bool,
  onShowAllClick: PropTypes.func.isRequired,
  onShowActiveClick: PropTypes.func.isRequired,
  onCreateProcessClick: PropTypes.func.isRequired,
  showAll: PropTypes.bool,
};

export default TopButtons;
