import React, { useCallback, useContext } from "react";
import PropTypes from "prop-types";

import {
  HubContext,
  ToasterContext,
} from "scenes/Hubly/components/Workspace/Provider";

import { ClientTileEditor } from "./ClientTileEditor";

export function Connector({ id, name, clientTileOptions, onClose }) {
  const { editWorkflow } = useContext(HubContext);
  const { fireSuccess, fireError, fireWarning } = useContext(ToasterContext);

  return (
    <ClientTileEditor
      title={name}
      current={clientTileOptions}
      submit={useCallback(
        (v) => {
          return editWorkflow(id, {
            options: { presentWorkflowName: v.presentWorkflowName },
            ...v,
          });
        },
        [id, editWorkflow]
      )}
      onClose={onClose}
      onSuccess={fireSuccess}
      onWarning={fireWarning}
      onError={fireError}
    />
  );
}

Connector.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  clientTileOptions: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Connector;
