import { API } from "aws-amplify";

import SyncedAPI from "data/libs/syncedAPI";

import { getAPIReq } from "./authLib";

export const CreateWorkflowRule = async (request) => {
  const req = await getAPIReq(request);
  return SyncedAPI.post("HublyAPI", `/workflowRules/`, req);
};

export const GetWorkflowRules = async () => {
  const req = await getAPIReq();
  return API.get("HublyAPI", `/workflowRules/`, req);
};

export const GetWorkflowRule = async (id) => {
  const req = await getAPIReq();
  return API.get("HublyAPI", `/workflowRules/${id}/`, req);
};

export const EditWorkflowRule = async (id, request) => {
  const req = await getAPIReq(request);
  return SyncedAPI.put("HublyAPI", `/workflowRules/${id}/`, req);
};

export const DeleteWorkflowRule = async (id, workflowId) => {
  const req = await getAPIReq();
  return SyncedAPI.del("HublyAPI", `/workflowRules/${id}/`, req);
};
