import React from "react";
import PropTypes from "prop-types";

import Spinner from "components/Spinner";

export function Loader({ children }) {
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        backgroundColor: "#eee",
      }}
    >
      <Spinner active size="large" />
      <div style={{ color: "grey" }}>{children}</div>
    </div>
  );
}

Loader.propTypes = {
  children: PropTypes.string,
};

Loader.defaultProps = {
  children: "Loading ....",
};

export default Loader;
