export function useFilterProcessor() {
  const toFilter = (key, group) => {
    if (key === "ALL" || key === "UNASSIGNED" || key === "CURRENT") {
      return { assignee: null, assigneeFilterType: key };
    }

    return {
      assignee: key,
      assigneeFilterType: group ? "SERVICE_ROLE" : "ASSIGNEE",
    };
  };

  const fromFilter = (assignee, assigneeFilterType) => {
    if (!assignee) return assigneeFilterType;

    return assignee;
  };

  return { toFilter, fromFilter };
}

export default useFilterProcessor;
