export const SetViewModalOpen = (
  open,
  editView = { id: "", name: "", description: "" }
) => {
  return {
    type: "SET_VIEW_MODAL_OPEN",
    viewModalOpen: open,
    editView: editView,
  };
};
export default SetViewModalOpen;
