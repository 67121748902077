import React from "react";
import PropTypes from "prop-types";

import { Button, Icon } from "semantic-ui-react";

import Popper from "components/Popper";

export function PopupButton({
  children,
  label,
  highlight,
  icon,
  onOpen,
  ...props
}) {
  return (
    <Popper
      on="click"
      className="dont_padd"
      onOpen={onOpen}
      trigger={
        <Button
          data-test="popup-button"
          basic
          primary={highlight}
          style={{
            marginRight: "0.5em",
            minWidth: "110px",
            whiteSpace: "nowrap",
          }}
          {...props}
        >
          {icon && <Icon name={icon} />}
          {label}
        </Button>
      }
    >
      {children}
    </Popper>
  );
}

PopupButton.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string,
  highlight: PropTypes.bool,
  icon: PropTypes.string,
  onOpen: PropTypes.func,
};

PopupButton.defaultProps = {
  children: null,
  label: undefined,
  highlight: false,
  icon: undefined,
  onOpen: () => {},
};

export default PopupButton;
