export function getAdvisors() {
  const query = `query getAdvisors {
        advisors(isActive: true) {
          edges {
            node {
              id
              name
              crmUsers {
                edges {
                  node {
                    listType
                  }
                }
              }
            }
          }
        }
      }
      `;

  const resolver = ({ data: { advisors } }) => {
    return advisors.edges.map(({ node }) => {
      return {
        ...node,
        listTypes: node.crmUsers.edges.map(({ node: cNode }) => {
          return cNode.listType;
        }),
      };
    });
  };

  return { query, resolver };
}

export default getAdvisors;
