import { format } from "date-fns";

const isDateObject = (date) => {
  return date instanceof Date;
};

const dateFormats = {
  simpleDate: "MMMM d, yyyy",
  simpleDateAbbreviated: "MMM d, yyyy",
  simpleDateTime: "MMMM d, yyyy h:mm a",
};

export const formatDate = (type) => {
  if (!(type in dateFormats)) {
    throw Error(
      "An incorrect date type was supplied. It must be one of the following: simpleDate, simpleDateAbbreviated, simpleDateTime"
    );
  }
  return (date) => {
    return isDateObject(date) ? format(date, dateFormats[type]) : "";
  };
};

export default formatDate;
