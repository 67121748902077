import React from "react";
import PropTypes from "prop-types";

import { useBooleanState } from "hooks";

import {
  useAssignServiceTeamAction,
  useClientServiceTeam,
  useClientWorkflows,
} from "./hooks";
import { ClientServiceTeam } from "./ClientServiceTeam";
import {
  ManageUserRolesModal,
  ServiceTeamList,
  TeamAssigner,
} from "./components";

function Connector({ clientId }) {
  const [isOpen, openModal, closeModal] = useBooleanState();
  const { loading, data, numOfFetches, reloadData } =
    useClientServiceTeam(clientId);
  const reloadTasks = useClientWorkflows(clientId);
  const assignServiceTeam = useAssignServiceTeamAction(clientId, () => {
    reloadData();
    reloadTasks();
  });

  return (
    <React.Fragment>
      <ClientServiceTeam
        showLoading={loading && numOfFetches === 0}
        onClickSettings={openModal}
      >
        <TeamAssigner
          currentTeam={data?.serviceTeam}
          onAssign={assignServiceTeam}
        />
        <ServiceTeamList
          serviceTeam={data?.serviceTeam}
          serviceRoles={data?.serviceRoles}
          defaultTeam={data?.defaultTeam}
          onMissingAdvisorAssigned={() => {
            reloadTasks();
            reloadData();
          }}
        />
      </ClientServiceTeam>
      <ManageUserRolesModal
        open={isOpen}
        onClose={() => {
          reloadData();
          closeModal();
        }}
      />
    </React.Fragment>
  );
}

Connector.propTypes = {
  clientId: PropTypes.string.isRequired,
};

export default Connector;
