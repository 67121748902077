import React from "react";
import PropTypes from "prop-types";

import { Input } from "semantic-ui-react";

export function RangeInput({ min, max, value, onChange, onError, ...props }) {
  const handleNewValue = (newNumericValue, newValue) => {
    if (Number.isNaN(newNumericValue)) {
      return onError("Must be a numeric value", newValue);
    }
    if (newNumericValue > max) {
      return onChange(max, `Please enter number between ${min} to ${max}`);
    }
    if (newNumericValue < min) {
      return onChange(min, `Please enter number between ${min} to ${max}`);
    }

    return onChange(newNumericValue);
  };

  return (
    <Input
      onChange={(_, { value: newValue }) => {
        handleNewValue(Number(newValue), newValue);
      }}
      maxLength={String(max).length}
      value={value}
      {...props}
    />
  );
}

RangeInput.propTypes = {
  max: PropTypes.number,
  min: PropTypes.number,
  onError: PropTypes.func,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

RangeInput.defaultProps = {
  min: 0,
  max: Number.MAX_SAFE_INTEGER,
  onError: () => {},
};

export default RangeInput;
