import { useCallback, useState } from "react";

import { useQueryClient } from "components/QueryClient";

function useMutation(factory) {
  const client = useQueryClient();

  const mutation = async (variables) => {
    return client.query(variables, factory(), true);
  };

  return useCallback(mutation, [client, factory]);
}

export function useMutationWithState(factory) {
  const mutationFunc = useMutation(factory);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [errors, setErrors] = useState([]);

  const run = async (v) => {
    setLoading(true);

    const result = await mutationFunc(v);
    setData(result.data);
    setErrors(result.errors);

    setLoading(false);

    return result;
  };

  return [
    run,
    {
      data: data,
      errors: errors,
      loading: loading,
      isError: errors?.length > 0,
    },
  ];
}

export default useMutation;
