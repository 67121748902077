import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Auth } from "aws-amplify";
import {
  Button,
  Form,
  Header,
  Icon,
  Input,
  Message,
  Modal,
  Table,
} from "semantic-ui-react";

import { SetAdvisor, UpdateAdvisor } from "data/advisor/actions";
import { GetAdvisor } from "data/libs/advisor";

import Access from "components/Access";
import { SetAlert } from "components/Alerts/actions";
import Anchor from "components/Anchor";
import { FeatureFlag } from "components/FeatureFlag";

import { ToasterContextProvider } from "scenes/Hubly/components/Workspace/Provider";

import AddUserModal from "./components/AddUserModal";
import EditEmailModal from "./components/EditAdvisorModals";
import EditUserInformationModal from "./components/EditUserInformationModal";
import SendInvitationButton from "./components/SendInvitationButton";
import UserInfoActionMenu from "./components/UserInfoActionMenu/UserInfoActionMenu";

class UserInformationForm extends Component {
  static propTypes = {
    advisor: PropTypes.shape({
      id: PropTypes.string.isRequired,
      hubs: PropTypes.array.isRequired,
      role: PropTypes.object.isRequired,
    }).isRequired,
    setAdvisor: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired,
    updateAdvisor: PropTypes.func.isRequired,
    selectedHub: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      currentPassword: "",
      email: "",
      errorContent: "",
      errorTitle: "",
      firstName: "",
      infoModalAdvisorId: "",
      infoModalOpen: false,
      lastName: "",
      loading: false,
      newPassword: "",
      newPassword2: "",
      passwordChangeSuccess: false,
      passwordInvalid: false,
      passwordModalOpen: false,
      emailModalOpen: false,
      popupOpenId: null,
      role: "",
      roleId: "",
      showPassword: false,
      addNewUsersModalOpen: false,
    };
  }

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };

  saveInfoChanges = () => {
    const { email, firstName, infoModalAdvisorId, lastName } = this.state;
    const { setAdvisor, setAlert, updateAdvisor } = this.props;
    this.setState({ loading: true });
    updateAdvisor(
      infoModalAdvisorId,
      { firstName, lastName, email },
      (success) => {
        if (success) {
          GetAdvisor()
            .then((advisor) => {
              setAdvisor(advisor);
              this.setState({ infoModalOpen: false, loading: false });
            })
            .catch((error) => {
              console.error(error);
              this.setState({ loading: false });
              setAlert({ type: "error", text: "Failed to update Hub users." });
            });
        } else {
          setAlert({
            type: "error",
            text: "Failed to update User Information.",
          });
          this.setState({ loading: false });
        }
      }
    );
  };

  savePasswordChanges = async () => {
    this.setState({ errorTitle: "", errorContent: "", loading: true });
    const { currentPassword, newPassword, newPassword2 } = this.state;
    if (newPassword !== newPassword2) {
      this.setState({
        errorTitle: "New Passwords do not Match",
        loading: false,
      });
      return;
    }

    Auth.currentAuthenticatedUser()
      .then((user) => {
        Auth.changePassword(user, currentPassword, newPassword)
          .then((response) => {
            this.setState({ loading: false, passwordChangeSuccess: true });
          })
          .catch((error) => {
            console.error(error);
            if (error.code === "LimitExceededException") {
              this.setState({
                errorTitle: "Error: Trying to change your password too often",
              });
            } else if (
              error.code === "InvalidPasswordException" ||
              error.code === "NotAuthorizedException"
            ) {
              this.setState({
                errorTitle: "Invalid Password",
                errorContent:
                  "Forgot your password? Please Sign Out and select ‘Reset it’ from the log-in page.",
              });
            } else if (error.code === "InvalidParameterException") {
              this.setState({ passwordInvalid: true });
            }
          })
          .finally(() => {
            this.setState({ loading: false });
          });
      })
      .catch((err) => {
        console.error(err);
        this.setState({ loading: false });
      });
  };

  openInfoModal = (advisor) => {
    this.setState({
      email: advisor.email,
      firstName: advisor.firstName,
      infoModalAdvisorId: advisor.id,
      infoModalOpen: true,
      lastName: advisor.lastName,
      popupOpenId: null,
      role: advisor.role.title,
      roleId: advisor.role.id,
    });
  };

  openPasswordModal = (advisor) => {
    this.setState({
      currentPassword: "",
      errorContent: "",
      errorTitle: "",
      newPassword: "",
      newPassword2: "",
      passwordChangeSuccess: false,
      passwordInvalid: false,
      passwordModalOpen: true,
      popupOpenId: null,
      showPassword: false,
    });
  };

  openEmailModal = (advisor) => {
    this.setState({
      email: advisor.email,
      emailModalOpen: true,
      popupOpenId: null,
    });
  };

  closeEmailModal = () => {
    this.setState({
      emailModalOpen: false,
    });
  };

  toggleShowPassword = () => {
    this.setState((state) => {
      return { showPassword: !state.showPassword };
    });
  };

  render() {
    const {
      currentPassword,
      email,
      errorContent,
      errorTitle,
      infoModalOpen,
      infoModalAdvisorId,
      firstName,
      lastName,
      loading,
      newPassword,
      newPassword2,
      passwordChangeSuccess,
      passwordInvalid,
      passwordModalOpen,
      emailModalOpen,
      popupOpenId,
      role,
      roleId,
      showPassword,
      addNewUsersModalOpen,
    } = this.state;

    const { advisor, selectedHub, setAlert } = this.props;

    const theHub = advisor.hubs.find(({ id }) => {
      return id === selectedHub.id;
    });

    return (
      <ToasterContextProvider fireToaster={setAlert}>
        <div
          id="UserInformationForm"
          data-test="user-information-section"
          style={{ marginTop: "1em" }}
        >
          <Header as="h1">Users</Header>
          <div>
            <Table
              data-test="user-information-table"
              selectable={advisor.role.title === "Administrator"}
              stackable
            >
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={3}>Name</Table.HeaderCell>
                  <Table.HeaderCell width={3}>Email</Table.HeaderCell>
                  <Table.HeaderCell width={3}>
                    <span>Permissions</span>
                  </Table.HeaderCell>
                  <Table.HeaderCell width={3}>Status</Table.HeaderCell>
                  <Table.HeaderCell width={1} />
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {(theHub?.advisors || [])
                  .sort((a, b) => {
                    return a.createdAt < b.createdAt;
                  })
                  .map((loopAdvisor) => {
                    return (
                      <Table.Row key={loopAdvisor.id}>
                        <Table.Cell>{`${loopAdvisor.firstName} ${loopAdvisor.lastName}`}</Table.Cell>
                        <Table.Cell>{loopAdvisor.email}</Table.Cell>
                        <Table.Cell>{loopAdvisor.role.title}</Table.Cell>
                        <Table.Cell>{loopAdvisor.status}</Table.Cell>
                        <Table.Cell>
                          <UserInfoActionMenu
                            userId={loopAdvisor.id}
                            isOpen={popupOpenId === loopAdvisor.id}
                            onMenuOpen={() => {
                              this.setState({
                                popupOpenId: loopAdvisor.id,
                              });
                            }}
                            onMenuClose={() => {
                              this.setState({ popupOpenId: null });
                            }}
                          >
                            <React.Fragment>
                              {loopAdvisor.id === advisor.id && (
                                <Button
                                  key="Edit Email Address"
                                  icon="envelope"
                                  content="Edit Email Address"
                                  onClick={() => {
                                    this.openEmailModal(loopAdvisor);
                                  }}
                                />
                              )}
                              <Button
                                key="Edit User Information"
                                icon="edit"
                                content="Edit User Information"
                                onClick={() => {
                                  this.openInfoModal(loopAdvisor);
                                }}
                              />
                              {loopAdvisor.id === advisor.id && (
                                <Button
                                  key="Change Password"
                                  icon="key"
                                  content="Change Password"
                                  onClick={() => {
                                    this.openPasswordModal(loopAdvisor);
                                  }}
                                />
                              )}
                              <FeatureFlag id="add-users">
                                {["Invite sent", "Invite not sent"].includes(
                                  loopAdvisor.status
                                ) && (
                                  <Access required={["Administrator"]}>
                                    <SendInvitationButton
                                      user={loopAdvisor}
                                      onInvitationSent={() => {
                                        const { setAdvisor } = this.props;
                                        GetAdvisor()
                                          .then((newAdvisor) => {
                                            setAdvisor(newAdvisor);
                                          })
                                          .catch((error) => {
                                            console.error(error);
                                            setAlert({
                                              type: "error",
                                              text: "Failed to update Hub users.",
                                            });
                                          });
                                      }}
                                    />
                                  </Access>
                                )}
                              </FeatureFlag>
                            </React.Fragment>
                          </UserInfoActionMenu>
                        </Table.Cell>
                      </Table.Row>
                    );
                  })}
              </Table.Body>
            </Table>
            <FeatureFlag id="add-users">
              <Access required={["Administrator"]}>
                <Button
                  color="blue"
                  content="Invite Users"
                  onClick={() => {
                    this.setState({ addNewUsersModalOpen: true });
                  }}
                />
              </Access>
            </FeatureFlag>
          </div>
          <EditEmailModal
            closeModal={this.closeEmailModal}
            isModalOpen={emailModalOpen}
            advisorEmail={email}
          />
          <Modal
            onClose={() => {
              this.setState({ passwordModalOpen: false });
            }}
            size="mini"
            open={passwordModalOpen}
            closeOnDimmerClick={false}
            style={{ minWidth: "360px" }}
          >
            <Modal.Header>Change Password</Modal.Header>
            <Modal.Content style={{ paddingTop: "0px" }}>
              {!passwordChangeSuccess ? (
                <Modal.Description style={{ paddingTop: "1em" }}>
                  <Form>
                    <Form.Field>
                      <label>Current Password</label>
                      <Input
                        name="currentPassword"
                        type="password"
                        value={currentPassword}
                        onChange={this.handleChange}
                      />
                    </Form.Field>
                    <Form.Field>
                      <label>
                        New Password&nbsp;&nbsp;&nbsp;
                        <Anchor onClick={this.toggleShowPassword}>
                          {showPassword ? "Hide" : "Show"}
                        </Anchor>
                      </label>
                      <Input
                        name="newPassword"
                        type={showPassword ? "text" : "password"}
                        value={newPassword}
                        onChange={this.handleChange}
                      />
                    </Form.Field>
                    <Form.Field>
                      <label>Confirm New Password</label>
                      <Input
                        name="newPassword2"
                        type={showPassword ? "text" : "password"}
                        value={newPassword2}
                        onChange={this.handleChange}
                      />
                    </Form.Field>
                  </Form>
                  <Message error hidden={!errorTitle.length}>
                    <Message.Header>{errorTitle}</Message.Header>
                    <p>{errorContent}</p>
                  </Message>
                  {passwordInvalid ? (
                    <Message error hidden={!passwordInvalid}>
                      <Message.Header>Invalid Password</Message.Header>
                      <div style={{ fontSize: "10pt", marginTop: "1em" }}>
                        Passwords must contain:
                        <ul style={{ margin: "0px", paddingLeft: "1em" }}>
                          <li>8 or more characters long</li>
                          <li>Have at least one uppercase letter</li>
                          <li>
                            One lowercase letter and one special character
                          </li>
                        </ul>
                      </div>
                    </Message>
                  ) : (
                    <div
                      style={{
                        fontSize: "10pt",
                        color: "grey",
                        marginTop: "1em",
                      }}
                    >
                      Passwords must contain:
                      <ul style={{ margin: "0px", paddingLeft: "1em" }}>
                        <li>8 or more characters long</li>
                        <li>Have at least one uppercase letter</li>
                        <li>One lowercase letter and one special character</li>
                      </ul>
                    </div>
                  )}
                </Modal.Description>
              ) : (
                <Modal.Description>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      padding: "3em 0px",
                    }}
                  >
                    <div>
                      <Icon name="check circle" size="huge" />
                    </div>
                    <div style={{ padding: "2em 0px", color: "grey" }}>
                      Your password has been reset successfully
                    </div>
                    <div>
                      <Button
                        primary
                        content="Continue"
                        onClick={() => {
                          this.setState({ passwordModalOpen: false });
                        }}
                      />
                    </div>
                  </div>
                </Modal.Description>
              )}
            </Modal.Content>
            {!passwordChangeSuccess && (
              <Modal.Actions>
                <Button
                  content="Discard Changes"
                  basic
                  disabled={loading}
                  onClick={() => {
                    this.setState({ passwordModalOpen: false });
                  }}
                />
                <Button
                  positive
                  content="Save Changes"
                  onClick={this.savePasswordChanges}
                  disabled={loading}
                  loading={loading}
                />
              </Modal.Actions>
            )}
          </Modal>

          {infoModalOpen && (
            <EditUserInformationModal
              user={{
                id: infoModalAdvisorId,
                firstName: firstName,
                lastName: lastName,
                email: email,
                role: { id: roleId, title: role },
              }}
              onCloseCb={() => {
                this.setState({ infoModalOpen: false });
              }}
              onSaveCb={() => {
                const { setAdvisor } = this.props;
                GetAdvisor()
                  .then((newAdvisor) => {
                    setAdvisor(newAdvisor);
                    this.setState({ infoModalOpen: false });
                  })
                  .catch((error) => {
                    console.error(error);
                    setAlert({
                      type: "error",
                      text: "Failed to update Hub users.",
                    });
                  });
              }}
            />
          )}

          <AddUserModal
            isModalOpen={addNewUsersModalOpen}
            onSubmitCb={() => {
              const { setAdvisor } = this.props;
              GetAdvisor()
                .then((newAdvisor) => {
                  setAdvisor(newAdvisor);
                  this.setState({ addNewUsersModalOpen: false });
                })
                .catch((error) => {
                  console.error(error);
                  setAlert({
                    type: "error",
                    text: "Failed to update Hub users.",
                  });
                });
            }}
            onClose={() => {
              this.setState({ addNewUsersModalOpen: false });
            }}
          />
        </div>
      </ToasterContextProvider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedHub: state.hubly.data.hub.selected.hub,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateAdvisor: (id, request, callback) => {
      dispatch(UpdateAdvisor(id, request, callback));
    },
    setAdvisor: (advisor) => {
      dispatch(SetAdvisor(advisor));
    },
    setAlert: (alert) => {
      dispatch(SetAlert(alert));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserInformationForm);
