import { differenceBy } from "lodash";

export const getClientsFromSelectedItems = (selectedItems = []) => {
  return selectedItems
    .filter(({ type }) => {
      return type === "client";
    })
    .map(({ key }) => {
      return key;
    });
};

export const getStreamsFromSelectedItems = (selectedItems = []) => {
  return selectedItems
    .filter((item) => {
      return item.type === "stream";
    })
    .map((item) => {
      return { id: item.key, name: item.text };
    });
};

export const getTagsFromSelectedItems = (selectedItems = []) => {
  return selectedItems
    .filter((item) => {
      return item.type === "tag";
    })
    .map((item) => {
      return item.key;
    });
};

export const parseBackendStreams = (streams = []) => {
  return streams.map((stream) => {
    return {
      color: stream.color,
      key: stream.id,
      text: stream.name,
      type: "stream",
      value: stream.id,
    };
  });
};

export const parseBackendTags = (tags = []) => {
  return tags.map((tag) => {
    return {
      color: "grey",
      key: tag.id,
      text: tag.name,
      type: "tag",
      value: tag.id,
    };
  });
};

export const parseEditWorkflowTags = (tagIds = [], allTags = []) => {
  return parseBackendTags(
    tagIds.map((tagId) => {
      return allTags[tagId];
    })
  );
};

export const parseBackendClient = (client = {}) => {
  return {
    key: client.id,
    text: client.name,
    value: client.id,
    type: "client",
  };
};

export const parseEditWorkflowClients = (clientIds = [], allClients = []) => {
  return clientIds.map((clientId) => {
    return parseBackendClient(
      allClients.find(({ id }) => {
        return id === clientId;
      })
    );
  });
};

export const filterOptionsFromSelectedItems = (
  options = [],
  selectedItems = [],
  type
) => {
  return differenceBy(
    options,
    selectedItems.filter((item) => {
      return item.type === type;
    }),
    "value"
  );
};

export default getStreamsFromSelectedItems;
