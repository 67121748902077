import React from "react";
import PropTypes from "prop-types";

import Moment from "moment";

import ReminderIcon from "./components";

export function ReminderItem({
  title,
  completed,
  time,
  clientNameSlot,
  children,
}) {
  const generateColorSet = () => {
    if (completed) {
      return {
        textColor: "#21ba45",
        iconColor: "green",
        highlightColor: "#def3e7",
      };
    }

    if (new Date() > new Date(time)) {
      return {
        textColor: "#db2828",
        iconColor: "red",
        highlightColor: "#fce6e6",
      };
    }

    const date = Moment(time);
    const duration = Moment.duration(date.diff());

    if (duration.days() === 0 && duration.hours() <= 12 && duration > 0) {
      return {
        iconColor: "yellow",
        textColor: "#fbbd08",
        highlightColor: "#fdf7e4",
      };
    }

    return {
      iconColor: "grey",
      textColor: "grey",
      highlightColor: "rgba(0,0,0,0)",
    };
  };

  const getFormattedTime = () => {
    const today = Moment().endOf("day");
    const tomorrow = Moment().add(1, "day").endOf("day");
    const week = Moment().endOf("week");
    const date = Moment(time);
    const now = Moment();

    if (date < now) return date.format("h:mma, MMM D YYYY");
    if (date < today && date > now) return `Today at ${date.format("h:mma")}`;
    if (date < tomorrow && date > now)
      return `Tomorrow at ${date.format("h:mma")}`;
    if (date < week) return date.format("h:mma dddd");
    return date.format("h:mma, MMM D YYYY");
  };

  return (
    <div
      title={title}
      data-test="reminder-item"
      style={{
        width: "100%",
        borderBottom: "1px solid #ebebeb",
        borderTop: "1px solid #ebebeb",
        padding: "0.5em",
        display: "flex",
        borderRadius: "0px",
      }}
    >
      <div style={{ maxWidth: children ? "270px" : "100%" }}>
        <div
          data-test="reminder-item-title"
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontWeight: "bold",
          }}
        >
          {title}
        </div>
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "100%",
          }}
        >
          <ReminderIcon
            lineThrough={completed}
            text={getFormattedTime()}
            {...generateColorSet()}
          />
          <span style={{ paddingLeft: "0.5em" }}>{clientNameSlot}</span>
        </div>
      </div>
      {children && (
        <div
          style={{
            width: "40px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginLeft: "auto",
          }}
        >
          {children}
        </div>
      )}
    </div>
  );
}

ReminderItem.propTypes = {
  title: PropTypes.string.isRequired,
  completed: PropTypes.bool.isRequired,
  time: PropTypes.string.isRequired,
  clientNameSlot: PropTypes.node,
  children: PropTypes.node,
};

ReminderItem.defaultProps = {
  clientNameSlot: null,
  children: null,
};

export default ReminderItem;
