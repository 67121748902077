import React, { useCallback } from "react";
import PropTypes from "prop-types";

import { Button, Icon } from "semantic-ui-react";

import Popper from "components/Popper";

export function Menu({ children, ...props }) {
  const [menuOpen, setMenuOpen] = React.useState(false);

  return (
    <Popper
      className="hubly_bars_menu"
      flowing
      hideOnScroll
      on="click"
      onClose={() => {
        setMenuOpen(false);
      }}
      onOpen={() => {
        setMenuOpen(true);
      }}
      open={menuOpen}
      trigger={
        <Icon
          name="ellipsis horizontal"
          link
          color="grey"
          onClick={(e) => {
            e.stopPropagation();
            setMenuOpen(true);
          }}
        />
      }
      {...props}
    >
      <Button.Group basic vertical labeled icon style={{ border: "none" }}>
        {children({
          closeMenu: useCallback(() => {
            setMenuOpen(false);
          }, []),
        })}
      </Button.Group>
    </Popper>
  );
}

Menu.propTypes = {
  children: PropTypes.func.isRequired,
};

export default Menu;
