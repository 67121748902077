import { useCallback } from "react";

import { useMutationWithToaster } from "hooks";

export function useAssignAdvisorAction(onDone = () => {}) {
  const mutationFactory = useCallback(() => {
    const query = `mutation assignAdvisorToCrmUser($advisorId: ID!, $crmUserId: ID!) {
        assignAdvisorToCrmUser(advisorId: $advisorId, crmUserId: $crmUserId) {
          crmList {
            id
          }
        }
      }
    `;

    const resolver = (resp) => {
      return resp;
    };

    return { query, resolver };
  }, []);

  const [assignAdvisor, { loading }] = useMutationWithToaster(mutationFactory, {
    onDone: useCallback(onDone, []),
  });

  return { assignAdvisor: assignAdvisor, assigning: loading };
}

export default useAssignAdvisorAction;
