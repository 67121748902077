import { API } from "aws-amplify";

import SyncedAPI from "data/libs/syncedAPI";

import { getAPIReq } from "./authLib";

export const CreateHub = async (request) => {
  const req = await getAPIReq(request);
  return SyncedAPI.post("HublyAPI", `/hubs/`, req);
};

export const GetHub = async (id) => {
  const req = await getAPIReq();
  return API.get("HublyAPI", `/hubs/${id}/`, req);
};

export const EditHub = async (id, request) => {
  const req = await getAPIReq(request);
  return SyncedAPI.put("HublyAPI", `/hubs/${id}/`, req);
};

export const SetHubPreferences = async (hubId, request) => {
  const req = await getAPIReq(request);
  return SyncedAPI.put("HublyAPI", `/hubs/${hubId}/set_preferences/`, req);
};

export const DeleteHub = async (id) => {
  const req = await getAPIReq();
  return SyncedAPI.del("HublyAPI", `/hubs/${id}/`, req);
};

export const CreateHubAdvisor = async (hubId, request) => {
  const req = await getAPIReq(request);
  return SyncedAPI.post("HublyAPI", `/hubs/${hubId}/advisors/`, req);
};

export const DeletehubAdvisor = async (hubId, advisorId) => {
  const req = await getAPIReq();
  return SyncedAPI.del(
    "HublyAPI",
    `/hubs/${hubId}/advisors/${advisorId}/`,
    req
  );
};

export const GetDigest = async (id, start, end) => {
  const req = await getAPIReq();
  // time is the local unix time
  return API.get(
    "HublyAPI",
    `/hubs/${id}/digest/?start=${start}&end=${end}`,
    req
  );
};
