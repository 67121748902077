import React from "react";
import PropTypes from "prop-types";

import { Icon } from "semantic-ui-react";

const AddNewEventPopupTrigger = ({ loading, title, triggerOnClickHandler }) => {
  return (
    <div
      className="grey_on_hover clickable rounded"
      onClick={triggerOnClickHandler}
      style={{ maxWidth: "fit-content", padding: "0 0.5em" }}
    >
      <div>
        <Icon
          color="grey"
          style={
            loading
              ? { margin: "0.25em 0.5em 0 0" }
              : { margin: "-0.1em 0.5em 0 0" }
          }
          name={loading ? "circle notch" : "calendar alternate"}
          loading={loading}
        />
        <span style={{ color: "grey" }}>{`Add ${title}`}</span>
      </div>
    </div>
  );
};

AddNewEventPopupTrigger.propTypes = {
  loading: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  triggerOnClickHandler: PropTypes.func.isRequired,
};

export default AddNewEventPopupTrigger;
