import React from "react";
import { range } from "lodash";
import { v4 } from "uuid";

import { Placeholder } from "semantic-ui-react";

import { MemberItemLayout } from "../MemberItem/components";

export function MemberItemPlaceholder() {
  const loader = () => {
    return (
      <Placeholder style={{ margin: "0.25em 2em 0.25em 0" }}>
        <Placeholder.Header>
          <Placeholder.Line length="full" />
        </Placeholder.Header>
      </Placeholder>
    );
  };

  return (
    <React.Fragment>
      {range(4).map(() => {
        return <MemberItemLayout key={v4()} name={loader()} popup={loader()} />;
      })}
    </React.Fragment>
  );
}

export default MemberItemPlaceholder;
