import React from "react";
import PropTypes from "prop-types";
import { range } from "lodash";
import { v4 } from "uuid";

import { Placeholder } from "semantic-ui-react";

export function SimpleLinePlaceholder({ numLines }) {
  return (
    <React.Fragment>
      {range(numLines).map(() => {
        return (
          <div
            key={v4()}
            style={{
              margin: "1em 0 1em 0.5em",
            }}
          >
            <Placeholder>
              <Placeholder.Header>
                <Placeholder.Line length="very long" />
              </Placeholder.Header>
            </Placeholder>
          </div>
        );
      })}
    </React.Fragment>
  );
}

SimpleLinePlaceholder.propTypes = {
  numLines: PropTypes.number,
};

SimpleLinePlaceholder.defaultProps = {
  numLines: 4,
};

export default SimpleLinePlaceholder;
