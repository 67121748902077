import React from "react";
import PropTypes from "prop-types";

import { List } from "semantic-ui-react";

import Expandable from "components/Expandable";

function Item({ children }) {
  return (
    <List.Item data-test="tile-item">
      <Expandable>{children}</Expandable>
    </List.Item>
  );
}

Item.propTypes = {
  children: PropTypes.node.isRequired,
};

export function TileList({ items }) {
  return (
    <List
      bulleted
      style={{
        color: "grey",
        paddingLeft: "0.5em",
        marginTop: "0.5em",
        marginRight: "1em",
      }}
    >
      {items.map(({ id, text }) => {
        return <Item key={id}>{text}</Item>;
      })}
    </List>
  );
}

TileList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    })
  ),
};

TileList.defaultProps = {
  items: [],
};

export default TileList;
