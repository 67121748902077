import { API } from "aws-amplify";

import SyncedAPI from "data/libs/syncedAPI";

import { getAPIReq } from "./authLib";

export const GetProcesses = async () => {
  const req = await getAPIReq();
  return API.get("HublyAPI", `/processes/`, req);
};

export const GetProcess = async (id) => {
  const req = await getAPIReq();
  return API.get("HublyAPI", `/processes/${id}/`, req);
};

export const CreateProcess = async (request) => {
  const req = await getAPIReq(request);
  return SyncedAPI.post("HublyAPI", `/processes/`, req);
};

export const EditProcess = async (id, request) => {
  const req = await getAPIReq(request);
  return SyncedAPI.put("HublyAPI", `/processes/${id}/`, req);
};

export const UpdateProcesSortOrder = async (id, request) => {
  const req = await getAPIReq(request);
  return SyncedAPI.put("HublyAPI", `/processes/${id}/updateSortOrder/`, req);
};

export const DeleteProcess = async (id) => {
  const req = await getAPIReq();
  return SyncedAPI.del("HublyAPI", `/processes/${id}/`, req);
};
