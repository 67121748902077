import React from "react";
import PropTypes from "prop-types";

export function WorkspaceLayout({ children, header }) {
  return (
    <div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        {header}
        <div>{children}</div>
      </div>
    </div>
  );
}

WorkspaceLayout.propTypes = {
  children: PropTypes.node,
  header: PropTypes.node,
};

WorkspaceLayout.defaultProps = {
  children: null,
  header: null,
};

export default WorkspaceLayout;
