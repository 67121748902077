import React from "react";
import PropTypes from "prop-types";

import IncludeInfoPopup from "scenes/Hubly/components/Workflows/components/WorkflowRules/components/IncludeInfoPopup";

export function Layout({ ruleClientDropdown, repeatSelector }) {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ marginRight: "0.5em", width: "110px" }}>
          Add clients with
        </div>
        {ruleClientDropdown}
        <div style={{ marginLeft: "0.5em" }}>
          <IncludeInfoPopup />
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {repeatSelector}
        </div>
      </div>
    </div>
  );
}

Layout.propTypes = {
  ruleClientDropdown: PropTypes.node,
  repeatSelector: PropTypes.node,
};

Layout.defaultProps = {
  ruleClientDropdown: null,
  repeatSelector: null,
};

export default Layout;
