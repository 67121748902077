import React, { useContext } from "react";
import PropTypes from "prop-types";

import { Container, Header } from "semantic-ui-react";

import SimpleLinePlaceholder from "components/SimpleLinePlaceholder";

import {
  useAccessTypeStore,
  useEditorData,
  useEditorState,
} from "scenes/Hubly/components/Workspace/components/hooks";
import {
  HubContext,
  ToasterContext,
} from "scenes/Hubly/components/Workspace/Provider";

import { AttachmentAdd, AttachmentList } from "./components";

export function AttachmentsEditor({ workflowId }) {
  const { fetchAttachments, bulkAddAttachment, deleteAttachment } =
    useContext(HubContext);
  const { hasAccess } = useAccessTypeStore();

  const [attachments, dispatch] = useEditorState();
  const { loading, reloadData } = useEditorData(fetchAttachments, {
    id: workflowId,
    dispatch: dispatch,
  });
  const { fireError } = useContext(ToasterContext);

  const createAttachments = (files, onlyAddToNewClients) => {
    return bulkAddAttachment(workflowId, files, onlyAddToNewClients).catch(
      (error) => {
        fireError("Failed to upload attachment", error);
        reloadData();
      }
    );
  };

  const removeAttachment = (name) => {
    return deleteAttachment(workflowId, { attachmentName: name }).catch(
      (error) => {
        fireError("Failed to delete attachment", error);
        reloadData();
      }
    );
  };

  if (loading && attachments.length === 0) {
    return (
      <Container className="configure_tasks">
        <Header as="h5">Attachments/Templates</Header>
        <SimpleLinePlaceholder />
      </Container>
    );
  }

  return (
    <React.Fragment>
      <Container style={{ marginTop: "1em" }}>
        <Header as="h5" style={{ marginBottom: "0.5em" }}>
          Attachments/Templates
        </Header>
        <AttachmentList
          withDelete={hasAccess()}
          onDelete={(name) => {
            dispatch({
              type: "edit",
              value: { id: name, name: name, inProgress: true },
            });
            removeAttachment(name).then(() => {
              reloadData();
            });
          }}
          attachments={attachments}
        />
        {hasAccess() && (
          <AttachmentAdd
            onAdd={(files, onlyAddToNewClients) => {
              dispatch({ type: "addMultiple", value: files });
              createAttachments(files, onlyAddToNewClients).then(() => {
                reloadData();
              });
            }}
            fireError={fireError}
          />
        )}
      </Container>
    </React.Fragment>
  );
}

AttachmentsEditor.propTypes = {
  workflowId: PropTypes.string,
};

AttachmentsEditor.defaultProps = {
  workflowId: null,
};

export default AttachmentsEditor;
