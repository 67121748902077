import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {
  Button,
  Dimmer,
  Grid,
  Header,
  Icon,
  Modal,
  Responsive,
} from "semantic-ui-react";

import { SetAlert } from "components/Alerts/actions";
import Loading from "components/Loading";

import { AddTemplateWorkflows } from "../../../actions";
import templateJson from "./templates";
import TemplatesFeatureFlag from "./TemplatesFeatureFlag";
import WorkflowCard from "./WorkflowCard";

class WorkflowLibrary extends Component {
  static propTypes = {
    addTemplateWorkflows: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired,
  };

  state = {
    loading: false,
    open: false,
    selectedTemplateInd: 0,
  };

  openModal = () => {
    this.setState({
      open: true,
    });
  };

  closeModal = () => {
    this.setState({
      open: false,
    });
  };

  addTemplateToHub = () => {
    this.setState({
      loading: true,
    });

    const { addTemplateWorkflows } = this.props;
    const { selectedTemplateInd } = this.state;
    const { templates } = templateJson;
    const selectedTemplate = templates[selectedTemplateInd];
    addTemplateWorkflows(selectedTemplate, this.addTemplateComplete);
  };

  addTemplateComplete = () => {
    const { setAlert } = this.props;
    const { selectedTemplateInd } = this.state;
    const { templates } = templateJson;
    const selectedTemplate = templates[selectedTemplateInd];

    this.setState({
      open: false,
      loading: false,
    });

    setAlert({
      type: "success",
      text: `Successfully added ${selectedTemplate.name} template to Hub`,
    });
  };

  selectTemplate = (templateInd) => {
    this.setState({
      selectedTemplateInd: templateInd,
    });
  };

  render() {
    const { loading, open, selectedTemplateInd } = this.state;
    const { templates } = templateJson;
    const selectedTemplate = templates[selectedTemplateInd];

    return (
      <TemplatesFeatureFlag>
        <div style={{ flex: "0 1 auto", margin: "0" }}>
          <div style={{ marginRight: "0.5em" }}>
            <Responsive
              id="filter-workflow-button"
              maxWidth={1350}
              as={Button}
              basic
              icon="book"
              onClick={this.openModal}
            />
            <Responsive
              id="filter-workflow-button"
              minWidth={1351}
              as={Button}
              basic
              icon="book"
              content="Templates"
              style={{ whiteSpace: "nowrap" }}
              onClick={this.openModal}
            />
          </div>
          <Modal open={open} centered={false} size="large">
            <Modal.Header>
              <Icon
                link
                color="grey"
                name="close"
                onClick={this.closeModal}
                style={{ float: "right" }}
              />
              Workflow Templates
            </Modal.Header>
            <Modal.Content scrolling>
              {loading ? (
                <Dimmer.Dimmable dimmed={loading}>
                  <div style={{ height: "300px" }}>
                    <Loading
                      active={loading}
                      inverted
                      message="Adding Template to Hub"
                      style={{ top: "100px" }}
                    />
                  </div>
                </Dimmer.Dimmable>
              ) : (
                <div>
                  <span>
                    Select from one of our templates, built with help from
                    advisors just like you. Preview the associated workflows and
                    personalize them after adding the selected template to your
                    Hub.
                  </span>
                  <Grid columns={3} style={{ marginTop: ".25em" }}>
                    <Grid.Column width={4}>
                      <Header as="h2">Templates</Header>
                      {templates.map((template, i) => {
                        return (
                          <Button
                            active={selectedTemplateInd === i}
                            key={`library-button-${template.id}`}
                            fluid
                            onClick={() => {
                              this.selectTemplate(i);
                            }}
                            style={{ marginBottom: "1em" }}
                          >
                            {template.name}
                          </Button>
                        );
                      })}
                    </Grid.Column>
                    <Grid.Column width={6}>
                      <Header as="h2">Description</Header>
                      {selectedTemplate && (
                        <span>{selectedTemplate.description}</span>
                      )}
                    </Grid.Column>
                    <Grid.Column width={6}>
                      <Header as="h2">Workflows</Header>
                      {selectedTemplate.workflows.map((workflow, i) => {
                        return (
                          <WorkflowCard
                            workflow={workflow}
                            key={`library-workflow-${workflow.id}`}
                            index={i}
                          />
                        );
                      })}
                    </Grid.Column>
                  </Grid>
                </div>
              )}
            </Modal.Content>
            <Modal.Actions>
              <Button
                disabled={loading}
                onClick={this.addTemplateToHub}
                primary
              >
                Add Template To Hub
              </Button>
            </Modal.Actions>
          </Modal>
        </div>
      </TemplatesFeatureFlag>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    addTemplateWorkflows: (template, callback) => {
      dispatch(AddTemplateWorkflows(template, callback));
    },
    setAlert: (alert) => {
      dispatch(SetAlert(alert));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowLibrary);
