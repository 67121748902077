import axios from "axios";

import {
  CopyAttachmentToAllClients,
  CreateAttachment,
  DeleteAttachment,
  GetAttachments,
} from "data/libs/workflows";

export const addAttachment = async (
  workflowId,
  { file },
  onlyAddToNewClients
) => {
  const attachment = await CreateAttachment(workflowId, {
    fileName: file.name,
  });
  await axios.put(attachment.data.url, file, { "Content-Type": file.type });
  if (!onlyAddToNewClients) {
    await CopyAttachmentToAllClients(workflowId, { attachmentName: file.name });
  }
};

export const bulkAddAttachment = async (
  workflowId,
  files,
  onlyAddToNewClients
) => {
  const uploadPromises = files.map((file) => {
    return addAttachment(workflowId, file, onlyAddToNewClients);
  });
  await Promise.all(uploadPromises);
};

export const fetchAttachments = async (workflowId) => {
  const { data } = await GetAttachments(workflowId);

  return data.result.attachments.map((item) => {
    return {
      ...item,
      id: item.name,
    };
  });
};

export const deleteAttachment = DeleteAttachment;

export default addAttachment;
