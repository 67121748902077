import React from "react";
import PropTypes from "prop-types";

import { Header } from "semantic-ui-react";

import TileIcon from "components/TileIcon";

import ReminderTile from "scenes/Hubly/components/Workspace/components/ReminderTile";

export function TileReminders({
  displayedReminders,
  numOfTotalReminders,
  statusColor,
}) {
  return (
    <TileIcon
      data-test="tile-icon"
      icon="bell outline"
      text={numOfTotalReminders}
      color={statusColor}
      popup={
        <div
          style={{ padding: "3px", maxWidth: "400px" }}
          className="clickable"
        >
          <Header as="h3" style={{ margin: "none" }}>
            {numOfTotalReminders > 0 ? "Reminders" : "No Reminders"}
          </Header>
          {displayedReminders.map((reminder) => {
            const { id } = reminder;
            return <ReminderTile key={id} reminder={reminder} />;
          })}
          {numOfTotalReminders > displayedReminders.length && (
            <div style={{ color: "grey", margin: "0.5em" }}>
              Plus&nbsp;
              {numOfTotalReminders - displayedReminders.length}
              &nbsp;more
            </div>
          )}
        </div>
      }
    />
  );
}

TileReminders.propTypes = {
  displayedReminders: PropTypes.array,
  numOfTotalReminders: PropTypes.number.isRequired,
  statusColor: PropTypes.string.isRequired,
};

TileReminders.defaultProps = {
  displayedReminders: [],
};

export default TileReminders;
