import { API } from "aws-amplify";

import { getAPIReq } from "data/libs/authLib";

import { mapUUID, toGlobalId } from "./helper";

export const getClientWorkflows = async ({
  hubId,
  workflowId,
  filters,
  pageSize = 5,
  after = "",
}) => {
  const {
    streams,
    streamFilterType,
    tags,
    tagFilterType,
    filterNextTaskForAssignee,
    assignee,
    assigneeFilterType,
    filterAssigneeIncludeServiceRole,
    filterServiceRoleUnassignedOnly,
    reminderTypes,
    clients,
  } = filters;

  const req = await getAPIReq(
    {
      query: `query getFilteredClientWorkflows( $filterInput: FilterInput!, $workflowId: ID!, $pageSize: Int, $after: String) {
      filteredClientWorkflows(filterInput: $filterInput, workflowId: $workflowId, first: $pageSize, after: $after) {
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            uuid
            name
            sortOrder
            numTasks
            numTasksCompleted
            numFilteredTasks
            numFilteredTasksCompleted
            isNew
            clientWorkflowStatus {
              assignees
              nextTaskAssignee
              waitingOnClient
              waitingOnClientDays
              oldestReminderTime
              numReminders
            }
            clientWorkflowReminders {
              reminder {
                uuid
                title
                dismissed
                completedAt
                time
                task {
                  uuid
                  assignedAdvisor {
                    uuid
                    firstName
                    lastName
                  }
                }
                client {
                  uuid
                  name
                  firstName
                  lastName
                }
              }
            }
            client {
              uuid
              firstName
              lastName
              topName
              bottomName
              hasTopNamePii
              hasBottomNamePii
              name
              newUploadedFileCount
              streams {
                edges {
                  node {
                    id
                    uuid
                    color
                    name
                  }
                }
              }
            }
            assignedAdvisor {
              uuid
              firstName
              lastName
            }
            filteredTasks {
              uuid
              title
              order
              completed
              assignee {
                uuid
                name
                group
              }
              assignedAdvisor {
                uuid
                firstName
                lastName
              }
            }
          }
        }
      }
    }`,
      variables: {
        pageSize: pageSize,
        after: after,
        workflowId: toGlobalId("Workflow", workflowId),
        filterInput: {
          streams: streams.map(({ id }) => {
            return toGlobalId("Stream", id);
          }),
          streamFilterType: streamFilterType,
          tags: tags.map(({ id }) => {
            return toGlobalId("Tag", id);
          }),
          tagFilterType: tagFilterType,
          filterNextTaskForAssignee: filterNextTaskForAssignee,
          assignee: assignee ? toGlobalId("Advisor", assignee) : null,
          assigneeFilterType: assigneeFilterType,
          filterAssigneeIncludeServiceRole: filterAssigneeIncludeServiceRole,
          filterServiceRoleUnassignedOnly: filterServiceRoleUnassignedOnly,
          reminderTypes: reminderTypes,
          clientFilter: clients.map(({ id }) => {
            return toGlobalId("Client", id);
          }),
        },
      },
    },
    { hub: hubId }
  );

  const {
    data: { filteredClientWorkflows },
  } = await API.post("HublyAPI", `/graphql`, req);

  return {
    pageInfo: filteredClientWorkflows.pageInfo,
    data: filteredClientWorkflows.edges.map(({ node }) => {
      return {
        ...node,
        id: node.uuid,
        clientWorkflowReminders: node.clientWorkflowReminders.map(
          ({ reminder }) => {
            return {
              reminder: {
                ...reminder,
                id: reminder.uuid,
                task: {
                  ...reminder.task,
                  assignedAdvisor: reminder.task?.assignedAdvisor
                    ? {
                        ...reminder.task?.assignedAdvisor,
                        id: reminder.task?.assignedAdvisor?.uuid,
                      }
                    : null,
                },
              },
            };
          }
        ),
        client: {
          ...node.client,
          id: node.client.uuid,
          streams: node.client.streams.edges.map(({ node: sNode }) => {
            return {
              ...sNode,
              id: sNode.uuid,
            };
          }),
        },
        assignedAdvisor: node.assignedAdvisor
          ? {
              ...node.assignedAdvisor,
              id: node.assignedAdvisor?.uuid,
            }
          : null,
        filteredTasks: node.filteredTasks.map((filteredTask) => {
          return {
            ...filteredTask,
            id: filteredTask.uuid,
            assignee: mapUUID(filteredTask.assignee),
            assignedAdvisor: filteredTask.assignedAdvisor
              ? {
                  ...filteredTask.assignedAdvisor,
                  id: filteredTask.assignedAdvisor.uuid,
                }
              : undefined,
          };
        }),
      };
    }),
  };
};

export const getWorkflowsWithNoProcess = async ({
  hubId,
  first = 25,
  after = "",
}) => {
  const req = await getAPIReq(
    {
      query: `query getWorkflowsWithNoProcess($first: Int, $after: String) {
      workflowsWithNoProcess(first: $first, after: $after) {
        edges {
          node {
            id
            uuid
            name
            numTasks
            numActiveClients
            canEdit
            clientTileOptions {
              showProgress
              showIcons
              showStreams
              numberOfTasks
              presentWorkflowName
            }
            workflowRules {
              edges {
                node {
                  id
                }
              }
            }
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }`,
      variables: {
        first: first,
        after: after,
      },
    },
    { hub: hubId }
  );

  const {
    data: { workflowsWithNoProcess },
  } = await API.post("HublyAPI", `/graphql`, req);

  return {
    data: workflowsWithNoProcess.edges.map(({ node }) => {
      return {
        ...node,
        id: node.uuid,
        workflowRules: node.workflowRules.edges.map(({ node: wrNode }) => {
          return { ...wrNode, id: wrNode.uuid };
        }),
      };
    }),
    pageInfo: workflowsWithNoProcess.pageInfo,
  };
};

export const getWorkflowEditorData = async (hubId, workflowId) => {
  const req = await getAPIReq(
    {
      query: `query getWorkflowEditorData($id: ID!) {
        workflow(id: $id) {
          uuid
          name
          numActiveClients
          numActiveClientWorkflows
          numTasks
          numWorkflowRules
          multipleTimePerClient
          canEdit
          clientTileOptions {
            showProgress
            showIcons
            showStreams
            numberOfTasks
            presentWorkflowName
          }
          assignedAdvisor {
            uuid
            firstName
            lastName
          }
        }
      }`,
      variables: {
        id: toGlobalId("Workflow", workflowId),
      },
    },
    { hub: hubId }
  );
  const {
    data: { workflow },
  } = await API.post("HublyAPI", `/graphql`, req);

  return [
    {
      ...workflow,
      id: workflow.uuid,
      assignedAdvisor: workflow.assignedAdvisor
        ? { ...workflow.assignedAdvisor, id: workflow.assignedAdvisor.uuid }
        : null,
    },
  ];
};

export const getWorkflowTasks = async (hubId, workflowId) => {
  const req = await getAPIReq(
    {
      query: `query getWorkflowTasks($id: ID!) {
        workflow(id: $id) {
          tasks {
            edges {
              node {
                uuid
                title
                description
                options
                completed
                isClientTask
                assignee {
                  uuid
                  name
                  group
                } 
                assignedAdvisor {
                  uuid
                  firstName
                  lastName
                }
                taskCondition {
                  uuid
                  conditionType
                  months
                  tags {
                    edges {
                      node {
                        uuid
                        name
                      }
                    }
                  }
                  streams {
                    edges {
                      node {
                        uuid
                        name
                        color
                      }
                    }
                  }
                }
                timeEstimates {
                  edges {
                    node {
                      uuid
                      autoAddedInd
                      timeEstimate
                    }
                  }
                }
                emailTemplate {
                  uuid
                  to
                  subject
                  body
                }
                reminders {
                  edges {
                    node {
                      uuid
                      time
                      futureReminderNumber
                      futureReminderType
                      futureReminderTime
                    }
                  }
                }
                links {
                  edges {
                    node {
                      uuid
                      title
                      url
                    }
                  }
                }
              }
            }
            
          }
        }
      }`,
      variables: {
        id: toGlobalId("Workflow", workflowId),
      },
    },
    { hub: hubId }
  );
  const {
    data: { workflow },
  } = await API.post("HublyAPI", `/graphql`, req);

  return workflow.tasks.edges.map(({ node: tNode }) => {
    return {
      ...tNode,
      id: tNode.uuid,
      emailTemplate: tNode.emailTemplate
        ? { ...tNode.emailTemplate, id: tNode.emailTemplate.uuid }
        : null,
      timeEstimates: tNode.timeEstimates.edges.map(({ node: timeNode }) => {
        return {
          ...timeNode,
          id: timeNode.uuid,
        };
      }),
      assignee: mapUUID(tNode.assignee),
      reminders: tNode.reminders.edges.map(({ node: rNode }) => {
        return {
          ...rNode,
          id: rNode.uuid,
        };
      }),
      links: tNode.links.edges.map(({ node: lNode }) => {
        return {
          ...lNode,
          id: lNode.uuid,
        };
      }),
      taskCondition: tNode.taskCondition
        ? {
            ...tNode.taskCondition,
            id: tNode.taskCondition.uuid,
            streams: tNode.taskCondition.streams.edges.map(
              ({ node: sNode }) => {
                return {
                  ...sNode,
                  id: sNode.uuid,
                };
              }
            ),
            tags: tNode.taskCondition.tags.edges.map(({ node: sNode }) => {
              return {
                ...sNode,
                id: sNode.uuid,
              };
            }),
          }
        : null,
    };
  });
};

export const getWorkflowNextWorkflows = async (hubId, workflowId) => {
  const req = await getAPIReq(
    {
      query: `query getWorkflowNextWorkflows($id: ID!) {
        workflow(id: $id) {
          nextWorkflows {
            edges {
              node {
                uuid
                nextWorkflow {
                  uuid
                  name
                }
              }
            }
          }
        }
      }`,
      variables: {
        id: toGlobalId("Workflow", workflowId),
      },
    },
    { hub: hubId }
  );
  const {
    data: { workflow },
  } = await API.post("HublyAPI", `/graphql`, req);

  return workflow.nextWorkflows.edges.map(({ node: nNode }) => {
    return {
      ...nNode,
      id: nNode.uuid,
      nextWorkflow: {
        ...nNode.nextWorkflow,
        id: nNode.nextWorkflow.uuid,
      },
    };
  });
};

export const getClientWorkflowCount = async (hubId, workflowId, clientId) => {
  const req = await getAPIReq(
    {
      query: `query clientWorkflowCount($clientId: ID!, $workflowId: ID!) {
        clientWorkflowCount(clientId: $clientId, workflowId: $workflowId) {
          activeClientWorkflowCount
          workflowAllowsMultiple
        }
      }`,
      variables: {
        clientId: toGlobalId("Client", clientId),
        workflowId: toGlobalId("Workflow", workflowId),
      },
    },
    { hub: hubId }
  );
  const {
    data: { clientWorkflowCount },
  } = await API.post("HublyAPI", `/graphql`, req);

  return clientWorkflowCount;
};

export const getAllHubWorkflows = async ({ hubId }) => {
  const req = await getAPIReq(
    {
      query: `query getAllHubWorkflows($pageSize: Int) {
        workflows(first: $pageSize) {
          edges {
            node {
              uuid
              name
              multipleTimePerClient
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
      `,
      variables: {
        pageSize: 200,
      },
    },
    { hub: hubId }
  );

  const {
    data: { workflows },
  } = await API.post("HublyAPI", `/graphql`, req);

  return {
    data: workflows.edges.map(({ node }) => {
      return {
        ...node,
        id: node.uuid,
      };
    }),
    pageInfo: workflows.pageInfo,
  };
};

export const getWorkflowsWithExclude = async ({
  hubId,
  searchText = null,
  workflowId,
  first = 100,
  after = "",
}) => {
  const req = await getAPIReq(
    {
      query: `query getWorkflows($excludeWorkflowId: ID, $first: Int, $after: String, $searchText: String) {
        workflows(excludeWorkflowId: $excludeWorkflowId, first: $first, after: $after, searchText: $searchText) {
          edges {
            node {
              uuid
              name
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }`,
      variables: {
        searchText: searchText,
        first: first,
        after: after,
        excludeWorkflowId: toGlobalId("Workflow", workflowId),
      },
    },
    { hub: hubId }
  );

  const {
    data: { workflows },
  } = await API.post("HublyAPI", `/graphql`, req);

  return {
    data: workflows.edges.map(({ node }) => {
      return {
        ...node,
        id: node.uuid,
      };
    }),
    pageInfo: workflows.pageInfo,
  };
};

export const getWorkflowRules = async (hubId, workflowId) => {
  const req = await getAPIReq(
    {
      query: `query getWorkflowRules($id: ID!) {
        workflow(id: $id) {
          workflowRules {
            edges {
              node {
                id
                uuid
                dateStart
                frequency
                interval
                weekStartDay
                timesRun
                lastProcessingDate
                lastRun
                repeat
                active
                type
                action
                onlyHouseholdHead
                importantDate
                age
                dateType {
                  uuid
                  name
                  icon
                }
                meetingDateType {
                  uuid
                  name
                }
                tags {
                  edges {
                    node {
                      uuid
                      name
                    }
                  }
                }
                tagsExclude {
                  edges {
                    node {
                      uuid
                      name
                    }
                  }
                }
                streams {
                  edges {
                    node {
                      uuid
                      name
                      color
                    }
                  }
                }
                streamsExclude {
                  edges {
                    node {
                      uuid
                      name
                      color
                    }
                  }
                }
                clientsExclude {
                  edges {
                    node {
                      uuid
                      name
                      firstName
                      lastName
                    }
                  }
                }
              }
            }
          }
        }
      }`,
      variables: {
        id: toGlobalId("Workflow", workflowId),
      },
    },
    { hub: hubId }
  );
  const {
    data: { workflow },
  } = await API.post("HublyAPI", `/graphql`, req);

  return workflow.workflowRules.edges.map(({ node: wNode }) => {
    return {
      ...wNode,
      id: wNode.uuid,
      dateType: wNode.dateType
        ? { ...wNode.dateType, id: wNode.dateType.uuid }
        : null,
      meetingDateType: wNode.meetingDateType
        ? { ...wNode.meetingDateType, id: wNode.meetingDateType.uuid }
        : null,
      tags: wNode.tags.edges.map(({ node: sNode }) => {
        return {
          ...sNode,
          id: sNode.uuid,
        };
      }),
      tagsExclude: wNode.tagsExclude.edges.map(({ node: sNode }) => {
        return {
          ...sNode,
          id: sNode.uuid,
        };
      }),
      streams: wNode.streams.edges.map(({ node: sNode }) => {
        return {
          ...sNode,
          id: sNode.uuid,
        };
      }),
      streamsExclude: wNode.streamsExclude.edges.map(({ node: sNode }) => {
        return {
          ...sNode,
          id: sNode.uuid,
        };
      }),
      clientsExclude: wNode.clientsExclude.edges.map(({ node: sNode }) => {
        return {
          ...sNode,
          id: sNode.uuid,
          name: sNode.name
            ? sNode.name
            : `${sNode.firstName} ${sNode.lastName}`,
        };
      }),
    };
  });
};

export default getClientWorkflows;
