import { combineReducers } from "redux";

import { reducer as advisorReducer } from "./advisor/reducer";
import { reducer as filtersReducer } from "./filters/reducer";
import { reducer as hubReducer } from "./hub/reducer";
import { reducer as appMessagesReducer } from "./messages/reducer";
import { reducer as remindersReducer, verReducer } from "./reminders/reducer";
import { reducer as viewsReducer } from "./views/reducer";
import { reducer as websocketsReducer } from "./websockets/reducer";

export const reducer = combineReducers({
  advisor: advisorReducer,
  filters: filtersReducer,
  hub: hubReducer,
  reminderVersion: verReducer,
  reminders: remindersReducer,
  views: viewsReducer,
  websockets: websocketsReducer,
  appMessages: appMessagesReducer,
});
export default reducer;
