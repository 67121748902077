import clone from "rfdc";

import { SetFilters } from "data/filters/actions";
import { SetHub } from "data/hub/actions";
import { SetWorkflows, sortWorkflow } from "data/hub/workflows/actions";
import {
  CreateProcess,
  DeleteProcess,
  EditProcess,
  UpdateProcesSortOrder,
} from "data/libs/processes";
import { GetWorkflows, UpdateWorkflowSortOrder } from "data/libs/workflows";

const deepClone = clone();

export const NewProcess = (request, callback) => {
  return (dispatch, getState) => {
    const { hub } = getState().hubly.data.hub.selected;
    request.hubId = hub.id;

    CreateProcess(request)
      .then((response) => {
        const selectedHub = deepClone(getState().hubly.data.hub.selected.hub);
        selectedHub.processes.unshift(response);
        dispatch(SetHub(selectedHub));
        return GetWorkflows(hub);
      })
      .then((getWorkflowResponse) => {
        dispatch(SetWorkflows(getWorkflowResponse));
        callback(true);
      })
      .catch((error) => {
        console.error(error);
        callback(false);
      });
  };
};

export const AlterProcess = (id, request, callback) => {
  return (dispatch, getState) => {
    EditProcess(id, request)
      .then((response) => {
        const selectedHub = deepClone(getState().hubly.data.hub.selected.hub);
        const foundIndex = selectedHub.processes.findIndex((process) => {
          return process.id === id;
        });
        selectedHub.processes[foundIndex] = response;
        dispatch(SetHub(selectedHub));
        return GetWorkflows(selectedHub);
      })
      .then((getWorkflowResponse) => {
        dispatch(SetWorkflows(getWorkflowResponse));
        callback(true);
      })
      .catch((error) => {
        console.error(error);
        callback(false);
      });
  };
};

export const RemoveProcess = (id, callback) => {
  return (dispatch, getState) => {
    DeleteProcess(id)
      .then((response) => {
        const selectedHub = deepClone(getState().hubly.data.hub.selected.hub);
        selectedHub.processes = selectedHub.processes.filter((process) => {
          return process.id !== id;
        });
        dispatch(SetHub(selectedHub));
        return GetWorkflows(selectedHub);
      })
      .then((getWorkflowResponse) => {
        dispatch(SetWorkflows(getWorkflowResponse));
        callback(true);
      })
      .catch((error) => {
        console.error(error);
        callback(false);
      });
  };
};

export const UpdateProcessOrder = (
  { targetIndex, processList, oldProcessList },
  callback
) => {
  return (dispatch, getState) => {
    const selectedHub = deepClone(getState().hubly.data.hub.selected.hub);
    const targetProcess = processList[targetIndex];
    const nextProcess =
      targetIndex + 1 < processList.length
        ? processList[targetIndex + 1].process
        : null;

    UpdateProcesSortOrder(targetProcess.id, {
      placeBeforeItem: nextProcess?.id,
    })
      .then((response) => {
        selectedHub.processes = processList.map(({ process }) => {
          if (response.sortOrder && process.id === targetProcess.id) {
            return {
              ...process,
              sortOrder: response.sortOrder,
            };
          } else if (
            response.nextItemSortOrder &&
            process.id === nextProcess?.id
          ) {
            // This if statement is for handling the edge case, illustrated below:
            // Initial: [Process A ("a"), Process B ("an"), ..., New Process ("some_sort_order_value")]
            // New Process is moved to the front of the list
            // After: [New Process ("a"), Process A ("ag"), Process B ("an"), ...]
            // Post: The sort order for New Process and Process A need to be updated.
            return {
              ...process,
              sortOrder: response.nextItemSortOrder,
            };
          } else {
            return process;
          }
        });
        dispatch(SetHub(selectedHub));
        callback(true);
      })
      .catch((error) => {
        selectedHub.processes = oldProcessList.map(({ process }) => {
          return {
            ...process,
          };
        });
        dispatch(SetHub(selectedHub));
        console.error(error);
        callback(false);
      });
  };
};

export const UpdateWorkflowOrder = (
  { targetIndex, workflowList, oldWorkflowList },
  callback
) => {
  return (dispatch, getState) => {
    const { filters } = getState().hubly.data;
    const { workflows } = getState().hubly.data.hub;
    const targetWorkflow = workflowList[targetIndex];
    const nextWorkflow =
      targetIndex + 1 < workflowList.length
        ? workflowList[targetIndex + 1]
        : null;

    UpdateWorkflowSortOrder(targetWorkflow.id, {
      placeBeforeItem: nextWorkflow?.id,
    })
      .then((response) => {
        workflowList[targetIndex].sortOrder = response.sortOrder;

        let updatedWorkflow = {
          ...workflows,
          [targetWorkflow.id]: {
            ...workflows[targetWorkflow.id],
            sortOrder: response.sortOrder,
          },
        };
        if (response.nextItemSortOrder && nextWorkflow) {
          // This if statement is for handling the edge case, illustrated below:
          // Initial: [Workflow A ("a"), Workflow B ("an"), ..., New Workflow ("some_sort_order_value")]
          // New Workflow is moved to the front of the list
          // After: [New Workflow ("a"), Workflow A ("ag"), Workflow B ("an"), ...]
          // Post: The sort order for New Workflow and Workflow A need to be updated.
          updatedWorkflow = {
            ...workflows,
            [nextWorkflow.id]: {
              ...workflows[nextWorkflow.id],
              sortOrder: response.nextItemSortOrder,
            },
          };
          workflowList[targetIndex + 1].sortOrder = response.nextItemSortOrder;
        }
        dispatch(SetWorkflows(Object.values(updatedWorkflow)));

        // Update the filter to reflect changes on the hub
        if (filters.workflows) {
          let firstOccurenceIndex = -1;
          const workflowsShown = [];
          // Remove existing workflows from the filters, only the ones shown in the
          // hub
          const filtered = filters.workflows.filter((workflow, index) => {
            const matchedWorkflow = workflowList.find((w) => {
              return w.id === workflow.id;
            });
            if (matchedWorkflow) {
              firstOccurenceIndex =
                firstOccurenceIndex === -1 ? index : firstOccurenceIndex;
              workflowsShown.push(matchedWorkflow);
              return false;
            }
            return true;
          });

          if (firstOccurenceIndex !== -1) {
            // Update the filter with the new order if they are shown in the hub
            workflowsShown.sort(sortWorkflow);
            filtered.splice(firstOccurenceIndex, 0, ...workflowsShown);

            const filtersCopy = { ...filters };
            filtersCopy.workflows = filtered;
            dispatch(SetFilters(filtersCopy));
          }
        }
        callback(true);
      })
      .catch((error) => {
        console.error(error);
        dispatch(SetWorkflows(oldWorkflowList));
        callback(false);
      });
  };
};
