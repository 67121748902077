import React from "react";
import PropTypes from "prop-types";

import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@material-ui/core";

const useStyles = makeStyles({
  table: {
    fontFamily: '"Helvetica Neue", Arial, Helvetica, sans-serif',
    maxWidth: "350px",
  },
  currentPlanCell: { color: "#5B5B5B", verticalAlign: "top" },
  totalCell: { fontWeight: "bold" },
});

export function SubscriptionChargesTable({ subscriptionInfo, currencySymbol }) {
  const {
    legacySeats,
    legacyCharges,
    regularCharges,
    regularSeats,
    regularPrice,
    seatsNeeded,
    additionalCharges,
    newTotalSeats,
    newTotalCharges,
  } = subscriptionInfo;
  const { table, currentPlanCell, totalCell } = useStyles();

  const formatPrice = (price) => {
    return `${currencySymbol}${price}`;
  };

  return (
    <Table className={table}>
      <TableBody>
        <TableRow>
          <TableCell className={currentPlanCell}>Current</TableCell>
          <TableCell className={currentPlanCell}>
            {legacySeats > 0 && <div>{legacySeats}</div>}
            {regularSeats > 0 &&
              `${regularSeats} x ${formatPrice(regularPrice)}`}
          </TableCell>
          <TableCell className={currentPlanCell}>
            {legacySeats > 0 && <div>{formatPrice(legacyCharges)}</div>}
            {regularCharges > 0 && formatPrice(regularCharges)}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Additional</TableCell>
          <TableCell>
            {seatsNeeded} x {formatPrice(regularPrice)}
          </TableCell>
          <TableCell>{formatPrice(additionalCharges)}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell className={totalCell}>Total</TableCell>
          <TableCell className={totalCell}>{newTotalSeats}</TableCell>
          <TableCell className={totalCell}>
            {formatPrice(newTotalCharges)}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}

SubscriptionChargesTable.propTypes = {
  subscriptionInfo: PropTypes.shape({
    legacySeats: PropTypes.number.isRequired,
    legacyCharges: PropTypes.number.isRequired,
    regularSeats: PropTypes.number.isRequired,
    regularCharges: PropTypes.number.isRequired,
    newTotalSeats: PropTypes.number.isRequired,
    newTotalCharges: PropTypes.number.isRequired,
    regularPrice: PropTypes.number.isRequired,
    seatsNeeded: PropTypes.number.isRequired,
    additionalCharges: PropTypes.number.isRequired,
  }).isRequired,
  currencySymbol: PropTypes.string,
};
SubscriptionChargesTable.defaultProps = {
  currencySymbol: "$",
};

export default SubscriptionChargesTable;
