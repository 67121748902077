import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Icon } from "semantic-ui-react";

import { PrivacyModeContext } from "components/PrivacyMode/Context";
import Selector from "components/Selector";

import {
  HubContext,
  ToasterContext,
} from "scenes/Hubly/components/Workspace/Provider";

export function AddClientSelector({ loading, members, onCancel, onAdd }) {
  const { piiMask } = useContext(PrivacyModeContext);
  const { fireError } = useContext(ToasterContext);
  const { fetchAllClientNames } = useContext(HubContext);
  const [loadingClient, setLoadingClient] = useState(true);
  const [allClients, setAllClients] = useState([]);

  // Currently, we are still fetching from allNames endpoint, the pagination
  // will introduce bug when all clients on the first page has been assigned
  // to the stream or tag
  useEffect(() => {
    let mounted = true;

    if (loadingClient) {
      fetchAllClientNames()
        .then((resp) => {
          if (mounted) {
            setAllClients(resp);
          }
        })
        .catch((error) => {
          fireError("Failed to load list of clients.", error);
        })
        .finally(() => {
          if (mounted) {
            setLoadingClient(false);
          }
        });
    }

    return () => {
      mounted = false;
    };
  }, [loadingClient, fetchAllClientNames, fireError]);

  const filteredClients = allClients.filter(({ id: clientId }) => {
    return !members.some(({ id: mId }) => {
      return mId === clientId;
    });
  });

  return (
    <div style={{ display: "flex", alignItems: "center", width: "350px" }}>
      <Selector
        name="Clients"
        dropdownProps={{
          placeholder: "Search for a client",
          noResultsMessage: "No more clients",
          className: piiMask("fs-block dd-privacy-mask"),
          loading: loading || loadingClient,
          disabled: loading,
          selectOnNavigation: false,
          selectOnBlur: false,
          closeOnBlur: false,
        }}
        selection={filteredClients.map(({ id, name }) => {
          return { key: id, value: name };
        })}
        multiple={false}
        selected=""
        onSelect={(clientId) => {
          onAdd(
            filteredClients.find(({ id }) => {
              return id === clientId;
            })
          );
        }}
      />
      <Icon
        color="grey"
        link
        name="cancel"
        onClick={() => {
          onCancel(false);
        }}
        style={{
          transform: "translate(3px, 0px)",
          fontSize: "12pt",
          marginLeft: "0.8em",
        }}
      />
    </div>
  );
}

AddClientSelector.defaultProps = {
  members: [],
  loading: false,
};

AddClientSelector.propTypes = {
  loading: PropTypes.bool,
  members: PropTypes.array,
  onCancel: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
};

export default AddClientSelector;
