import { useState } from "react";

export function useForm({
  initialValue = {},
  fieldValidator = () => {
    return "";
  },
  formValidator = () => {
    return true;
  },
}) {
  const [data, setData] = useState(initialValue);
  const [errors, setErrors] = useState({});

  const setDataValue = (params) => {
    if (!params) return;
    const error = fieldValidator(params);
    const { name, value } = params;
    setErrors((prevState) => {
      return { ...prevState, [name]: error };
    });
    setData((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const getErrorList = () => {
    return Object.values(errors).filter((error) => {
      return error !== "";
    });
  };

  const register = ({ name, required = false, errortext = "" }) => {
    return {
      name: name,
      required: required,
      errortext: errortext,
      value:
        data && (data[name] !== null || data[name] !== undefined)
          ? data[name]
          : null,
      error: required && name in errors && errors[name] !== "",
      onChange: (_, params) => {
        setDataValue(params);
      },
    };
  };

  return {
    disableButton: !formValidator(data),
    errorList: getErrorList(),
    register: register,
    data: data,
    clearForm: () => {
      setData({});
    },
  };
}

export default useForm;
