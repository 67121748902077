import { Auth } from "aws-amplify";

export const getCognitoId = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const cognitoUser = await Auth.currentSession();
      const id = cognitoUser.idToken.payload.sub;
      resolve(id);
    } catch (error) {
      reject(error);
    }
  });
};

export const getAPIReq = (body, header = {}) => {
  return new Promise(async (resolve, reject) => {
    try {
      const cognitoUser = await Auth.currentSession();
      const init = {
        headers: {
          Authorization: `Bearer ${cognitoUser.idToken.jwtToken}`,
          ...header,
        },
        body: body,
      };
      resolve(init);
    } catch (error) {
      reject(error);
    }
  });
};

export const getAPIQuery = (query) => {
  return new Promise(async (resolve, reject) => {
    try {
      const cognitoUser = await Auth.currentSession();
      const init = {
        headers: {
          Authorization: `Bearer ${cognitoUser.idToken.jwtToken}`,
        },
        queryStringParameters: query,
      };
      resolve(init);
    } catch (error) {
      reject(error);
    }
  });
};
