import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Button, Container, Dropdown, Icon, List } from "semantic-ui-react";

import { MeetingDateTypesDataProvider } from "components/MeetingDateTypeDropdown";

import AddOrRemoveStreamRule from "./components/AddOrRemoveStreamRule";
import AddOrRemoveStreamRuleModal from "./components/AddOrRemoveStreamRuleModal";
import AgeRule from "./components/AgeRule";
import AgeRuleModal from "./components/AgeRuleModal";
import ImportantClientDateRule from "./components/ImportantClientDateRule";
import ImportantClientDatesRuleModal from "./components/ImportantClientDatesRuleModal";
import RepeatingRulesModal from "./components/RepeatingRulesModal";
import RepeatingWorkflowRule from "./components/RepeatingWorkflowRule";
import { AddWorkflowRule, UpdateWorkflowRule } from "./actions";

class WorkflowRules extends React.Component {
  static propTypes = {
    addWorkflowRule: PropTypes.func.isRequired,
    advisor: PropTypes.shape({
      streams: PropTypes.array.isRequired,
    }).isRequired,
    updateWorkflowRule: PropTypes.func.isRequired,
    workflow: PropTypes.shape({
      id: PropTypes.string.isRequired,
      workflowRules: PropTypes.array.isRequired,
    }).isRequired,
    hubId: PropTypes.string.isRequired,
  };

  state = {
    action: "repeating",
    isOpen: false,
    modalOpen: false,
    modalType: "",
  };

  handleChange = (e, { name, value }) => {
    this.setState((state) => {
      return {
        [name]: value,
      };
    });
  };

  addRule = (request, callback) => {
    const { addWorkflowRule, workflow } = this.props;
    addWorkflowRule(workflow, request, callback);
  };

  setWorkflowRuleModal = (open, type) => {
    const { action } = this.state;

    this.setState({
      modalOpen: open,
      modalType: type || action,
    });
  };

  updateRule = (request, callback) => {
    const { updateWorkflowRule, workflow } = this.props;
    updateWorkflowRule(workflow, request, callback);
  };

  render() {
    const { workflow, hubId } = this.props;
    const { action, isOpen, modalOpen, modalType } = this.state;

    return (
      <MeetingDateTypesDataProvider hubId={hubId}>
        <Container>
          <List style={{ marginTop: "0em", marginBottom: "0em" }}>
            {workflow.workflowRules && workflow.workflowRules.length <= 0 && (
              <span style={{ color: "grey" }}>No rules applied</span>
            )}
            {workflow.workflowRules.map((rule) => {
              if (rule.type === "REPEATING_RULE") {
                return (
                  <RepeatingWorkflowRule
                    key={rule.id}
                    rule={rule}
                    setWorkflowRuleModal={this.setWorkflowRuleModal}
                    workflow={workflow}
                  />
                );
              } else if (
                rule.type === "ADD_ATTRIBUTES" ||
                rule.type === "REMOVE_ATTRIBUTES"
              ) {
                return (
                  <AddOrRemoveStreamRule
                    key={rule.id}
                    rule={rule}
                    setWorkflowRuleModal={this.setWorkflowRuleModal}
                    workflow={workflow}
                  />
                );
              } else if (rule.type === "IMPORTANT_DATE_RULE") {
                return (
                  <ImportantClientDateRule
                    key={rule.id}
                    rule={rule}
                    setWorkflowRuleModal={this.setWorkflowRuleModal}
                    workflow={workflow}
                  />
                );
              } else if (rule.type === "AGE_RULE") {
                return (
                  <AgeRule
                    key={rule.id}
                    rule={rule}
                    setWorkflowRuleModal={this.setWorkflowRuleModal}
                    workflow={workflow}
                  />
                );
              } else {
                return null;
              }
            })}
            {modalOpen && modalType === "repeating" && (
              <RepeatingRulesModal
                save={this.addRule}
                setWorkflowRuleModal={this.setWorkflowRuleModal}
                update={this.updateRule}
              />
            )}
            {modalOpen && modalType === "important_date" && (
              <ImportantClientDatesRuleModal
                save={this.addRule}
                setWorkflowRuleModal={this.setWorkflowRuleModal}
                update={this.updateRule}
              />
            )}
            {modalOpen && modalType === "stream" && (
              <AddOrRemoveStreamRuleModal
                save={this.addRule}
                setWorkflowRuleModal={this.setWorkflowRuleModal}
                update={this.updateRule}
                workflow={workflow}
              />
            )}
            {modalOpen && modalType === "age" && (
              <AgeRuleModal
                save={this.addRule}
                setWorkflowRuleModal={this.setWorkflowRuleModal}
                update={this.updateRule}
                workflow={workflow}
              />
            )}
          </List>
          {isOpen ? (
            <div>
              <Dropdown
                compact
                fluid
                name="action"
                onChange={this.handleChange}
                options={[
                  {
                    key: "repeating",
                    text: "Repeating Rule",
                    value: "repeating",
                  },
                  {
                    key: "important_date",
                    text: "Important Client Date Rule",
                    value: "important_date",
                  },
                  {
                    key: "stream",
                    text: "Add or Remove Stream/Tag Rule",
                    value: "stream",
                  },
                  { key: "age", text: "Age Based Rule", value: "age" },
                ]}
                selection
                style={{ marginTop: "0.5em" }}
                value={action}
                data-test="workflow-rule-dropdown"
              />
              <Button
                compact
                positive
                content="Configure"
                onClick={(e, data) => {
                  this.setState({ isOpen: false });
                  this.setWorkflowRuleModal(true);
                }}
              />
              <Icon
                name="delete"
                color="grey"
                size="large"
                link
                onClick={() => {
                  this.setState({ isOpen: false });
                }}
              />
            </div>
          ) : (
            <Button
              basic
              compact
              content="Add Rule"
              hidden={isOpen}
              onClick={() => {
                this.setState({ isOpen: true });
              }}
            />
          )}
        </Container>
      </MeetingDateTypesDataProvider>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    hubId: state.hubly.data.hub.selected.hub.id,
    advisor: state.hubly.data.advisor,
    editWorkflowRule: state.hubly.scenes.hubly.workflowRules.editWorkflowRule,
    workflow: state.hubly.data.hub.workflows[ownProps.workflowId],
    workflows: state.hubly.data.hub.workflows,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addWorkflowRule: (workflow, workflowRule, callback) => {
      dispatch(AddWorkflowRule(workflow, workflowRule, callback));
    },
    updateWorkflowRule: (workflow, workflowRule, callback) => {
      dispatch(UpdateWorkflowRule(workflow, workflowRule, callback));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowRules);
