import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Header, Message, Segment } from "semantic-ui-react";

import Loading from "components/Loading";

import { SetLoginErrorMessage } from "../actions";

class LoginContainer extends React.Component {
  static defaultProps = {
    loginInfoMessage: null,
  };

  static propTypes = {
    children: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    loginErrorMessage: PropTypes.string.isRequired,
    loginInfoMessage: PropTypes.object,
    signingIn: PropTypes.bool.isRequired,
  };

  render() {
    const { children, loginErrorMessage, loginInfoMessage, signingIn, title } =
      this.props;
    const errorMessage = loginErrorMessage || "";
    const infoMessage = loginInfoMessage;

    if (signingIn) {
      return <Loading active message="Signing you in..." />;
    }

    return (
      <div
        style={{
          position: "absolute",
          minWidth: "100%",
          minHeight: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ width: "500px" }}>
          <img
            alt="Hubly"
            src="/images/hubly.png"
            style={{ margin: "auto", display: "block", width: "200px" }}
          />
          <Segment>
            <Header as="h3">{title}</Header>
            <Message negative hidden={errorMessage.length === 0}>
              <Message.Header>Error</Message.Header>
              <p>{errorMessage}</p>
            </Message>
            <Message info hidden={infoMessage == null}>
              {infoMessage}
            </Message>
            {children}
          </Segment>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loginInfoMessage: state.hubly.scenes.login.loginInfoMessage,
    loginErrorMessage: state.hubly.scenes.login.loginErrorMessage,
    signingIn: state.hubly.scenes.login.signingIn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginErrorMessage: (message) => {
      dispatch(SetLoginErrorMessage(message));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
