import React from "react";
import PropTypes from "prop-types";

class Anchor extends React.Component {
  static defaultProps = {
    disabled: false,
    style: {},
    tooltip: "",
  };

  static propTypes = {
    children: PropTypes.node.isRequired,
    disabled: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    style: PropTypes.object,
    tooltip: PropTypes.string,
  };

  render() {
    const { children, disabled, onClick, style, tooltip } = this.props;
    const inputProps = {
      style: style,
    };

    if (disabled) {
      inputProps.title = tooltip;
      inputProps.className = "non_clickable_text";
    } else {
      inputProps.onClick = onClick;
      inputProps.className = "clickable_text";
    }

    return <span {...inputProps}>{children}</span>;
  }
}

export default Anchor;
