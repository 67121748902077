import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {
  Button,
  Checkbox,
  Dropdown,
  Header,
  Icon,
  Label,
  Popup,
} from "semantic-ui-react";

import {
  allMonths,
  monthsToString,
  stringToMonths,
} from "data/libs/taskConditions";

import {
  AlterTaskCondition,
  ApplyTaskCondition,
  RemoveTaskCondition,
  ToggleTaskConditionPopup,
} from "components/Task/actions";

class TaskTimeEstimate extends React.Component {
  static defaultProps = {
    addingTask: false,
    editTaskState: () => {},
    modalTaskId: "",
  };

  static propTypes = {
    addingTask: PropTypes.bool,
    addTaskCondition: PropTypes.func.isRequired,
    editTaskCondition: PropTypes.func.isRequired,
    editTaskState: PropTypes.func,
    deleteTaskCondition: PropTypes.func.isRequired,
    hub: PropTypes.shape({
      streams: PropTypes.array.isRequired,
    }).isRequired,
    isTaskConditionOpen: PropTypes.bool.isRequired,
    modalTaskId: PropTypes.string,
    tags: PropTypes.object.isRequired,
    task: PropTypes.object.isRequired,
    toggleTaskConditionPopup: PropTypes.func.isRequired,
    workflow: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
  };

  monthOptions = Object.keys(allMonths).map((month) => {
    return {
      value: month,
      key: month,
      text: month,
    };
  });

  constructor(props) {
    super(props);
    const selectedItems = [];
    let type = "ANY";
    if (props.task.taskCondition) {
      const { streams = [], tags = [] } = props.task.taskCondition;
      const allTags = props.tags;

      streams.forEach((stream) => {
        selectedItems.push({
          color: stream.color,
          content: (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Label
                empty
                circular
                style={{ backgroundColor: stream.color, marginRight: "1em" }}
              />
              <div>{stream.name}</div>
            </div>
          ),
          key: stream.id,
          text: stream.name,
          type: "stream",
          value: stream.id,
        });
      });

      tags.forEach((tagId) => {
        const tag = allTags[tagId];

        selectedItems.push({
          color: "grey",
          content: (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Label empty circular style={{ marginRight: "1em" }} />
              <div>{tag.name}</div>
            </div>
          ),
          key: tag.id,
          text: tag.name,
          type: "tag",
          value: tag.id,
        });
      });
      type = props.task.taskCondition.conditionType;
    }

    this.state = {
      selectedItems: selectedItems,
      conditionType: type,
      loading: false,
      searchInput: "",
      dropdownOpen: false,
      monthsCheckBox: props.task.taskCondition?.months != null,
      selectedMonths: stringToMonths(props.task.taskCondition?.months),
      conditionTypeOptions: [
        {
          value: "ANY",
          key: "ANY",
          text: "Any",
        },
        {
          value: "ALL",
          key: "ALL",
          text: "All",
        },
      ],
    };
  }

  getStreamOptions = () => {
    const { hub } = this.props;
    const streamOptions = [];

    hub.streams
      .sort((a, b) => {
        return a.name > b.name ? 1 : -1;
      })
      .forEach((stream) => {
        streamOptions.push({
          color: stream.color,
          content: (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Label
                empty
                circular
                style={{ backgroundColor: stream.color, marginRight: "1em" }}
              />
              <div>{stream.name}</div>
            </div>
          ),
          key: stream.id,
          text: stream.name,
          type: "stream",
          value: stream.id,
        });
      });
    return streamOptions;
  };

  getTagOptions = () => {
    const { tags } = this.props;
    const tagOptions = [];

    Object.values(tags)
      .sort((a, b) => {
        return a.name > b.name ? 1 : -1;
      })
      .forEach((tag) => {
        tagOptions.push({
          color: "grey",
          content: (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Label empty circular style={{ marginRight: "1em" }} />
              <div>{tag.name}</div>
            </div>
          ),
          key: tag.id,
          text: tag.name,
          type: "tag",
          value: tag.id,
        });
      });
    return tagOptions;
  };

  // dropdownOnChange is only called when removing items from the list since it is a controlled component
  // Thus we only need to filter our selectedItem to remove the removed element(s)
  dropdownOnChange = (data) => {
    let { selectedItems } = this.state;

    selectedItems = selectedItems.filter((item) => {
      return data.value.indexOf(item.value) >= 0;
    });

    this.setState({
      selectedItems: selectedItems,
    });
  };

  monthDropdownOnChange = (e, { value }) => {
    this.setState({
      selectedMonths: value,
    });
  };

  selectStream = (stream) => {
    const { selectedItems } = this.state;
    stream.type = "stream";

    selectedItems.push(stream);
    this.setState({
      selectedItems: selectedItems,
      dropdownOpen: false,
    });
  };

  selectTag = (tag) => {
    const { selectedItems } = this.state;
    tag.type = "tag";

    selectedItems.push(tag);
    this.setState({
      selectedItems: selectedItems,
      dropdownOpen: false,
    });
  };

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };

  handleTyping = (e) => {
    this.setState({ searchInput: e.target.value });
  };

  isOpen = () => {
    const { isTaskConditionOpen, modalTaskId, task } = this.props;
    return task.id === modalTaskId && isTaskConditionOpen;
  };

  remove = () => {
    this.setState({ loading: true });
    const { addingTask, editTaskState, task, workflow, deleteTaskCondition } =
      this.props;
    if (addingTask) {
      editTaskState("taskCondition", null, "edit");
    } else {
      deleteTaskCondition(
        workflow.id,
        task.id,
        task.taskCondition.id,
        this.callbackAndReset
      );
      this.setState({ selectedMonths: [], monthsCheckBox: false });
    }
  };

  confirm = () => {
    const {
      addingTask,
      addTaskCondition,
      editTaskState,
      task,
      workflow,
      editTaskCondition,
    } = this.props;
    const { selectedItems, conditionType, selectedMonths } = this.state;
    this.setState({ loading: true });

    if (selectedItems.length === 0 && selectedMonths.length === 0) {
      if (task.taskCondition) {
        this.remove();
      }
      this.setState({ loading: false });
      return;
    }

    const streams = [];
    const tags = [];
    selectedItems.forEach((item) => {
      if (item.type === "stream") {
        streams.push(item.key);
      } else {
        tags.push(item.key);
      }
    });

    const months = monthsToString(selectedMonths);
    if (addingTask) {
      const taskCondition = {
        streams: streams,
        tags: tags,
        conditionType: conditionType,
        months: months,
      };
      editTaskState("taskCondition", taskCondition, "edit");
      this.callback(true);
    } else if (task.taskCondition) {
      editTaskCondition(
        workflow.id,
        task.id,
        task.taskCondition.id,
        streams,
        tags,
        conditionType,
        months,
        this.callback
      );
    } else {
      addTaskCondition(
        workflow.id,
        task.id,
        streams,
        tags,
        conditionType,
        months,
        this.callback
      );
    }
  };

  callback = (success) => {
    const { toggleTaskConditionPopup } = this.props;
    this.setState({ loading: false });
    if (success) {
      toggleTaskConditionPopup();
    }
  };

  callbackAndReset = (success) => {
    const { toggleTaskConditionPopup } = this.props;
    this.setState({ loading: false });
    if (success) {
      toggleTaskConditionPopup();
      this.setState({
        selectedItems: [],
        conditionType: "ANY",
        loading: false,
        searchInput: "",
        dropdownOpen: false,
      });
    }
  };

  renderTrigger() {
    const { task, toggleTaskConditionPopup } = this.props;
    if (task.taskCondition) {
      return (
        <Icon
          link
          name="filter"
          color="grey"
          onClick={() => {
            toggleTaskConditionPopup(task.id, true);
          }}
        />
      );
    } else {
      const isOpen = this.isOpen();
      return (
        <Icon.Group
          className={!isOpen && "show_on_hover dark_grey_on_hover"}
          onClick={() => {
            toggleTaskConditionPopup(task.id, true);
          }}
          style={{ fontSize: "13pt" }}
          title="Task Condition"
        >
          <Icon
            link
            name="filter"
            style={isOpen ? { color: "#aaaaaa", margin: 0 } : { margin: 0 }}
          />
          <Icon
            link
            corner
            name="add"
            style={
              isOpen
                ? { color: "#aaaaaa" }
                : { color: "#aaaaaa", textShadow: "none" }
            }
          />
        </Icon.Group>
      );
    }
  }

  render() {
    const { task, toggleTaskConditionPopup } = this.props;
    const {
      conditionType,
      conditionTypeOptions,
      loading,
      searchInput,
      selectedItems,
      dropdownOpen,
      monthsCheckBox,
      selectedMonths,
    } = this.state;

    const selectedItemValues = [];
    selectedItems.forEach((item) => {
      selectedItemValues.push(item.key);
    });

    // We do the filtering separately because the Dropdown options attributes requires all options to be included
    // Otherwise, when searching the already selected streams would no longer display in the input box
    const streamOptions = this.getStreamOptions();
    const streamOptionsFiltered = streamOptions.filter((stream) => {
      return (
        stream.text.toLowerCase().includes(searchInput.toLowerCase()) &&
        selectedItemValues.indexOf(stream.key) === -1
      );
    });
    const tagOptions = this.getTagOptions();
    const tagOptionsFiltered = tagOptions.filter((tag) => {
      return (
        tag.text.toLowerCase().includes(searchInput.toLowerCase()) &&
        selectedItemValues.indexOf(tag.key) === -1
      );
    });

    if (!this.isOpen()) {
      return this.renderTrigger();
    } else {
      return (
        <Popup
          on="click"
          open
          onClose={() => {
            toggleTaskConditionPopup();
          }}
          onOpen={() => {
            toggleTaskConditionPopup(task.id, true);
          }}
          popperModifiers={{
            preventOverflow: { boundariesElement: "window", enabled: false },
          }}
          position="bottom center"
          trigger={this.renderTrigger()}
        >
          <div>
            <div style={{ display: "flex" }}>
              <Header as="h4" style={{ marginBottom: "1em" }}>
                {task.taskCondition ? "Edit Condition" : "Add Condition"}
              </Header>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "1em",
                  }}
                >
                  <div>Assign to clients with</div>
                  <Dropdown
                    name="conditionType"
                    value={conditionType}
                    options={conditionTypeOptions}
                    selection
                    onChange={this.handleChange}
                    compact
                    style={{ margin: "0px 1em", minWidth: "70px" }}
                  />
                  <div style={{ marginRight: "1em" }}>of the following:</div>
                </div>
                <Dropdown
                  clearable
                  open={dropdownOpen}
                  onOpen={() => {
                    this.setState({ dropdownOpen: true });
                  }}
                  onClose={() => {
                    this.setState({ dropdownOpen: false });
                  }}
                  multiple
                  onChange={(event, data) => {
                    this.dropdownOnChange(data);
                  }}
                  onSearchChange={this.handleTyping}
                  options={streamOptions.concat(tagOptions)}
                  placeholder="Select streams or tags"
                  renderLabel={(item, index, object) => {
                    object.content = item.text;
                    object.style = {
                      backgroundColor: item.color,
                      color: "white",
                    };
                    if (item.type === "tag") {
                      object.className = "rule_modal_dropdown_tag_label";
                      object.icon = "hashtag";
                    }
                    return object;
                  }}
                  search
                  selection
                  style={{ width: "380px", marginBottom: "1em" }}
                  value={selectedItemValues}
                >
                  <Dropdown.Menu
                    id="filtering_dropdown_menu"
                    style={{ paddingBottom: "1em" }}
                  >
                    <Dropdown.Header
                      content="Streams"
                      style={{ fontSize: "10pt" }}
                    />
                    {streamOptionsFiltered.length > 0 ? (
                      streamOptionsFiltered.map((stream) => {
                        return (
                          <Dropdown.Item
                            key={stream.key}
                            onClick={() => {
                              this.selectStream(stream);
                            }}
                          >
                            <Icon
                              name="circle"
                              style={{ color: stream.color }}
                            />
                            {stream.text}
                          </Dropdown.Item>
                        );
                      })
                    ) : (
                      <span style={{ marginLeft: "2em", color: "grey" }}>
                        No matching streams
                      </span>
                    )}
                    <Dropdown.Header
                      content="Tags"
                      style={{ fontSize: "10pt" }}
                    />
                    {tagOptionsFiltered.length > 0 ? (
                      tagOptionsFiltered.map((tag) => {
                        return (
                          <Dropdown.Item
                            key={tag.key}
                            onClick={() => {
                              this.selectTag(tag);
                            }}
                          >
                            <Icon name="hashtag" />
                            {tag.text}
                          </Dropdown.Item>
                        );
                      })
                    ) : (
                      <span style={{ marginLeft: "2em", color: "grey" }}>
                        No matching tags
                      </span>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div>
                <Checkbox
                  checked={monthsCheckBox}
                  label="Only apply in certain months"
                  onClick={() => {
                    if (monthsCheckBox)
                      this.setState({
                        selectedMonths: [],
                        monthsCheckBox: false,
                      });
                    else this.setState({ monthsCheckBox: true });
                  }}
                />
              </div>
              {monthsCheckBox && (
                <div>
                  <Dropdown
                    className="dropdown_size_3"
                    fluid
                    selection
                    search
                    multiple
                    style={{ marginTop: "0.5em" }}
                    placeholder="Select months in which condition applies"
                    options={this.monthOptions}
                    onChange={this.monthDropdownOnChange}
                    value={selectedMonths}
                  />
                </div>
              )}
            </div>
            <div style={{ marginTop: "1em" }}>
              {task.taskCondition && (
                <Button
                  content="Remove"
                  disabled={loading}
                  negative
                  onClick={this.remove}
                  style={{ float: "left", marginLeft: "5px" }}
                />
              )}
              <Button
                content={task.taskCondition ? "Save" : "Add"}
                floated="right"
                loading={loading}
                onClick={this.confirm}
                positive
              />
            </div>
          </div>
        </Popup>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    hub: state.hubly.data.hub.selected.hub,
    isTaskConditionOpen: state.hubly.task.isTaskConditionOpen,
    modalTaskId: state.hubly.task.modalTaskId,
    tags: state.hubly.data.hub.tags,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    addTaskCondition: (
      workflowId,
      taskId,
      streams,
      tags,
      conditionType,
      months,
      callback
    ) => {
      dispatch(
        ApplyTaskCondition(
          workflowId,
          taskId,
          streams,
          tags,
          conditionType,
          months,
          callback
        )
      );
    },
    editTaskCondition: (
      workflowId,
      taskId,
      conditionId,
      streams,
      tags,
      conditionType,
      months,
      callback
    ) => {
      dispatch(
        AlterTaskCondition(
          workflowId,
          taskId,
          conditionId,
          streams,
          tags,
          conditionType,
          months,
          callback
        )
      );
    },
    deleteTaskCondition: (workflowId, taskId, conditionId, callback) => {
      dispatch(RemoveTaskCondition(workflowId, taskId, conditionId, callback));
    },
    toggleTaskConditionPopup: (taskId, isOpen) => {
      dispatch(ToggleTaskConditionPopup(taskId, isOpen));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskTimeEstimate);
