import React, { useEffect, useRef, useState } from "react";

import { createWidget } from "@typeform/embed";
import { Button, Icon } from "semantic-ui-react";

import "@typeform/embed/build/css/widget.css";

const NotificationsTypeformEmbed = () => {
  const typeformEmbedRef = useRef();
  const [sliderOpen, setSliderOpen] = useState(false);

  useEffect(() => {
    createWidget("bL9yu0tY", { container: typeformEmbedRef.current });
  }, []);

  return (
    <React.Fragment>
      <div
        style={{
          position: "fixed",
          height: "100%",
          width: "720px",
          top: 0,
          right: sliderOpen ? 0 : "-720px",
          boxShadow: "-2px -2px 8px 0 #d0d0d0",
          background: "white",
          zIndex: 1001,
        }}
      >
        <Icon
          name="close"
          color="grey"
          onClick={() => {
            setSliderOpen(false);
          }}
          size="large"
          style={{ marginLeft: "5px", marginTop: "5px", cursor: "pointer" }}
        />
        <div
          style={{ width: "100%", height: "calc(100% - 40px)" }}
          ref={typeformEmbedRef}
          id="typeform-container"
        />
      </div>
      <div style={{ marginTop: "32px" }}>
        <h5>
          Email Notifications are still in Beta. We’d love to hear what you’d
          like you see!
        </h5>
        <Button
          onClick={() => {
            setSliderOpen(true);
          }}
          disabled={sliderOpen}
        >
          Let Us Know
        </Button>
      </div>
    </React.Fragment>
  );
};

export default NotificationsTypeformEmbed;
