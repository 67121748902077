import React, { useEffect } from "react";
import PropTypes from "prop-types";

import {
  CrmAdvisorSelector,
  Icon,
  Popper,
  useAdvisors,
  useAssignAdvisorAction,
  useBooleanState,
} from "./components";

export function CrmAdvisorMatcher({ member, onUpdated }) {
  const { name, advisor, serviceRole, crmListUser } = member;

  const [isOpen, open, close] = useBooleanState();
  const { loading, advisors, fetch } = useAdvisors(crmListUser?.id);
  const { assignAdvisor, assigning } = useAssignAdvisorAction(onUpdated);

  useEffect(() => {
    if (isOpen) fetch();
  }, [isOpen, fetch]);

  if (advisor?.id || !serviceRole.isSynced) {
    return null;
  }

  if (isOpen) {
    return (
      <CrmAdvisorSelector
        flowing
        loading={loading || assigning}
        title={`Map ${name || "Unassigned"} to Hubly User`}
        data-test="crm-advisor-selector-popup"
        selected={member}
        advisors={advisors}
        onClose={close}
        open={isOpen}
        onSelect={(advisorId) => {
          assignAdvisor(
            { crmUserId: crmListUser.id, advisorId: advisorId },
            {
              successMsg: `Hubly user has been successfully mapped${
                name ? ` to ${name}` : ""
              }.`,
              defaultErrorMsg:
                "Failed to assign advisor to CRM user, please try again or contact support for assistance.",
            }
          );
        }}
      >
        <Icon
          name="exclamation triangle"
          color="yellow"
          style={{ marginLeft: "0.3em" }}
        />
      </CrmAdvisorSelector>
    );
  }

  return (
    <Popper
      data-test="crm-advisor-missing-popup"
      content="CRM user does not match an existing Hubly user. Please take action."
      on="hover"
      trigger={
        <Icon
          data-test="crm-advisor-missing-icon"
          onClick={(e) => {
            e.stopPropagation();
            open();
          }}
          name="exclamation triangle"
          color="yellow"
          style={{ marginLeft: "0.3em" }}
        />
      }
    />
  );
}

CrmAdvisorMatcher.propTypes = {
  member: PropTypes.shape({
    name: PropTypes.string,
    advisor: PropTypes.shape({ id: PropTypes.string.isRequired }),
    crmListUser: PropTypes.shape({ id: PropTypes.string.isRequired }),
    serviceRole: PropTypes.shape({
      isSynced: PropTypes.bool,
    }),
  }).isRequired,
  onUpdated: PropTypes.func.isRequired,
};

export default CrmAdvisorMatcher;
