import React from "react";
import PropTypes from "prop-types";

import { Icon } from "semantic-ui-react";

export const ReminderSection = ({ style, children }) => {
  return (
    <div
      style={{
        gap: "3px",
        color: "black",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        ...style,
      }}
    >
      <Icon name="bell outline" style={{ fontSize: "15pt" }} />
      {children}
    </div>
  );
};

ReminderSection.defaultProps = {
  children: undefined,
  style: {},
};

ReminderSection.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object,
};

export default ReminderSection;
