import { useMutationWithToaster } from "hooks";

const addNewUsersFactory = () => {
  /**
   * {
   *   "userInfos": {
   *     "email": String.required,
   *     "firstName": String.required
   *     "lastName": String.required,
   *     "roleId": ID.required,
   *     "inviteNow": Boolean.required
   *   }
   * }
   */
  const query = `
    mutation addNewUsers($userInfos: [UserInfo]!) {
      addNewUsers(userInfos: $userInfos) {
        newUsers {
          id
          name
          email
          lastInviteSentAt
          role {
            title
          }
        }
        warningMessage
      }
    } 
  `;

  const resolver = ({ errors, data }) => {
    if (errors && errors.length) {
      return { data: null, errors: errors };
    }
    return { data: data.addNewUsers };
  };

  return { query, resolver };
};

const useSubmitNewUsers = () => {
  const [submit, { loading }] = useMutationWithToaster(addNewUsersFactory);

  return {
    loading,
    submit,
  };
};

export default useSubmitNewUsers;
