import React from "react";
import PropTypes from "prop-types";

import { Icon } from "semantic-ui-react";

import Tooltip from "components/Tooltip";

function CRMPermissionWarning({ permissionSubject }) {
  return (
    <span>
      <Tooltip
        title={`You cannot see all ${permissionSubject} due to your CRM permission`}
        position="right center"
      >
        <Icon
          name="info circle"
          color="grey"
          style={{ marginLeft: "0.5em" }}
          data-testid="crm-permissions-warning-icon"
        />
      </Tooltip>
    </span>
  );
}

CRMPermissionWarning.propTypes = {
  permissionSubject: PropTypes.string.isRequired,
};

export default CRMPermissionWarning;
