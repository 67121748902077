export const initialState = {};

export const reducer = (state = initialState, action) => {
  if (action.type === "SET_ADVISOR") {
    return action.advisor;
  } else {
    return state;
  }
};
export default reducer;
