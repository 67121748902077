import { uniqBy } from "lodash";

function useMemberDropdown(role, selected, advisors) {
  const toOptions = ({ id, name }) => {
    return { value: id, key: id, text: name };
  };

  const byListType = ({ listTypes }) => {
    return !role.isSynced || listTypes.includes(role.listType);
  };

  const options = advisors?.filter(byListType).map(toOptions) || [];
  const selectedOptions = selected.map(toOptions);

  const formatSelected = (objects) => {
    const s = objects.map(({ key }) => {
      return key;
    });
    return role.isSynced ? s[0] || "" : s;
  };

  return {
    dropdownOptions: uniqBy(options.concat(selectedOptions), "key"),
    selected: formatSelected(selectedOptions),
  };
}

export default useMemberDropdown;
