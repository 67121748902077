import { useCallback, useEffect } from "react";

import { useQuery } from "hooks";

export function useServiceTeams(fetchOn) {
  const getAssignableServiceTeams = useCallback(() => {
    const query = `query getAssignableServiceTeams {
    assignableServiceTeams {
      edges {
        node {
          id
          name
        }
      }
    }
  }  
  `;

    const resolver = ({ data }) => {
      const { assignableServiceTeams } = data;

      return (
        assignableServiceTeams?.edges?.map(({ node }) => {
          return { ...node };
        }) || []
      );
    };

    return { query, resolver };
  }, []);

  const { loading, data, reloadData } = useQuery(getAssignableServiceTeams, {
    options: { fetchOnInit: false },
  });

  useEffect(() => {
    if (fetchOn) reloadData();
  }, [fetchOn, reloadData]);

  return [loading, data];
}

export default useServiceTeams;
