import React from "react";
import { DragDropContext } from "react-beautiful-dnd";
import PropTypes from "prop-types";
import { isEqual } from "lodash";

export function DragDropHub({
  children,
  onWorkflowPositionChanged,
  onTilePositionChanged,
  onTileMovedIntoWorkflow,
}) {
  const handleHubDragDrop = ({ type, source, destination, draggableId }) => {
    if (!destination || isEqual(source, destination)) {
      return;
    }

    if (destination.droppableId !== source.droppableId) {
      if (type === "WorkflowItems") {
        const { droppableId, index: desIndex } = destination;
        const subId = draggableId.split(":")[1];
        onTileMovedIntoWorkflow(subId, droppableId, desIndex);
      }
    } else {
      if (type === "WorkflowItems") {
        const { droppableId, index: desIndex } = destination;
        const subId = draggableId.split(":")[1];
        onTilePositionChanged(subId, droppableId, source.index, desIndex);
      }

      if (type === "Workflows") {
        onWorkflowPositionChanged(source.index, destination.index);
      }
    }
  };

  return (
    <DragDropContext onDragEnd={handleHubDragDrop}>{children}</DragDropContext>
  );
}

DragDropHub.propTypes = {
  children: PropTypes.node.isRequired,
  onWorkflowPositionChanged: PropTypes.func.isRequired,
  onTilePositionChanged: PropTypes.func.isRequired,
  onTileMovedIntoWorkflow: PropTypes.func.isRequired,
};

export default DragDropHub;
