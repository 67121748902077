import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Header } from "semantic-ui-react";

import { MeetingDateTypesDataProvider } from "components/MeetingDateTypeDropdown";

import EventField from "./components/EventField";
import EventPopup from "./components/EventPopup";

class CardMeetings extends React.Component {
  static propTypes = {
    client: PropTypes.shape({
      id: PropTypes.string.isRequired,
      events: PropTypes.array.isRequired,
    }).isRequired,
    hubId: PropTypes.string.isRequired,
  };

  getNextMeeting = () => {
    const { client } = this.props;
    const futureMeetings = client.events.filter((meeting) => {
      return (
        meeting.type === "MEETING" && meeting.date >= new Date().toISOString()
      );
    });
    const sortedEvents = futureMeetings.sort((a, b) => {
      return (a.date > b.date) - (a.date < b.date);
    });
    if (sortedEvents.length > 0) {
      return sortedEvents[0];
    } else {
      return null;
    }
  };

  getPrevMeeting = () => {
    const { client } = this.props;
    const pastMeetings = client.events.filter((meeting) => {
      return (
        meeting.type === "MEETING" && meeting.date < new Date().toISOString()
      );
    });
    const reverseSortedEvents = pastMeetings.sort((a, b) => {
      return (b.date > a.date) - (b.date < a.date);
    });
    if (reverseSortedEvents.length > 0) {
      return reverseSortedEvents[0];
    } else {
      return null;
    }
  };

  render() {
    const { client, hubId } = this.props;
    const nextMeeting = this.getNextMeeting();
    const prevMeeting = this.getPrevMeeting();
    return (
      <MeetingDateTypesDataProvider hubId={hubId}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            margin: "0.5em 1em 0.5em 1em",
          }}
          data-test="card-meetings"
        >
          <Header as="h5" style={{ marginBottom: "0.25em" }}>
            Meeting Dates
          </Header>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <EventField
              client={client}
              fieldType="MEETING"
              event={nextMeeting}
              title="Next"
              type="MEETING"
            />
            <EventField
              client={client}
              fieldType="MEETING"
              event={prevMeeting}
              title="Last"
              type="MEETING"
            />
            <EventField
              client={client}
              fieldType="ADDABLE_POPUP"
              title="Meeting"
              type="MEETING"
            />
            <EventPopup client={client} />
          </div>
        </div>
      </MeetingDateTypesDataProvider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    hubId: state.hubly.data.hub.selected.hub.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CardMeetings);
