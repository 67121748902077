import React from "react";
import PropTypes from "prop-types";

import Moment from "moment";

import "./style.css";

function Snoozer({ dateValue, children, onClick }) {
  return (
    <div
      className="clickable"
      style={{ padding: "0.25em 0.5em" }}
      onClick={() => {
        onClick(dateValue);
      }}
    >
      <span style={{ fontWeight: "bold", color: "#2185d0" }}>{children}</span>
      <span style={{ float: "right", color: "grey", fontSize: "9pt" }}>
        {dateValue.format("dddd, h:mm a").toString()}
      </span>
    </div>
  );
}

Snoozer.propTypes = {
  onClick: PropTypes.func.isRequired,
  dateValue: PropTypes.instanceOf(Moment).isRequired,
  children: PropTypes.string.isRequired,
};

export default Snoozer;
