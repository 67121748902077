import { format } from "date-fns";

export const getMeetingDateTypeNameFromId = (
  meetingDateTypes,
  meetingDateTypeId
) => {
  return (
    meetingDateTypes?.find(({ id }) => {
      return id === meetingDateTypeId;
    })?.name || ""
  );
};

export const getFormattedDate = (date) => {
  return date instanceof Date ? format(date, "MMM do yyyy h:mm a") : "None";
};

export const getFormattedDateForInputField = (date) => {
  return date instanceof Date ? format(date, "MMMM do yyyy, h:mm a") : "None";
};

export default {};
