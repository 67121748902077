import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Container } from "semantic-ui-react";

import "./Hubly.css";

import { LoadMinimizeHubResources } from "data/hub/actions";

import HubModals from "./components/HubModals";
import RefreshRequiredOverlay from "./components/RefreshRequiredOverlay";
import { Menu as SupportTools } from "./components/Support";
import Workspaces from "./components/Workspace";
import Settings from "../Settings";

function Hubly({ hub, advisor, unsyncedUpdates, match, loadResources }) {
  if (match.path.includes("/settings")) return <Settings match={match} />;

  /*
   * THIS IS A TEMPORARY SOULUTION FOR LOADING UNPAGNATED RESOURCES
   */
  loadResources();

  return (
    <div
      style={{
        display: "flex",
        flexFlow: "column",
        height: "calc(100vh - 47px)",
        justifyContent: "flex-start",
      }}
    >
      {unsyncedUpdates > 0 && (
        <RefreshRequiredOverlay unsyncedUpdates={unsyncedUpdates} />
      )}
      <HubModals match={match} />
      {match.path.includes("tools") && <SupportTools />}
      <Container fluid basic="true" className="workflow-container">
        <Workspaces hub={hub} advisor={advisor} />
      </Container>
    </div>
  );
}

Hubly.propTypes = {
  loadResources: PropTypes.func.isRequired,
  unsyncedUpdates: PropTypes.number.isRequired,
  hub: PropTypes.object.isRequired,
  advisor: PropTypes.object.isRequired,
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
  }).isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadResources: () => {
      dispatch(LoadMinimizeHubResources());
    },
  };
};

const mapStateToProps = (state) => {
  const { advisor, websockets, hub } = state.hubly.data;

  return {
    advisor: advisor,
    hub: hub.selected.hub,
    unsyncedUpdates: websockets.unsyncedUpdates,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Hubly);
