import React from "react";
import PropTypes from "prop-types";

import { Header, Progress } from "semantic-ui-react";

export function TileProgress({ percentage, header, color }) {
  return (
    <React.Fragment>
      <Header
        data-test="client-tile-progress-header"
        as="h5"
        style={{
          color: "grey",
          marginBottom: 0,
          fontSize: "10pt",
          fontWeight: "500",
        }}
      >
        {header}
      </Header>
      <Progress
        percent={percentage}
        size="tiny"
        color={color}
        style={{ marginRight: "1em", marginBottom: 0, marginTop: 0 }}
      />
    </React.Fragment>
  );
}

TileProgress.propTypes = {
  percentage: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  header: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};

export default TileProgress;
