import React from "react";

import HubContextConnector from "scenes/Hubly/components/HubModals/components/ClientCard/components/HubContextConnector";

import { ManageServiceTeams } from "./components";

export function ServiceTeamSettings() {
  return (
    <HubContextConnector>
      <ManageServiceTeams />
    </HubContextConnector>
  );
}

export default ServiceTeamSettings;
