import { useCallback } from "react";

import { useMutationWithToaster } from "hooks";

import { toGlobalId } from "scenes/Hubly/components/Workspace/api/helper";

function nullOrGlobalId(model, nullableUuid) {
  if (!nullableUuid || nullableUuid === "unassigned") return null;
  return toGlobalId(model, nullableUuid);
}

export function useReassignTasks(workflow, onDone = () => {}) {
  const mutation = useCallback(() => {
    const query = `mutation reassignTasks($workflow: ID!, $currentAssigned: ID, $newAssigned: ID) {
      reassignTasks(workflowId: $workflow, fromAssigneeId: $currentAssigned, toAssigneeId: $newAssigned) {
        workflow {
          id
        }
        tasks {
          edges {
            node {
              id
            }
          }
        }
      }
    }`;

    const resolver = (resp) => {
      return resp;
    };

    return { query, resolver };
  }, []);

  const [bulkAssign, { loading }] = useMutationWithToaster(mutation, {
    onDone: useCallback(onDone, []),
  });

  const assign = ({ currentAssigned, newAssigned }) => {
    return bulkAssign({
      workflow: toGlobalId("Workflow", workflow.id),
      currentAssigned: nullOrGlobalId("Assignee", currentAssigned?.id),
      newAssigned: nullOrGlobalId("Assignee", newAssigned?.id),
    });
  };

  return { assign: assign, running: loading };
}

export default useReassignTasks;
