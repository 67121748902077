import { useState } from "react";

export function useBooleanState(init = false) {
  const [value, set] = useState(init);

  const setPositive = () => {
    return set(true);
  };

  const setNegative = () => {
    return set(false);
  };

  return [value, setPositive, setNegative];
}

export default useBooleanState;
