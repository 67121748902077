export const initialState = {
  isConnected: false,
  unsyncedUpdates: 0,
};

export const reducer = (state = initialState, action) => {
  if (action.type === "SET_WEBSOCKET_CONNECTION") {
    return { ...state, isConnected: action.isConnected };
  } else if (action.type === "SET_UNSYNCED_UPDATES") {
    return { ...state, unsyncedUpdates: action.unsyncedUpdates };
  } else {
    return state;
  }
};

export default reducer;
