import React from "react";
import { DragDropContext } from "react-beautiful-dnd";
import PropTypes from "prop-types";

import { swap } from "utils";

export function DragDropTileContext({ onReorder, children }) {
  const crossContainerSorter = ({
    toBeSorted,
    toBeFiltered,
    showMore,
    dst,
    src,
  }) => {
    return (c) => {
      const containerToSort = c[toBeSorted].concat({
        ...c[toBeFiltered][src],
        showMore,
      });
      return {
        [toBeSorted]: swap(containerToSort, c[toBeSorted].length, dst),
        [toBeFiltered]: c[toBeFiltered].filter(({ id }) => {
          return id !== c[toBeFiltered][src].id;
        }),
      };
    };
  };

  const withinContainerSorter = ({ toBeSorted, toBeFiltered, dst, src }) => {
    return (c) => {
      return {
        [toBeSorted]: swap(c[toBeSorted], src, dst),
        [toBeFiltered]: c[toBeFiltered],
      };
    };
  };

  const getContainerType = (destId) => {
    return destId === "visible-item-container"
      ? { toBeSorted: "visible", toBeFiltered: "showMore", showMore: false }
      : { toBeSorted: "showMore", toBeFiltered: "visible", showMore: true };
  };

  const selectSorter = (destination, source) => {
    const params = {
      ...getContainerType(destination.droppableId),
      dst: destination.index,
      src: source.index,
    };
    return destination.droppableId !== source.droppableId
      ? crossContainerSorter(params)
      : withinContainerSorter(params);
  };

  return (
    <DragDropContext
      onDragEnd={({ destination, source }) => {
        if (
          !destination ||
          (destination.droppableId === source.droppableId &&
            destination.index === source.index)
        ) {
          return;
        }
        onReorder(selectSorter(destination, source));
      }}
    >
      {children}
    </DragDropContext>
  );
}

DragDropTileContext.propTypes = {
  onReorder: PropTypes.func.isRequired,
  children: PropTypes.node,
};

DragDropTileContext.defaultProps = {
  children: undefined,
};

export default DragDropTileContext;
