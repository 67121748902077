import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Header } from "semantic-ui-react";

import { SetNotificationPreferences } from "./actions";
import { convertToPreferenceObject, disableRedundantToggles } from "./helpers";
import NotificationsTypeformEmbed from "./NotificationsTypeformEmbed";
import SettingsToggleRow from "./SettingsToggleRow";

const SettingsRenderData = {
  notificationChannels: [
    {
      title: "Email",
      key: "email",
      renderDescription: ({ email = "you@domain.com" }) => {
        return [
          "An email notification will be sent immediately to ",
          <strong key={email}>{email}</strong>,
          ".",
        ];
      },
    },
    {
      title: "Daily Briefing (Coming Soon)",
      key: "dailyBriefing",
      renderDescription: ({ email = "you@domain.com" }) => {
        return [
          "A daily summary email will be sent to ",
          <strong key="dailyBriefing_email">{email}</strong>,
          ".",
          <br key="dailyBriefing_br" />,
          <strong key="dailyBriefing">
            Selecting this option will enable it for your account once
            available.
          </strong>,
        ];
      },
    },
    {
      title: "Browser Alert (Coming Soon)",
      key: "browserAlert",
      renderDescription: () => {
        return [
          "An immediate notification will be sent through your web browser.",
          <br key="browserAlert_br" />,
          <strong key="browserAlert">
            Selecting this option will enable it for your account once
            available.
          </strong>,
        ];
      },
    },
  ],
  notificationsSubjects: [
    {
      title: "Tasks",
      key: "task",
      renderDescription: () => {
        return [
          `A notification will be included when the next workflow task for a client is 
          a task that is assigned to you or your role.`,
        ];
      },
    },
    {
      title: "Upload Portal",
      key: "uploadPortal",
      renderDescription: () => {
        return [
          `A notification will be included when a new attachment is uploaded through an
          upload portal link that you created.`,
        ];
      },
    },
    {
      title: "Comments (Coming Soon)",
      key: "comment",
      renderDescription: () => {
        return [
          `A notification will be included when a new comment is 
        created by another user on a client that you’re working on in Hubly.`,
        ];
      },
    },
    {
      title: "Reminders (Coming Soon)",
      key: "reminder",
      renderDescription: () => {
        return [
          `A notification will be included when a reminder comes due for a 
          workflow task that is assigned to you or your role.`,
        ];
      },
    },
  ],
};

const NotificationSettings = ({
  advisorEmail,
  notificationPreferences,
  setNotificationPreferences,
}) => {
  const [loadingKey, setLoadingKey] = useState();

  const handleChangeSetting = async (settingKey, checked) => {
    let newPreferences = { ...notificationPreferences, [settingKey]: checked };
    if (settingKey === "email") {
      newPreferences.task = checked;
    }
    if (settingKey === "task") {
      newPreferences.email = checked;
    }

    if (!checked) {
      const notificationChannels = convertToPreferenceObject(
        SettingsRenderData.notificationChannels,
        newPreferences
      );
      const notificationSubjects = convertToPreferenceObject(
        SettingsRenderData.notificationsSubjects,
        newPreferences
      );
      newPreferences = {
        ...newPreferences,
        ...disableRedundantToggles(notificationChannels, notificationSubjects),
      };
    }

    setLoadingKey(settingKey);
    await setNotificationPreferences(newPreferences);
    setLoadingKey(undefined);
  };

  return (
    <div style={{ marginTop: "1em" }}>
      <Header as="h1" style={{ marginBottom: "0" }}>
        Notification Settings
      </Header>
      <h4>I want to be notified by...</h4>
      <div>
        {SettingsRenderData.notificationChannels.map(
          ({ renderDescirption, ...renderData }) => {
            const { key, renderDescription } = renderData;
            return (
              <SettingsToggleRow
                key={key}
                renderData={{
                  ...renderData,
                  description: renderDescription({ email: advisorEmail }),
                }}
                loading={key === loadingKey}
                checked={
                  notificationPreferences && notificationPreferences[key]
                }
                setPreference={handleChangeSetting}
              />
            );
          }
        )}
      </div>
      <h4>I want to be notified about...</h4>
      <div>
        {SettingsRenderData.notificationsSubjects.map(
          ({ renderDescirption, ...renderData }) => {
            const { key, renderDescription } = renderData;
            return (
              <SettingsToggleRow
                key={key}
                loading={key === loadingKey}
                renderData={{
                  ...renderData,
                  description: renderDescription({ email: advisorEmail }),
                }}
                checked={
                  notificationPreferences && notificationPreferences[key]
                }
                setPreference={handleChangeSetting}
              />
            );
          }
        )}
      </div>
      <NotificationsTypeformEmbed />
    </div>
  );
};

NotificationSettings.propTypes = {
  advisorEmail: PropTypes.string.isRequired,
  notificationPreferences: PropTypes.object.isRequired,
  setNotificationPreferences: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    advisorEmail: state.hubly.data.advisor.email,
    notificationPreferences:
      state.hubly.data.advisor.preferences?.notifications,
  };
};

const mapDispatchToProps = {
  setNotificationPreferences: SetNotificationPreferences,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationSettings);
