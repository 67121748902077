import { GetHouseholds } from "data/libs/households";

const sortHouseholds = (households = []) => {
  return households.sort((a, b) => {
    return a.name < b.name ? -1 : 1;
  });
};

export const AddHousehold = (household) => {
  return {
    type: "ADD_HOUSEHOLD",
    household: household,
  };
};

export const AddHouseholdMember = (householdMember) => {
  return {
    type: "ADD_HOUSEHOLD_MEMBER",
    householdMember: householdMember,
  };
};

export const EditHousehold = (household) => {
  return {
    type: "EDIT_HOUSEHOLD",
    household: household,
  };
};

export const EditHouseholdMember = (householdMember) => {
  return {
    type: "EDIT_HOUSEHOLD_MEMBER",
    householdMember: householdMember,
  };
};

export const RemoveHousehold = (householdId) => {
  return {
    type: "REMOVE_HOUSEHOLD",
    householdId: householdId,
  };
};

export const RemoveHouseholdMember = (householdId, householdMemberId) => {
  return {
    type: "REMOVE_HOUSEHOLD_MEMBER",
    householdId: householdId,
    householdMemberId: householdMemberId,
  };
};

export const SetHousehold = (household) => {
  return {
    type: "SET_HOUSEHOLD",
    household: household,
  };
};

export const SetHouseholds = (households) => {
  const householdsDict = {};
  const sortedHouseholds = sortHouseholds(households);
  sortedHouseholds.forEach((household) => {
    householdsDict[household.id] = household;
  });
  return {
    type: "SET_HOUSEHOLDS",
    households: householdsDict,
  };
};

export function FetchHubHouseholds() {
  return (dispatch, getState) => {
    const { hub } = getState().hubly.data.hub.selected;

    dispatch({
      type: "FETCHING_HUB_HOUSEHOLDS",
      value: true,
    });
    GetHouseholds(hub).then((hubHouseholds) => {
      dispatch(SetHouseholds(hubHouseholds));
      dispatch({
        type: "FETCHING_HUB_HOUSEHOLDS",
        value: false,
      });
    });
  };
}
