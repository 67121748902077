import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { range } from "lodash";

import { datadogRum } from "@datadog/browser-rum";
import getMiliSecondDuration from "analytics/helper";
import { Button, Header, Icon, Popup } from "semantic-ui-react";

import "./Views.css";

import useFetch from "hooks/useFetch";
import { ConfirmationModal } from "components/ConfirmationModal";
import DragDropList from "components/DragDropList";
import DraggableTile from "components/DraggableTile";
import DraggableTilePlaceholder from "components/DraggableTile/components/DraggableTilePlaceholder";
import SimpleFormModal from "components/SimpleFormModal";
import Spinner from "components/Spinner";

import {
  HubContext,
  ToasterContext,
} from "scenes/Hubly/components/Workspace/Provider";

import { useAccessTypeStore } from "../hooks";
import { mapViewToDraggableTileProps } from "./helper";

export function Views({
  selectedView,
  loading,
  disabled,
  onDeselectView,
  onSelectView,
  onUpdateView,
}) {
  const { fetchViews, editView, deleteView, reOrderView } =
    useContext(HubContext);
  const { fireError, fireSuccess } = useContext(ToasterContext);

  const [timeSpentOpeningViewMenu, setTimeSpentOpeningViewMenu] = useState(0);

  const [open, setOpen] = useState(false);

  const [updateLoading, setUpdateLoading] = useState(false);
  const [viewToEdit, setViewToEdit] = useState(null);
  const [viewToDelete, setViewToDelete] = useState(null);
  const { hasAccess } = useAccessTypeStore();

  const {
    loading: loadingViews,
    data: views,
    reloadData,
    numOfFetches,
  } = useFetch(fetchViews);

  const handleUpdateView = (savedView) => {
    const { id, ...request } = savedView;
    setUpdateLoading(true);
    editView(id, request)
      .then(() => {
        setUpdateLoading(false);
        fireSuccess("Successfully edited view.");
        reloadData();
        setViewToEdit(null);
        onUpdateView(savedView);
      })
      .catch((error) => {
        fireError("failed to edit view.", error);
        setUpdateLoading(false);
      });
  };

  const removeView = (view) => {
    setUpdateLoading(true);
    deleteView(view.id)
      .then(() => {
        setUpdateLoading(false);
        reloadData();
        fireSuccess("Successfully deleted view.");
        if (selectedView?.id === view.id) {
          onDeselectView(view);
        }
      })
      .catch((error) => {
        fireError("Failed to delete process.", error);
        setViewToDelete(null);
        setUpdateLoading(false);
      });
  };

  const updateViewOrder = (params) => {
    reOrderView(params)
      .catch((error) => {
        fireError("Failed to reorder view.", error);
      })
      .finally(() => {
        reloadData();
      });
  };

  return (
    <React.Fragment>
      <Popup
        on="click"
        open={open}
        onOpen={() => {
          setOpen(true);
          reloadData();
          setTimeSpentOpeningViewMenu(new Date().getTime());
        }}
        onClose={(event) => {
          // Do not close popup when user is clicking confirmation modal, or
          // view tile menu popup
          if (
            event.target.closest("div.ui.small.modal.visible.active") ||
            event.target.closest("#hide-show-popup") ||
            event.target.closest("#color-picker-popup")
          ) {
            return;
          }

          setOpen(false);
        }}
        onMount={() => {
          datadogRum.addAction("hubly_timespent_view_viewmenu", {
            duration: getMiliSecondDuration(timeSpentOpeningViewMenu),
          });
        }}
        onUnmount={() => {
          setTimeSpentOpeningViewMenu(0);
        }}
        position="bottom left"
        style={{
          paddingRight: 0,
          paddingLeft: 0,
          height: "auto",
          zIndex: "1000",
        }}
        trigger={
          <Button
            basic
            primary={Boolean(selectedView?.id)}
            loading={loading}
            disabled={disabled}
            style={{
              marginRight: "0.5em",
              minWidth: "130px",
              width: "max-content",
              maxWidth: "600px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <Icon
              name="bookmark"
              style={{ opacity: 1, color: selectedView?.color }}
            />
            {selectedView?.name || "Views"}
          </Button>
        }
      >
        <div className="views-popup">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Header
              style={{ width: "100%", margin: "0 0 1em 0", fontSize: "14pt" }}
            >
              Views
            </Header>
            <Spinner active={loadingViews} />
          </div>
          {numOfFetches === 0 &&
            loadingViews &&
            range(3).map((i) => {
              return <DraggableTilePlaceholder key={`processTile${i}`} />;
            })}

          {numOfFetches > 0 && views.length === 0 && (
            <span style={{ color: "grey" }}>No views created.</span>
          )}

          {views.length > 0 && (
            <DragDropList
              id="dragDropViewsList"
              items={views}
              onListReordered={updateViewOrder}
            >
              {(view, viewDragHandleProps) => {
                return (
                  <DraggableTile
                    key={view.id}
                    {...mapViewToDraggableTileProps(view)}
                    active={selectedView?.id === view.id}
                    disabled={!hasAccess()}
                    dragHandleProps={viewDragHandleProps}
                    onDeleteClick={() => {
                      setViewToDelete(view);
                    }}
                    onEditClick={() => {
                      setViewToEdit(view);
                    }}
                    onClick={() => {
                      const selection =
                        selectedView?.id === view.id
                          ? onDeselectView
                          : onSelectView;

                      selection(view);
                      setOpen(false);
                    }}
                  />
                );
              }}
            </DragDropList>
          )}
        </div>
      </Popup>
      {viewToEdit && (
        <SimpleFormModal
          title="View"
          loading={updateLoading}
          value={viewToEdit}
          onClickSave={handleUpdateView}
          onClosed={() => {
            setViewToEdit(null);
          }}
        />
      )}
      {viewToDelete && (
        <ConfirmationModal
          title="Delete View"
          message={`This will delete "${viewToDelete?.name}". This cannot be undone.`}
          icon="delete"
          buttons={[
            {
              text: "Cancel",
              callBack: () => {
                setViewToDelete(null);
              },
            },
            {
              text: "Delete",
              callBack: () => {
                removeView(viewToDelete);
                setViewToDelete(null);
              },
              color: "red",
            },
          ]}
        />
      )}
    </React.Fragment>
  );
}

Views.defaultProps = {
  selectedView: null,
  disabled: false,
  loading: false,
  fireToaster: () => {},
};

Views.propTypes = {
  selectedView: PropTypes.shape({
    id: PropTypes.string,
    description: PropTypes.string,
    color: PropTypes.string,
    name: PropTypes.string,
  }),
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onSelectView: PropTypes.func.isRequired,
  onDeselectView: PropTypes.func.isRequired,
  onUpdateView: PropTypes.func.isRequired,
  fireToaster: PropTypes.func,
};

export default Views;
