import { GetClient, GetClients } from "../../libs/clients";

export const SetActiveClients = (activeClients) => {
  const clients = {};
  activeClients.forEach((client) => {
    clients[client.id] = client;
  });
  return {
    type: "SET_ACTIVE_CLIENTS",
    activeClients: clients,
  };
};

export const SetAllClientNames = (allClientNames) => {
  return {
    type: "SET_ALL_CLIENT_NAMES",
    allClientNames: allClientNames,
  };
};

export const SetLoadRecentClientNames = (loaded) => {
  return {
    type: "SET_LOAD_RECENT_CLIENT_NAMES",
    recentClientNamesLoaded: loaded,
  };
};

export const SetRecentClientNames = (recentClientNames) => {
  return {
    type: "SET_RECENT_CLIENT_NAMES",
    recentClientNames: recentClientNames,
  };
};

export const SetActiveClient = (client) => {
  return {
    type: "SET_ACTIVE_CLIENT",
    client: client,
  };
};

export const DeleteActiveClient = (clientId) => {
  return {
    type: "DELETE_ACTIVE_CLIENT",
    clientId: clientId,
  };
};

export const SetClientHousehold = (clientId, householdId) => {
  return {
    type: "SET_CLIENT_HOUSEHOLD",
    clientId: clientId,
    householdId: householdId,
  };
};

export const ClearClientHousehold = (clientId, householdId) => {
  return {
    type: "CLEAR_CLIENT_HOUSEHOLD",
    clientId: clientId,
    householdId: householdId,
  };
};

export const AddTagToClient = (clientId, tagId) => {
  return {
    type: "ADD_TAG_TO_CLIENT",
    clientId: clientId,
    tagId: tagId,
  };
};

export const RemoveTagFromClient = (clientId, tagId) => {
  return {
    type: "REMOVE_TAG_FROM_CLIENT",
    clientId: clientId,
    tagId: tagId,
  };
};

export const FetchActiveClient = (clientId) => {
  return (dispatch, getState) => {
    GetClient(clientId).then((resp) => {
      dispatch(SetActiveClient(resp));
    });
  };
};

export const GetAllClients = async (hub) => {
  let clients = [];

  const limitForHub = new Map();
  limitForHub.set("4a40ac62-0d91-4feb-b1f6-c18f8ca4a245", 200);
  limitForHub.set("65eded00-efd0-46b6-9446-57850e677201", 200);

  const limit = limitForHub.has(hub.id) ? limitForHub.get(hub.id) : 300;

  return new Promise((resolve, reject) => {
    GetClients(hub, limit, 0).then((response) => {
      if (!response.next) {
        // If there's only one page, just return it
        resolve(response.results);
      } else {
        const requests = [];
        clients = response.results;
        const pages = response.count / limit;
        for (let i = 1; i < pages; i += 1) {
          requests.push(GetClients(hub, limit, i * limit));
        }

        Promise.all(requests).then((allResponses) => {
          for (let i = 0; i < allResponses.length; i += 1) {
            clients = clients.concat(allResponses[i].results);
          }
          resolve(clients);
        });
      }
    });
  });
};

export const RefreshActiveClients = (hub) => {
  return (dispatch, getState) => {
    GetAllClients(hub).then((resp) => {
      dispatch(SetActiveClients(resp));
    });
  };
};

export const AddClientToAllClientNames = (client) => {
  return (dispatch, getState) => {
    const clientNames = Object.assign(
      [],
      getState().hubly.data.hub.clients.allClientNames
    );
    clientNames.push({
      email: client.email,
      firstName: client.firstName,
      id: client.id,
      lastName: client.lastName,
      name: client.name,
      middleName: client.middleName,
      nickname: client.nickname,
    });

    dispatch(SetAllClientNames(clientNames));
  };
};

export const GetClientById = (activeClients, id) => {
  return activeClients[id];
};

export const RemoveCommentFromClient = (comment) => {
  return (dispatch, getState) => {
    const clientCopy = {
      ...getState().hubly.data.hub.clients.activeClients[comment.clientId],
    };
    clientCopy.comments = clientCopy.comments.filter((c) => {
      return c.id !== comment.id;
    });
    dispatch(SetActiveClient(clientCopy));
  };
};

export const EditClientComment = (comment) => {
  return (dispatch, getState) => {
    const clientCopy = {
      ...getState().hubly.data.hub.clients.activeClients[comment.clientId],
    };
    for (let i = 0; i < clientCopy.comments.length; i += 1) {
      if (clientCopy.comments[i].id === comment.id) {
        clientCopy.comments[i] = comment;
      }
    }
    dispatch(SetActiveClient(clientCopy));
  };
};

export const HideClientComment = (comment) => {
  return (dispatch, getState) => {
    const clientCopy = {
      ...getState().hubly.data.hub.clients.activeClients[comment.clientId],
    };
    for (let i = 0; i < clientCopy.comments.length; i += 1) {
      if (clientCopy.comments[i].id === comment.id) {
        clientCopy.comments[i] = comment;
      }
    }
    dispatch(SetActiveClient(clientCopy));
  };
};
