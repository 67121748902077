import React from "react";
import PropTypes from "prop-types";

import { Header } from "semantic-ui-react";

export function Layout({
  onClick,
  borderColor,
  menuSlot,
  itemSlot,
  iconSlot,
  bottomRightSlot,
  progressSlot,
  infoSlot,
  title,
  name,
  ...props
}) {
  return (
    <div
      {...props}
      className="ui segment"
      style={{
        display: "flex",
        padding: 0,
        margin: "0.8em 0",
        borderColor: borderColor,
      }}
    >
      <div
        style={{
          fontSize: "12pt",
          position: "absolute",
          right: "0.25em",
          top: ".5em",
        }}
      >
        {menuSlot}
      </div>
      <div
        onClick={onClick}
        className="ui mini_card"
        style={{
          flex: "1 1 auto",
          wordBreak: "break-word",
          display: "flex",
          flexFlow: "column",
          padding: "0.75em",
        }}
      >
        <div>
          <div data-test="name-container-clientTile">
            <div style={{ marginBottom: "6px" }}>{infoSlot}</div>
            <div style={{ display: "flex" }}>{name}</div>
          </div>
          <div>
            {title && (
              <Header
                as="h5"
                style={{
                  marginTop: "1px",
                  fontWeight: "300",
                  fontSize: "11pt",
                }}
              >
                {title}
              </Header>
            )}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            marginTop: "5px",
            flexDirection: "column",
          }}
        >
          {progressSlot}
          {itemSlot && <React.Fragment>{itemSlot}</React.Fragment>}
        </div>
        <div style={{ display: "flex", marginTop: "0.5em" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            {iconSlot}
          </div>
          <div style={{ marginLeft: "auto" }}>{bottomRightSlot}</div>
        </div>
      </div>
    </div>
  );
}

Layout.propTypes = {
  onClick: PropTypes.func.isRequired,
  borderColor: PropTypes.string,
  progressSlot: PropTypes.node,
  menuSlot: PropTypes.node,
  itemSlot: PropTypes.node,
  iconSlot: PropTypes.node,
  bottomRightSlot: PropTypes.node,
  infoSlot: PropTypes.node,
  title: PropTypes.string,
  name: PropTypes.node,
};

Layout.defaultProps = {
  borderColor: undefined,
  progressSlot: null,
  menuSlot: null,
  itemSlot: null,
  iconSlot: null,
  bottomRightSlot: null,
  infoSlot: null,
  title: undefined,
  name: undefined,
};

export default Layout;
