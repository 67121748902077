import React from "react";
import PropTypes from "prop-types";

import { Segment } from "semantic-ui-react";

export function TextualTile({ text }) {
  return (
    <Segment
      basic
      textAlign="center"
      style={{
        border: "2px dashed #D3D3D3",
        marginTop: "2em",
        color: "#959799",
      }}
    >
      {text}
    </Segment>
  );
}

TextualTile.propTypes = {
  text: PropTypes.string,
};

TextualTile.defaultProps = { text: "Empty" };

export default TextualTile;
