import React from "react";
import PropTypes from "prop-types";

import { Header } from "semantic-ui-react";

export function ConditionLayout({
  isCreating,
  conditionSelector,
  streamTagSelector,
  monthsCheckbox,
  showMonthsSelector,
  monthsSelector,
}) {
  return (
    <React.Fragment>
      <div style={{ display: "flex" }}>
        <Header as="h4" style={{ marginBottom: "1em" }}>
          {!isCreating ? "Edit Condition" : "Add Condition"}
        </Header>
      </div>
      <div
        style={{ display: "flex", flexDirection: "column", minWidth: "410px" }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "1em",
            }}
          >
            <div>Assign to clients with</div>
            {conditionSelector}
            <div style={{ marginRight: "1em" }}>of the following:</div>
          </div>
          {streamTagSelector}
        </div>
        <div>{monthsCheckbox}</div>
        {showMonthsSelector && <div>{monthsSelector}</div>}
      </div>
    </React.Fragment>
  );
}

ConditionLayout.propTypes = {
  conditionSelector: PropTypes.node,
  streamTagSelector: PropTypes.node,
  monthsCheckbox: PropTypes.node,
  showMonthsSelector: PropTypes.bool,
  monthsSelector: PropTypes.node,
  isCreating: PropTypes.bool,
};

ConditionLayout.defaultProps = {
  isCreating: true,
  showMonthsSelector: false,
  monthsSelector: null,
  conditionSelector: null,
  streamTagSelector: null,
  monthsCheckbox: null,
};

export default ConditionLayout;
