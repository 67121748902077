import { API } from "aws-amplify";

import { getAPIReq } from "./authLib";

export const AddEvent = async (request) => {
  const req = await getAPIReq(request);
  return API.post("HublyAPI", `/events/`, req);
};

export const EditEvent = async (id, request) => {
  const req = await getAPIReq(request);
  return API.put("HublyAPI", `/events/${id}/`, req);
};

export const DeleteEvent = async (id) => {
  const req = await getAPIReq();
  return API.del("HublyAPI", `/events/${id}/`, req);
};
