import React from "react";
import PropTypes from "prop-types";

import { Icon } from "semantic-ui-react";

import Popper from "components/Popper";

export function InfoBullet({ content, icon, ...iconProps }) {
  return (
    <Popper
      content={content}
      position="right center"
      wide
      trigger={<Icon name={icon} {...iconProps} />}
    />
  );
}

InfoBullet.propTypes = {
  content: PropTypes.string.isRequired,
  icon: PropTypes.string,
};

InfoBullet.defaultProps = {
  icon: "info circle",
};

export default InfoBullet;
