import { createRef } from "react";

import { createSelector } from "reselect";

export const getTasks = (state, props) => {
  if (!props?.workflowId) return [];

  const { workflowId } = props;
  const { clientWorkflows = {} } = state?.hubly?.data?.hub;
  const { tasks = [] } = clientWorkflows[workflowId];

  return tasks;
};

export const makeGetTaskRefs = () => {
  return createSelector([getTasks], (tasks) => {
    return (tasks || []).reduce((acc, task) => {
      if (task.id) acc[task.id] = createRef();
      return acc;
    }, {});
  });
};

export default makeGetTaskRefs;
