import React from "react";
import PropTypes from "prop-types";

import { Button, Popup } from "semantic-ui-react";

import { SendEmailToService } from "./actions";

export function SendEmail({ to, subject, body, disabled }) {
  return (
    <Popup
      flowing
      on="click"
      position="bottom center"
      style={{ padding: "0" }}
      trigger={
        <Button primary floated="right" disabled={disabled}>
          Send Email
        </Button>
      }
    >
      <Button.Group basic vertical>
        <Button
          content="Gmail"
          href={SendEmailToService(to, subject, body, "gmail")}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textAlign: "left", paddingLeft: "0.5em", width: "10em" }}
        />
        <Button
          content="Outlook.com"
          href={SendEmailToService(to, subject, body, "outlook")}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textAlign: "left", paddingLeft: "0.5em", width: "10em" }}
        />
        <Button
          content="Yahoo"
          href={SendEmailToService(to, subject, body, "yahoo")}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textAlign: "left", paddingLeft: "0.5em", width: "10em" }}
        />
        <Button
          content="Local Client"
          href={SendEmailToService(to, subject, body, "local")}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textAlign: "left", paddingLeft: "0.5em", width: "10em" }}
        />
      </Button.Group>
    </Popup>
  );
}

SendEmail.defaultProps = {
  disabled: false,
};

SendEmail.propTypes = {
  to: PropTypes.string.isRequired,
  subject: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};
export default SendEmail;
