import React from "react";
import PropTypes from "prop-types";

export function Layout({ children, ...props }) {
  return (
    <div
      style={{ maxHeight: "300px", marginTop: "0.5em", overflowY: "auto" }}
      {...props}
    >
      {children}
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node,
};

Layout.defaultProps = {
  children: null,
};

export default Layout;
