import React from "react";
import PropTypes from "prop-types";

import { Icon, Popup } from "semantic-ui-react";

export function Layout({ ruleClientDropdown, dateSelector }) {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ marginRight: "0.5em", width: "120px" }}>
          Add clients with
        </div>
        {ruleClientDropdown}
        <div style={{ marginLeft: "0.5em" }}>
          <Popup
            on="hover"
            position="right center"
            trigger={<Icon color="grey" name="question circle" />}
          >
            Clients will be added that have ALL of the selected streams/tags.
          </Popup>
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            marginTop: "1em",
            alignItems: "center",
          }}
        >
          {dateSelector}
        </div>
      </div>
    </div>
  );
}

Layout.propTypes = {
  ruleClientDropdown: PropTypes.node,
  dateSelector: PropTypes.node,
};

Layout.defaultProps = {
  ruleClientDropdown: null,
  dateSelector: null,
};

export default Layout;
