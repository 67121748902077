import React from "react";
import PropTypes from "prop-types";

import { Checkbox } from "semantic-ui-react";

export function ToggleItem({ title, value, onChange }) {
  return (
    <div data-test={`toggle-item-${title}`} style={{ display: "block" }}>
      <div style={{ height: "50px", display: "flex", alignItems: "center" }}>
        <div>{title}</div>
        <div style={{ marginLeft: "auto" }}>
          <span>
            <Checkbox
              toggle
              checked={value}
              onChange={() => {
                onChange(!value);
              }}
            />
          </span>
        </div>
      </div>
    </div>
  );
}

ToggleItem.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ToggleItem;
