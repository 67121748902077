import React from "react";
import PropTypes from "prop-types";

function PermissionText({ roleTitle }) {
  return (
    <div style={{ marginTop: "1em", color: "grey" }}>
      <p style={{ marginBottom: "5px", fontWeight: "bold" }}>
        {roleTitle === "Administrator" ? "Admins can:" : "Users cannot:"}
      </p>
      <ul
        style={{
          margin: 0,
          paddingLeft: "20px",
        }}
      >
        <li>Manage users &#38; admin permissions</li>
        <li>Edit all Workflow settings</li>
        <li>Manage User Roles &#38; Service Teams</li>
        <li>Manage Important Client Dates</li>
        <li>Remove clients from Workflows</li>
        <li>Create new Meeting Date types</li>
        <li>Manage Hub settings</li>
        <li>Edit or remove a Process or View</li>
      </ul>
    </div>
  );
}

PermissionText.propTypes = {
  roleTitle: PropTypes.string,
};

PermissionText.defaultProps = {
  roleTitle: "",
};

export default PermissionText;
