import React from "react";

import { Divider } from "semantic-ui-react";

import { NotificationSettings, PrivacyPolicy } from "./components";

export function PersonalSettings() {
  return (
    <React.Fragment>
      <NotificationSettings />
      <Divider style={{ marginTop: "2em", marginBottom: "1em" }} />
      <PrivacyPolicy />
    </React.Fragment>
  );
}

export default PersonalSettings;
