import React from "react";
import PropTypes from "prop-types";

import { useBooleanState } from "hooks";

import { useServiceTeams } from "./hooks";
import { ServiceTeamItem, UserSelector } from "./components";

export function TeamAssigner({ currentTeam, disabled, onAssign }) {
  const [isOpen, open, close] = useBooleanState();
  const [submitting, submit, clearSubmit] = useBooleanState();
  const [loading, data] = useServiceTeams(isOpen);

  return (
    <UserSelector
      title="Edit a Service Team"
      placeholder="Select a Service Team"
      position="bottom left"
      data-test="assign-team-popup"
      open={isOpen && !loading}
      includeUnassignedOption={false}
      onClose={close}
      onOpen={disabled ? () => {} : open}
      loading={submitting}
      initSelectedUserId={currentTeam?.id}
      users={data.map((d) => {
        return { ...d, group: true };
      })}
      onSelect={(assigneeTeam) => {
        submit();
        onAssign(assigneeTeam.id).then(close).finally(clearSubmit);
      }}
    >
      <ServiceTeamItem
        data-test="assign-team-button"
        clickable={!disabled}
        title={currentTeam?.name}
        text={currentTeam?.name}
        icon={loading ? "spinner" : "users"}
      />
    </UserSelector>
  );
}

TeamAssigner.propTypes = {
  onAssign: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  currentTeam: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
};

TeamAssigner.defaultProps = {
  currentTeam: undefined,
  disabled: false,
};

export default TeamAssigner;
