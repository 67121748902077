import React from "react";
import PropTypes from "prop-types";

import { ConfirmationDropdown, Header, useAssigneeOptions } from "./components";

export function UserSelector({
  title,
  placeholder,
  loading,
  users,
  forceEnabled,
  initSelectedUserId,
  onSelect,
  includeUnassignedOption,
}) {
  const assigneeOptions = useAssigneeOptions(users);
  const options = includeUnassignedOption
    ? assigneeOptions.concat({
        key: "unassigned",
        value: "unassigned",
        text: "Unassigned",
        icon: "user circle outline",
      })
    : assigneeOptions;

  return (
    <div style={{ width: "max-content" }}>
      <div style={{ display: "flex" }}>
        <Header as="h4" style={{ marginBottom: "1em" }}>
          {title}
        </Header>
      </div>
      <div>
        <ConfirmationDropdown
          data-dd-action-name={title}
          placeholder={placeholder}
          loading={loading}
          forceEnabled={forceEnabled}
          initValue={
            options.find(({ key }) => {
              return key === initSelectedUserId;
            })?.key || "unassigned"
          }
          onSelect={(selectedKey) => {
            onSelect(
              users.find(({ id }) => {
                return id === selectedKey;
              }) || { id: "unassigned" }
            );
          }}
          items={options}
        />
      </div>
    </div>
  );
}

UserSelector.propTypes = {
  title: PropTypes.string,
  placeholder: PropTypes.string,
  loading: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  initSelectedUserId: PropTypes.string,
  forceEnabled: PropTypes.bool,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      group: PropTypes.bool,
    })
  ).isRequired,
  includeUnassignedOption: PropTypes.bool,
};

UserSelector.defaultProps = {
  title: "Edit Team Member",
  placeholder: "Select a Team Member",
  initSelectedUserId: "unassigned",
  forceEnabled: false,
  loading: false,
  includeUnassignedOption: true,
};

export default UserSelector;
