import React from "react";
import DatePicker from "react-datepicker/es";
import PropTypes from "prop-types";

import Moment from "moment";
import { Icon } from "semantic-ui-react";

import { Snoozer } from "./components";

export function Calendar({ onSelect, value }) {
  const handleChange = (type) => {
    return (date) => {
      if (date.toString() !== value.toString()) {
        onSelect(date.toISOString(), type);
      }
    };
  };

  const isOverdue = () => {
    return new Date() > new Date(value);
  };

  return (
    <React.Fragment>
      <span
        data-test="scheduler-calendar"
        style={{
          color: isOverdue() ? "#db2828" : "grey",
          fontSize: "9pt",
          marginBottom: "0.5em",
          display: "block",
        }}
      >
        <Icon name="bell outline" color={isOverdue() ? "red" : "grey"} />
        {Moment(value).format("dddd MMMM Do h:mm a")}
      </span>
      <div style={{ marginBottom: "2em" }}>
        {Moment().hours() < 15 && (
          <Snoozer
            onClick={handleChange("later-today")}
            dateValue={Moment()
              .set("hour", 15)
              .set("minute", 0)
              .set("second", 0)}
          >
            Later Today
          </Snoozer>
        )}
        <Snoozer
          onClick={handleChange("tomorrow")}
          dateValue={Moment()
            .add(1, "d")
            .set("hour", 9)
            .set("minute", 0)
            .set("second", 0)}
        >
          Tomorrow
        </Snoozer>
        <Snoozer
          onClick={handleChange("next-week")}
          dateValue={Moment()
            .startOf("isoWeek")
            .add(7, "d")
            .set("hour", 9)
            .set("minute", 0)
            .set("second", 0)}
        >
          Next Week
        </Snoozer>
      </div>
      <DatePicker
        selected={new Date(value)}
        onChange={handleChange("date-picker")}
        showTimeSelect
        dateFormat="Pp"
        inline
        fixedHeight
      />
    </React.Fragment>
  );
}

Calendar.propTypes = {
  onSelect: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default Calendar;
