import React from "react";
import PropTypes from "prop-types";

import config from "config";

import advisorAsLDUser from "launchDarkly";
import { LDProvider } from "launchdarkly-react-client-sdk";

function FeatureFlagProvider({ children, advisor }) {
  return (
    <LDProvider
      clientSideID={config.launchDarkly?.clientSideID}
      user={advisor ? advisorAsLDUser(advisor) : undefined}
      reactOptions={{
        useCamelCaseFlagKeys: false,
      }}
    >
      {children}
    </LDProvider>
  );
}

FeatureFlagProvider.propTypes = {
  advisor: PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    hubs: PropTypes.arrayOf(
      PropTypes.shape({
        hubId: PropTypes.string,
      })
    ),
  }),
  children: PropTypes.node.isRequired,
};

FeatureFlagProvider.defaultProps = {
  advisor: undefined,
};

export default FeatureFlagProvider;
