import React from "react";
import PropTypes from "prop-types";

export function withStyle(Component, className) {
  const WithStyle = ({ style, ...props }) => {
    React.useEffect(() => {
      document.getElementsByClassName(className).forEach((e) => {
        Object.entries(style || {}).forEach(([p, s]) => {
          e.style[p] = s;
        });
      });
    }, [style]);

    return <Component {...props} />;
  };

  WithStyle.propTypes = {
    style: PropTypes.object,
  };

  WithStyle.defaultProps = {
    style: {},
  };

  return WithStyle;
}

export default withStyle;
