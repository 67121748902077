import { API } from "aws-amplify";

import { getAPIReq } from "data/libs/authLib";

const toServiceTeamMemberInput = ({ id, members }) => {
  return {
    advisorIds: members.map(({ id: advId }) => {
      return advId;
    }),
    serviceRoleId: id,
  };
};

export async function addServiceTeam({ hubId, name, teamMembers }) {
  const req = await getAPIReq(
    {
      query: `mutation addServiceTeam($name: String!, $members: [ServiceTeamMemberInput]!) {
          addServiceTeam(name: $name, members: $members) {
            serviceTeam {
              id
            }
          }
        }`,
      variables: {
        name: name,
        members: teamMembers.map(toServiceTeamMemberInput),
      },
    },
    { hub: hubId }
  );
  const {
    data: { addServiceTeam: serviceTeam },
    errors,
  } = await API.post("HublyAPI", `/graphql`, req);

  if (errors?.length > 0) throw errors;

  return serviceTeam;
}

export async function editServiceTeam({
  hubId,
  id,
  name,
  teamRoleIds,
  teamMembers,
}) {
  const req = await getAPIReq(
    {
      query: `mutation editServiceTeam($id: ID!, $name: String, $serviceTeamRoleIds:[UUID], $editedMembers: [ServiceTeamMemberInput]!) {
                editServiceTeam(id: $id, name: $name, serviceTeamRoleIds: $serviceTeamRoleIds, editedMembers: $editedMembers) {
                  serviceTeam {
                    id
                  }
                }
              }`,
      variables: {
        id: id,
        serviceTeamRoleIds: teamRoleIds,
        name: name,
        editedMembers: teamMembers.map(toServiceTeamMemberInput),
      },
    },
    { hub: hubId }
  );
  const {
    data: { editServiceTeam: serviceTeam },
    errors,
  } = await API.post("HublyAPI", `/graphql`, req);

  if (errors?.length > 0) throw errors;

  return serviceTeam;
}

export async function mergeServiceTeam({
  hubId,
  sourceId,
  sourceTeamRoleIds,
  targetId,
  teamMembers,
}) {
  const req = await getAPIReq(
    {
      query: `mutation mergeServiceTeam($sourceId: ID!, $targetId: ID!, $teamRoleIds:[UUID], $editedMembers: [ServiceTeamMemberInput]!) {
                mergeServiceTeam(sourceId: $sourceId, targetId: $targetId, sourceTeamRoleIds: $teamRoleIds, editedMembers: $editedMembers) {
                  serviceTeam {
                    id
                  }
                }
            }`,
      variables: {
        sourceId: sourceId,
        teamRoleIds: sourceTeamRoleIds,
        targetId: targetId,
        editedMembers: teamMembers.map(toServiceTeamMemberInput),
      },
    },
    { hub: hubId }
  );
  const {
    data: { mergeServiceTeam: serviceTeam },
    errors,
  } = await API.post("HublyAPI", `/graphql`, req);

  if (errors?.length > 0) throw errors;

  return serviceTeam;
}

export async function findServiceTeam({
  hubId,
  serviceTeamName,
  serviceTeamId,
  serviceTeamRoleIds,
  teamMembers,
}) {
  const req = await getAPIReq(
    {
      query: `query findServiceTeam($members: [ServiceTeamMemberInput], $serviceTeamId: ID, $name: String, $serviceTeamRoleIds: [UUID]) {
        findServiceTeam(members: $members, serviceTeamId: $serviceTeamId, serviceTeamName: $name, serviceTeamRoleIds: $serviceTeamRoleIds) {
          id
          numActiveClients
          name
        }
      }`,
      variables: {
        serviceTeamId: serviceTeamId,
        serviceTeamRoleIds: serviceTeamRoleIds,
        members: teamMembers
          ? teamMembers.map(toServiceTeamMemberInput)
          : undefined,
        name: serviceTeamName,
      },
    },
    { hub: hubId }
  );
  const {
    data: { findServiceTeam: serviceTeam },
    errors,
  } = await API.post("HublyAPI", `/graphql`, req);

  if (errors?.length > 0) throw errors;

  return serviceTeam;
}

export default addServiceTeam;
