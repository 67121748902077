import React from "react";
import PropTypes from "prop-types";

import { Header, Icon, Popup } from "semantic-ui-react";

export function FilterLayout({ title, onClearFilter, children }) {
  return (
    <React.Fragment>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div>
          <Header as="h4" style={{ display: "inline", marginRight: "0.5em" }}>
            {title}
          </Header>
        </div>
        <div style={{ marginRight: "0.5em" }} />
        <div />
      </div>
      <div
        style={{
          padding: "1px 1em",
          height: "max-content",
        }}
      >
        <div
          style={{
            width: "400px",
            maxHeight: "400px",
          }}
        >
          {children}
        </div>
      </div>
      <div>
        <Popup
          content="Clear"
          on="hover"
          position="top center"
          trigger={
            <Icon
              data-test={`filter-menu-${String(title)
                .toLowerCase()
                .replaceAll(" ", "-")}-clear`}
              name="delete"
              onClick={onClearFilter}
              link
              color="grey"
            />
          }
        />
      </div>
    </React.Fragment>
  );
}

FilterLayout.propTypes = {
  title: PropTypes.string.isRequired,
  onClearFilter: PropTypes.func,
  children: PropTypes.node,
};

FilterLayout.defaultProps = {
  onClearFilter: () => {},
  children: undefined,
};

export default FilterLayout;
