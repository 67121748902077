import { useCallback } from "react";

import { useQuery } from "hooks";

function useGetAssignmentStrategies() {
  const queryFactory = useCallback(() => {
    const query = `query getAssignmentStrategies {
              assignmentStrategies {
                key
                shortDesc
                description
              }
              hub {
                uuid
                assignmentStrategy {
                  key
                  description
                }
              }
              me {
                isAdmin
              }
          }
          `;

    const resolver = ({ data }) => {
      return data;
    };

    return { query, resolver };
  }, []);

  return useQuery(queryFactory);
}

export default useGetAssignmentStrategies;
