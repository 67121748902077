import { useCallback, useContext } from "react";

import useMutation from "hooks/useMutation";

import { ToasterContext } from "scenes/Hubly/components/Workspace/Provider";

export function useDeleteServiceTeamAction(onDone = () => {}) {
  const { fireSuccess, fireError } = useContext(ToasterContext);

  const mutationFactory = useCallback(() => {
    const query = `mutation deleteServiceTeam($serviceTeamId: ID!) {
    deleteServiceTeam(serviceTeamId: $serviceTeamId) {
      result
    }
  }
  `;

    const resolver = (resp) => {
      return resp;
    };

    return { query, resolver };
  }, []);

  const mutate = useMutation(mutationFactory);

  return (serviceTeamId, serviceTeamName) => {
    return mutate({ serviceTeamId })
      .then(() => {
        fireSuccess(`${serviceTeamName} successfully deleted.`);
        onDone();
      })
      .catch((error) => {
        fireError(
          error[0]?.message
            ? error[0]?.message
            : "Failed to delete Service Team.",
          error
        );
      });
  };
}

export default useDeleteServiceTeamAction;
