import React, { useState } from "react";
import PropTypes from "prop-types";

import { Icon } from "semantic-ui-react";

import PopupMenu from "components/PopupMenu";

const PureExistingTaskReminder = ({
  taskTitle,
  overdueInfo,
  openReminderRescheduleModal,
  dismissReminder,
}) => {
  const [taskCompletePopupOpen, setTaskCompletePopupOpen] = useState(false);

  return (
    <PopupMenu
      data-test="edit-task-popup"
      open={taskCompletePopupOpen}
      onOpen={() => {
        return setTaskCompletePopupOpen(true);
      }}
      onClose={() => {
        return setTaskCompletePopupOpen(false);
      }}
      buttons={[
        {
          content: "Reschedule Reminder",
          icon: "clock",
          onClick: () => {
            openReminderRescheduleModal(() => {
              return setTaskCompletePopupOpen(false);
            });
          },
        },
        {
          content: "Dismiss Reminder",
          icon: "delete",
          onClick: () => {
            dismissReminder(() => {
              return setTaskCompletePopupOpen(false);
            });
          },
        },
      ]}
      trigger={
        <Icon
          color={overdueInfo.iconColor}
          link
          name="bell"
          onClick={() => {
            setTaskCompletePopupOpen(true);
          }}
          title="This task has a reminder saved"
          data-test={`task-reminder-trigger-${taskTitle}`}
        />
      }
      popperModifiers={{
        preventOverflow: { boundariesElement: "offsetParent" },
      }}
      style={{ padding: 0 }}
    />
  );
};

PureExistingTaskReminder.propTypes = {
  taskTitle: PropTypes.string.isRequired,
  overdueInfo: PropTypes.object.isRequired,
  openReminderRescheduleModal: PropTypes.func.isRequired,
  dismissReminder: PropTypes.func.isRequired,
};

export default PureExistingTaskReminder;
