import React, { createRef } from "react";
import { useRouteMatch } from "react-router";
import PropTypes from "prop-types";

import { Menu, Ref, Sticky } from "semantic-ui-react";

import "./settings.css";

import history from "data/history";
import useHub from "data/hub/useHub";

import { FeatureFlag } from "components/FeatureFlag";

import AccountSettings from "./components/AccountSettings";
import HubSettings from "./components/HubSettings";
import PersonalSettings from "./components/PersonalSettings";
import ServiceTeamSettings from "./components/ServiceTeamSettings";

function SettingsPages({ onChange, page }) {
  const contextRef = createRef();

  const pages = [
    { name: "hub", title: "Hub Settings" },
    { name: "personal", title: "Personal Settings" },
    { name: "account", title: "Account Settings" },
    {
      name: "service-team",
      title: "Service Teams",
      featureFlag: "service-team-settings",
    },
  ];

  return (
    <div className="settings-container">
      <Ref innerRef={contextRef}>
        <div style={{ display: "flex", marginTop: "2em" }}>
          <div style={{ width: "300px" }}>
            <Sticky context={contextRef} offset={75}>
              <Menu
                floated="right"
                secondary
                size="large"
                vertical
                data-test="settings-menu"
              >
                {pages.map(({ name, title, featureFlag }) => {
                  return (
                    <FeatureFlag
                      key={name}
                      id={featureFlag || "no-flag"}
                      enableOnMissing
                    >
                      <Menu.Item
                        style={{ textAlign: "right" }}
                        active={page === name}
                        onClick={() => {
                          onChange(name);
                        }}
                      >
                        <span
                          className={
                            page === name
                              ? "active-header-font"
                              : "inactive-header-font"
                          }
                        >
                          {title}
                        </span>
                      </Menu.Item>
                    </FeatureFlag>
                  );
                })}
              </Menu>
            </Sticky>
          </div>
          {page !== "service-team" ? (
            <div style={{ marginLeft: "3em", maxWidth: "600px", width: "60%" }}>
              {page === "account" && <AccountSettings />}
              {page === "hub" && <HubSettings />}
              {page === "personal" && <PersonalSettings />}
            </div>
          ) : (
            <div style={{ marginLeft: "3em", width: "calc(95vw - 350px)" }}>
              <ServiceTeamSettings />
            </div>
          )}
        </div>
      </Ref>
    </div>
  );
}

SettingsPages.propTypes = {
  page: PropTypes.oneOf(["account", "hub", "personal", "service-team"]),
  onChange: PropTypes.func.isRequired,
};

SettingsPages.defaultProps = {
  page: "hub",
};

function Settings() {
  const { hubId } = useHub();
  const { url, params } = useRouteMatch();

  const goTo = (location) => {
    const path = `/hub/${hubId}/settings/${location}`;
    if (path !== url) history.push(path);
  };

  if (!params?.settingsPage) goTo("hub");

  return <SettingsPages onChange={goTo} page={params.settingsPage} />;
}

export default Settings;
