import SyncedAPI from "data/libs/syncedAPI";

import { getAPIReq } from "./authLib";

export const AddTaskCondition = async (taskId, request) => {
  const req = await getAPIReq(request);
  return SyncedAPI.post("HublyAPI", `/tasks/${taskId}/task_conditions/`, req);
};

export const EditTaskCondition = async (taskId, id, request) => {
  const req = await getAPIReq(request);
  return SyncedAPI.put(
    "HublyAPI",
    `/tasks/${taskId}/task_conditions/${id}/`,
    req
  );
};

export const DeleteTaskCondition = async (taskId, id) => {
  const req = await getAPIReq();
  return SyncedAPI.del(
    "HublyAPI",
    `/tasks/${taskId}/task_conditions/${id}/`,
    req
  );
};

export const allMonths = {
  January: 0,
  February: 1,
  March: 2,
  April: 3,
  May: 4,
  June: 5,
  July: 6,
  August: 7,
  September: 8,
  October: 9,
  November: 10,
  December: 11,
};

// converts a string of the format 110010000000 to ['January', 'February', 'June']
export const stringToMonths = (string) => {
  if (!string) return [];
  if (string.length !== 12) {
    console.error("Error expected string of length 12 ");
    return [];
  }
  const months = [];
  const monthNames = Object.keys(allMonths);
  for (let i = 0; i < 12; i += 1) {
    const char = string[i];
    if (char === "1") {
      months.push(monthNames[i]);
    }
  }
  return months;
};

// converts a list of months (starting with capitol letters e.g. ['January', 'February', 'June']) to a string of the format 110010000000
export const monthsToString = (months) => {
  if (months.length === 0) return null;
  let string = "000000000000";
  months.forEach((month) => {
    const index = allMonths[month];
    string = `${string.substring(0, index)}1${string.substring(index + 1)}`;
  });
  return string;
};
