export function swap(array, source, destination) {
  const swapped = Array.from(array);
  const [removed] = swapped.splice(source, 1);
  swapped.splice(destination, 0, removed);

  return swapped;
}

export function saveIndex(key = "index") {
  return (e, i) => {
    return { ...e, [key]: i };
  };
}

export default swap;
