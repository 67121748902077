import React from "react";
import PropTypes from "prop-types";

import MarkedText from "components/MarkedText";

export function ProcessWorkflowsText({ workflows, filteredKeyword }) {
  if (workflows.length === 0) {
    return null;
  }
  return (
    <div
      data-test="process-workflow-name-text"
      style={{
        display: "inline-flex",
        flexWrap: "wrap",
        color: "rgba(0, 0, 0, 0.8)",
        fontSize: "10pt",
        marginTop: "1em",
      }}
    >
      {workflows.map((workflow, index) => {
        return (
          <div
            key={workflow.id}
            style={{
              display: "inline-flex",
              flexWrap: "wrap",
              marginRight: "0.2em",
            }}
          >
            <MarkedText text={workflow.name} keyword={filteredKeyword} />
            {index < workflows.length - 1 && ", "}
          </div>
        );
      })}
    </div>
  );
}

ProcessWorkflowsText.defaultProps = {
  filteredKeyword: "",
};

ProcessWorkflowsText.propTypes = {
  workflows: PropTypes.array.isRequired,
  filteredKeyword: PropTypes.string,
};

export default ProcessWorkflowsText;
