import React from "react";
import PropTypes from "prop-types";

import { Grid } from "semantic-ui-react";
import { formatDate } from "utils";

export function PrivacyPolicyRow({ policy, acceptedTime }) {
  const dateFormatter = formatDate("simpleDate");

  return (
    <Grid.Row style={{ padding: ".25em 0" }}>
      <Grid.Column>
        <div
          style={{
            color: "grey",
            fontWeight: "bold",
            textDecoration: "underline",
          }}
        >
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={URL.createObjectURL(
              new Blob([policy.text], { type: "text/html" })
            )}
          >
            {policy.name}
          </a>
        </div>
      </Grid.Column>
      <Grid.Column>{dateFormatter(new Date(acceptedTime))}</Grid.Column>
    </Grid.Row>
  );
}

PrivacyPolicyRow.propTypes = {
  policy: PropTypes.shape({
    name: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }).isRequired,
  acceptedTime: PropTypes.string.isRequired,
};

export default PrivacyPolicyRow;
