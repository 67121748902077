import clone from "rfdc";

const deepClone = clone();

export const initialState = {
  activeClients: {},
  allClientNames: [],
  allClientNamesLoaded: false,
  recentClientNames: [],
  recentClientNamesLoaded: false,
  clientsLoaded: false,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_ACTIVE_CLIENTS":
      return {
        ...state,
        activeClients: action.activeClients,
        clientsLoaded: true,
      };
    case "SET_ACTIVE_CLIENT": {
      const newState = { ...state };
      newState.activeClients[action.client.id] = action.client;
      return newState;
    }
    case "DELETE_ACTIVE_CLIENT": {
      const newState = { ...state };
      delete newState.activeClients[action.clientId];
      return newState;
    }
    case "SET_ALL_CLIENT_NAMES":
      return {
        ...state,
        allClientNames: action.allClientNames,
        allClientNamesLoaded: true,
      };
    case "SET_LOAD_RECENT_CLIENT_NAMES":
      return {
        ...state,
        recentClientNamesLoaded: action.recentClientNamesLoaded,
      };
    case "SET_RECENT_CLIENT_NAMES":
      return {
        ...state,
        recentClientNames: action.recentClientNames,
        recentClientNamesLoaded: true,
      };
    case "ADD_CLIENT_NAME":
      return {
        ...state,
        allClientNames: [...state.allClientNames, action.client],
      };
    case "ADD_TAG_TO_CLIENT":
      try {
        // Need to deep clone the whole state unfortunately, as the tag modal for managing tags does not trigger a state change in React,
        // as it is three levels deep (activeClients.client.tag).  Same for REMOVE_TAG_FROM_CLIENT
        const client = { ...state.activeClients[action.clientId] };
        client.tags.push(action.tagId);
        const newState = deepClone(state);
        newState.activeClients[action.clientId] = client;
        return newState;
      } catch (e) {
        console.error(e);
        return state;
      }
    case "REMOVE_TAG_FROM_CLIENT":
      try {
        const client = { ...state.activeClients[action.clientId] };
        client.tags = [...client.tags].filter((t) => {
          return t !== action.tagId;
        });
        const newState = deepClone(state);
        newState.activeClients[action.clientId] = client;
        return newState;
      } catch (e) {
        console.error(e);
        return state;
      }
    case "ADD_CLIENT_STREAM":
      try {
        const newState = { ...state };
        const client = { ...newState.activeClients[action.clientId] };
        client.streams.push(action.stream);
        newState.activeClients[action.clientId] = client;
        return newState;
      } catch (e) {
        console.error(e);
        return state;
      }
    case "REMOVE_CLIENT_STREAM":
      try {
        const newState = { ...state };
        const client = { ...newState.activeClients[action.clientId] };
        client.streams = [...client.streams].filter((s) => {
          return s.id !== action.streamId;
        });
        newState.activeClients[action.clientId] = client;
        return newState;
      } catch (e) {
        console.error(e);
        return state;
      }
    case "SET_CLIENT_HOUSEHOLD":
      try {
        const newState = { ...state };
        if (action.clientId in newState.activeClients) {
          const client = { ...newState.activeClients[action.clientId] };
          client.householdId = action.householdId;
          newState.activeClients[action.clientId] = client;
        }
        return newState;
      } catch (e) {
        console.error(e);
        return state;
      }
    case "CLEAR_CLIENT_HOUSEHOLD":
      try {
        const newState = { ...state };
        const client = { ...newState.activeClients[action.clientId] };
        client.householdId = null;
        newState.activeClients[action.clientId] = client;
        return newState;
      } catch (e) {
        console.error(e);
        return state;
      }
    case "NEW_CLIENT_UPLOAD":
      try {
        const newState = { ...state };
        const client = { ...newState.activeClients[action.clientId] };
        client.newUploadedFileCount += 1;
        newState.activeClients[action.clientId] = client;
        return newState;
      } catch (e) {
        console.error(e);
        return state;
      }
    default:
      return state;
  }
};

export default reducer;
