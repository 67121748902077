import React from "react";
import PropTypes from "prop-types";

import IncludeInfoPopup from "scenes/Hubly/components/Workflows/components/WorkflowRules/components/IncludeInfoPopup";

export function Layout({ ruleClientDropdown, ageSelector }) {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div
        style={{
          marginBottom: "1em",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div style={{ marginRight: "0.5em", width: "120px" }}>
          Add clients with
        </div>
        {ruleClientDropdown}
        <div style={{ marginLeft: "0.5em" }}>
          <IncludeInfoPopup />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        {ageSelector}
      </div>
    </div>
  );
}

Layout.propTypes = {
  ruleClientDropdown: PropTypes.node,
  ageSelector: PropTypes.node,
};

Layout.defaultProps = {
  ruleClientDropdown: null,
  ageSelector: null,
};

export default Layout;
