import React from "react";

import { Dropdown, Icon } from "semantic-ui-react";

export const renderIntegration = (name, logo, options, loading = false) => {
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: "1em",
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ width: "40px", display: "flex", alignItems: "center" }}>
          <img src={logo} alt={`${name}  logo`} height="25px" />
        </div>
        <div style={{ fontSize: "24px" }}>{name}</div>
      </div>
      {options && (
        <div>
          <Dropdown button disabled={loading} loading={loading} text="More">
            <Dropdown.Menu>
              {options.map((option) => {
                return (
                  <Dropdown.Item onClick={option.onClick} key={option.content}>
                    <span style={{ color: option.color }}>
                      {option.content}
                    </span>
                    {option.icon && (
                      <Icon
                        style={{ marginLeft: "0.5em" }}
                        color="grey"
                        name={option.icon}
                      />
                    )}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      )}
    </div>
  );
};

export default renderIntegration;
