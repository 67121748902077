export const initialState = {};

export const reducer = (state = initialState, action) => {
  if (action.tags && action.type === "SET_TAGS") {
    return action.tags;
  } else if (action.type === "SET_TAG") {
    const newState = { ...state };
    newState[action.tag.id] = action.tag;
    return newState;
  } else if (action.type === "REMOVE_TAG") {
    const newState = { ...state };
    delete newState[action.tagId];
    return newState;
  } else {
    return state;
  }
};

export default reducer;
