import React from "react";
import PropTypes from "prop-types";

import { Icon, Label } from "semantic-ui-react";

export function Circle({
  onClick,
  fontSize,
  size,
  title,
  color,
  disabled,
  children,
}) {
  const controlledColor = color === "white" ? "grey" : undefined;

  return (
    <Label
      circular
      className={disabled ? "" : "clickable"}
      onClick={
        disabled
          ? (e) => {
              e.persist();
            }
          : onClick
      }
      title={title}
      basic={color === "white"}
      color={
        disabled || color?.startsWith("#") || color === "white"
          ? controlledColor
          : color
      }
      style={{
        cursor: disabled ? "default" : undefined,
        color: color === "white" ? undefined : "white",
        backgroundColor: disabled ? "#cccccc" : color,
        wordBreak: "normal",
        display: "flex",
        fontSize: fontSize,
        height: size,
        width: size,
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        userSelect: "none",
        fontWeight: "normal",
        paddingTop: "2.9px",
      }}
    >
      {children}
    </Label>
  );
}

Circle.propTypes = {
  onClick: PropTypes.func,
  fontSize: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  title: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.node,
};

Circle.defaultProps = {
  children: null,
  disabled: false,
  title: undefined,
  color: "#767676",
  onClick: undefined,
};

Circle.Icon = (props) => {
  return <Icon data-test="circle-icon" style={{ margin: "0px" }} {...props} />;
};

export default Circle;
