import { useContext, useEffect, useState } from "react";

import { ToasterContext } from "scenes/Hubly/components/Workspace/Provider";

export function useDropdownData({
  searchText = "",
  workflowId = null,
  hasClients = null,
  manyToManyId = null,
  type = "",
  first = null,
  initialLoad = false,
  fetchFunction,
}) {
  const { fireError } = useContext(ToasterContext);

  const [loading, setLoading] = useState(false);
  const [loadData, setLoadData] = useState(initialLoad);
  const [loadMoreData, setLoadMoreData] = useState(false);
  const [pageInfo, setPageInfo] = useState({});
  const [data, setData] = useState([]);

  useEffect(() => {
    // This hook is used to trigger data loading
    let mount = true;

    if (loadData || searchText !== "") {
      setLoading(true);
      fetchFunction({
        searchText: searchText,
        workflowId: workflowId,
        hasClients: hasClients,
        iDs: [manyToManyId],
        first: first,
      })
        .then(({ data: d, pageInfo: p }) => {
          if (mount) {
            setLoadData(false);
            setData(d);
            setPageInfo(p);
            setLoading(false);
          }
        })
        .catch((error) => {
          fireError(`Failed to load ${type}.`, error);
          setLoadData(false);
          setLoading(false);
        });
    }

    return () => {
      mount = false;
    };
  }, [
    loadData,
    manyToManyId,
    workflowId,
    searchText,
    hasClients,
    type,
    fetchFunction,
    fireError,
    first,
  ]);

  useEffect(() => {
    // This hook is used to load more data
    let mount = true;

    if (loadMoreData && searchText === "") {
      setLoading(true);
      fetchFunction({
        searchText: searchText,
        workflowId: workflowId,
        hasClients: hasClients,
        iDs: [manyToManyId],
        first: first,
        after: pageInfo?.hasNextPage ? pageInfo?.endCursor : "",
      })
        .then(({ data: d, pageInfo: p }) => {
          if (mount) {
            setLoadMoreData(false);
            setData((prevState) => {
              return [...prevState, ...d];
            });
            setPageInfo(p);
            setLoading(false);
          }
        })
        .catch((error) => {
          fireError(`Failed to load more ${type}.`, error);
          setLoadMoreData(false);
          setLoading(false);
        });
    }

    return () => {
      mount = false;
    };
  }, [
    loadMoreData,
    manyToManyId,
    pageInfo,
    hasClients,
    type,
    searchText,
    workflowId,
    fetchFunction,
    fireError,
    first,
  ]);

  return {
    data: data,
    hasMoreData: pageInfo?.hasNextPage,
    loading: loading,
    loadData: () => {
      if (!loadData) setLoadData(true);
    },
    loadMoreData: () => {
      if (!loadMoreData) setLoadMoreData(true);
    },
  };
}

export default useDropdownData;
