import React from "react";
import PropTypes from "prop-types";

import { Icon } from "semantic-ui-react";

import InfiniteScroll from "components/InfiniteScroll";

import { MemberItem, MemberItemPlaceholder } from "..";

export function MembersList({
  members,
  showLoadMore,
  loading,
  type,
  onViewClick,
  onDeleteClick,
  loadMoreData,
}) {
  return (
    <div
      columns="equal"
      style={{
        maxHeight: "30vh",
        overflowY: "auto",
      }}
    >
      <InfiniteScroll
        loadingPage={loading}
        hasMorePages={showLoadMore}
        onLoadMore={loadMoreData}
        loadingElement={<MemberItemPlaceholder />}
        pullNextPageElement={
          <div style={{ color: "grey", textAlign: "center" }}>
            Scroll down to load more
            <Icon name="angle down" />
          </div>
        }
      >
        {members.map((member) => {
          return (
            <MemberItem
              key={member.id}
              member={member}
              type={type}
              onViewClick={() => {
                onViewClick(member.id);
              }}
              onDeleteClick={() => {
                onDeleteClick(member.id);
              }}
            />
          );
        })}
      </InfiniteScroll>
    </div>
  );
}

MembersList.defaultProps = {
  showLoadMore: false,
  loading: false,
  loadMoreData: false,
};

MembersList.propTypes = {
  showLoadMore: PropTypes.bool,
  loading: PropTypes.bool,
  loadMoreData: PropTypes.func,
  type: PropTypes.string.isRequired,
  members: PropTypes.array.isRequired,
  onViewClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
};

export default MembersList;
