export const initialState = {
  edit_profile_modal_open: false,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "TOGGLE_EDIT_PROFILE_MODAL":
      return {
        ...state,
        edit_profile_modal_open: !state.edit_profile_modal_open,
      };
    default:
      return state;
  }
};
export default reducer;
