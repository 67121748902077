import { useState } from "react";

const defaultModalProps = {
  icon: undefined,
  title: undefined,
  message: undefined,
  buttons: [],
};

export default function useConfirmationModal(closeModal) {
  const [props, setProps] = useState(defaultModalProps);

  const setModalProps = ({ title, message, buttons }) => {
    setProps({
      icon: "delete",
      title: title,
      message: message,
      buttons: [
        {
          text: "Cancel",
          callBack: closeModal,
        },
        ...buttons.map((button) => {
          return {
            ...button,
            callBack: () => {
              button.callBack();
              closeModal();
            },
          };
        }),
      ],
    });
  };

  return {
    setModalProps,
    props,
  };
}
