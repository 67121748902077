import { Modal as OriginalModal } from "semantic-ui-react";

import Modal from "./Modal";
import ModalHeader from "./ModalHeader";

Modal.Header = ModalHeader;
Modal.Body = OriginalModal.Content;
Modal.Footer = OriginalModal.Actions;

export default Modal;
