import { useCallback, useMemo } from "react";

import { useAnonymousQuery } from "hooks";

import toGlobalId from "scenes/Hubly/components/Workspace/api/helper";

export function useSignupEmail(advisorId) {
  const queryFactory = useCallback(() => {
    const query = `query advisorSignup ($advisorId: ID!) {
        advisorSignup(advisorId:$advisorId)
      }`;

    const resolver = ({ data: { advisorSignup }, errors }) => {
      if (errors?.length > 0) throw errors;

      return advisorSignup;
    };

    return { query: query, resolver: resolver };
  }, []);

  const {
    data: email,
    error,
    loading,
  } = useAnonymousQuery(queryFactory, {
    variables: useMemo(() => {
      return { advisorId: toGlobalId("Advisor", advisorId) };
    }, [advisorId]),
  });

  return {
    loading: loading,
    email: email,
    error: error,
    isError: error?.length > 0,
  };
}

export default useSignupEmail;
