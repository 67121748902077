import React from "react";
import FullStory from "react-fullstory";
import { hot, setConfig } from "react-hot-loader";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import {
  ConfirmSignUp,
  ForgotPassword,
  Greetings,
  Loading,
  RequireNewPassword,
  SignIn,
  SignUp,
} from "aws-amplify-react";

import "./scenes/Login/Login.css";
import "react-datepicker/dist/react-datepicker.css";
import "css/general.css";
import "css/animation.css";
import "css/segment_toggle.css";

import config from "./config";
import Alert from "./components/Alerts";
import HublySignIn from "./scenes/Login";
import { HandleAuthenticationChange } from "./scenes/Login/actions";
import HublyAuthenticator from "./scenes/Login/components/HublyAuthenticator";
import HublyConfirmSignUp from "./scenes/Login/components/HublyConfirmSignUp";
import HublyForgotPassword from "./scenes/Login/components/HublyForgotPassword";
import HublyLoading from "./scenes/Login/components/HublyLoading";
import HublyRequireNewPassword from "./scenes/Login/components/HublyRequireNewPassword";
import HublySignUp from "./scenes/Login/components/HublySignUp";
import HublySignUpLinkHandler from "./scenes/Login/components/HublySignUpLinkHandler";
import AuthenticatedApp from "./AuthenticatedApp";

setConfig({
  ignoreSFC: true, // RHL will be __completely__ disabled for SFC
  pureRender: true, // RHL will not change render method
});

const map = (message) => {
  if (/incorrect.*username.*password/i.test(message)) {
    return "Incorrect username or password";
  }
  if (/null.*failed.*with.*error/i.test(message)) {
    return "Password cannot be empty";
  }
  return message;
};

class App extends React.Component {
  static propTypes = {
    handleAuthenticationChange: PropTypes.func.isRequired,
  };

  state = {
    emailAddress: "",
  };

  setEmailAddress = (email) => {
    this.setState({ emailAddress: email });
  };

  render() {
    const { handleAuthenticationChange } = this.props;
    const { emailAddress } = this.state;
    return (
      <React.Fragment>
        <HublyAuthenticator
          errorMessage={map}
          hide={[
            ConfirmSignUp,
            ForgotPassword,
            Greetings,
            Loading,
            SignIn,
            SignUp,
            RequireNewPassword,
          ]}
          onStateChange={(authState) => {
            handleAuthenticationChange(authState);
          }}
        >
          <Alert />
          <AuthenticatedApp code="string" />
          <FullStory
            org={config?.fullStory?.orgId}
            debug={config?.fullStory?.debug}
          />
          <HublyConfirmSignUp />
          <HublyForgotPassword />
          <HublyLoading />
          <HublySignIn setEmailAddress={this.setEmailAddress} />
          <HublySignUpLinkHandler onEmailIsReady={this.setEmailAddress} />
          <HublySignUp emailAddress={emailAddress} />
          <HublyRequireNewPassword emailAddress={emailAddress} />
        </HublyAuthenticator>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleAuthenticationChange: (authState) => {
      dispatch(HandleAuthenticationChange(authState));
    },
  };
};
export default process.env.NODE_ENV === "development"
  ? withRouter(connect(mapStateToProps, mapDispatchToProps)(hot(module)(App)))
  : withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
