import React from "react";
import PropTypes from "prop-types";

import { Header } from "semantic-ui-react";

export function Tab({ visible, ...props }) {
  if (!visible) return null;

  const { title, open, icon, children, onClick, ...others } = props;

  return (
    <div
      {...others}
      className="clickable grey_on_hover"
      style={{
        marginRight: "0px",
        borderLeft: "1px solid rgba(34,36,38,.15)",
        borderRight: "2px solid rgba(34,36,38,.15)",
        borderTopRightRadius: "0px",
        borderBottomRightRadius: "0px",
        width: "40px",
        height: open ? "calc(100% + 13px)" : "calc(100% - 47px)",
        position: "fixed",
        top: open ? "0px" : "47px",
        transform: open ? "translate(-40px, 0px)" : "",
        float: !open ? "right" : "",
        backgroundColor: "white",
        right: !open ? "0px" : "",
        zIndex: 999,
        boxShadow: "-2px 0px 2px rgba(34,36,38,.15)",
      }}
      onClick={onClick}
    >
      <div style={{ margin: "auto", width: "min-content", marginTop: "1em" }}>
        {icon}
      </div>
      <div style={{ transform: "rotate(-90deg)", marginTop: "4em" }}>
        <Header as="h3" style={{ width: "max-content" }}>
          {title}
        </Header>
      </div>
      <div
        style={{
          width: "100%",
          marginTop: "1.25em",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {children}
      </div>
    </div>
  );
}

Tab.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool,
  visible: PropTypes.bool,
  children: PropTypes.node,
  icon: PropTypes.node,
  onClick: PropTypes.func.isRequired,
};

Tab.defaultProps = {
  title: "Feed",
  visible: true,
  open: false,
  icon: null,
  children: null,
};

export default Tab;
