import React from "react";
import PropTypes from "prop-types";

import { useInitials, useSize } from "./hooks";
import { Circle, EmptyCircle, HoverEffect } from "./components";

export function UserAvatar({ onClick, size, color, disabled, user }) {
  const { empty, initials, fullName } = useInitials(user);
  const { fontSize, circleSize, iconSize } = useSize(size, initials.length - 2);

  if (user?.group) {
    return (
      <HoverEffect content={user.name}>
        <Circle
          onClick={onClick}
          fontSize={fontSize}
          size={circleSize}
          color="white"
          disabled={disabled}
        >
          {initials}
        </Circle>
      </HoverEffect>
    );
  }

  if (empty) {
    return (
      <HoverEffect content="Unassigned">
        <EmptyCircle onClick={onClick} disabled={disabled} size={iconSize} />
      </HoverEffect>
    );
  }

  return (
    <HoverEffect content={fullName}>
      <Circle
        onClick={onClick}
        fontSize={fontSize}
        size={circleSize}
        color={color}
        disabled={disabled}
      >
        {initials}
      </Circle>
    </HoverEffect>
  );
}

function Container(props) {
  return (
    <div data-test="user-avatar">
      <UserAvatar {...props} />
    </div>
  );
}

UserAvatar.propTypes = {
  onClick: PropTypes.func,
  size: PropTypes.oneOf(["small", "medium", "large", "big"]),
  color: PropTypes.string,
  disabled: PropTypes.bool,
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    name: PropTypes.string,
    group: PropTypes.bool,
  }),
};

UserAvatar.defaultProps = {
  user: undefined,
  disabled: false,
  color: "#767676",
  size: "small",
  onClick: undefined,
};

export default Container;
