import React, { useEffect, useState } from "react";

import { Button, Header } from "semantic-ui-react";

import { useSync } from "data/hub/sync";

import { useBooleanState } from "hooks";
import Access, { useAccess } from "components/Access";
import Tooltip from "components/Tooltip";

import { useManageServiceTeamsTable } from "./hooks";
import TeamActionsWrapper from "./components/TeamActionsWrapper";
import ManageClientsModal from "../ManageClientsModal";
import {
  ActiveClientsWrapper,
  ManageServiceTeamModal,
  ManageUserRolesModal,
  SharedTaskDefaultSettings,
  SimpleDataTable,
} from "./components";

export default function ManageServiceTeams() {
  const [isRoleModalOpened, openRoleModal, closeRoleModal] = useBooleanState();
  const [isTeamModalOpened, openTeamModal, closeTeamModal] = useBooleanState();
  const [isClientModalOpened, openClientModal, closeClientModal] =
    useBooleanState();
  const [selectedTeam, setSelectedTeam] = useState(null);

  const {
    loading,
    headerRow,
    teams,
    serviceRoles,
    serviceTeams,
    reloadData,
    teamRoleHasMultipleMember,
  } = useManageServiceTeamsTable();

  const hasAccess = useAccess();

  const defaultSettingsTeam = serviceTeams.find((team) => {
    return team.isDefaultSettings;
  });

  const { syncInProgress, syncCompleted, resetSyncProgress } = useSync();

  useEffect(() => {
    if (syncCompleted) {
      reloadData();
      resetSyncProgress();
    }
  }, [syncCompleted, reloadData, resetSyncProgress]);

  const openManageServiceTeam = (id) => {
    setSelectedTeam(
      serviceTeams.find((team) => {
        return team.id === id;
      })
    );
    openTeamModal();
  };

  const openManageClientsModal = (id) => {
    setSelectedTeam(
      serviceTeams.find((team) => {
        return team.id === id;
      })
    );
    openClientModal();
  };

  const withActionColumns = ({
    cells,
    numActiveClients,
    totalClients,
    id,
    actions,
  }) => {
    return {
      id: id,
      cells: cells
        .concat(
          <ActiveClientsWrapper
            onClientsClicked={() => {
              if (numActiveClients === 0) {
                openManageServiceTeam(id);
                return;
              }

              openManageClientsModal(id);
            }}
            numActiveClients={numActiveClients}
            crmPermissionWarning={totalClients > 0 && numActiveClients === 0}
          />
        )
        .concat(<TeamActionsWrapper actions={actions} />),
    };
  };

  return (
    <React.Fragment>
      <div style={{ marginTop: "1em" }} data-test="service-teams">
        <Header as="h1">Service Teams</Header>
        <SimpleDataTable
          loading={loading}
          header={headerRow}
          rows={teams.map(withActionColumns)}
          emptyDataMessage="There are no service teams available."
          onRowClick={openManageServiceTeam}
          highlightFirstRowBorders
          disabled={hasAccess(["Administrator"])}
        />
        <Access required={["Administrator"]}>
          <div style={{ marginTop: "1rem" }}>
            <Button
              basic
              color="grey"
              disabled={loading || serviceRoles.length === 0}
              onClick={openTeamModal}
            >
              Add Team
            </Button>
            <Tooltip
              title="You cannot manage User Roles while a CRM sync is in progress"
              hidden={!syncInProgress}
            >
              <span>
                <Button
                  basic
                  color="grey"
                  disabled={loading || syncInProgress}
                  onClick={openRoleModal}
                >
                  Manage User Roles
                </Button>
              </span>
            </Tooltip>
          </div>
        </Access>
      </div>
      {teamRoleHasMultipleMember && <SharedTaskDefaultSettings />}
      {isTeamModalOpened && (
        <ManageServiceTeamModal
          serviceTeam={selectedTeam}
          serviceRoles={serviceRoles}
          defaultSettingsTeam={defaultSettingsTeam}
          onClose={() => {
            setSelectedTeam(null);
            reloadData();
            closeTeamModal();
          }}
        />
      )}
      <ManageUserRolesModal
        open={isRoleModalOpened}
        onClose={() => {
          reloadData();
          closeRoleModal();
        }}
      />
      {isClientModalOpened && (
        <ManageClientsModal
          serviceTeamId={selectedTeam.id}
          crmPermissionWarning={
            selectedTeam.numActiveClients < selectedTeam.totalClients
          }
          isOpen
          onClose={closeClientModal}
        />
      )}
    </React.Fragment>
  );
}
