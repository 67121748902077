import React from "react";

import config from "config";

import { useFlags } from "launchdarkly-react-client-sdk";

function isPrivacyKeywordInUrl(currentPath = "", privacyModeConfig = {}) {
  const { enableKeyword = "privacy" } = privacyModeConfig;

  return () => {
    return currentPath.includes(enableKeyword);
  };
}

function isPrivacyFeatureFlagEnabled(flags = {}) {
  return () => {
    return flags["permanent-privacy-mode"] || false;
  };
}

export function withDecider(WrappedComponent) {
  const createDecider = (predicates) => {
    return {
      isOn: () => {
        return predicates.some((p) => {
          return p();
        });
      },
    };
  };

  return (props) => {
    const [appStartedInPrivacy] = React.useState(() => {
      return isPrivacyKeywordInUrl(window.location.href, config.privacyMode);
    });

    return (
      <WrappedComponent
        privacyModeDecider={createDecider([
          appStartedInPrivacy,
          isPrivacyFeatureFlagEnabled(useFlags()),
        ])}
        {...props}
      />
    );
  };
}

export default withDecider;
