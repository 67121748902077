import React from "react";
import PropTypes from "prop-types";

import { Popover, Sidetab } from "./components";

function selectComponent(type, defaultValue) {
  switch (type?.toLowerCase()) {
    case "sidetab":
      return Sidetab;
    case "popover":
      return Popover;
    default:
      return defaultValue;
  }
}

export function FeedbackForm({ id, hidden, ...otherProps }) {
  if (!id || hidden) return null;

  const { title, type, color, iconHref, rightMargin } = otherProps;
  const FeedbackComponent = selectComponent(type, Popover);

  return (
    <FeedbackComponent
      id={id}
      style={{
        bottom: "20px",
        height: "48px",
        width: "48px",
        right: rightMargin,
      }}
      buttonColor={color}
      buttonText={title || "Leave Feedback"}
      customIcon={iconHref?.startsWith("http") ? iconHref : undefined}
    />
  );
}

FeedbackForm.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  hidden: PropTypes.bool,
  type: PropTypes.string,
  color: PropTypes.string,
  iconHref: PropTypes.string,
  rightMargin: PropTypes.string,
};

FeedbackForm.defaultProps = {
  type: "popover",
  title: "Leave Feedback",
  hidden: false,
  color: undefined,
  iconHref: undefined,
  rightMargin: undefined,
};

export default FeedbackForm;
