import React from "react";
import PropTypes from "prop-types";

import { Divider, Header, Loader } from "semantic-ui-react";

export function Layout({ title, loading, inputControl, list }) {
  return (
    <React.Fragment>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Header
          as="h3"
          content={`${title}s`}
          style={{
            paddingLeft: "1em",
            paddingTop: "0.5em",
            marginBottom: 0,
            paddingBottom: 0,
            flexGrow: 1,
          }}
        />
        <div style={{ paddingRight: "1em" }}>
          {loading && <Loader active size="tiny" inline />}
        </div>
      </div>

      <Divider style={{ marginBottom: "0" }} />
      {inputControl}
      <Divider style={{ marginBottom: "0", marginTop: "0em" }} />
      {list}
    </React.Fragment>
  );
}

Layout.propTypes = {
  title: PropTypes.string,
  loading: PropTypes.bool,
  inputControl: PropTypes.node,
  list: PropTypes.node,
};

Layout.defaultProps = {
  title: "",
  loading: false,
  inputControl: null,
  list: null,
};

export default Layout;
