import { API } from "aws-amplify";

import { getAPIReq } from "./authLib";

const EditClientPool = async (id, request) => {
  const req = await getAPIReq(request);
  return API.put("HublyAPI", `/clientPools/${id}/`, req);
};

export default EditClientPool;
