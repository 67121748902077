import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import history from "data/history";

import { Grid, HubCard } from "./components";

export function HubSelector({ hubs, onHubSelected }) {
  if (hubs.length === 1) {
    onHubSelected(hubs[0]);
    return null;
  }

  if (hubs.length === 0) {
    return <p style={{ textAlign: "center" }}>No Available Hub exists</p>;
  }

  return (
    <div
      data-test="hub-selector"
      style={{ height: "100vh", overflowY: "auto", paddingBottom: "4em" }}
    >
      <Grid itemsPerRow={3}>
        {hubs.map((h) => {
          return (
            <HubCard
              key={h.id}
              name={h.name}
              type={h.clientPool?.type}
              onClick={() => {
                return onHubSelected(h);
              }}
            />
          );
        })}
      </Grid>
    </div>
  );
}

HubSelector.propTypes = {
  hubs: PropTypes.array,
  onHubSelected: PropTypes.func.isRequired,
};

HubSelector.defaultProps = {
  hubs: [],
};

const mapStateToProps = (state) => {
  return {
    hubs: state.hubly.data.advisor?.hubs || [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onHubSelected: (hub) => {
      history.push(`/hub/${hub.hubId}`);
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HubSelector);
