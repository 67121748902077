import React from "react";

import { Placeholder, Segment } from "semantic-ui-react";

export function WorkflowTilePlaceholder() {
  return (
    <Segment.Group
      horizontal
      style={{ width: "100%", color: "grey", margin: "0.5em 0em" }}
    >
      <Segment style={{ width: "55%", padding: "0.75em 1em" }}>
        <div style={{ display: "flex" }}>
          <div
            className="overflow_ellipsis"
            style={{
              margin: 0,
              color: "rgba(0, 0, 0, 0.8)",
              flexGrow: 1,
            }}
          >
            <Placeholder>
              <Placeholder.Header>
                <Placeholder.Line />
              </Placeholder.Header>
            </Placeholder>
          </div>
        </div>
      </Segment>
      <Segment style={{ width: "20%", textAlign: "center", padding: "0.75em" }}>
        <Placeholder>
          <Placeholder.Header>
            <Placeholder.Line />
          </Placeholder.Header>
        </Placeholder>
      </Segment>

      <Segment style={{ width: "25%", textAlign: "center", padding: "0.75em" }}>
        <Placeholder>
          <Placeholder.Header>
            <Placeholder.Line />
          </Placeholder.Header>
        </Placeholder>
      </Segment>
    </Segment.Group>
  );
}

export default WorkflowTilePlaceholder;
