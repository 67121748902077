import React from "react";
import PropTypes from "prop-types";

import { Header } from "semantic-ui-react";

export function ReminderGroup({ collapsed, title, children }) {
  return (
    <div
      data-test="reminder-date"
      style={{
        padding: "2px",
        marginLeft: "1em",
        width: collapsed ? "50%" : "96%",
      }}
    >
      <Header as="h4" style={{ display: "inline", margin: "0.25em" }}>
        {title}
      </Header>
      <div style={{ marginLeft: "1em" }}>{children}</div>
    </div>
  );
}

ReminderGroup.propTypes = {
  collapsed: PropTypes.bool,
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
};

ReminderGroup.defaultProps = {
  children: null,
  collapsed: false,
};

export default ReminderGroup;
