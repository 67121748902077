import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Card, Icon, List, Transition } from "semantic-ui-react";

import { PrivacyModeContext } from "components/PrivacyMode/Context";

import { RemoveAlertFromQueueById } from "./actions";

export class Alerts extends React.Component {
  static contextType = PrivacyModeContext;

  static propTypes = {
    removeAlertFromQueueById: PropTypes.func.isRequired,
    items: PropTypes.array.isRequired,
  };

  removeOnClick = (e, data) => {
    const alertId = data.id;
    this.props.removeAlertFromQueueById(alertId);
  };

  render() {
    const { items } = this.props;
    const { piiMask } = this.context;

    if (!items) {
      return null;
    }

    return (
      <div
        data-test="alert-toast"
        style={{
          position: "fixed",
          top: "40px",
          right: "50px",
          zIndex: "2000",
        }}
      >
        <Transition.Group as={List} duration={600} animation="fade down">
          {items.map((item) => {
            const itemId = `alertList-${item.id}`;
            const cardId = `alertCard-${item.id}`;
            return (
              <List.Item key={itemId}>
                <Card
                  key={cardId}
                  id={item.id}
                  style={{ color: item.color }}
                  onClick={this.removeOnClick}
                  color={item.color}
                >
                  <Card.Content
                    data-test="alert-message"
                    className={piiMask("fs-block dd-privacy-mask")}
                    icon={item.icon}
                    textAlign="center"
                  >
                    {item.icon.length > 3 ? (
                      <Icon name={item.icon} color={item.color} size="large" />
                    ) : null}
                    {item.text}
                  </Card.Content>
                </Card>
              </List.Item>
            );
          })}
        </Transition.Group>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    items: state.hubly.alerts.items,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    removeAlertFromQueueById: (alertId) => {
      dispatch(RemoveAlertFromQueueById(alertId));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Alerts);
