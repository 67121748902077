export const initialState = {
  isAssignedAdvisorOpen: false,
  modalObjectId: "",
  objectType: "TASK",
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "TOGGLE_ASSIGNED_ADVISOR_POPUP":
      return {
        ...state,
        isAssignedAdvisorOpen: !state.isAssignedAdvisorOpen,
        modalObjectId: action.objectId,
        objectType: action.objectType,
        defaultDropdownValue: action.defaultDropdownValue,
      };
    default:
      return state;
  }
};
export default reducer;
