import config from "config";

import createMiddleware from "./handler";
import createMetricPublisher from "./metrics";

export default createMiddleware({
  publishers: config.analytics ? createMetricPublisher() : [],
  isEnabled: () => {
    return config.analytics;
  },
});
