import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Header } from "semantic-ui-react";

import NextWorkflowTile from "./NextWorkflowTile";
import WorkflowTile from "./WorkflowTile";

class CardNextWorkflows extends React.Component {
  static propTypes = {
    client: PropTypes.object.isRequired,
    clientWorkflows: PropTypes.object.isRequired,
    workflowId: PropTypes.string.isRequired,
  };

  render() {
    const { client, workflowId, clientWorkflows } = this.props;

    const cws = Object.entries(clientWorkflows)
      .filter(([cwId]) => {
        return client.workflows.includes(cwId);
      })
      .map(([_, cw]) => {
        return cw;
      });

    const unaffiliatedWorkflows = cws
      .filter(({ completed }) => {
        return completed;
      })
      .flatMap(({ nextWorkflows }) => {
        return nextWorkflows;
      })
      .filter(({ startDate }) => {
        return startDate;
      });

    const numActiveWorkflows = cws.filter(({ completed }) => {
      return !completed;
    }).length;

    return (
      <React.Fragment>
        {numActiveWorkflows > 0 && (
          <div
            data-test="client-card-workflow-chain"
            style={{ marginTop: "0.5em" }}
          >
            <Header as="h5" style={{ marginBottom: "0.5em" }}>
              Workflows
            </Header>
            {client.workflows.map((clientWorkflowId) => {
              return (
                <WorkflowTile
                  bold={clientWorkflowId === workflowId}
                  client={client}
                  clientWorkflowId={clientWorkflowId}
                />
              );
            })}
          </div>
        )}
        {unaffiliatedWorkflows.length > 0 && (
          <div style={{ marginTop: "1em" }}>
            <Header as="h5" style={{ marginBottom: "0.5em" }}>
              Scheduled Next Workflows
            </Header>
            {unaffiliatedWorkflows.map((wf) => {
              return (
                <NextWorkflowTile
                  client={client}
                  nextWorkflow={wf}
                  style={{ marginBottom: "1em" }}
                />
              );
            })}
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    clientWorkflows: state.hubly.data.hub.clientWorkflows,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CardNextWorkflows);
