import React from "react";
import PropTypes from "prop-types";

import useCurrentUser from "data/advisor/useCurrentUser";

export function Access({ userRoles, required, children, fallback }) {
  if (
    !userRoles.some((userRole) => {
      return required.includes(userRole);
    })
  ) {
    return fallback;
  }

  return children;
}

Access.propTypes = {
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
  required: PropTypes.arrayOf(PropTypes.string).isRequired,
  children: PropTypes.node,
  fallback: PropTypes.node,
};

Access.defaultProps = {
  children: null,
  fallback: null,
};

function Container(props) {
  const { role } = useCurrentUser();

  return <Access userRoles={[role.title]} {...props} />;
}

export default Container;
