import React from "react";
import PropTypes from "prop-types";

export function GettingStarted({ children }) {
  return (
    <div
      style={{
        display: "flex",
        width: "100vw",
        height: "calc(100vh - 100px)",
        top: "100px",
        minHeight: "500px",
        padding: "0 15%",
        alignItems: "center",
        left: "0px",
        justifyContent: "space-around",
      }}
      data-test="new-hub-homepage"
    >
      {children}
    </div>
  );
}

GettingStarted.propTypes = {
  children: PropTypes.node,
};

GettingStarted.defaultProps = {
  children: null,
};

export default GettingStarted;
