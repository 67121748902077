import React from "react";
import PropTypes from "prop-types";

import { ScrollContext } from "components/ClickToScroll";
import Menu from "components/Menu";

import { ReminderInfo } from "./components";

export function Reminder({
  data,
  onNavigate,
  onReschedule,
  onCompleteTask,
  onDismiss,
}) {
  const { id, title, time, task } = data;

  return (
    <ScrollContext.Consumer key={id}>
      {({ setScrollToItem }) => {
        return (
          <ReminderInfo
            title={title}
            time={time}
            onClick={
              !task
                ? () => {}
                : () => {
                    onNavigate(task.clientWorkflow.id);
                    setScrollToItem(task.id);
                  }
            }
          >
            <Menu
              data-test="reminder-item-popup"
              position="right center"
              style={{ padding: "0 !important" }}
            >
              {({ closeMenu }) => {
                return (
                  <React.Fragment>
                    <Menu.Button
                      icon="clock"
                      content="Reschedule Reminder"
                      onClick={() => {
                        onReschedule(data);
                        closeMenu();
                      }}
                    />
                    {task && (
                      <Menu.Button
                        icon="check circle"
                        content="Complete Task"
                        onClick={() => {
                          onCompleteTask(data);
                          closeMenu();
                        }}
                      />
                    )}
                    <Menu.Button
                      icon="delete"
                      content="Dismiss Reminder"
                      onClick={() => {
                        onDismiss(data);
                        closeMenu();
                      }}
                    />
                  </React.Fragment>
                );
              }}
            </Menu>
          </ReminderInfo>
        );
      }}
    </ScrollContext.Consumer>
  );
}

Reminder.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    time: PropTypes.string.isRequired,
    task: PropTypes.object,
  }).isRequired,
  onNavigate: PropTypes.func.isRequired,
  onReschedule: PropTypes.func.isRequired,
  onCompleteTask: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

export default Reminder;
