import { useContext } from "react";

import { API } from "aws-amplify";

import useHub from "data/hub/useHub";
import { getAPIReq } from "data/libs/authLib";

import toGlobalId from "scenes/Hubly/components/Workspace/api/helper";
import { ToasterContext } from "scenes/Hubly/components/Workspace/Provider";

const assignClientToServiceTeam = async (hubId, clientId, serviceTeamId) => {
  const req = await getAPIReq(
    {
      query: `mutation assignServiceTeam($clientId: ID!, $serviceTeamId: ID) {
        assignServiceTeam(clientId: $clientId, serviceTeamId: $serviceTeamId) {
          serviceTeam {
            id
          }
        }                    
      }`,
      variables: {
        clientId: toGlobalId("Client", clientId),
        serviceTeamId: serviceTeamId,
      },
    },
    { hub: hubId }
  );
  const {
    data: { assignServiceTeam: serviceTeam },
    errors,
  } = await API.post("HublyAPI", `/graphql`, req);

  if (errors?.length > 0) throw errors;

  return serviceTeam;
};

export function useAssignServiceTeamAction(clientId, onDone = () => {}) {
  const { fireSuccess, fireError } = useContext(ToasterContext);
  const hub = useHub();

  return (serviceTeamId) => {
    return assignClientToServiceTeam(hub?.hubId, clientId, serviceTeamId)
      .then(() => {
        fireSuccess("Service Team successfully updated.");
        onDone();
      })
      .catch((error) => {
        fireError(
          error[0]?.message
            ? error[0]?.message
            : "Failed to update Service Team.",
          error
        );
      });
  };
}

export default useAssignServiceTeamAction;
