import { keyBy, merge, values } from "lodash";

import Moment from "moment";
import { createSelector } from "reselect";

const getReminderFilter = (state, _) => {
  return state.hubly.data.filters.remindersVisibilityFilter;
};

const getCurrentUser = (state, _) => {
  return state.hubly.data.advisor;
};

const getAllReminders = (state) => {
  return state.hubly.data.reminders;
};
const getClientWorkflows = (state) => {
  return state.hubly.data.hub.clientWorkflows;
};

const getClientWorkflowTasks = (state, props) => {
  return state.hubly.data.hub.clientWorkflows[props?.workflowId]?.tasks || [];
};

const obtainTasksReminders = (reminders, clientWorkflows) => {
  if (!reminders || reminders.length <= 0) return [];

  const tasks = Object.values(clientWorkflows).flatMap((clientWorkflow) => {
    return Array.isArray(clientWorkflow?.tasks)
      ? [...clientWorkflow.tasks]
      : [];
  });

  return reminders.map((reminder) => {
    const task = tasks.find((t) => {
      return t.id === reminder?.taskId;
    });
    return { assignedAdvisor: { ...task?.assignedAdvisor }, ...reminder };
  });
};

export default function makeGetFilteredReminders() {
  return createSelector(
    [getReminderFilter, getAllReminders, getClientWorkflows, getCurrentUser],
    (reminderFilter, reminders, clientWorkflows, currentUser) => {
      const taskReminders = obtainTasksReminders(reminders, clientWorkflows);

      switch (reminderFilter?.type) {
        case "SHOW_ALL":
          return taskReminders;
        case "BY_USER":
          return taskReminders.filter((r) => {
            return r.assignedAdvisor.id === reminderFilter.value;
          });
        case "CURRENT_USER":
          return taskReminders.filter((r) => {
            const isUnassigned = !r.assignedAdvisor.id;
            return isUnassigned || r.assignedAdvisor.id === currentUser.id;
          });
        default:
          return reminders;
      }
    }
  );
}

export const makeGetReminders = () => {
  const activeReminder = (r) => {
    return !r.dismissed && !r.completedAt;
  };

  const selectReminders = (reminderPredicate) => {
    return (tasks) => {
      return tasks
        .filter((t) => {
          return t.reminders?.length > 0;
        })
        .flatMap(({ reminders }) => {
          return reminders.filter(reminderPredicate);
        });
    };
  };

  return createSelector(
    [getClientWorkflowTasks],
    selectReminders(activeReminder)
  );
};

export const makeGetClientReminders = (getClient) => {
  return createSelector(
    [getClient, getAllReminders],
    (client, allReminders) => {
      return allReminders.filter((reminder) => {
        return reminder.clientId === client?.id;
      });
    }
  );
};

export const makeGetTaskReminders = (getTask) => {
  return createSelector([getTask, getAllReminders], (task, allReminders) => {
    return allReminders.filter((reminder) => {
      return reminder.taskId === task?.id;
    });
  });
};

export const makeGetUpcomingClientReminders = (getClient) => {
  return createSelector(
    [getClient, getAllReminders, getClientWorkflows],
    (client, allReminders, clientWorkflows) => {
      const taskAndClientReminders = values(
        merge(keyBy(client?.reminders || [], "id"), keyBy(allReminders, "id"))
      );

      const reminders = taskAndClientReminders.filter((reminder) => {
        return reminder.clientId === client?.id;
      });
      const dates = {};
      if (reminders) {
        reminders.forEach((reminder) => {
          const date = Moment(reminder.time);
          const key = date.format("YYYYMMDD");
          let obj = { reminder };
          if (reminder.taskId) {
            const foundWorkflowId = client?.workflows.find((workflowId) => {
              const workflow = clientWorkflows[workflowId];
              if (!workflow.completed && !workflow.archived) {
                return workflow.tasks.find((task) => {
                  return task.id === reminder.taskId;
                });
              }
              return false;
            });
            if (foundWorkflowId) {
              const foundWorkflow = clientWorkflows[foundWorkflowId];
              const foundTask = foundWorkflow.tasks.find((task) => {
                return task.id === reminder.taskId;
              });
              if (!foundTask.completed && !reminder.dismissed) {
                obj = {
                  task: foundTask,
                  reminder: reminder,
                };
                if (key in dates) {
                  dates[key].push(obj);
                } else {
                  dates[key] = [obj];
                }
              }
            }
          } else if (!reminder.dismissed) {
            if (key in dates) {
              dates[key].push(obj);
            } else {
              dates[key] = [obj];
            }
          }
        });
      }
      const ordered = {};
      const dateAreas = [];
      Object.keys(dates)
        .sort()
        .forEach((key) => {
          ordered[key] = dates[key];
        });
      Object.keys(ordered).forEach((key) => {
        dateAreas.push(dates[key]);
      });
      return dateAreas;
    }
  );
};
