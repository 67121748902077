import React from "react";
import PropTypes from "prop-types";
import { omit, pick } from "lodash";

import { Popper } from "./Popper";

const PopperProps = [
  "onClose",
  "onOpen",
  "open",
  "disabled",
  "offset",
  "position",
  "data-test",
];

export function withPopper(WrappedComponent) {
  const WithPopper = ({ children, ...props }) => {
    const popperProps = pick(props, PopperProps);
    const componentProps = omit(props, PopperProps);

    return (
      <Popper trigger={children} {...popperProps}>
        <WrappedComponent {...componentProps} />
      </Popper>
    );
  };

  WithPopper.propTypes = {
    children: PropTypes.node.isRequired,
  };

  return WithPopper;
}

export default withPopper;
