import React from "react";
import PropTypes from "prop-types";

export function SuggestionsTips({ suggestions: { info, link } }) {
  return (
    <p>
      {info}{" "}
      {link && (
        <a target="_blank" rel="noopener noreferrer" href={link.href}>
          {link.content}
        </a>
      )}
    </p>
  );
}

SuggestionsTips.propTypes = {
  suggestions: PropTypes.shape({
    info: PropTypes.string.isRequired,
    link: PropTypes.shape({
      content: PropTypes.string,
      href: PropTypes.string,
    }),
  }).isRequired,
};

SuggestionsTips.defaultProps = {};

export default SuggestionsTips;
