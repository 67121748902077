import React, { useContext, useState } from "react";
import ReactQuill from "react-quill";
import PropTypes from "prop-types";

import { Form, Icon, Label, Message, Modal, TextArea } from "semantic-ui-react";
import { isEmail } from "validator";

import "react-quill/dist/quill.snow.css";
import "./styles.css";

import { useForm } from "hooks";
import AddRemoveButton from "components/AddRemoveButton";

import {
  HubContext,
  ToasterContext,
} from "scenes/Hubly/components/Workspace/Provider";

import { modules } from "../../../../helper";
import { SendEmail } from "..";

export function EmailModal({
  taskId,
  isCreating,
  emailInHubly,
  showEmailToField,
  client,
  emailTemplate,
  onClose,
  onEmailTemplateUpdated,
  disabled,
}) {
  const { addEmailTemplate, editEmailTemplate, deleteEmailTemplate } =
    useContext(HubContext);
  const { fireError } = useState(ToasterContext);
  const [loading, setLoading] = useState(false);

  const defaultEmail = client && client.email ? client.email : "";

  const { errorList, register, data, disableButton } = useForm({
    initialValue: {
      to:
        emailTemplate && emailTemplate.to !== ""
          ? emailTemplate.to
          : defaultEmail,
      subject: emailTemplate ? emailTemplate.subject : "",
      body: emailTemplate ? emailTemplate.body : "",
    },
    fieldValidator: ({ name, required, value, errortext = "" }) => {
      return (required && (value === "" || value === "<p><br></p>")) ||
        (name === "to" && showEmailToField && !isEmail(value))
        ? errortext
        : "";
    },
    formValidator: ({ to = "", body = "", subject = "" }) => {
      const emailTo = showEmailToField ? !isEmail(to) : true;
      return emailInHubly ? emailTo && body && subject : true;
    },
  });

  const bodyProps = register({
    name: "body",
    required: emailInHubly,
    errortext: "Body cannot be empty.",
  });

  const removeEmailTemplate = () => {
    if (!taskId) {
      onEmailTemplateUpdated(null);
      onClose(null);
    } else {
      setLoading(true);
      deleteEmailTemplate(emailTemplate.id)
        .then(() => {
          onEmailTemplateUpdated(null);
        })
        .catch((error) => {
          fireError("Failed to delete email template", error);
          setLoading(false);
          onClose(null);
        });
    }
  };

  const saveEmailTemplate = async () => {
    if (!taskId) {
      onEmailTemplateUpdated(data);
      onClose(null);
    } else {
      try {
        setLoading(true);
        const resp = isCreating
          ? await addEmailTemplate(taskId, data)
          : await editEmailTemplate(emailTemplate.id, data);
        onEmailTemplateUpdated(resp);
      } catch (error) {
        fireError("Failed to save email template", error);
        setLoading(false);
        onClose(null);
      }
    }
  };

  return (
    <Modal size="small" on="click" open>
      <Modal.Header as="h4">
        <Icon
          color="grey"
          link
          name="close"
          onClick={() => {
            onClose(null);
          }}
          style={{ float: "right" }}
        />
        Email Template
      </Modal.Header>
      <Modal.Content>
        <Form error>
          <Message
            error
            negative
            header="Error"
            hidden={errorList.length === 0}
            list={errorList}
          />
          {showEmailToField ? (
            <Form.Input
              {...register({
                name: "to",
                required: emailInHubly,
                errortext: "To email address is invalid",
              })}
              autoComplete="off"
              placeholder="example@email.com"
              label="To"
              disabled={disabled || loading}
            />
          ) : (
            <Form.Field>
              <label>To</label>
              <Label>Client Email</Label>
            </Form.Field>
          )}
          <Form.Input
            {...register({
              name: "subject",
              required: emailInHubly,
              errortext: "Subject cannot be empty.",
            })}
            label="Subject"
            autoComplete="off"
            placeholder="Subject"
            disabled={disabled || loading}
          />
          <Form.Field required={bodyProps.required} error={bodyProps.error}>
            <label>Body</label>
            {emailInHubly ? (
              <ReactQuill
                name="body"
                defaultValue={bodyProps.value}
                onChange={(content) => {
                  bodyProps.onChange(null, {
                    ...bodyProps,
                    value: content,
                  });
                }}
                modules={modules}
                disabled={disabled || loading}
              />
            ) : (
              <TextArea
                {...bodyProps}
                error={bodyProps.error ? bodyProps.errortext : ""}
                style={{
                  minHeight: "10em",
                }}
                disabled={disabled || loading}
              />
            )}
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <AddRemoveButton
          loading={loading}
          disabled={disabled || disableButton || loading}
          isCreating={isCreating}
          onRemove={removeEmailTemplate}
          onAdd={saveEmailTemplate}
        >
          {client?.id && (
            <SendEmail {...data} disabled={disabled || disableButton} />
          )}
        </AddRemoveButton>
      </Modal.Actions>
    </Modal>
  );
}

EmailModal.defaultProps = {
  emailInHubly: false,
  isCreating: true,
  client: null,
  emailTemplate: null,
  showEmailToField: false,
  onEmailTemplateUpdated: () => {},
  taskId: null,
  disabled: false,
};

EmailModal.propTypes = {
  taskId: PropTypes.string,
  emailInHubly: PropTypes.bool,
  isCreating: PropTypes.bool,
  showEmailToField: PropTypes.bool,
  client: PropTypes.object,
  emailTemplate: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onEmailTemplateUpdated: PropTypes.func,
  disabled: PropTypes.bool,
};

export default EmailModal;
