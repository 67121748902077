import React, { useContext, useState } from "react";
import PropTypes from "prop-types";

import { Popup } from "semantic-ui-react";

import { useDropdownData } from "hooks";

import {
  HubContext,
  ToasterContext,
} from "scenes/Hubly/components/Workspace/Provider";

import {
  InputControl,
  ManyToManyList,
  ManyToManyModal,
  PopupLayout,
  TriggerButton,
} from "../ManyToManyManager/components";

export function TagManager({ onModifyClient, onUpdate, onDelete }) {
  const { fireWarning, fireError } = useContext(ToasterContext);
  const {
    fetchHousehold,
    fetchTags,
    addTag,
    editTag,
    deleteTag,
    fetchClientsWithTagsInTag,
    addClientToTag,
    removeClientFromTag,
    viewClientCard,
  } = useContext(HubContext);

  const [showHouseholdConfirm, setShowHouseholdConfirm] = useState(true);
  const [selectedItem, setSelectedItem] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [popupOpen, setPopupOpen] = useState(false);
  const [adding, setAdding] = useState(false);

  const {
    loading,
    data: tagData,
    loadData,
    loadMoreData,
    hasMoreData,
  } = useDropdownData({
    searchText: searchText,
    type: "tag",
    fetchFunction: fetchTags,
  });

  return (
    <React.Fragment>
      <div style={{ flex: "0 1 auto", margin: "0 0.5em 0 0" }}>
        <Popup
          data-test="tag-popup"
          position="top right"
          style={{ paddingLeft: 0, paddingRight: 0 }}
          open={popupOpen}
          onClose={(e) => {
            if (e.type !== "mouseleave") {
              setPopupOpen(false);
              setSearchText("");
            }
          }}
          trigger={
            <TriggerButton
              data-test="hub-menu-tags-button"
              icon="hashtag"
              text="Tags"
              onClick={() => {
                setPopupOpen(!popupOpen);
                loadData();
              }}
            />
          }
        >
          <PopupLayout
            title="Tag"
            loading={loading || adding}
            inputControl={
              <InputControl
                placeholder="Add a tag"
                loading={adding}
                onChange={(text) => {
                  setSearchText(text);
                  if (text === "") loadData();
                }}
                onAdd={(tag) => {
                  if (tag === "") {
                    fireWarning(
                      "Input field cannot be empty. Please verify tag name and try again."
                    );
                    return;
                  }
                  setAdding(true);
                  addTag(tag, true)
                    .then(() => {
                      setSearchText("");
                      loadData();
                      setAdding(false);
                    })
                    .catch((error) => {
                      const { response: { data = {} } = {} } = error;
                      fireError(
                        data?.tag ? data.tag : "Failed to add tag",
                        error
                      );
                      setAdding(false);
                    });
                }}
              />
            }
            list={
              <ManyToManyList
                loading={loading}
                showLoadMore={hasMoreData}
                loadMoreData={loadMoreData}
                type="tag"
                items={tagData}
                onClick={(item) => {
                  setSelectedItem(item);
                  setPopupOpen(false);
                  setSearchText("");
                }}
              />
            }
          />
        </Popup>
      </div>
      {selectedItem && (
        <ManyToManyModal
          obj={selectedItem}
          type="tag"
          onClose={() => {
            setSelectedItem(null);
          }}
          onModifyClient={onModifyClient}
          updateData={(id, request) => {
            return editTag(id, request)
              .then((resp) => {
                onUpdate(resp);
                loadData();
              })
              .catch((error) => {
                const { response: { data = {} } = {} } = error;
                fireError(data?.tag ? data.tag : "Failed to update tag", error);
              });
          }}
          deleteData={(id) => {
            return deleteTag(id)
              .then(() => {
                onDelete(id);
                loadData();
              })
              .catch((error) => {
                fireError("Failed to delete tag", error);
              });
          }}
          fetchMembers={fetchClientsWithTagsInTag}
          addMember={(clientId, tag) => {
            return addClientToTag(clientId, tag.name).catch((error) => {
              fireError("Failed to add client to tag.", error);
            });
          }}
          bulkAddMember={(clientId, tag) => {
            return fetchHousehold(clientId)
              .then((household) => {
                return household.householdMembers.reduce((p, member) => {
                  return p.then(() => {
                    return addClientToTag(member.client.id, tag.name);
                  });
                }, Promise.resolve());
              })
              .catch((error) => {
                fireError("Failed to add all clients to tag.", error);
              });
          }}
          removeMember={(clientId, tagId) => {
            return removeClientFromTag(clientId, tagId).catch((error) => {
              fireError("Failed to remove client from tag.", error);
            });
          }}
          onViewClick={viewClientCard}
          showHouseholdConfirm={showHouseholdConfirm}
          onDisableHouseholdConfirm={() => {
            setShowHouseholdConfirm(false);
          }}
          exportProps={{ tag: selectedItem }}
        />
      )}
    </React.Fragment>
  );
}

TagManager.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onModifyClient: PropTypes.func.isRequired,
};

export default TagManager;
