import { useCallback, useState } from "react";

import useSubmitNewUsers from "./useSubmitNewUsers";

function useAddUser() {
  const [userInfos, setUserInfos] = useState([]);

  const { submit, loading } = useSubmitNewUsers();

  const onSubmit = useCallback(async () => {
    await submit(
      { userInfos: userInfos },
      {
        successMsg: "Users added successfully",
        defaultErrorMsg: "Failed to add users",
      }
    );
  }, [userInfos, submit]);

  return { onSubmit, loading, setUserInfos, userInfos };
}

export default useAddUser;
