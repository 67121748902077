import { datadogRum } from "@datadog/browser-rum";

import SetAdvisor from "data/advisor/actions";
import history from "data/history";
import { GetAdvisor } from "data/libs/advisor";
import { ConnectWebsocket } from "data/websockets/actions";

import config from "../../config";

export const HandleAuthenticationChange = (authState) => {
  return (dispatch, getState) => {
    if (authState === "signedIn") {
      GetAdvisor()
        .then((resp) => {
          if (config.datadog) {
            datadogRum.setUser({
              user_id: resp.id,
              email: resp.email,
              name: `${resp.firstName} ${resp.lastName}`,
              hub: resp.hubs?.[0]?.hubId,
            });
            if (
              config.isSessionRecordingOn &&
              !resp.preferences?.sessionRecodingDisabled
            ) {
              datadogRum.startSessionReplayRecording();
            }
          }
          dispatch(SetAdvisor(resp));
          dispatch(ConnectWebsocket());
        })
        .catch((error) => {
          console.error(error);
          history.push(`/error`);
        });
    } else if (config.isSessionRecordingOn) {
      datadogRum.stopSessionReplayRecording();
    }
  };
};

export const SetLoginErrorMessage = (message) => {
  return {
    type: "SET_LOGIN_ERROR_MESSAGE",
    message: message,
  };
};

export const SetLoginInfoMessage = (message) => {
  return {
    type: "SET_LOGIN_INFO_MESSAGE",
    message: message,
  };
};

// for a password to be valid it must:
// Have >= 8 characters
// Contain a lowercase letter
// Contain an uppercase letter
// Contain a special character
// Contain a number
export const isPasswordValid = (password) => {
  return /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%&/=?_.,:;\\-]){8,}/.test(
    password
  );
};
export default isPasswordValid;

export const SetLoginSigningIn = (value) => {
  return {
    type: "SET_LOGIN_SIGNING_IN",
    signingIn: value,
  };
};
