import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";

import copy from "copy-to-clipboard";
import {
  Button,
  Checkbox,
  Divider,
  Header,
  Icon,
  Input,
  Label,
  List,
} from "semantic-ui-react";

import {
  AddClientTask,
  SetClientWorkflow,
} from "data/hub/clientWorkflows/actions";
import { CreateWorkflowTask } from "data/hub/workflows/actions";

import { SetAlert } from "components/Alerts/actions";
import AssignedAdvisorPopup from "components/AssignedAdvisorPopup";
import { SetConfirmationModal } from "components/ConfirmationModal/actions";
import { FeatureFlag } from "components/FeatureFlag";
import { PrivacyModeContext } from "components/PrivacyMode/Context";
import {
  SetCommentTaskId,
  SetTaskComplete,
  SetTaskIncomplete,
} from "components/Task/actions";

import { Assigner } from "scenes/Hubly/components/Workspace/components/Assigner";

import TaskCondition from "../TaskConfigurations/components/TaskCondition";
import TaskDetails from "../TaskConfigurations/components/TaskDetails";
import TaskEmailTemplate from "../TaskConfigurations/components/TaskEmailTemplate";
import TaskFutureReminder from "../TaskConfigurations/components/TaskFutureReminders";
import TaskReminder from "../TaskConfigurations/components/TaskReminder";
import TaskTimeEstimate from "../TaskConfigurations/components/TaskTimeEstimate";
import TaskTimeSpent from "../TaskConfigurations/components/TaskTimeSpent";

class TaskAdd extends React.Component {
  static contextType = PrivacyModeContext;

  static defaultProps = {
    client: {
      id: "",
      comments: [],
      householdId: "",
      reminders: [],
    },
    currentWorkflow: {
      id: "",
      tasks: [],
    },
    setSavingTask: null,
    isClientWorkflow: false,
    isNextWorkflow: false,
    smallButtons: false,
    setNextWorkflowTask: () => {},
    existingTask: null,
  };

  static propTypes = {
    addClientTask: PropTypes.func.isRequired,
    closeAddTask: PropTypes.func.isRequired,
    createWorkflowTask: PropTypes.func.isRequired,
    currentWorkflow: PropTypes.shape({
      id: PropTypes.string,
      clientId: PropTypes.string,
      tasks: PropTypes.array,
    }),
    client: PropTypes.shape({
      id: PropTypes.string,
      comments: PropTypes.array,
      householdId: PropTypes.string,
      reminders: PropTypes.array,
    }),
    isClientWorkflow: PropTypes.bool,
    smallButtons: PropTypes.bool,
    isNextWorkflow: PropTypes.bool,
    selectedHub: PropTypes.shape({
      advisors: PropTypes.array.isRequired,
    }).isRequired,
    currentAdvisor: PropTypes.shape({
      id: PropTypes.string.isRequired,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }).isRequired,
    setAlert: PropTypes.func.isRequired,
    setSavingTask: PropTypes.func,
    setNextWorkflowTask: PropTypes.func,
    existingTask: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      loading: false,
      taskTitle: props.existingTask ? props.existingTask.title : "",
      applyToAll: true,
      task: props.existingTask
        ? props.existingTask
        : {
            assignedAdvisorId: props.currentAdvisor?.id || "",
            assignedAdvisor: props.currentAdvisor,
            assignee: props.currentAdvisor,
            assigneeId: props.currentAdvisor?.id || null,
            clientWorkflowId: props.currentWorkflow.id,
            description: "",
            emailTemplate: null,
            householdMembers: [],
            id: uuidv4(), // Sorry, hack to prevent double modal/popup by assigning temporary UUID (not actually saved)
            isClientTask: false,
            links: [],
            reminders: [],
            taskCondition: null,
            timeEstimates: [],
            title: "",
            workflowId: props.currentWorkflow.id,
          },
    };
  }

  addTask = () => {
    const { applyToAll, task, taskTitle } = this.state;
    const {
      addClientTask,
      client,
      closeAddTask,
      createWorkflowTask,
      currentWorkflow,
      isClientWorkflow,
      isNextWorkflow,
      setSavingTask,
      setNextWorkflowTask,
    } = this.props;
    if (taskTitle.length === 0) {
      closeAddTask();
      return;
    }

    const workflowTasks = currentWorkflow.tasks || [];

    const request = { ...task };
    delete request.assignedAdvisor;
    delete request.assignee;

    request.title = taskTitle;
    request.order = this.getMaxOrderValue(workflowTasks) + 1;

    // Add title to any reminders
    request.reminders = request.reminders.map((reminder) => {
      reminder.title = taskTitle;
      return reminder;
    });
    this.setState({ loading: true });

    if (isNextWorkflow) {
      request.nextWorkflowId = request.workflowId;
      delete request.workflowId;
      delete request.clientWorkflowId;
      setNextWorkflowTask(request, () => {
        this.createTaskComplete();
      });
    } else if (isClientWorkflow) {
      delete request.workflowId;
      request.clientWorkflowId = currentWorkflow.id;
      addClientTask(request, client, currentWorkflow);
      this.createTaskComplete();
    } else {
      setSavingTask(true);
      delete request.clientWorkflowId;
      request.workflowId = currentWorkflow.id;
      createWorkflowTask(request, applyToAll, this.createTaskComplete);
    }
  };

  createTaskComplete = () => {
    const { closeAddTask, isClientWorkflow, isNextWorkflow, setSavingTask } =
      this.props;
    this.setState({
      loading: false,
      taskTitle: "",
    });
    if (isClientWorkflow || isNextWorkflow) {
      closeAddTask();
    } else {
      setSavingTask(false);
    }
  };

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };

  copyURL = (url) => {
    const { setAlert } = this.props;
    const success = copy(url);
    if (success) {
      setAlert({
        text: "Link Copied to Clipboard",
        type: "success",
      });
    } else {
      setAlert({
        text: "Failed to copy client upload link.",
        type: "error",
      });
    }
  };

  editTaskState = (type, value, operation) => {
    const { task } = this.state;
    if (operation === "add") {
      task[type].push(value);
    } else if (operation === "edit") {
      task[type] = value;
    } else if (operation === "delete") {
      task[type] = task[type].filter((v) => {
        return v.id !== value;
      });
    }
    this.setState({ task: { ...task } });
  };

  enterTyped = (e) => {
    // handles enter key press for adding tasks
    const { loading } = this.state;

    if (!loading && e.keyCode === 13) {
      // Enter pressed
      this.addTask();
    }
  };

  getMaxOrderValue = (items) => {
    let max = 0;
    items.forEach((item) => {
      if (item.order && item.order > max) {
        max = item.order;
      }
    });

    return max;
  };

  showAssignAdvisorMenu = () => {
    const { selectedHub } = this.props;
    return selectedHub.advisors.length >= 2;
  };

  taskHasNonClientHouseholdMember = () => {
    const { task } = this.state;
    const { client } = this.props;
    return task.householdMembers.find((householdMember) => {
      return householdMember.client.id !== client.id;
    });
  };

  taskHasDescription = () => {
    const { task } = this.state;
    return task.description && task.description.length > 0;
  };

  taskHasLink = () => {
    const { task } = this.state;
    return task.links && task.links.length > 0;
  };

  taskLinkMarkup = () => {
    const { task } = this.state;
    return (
      <List bulleted style={{ padding: 0 }}>
        {task.links.map((link) => {
          const isLinkProtocol = link.url.includes("://");
          return (
            <List.Item style={{ display: "flex" }}>
              <div
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                <a
                  href={isLinkProtocol ? link.url : `//${link.url}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {link.title || link.url}
                </a>
                {link.title && (
                  <span style={{ color: "grey" }}>
                    ,&nbsp;
                    {link.url}
                  </span>
                )}
              </div>
              <Icon
                color="grey"
                link
                name="copy outline"
                title="Copy Link"
                style={{ marginRight: "0.5em", marginLeft: "0.5em" }}
                onClick={() => {
                  this.copyURL(link.url);
                }}
              />
            </List.Item>
          );
        })}
      </List>
    );
  };

  taskHouseholdMemberMarkup = (nonClientHouseholdMember) => {
    const { piiMask } = this.context;

    return (
      <div>
        This task is for the following household member
        <Label
          key={nonClientHouseholdMember.id}
          className={piiMask("fs-block dd-privacy-mask")}
          color="grey"
          style={{ fontSize: "10px", marginRight: "4px", marginLeft: "4px" }}
        >
          {`${nonClientHouseholdMember.client.name}`}
        </Label>
      </div>
    );
  };

  render() {
    const {
      client,
      closeAddTask,
      currentWorkflow,
      isClientWorkflow,
      isNextWorkflow,
      smallButtons,
    } = this.props;
    const { expanded, task, taskTitle, applyToAll } = this.state;

    const nonClientHouseholdMember = this.taskHasNonClientHouseholdMember();
    const iconGridStyle = {
      display: "flex",
      width: "15px",
      padding: "0",
      marginTop: "3px",
    };
    const showAssignAdvisor = this.showAssignAdvisorMenu();
    let leftSideMinWidth = 75;
    if (!isClientWorkflow) {
      leftSideMinWidth -= 25;
    }
    if (!showAssignAdvisor) {
      leftSideMinWidth -= 30;
    }
    if (smallButtons) {
      leftSideMinWidth -= 21;
    }

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          borderRadius: "5px",
          padding: "0.25em 0.5em",
          marginLeft: smallButtons && "2em",
        }}
        data-test="task-add-form"
      >
        <div style={{ display: "flex", flexFlow: "row" }}>
          {showAssignAdvisor && (
            <div style={{ minWidth: leftSideMinWidth }}>
              <div
                style={{
                  ...iconGridStyle,
                  marginTop: "0.8em",
                  marginLeft: leftSideMinWidth - 25,
                }}
              >
                <FeatureFlag id="service-team-settings">
                  <Assigner
                    size="small"
                    currentAssignee={task.assignee}
                    onAssign={(id, assignee) => {
                      this.editTaskState("assignee", assignee, "edit");
                      this.editTaskState(
                        "assigneeId",
                        id === "unassigned" ? null : id,
                        "edit"
                      );
                    }}
                  />
                </FeatureFlag>
                <FeatureFlag id="service-team-settings" showOnDisabled>
                  <AssignedAdvisorPopup
                    addingTask
                    currentObject={task}
                    editTaskState={(type, value, operation) => {
                      this.editTaskState(type, value, operation);
                      if (type === "assignedAdvisorId") {
                        this.editTaskState("assigneeId", value, operation);
                      }
                    }}
                    currentObjectType="TASK"
                  />
                </FeatureFlag>
              </div>
            </div>
          )}
          <div style={{ flexGrow: 1, margin: "0.2em 0.5em 0 0.5em" }}>
            <Input
              autoComplete="off"
              name="taskTitle"
              value={taskTitle}
              autoFocus
              fluid
              onChange={this.handleChange}
              onKeyUp={(e) => {
                this.enterTyped(e, currentWorkflow);
              }}
              placeholder="New Task"
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexFlow: "row",
            margin: "0.5em 0 0 0.5em",
            justifyContent: "space-between",
          }}
        >
          <div
            style={showAssignAdvisor ? { marginLeft: leftSideMinWidth } : {}}
          >
            <div style={{ diplay: "flex", flexDirection: "column" }}>
              {!isClientWorkflow && !isNextWorkflow && (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Checkbox
                    checked={applyToAll}
                    onChange={() => {
                      this.setState((state) => {
                        return { applyToAll: !state.applyToAll };
                      });
                    }}
                  />
                  <div
                    style={{
                      color: "grey",
                      marginLeft: "0.5em",
                      fontSize: "10pt",
                    }}
                  >
                    Add to existing clients
                  </div>
                </div>
              )}
              <div
                style={{
                  // transform: smallButtons && "translateX(-30px)",
                  display: "flex",
                  alignItems: "center",
                  marginTop: !smallButtons && "0.5em",
                }}
              >
                <Button
                  content="Save"
                  compact={smallButtons}
                  color="green"
                  onClick={() => {
                    this.addTask();
                  }}
                  style={{ marginTop: "0px" }}
                />
                <Icon
                  name="delete"
                  link
                  color="grey"
                  size={!smallButtons && "large"}
                  onClick={() => {
                    closeAddTask();
                  }}
                  style={{ marginBottom: smallButtons && "0.25em" }}
                />
              </div>
            </div>
          </div>
          <div>
            <div
              style={{
                alignSelf: "flex-start",
                display: "flex",
                marginRight: "0.5em",
                width: smallButtons ? "auto" : 110,
                justifyContent: "space-between",
              }}
            >
              <div style={iconGridStyle}>
                {isClientWorkflow ? (
                  <TaskReminder
                    addingTask
                    client={client}
                    editTaskState={this.editTaskState}
                    task={task}
                  />
                ) : (
                  <TaskFutureReminder
                    addingTask
                    editTaskState={this.editTaskState}
                    task={task}
                    workflow={currentWorkflow}
                  />
                )}
              </div>
              {!isNextWorkflow && (
                <React.Fragment>
                  <div style={iconGridStyle}>
                    <TaskEmailTemplate
                      addingTask
                      editTaskState={this.editTaskState}
                      emailToField={!!isClientWorkflow}
                      client={client}
                      task={task}
                      workflow={currentWorkflow}
                    />
                  </div>
                  <div style={iconGridStyle}>
                    {isClientWorkflow && !isNextWorkflow ? (
                      <TaskTimeSpent
                        addingTask
                        editTaskState={this.editTaskState}
                        task={task}
                      />
                    ) : (
                      <TaskTimeEstimate
                        addingTask
                        editTaskState={this.editTaskState}
                        task={task}
                        workflow={currentWorkflow}
                      />
                    )}
                  </div>
                  <div style={iconGridStyle}>
                    <TaskDetails
                      addingTask
                      client={client}
                      currentWorkflow={currentWorkflow}
                      isClientWorkflow={isClientWorkflow}
                      editTaskState={this.editTaskState}
                      expanded={expanded}
                      task={task}
                      toggleExpand={() => {
                        this.setState((state) => {
                          return { expanded: !state.expanded };
                        });
                      }}
                    />
                  </div>
                </React.Fragment>
              )}
              {!isClientWorkflow && !isNextWorkflow && (
                <div style={iconGridStyle}>
                  <TaskCondition
                    addingTask
                    editTaskState={this.editTaskState}
                    task={task}
                    workflow={currentWorkflow}
                  />
                </div>
              )}
              {(isClientWorkflow || !isNextWorkflow) && (
                <div style={iconGridStyle} />
              )}
              {isClientWorkflow && <div style={iconGridStyle} />}
            </div>
          </div>
        </div>
        {expanded && (
          <div
            className="taskDescriptionDiv"
            style={{
              marginTop: "0.25em",
              marginLeft: "5em",
              marginRight: "2em",
            }}
          >
            {this.taskHasDescription() && (
              <div
                style={{ color: "grey", fontSize: "10pt" }}
                dangerouslySetInnerHTML={{ __html: task.description }}
              />
            )}
            {(task.isClientTask || nonClientHouseholdMember) && (
              <React.Fragment>
                {this.taskHasDescription() && (
                  <Divider style={{ margin: "0.5em 0em 0.5em 0em" }} />
                )}
                <Header as="h5" style={{ margin: "0.25em 0" }}>
                  Client Details
                </Header>
                <List bulleted style={{ padding: 0 }}>
                  {task.isClientTask && (
                    <List.Item>
                      The client is responsible for completing this task.
                    </List.Item>
                  )}
                  {nonClientHouseholdMember && (
                    <List.Item>
                      {this.taskHouseholdMemberMarkup(nonClientHouseholdMember)}
                    </List.Item>
                  )}
                </List>
              </React.Fragment>
            )}
            {this.taskHasLink() > 0 && (
              <React.Fragment>
                {(this.taskHasDescription() ||
                  task.isClientTask ||
                  nonClientHouseholdMember) && (
                  <Divider style={{ margin: "0.5em 0em 0.5em 0em" }} />
                )}
                {this.taskLinkMarkup()}
              </React.Fragment>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedHub: state.hubly.data.hub.selected.hub,
    currentAdvisor: state.hubly.data.advisor,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    addClientTask: (request, client, currentWorkflow) => {
      dispatch(AddClientTask(request, client, currentWorkflow));
    },
    createWorkflowTask: (request, applyToAll, complete) => {
      dispatch(CreateWorkflowTask(request, applyToAll, complete));
    },
    setTaskComplete: (workflow, taskId, editedTime) => {
      dispatch(SetTaskComplete(workflow, taskId, editedTime));
    },
    setConfirmationModal: (id) => {
      dispatch(SetConfirmationModal(id));
    },
    setTaskIncomplete: (workflow, taskId) => {
      dispatch(SetTaskIncomplete(workflow, taskId));
    },
    setAlert: (alert) => {
      dispatch(SetAlert(alert));
    },
    setClientWorkflow: (clientWorkflow) => {
      dispatch(SetClientWorkflow(clientWorkflow));
    },
    setCommentTaskId: (taskId, clientWorkflowId) => {
      dispatch(SetCommentTaskId(taskId, clientWorkflowId));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(TaskAdd);
