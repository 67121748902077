export const initialState = {
  editWorkflowRule: {},
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_EDIT_WORKFLOW_RULE":
      return {
        ...state,
        editWorkflowRule: action.editWorkflowRule,
      };
    default:
      return state;
  }
};
export default reducer;
