import React from "react";
import PropTypes from "prop-types";

import { Dropdown } from "semantic-ui-react";

const ClientWorkflowDropdownItem = ({
  clientWorkflow,
  handleChange,
  isCurrentClientWorkflow,
}) => {
  const { value, key, text, content, completed } = clientWorkflow;

  return (
    <Dropdown.Item
      text={text}
      value={value}
      content={content}
      key={key}
      style={{
        fontWeight: isCurrentClientWorkflow ? "bold" : undefined,
        fontSize: isCurrentClientWorkflow ? "13pt" : undefined,
        fontStyle: completed ? "italic" : undefined,
      }}
      name="workflowId"
      data-test="client-workflow-dropdown-item"
      onClick={handleChange}
    />
  );
};

ClientWorkflowDropdownItem.propTypes = {
  clientWorkflow: PropTypes.shape({
    value: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    completed: PropTypes.bool.isRequired,
  }),
  handleChange: PropTypes.func,
  isCurrentClientWorkflow: PropTypes.bool,
};

ClientWorkflowDropdownItem.defaultProps = {
  clientWorkflow: {
    value: "",
    key: "",
    text: "",
    content: "",
    completed: false,
  },
  handleChange: () => {},
  isCurrentClientWorkflow: false,
};

export default ClientWorkflowDropdownItem;
