import React from "react";
import PropTypes from "prop-types";

import { ClientTile } from "scenes/Hubly/components/Workspace/components/WorkflowContainer/components/ClientTile/ClientTile";

export function ClientTileSample({
  tileName,
  topName,
  bottomName,
  showProgress,
  showIcons,
  showStreams,
  numberOfTasks,
  presentWorkflowName,
}) {
  return (
    <ClientTile
      onClick={() => {}}
      clientTileAppearance={{
        showProgress,
        showIcons,
        showStreams,
        numberOfTasks,
        presentWorkflowName,
      }}
      data={{
        id: "sample",
        name: tileName,
        client: {
          firstName: topName,
          lastName: bottomName,
          newUploadedFileCount: 3,
          streams: [
            { id: "s-1", name: "Active Client", color: "#4caf50" },
            { id: "s-2", name: "Benefits", color: "#03a9f4" },
            { id: "s-3", name: "Estate", color: "#673ab7" },
            { id: "s-4", name: "Financial Planning", color: "#3f51b5" },
          ],
        },
        tasksStats: {
          numReminders: 3,
          numFilteredTasks: 5,
          numFilteredTasksCompleted: 0,
          filteredTasks: ["one", "two", "three", "four", "five"].map(
            (num, i) => {
              return {
                id: `t-${i + 1}`,
                order: i + 1,
                title: `Example task ${num}`,
                completed: false,
              };
            }
          ),
        },
        clientWorkflowReminders: [
          {
            reminder: {
              id: "r-1",
              title: "Reminder ",
              time: new Date().toDateString(),
              dismissed: false,
            },
          },
        ],
        clientWorkflowStatus: {},
      }}
      context={{
        resolveNamePerPreferences: () => {
          return {
            topName: { value: topName },
            bottomName: { value: bottomName },
          };
        },
      }}
    />
  );
}

ClientTileSample.propTypes = {
  tileName: PropTypes.string,
  topName: PropTypes.string,
  bottomName: PropTypes.string,
  showProgress: PropTypes.bool.isRequired,
  showIcons: PropTypes.bool.isRequired,
  showStreams: PropTypes.bool.isRequired,
  numberOfTasks: PropTypes.number.isRequired,
  presentWorkflowName: PropTypes.bool.isRequired,
};

ClientTileSample.defaultProps = {
  tileName: "Workflow Name",
  topName: "John",
  bottomName: "Doe",
};

export default ClientTileSample;
