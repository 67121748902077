import React from "react";
import PropTypes from "prop-types";

import {
  AddItems,
  createDroppable,
  DragDropTileContext,
  FloatingDraggableFragment,
  Icon,
  ListTile,
  Modal,
  ModalPlaceholder,
} from "./components";

const DroppableItemList = createDroppable("ItemList");
export function ManageListModal({
  loading,
  onClose,
  header,
  open,
  onRename,
  onReorder,
  onAdd,
  onDelete,
  onChangeOption,
  visibleItems,
  showMoreItems,
  icons,
  isAdding,
  maxItems,
  maxItemsMessage,
  itemOptions,
  defaultOption,
  ...props
}) {
  return (
    <Modal onClose={onClose} open={open} {...props}>
      <Modal.Header>
        <Icon
          name="delete"
          link
          color="grey"
          style={{ float: "right", position: "relative" }}
          onClick={onClose}
        />
        {header}
      </Modal.Header>
      <Modal.Content scrolling style={{ maxHeight: "80vh" }}>
        {loading ? (
          <ModalPlaceholder />
        ) : (
          <React.Fragment>
            <DragDropTileContext
              onReorder={(sort) => {
                onReorder(
                  sort({ visible: visibleItems, showMore: showMoreItems })
                );
              }}
            >
              <DroppableItemList
                id="visible-item-container"
                style={{ minHeight: "20px" }}
              >
                {visibleItems.map((item, i) => {
                  return (
                    <FloatingDraggableFragment
                      id={item.id}
                      key={item.id}
                      index={i}
                    >
                      <ListTile
                        item={item}
                        onClickDelete={onDelete}
                        onClickSave={onRename}
                        onChangeOption={onChangeOption}
                      />
                    </FloatingDraggableFragment>
                  );
                })}
              </DroppableItemList>
              <div
                style={{
                  marginTop: "1em",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{ borderBottom: "1px dashed darkgrey", width: "30%" }}
                />
                <div style={{ color: "darkgrey", margin: "0px 2em" }}>
                  Show More
                </div>
                <div
                  style={{ borderBottom: "1px dashed darkgrey", width: "30%" }}
                />
              </div>
              <DroppableItemList
                id="show-more-item-container"
                style={{ minHeight: "20px" }}
              >
                {showMoreItems.map((item, i) => {
                  return (
                    <FloatingDraggableFragment
                      id={item.id}
                      key={item.id}
                      index={i}
                    >
                      <ListTile
                        item={item}
                        onClickDelete={onDelete}
                        onClickSave={onRename}
                        onChangeOption={onChangeOption}
                      />
                    </FloatingDraggableFragment>
                  );
                })}
              </DroppableItemList>
            </DragDropTileContext>
            <div style={{ marginTop: "1em", marginBottom: "1em" }}>
              {visibleItems.length + showMoreItems.length < maxItems ? (
                <AddItems
                  onClickAdd={onAdd}
                  icons={icons}
                  loading={isAdding}
                  options={itemOptions}
                  defaultOption={defaultOption}
                />
              ) : (
                <div style={{ color: "red" }}> {maxItemsMessage}</div>
              )}
            </div>
          </React.Fragment>
        )}
      </Modal.Content>
    </Modal>
  );
}

ManageListModal.propTypes = {
  loading: PropTypes.bool,
  header: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  icons: PropTypes.array,
  visibleItems: PropTypes.array,
  showMoreItems: PropTypes.array,
  onAdd: PropTypes.func.isRequired,
  onReorder: PropTypes.func.isRequired,
  onRename: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onChangeOption: PropTypes.func.isRequired,
  isAdding: PropTypes.bool,
  maxItems: PropTypes.number,
  maxItemsMessage: PropTypes.string,
  itemOptions: PropTypes.array,
  defaultOption: PropTypes.object,
};

ManageListModal.defaultProps = {
  loading: false,
  visibleItems: [],
  showMoreItems: [],
  icons: undefined,
  open: false,
  isAdding: false,
  maxItems: 20,
  maxItemsMessage: "",
  itemOptions: [],
  defaultOption: null,
};

export default ManageListModal;
