import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import config from "config";

import { Header } from "semantic-ui-react";

import { SetAlert } from "components/Alerts/actions";

class CardViewIntegrations extends React.Component {
  static propTypes = {
    client: PropTypes.shape({
      externalId: PropTypes.string.isRequired,
      workflows: PropTypes.object.isRequired,
    }).isRequired,
    integration: PropTypes.shape({
      type: PropTypes.string.isRequired,
    }).isRequired,
  };

  render() {
    const { client, integration } = this.props;

    let name = "Wealthbox";
    let url = `https://app.crmworkspace.com/contacts/${client.externalId}`;
    let color = "#97bff6";
    if (integration.type === "redtail") {
      name = "Redtail";
      url = `${config.redtailUrl}/contacts/${client.externalId}`;
      color = "#d89497";
    }
    return (
      <div style={{ marginBottom: "2em" }}>
        <Header as="h5" style={{ marginBottom: "0.5em" }}>
          View Client In
        </Header>
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "white", fontWeight: "bold" }}
        >
          <div
            className="view_integration_link reduce_opacity_on_hover"
            style={{
              marginLeft: "1em",
              backgroundColor: color,
              padding: "0.5em 0.75em",
              borderRadius: "5px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src={`/images/logos/${integration.type}-logo.png`}
              alt={`${name} Logo`}
              style={{ height: "25px", marginRight: "0.75em" }}
            />
            <div>{name}</div>
          </div>
        </a>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    advisor: state.hubly.data.advisor,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAlert: (alert) => {
      dispatch(SetAlert(alert));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CardViewIntegrations);
