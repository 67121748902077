import React from "react";

export function NoBillingChanges() {
  return (
    <div>
      <strong>There are no changes to billing on your account.</strong>
    </div>
  );
}

export default NoBillingChanges;
