import { useDispatch } from "react-redux";

import { fetchClientWorkflows } from "data/hub/clientWorkflows/actions";

export function useClientServiceTeam(clientId) {
  const dispatch = useDispatch();

  return () => {
    return dispatch(fetchClientWorkflows(clientId));
  };
}

export default useClientServiceTeam;
