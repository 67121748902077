import React from "react";
import PropTypes from "prop-types";
import { v4 } from "uuid";

import { makeStyles } from "@material-ui/core/styles";

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePlaceholder,
  TableRow,
} from "./components";

const useStyles = makeStyles({
  table: {
    fontFamily: '"Helvetica Neue", Arial, Helvetica, sans-serif',
    minWidth: 500,
  },
  headerCell: {
    fontSize: "1.2em",
    fontWeight: "bold",
    minWidth: "100px",
    verticalAlign: "top",
  },
  highlightedHeaderCell: {
    fontSize: "1.2em",
    fontWeight: "bold",
    minWidth: "100px",
    verticalAlign: "top",
    borderBottom: "1px solid black",
  },
  tableRow: {
    fontSize: "1em",
    verticalAlign: "top",
    cursor: "pointer",
  },
  disabledRow: {
    fontSize: "1em",
    verticalAlign: "top",
    cursor: "default",
  },
  cellData: {
    font: "inherit",
    margin: "2px 0",
  },
  highlightedCellData: {
    font: "inherit",
    margin: "2px 0",
    fontWeight: "bold",
  },
  highlightedRowCell: {
    borderBottom: "1px solid black",
  },
});

export function SimpleDataTable({
  loading,
  header,
  rows,
  emptyDataMessage,
  stickyHeader,
  onRowClick,
  highlightFirstRowBorders,
  disabled,
}) {
  const {
    table,
    headerCell,
    highlightedHeaderCell,
    tableRow,
    disabledRow,
    cellData,
    highlightedCellData,
    highlightedRowCell,
  } = useStyles();

  if (loading) {
    return <TablePlaceholder />;
  }

  if (header.length === 0 || rows.length === 0) {
    return <div>{emptyDataMessage}</div>;
  }

  return (
    <TableContainer component={Paper}>
      <Table
        stickyHeader={stickyHeader}
        className={table}
        size="small"
        aria-label="manage service team table"
      >
        <TableBody>
          <TableRow>
            {header.map((col) => {
              return (
                <TableCell
                  data-test="header-column"
                  component="th"
                  scope="row"
                  className={
                    highlightFirstRowBorders
                      ? highlightedHeaderCell
                      : headerCell
                  }
                  key={`${v4()}-${col}-header`}
                >
                  {col}
                </TableCell>
              );
            })}
          </TableRow>
          {rows.map(({ id, cells }, rowIndex) => {
            return (
              <TableRow
                data-test="data-row"
                component="tr"
                scope="row"
                hover={disabled}
                className={disabled ? tableRow : disabledRow}
                key={`${id}-row`}
                onClick={() => {
                  if (disabled) onRowClick(id);
                }}
              >
                {cells.map((cell, cellIndex) => {
                  return (
                    <TableCell
                      data-test="data-cell"
                      key={`${v4()}-${cell}`}
                      className={
                        highlightFirstRowBorders && rowIndex === 0
                          ? highlightedRowCell
                          : ""
                      }
                    >
                      <div
                        className={
                          highlightFirstRowBorders &&
                          rowIndex === 0 &&
                          cellIndex === 0
                            ? highlightedCellData
                            : cellData
                        }
                      >
                        {cell}
                      </div>
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

SimpleDataTable.propTypes = {
  header: PropTypes.array,
  rows: PropTypes.array,
  loading: PropTypes.bool,
  emptyDataMessage: PropTypes.string,
  onRowClick: PropTypes.func.isRequired,
  stickyHeader: PropTypes.bool,
  highlightFirstRowBorders: PropTypes.bool,
  disabled: PropTypes.bool,
};

SimpleDataTable.defaultProps = {
  loading: false,
  header: [],
  rows: [],
  stickyHeader: true,
  emptyDataMessage: "This table contains no data",
  highlightFirstRowBorders: false,
  disabled: true,
};

export default SimpleDataTable;
