import React from "react";
import PropTypes from "prop-types";

import { Icon } from "semantic-ui-react";

export function LayoutIcon({ children, spinning }) {
  return (
    <React.Fragment>
      <Icon.Group>
        <Icon name="bell outline" size="large" />
        <Icon name="asterisk" corner loading={spinning} />
      </Icon.Group>
      {children}
    </React.Fragment>
  );
}

LayoutIcon.propTypes = {
  spinning: PropTypes.bool,
  children: PropTypes.node,
};

LayoutIcon.defaultProps = {
  spinning: false,
  children: null,
};

export default Icon;
