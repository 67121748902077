import { add, parseISO } from "date-fns";
import { groupByKeys } from "utils";

import { getCombinedStreamsTagsAndClient } from "../../helper";

export const getImportantDateValue = (workflowRule) => {
  if (!workflowRule?.importantDate) return "meeting";
  if (workflowRule.importantDate !== "meeting") {
    return workflowRule?.dateType?.id;
  }
  return workflowRule.importantDate;
};

export const getDefaultMeetingDateType = (meetingDateTypes = []) => {
  return (
    meetingDateTypes?.find(({ name }) => {
      return name === "Client Meeting";
    })?.id || (meetingDateTypes?.length > 0 ? meetingDateTypes[0].id : "")
  );
};

export const getImportantDateOptions = (dateTypes, onBeforeAfter) => {
  return [
    {
      value: "meeting",
      key: "meeting",
      icon: "calendar alternate",
      text: `${
        onBeforeAfter.toLowerCase() === "after" ? "Last" : "Next"
      } Meeting Date`,
    },
    ...dateTypes.map(({ id, name: dtName, icon }) => {
      return { value: id, key: id, text: dtName, icon: icon };
    }),
  ];
};

export const getImportantClientRulesInitialValue = (workflowRule) => {
  return {
    dateSelector: {
      interval:
        workflowRule?.interval !== undefined ? workflowRule?.interval : 1,
      frequency: workflowRule?.frequency || "WEEKLY",
      meetingDateTypeId: workflowRule?.meetingDateType?.id,
      dateType: workflowRule?.dateType
        ? workflowRule?.dateType
        : { id: null, name: "meeting" },
    },
    selectedStreamsTags: getCombinedStreamsTagsAndClient(
      workflowRule?.streams,
      workflowRule?.tags
    ),
    excludeItems: getCombinedStreamsTagsAndClient(
      workflowRule?.streamsExclude,
      workflowRule?.tagsExclude,
      workflowRule?.clientsExclude
    ),
  };
};

export const getImportantClientDatesModalProp = (workflowRule) => {
  return {
    initialValue: getImportantClientRulesInitialValue(workflowRule),
    title: "Important Client Date Rule",
    testId: "importantClientDatesRule-modal",
    showExcludeItems: true,
    showHouseholdHead: false,
    formValidator: ({ selectedStreamsTags, dateSelector }) => {
      return selectedStreamsTags.length > 0 && dateSelector.interval !== "";
    },
  };
};

export const formatImportantClientDateRuleRequest = ({
  selectedStreamsTags,
  excludeItems,
  dateSelector,
  workflowRule,
  meetingDateTypes,
}) => {
  // Set the time to be before the lambda cron, so it will run that morning
  const dateBeforeLambda = workflowRule?.dateStart
    ? parseISO(workflowRule.dateStart)
    : add(new Date(), { days: 1 });
  dateBeforeLambda.setHours(7, 15, 0, 0);

  const {
    streams: streamsExclude,
    tags: tagsExclude,
    clients: clientsExclude,
  } = groupByKeys(excludeItems, ["stream", "tag", "client"]);

  const { frequency, interval, dateType, meetingDateTypeId } = dateSelector;

  return {
    ...groupByKeys(selectedStreamsTags, ["stream", "tag"]),
    type: "IMPORTANT_DATE_RULE",
    interval: interval,
    dateStart: dateBeforeLambda.toISOString(),
    frequency: frequency,
    importantDate: dateType.name,
    dateTypeId: dateType.id,
    meetingDateTypeId:
      dateType.name === "meeting" && !meetingDateTypeId
        ? getDefaultMeetingDateType(meetingDateTypes)
        : meetingDateTypeId,
    streamsExclude: streamsExclude,
    tagsExclude: tagsExclude,
    clientsExclude: clientsExclude,
  };
};

export default getImportantClientRulesInitialValue;
