import React from "react";

import { useDraggable } from "components/DragDrop";

export function HiddenContainer() {
  const { draggableProps, dragHandleProps } = useDraggable();
  return (
    <div {...draggableProps} style={{ display: "none" }}>
      <div {...dragHandleProps} />
    </div>
  );
}

export default HiddenContainer;
