import { API } from "aws-amplify";
import { formatISO } from "date-fns";

import SyncedAPI from "data/libs/syncedAPI";

import { getAPIQuery, getAPIReq } from "./authLib";

export const GetClients = async (hub, limit = 1000, offset = 0) => {
  const req = await getAPIQuery();
  return API.get(
    "HublyAPI",
    `/hubs/${hub.id}/clients/?limit=${limit}&offset=${offset}`,
    req
  );
};

export const GetRecentClientNames = async (hub) => {
  const req = await getAPIReq();
  return API.get(
    "HublyAPI",
    `/hubs/${hub.id}/clients/recentClientNames/?limit=10`,
    req
  );
};

export const GetAllClientNames = async (hub) => {
  const req = await getAPIReq();
  return API.get("HublyAPI", `/hubs/${hub.id}/clients/allNames/`, req);
};

export const GetClient = async (clientId) => {
  const req = await getAPIReq();
  return API.get("HublyAPI", `/clients/${clientId}/`, req);
};

export const CreateClient = async (request) => {
  const req = await getAPIReq(request);
  return SyncedAPI.post("HublyAPI", `/clients/`, req, (response) => {
    return [
      {
        type: "ADD_CLIENT_NAME",
        client: {
          id: response.id,
          firstName: response.firstName,
          lastName: response.lastName,
        },
      },
      { type: "SET_ACTIVE_CLIENT", client: response },
    ];
  });
};

export const EditClient = async (clientId, request) => {
  const req = await getAPIReq(request);
  return SyncedAPI.put("HublyAPI", `/clients/${clientId}/`, req, (response) => {
    return { type: "SET_ACTIVE_CLIENT", client: response };
  });
};

export const GetAttachments = async (clientId) => {
  const req = await getAPIReq();
  return API.get("HublyAPI", `/clients/${clientId}/attachments/`, req);
};

export const GetAttachmentDownload = async (clientId, query) => {
  const req = await getAPIQuery(query);
  return API.get("HublyAPI", `/clients/${clientId}/attachments/`, req);
};

export const CreateAttachment = async (clientId, request) => {
  const req = await getAPIReq(request);
  return SyncedAPI.post("HublyAPI", `/clients/${clientId}/attachments/`, req);
};

export const DeleteAttachment = async (clientId, request) => {
  const req = await getAPIReq(request);
  return SyncedAPI.del("HublyAPI", `/clients/${clientId}/attachments/`, req);
};

export const GetClientCardOpened = async (clientId, request) => {
  const req = await getAPIReq(request);
  return API.get("HublyAPI", `/clients/${clientId}/getCardOpened/`, req);
};

export const UpdateClientCardOpened = async (clientId, request) => {
  const req = await getAPIReq(request);
  return SyncedAPI.put("HublyAPI", `/clients/${clientId}/cardOpened/`, req);
};

export const CreateClientHousehold = async (clientId, request) => {
  const req = await getAPIReq(request);
  return SyncedAPI.post(
    "HublyAPI",
    `/clients/${clientId}/households/`,
    req,
    (response) => {
      return { type: "ADD_HOUSEHOLD", household: response };
    }
  );
};

export const DeleteClientHousehold = async (clientId, householdId) => {
  const req = await getAPIReq();
  return SyncedAPI.del(
    "HublyAPI",
    `/clients/${clientId}/households/${householdId}/`,
    req
  );
};

export const GetClientStreams = async (clientId) => {
  const req = await getAPIReq();
  return API.get("HublyAPI", `/clients/${clientId}/streams/`, req);
};

export const CreateClientStream = async (clientId, request) => {
  const req = await getAPIReq(request);
  return SyncedAPI.post(
    "HublyAPI",
    `/clients/${clientId}/streams/`,
    req,
    (response) => {
      return {
        type: "ADD_CLIENT_STREAM",
        stream: response,
        clientId: clientId,
      };
    }
  );
};

export const DeleteClientStream = async (clientId, streamId) => {
  const req = await getAPIReq();
  return SyncedAPI.del(
    "HublyAPI",
    `/clients/${clientId}/streams/${streamId}/`,
    req,
    () => {
      return {
        type: "REMOVE_CLIENT_STREAM",
        streamId: streamId,
        clientId: clientId,
      };
    }
  );
};

export const GetClientComments = async (clientId) => {
  const req = await getAPIQuery();
  return API.get("HublyAPI", `/clients/${clientId}/comments/`, req);
};

export const GetClientCommentsPage = async (clientId, limit, minDate) => {
  const req = await getAPIQuery();
  return API.get(
    "HublyAPI",
    `/clients/${clientId}/comments/?` +
      `limit=${limit}&min_date=${formatISO(minDate)}`,
    req
  );
};

export const GetClientTags = async (clientId) => {
  const req = await getAPIReq();
  return API.get("HublyAPI", `/clients/${clientId}/tags/`, req);
};

export const CreateClientTag = async (clientId, request) => {
  const req = await getAPIReq(request);
  return SyncedAPI.post(
    "HublyAPI",
    `/clients/${clientId}/tags/`,
    req,
    (response) => {
      return [
        { type: "SET_TAG", tag: response },
        { type: "ADD_TAG_TO_CLIENT", tagId: response.id, clientId: clientId },
      ];
    }
  );
};

export const UpdateClientTag = async (clientId, tagId, request) => {
  const req = await getAPIReq(request);
  return SyncedAPI.put(
    "HublyAPI",
    `/clients/${clientId}/tags/${tagId}/`,
    req,
    (response) => {
      return { type: "SET_TAG", tag: response };
    }
  );
};

export const DeleteClientTag = async (clientId, tagId) => {
  const req = await getAPIReq();
  return SyncedAPI.del(
    "HublyAPI",
    `/clients/${clientId}/tags/${tagId}/`,
    req,
    () => {
      return [
        { type: "REMOVE_TAG_FROM_CLIENT", tagId: tagId, clientId: clientId },
      ];
    }
  );
};

export const GetClientAudit = async (clientId, query) => {
  const req = await getAPIQuery(query);
  req.responseType = "blob";
  req.isBase64Encoded = true;
  return API.get("HublyAPI", `/clients/${clientId}/auditExport/`, req);
};

export const formatFirstNameWithMiddleNameAndNickname = (client) => {
  if (!client) return "";
  const { firstName = "", middleName = "", nickname = "" } = client;
  return [firstName, nickname ? `"${nickname}"` : "", middleName].reduce(
    (formattedName, name) => {
      if (formattedName) {
        return name ? `${formattedName} ${name}` : formattedName;
      } else {
        return name || "";
      }
    },
    ""
  );
};

// returns a clients name "First Last" or "Last First" depending on hub settings
export const getOrderedClientName = (client, hub) => {
  if (!client) return ""; // usually this will only happen when a web socket has sent a name but the client body hasn't been sent yet
  const ln = client.lastName;
  const fn = formatFirstNameWithMiddleNameAndNickname(client);
  if (hub?.sortByLastName) {
    return `${ln || ""}${fn && ln ? ", " : ""}${fn || ""}`;
  }
  return `${fn || ""}${fn && ln ? " " : ""}${ln || ""}`;
};

// returns a clients name "First Last" or "Last First" depending on hub settings with prefix and suffix attached
export const getOrderedFullClientName = (client, hub) => {
  if (!client) return ""; // usually this will only happen when a web socket has sent a name but the client body hasn't been sent yet
  const ln = client.lastName;
  const fn = formatFirstNameWithMiddleNameAndNickname(client);
  const pref = client.prefix;
  const suf = client.suffix;
  if (hub.sortByLastName) {
    return `${pref ? `${pref} ` : ""}${ln || ""}${fn && ln ? ", " : ""}${
      fn || ""
    }${suf ? ` ${suf} ` : ""}`;
  }
  return `${pref ? `${pref} ` : ""}${fn || ""}${fn && ln ? " " : ""}${
    ln || ""
  }${suf ? ` ${suf} ` : ""}`;
};

// sort clients names alphabetically according to hub settings
export const sortClients = (unsorted = [], hub, reverseOrder = false) => {
  const reverse = reverseOrder ? -1 : 1;
  return unsorted.sort((a, b) => {
    const A = getOrderedClientName(a, hub);
    const B = getOrderedClientName(b, hub);
    return A?.localeCompare(B) * reverse;
  });
};
