import React from "react";
import PropTypes from "prop-types";

import { Button } from "semantic-ui-react";

import Popper from "components/Popper";

export function ModalActions({
  loading,
  disabled,
  isWealthboxUser,
  onEditClick,
  onDeleteClick,
}) {
  return (
    <React.Fragment>
      {isWealthboxUser ? (
        <React.Fragment>
          <Popper
            content="Edit or delete the tag in Wealthbox.  A manual sync is required for the changes to appear in the Tag Manager."
            on="hover"
            position="bottom center"
            style={{
              padding: "0.6em",
              fontSize: "11px",
              fontWeight: "bold",
            }}
            trigger={
              <Button
                onClick={() => {
                  const win = window.open(
                    `https://app.crmworkspace.com/settings/tags`,
                    "_blank"
                  );
                  win.focus();
                }}
                primary
                loading={loading}
                disabled={disabled}
              >
                Manage in Wealthbox
              </Button>
            }
          />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Button
            negative
            onClick={onDeleteClick}
            loading={loading}
            disabled={disabled}
          >
            Delete
          </Button>
          <Button
            positive
            onClick={onEditClick}
            loading={loading}
            disabled={disabled}
          >
            Save
          </Button>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

ModalActions.defaultProps = {
  disabled: false,
  loading: false,
  isWealthboxUser: false,
};

ModalActions.propTypes = {
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  isWealthboxUser: PropTypes.bool,
  onDeleteClick: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
};

export default ModalActions;
