import { useContext, useEffect, useRef } from "react";

import { ToasterContext } from "scenes/Hubly/components/Workspace/Provider";

import { useBooleanState, useMutation } from ".";

export function useMutationWithToaster(factory, callback = {}) {
  const { onDone = () => {}, onError = () => {} } = callback;

  const mutationFunc = useMutation(factory);
  const [isRunning, setRunning, stopRunning] = useBooleanState();
  const { fireSuccess, fireWarning, fireError } = useContext(ToasterContext);
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  const makeCall = async (action) => {
    if (mounted.current) {
      setRunning();
      await action();
      if (mounted.current) stopRunning();
    }
  };

  const run = (
    v,
    {
      successMsg = "Successfully updated.",
      defaultErrorMsg = "Failed to update.",
    } = {}
  ) => {
    const showToasterError = (e) => {
      fireError(
        Array.isArray(e) && e[0]?.message ? e[0]?.message : defaultErrorMsg,
        e
      );
      onError(e);
    };

    return makeCall(() => {
      return mutationFunc(v)
        .then(({ data = [], errors = null } = {}) => {
          if (errors) {
            showToasterError(errors);
            return;
          }

          if (data.warningMessage) {
            fireWarning(data.warningMessage);
          }

          fireSuccess(data.successMessage || successMsg);
          onDone(data);
        })
        .catch(showToasterError);
    });
  };

  return [run, { loading: isRunning }];
}

export default useMutationWithToaster;
