import React from "react";
import PropTypes from "prop-types";

import { swap } from "utils";

const FilterStore = React.createContext();

export const isEmptyFilter = (f, ignoreFilterEmptyWorkflows = false) => {
  return (
    f.streams.length === 0 &&
    f.tags.length === 0 &&
    (f.filterEmptyWorkflows === false || ignoreFilterEmptyWorkflows) &&
    f.filterNextTaskForAssignee === false &&
    f.assigneeFilterType === "ALL" &&
    f.filterAssigneeIncludeServiceRole === true &&
    f.filterServiceRoleUnassignedOnly === true &&
    f.reminderTypes.length === 0 &&
    f.clients.length === 0
  );
};

export const initState = {
  workflows: [],
  filters: {
    streams: [],
    streamFilterType: "ANY",
    tags: [],
    clients: [],
    tagFilterType: "ANY",
    filterEmptyWorkflows: false,
    filterNextTaskForAssignee: false,
    assigneeFilterType: "ALL",
    filterAssigneeIncludeServiceRole: true,
    filterServiceRoleUnassignedOnly: true,
    assignee: null,
    reminderTypes: [],
  },
};

const filterEmptyWorkflows = (isPrevFilterClear, isCurFilterClear) => {
  return isPrevFilterClear === isCurFilterClear
    ? {}
    : { filterEmptyWorkflows: Boolean(isPrevFilterClear) };
};

function reducer(state, action) {
  switch (action.type) {
    case "workflows":
      return { ...state, [action.type]: action.value };
    case "filters":
      return {
        ...state,
        [action.type]: {
          ...action.value,
          ...filterEmptyWorkflows(
            isEmptyFilter(state[action.type], true),
            isEmptyFilter(action.value, true)
          ),
        },
      };
    case "update-stream-filter":
    case "update-tag-filter":
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.value.type]: state.filters[action.value.type].map((s) => {
            return s.id === action.value.data.id ? action.value.data : s;
          }),
        },
      };
    case "remove-stream-filter":
    case "remove-tag-filter":
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.value.type]: state.filters[action.value.type].filter((s) => {
            return s.id !== action.value.data.id;
          }),
        },
      };
    case "add-workflow":
      return { ...state, workflows: [...state.workflows, action.value] };
    case "remove-workflow":
      return {
        ...state,
        workflows: state.workflows.filter((w) => {
          return w.id !== action.value.id;
        }),
      };
    case "swap-workflows":
      return {
        ...state,
        workflows: swap(state.workflows, action.value.src, action.value.des),
      };
    case "update-workflow":
      return {
        ...state,
        workflows: state.workflows.map((w) => {
          return w.id === action.value.id ? { ...w, ...action.value } : w;
        }),
      };
    case "cleanAll":
    default:
      return initState;
  }
}

export const useFilterState = () => {
  return React.useReducer(reducer, initState);
};

export function Provider({ children, value }) {
  return <FilterStore.Provider value={value}>{children}</FilterStore.Provider>;
}

Provider.propTypes = {
  value: PropTypes.array.isRequired,
  children: PropTypes.node,
};

Provider.defaultProps = {
  children: null,
};

export function useFilterContext() {
  return React.useContext(FilterStore);
}

export default Provider;
