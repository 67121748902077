import React from "react";

const featureKnowledge = new Map([
  [
    "workflow-menu-delete-button",
    new Map([
      ["0ebd0fe4-a7ff-48e9-b069-1eb6ce1bbaf6", "enabled"],
      ["65204991-d303-43b6-8789-74699b96fad3", "enabled"],
      ["86b0309b-3e2b-4f3f-a75e-346e5da2e95b", "enabled"],
      ["5ce95fb6-fd60-4313-94fc-447beeeb8295", "enabled"],
      ["05522987-4d09-4b19-a2b3-de149984d040", "enabled"],
      ["706c82c2-ffd3-49c1-b1af-41d83f2e5829", "enabled"],
      ["98086c66-2290-4063-8d9a-db1529412b4a", "enabled"],
      ["c36e9772-7754-4789-9c7b-888d5b2338a7", "enabled"],
      ["deb4e114-552f-433c-ac7d-adab375a293b", "enabled"],
      ["4ff7f982-a542-4b24-8696-d72eee39a6f7", "enabled"],
      ["6905678e-0edb-4c8c-942b-bc18e70b7a8d", "enabled"],
      ["4f496c55-55f9-4eec-ad46-5c3c621bf794", "enabled"],
      ["e029b0ce-a626-4d0f-ac07-54e925d52f77", "enabled"],
      ["57cc6de1-26ea-45b2-afa5-a44ae84f3381", "enabled"],
      ["5c95c8cc-7943-4c2a-b70c-39be46e6f262", "enabled"],
      ["7a58b270-e8c3-4f81-8f38-569182bf4618", "enabled"],
      ["6c01d098-e4bd-4b93-be5c-70fd177f2de7", "enabled"],
      ["b0e2c9cf-58c4-4e6f-947f-74e6a1f97eff", "enabled"],
      ["60ae7602-4b69-40c4-8ec2-6d9cd0cc59ca", "enabled"],
      ["5a65d5c4-62dc-437a-9a98-a0e9073bf610", "enabled"],
      ["c4483fde-8333-4dad-9535-6199b2df68d5", "enabled"],
      ["3edf9a42-9cd6-4c00-9c15-b9bfd1566b96", "enabled"],
      ["3bd1cc3c-a66b-4939-aa1d-ae2fe5da5b09", "enabled"],
      ["6f4ba2d3-8ff3-4161-89e1-24d772a525e7", "enabled"],
      ["5aeda3bb-4ab6-4202-ad1e-808e0a559198", "enabled"],
      ["99488708-7df7-4292-a824-f1f5c91ff7eb", "enabled"],
      ["e4423e1f-7cb7-45d6-87c2-87ae2246aa3d", "enabled"],
      ["7406703a-987f-4908-8bb8-06d0ac087f4d", "enabled"],
      ["8c02a2e6-c50d-4da2-ba01-b317bd4f4cf7", "enabled"],
      ["3987fb0b-13d9-46f6-b37f-91aa2718eb3b", "enabled"],
      ["068a0593-ee8d-43aa-a0d7-b7a187e7ba67", "enabled"],
      ["971a09d7-2fd4-44cd-8c7d-f7d07f354889", "enabled"],
      ["681ab6bb-d52b-4dd2-b7a1-a8d702f175c1", "enabled"],
      ["82d881f5-3bda-4f7b-9e07-079ebbb5d3e6", "enabled"],
      ["b00e848a-fe4e-4b3f-859e-d0fc3f4a6e78", "enabled"],
      ["fadb37d7-1a85-4395-af6c-068ff7df129a", "enabled"],
      ["dd8fdffb-782d-42b2-985c-07b8665a51da", "enabled"],
      ["b1734f8e-1360-4013-ba20-367e91e323dd", "enabled"],
      ["9e64c062-2e0a-4cd6-b401-359cb005749d", "enabled"],
      ["9ae0cb70-40d7-4914-8d7c-22b0d823ee71", "enabled"],
      ["6b73f59b-79dd-46cd-b993-8fe0408178e9", "enabled"],
      ["2761d9fb-5f0f-40ca-80ab-50a31c59c9ef", "enabled"],
      ["a0f6677c-d663-4079-a223-e08b4fbfe016", "enabled"],
      ["8ee50292-1a52-489c-b28f-c05c7ed84db3", "enabled"],
      ["5ae23b3d-66d2-4f01-aacc-6925761dd889", "enabled"],
      ["3b0340c5-2028-470d-998c-26c8de8eb6ae", "enabled"],
      ["2ce3655b-7cfd-4f42-bf30-4a52f9a6f1b6", "enabled"],
      ["50b6723d-5890-40d0-a475-c78cf169caba", "enabled"],
      ["8449f059-79d8-4c85-9c00-e7efe9a1f2b2", "enabled"],
      ["02834906-9253-411f-a7b8-44305c3e55d3", "enabled"],
      ["478f97fb-aa0c-41d5-88ea-71d6f60fae5a", "enabled"],
      ["72c0447f-62d2-410a-aaa8-3acd7067c9ad", "enabled"],
      ["1d48166c-507a-43bf-8368-9d6f70b110c3", "enabled"],
      ["a0ea1bf2-5cf1-4da9-8045-a6b1e04ab6f5", "enabled"],
      ["0b021bab-45a6-4ed3-86e0-265b825808fa", "enabled"],
      ["adaba583-6877-4897-9943-c12d792aa539", "enabled"],
      ["3dba85be-6dbf-4504-804f-f9901d25ebba", "enabled"],
      ["9b0dc187-e5bb-4034-afa1-63344ac34f01", "enabled"],
      ["0906ff23-1e9d-4f5e-acc8-2faa1dcdf32b", "enabled"],
      ["2989b17e-8dfb-478c-a582-9df2b4058fb9", "enabled"],
      ["fce4f2ca-969f-4cf3-8fad-4792351eae28", "enabled"],
      ["932d2e27-76e1-4da6-b4d7-27d0b7568533", "enabled"],
      ["5144d24e-ee73-4678-992b-f29a943ebd42", "enabled"],
      ["0b286ee2-c3de-49e6-ab10-0659490dd762", "enabled"],
      ["68928289-6903-4fce-a312-538f63f6b85e", "enabled"],
      ["1239eca1-d73f-43f7-8caf-d6bf49d4820e", "enabled"],
      ["a39feccd-832a-4bf1-9157-2b9e0fd0b633", "enabled"],
      ["02d1d603-07a1-428c-8594-211bba52a043", "enabled"],
      ["4d9a6427-492c-4101-baa3-26208114a8fa", "enabled"],
      ["11e5c169-f3c7-43b5-99ed-d5ad6f2660f8", "enabled"],
      ["c453c80e-bf28-473d-8ad0-8ece959267ba", "enabled"],
      ["a482e6a8-b349-423f-b939-72e4e33e519e", "enabled"],
      ["01fa07dd-c0a8-4691-9167-f5708f89ca78", "enabled"],
      ["2b71ee82-39b2-4a1e-b511-251b1860a7a1", "enabled"],
      ["cea49036-306d-44d6-91a3-1965abf22ecc", "enabled"],
      ["6d121374-0477-4798-9c3d-17b8e94ef8b3", "enabled"],
      ["2ef39149-19fa-4bec-9352-678040a287d9", "enabled"],
      ["b26dc86f-baf5-4515-97c0-a6550562b082", "enabled"],
      ["8c8ca263-27be-4a05-8395-cdb31c35f5ba", "enabled"],
      ["680978b3-0190-4171-9f7a-e340f112b161", "enabled"],
      ["2871257a-920f-41ed-bd4d-a9df1b87107e", "enabled"],
      ["f589baee-7892-4295-9278-a549db0a9aa2", "enabled"],
      ["8a14a2cb-754e-44ad-acd9-5ce06637980f", "enabled"],
      ["101933c3-eeae-441d-8c0b-c579cd18a3fd", "enabled"],
      ["98473c48-83d1-46a9-a9dd-e32bdbc98f47", "enabled"],
      ["09f5188c-4b6f-4340-ae8b-1ca8daac38fa", "enabled"],
      ["08eff4f1-ffda-48b8-b203-185904e9603c", "enabled"],
      ["f4422bd1-4cf7-4b24-b519-d74890a3e1f7", "enabled"],
      ["0c023c71-401a-40c6-9984-b31f571161bb", "enabled"],
      ["3f8ca783-fdc2-4a3d-a7f3-1d306b6f9373", "enabled"],
      ["034330ba-1793-44dc-8b08-53555bb176eb", "enabled"],
      ["others", "disabled"],
    ]),
  ],
]);

export const FeatureExperimentContext = React.createContext(featureKnowledge);

export default FeatureExperimentContext;
