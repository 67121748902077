import EnhancedTableHeader from "./EnhancedTableHeader";
import PaginationControl from "./PaginationControl";
import Table from "./Table";
import TableBody from "./TableBody";
import TableContent from "./TableContent";

Table.PaginationControl = PaginationControl;
Table.Content = TableContent;
Table.Header = EnhancedTableHeader;
Table.Body = TableBody;

export default Table;
