import React from "react";
import PropTypes from "prop-types";

export default function OptionList({ children, ...props }) {
  const style = {
    display: "grid",
    gridTemplateColumns: "auto auto auto auto",
    alignItems: "center",
    padding: "15px",
    gridRowGap: "1em",
  };

  return (
    <div style={style} {...props}>
      {children}
    </div>
  );
}

OptionList.propTypes = {
  children: PropTypes.node,
};

OptionList.defaultProps = {
  children: null,
};
