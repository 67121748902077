import { API } from "aws-amplify";

import { getAPIQuery, getAPIReq } from "./authLib";

export const GetClientUploadToken = async (id) => {
  const req = await getAPIReq();
  return API.get("HublyAPI", `/clientUploadTokens/${id}`, req);
};

export const CreateClientUploadToken = async (request) => {
  const req = await getAPIReq(request);
  return API.post("HublyAPI", `/clientUploadTokens/`, req);
};

export const DeleteClientUploadToken = async (id, request) => {
  const req = await getAPIReq(request);
  return API.del("HublyAPI", `/clientUploadTokens/${id}/`, req);
};

export const EditClientUploadToken = async (id, request) => {
  const req = await getAPIReq(request);
  return API.put("HublyAPI", `/clientUploadTokens/${id}/`, req);
};

export const ValidateClientUploadToken = async (query) => {
  const req = await getAPIQuery(query);
  return API.get("HublyAPI", `/clientUploadTokens/validate`, req);
};
