import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  errorMessage: {
    position: "absolute",
    color: "#9e3a38",
  },
});

function FieldErrorMessage({ message }) {
  const classes = useStyles();
  return <p className={classes.errorMessage}>{message}</p>;
}

FieldErrorMessage.propTypes = {
  message: PropTypes.string.isRequired,
};

export default FieldErrorMessage;
