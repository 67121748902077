import React from "react";
import PropTypes from "prop-types";

export const CannyInitChangelog = ({
  appId = "",
  position = "bottom",
  align = "right",
}) => {
  if (appId && typeof Canny === typeof Function) {
    // eslint-disable-next-line no-undef
    Canny("initChangelog", {
      appID: appId,
      position: position,
      align: align,
    });
  }
};
function CannyComponent({ appId, user, ...props }) {
  React.useEffect(() => {
    const { position, align } = props;
    const cannyUserObject = () => {
      const { id, email, name, avatarURL, created } = user;
      return {
        appID: appId,
        user: {
          id: id,
          email: email,
          name: name,
          avatarURL: avatarURL,
          created: created ? new Date(created).toISOString() : undefined,
        },
      };
    };

    if (appId) {
      // eslint-disable-next-line no-undef
      Canny("identify", cannyUserObject());
      CannyInitChangelog({ appId, position, align });
    } else if (process.env.REACT_APP_WARN_ENABLED) {
      console.warn(
        "Canny appId is not defined, but Canny was configured as enabled, " +
          "if you wanted to disable canny please define config.isEnabled = false in the app config"
      );
    }
  }, [appId, user, props]);

  return <React.Fragment />;
}

CannyComponent.propTypes = {
  appId: PropTypes.string.isRequired,
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    avatarURL: PropTypes.string,
    created: PropTypes.string,
  }),
  align: PropTypes.string,
  position: PropTypes.string,
};

CannyComponent.defaultProps = {
  user: {
    avatarURL: undefined,
    created: undefined,
  },
  align: "right",
  position: "bottom",
};

export default CannyComponent;
