import { EditAdvisor } from "data/libs/advisor";

export const SetAdvisor = (advisor) => {
  return {
    type: "SET_ADVISOR",
    advisor: advisor,
  };
};

export const UpdateAdvisor = (id, request, callback) => {
  return (dispatch, getState) => {
    EditAdvisor(id, request)
      .then((response) => {
        const advisor = { ...getState().hubly.data.advisor };
        if (callback) {
          callback(true);
        }

        Object.keys(request).forEach((key) => {
          advisor[key] = request[key];
        });
        dispatch(SetAdvisor(advisor));
      })
      .catch((error) => {
        if (callback) callback(false);
      });
  };
};

export default SetAdvisor;
