import React from "react";
import PropTypes from "prop-types";

import { Placeholder } from "semantic-ui-react";

import useSubscriptionInfo from "../../../../hooks/useSubscriptionInfo";

function SubscriptionInfoSection({ inviteCount }) {
  const { data, isEmpty, loading } = useSubscriptionInfo(inviteCount);

  const {
    billingPeriod,
    legacySeats,
    legacyCharges,
    regularSeats,
    regularPrice,
    seatsAvailable,
    currentCharges,
    seatsNeeded,
    newTotalCharges,
  } = data;

  if (loading) {
    return (
      <Placeholder
        style={{ display: "inline-block", float: "right", width: "500px" }}
      >
        <Placeholder.Line length="full" />
      </Placeholder>
    );
  }

  if (isEmpty) {
    return null;
  }

  return (
    <div data-test="subscription-information">
      <p style={{ display: "inline-block", float: "right" }}>
        <span style={seatsNeeded > 0 ? { color: "#5b5b5b" } : {}}>
          <span style={{ marginRight: "20px", textTransform: "capitalize" }}>
            Current {billingPeriod} Charge:
          </span>
          <strong>
            {legacySeats > 0 && (
              <span>
                {legacySeats} {`user${legacySeats !== 1 ? "s" : ""}`} $
                {legacyCharges}
              </span>
            )}
            {legacySeats > 0 && regularSeats > 0 && (
              <span style={{ margin: "0 10px" }}>+</span>
            )}
            {regularSeats > 0 && (
              <span>
                {regularSeats} x ${regularPrice}
              </span>
            )}
            <span style={{ margin: "0 10px" }}>=</span>
            <span>${currentCharges}</span>
          </strong>
        </span>
        {seatsNeeded > 0 && (
          <span style={{ marginLeft: "60px" }}>
            <span style={{ marginRight: "20px", textTransform: "capitalize" }}>
              New {billingPeriod} Charge:
            </span>
            <strong>
              {legacySeats > 0 && (
                <span>
                  {legacySeats} {`user${legacySeats !== 1 ? "s" : ""}`} $
                  {legacyCharges}
                </span>
              )}
              {legacySeats > 0 && seatsNeeded > 0 && (
                <span style={{ margin: "0 10px" }}>+</span>
              )}
              {seatsNeeded > 0 && (
                <span>
                  {seatsNeeded + regularSeats} x ${regularPrice}
                </span>
              )}
              <span style={{ margin: "0 10px" }}>=</span>
              <span>${newTotalCharges}</span>
            </strong>
          </span>
        )}
        {seatsNeeded <= 0 && (
          <span style={{ marginLeft: "60px" }}>
            <span style={{ marginRight: "20px" }}>Licenses Available:</span>
            <strong>
              <span>{seatsAvailable}</span>
            </strong>
          </span>
        )}
      </p>
    </div>
  );
}

SubscriptionInfoSection.propTypes = {
  inviteCount: PropTypes.number,
};
SubscriptionInfoSection.defaultProps = {
  inviteCount: 0,
};

export default SubscriptionInfoSection;
