import React from "react";
import PropTypes from "prop-types";

import { Dropdown, Placeholder } from "semantic-ui-react";

export function Selector({
  name,
  multiple,
  selection,
  selected,
  onOpen,
  onSelect,
  showLoadMore,
  loadMoreData,
  dropdownProps,
}) {
  const toOptionObj = ({ key, value, text = "", ...options }) => {
    return {
      key: key,
      value: key,
      text: text || value,
      ...options,
    };
  };

  const getDropdownPlaceholder = () => {
    return [
      {
        key: "loading",
        value: "loading",
        selected: false,
        content: (
          <Placeholder key="loading">
            <Placeholder.Header>
              <Placeholder.Line length="very long" />
              <Placeholder.Line length="very long" />
              <Placeholder.Line length="very long" />
            </Placeholder.Header>
          </Placeholder>
        ),
      },
    ];
  };

  const dropdownOptions = selection.map(toOptionObj);
  if (dropdownProps.loading) {
    dropdownOptions.push(...getDropdownPlaceholder());
  }

  return (
    <Dropdown
      data-test={`filter-menu-${String(name)
        .toLowerCase()
        .replaceAll(" ", "-")}`}
      fluid
      deburr
      search
      multiple={multiple}
      onScroll={({ target: { scrollHeight, clientHeight, scrollTop } }) => {
        if (
          showLoadMore &&
          !dropdownProps.loading &&
          scrollHeight - (scrollTop + clientHeight) <= 1
        ) {
          loadMoreData();
        }
      }}
      selection
      style={{
        marginTop: "1px",
        position: "relative",
        width: "calc(100%)",
      }}
      options={dropdownOptions}
      onChange={(_, { value }) => {
        onSelect(value);
      }}
      onOpen={onOpen}
      value={selected}
      {...dropdownProps}
    />
  );
}

Selector.propTypes = {
  name: PropTypes.string.isRequired,
  dropdownProps: PropTypes.object,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  selection: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  onSelect: PropTypes.func.isRequired,
  onOpen: PropTypes.func,
  multiple: PropTypes.bool,
  showLoadMore: PropTypes.bool,
  loadMoreData: PropTypes.func,
};

Selector.defaultProps = {
  dropdownProps: {},
  selected: [],
  selection: [],
  multiple: true,
  onOpen: () => {},
  loadMoreData: () => {},
  showLoadMore: false,
};

export default Selector;
