import React from "react";
import PropTypes from "prop-types";

import { Button, Header } from "semantic-ui-react";

import { useSync } from "data/hub/sync";

export function ClientSyncButton({ integrationType }) {
  const { runFullSync, syncInProgress } = useSync();

  return (
    <React.Fragment>
      <Button
        id="client-sync-button"
        data-test="client-sync-button"
        onClick={runFullSync}
        disabled={syncInProgress}
        style={{
          background: "#ffffff00",
          padding: 0,
          margin: 0,
          width: "100%",
          textAlign: "left",
        }}
      >
        <Header as="h5">
          <img
            style={{ filter: "brightness(0%)", width: 20 }}
            src={`/images/logos/${integrationType}-logo.png`}
            alt={`${integrationType} Logo`}
          />
          <span
            style={{
              marginLeft: "0.7em",
              position: "relative",
              top: "0.2em",
            }}
          >
            Sync with CRM
          </span>
        </Header>
      </Button>
    </React.Fragment>
  );
}

ClientSyncButton.propTypes = {
  integrationType: PropTypes.string.isRequired,
};

export default ClientSyncButton;
