import React from "react";
import PropTypes from "prop-types";

export const TotalSection = ({ total, label, icon, style, ...props }) => {
  return (
    <div
      style={{
        color: "black",
        fontWeight: "bold",
        flexGrow: 1,
        display: "flex",
        alignItems: "center",
        ...style,
      }}
      {...props}
    >
      {icon}
      <div style={{ marginLeft: "0.5em" }}>{`${total} ${label}${
        total > 1 ? "s" : ""
      }`}</div>
    </div>
  );
};

TotalSection.defaultProps = {
  style: {},
  total: 0,
  icon: undefined,
};

TotalSection.propTypes = {
  total: PropTypes.number,
  icon: PropTypes.node,
  label: PropTypes.string.isRequired,
  style: PropTypes.object,
};

export default TotalSection;
