import React, { useContext, useState } from "react";
import PropTypes from "prop-types";

import { Checkbox, Dropdown } from "semantic-ui-react";

import { stringToMonths } from "data/libs/taskConditions";

import { useForm } from "hooks";
import AddRemoveButton from "components/AddRemoveButton";
import { MultipleClientDropdown } from "components/ClientDropdown";
import Popper from "components/Popper";

import { useAccessTypeStore } from "scenes/Hubly/components/Workspace/components/hooks";
import {
  HubContext,
  ToasterContext,
} from "scenes/Hubly/components/Workspace/Provider";

import {
  conditionTypeOptions,
  getCombinedStreamsAndTags,
  mapTaskConditionData,
  monthOptions,
} from "../../helper";
import { TaskIcon } from "..";
import { ConditionLayout } from "./components";

export function TaskCondition({ task, onTaskConfigChange, ...props }) {
  const { addTaskCondition, editTaskCondition, deleteTaskCondition } =
    useContext(HubContext);
  const { fireError } = useContext(ToasterContext);
  const { hasAccess } = useAccessTypeStore();
  const [monthsCheckbox, showMonthsCheckbox] = useState(
    task?.taskCondition?.months !== null &&
      task?.taskCondition?.months !== undefined
  );

  const [popupOpen, setPopupOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const hasTaskCondition =
    task?.taskCondition !== null && task?.taskCondition !== undefined;
  const combinedStreamTags = getCombinedStreamsAndTags(task?.taskCondition);

  const { register, data, disableButton } = useForm({
    initialValue: {
      conditionType: task?.taskCondition?.conditionType
        ? task.taskCondition.conditionType
        : "ANY",
      months: task?.taskCondition?.months
        ? stringToMonths(task.taskCondition.months)
        : null,
      selectedStreamsTags: combinedStreamTags,
    },
    fieldValidator: ({ name, required, value, errortext }) => {
      if (name === "selectedStreamsTags" && required && value.length === 0)
        return errortext;
      return "";
    },
    formValidator: ({ selectedStreamsTags }) => {
      return selectedStreamsTags && selectedStreamsTags.length !== 0;
    },
  });

  const saveTaskCondition = async () => {
    const request = mapTaskConditionData(data);
    if (!task?.id) {
      onTaskConfigChange({ taskCondition: request });
      setPopupOpen(false);
    } else {
      try {
        setLoading(true);
        const resp = !hasTaskCondition
          ? await addTaskCondition(task.id, request)
          : await editTaskCondition(task.id, task.taskCondition.id, request);
        setPopupOpen(false);
        setLoading(false);
        onTaskConfigChange({ ...task, taskCondition: resp });
      } catch (error) {
        fireError("Failed to save task condition", error);
        setLoading(false);
        setPopupOpen(false);
      }
    }
  };

  const removeTaskCondition = () => {
    if (!task?.id) {
      onTaskConfigChange({ taskCondition: null });
      setPopupOpen(false);
    } else {
      setLoading(true);
      deleteTaskCondition(task.id, task.taskCondition.id)
        .then(() => {
          setPopupOpen(false);
          setLoading(false);
          onTaskConfigChange({ ...task, taskCondition: null });
        })
        .catch((error) => {
          fireError("Failed to delete task condition", error);
          setLoading(false);
          setPopupOpen(false);
        });
    }
  };

  return (
    <Popper
      data-test="task-condition-popup"
      on="click"
      position="bottom center"
      open={popupOpen}
      onOpen={() => {
        setPopupOpen(true);
      }}
      onClose={() => {
        setPopupOpen(false);
      }}
      trigger={
        <TaskIcon
          hasValue={hasTaskCondition}
          expanded={popupOpen}
          iconName="filter"
          iconNameOutline="filter"
          title="Task Condition"
          onClick={() => {}}
          visible={hasAccess() || hasTaskCondition}
        />
      }
    >
      <ConditionLayout
        isCreating={!hasTaskCondition}
        conditionSelector={
          <Dropdown
            {...register({ name: "conditionType" })}
            disabled={!hasAccess() || loading}
            options={conditionTypeOptions}
            selection
            compact
            style={{ margin: "0px 1em", minWidth: "70px" }}
          />
        }
        monthsCheckbox={
          <Checkbox
            disabled={!hasAccess() || loading}
            checked={monthsCheckbox}
            label="Only apply in certain months"
            onClick={() => {
              showMonthsCheckbox(!monthsCheckbox);
            }}
          />
        }
        streamTagSelector={
          <MultipleClientDropdown
            data-test="stream-tag-dropdown"
            {...register({
              name: "selectedStreamsTags",
              required: true,
              errortext: "Please select streams or tags",
            })}
            placeholder="Select streams or tags"
            showStreams
            showTags
            loading={loading}
            disabled={!hasAccess()}
            {...props}
          />
        }
        showMonthsSelector={monthsCheckbox}
        monthsSelector={
          <Dropdown
            {...register({ name: "months" })}
            className="dropdown_size_3"
            disabled={!hasAccess() || loading}
            fluid
            selection
            search
            multiple
            style={{ marginTop: "0.5em" }}
            placeholder="Select months in which condition applies"
            options={monthOptions}
          />
        }
      />
      <AddRemoveButton
        loading={loading}
        disabled={!hasAccess() || disableButton || loading}
        isCreating={!hasTaskCondition}
        onRemove={removeTaskCondition}
        onAdd={saveTaskCondition}
      />
    </Popper>
  );
}

TaskCondition.propTypes = {
  task: PropTypes.object,
  streamData: PropTypes.object,
  tagData: PropTypes.object,
  onTaskConfigChange: PropTypes.func.isRequired,
};

TaskCondition.defaultProps = {
  task: null,
  streamData: null,
  tagData: null,
};

export default TaskCondition;
