import PropTypes from "prop-types";

function RenderWhenAny({ conditions, fallback, children }) {
  return conditions
    .map((c) => {
      return c instanceof Function
        ? c
        : () => {
            return c;
          };
    })
    .some((p) => {
      return p();
    })
    ? children
    : fallback;
}

RenderWhenAny.propTypes = {
  conditions: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.bool, PropTypes.func])
  ),
  fallback: PropTypes.node,
  children: PropTypes.node.isRequired,
};

RenderWhenAny.defaultProps = {
  conditions: [],
  fallback: null,
};

export default RenderWhenAny;
