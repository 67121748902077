import React, { useContext, useState } from "react";
import PropTypes from "prop-types";

import Menu from "components/Menu";

import {
  HubContext,
  ToasterContext,
} from "scenes/Hubly/components/Workspace/Provider";

export function TaskPopup({
  id,
  workflowId,
  hasComment,
  isClientWorkflow,
  onTaskDeleted,
  onEditTask,
  onCommentTask,
}) {
  const { isCRMType, deleteTask } = useContext(HubContext);
  const { fireError } = useContext(ToasterContext);
  const [loading, setLoading] = useState(false);
  return (
    <Menu>
      {({ closeMenu }) => {
        return (
          <React.Fragment>
            {isClientWorkflow && !isCRMType("redtail") && (
              <Menu.Button
                icon={hasComment ? "comment" : "comment outline"}
                content="Add Comment"
                onClick={() => {
                  onCommentTask();
                  closeMenu();
                }}
              />
            )}
            <Menu.Button
              icon="align left"
              content="Edit Task Details"
              onClick={() => {
                onEditTask();
                closeMenu();
              }}
            />
            <Menu.Button
              icon="delete"
              loading={loading}
              content="Delete Task"
              onClick={() => {
                setLoading(true);
                deleteTask({
                  id: id,
                  workflowId: !isClientWorkflow ? workflowId : null,
                  clientWorkflowId: isClientWorkflow ? workflowId : null,
                })
                  .then(() => {
                    closeMenu();
                    onTaskDeleted(id);
                  })
                  .catch((error) => {
                    fireError("Failed to delete task", error);
                    setLoading(false);
                    closeMenu();
                  });
              }}
            />
          </React.Fragment>
        );
      }}
    </Menu>
  );
}

TaskPopup.defaultProps = {
  isClientWorkflow: false,
  hasComment: false,
};

TaskPopup.propTypes = {
  id: PropTypes.string.isRequired,
  workflowId: PropTypes.string.isRequired,
  isClientWorkflow: PropTypes.bool,
  hasComment: PropTypes.bool,
  onEditTask: PropTypes.func.isRequired,
  onTaskDeleted: PropTypes.func.isRequired,
  onCommentTask: PropTypes.func.isRequired,
};

export default TaskPopup;
