import React from "react";
import PropTypes from "prop-types";

import { Icon } from "semantic-ui-react";

export const TotalWorkflowSection = ({
  totalWorkflows,
  style,
  ...restProps
}) => {
  return (
    <div
      style={{
        color: "black",
        fontWeight: "bold",
        flexGrow: 1,
        display: "flex",
        alignItems: "center",
        ...style,
      }}
      {...restProps}
    >
      <Icon
        name="align left"
        style={{ fontSize: "15pt", transform: "rotate(90deg)" }}
      />
      <div style={{ marginLeft: "0.5em" }}>{`${totalWorkflows} Workflow${
        totalWorkflows > 1 ? "s" : ""
      }`}</div>
    </div>
  );
};

TotalWorkflowSection.defaultProps = {
  style: {},
};

TotalWorkflowSection.propTypes = {
  totalWorkflows: PropTypes.number.isRequired,
  style: PropTypes.object,
};

export default TotalWorkflowSection;
