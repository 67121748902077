import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { SetActiveClient } from "data/hub/clients/actions";
import { DeleteEvent } from "data/libs/events";

import { SetAlert } from "components/Alerts/actions";

import { AddNewEvent, EditEvent } from "./actions";
import AddEventButton from "./AddEventButton";
import AddNewEventPopup from "./AddNewEventPopup";
import EditableInlineEvent from "./EditableInlineEvent";
import { getFormattedDate } from "./helpers";
import MeetingDateField from "./MeetingDateField";

class EventField extends React.Component {
  static propTypes = {
    client: PropTypes.shape({
      id: PropTypes.string.isRequired,
      events: PropTypes.array.isRequired,
    }).isRequired,
    event: PropTypes.shape({
      id: PropTypes.string.isRequired,
      clientId: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
    }).isRequired,
    fieldType: PropTypes.bool,
    setActiveClient: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired,
    setNestedComponentOpen: PropTypes.func,
    title: PropTypes.string,
    type: PropTypes.string,
    addEvent: PropTypes.func.isRequired,
    editEvent: PropTypes.func.isRequired,
  };

  static defaultProps = {
    fieldType: "",
    setNestedComponentOpen: () => {},
    title: "",
    type: "MEETING",
  };

  constructor(props) {
    super(props);
    const eventDate = props.event ? new Date(props.event.date) : null;
    this.state = {
      currentDate: props.fieldType.startsWith("ADDABLE")
        ? this.getInitialDate()
        : eventDate,
      loading: false,
      pickerOpen: false,
    };
  }

  addEvent = async (meetingDateTypeId) => {
    const { currentDate } = this.state;
    const { addEvent, client, type } = this.props;
    const request = {
      clientId: client.id,
      date: currentDate?.toISOString() || new Date(currentDate)?.toISOString(),
      type: type,
      meetingDateTypeId: meetingDateTypeId,
    };

    this.setState({ loading: true });
    await addEvent(client, request);
    this.setState({
      currentDate: this.getInitialDate(),
      loading: false,
      pickerOpen: false,
    });
  };

  deleteEvent = () => {
    const { client, event, setActiveClient, setAlert, setNestedComponentOpen } =
      this.props;
    this.setState({ loading: true });
    DeleteEvent(event.id)
      .then((response) => {
        const clientCopy = { ...client };
        const foundEventIndex = clientCopy.events.findIndex((e) => {
          return e.id === event.id;
        });
        if (foundEventIndex < 0) {
          console.warn(
            `Could not find event to delete with index: ${foundEventIndex}`
          );
          this.setState({ loading: false });
          setNestedComponentOpen(false);
          return;
        }
        clientCopy.events.splice(foundEventIndex, 1);
        setActiveClient(clientCopy);
        this.setState({ currentDate: null, loading: false, pickerOpen: false });
        setNestedComponentOpen(false);
      })
      .catch((error) => {
        setAlert({ type: "error", text: `Failed to delete meeting.` });
        this.setState({ loading: false });
      });
  };

  editEvent = async (meetingDateTypeId) => {
    const { currentDate } = this.state;
    const { client, event, editEvent, setNestedComponentOpen, type } =
      this.props;
    const request = {
      clientId: event.clientId,
      date: currentDate?.toISOString() || new Date(currentDate)?.toISOString(),
      type: type,
      meetingDateTypeId: meetingDateTypeId,
    };

    this.setState({ loading: true });
    const response = await editEvent(client, event, request);
    this.setState({
      currentDate: new Date(response.date),
      loading: false,
      pickerOpen: false,
    });
    setNestedComponentOpen(false);
  };

  getInitialDate = () => {
    // Need to round up to nearest 15 minute increments
    const minutesInMS = 1000 * 60 * 15;
    return new Date(Math.ceil(new Date() / minutesInMS) * minutesInMS);
  };

  handleClose = () => {
    this.setState({ pickerOpen: false });
  };

  handleDateChange = (date) => {
    this.setState({ currentDate: date });
  };

  render() {
    const { event, fieldType, setNestedComponentOpen, title } = this.props;
    const { currentDate, loading, pickerOpen } = this.state;
    const eventDate = event ? new Date(event.date) : null;
    const date = fieldType.startsWith("ADDABLE") ? currentDate : eventDate;
    const formattedDate = getFormattedDate(date);

    switch (fieldType) {
      case "ADDABLE_POPUP":
        return (
          <AddNewEventPopup
            title={title}
            pickerOpen={pickerOpen}
            setPickerOpen={(value) => {
              this.setState({ pickerOpen: value });
            }}
            currentDate={currentDate}
            setCurrentDate={(newCurrentDate) => {
              this.setState({ currentDate: newCurrentDate });
            }}
            loading={loading}
            addEvent={this.addEvent}
          />
        );
      case "ADDABLE_BUTTON":
        return (
          <AddEventButton
            addEvent={this.addEvent}
            currentDate={currentDate}
            setCurrentDate={(newCurrentDate) => {
              this.setState({ currentDate: newCurrentDate });
            }}
          />
        );
      case "EDITABLE_INLINE":
        return (
          <EditableInlineEvent
            title={title}
            event={event}
            setNestedComponentOpen={setNestedComponentOpen}
            currentDate={currentDate}
            setCurrentDate={(newCurrentDate) => {
              this.setState({ currentDate: newCurrentDate });
            }}
            editEvent={this.editEvent}
            deleteEvent={this.deleteEvent}
          />
        );
      case "MEETING":
        return <MeetingDateField title={title} event={event} />;
      default:
        return (
          <div
            style={{
              display: "flex",
              fontWeight: "normal",
              height: "20px",
              marginBottom: "0.5em",
            }}
          >
            {title && (
              <span
                style={{ fontWeight: "700", marginRight: "0.5em" }}
              >{`${title}:`}</span>
            )}
            <span
              style={{ color: formattedDate === "None" ? "grey" : "black" }}
            >
              {formattedDate}
            </span>
          </div>
        );
    }
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = {
  setAlert: SetAlert,
  setActiveClient: SetActiveClient,
  addEvent: AddNewEvent,
  editEvent: EditEvent,
};

export default connect(mapStateToProps, mapDispatchToProps)(EventField);
