import { combineReducers } from "redux";

import { reducer as integrationsReducer } from "./components/Integrations/reducer";
import { reducer as policyReducer } from "./components/PrivacyPolicy/reducer";

export const reducer = combineReducers({
  integrations: integrationsReducer,
  policy: policyReducer,
});
export default reducer;
