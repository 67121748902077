import { useState } from "react";

import { useForm, useMutationWithToaster } from "hooks";

import toGlobalId from "scenes/Hubly/components/Workspace/api/helper";

const updateUserInfoMutationFactory = () => {
  const query = `
    mutation UpdateAdvisorMutation($id: ID!, $firstName: String, $lastName: String, $roleId: ID) {
      updateAdvisor(id: $id, firstName: $firstName, lastName: $lastName, roleId: $roleId) {
        advisor {
          id
        }
      }
    }
`;

  const resolver = ({ errors, data }) => {
    if (errors && errors.length) {
      return { data: null, errors: errors };
    }
    return { data: data };
  };

  return { query, resolver };
};

const useUpdateUserInformation = (userId, initialData, onCloseCb) => {
  const [showLastAdminWarning, setShowLastAdminWarning] = useState(false);
  const [initialValues] = useState({
    ...initialData,
    roleId: toGlobalId("Role", initialData.roleId),
  });
  const { data, disableButton, register } = useForm({
    initialValue: initialValues,
    fieldValidator: () => {
      return "";
    },
    formValidator: ({ firstName = "", lastName = "", roleId = "" }) => {
      return firstName.trim().length && lastName.trim().length && roleId.length;
    },
  });

  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [submit, { loading }] = useMutationWithToaster(
    updateUserInfoMutationFactory,
    {
      onDone: async () => {
        setIsSubmitSuccess(true);
      },
      onError: (err) => {
        if (err[0].message.startsWith("No Admin In Practice")) {
          setShowLastAdminWarning(true);
        }
      },
    }
  );

  const onSubmit = async () => {
    const updatedFields = Object.keys(data).reduce((prev, key) => {
      if (data[key] !== initialValues[key]) {
        return { ...prev, [key]: data[key] };
      }
      return prev;
    }, {});

    if (Object.keys(updatedFields).length) {
      await submit(
        { ...updatedFields, id: toGlobalId("Advisor", userId) },
        {
          successMsg: "User information successfully updated",
          defaultErrorMsg: "Failed to update User Information",
        }
      );
      return;
    }
    onCloseCb();
  };

  return {
    loading,
    showLastAdminWarning,
    disableButton,
    isSubmitSuccess,
    register,
    setShowLastAdminWarning,
    onSubmit,
  };
};

export default useUpdateUserInformation;
