export const initialState = {
  confirmationParams: null,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_CONFIRMATION_MODAL":
      return {
        ...state,
        confirmationParams: action.confirmationParams,
      };
    default:
      return state;
  }
};
export default reducer;
