import React from "react";
import PropTypes from "prop-types";
import { range } from "lodash";
import { v4 } from "uuid";

import { Grid, Placeholder } from "semantic-ui-react";

export function TablePlaceholder({ numOfColumn, numOfRow }) {
  return (
    <Grid columns={numOfColumn} data-test="placeholder">
      {range(numOfRow).map(() => {
        return (
          <Grid.Row key={v4()}>
            {range(numOfColumn).map(() => {
              return (
                <Grid.Column key={v4()}>
                  <Placeholder>
                    <Placeholder.Header>
                      <Placeholder.Line length="very long" />
                    </Placeholder.Header>
                  </Placeholder>
                </Grid.Column>
              );
            })}
          </Grid.Row>
        );
      })}
    </Grid>
  );
}

TablePlaceholder.propTypes = {
  numOfColumn: PropTypes.number,
  numOfRow: PropTypes.number,
};

TablePlaceholder.defaultProps = {
  numOfColumn: 4,
  numOfRow: 3,
};

export default TablePlaceholder;
