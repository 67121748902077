import React from "react";
import PropTypes from "prop-types";

import FilterLayout from "components/FilterLayout";
import Selector from "components/Selector";

import { useAssigneeOptions } from "scenes/Hubly/components/Workspace/components/Assigner/components";

import { useFilterProcessor } from "./hooks";

export function AssigneeFilters({
  assignee,
  assigneeFilterType,
  onUpdateFilter,
  loadingAssignees,
  assignees,
}) {
  const { toFilter, fromFilter } = useFilterProcessor();
  const assigneeOptions = useAssigneeOptions(assignees);
  const options = [
    {
      key: "ALL",
      value: "ALL",
      text: "Any User",
      icon: "user circle outline",
    },
    {
      key: "UNASSIGNED",
      value: "UNASSIGNED",
      text: "Unassigned",
      icon: "user circle outline",
    },
    {
      key: "CURRENT",
      value: "CURRENT",
      text: "Current User",
      icon: "user circle outline",
    },
  ].concat(assigneeOptions);

  if (!loadingAssignees && assignees.length < 2) {
    return null;
  }

  return (
    <FilterLayout
      title="Assignee"
      onClearFilter={() => {
        onUpdateFilter({
          assigneeFilterType: AssigneeFilters.defaultProps.assigneeFilterType,
          assignee: null,
        });
      }}
    >
      <Selector
        name="Assignee"
        dropdownProps={{
          placeholder: "Select assignee",
          loading: loadingAssignees,
        }}
        multiple={false}
        selected={
          options.find(({ key }) => {
            return key === fromFilter(assignee, assigneeFilterType);
          })?.key || "ALL"
        }
        onSelect={(selectedKey) => {
          const { id, group } = assignees.find(({ id: assigneeId }) => {
            return assigneeId === selectedKey;
          }) || { id: selectedKey };

          onUpdateFilter({
            ...toFilter(id, group),
          });
        }}
        selection={options}
      />
    </FilterLayout>
  );
}

AssigneeFilters.propTypes = {
  assigneeFilterType: PropTypes.string,
  assignee: PropTypes.string,
  onUpdateFilter: PropTypes.func.isRequired,
  loadingAssignees: PropTypes.bool,
  assignees: PropTypes.array,
};

AssigneeFilters.defaultProps = {
  assignee: "",
  assigneeFilterType: "ALL",
  loadingAssignees: false,
  assignees: [],
};

export default AssigneeFilters;
