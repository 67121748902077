export const initialState = {
  set_new_user_open: false,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_NEW_USER_MODAL":
      return {
        ...state,
        set_new_user_open: action.set_new_user_open,
      };
    default:
      return state;
  }
};
export default reducer;
