const getClientsByServiceTeam = ({ serviceTeamId, pageSize = 25, page }) => {
  const query = `query getClientsByServiceTeam {
    clients(serviceTeam: "${
      ("ServiceTeam", serviceTeamId)
    }", first: ${pageSize}, offset: ${page * pageSize}) {
      totalCount
      edges {
        node {
          id
          name
          relationship
          email
          serviceTeam {
            name
          }
          household {
            name
          }
          streams {
            edges {
              node {
                name
                color
              }
            }
          }
          tags {
            edges {
              node {
                name
              }
            }
          }
        }
      }
    }
  }`;

  const resolver = ({ data: { clients } }) => {
    return {
      clients: clients.edges.map(({ node }) => {
        return {
          ...node,
          serviceTeam: node.serviceTeam?.name || "",
          household: node.household?.name || "",
          relationship: node.relationship || "",
          streams: node.streams.edges.map(({ node: streamNode }) => {
            return {
              ...streamNode,
            };
          }),
          tags: node.tags.edges.map(({ node: tagNode }) => {
            return { ...tagNode };
          }),
        };
      }),
      totalCount: clients.totalCount,
    };
  };

  return { query, resolver };
};

export default getClientsByServiceTeam;
