import { API } from "aws-amplify";

import SyncedAPI from "data/libs/syncedAPI";

import { getAPIReq } from "./authLib";

export const GetViews = async (hub) => {
  const req = await getAPIReq();
  return API.get("HublyAPI", `/hubs/${hub.id}/views/`, req);
};

export const GetView = async (id) => {
  const req = await getAPIReq();
  return API.get("HublyAPI", `/views/${id}/`, req);
};

export const CreateView = async (request) => {
  const req = await getAPIReq(request);
  return SyncedAPI.post("HublyAPI", `/views/`, req);
};

export const EditView = async (id, request) => {
  const req = await getAPIReq(request);
  return SyncedAPI.put("HublyAPI", `/views/${id}/`, req);
};

export const DeleteView = async (id) => {
  const req = await getAPIReq();
  return SyncedAPI.del("HublyAPI", `/views/${id}/`, req);
};

export const BulkUpdateViews = async (request) => {
  const req = await getAPIReq(request);
  return SyncedAPI.put("HublyAPI", `/views/bulkUpdate/`, req, (response) => {
    return { type: "BULK_UPDATE_VIEWS", views: response };
  });
};
