export const initialState = {
  isFilterUpdated: false,
  assignee: "ALL",
  filterEmptyWorkflows: false,
  processes: [],
  streams: [],
  streamFilterType: "ANY",
  tags: [],
  tagFilterType: "ANY",
  reminderTypes: [],
  clientFilter: [],
  filterWaitingOnClient: { isFilterOn: false, waitingOnClient: undefined },
  workflows: null, // We won't know workflows initially, until either a view is set or no view is set and will need to check for null
  remindersVisibilityFilter: { type: "CURRENT_USER", value: "CURRENT_USER" },
  setManuallyFilterEmptyWorkflows: false,
  filterNextTaskForAssignee: false,
};

const areAllMenuFiltersClear = (filters) => {
  const { assignee, streams, tags, reminderTypes, clientFilter } = filters;

  return (
    assignee === "ALL" &&
    streams?.length === 0 &&
    tags?.length === 0 &&
    reminderTypes?.length === 0 &&
    clientFilter?.length === 0
  );
};

export const reducer = (state = initialState, action) => {
  if (action.type === "SET_REMINDERS_VISIBILITY_FILTER") {
    return {
      ...state,
      remindersVisibilityFilter: action.remindersVisibilityFilter,
    };
  } else if (action.type === "SET_MANUALLY_EMPTY_WORKFLOWS") {
    return {
      ...state,
      setManuallyFilterEmptyWorkflows: action.setManuallyFilterEmptyWorkflows,
    };
  } else if (action.filters && action.type === "SET_FILTERS") {
    const filtersCopy = action.applyState
      ? action.applyState(state)
      : { ...action.filters };

    const waitingOnClient = filtersCopy.reminderTypes.filter((r) => {
      return r.includes("WaitingOnClient");
    });
    filtersCopy.filterWaitingOnClient = {
      isFilterOn: waitingOnClient.length === 1,
      waitingOnClient:
        waitingOnClient.length === 1
          ? !waitingOnClient[0].includes("Not")
          : undefined,
    };

    if (action.filterCategory === "MENU_FILTERS") {
      if (areAllMenuFiltersClear(filtersCopy)) {
        filtersCopy.filterEmptyWorkflows = false;
        filtersCopy.setManuallyFilterEmptyWorkflows = false;
      } else if (!state.setManuallyFilterEmptyWorkflows) {
        filtersCopy.filterEmptyWorkflows = true;
      }
    }
    filtersCopy.isFilterUpdated = action.isFilterUpdated;
    if (filtersCopy.assignee === "ALL") {
      filtersCopy.filterNextTaskForAssignee = false;
    }
    return { ...state, ...filtersCopy };
  } else if (action.type === "RESET_FILTERS") {
    return {
      ...state,
      isFilterUpdated: false,
      filterEmptyWorkflows: false,
      setManuallyFilterEmptyWorkflows: false,
      processes: [],
      streams: [],
      streamFilterType: "ANY",
      tags: [],
      tagFilterType: "ANY",
      workflows: [],
      assignee: "ALL",
      reminderTypes: [],
      clientFilter: [],
      filterNextTaskForAssignee: false,
    };
  } else {
    return state;
  }
};

export default reducer;
