import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { findIndex, uniqBy } from "lodash";

import { Button, Form, Header, Input } from "semantic-ui-react";

import { SetAdvisor } from "data/advisor/actions";
import { CreatePractice, EditPractice } from "data/libs/practices";

import Access from "components/Access";
import { SetAlert } from "components/Alerts/actions";

class PracticeInformationForm extends Component {
  static propTypes = {
    advisor: PropTypes.shape({
      id: PropTypes.string.isRequired,
      practices: PropTypes.array.isRequired,
      role: PropTypes.object,
    }).isRequired,
    setAdvisor: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired,
    selectedHub: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    const { advisor, selectedHub } = this.props;
    const practice = advisor.practices.find(({ id }) => {
      return id === selectedHub?.practice;
    });

    this.state = {
      theHubPractice: practice,
      practiceName: practice?.name || "",
      primaryContact: practice?.primaryContact || "",
    };
  }

  handleChange = (e, { name, value }) => {
    // Form values stored in camelCase
    return this.setState({ [name]: value });
  };

  save = () => {
    const { advisor, setAdvisor, setAlert } = this.props;
    const advisorCopy = { ...advisor };
    const practice = this.state.theHubPractice;

    const practiceRequest = {
      name: this.state.practiceName,
      primaryContact: this.state.primaryContact,
    };
    if (practice) {
      EditPractice(practice.id, practiceRequest)
        .then((response) => {
          const i = findIndex(advisorCopy.practices || [], ({ id }) => {
            return id === practice.id;
          });
          if (i > -1) {
            advisorCopy.practices[i] = response;
            setAdvisor(advisorCopy);
            setAlert({
              type: "success",
              text: "Successfully saved practice information",
            });
          } else {
            console.error("Unexpected Index", i, advisorCopy.practices);
          }
        })
        .catch((error) => {
          console.error(error);
          setAlert({
            type: "error",
            text: "Failed to save practice information",
          });
        });
    } else {
      CreatePractice(practiceRequest)
        .then((response) => {
          advisorCopy.practices.push(response);
          setAdvisor(advisorCopy);
          setAlert({
            type: "error",
            text: "Successfully saved practice information",
          });
        })
        .catch((error) => {
          console.error(error);
          setAlert({
            type: "error",
            text: "Failed to save practice information",
          });
        });
    }
  };

  render() {
    const { advisor, selectedHub } = this.props;
    const { practiceName, primaryContact } = this.state;

    const practice = advisor.practices.find((p) => {
      return p.id === selectedHub.practice;
    });

    if (!practice) {
      return (
        <div style={{ marginTop: "1em" }}>
          <div style={{ display: "flex" }}>
            <Header as="h1" style={{ marginBottom: "0" }}>
              Practice Information
            </Header>
          </div>
          <div style={{ marginTop: "1em" }}>
            <span>Practice not found for the user</span>
          </div>
        </div>
      );
    }

    const advisorOptions = uniqBy(practice?.advisors, "id").map(
      ({ id, firstName, lastName }) => {
        return {
          key: id,
          text: `${firstName} ${lastName}`,
          value: `${firstName} ${lastName}`,
        };
      }
    );

    // If the primary contact is set to a name, and that name doesn't exist in the dropdown options, add it!
    if (
      !advisorOptions.find((optionAdvisor) => {
        return optionAdvisor.text === advisor.practices[0].primaryContact;
      })
    ) {
      if (advisor.practices[0].primaryContact) {
        advisorOptions.push({
          key: 1,
          text: advisor.practices[0].primaryContact,
          value: advisor.practices[0].primaryContact,
        });
      }
    }

    return (
      <div style={{ marginTop: "1em" }}>
        <div style={{ display: "flex" }}>
          <Header as="h1" style={{ marginBottom: "0" }}>
            Practice Information
          </Header>
          <Access required={["Administrator"]}>
            <Button
              color="green"
              content="Save Changes"
              onClick={this.save}
              style={{ float: "right", marginLeft: "1em" }}
            />
          </Access>
        </div>
        <Form style={{ marginTop: "1em" }}>
          <Form.Group>
            <Form.Field
              key="practice-name"
              style={{ flexGrow: 1, maxWidth: "300px" }}
            >
              <Header as="h3">Practice Name</Header>
              <Input
                required
                fluid
                name="practiceName"
                onChange={this.handleChange}
                placeholder="Practice Name"
                value={practiceName}
                disabled={advisor.role.title !== "Administrator"}
              />
            </Form.Field>
            <Form.Field
              key="primary-contact"
              style={{ flexGrow: 1, maxWidth: "300px" }}
            >
              <Header as="h3">Primary Contact</Header>
              <Form.Select
                clearable
                name="primaryContact"
                onChange={this.handleChange}
                options={advisorOptions}
                placeholder="Primary Contact"
                value={primaryContact}
                disabled={advisor.role.title !== "Administrator"}
              />
            </Form.Field>
          </Form.Group>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    advisor: state.hubly.data.advisor,
    selectedHub: state.hubly.data.hub.selected.hub,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAdvisor: (advisor) => {
      dispatch(SetAdvisor(advisor));
    },
    setAlert: (alert) => {
      dispatch(SetAlert(alert));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PracticeInformationForm);
