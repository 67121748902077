import React from "react";
import PropTypes from "prop-types";

import { Card } from "semantic-ui-react";

export function HubCard({ name, type, onClick }) {
  return (
    <Card className="grey_on_hover p25" onClick={onClick}>
      <Card.Content>
        <Card.Header textAlign="center">{name}</Card.Header>
        <Card.Description textAlign="center">
          Clients managed with&nbsp;
          {(type || "UNDEFINED").toUpperCase()}
        </Card.Description>
      </Card.Content>
    </Card>
  );
}

HubCard.propTypes = {
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  type: PropTypes.string,
};

HubCard.defaultProps = {
  type: undefined,
};

export default HubCard;
