import { EditAdvisor } from "data/libs/advisor";
import { CreateFilter, EditFilter } from "data/libs/filters";
import {
  BulkUpdateViews,
  CreateView,
  DeleteView,
  EditView,
} from "data/libs/views";

export function createView(filters, request) {
  return CreateFilter(filters).then((resp) => {
    return CreateView({ filterId: resp.id, ...request });
  });
}

export const editView = EditView;
export const deleteView = DeleteView;
export const editFilter = EditFilter;

export const updateDefaultView = (advisorId, viewId) => {
  return EditAdvisor(advisorId, { defaultView: viewId });
};

export const mapViewToDraggableTileProps = (view = {}) => {
  const {
    name,
    color,
    description,
    numActiveClientWorkflows,
    numActiveClients,
    numRed,
    numYellow,
    numGreen,
  } = view;

  return {
    type: "view",
    title: name,
    color: color,
    description: description,
    totalWorkflow: numActiveClientWorkflows,
    totalClients: numActiveClients,
    totalUpcoming: numGreen,
    totalComingDue: numYellow,
    totalOverdue: numRed,
    workflowLabel: "Active Workflow",
    clientLabel: "Active Client",
  };
};

export function reOrderView({ items }) {
  const maxOrder = items.reduce((max, currentView) => {
    return currentView.order > max ? currentView.order : max;
  }, 0);
  return BulkUpdateViews({
    views: items.map(({ id }, i) => {
      return {
        id: id,
        order: maxOrder + i + 1,
      };
    }),
  });
}

export default editView;
