import React from "react";
import PropTypes from "prop-types";

import { Icon } from "semantic-ui-react";

import Tooltip from "components/Tooltip";

const composeTooltipContent = (roleTitle) => {
  return (
    <div style={{ marginBottom: "10px" }}>
      <p style={{ marginBottom: "5px", fontWeight: "bold" }}>
        {roleTitle === "Administrator" ? "Admins can:" : "Users cannot:"}
      </p>
      <ul
        style={{
          margin: 0,
          paddingLeft: "20px",
        }}
      >
        <li>Manage users &#38; admin permissions</li>
        <li>Edit all Workflow settings</li>
        <li>Manage User Roles &#38; Service Teams</li>
        <li>Manage Important Client Dates</li>
        <li>Remove clients from Workflows</li>
        <li>Create new Meeting Date types</li>
        <li>Manage Hub settings</li>
        <li>Edit or remove a Process or View</li>
      </ul>
    </div>
  );
};

function PermissionTooltip({ roleTitle }) {
  return (
    <Tooltip position="top center" title={composeTooltipContent(roleTitle)}>
      <Icon color="grey" name="info circle" />
    </Tooltip>
  );
}

PermissionTooltip.propTypes = {
  roleTitle: PropTypes.string,
};

PermissionTooltip.defaultProps = {
  roleTitle: "",
};

export default PermissionTooltip;
