import React from "react";
import PropTypes from "prop-types";

import { QueryClientContextProvider } from "./Context";

export function QueryClientProvider({ clientFactory, children }) {
  return (
    <QueryClientContextProvider value={clientFactory()}>
      {children}
    </QueryClientContextProvider>
  );
}

QueryClientProvider.propTypes = {
  clientFactory: PropTypes.func.isRequired,
  children: PropTypes.node,
};

QueryClientProvider.defaultProps = {
  children: null,
};

export default QueryClientProvider;
