import { Auth } from "aws-amplify/lib/index";

import config from "../../config";
import {
  SetUnsyncedUpdates,
  SetWebsocketConnection,
} from "../websockets/actions";

class WebsocketManager {
  webSocket = null;

  hubId = null;

  onOpen = (dispatch, getState) => {
    return (event) => {
      this.webSocket = event.target;
      dispatch(SetWebsocketConnection(true));
      const { hub } = getState().hubly.data.hub.selected;
      if (hub) {
        const { hubId } = hub;
        setTimeout(() => {
          this.setWebsocketHubId(hubId);
        }, 3000); // wait 3s then set hub ID
      }
    };
  };

  onClose = (dispatch, getState) => {
    return (event) => {
      this.webSocket = null;
      this.hubId = null;
      console.warn("Websocket Closed");
      dispatch(SetWebsocketConnection(false));
      setTimeout(() => {
        this.connect(dispatch, getState);
      }, 10000); // reconnect after 10 seconds
    };
  };

  onMessage = (dispatch, getState) => {
    return (event) => {
      const payload = JSON.parse(event.data);
      switch (payload.type) {
        case "sync":
          this.recievedSync(payload, dispatch, getState);
          break;
        default:
          console.warn("Unrecognised WebSocket Message Received:", payload);
          break;
      }
    };
  };

  connect = (dispatch, getState) => {
    if (this.webSocket !== null) {
      return;
    }

    Auth.currentSession()
      .then((user) => {
        try {
          const jwt = user.idToken.jwtToken;
          const url = `${config.websocketGateway.URL}?token=${jwt}`;
          const webSocket = new WebSocket(url);

          webSocket.onmessage = this.onMessage(dispatch, getState);
          webSocket.onclose = this.onClose(dispatch, getState);
          webSocket.onopen = this.onOpen(dispatch, getState);
        } catch (error) {
          clearInterval(this.reconnectInterval);
          console.error("Failed to create websocket connection");
          console.error(error);
        }
      })
      .catch((error) => {
        console.error(
          "Failed to load user authentication -> cannot open socket connection"
        );
        console.error(error);
      });
  };

  disconnect = () => {
    if (this.webSocket !== null) {
      this.webSocket.close();
    }
    this.webSocket = null;
  };

  setWebsocketHubId = (id, force = false) => {
    if (!this.webSocket || (id === this.hubId && !force)) return; // No need to set id
    this.send({
      type: "set_hub",
      hubId: id,
    });
    this.hubId = id;
  };

  sendSync = (sync) => {
    this.send({
      type: "sync",
      sync: sync,
    });
  };

  send = (message) => {
    if (this.webSocket) {
      this.webSocket.send(JSON.stringify(message));
    }
  };

  recievedSync = (payload, dispatch, getState) => {
    if (payload.sync) {
      try {
        payload.sync.forEach((s) => {
          dispatch(s);
        });
      } catch (error) {
        console.error(error);
        this.generalUpdate(dispatch, getState);
      }
    } else {
      this.generalUpdate(dispatch, getState);
    }
  };

  generalUpdate = (dispatch, getState) => {
    const { unsyncedUpdates } = getState().hubly.data.websockets;
    dispatch(SetUnsyncedUpdates(unsyncedUpdates + 1));
  };
}

export default WebsocketManager;
