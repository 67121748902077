import PropTypes from "prop-types";

import useFeatures from "./useFeatures";

export function FeatureFlag({ id, children, enableOnMissing, showOnDisabled }) {
  const isFeatureEnabled = useFeatures(id, enableOnMissing);

  const [c, n] = showOnDisabled ? [null, children] : [children, null];
  return isFeatureEnabled ? c : n;
}

FeatureFlag.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node,
  enableOnMissing: PropTypes.bool,
  showOnDisabled: PropTypes.bool,
};

FeatureFlag.defaultProps = {
  children: null,
  enableOnMissing: false,
  showOnDisabled: false,
};

export default FeatureFlag;
