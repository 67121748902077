import React, { useContext, useState } from "react";
import PropTypes from "prop-types";

import { Dropdown } from "semantic-ui-react";

import { useSync } from "data/hub/sync";

import { useDelay } from "hooks";
import PrivacyModeContext from "components/PrivacyMode/Context";

import { CreateClientModal } from "scenes/Hubly/components/Workspace/components/WorkflowMenu/components";
import { HubContext } from "scenes/Hubly/components/Workspace/Provider";

import {
  DropdownSectionWithSearchMode,
  PlaceholderIcon,
  RecentContactsSection,
} from "./components";
import {
  clientOptionProps,
  createDropdownItemSectionProps,
  recordCustomAction,
  streamOptionProps,
  tagOptionProps,
} from "./helper";

export function ClientDropdown({
  opened,
  recentClientData,
  clientsData,
  streamsData,
  tagsData,
  placeholder,
  onSearchChange,
  onClientClick,
  onStreamOrTagClick,
  icon,
  searchPlaceholder,
  dropdownProps,
  selectedItemValues,
  defaultSection,
  closeOnClick,
  initialSearchInputValue,
  showSelectionValueOnClick,
  integrationButtonText,
}) {
  const context = useContext(PrivacyModeContext);
  const { piiMask } = context;

  const {
    integration,
    fetchClientsWithStreamsInStream,
    fetchClientsWithTagsInTag,
    fetchStreamClientHouseholdCount,
    fetchTagclientHouseholdCount,
  } = useContext(HubContext);
  const [dropdownOpened, setDropdownOpened] = useState(opened);
  const { syncInProgress } = useSync();
  const [showCreateClientModal, setShowCreateClientModal] = useState(false);
  const [dropdownOpenedTime, setDropdownOpenedTime] = useState(
    new Date().getTime()
  );
  const [sectionOpened, setSectionOpened] = useState(defaultSection);

  const [searchInput, setSearchInput] = useState(initialSearchInputValue);
  const delayCallback = useDelay(100);
  const performSearchText = (text) => {
    setSearchInput(text);
    delayCallback(() => {
      onSearchChange(text);
    });
  };

  const handleScrollToLoad = ({
    target: { scrollHeight, clientHeight, scrollTop },
  }) => {
    if (
      sectionOpened !== "" &&
      scrollHeight - (scrollTop + clientHeight) <= 1
    ) {
      if (sectionOpened === "All Contacts" && clientsData) {
        if (clientsData.hasMoreData) clientsData.loadMoreData();
      } else if (sectionOpened === "Streams" && streamsData) {
        if (streamsData.hasMoreData) streamsData.loadMoreData();
      } else if (sectionOpened === "Tags" && tagsData) {
        if (tagsData.hasMoreData) tagsData.loadMoreData();
      }
    }
  };

  const handlePostOnClickEvent = (selectedItemValue) => {
    if (closeOnClick) setDropdownOpened(false);
    performSearchText(showSelectionValueOnClick ? selectedItemValue : "");
  };

  const getDropdownMenuProps = () => {
    const contactProps = clientsData
      ? {
          ...createDropdownItemSectionProps({
            headerText: "All Contacts",
            openList: sectionOpened === "All Contacts",
            items: clientsData,
            filterKeyword: searchInput,
            optionProps: clientOptionProps,
            selectedItemValues: selectedItemValues,
            setSectionOpened: setSectionOpened,
            onItemClick: (clientId) => {
              const client = clientsData.data.find(({ id }) => {
                return id === clientId;
              });
              onClientClick(client);
              recordCustomAction(
                searchInput === "" ? "all_contacts" : "search_by_name",
                dropdownOpenedTime
              );
              handlePostOnClickEvent(client.name);
            },
          }),
        }
      : null;
    const streamProps = streamsData
      ? {
          ...createDropdownItemSectionProps({
            headerText: "Streams",
            openList: sectionOpened === "Streams",
            emptyText: "No matching streams.",
            items: streamsData,
            optionProps: streamOptionProps,
            filterKeyword: searchInput,
            selectedItemValues: selectedItemValues,
            setSectionOpened: setSectionOpened,
            onItemClick: (streamId) => {
              const stream = streamsData.data.find(({ id }) => {
                return id === streamId;
              });
              onStreamOrTagClick({
                obj: stream,
                type: "stream",
                fetchClientByType: fetchClientsWithStreamsInStream,
                fetchCount: fetchStreamClientHouseholdCount,
              });
              recordCustomAction("streams", dropdownOpenedTime);
              handlePostOnClickEvent(stream.name);
            },
          }),
        }
      : null;
    const tagProps = tagsData
      ? {
          ...createDropdownItemSectionProps({
            headerText: "Tags",
            openList: sectionOpened === "Tags",
            emptyText: "No matching tags.",
            items: tagsData,
            filterKeyword: searchInput,
            optionProps: tagOptionProps,
            selectedItemValues: selectedItemValues,
            setSectionOpened: setSectionOpened,
            onItemClick: (tagId) => {
              const tag = tagsData.data.find(({ id }) => {
                return id === tagId;
              });
              onStreamOrTagClick({
                obj: tag,
                type: "tag",
                fetchClientByType: fetchClientsWithTagsInTag,
                fetchCount: fetchTagclientHouseholdCount,
              });
              recordCustomAction("tags", dropdownOpenedTime);
              handlePostOnClickEvent(tag.name);
            },
          }),
        }
      : null;
    return { contactProps, streamProps, tagProps };
  };

  const isLoading = () => {
    return (
      recentClientData?.loading ||
      clientsData?.loading ||
      tagsData?.loading ||
      streamsData?.loading ||
      syncInProgress
    );
  };

  return (
    <React.Fragment>
      <PlaceholderIcon
        showIcon={!dropdownOpened}
        icon={icon}
        searchPlaceholder={searchPlaceholder}
        searchInput={searchInput}
      />
      <Dropdown
        {...dropdownProps}
        onScroll={handleScrollToLoad}
        open={dropdownOpened}
        onOpen={() => {
          setDropdownOpened(true);
          setDropdownOpenedTime(new Date().getTime());
          if (recentClientData) recentClientData.loadData();
          if (sectionOpened === "Streams") streamsData.loadData();
          else if (sectionOpened === "Tags") tagsData.loadData();
          else if (sectionOpened === "All Contacts") clientsData.loadData();
        }}
        onClose={() => {
          setDropdownOpened(false);
          performSearchText("");
        }}
        className={`${dropdownProps.className} selection ${piiMask(
          "fs-block dd-privacy-mask"
        )}`}
        loading={isLoading()}
        onSearchChange={(e) => {
          performSearchText(e.target.value);
        }}
        search
        searchInput={{ ...dropdownProps.searchInput, value: searchInput }}
        searchQuery={searchInput}
        placeholder={placeholder}
        scrolling
        fluid
        value={dropdownProps.multiple ? selectedItemValues : searchInput}
        style={{ paddingRight: "0.8em", ...dropdownProps.style }}
      >
        <Dropdown.Menu>
          {recentClientData && (
            <RecentContactsSection
              sectionOpened={sectionOpened}
              filterKeyword={searchInput}
              {...recentClientData}
              onSectionExpanded={setSectionOpened}
              onClientClick={(client, source) => {
                recordCustomAction(source, dropdownOpenedTime);
                onClientClick(client);
                handlePostOnClickEvent(client.name);
              }}
            />
          )}
          <DropdownSectionWithSearchMode
            hideSyncAndCreate={dropdownProps.multiple}
            searchInput={searchInput}
            {...getDropdownMenuProps()}
            disabled={isLoading()}
            onCreateClientModal={setShowCreateClientModal}
            integration={integration}
          />
        </Dropdown.Menu>
      </Dropdown>
      {showCreateClientModal && (
        <CreateClientModal
          clientName={searchInput}
          integrationButtonText={integrationButtonText}
          onClientCreated={(client, editInCrm) => {
            setShowCreateClientModal(null);
            onClientClick(client, editInCrm);
            handlePostOnClickEvent(client.name);
          }}
          onClose={setShowCreateClientModal}
        />
      )}
    </React.Fragment>
  );
}

ClientDropdown.defaultProps = {
  streamsData: null,
  tagsData: null,
  initialSearchInputValue: "",
  showSelectionValueOnClick: false,
  opened: false,
  placeholder: "Search for a client",
  searchPlaceholder: "",
  icon: null,
  dropdownProps: {},
  onStreamOrTagClick: () => {},
  selectedItemValues: [],
  defaultSection: "Recent Contacts",
  recentClientData: null,
  clientsData: null,
  closeOnClick: true,
  integrationButtonText: "",
};

ClientDropdown.propTypes = {
  initialSearchInputValue: PropTypes.string,
  showSelectionValueOnClick: PropTypes.bool,
  opened: PropTypes.bool,
  recentClientData: PropTypes.object,
  clientsData: PropTypes.object,
  streamsData: PropTypes.object,
  tagsData: PropTypes.object,
  placeholder: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  icon: PropTypes.node,
  dropdownProps: PropTypes.object,
  onStreamOrTagClick: PropTypes.func,
  onSearchChange: PropTypes.func.isRequired,
  onClientClick: PropTypes.func.isRequired,
  selectedItemValues: PropTypes.array,
  defaultSection: PropTypes.string,
  closeOnClick: PropTypes.bool,
  integrationButtonText: PropTypes.string,
};

export default ClientDropdown;
