import React from "react";

import { useFlags } from "launchdarkly-react-client-sdk";

const TemplatesFeatureFlag = ({ showOnEnableWithStyle, children }) => {
  const showTemplates = useFlags()["templates-module"] || false;
  if (!showTemplates) {
    if (showOnEnableWithStyle) {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "middle",
            flexDirection: "column",
            justifyContent: "space-around",
            height: "100%",
            padding: "10% 0",
          }}
        >
          {children}
        </div>
      );
    }

    return null;
  }

  return children;
};

export default TemplatesFeatureFlag;
