import React from "react";
import PropTypes from "prop-types";

import { Assigner } from "scenes/Hubly/components/Workspace/components/Assigner";

import useAssignees from "./useAssignees";

export function MultiTasksAssigners({
  tasks,
  onAssign,
  badge,
  style,
  disabled,
}) {
  const assignees = useAssignees(tasks);

  if (assignees.length === 0) {
    return null;
  }

  return (
    <div style={{ display: "flex" }} data-test="multi-task-assigner">
      {assignees.map(
        ({ assignee, allTasksCompleted, numOfIncomplete, numOfComplete }) => {
          return (
            <Assigner
              style={style}
              key={assignee?.id || "unassigned"}
              currentAssignee={assignee}
              disabled={allTasksCompleted || disabled}
              onAssign={(_, newAssignee) => {
                return onAssign({
                  currentAssigned: assignee,
                  newAssigned: newAssignee,
                });
              }}
            >
              {badge && badge(numOfIncomplete, numOfComplete)}
            </Assigner>
          );
        }
      )}
    </div>
  );
}

MultiTasksAssigners.defaultProps = {
  style: undefined,
  badge: undefined,
  disabled: false,
};

MultiTasksAssigners.propTypes = {
  tasks: PropTypes.array.isRequired,
  onAssign: PropTypes.func.isRequired,
  style: PropTypes.object,
  badge: PropTypes.func,
  disabled: PropTypes.bool,
};

export default MultiTasksAssigners;
