import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {
  Button,
  Divider,
  Header,
  Icon,
  Input,
  Item,
  Popup,
  Responsive,
} from "semantic-ui-react";

import { SetHub } from "data/hub/actions";
import { CreateTag } from "data/libs/tags";

import { SetAlert } from "components/Alerts/actions";
import { ToggleTagModal } from "components/TagModal/actions";

class ManageTags extends React.Component {
  static propTypes = {
    hub: PropTypes.shape({
      id: PropTypes.string.isRequired,
      tags: PropTypes.array.isRequired,
    }).isRequired,
    setAlert: PropTypes.func.isRequired,
    setHub: PropTypes.func.isRequired,
    toggleTagModal: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
      loading: false,
      tagSearch: "",
    };
  }

  toggleDropdown = () => {
    this.setState((state) => {
      return { dropdownOpen: !state.dropdownOpen, tagSearch: "" };
    });
  };

  closeDropdown = (event) => {
    // Get undefined event when clicking outside of the dropdown, close in this case
    if (!event) {
      this.setState({ dropdownOpen: false });
    }
  };

  getDropdownOptions = () => {
    const { tagSearch } = this.state;
    const { hub } = this.props;
    const dropdownOptions = [];
    hub.tags.sort((a, b) => {
      return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
    });
    hub.tags.forEach((tag) => {
      // Only add if the search is empty or matches the current search
      if (
        !tagSearch ||
        tag.name.toLowerCase().includes(tagSearch.toLowerCase())
      ) {
        dropdownOptions.push({
          text: tag.name,
          key: tag.id,
          value: tag,
        });
      }
    });
    return dropdownOptions;
  };

  addTag = () => {
    const { tagSearch } = this.state;
    const { hub, setHub, setAlert } = this.props;
    if (!tagSearch.trim()) {
      setAlert({
        type: "warning",
        text: "Input field cannot be empty. Please verify tag name and try again.",
      });
      return;
    }

    // Check if tag already exists, if so, just add client to tag
    const tagExists = hub.tags.find((tag) => {
      return tag.name === tagSearch;
    });
    if (tagExists) {
      setAlert({
        type: "warning",
        text: `Tag ${tagSearch} already exists. Please verify tag name and try again.`,
      });
      return;
    }

    const hubCopy = { ...hub };

    this.setState({ loading: true });
    const request = {
      hubId: hub.id,
      name: tagSearch,
    };
    CreateTag(request)
      .then((response) => {
        hubCopy.tags.push(response);
        setHub(hubCopy);
        this.setState({ loading: false, tagSearch: "" });
      })
      .catch((error) => {
        setAlert({ type: "error", text: `Failed to create tag.` });
        this.setState({ loading: false });
      });
  };

  handleKeyUp = (e) => {
    // handles key press for adding tags
    if (e.keyCode === 13) {
      // Enter pressed
      this.addTag();
    }
  };

  render() {
    const { dropdownOpen, loading, tagSearch } = this.state;
    const { hub, toggleTagModal } = this.props;
    const dropdownOptions = this.getDropdownOptions();
    hub.tags.sort((a, b) => {
      return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
    });

    return (
      <div style={{ flex: "0 1 auto", margin: "0" }}>
        <div style={{ marginRight: "0.5em" }}>
          <Popup
            data-test="tag-popup"
            icon // required to not have default dropdown icon and not show space
            open={dropdownOpen}
            onClose={() => {
              this.closeDropdown();
            }}
            position="top right"
            style={{ paddingLeft: 0, paddingRight: 0 }}
            trigger={
              <React.Fragment>
                <Responsive maxWidth={1350}>
                  <Button basic icon onClick={this.toggleDropdown}>
                    <Icon.Group>
                      <Icon name="hashtag" />
                      <Icon corner name="cog" />
                    </Icon.Group>
                  </Button>
                </Responsive>
                <Responsive minWidth={1351} style={{ whiteSpace: "nowrap" }}>
                  <Button
                    basic
                    onClick={this.toggleDropdown}
                    data-test="tag-manager-button"
                  >
                    <Icon.Group style={{ marginRight: "0.428571em" }}>
                      <Icon name="hashtag" />
                      <Icon corner name="cog" />
                    </Icon.Group>
                    Tags
                  </Button>
                </Responsive>
              </React.Fragment>
            }
          >
            <Header as="h6" content="Tags" style={{ paddingLeft: "1em" }} />
            <Divider style={{ marginBottom: "0" }} />
            <Item key="add-tag" style={{ padding: "0.5em 1em" }}>
              <div style={{ display: "flex" }}>
                <Input
                  autoComplete="off"
                  onChange={(e, data) => {
                    this.setState({ tagSearch: data.value });
                  }}
                  onKeyUp={this.handleKeyUp}
                  placeholder="Add a Tag"
                  value={tagSearch}
                />
                <div>
                  <Button
                    disabled={loading}
                    loading={loading}
                    icon="plus"
                    style={{ marginLeft: "0.25em", marginRight: 0 }}
                    onClick={this.addTag}
                  />
                </div>
              </div>
            </Item>
            <Divider style={{ marginBottom: "0", marginTop: "0em" }} />
            <div style={{ maxHeight: "300px", overflowY: "auto" }}>
              {dropdownOptions.map((option) => {
                return (
                  <Item
                    className="grey_on_hover"
                    key={option.key}
                    style={{ padding: ".5em 1em" }}
                    onClick={() => {
                      toggleTagModal(option.value);
                      this.toggleDropdown();
                    }}
                  >
                    <Button data-test="edit_tag_button" fluid icon>
                      <Icon name="hashtag" />
                      {option.text}
                    </Button>
                  </Item>
                );
              })}
            </div>
          </Popup>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    hub: state.hubly.data.hub.selected.hub,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAlert: (alert) => {
      dispatch(SetAlert(alert));
    },
    setHub: (hub) => {
      dispatch(SetHub(hub));
    },
    toggleTagModal: (tag) => {
      dispatch(ToggleTagModal(tag, true));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageTags);
