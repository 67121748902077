export function useAssigneeOptions(assignee) {
  return assignee
    .sort((a, b) => {
      if (a.group === b.group) return 0;
      if (a.group && !b.group) return -1;
      return 1;
    })
    .map((u) => {
      return { ...u, icon: u.group ? "users" : "user circle" };
    })
    .map(({ id, name, icon }) => {
      return {
        key: id,
        value: id,
        text: name,
        icon: icon,
      };
    });
}

export default useAssigneeOptions;
