import React from "react";
import PropTypes from "prop-types";

import { Placeholder } from "semantic-ui-react";

import NoBillingChanges from "../NoBillingChanges";
import SubscriptionChargesTable from "../SubscriptionChargesTable";

function Information({ loading, info }) {
  const { seatsAvailable = 0, seatsNeeded = 0, billingPeriod = "" } = info;

  const pluralize = (count, suffix = "s") => {
    return count > 1 ? suffix : "";
  };

  if (loading) {
    return (
      <Placeholder>
        <Placeholder.Header>
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Header>
      </Placeholder>
    );
  }

  if (seatsNeeded) {
    return (
      <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <div style={{ flexGrow: 1 }}>
          <p data-test="additional-license-text">
            <strong>
              {seatsNeeded} additional license
              {pluralize(seatsNeeded)}
            </strong>{" "}
            will be added to your existing {billingPeriod} subscription when
            these invites are sent.
          </p>
          <SubscriptionChargesTable subscriptionInfo={info} />
        </div>

        <div
          style={{
            color: "grey",
            marginTop: "0.5em",
            fontSize: "90%",
          }}
        >
          The total displayed will be charged in full upon your next
          subscription billing cycle. A prorated amount will be charged today,
          and an invoice will be emailed to you.
        </div>
      </div>
    );
  }

  return (
    <React.Fragment>
      <div data-test="available-license-text">
        {seatsAvailable > 0 ? (
          <span>
            You will still have{" "}
            <strong>
              {seatsAvailable} available license
              {pluralize(seatsAvailable)}
            </strong>
          </span>
        ) : (
          <span>You have used all available licenses</span>
        )}{" "}
        on your subscription. You can add additional users at any time.
      </div>
      <NoBillingChanges />
    </React.Fragment>
  );
}

Information.propTypes = {
  loading: PropTypes.bool,
  info: PropTypes.shape({
    billingPeriod: PropTypes.string,
    seatsAvailable: PropTypes.number,
    seatsNeeded: PropTypes.number,
  }),
};

Information.defaultProps = {
  loading: false,
  info: PropTypes.shape({
    billingPeriod: "",
    seatsAvailable: 0,
    seatsNeeded: 0,
  }),
};
export default Information;
