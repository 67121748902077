export const initialState = {
  attachments: [],
  attachmentsLoading: true,
  tags: [],
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_SELECTED_WORKFLOW_ID":
      return {
        ...state,
        selectedWorkflowId: action.selectedWorkflowId,
      };
    case "SET_ATTACHMENTS":
      return {
        ...state,
        attachments: action.attachments,
        attachmentsLoading: false,
      };
    case "SET_ATTACHMENTS_LOADING":
      return {
        ...state,
        attachmentsLoading: action.attachmentsLoading,
      };
    case "SET_COMMENTS":
      return {
        ...state,
        comments: action.comments,
      };
    default:
      return state;
  }
};
export default reducer;
