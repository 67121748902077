import { useCallback } from "react";

import useMutationWithToaster from "hooks/useMutationWithToaster";

export function useSendInvitationEmail() {
  const mutationFactory = useCallback(() => {
    const query = `mutation sendAdvisorInvitation($id: ID!) {
          sendAdvisorInvitation(id: $id) {
            successMessage
          }
        }`;

    const resolver = ({ data: { sendAdvisorInvitation }, errors }) => {
      return { data: { ...sendAdvisorInvitation }, errors: errors };
    };

    return { query, resolver };
  }, []);

  const [sendInvitationEmail, { loading }] =
    useMutationWithToaster(mutationFactory);

  return { sendInvitationEmail, loading };
}

export default useSendInvitationEmail;
