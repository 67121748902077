import React, { useEffect } from "react";
import { Route, Switch, useHistory, useParams } from "react-router-dom";
import PropTypes from "prop-types";

import { Icon } from "semantic-ui-react";

import { useBooleanState } from "hooks";
import Modal from "components/Modal";

import { useSignupEmail } from "../hooks";

function SignUpHelper({ authState, onStateChange, onEmailIsReady }) {
  const history = useHistory();
  const { advisorId } = useParams();
  const { loading, email, error, isError } = useSignupEmail(advisorId);
  const [isModalOpen, , close] = useBooleanState(true);

  useEffect(() => {
    if (!loading && authState === "signIn" && email && !isError) {
      history.push("/");
      onStateChange("signUp");
      onEmailIsReady(email);
      close();
    }
  }, [
    loading,
    email,
    isError,
    history,
    authState,
    onStateChange,
    onEmailIsReady,
    close,
  ]);

  return (
    <React.Fragment>
      <Modal
        data-test="signup-status-modal"
        style={{ width: "40%" }}
        isModalOpen={isModalOpen}
      >
        {Array.isArray(error) && (
          <Modal.Header
            onClose={() => {
              history.push("/");
              close();
            }}
          >
            Error
          </Modal.Header>
        )}
        <Modal.Body>
          {loading && (
            <div style={{ textAlign: "center" }}>
              <Icon loading name="circle notch" size="big" />
            </div>
          )}
          {Array.isArray(error) && (
            <div>
              {error[0]?.message
                ? error[0]?.message
                : "Error processing the sign up request."}
            </div>
          )}
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}

SignUpHelper.propTypes = {
  authState: PropTypes.string.isRequired,
  onStateChange: PropTypes.func.isRequired,
  onEmailIsReady: PropTypes.func.isRequired,
};

export function HublySignUpLinkHandler(props) {
  return (
    <Switch>
      <Route
        exact
        path={["/signup/:advisorId"]}
        render={() => {
          return <SignUpHelper {...props} />;
        }}
      />
    </Switch>
  );
}

export default HublySignUpLinkHandler;
