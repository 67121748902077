export const initialState = {
  active: false,
  message: "",
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_LOADING_ACTIVE":
      return {
        ...state,
        active: action.active,
        message: action.message,
      };

    default:
      return state;
  }
};
export default reducer;
