import { useDispatch, useSelector } from "react-redux";

import { ResetSyncProgress, TriggerFullSync } from "./action";

export function useSync() {
  const dispatch = useDispatch();

  const { inProgress, percent } = useSelector(({ hubly }) => {
    return hubly?.data?.hub?.selected;
  });

  return {
    syncInProgress: inProgress,
    syncProgress: percent,
    syncCompleted: !inProgress && percent === 100,
    resetSyncProgress: () => {
      dispatch(ResetSyncProgress());
    },
    runFullSync: () => {
      dispatch(TriggerFullSync());
    },
  };
}

export default useSync;
