import { useCallback, useEffect, useState } from "react";

import { useSubscribe } from "data/messages/hooks";

export function useSubscribeToWorkflowUpdates(onUpdate) {
  const [isRefetch, refetch] = useState(false);

  useEffect(() => {
    if (isRefetch) {
      onUpdate();
      refetch(false);
    }
  }, [isRefetch, onUpdate]);

  useSubscribe(
    "workflows",
    useCallback((wf) => {
      if (wf.size > 0) refetch(true);
    }, [])
  );
}

export default useSubscribeToWorkflowUpdates;
