import * as URI from "uri-js";

export const SendEmailToService = (to, subject, body, service) => {
  switch (service) {
    case "gmail":
      return URI.serialize({
        scheme: "https",
        host: "mail.google.com",
        path: "/mail/u/0",
        query: `view=cm&fs=1&tf=1&source=mailto&su=${subject}&to=${to}&body=${body}`,
      });
    case "outlook":
      return URI.serialize({
        scheme: "https",
        host: "outlook.live.com",
        path: "/owa",
        query: `path=/mail/action/compose&subject=${subject}&to=${to}&body=${body}`,
      });
    case "yahoo":
      return URI.serialize({
        scheme: "https",
        host: "compose.mail.yahoo.com",
        path: "/mail/u/0",
        query: `subj=${subject}&to=${to}&body=${body}`,
      });
    case "local":
      return URI.serialize({
        scheme: "mailto",
        to: to,
        subject: subject,
        body: body,
      });
    default:
      return "";
  }
};
export default SendEmailToService;
