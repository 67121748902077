import React from "react";
import PropTypes from "prop-types";

import "./style.css";

import CRMPermissionWarning from "scenes/Hubly/components/Workspace/components/CRMPermissionWarning";

const ActiveClientsWrapper = ({
  onClientsClicked,
  crmPermissionWarning,
  numActiveClients,
}) => {
  const handleClick = (e) => {
    e.stopPropagation();
    onClientsClicked();
  };

  return (
    <div
      className={numActiveClients > 0 ? "service-team-clients-with-client" : ""}
      style={{
        fontSize: "12px",
      }}
      onClick={handleClick}
    >
      {numActiveClients === 1
        ? `${numActiveClients} contact`
        : `${numActiveClients} contacts`}
      {crmPermissionWarning && (
        <CRMPermissionWarning permissionSubject="contacts assigned to this Service Team" />
      )}
    </div>
  );
};

ActiveClientsWrapper.propTypes = {
  onClientsClicked: PropTypes.func.isRequired,
  crmPermissionWarning: PropTypes.bool,
  numActiveClients: PropTypes.number,
};

ActiveClientsWrapper.defaultProps = {
  crmPermissionWarning: false,
  numActiveClients: 0,
};

export default ActiveClientsWrapper;
