import React from "react";
import PropTypes from "prop-types";

import {
  AddOrRemoveStreamRuleText,
  AgeRuleText,
  ConditionList,
  ExcludeItemsLabels,
  ImportantClientDatesRuleText,
  RepeatingRuleText,
  RuleItemLayout,
  RulePopupMenu,
} from "./components";

export function RuleList({
  workflowRules,
  onSave,
  onDelete,
  onEdit,
  dateTypes,
  meetingDateTypes,
  withActionMenu,
}) {
  if (workflowRules.length === 0) {
    return (
      <div style={{ color: "grey", marginBottom: "1em", marginTop: "1em" }}>
        No rules applied
      </div>
    );
  }

  return (
    <div style={{ marginBottom: "0.5em" }}>
      {workflowRules.map((workflowRule) => {
        return (
          <RuleItemLayout
            key={workflowRule.id}
            active={workflowRule?.active}
            onEdit={() => {
              if (withActionMenu) onEdit(workflowRule.type, workflowRule);
            }}
            excludeItemLabels={
              <ExcludeItemsLabels
                streamsExclude={workflowRule?.streamsExclude}
                tagsExclude={workflowRule?.tagsExclude}
                clientsExclude={workflowRule?.clientsExclude}
              />
            }
            ruleMenu={
              withActionMenu && (
                <RulePopupMenu
                  active={workflowRule?.active}
                  onEdit={() => {
                    onEdit(workflowRule.type, workflowRule);
                  }}
                  onDelete={() => {
                    onDelete(workflowRule.id);
                  }}
                  onPause={() => {
                    onSave(workflowRule.id, { active: !workflowRule.active });
                  }}
                />
              )
            }
            data-test="automation-rule-item"
          >
            <ConditionList
              tags={workflowRule.tags}
              streams={workflowRule.streams}
              type={workflowRule.type}
            >
              {workflowRule.type === "REPEATING_RULE" && (
                <RepeatingRuleText workflowRule={workflowRule} />
              )}
              {(workflowRule.type === "ADD_ATTRIBUTES" ||
                workflowRule.type === "REMOVE_ATTRIBUTES") && (
                <AddOrRemoveStreamRuleText workflowRule={workflowRule} />
              )}
              {workflowRule.type === "IMPORTANT_DATE_RULE" && (
                <ImportantClientDatesRuleText
                  dateTypes={dateTypes}
                  meetingDateTypes={meetingDateTypes}
                  workflowRule={workflowRule}
                />
              )}
              {workflowRule.type === "AGE_RULE" && (
                <AgeRuleText workflowRule={workflowRule} />
              )}
            </ConditionList>
          </RuleItemLayout>
        );
      })}
    </div>
  );
}

RuleList.propTypes = {
  workflowRules: PropTypes.array,
  dateTypes: PropTypes.array,
  meetingDateTypes: PropTypes.array,
  onSave: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  withActionMenu: PropTypes.bool,
};

RuleList.defaultProps = {
  workflowRules: [],
  dateTypes: [],
  meetingDateTypes: [],
  withActionMenu: true,
};

export default RuleList;
