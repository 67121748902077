import React from "react";
import PropTypes from "prop-types";

import {
  makeStyles,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import {
  ArrowDropDown as ArrowDropDownIcon,
  ArrowDropUp as ArrowDropUpIcon,
} from "@material-ui/icons";

const useSortStyles = makeStyles(() => {
  return {
    headerCell: {
      backgroundColor: "#FFFFFF",
      fontSize: "1.2rem",
      fontWeight: "bold",
    },
    iconWrap: {
      position: "relative",
      width: "21px",
      height: "21px",
    },
    arrowUp: {
      position: "absolute",
      top: "-2.5px",
    },
    arrowDown: {
      position: "absolute",
      bottom: "-2.5px",
    },
  };
});

const CustomSortingIcon = () => {
  const classes = useSortStyles();
  return (
    <div className={classes.iconWrap}>
      <ArrowDropUpIcon className={classes.arrowUp} />
      <ArrowDropDownIcon className={classes.arrowDown} />
    </div>
  );
};

function EnhancedTableHeader({ columns, isAsc, orderBy, onClickSort }) {
  const classes = useSortStyles();

  const createSortHandler = (headerToSortBy) => {
    return (event) => {
      onClickSort(headerToSortBy, event);
    };
  };

  const modifiedColumns = columns.map((column) => {
    return {
      ...column,
      align: "left",
      disablePadding: true,
    };
  });

  const composeOrder = (dir) => {
    return dir ? "asc" : "desc";
  };

  return (
    <TableHead>
      <TableRow>
        {modifiedColumns.map((headCell) => {
          return (
            <TableCell
              key={headCell.id}
              className={classes.headerCell}
              align={headCell.align || "left"}
              sortDirection={
                orderBy === headCell.id ? composeOrder(isAsc) : false
              }
            >
              {headCell.isSortable ? (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={
                    orderBy === headCell.id ? composeOrder(isAsc) : "asc"
                  }
                  onClick={createSortHandler(headCell.id)}
                  IconComponent={() => {
                    if (orderBy !== headCell.id) {
                      return <CustomSortingIcon />;
                    }
                    return composeOrder(isAsc) === "asc" ? (
                      <ArrowDropUpIcon />
                    ) : (
                      <ArrowDropDownIcon />
                    );
                  }}
                >
                  {headCell.label}
                </TableSortLabel>
              ) : (
                headCell.label
              )}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHeader.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    })
  ).isRequired,
  onClickSort: PropTypes.func,
  isAsc: PropTypes.bool,
  orderBy: PropTypes.string,
};

EnhancedTableHeader.defaultProps = {
  isAsc: true,
  orderBy: "",
  onClickSort: () => {},
};

export default EnhancedTableHeader;
