import {
  DeleteClientWorkflow,
  EditClientWorkflow,
  UpdateClientWorkflowBulkSortOrder,
  UpdateClientWorkflowSortOrder,
} from "data/libs/clientWorkflows";
import { EditTask } from "data/libs/tasks";

export const removeClientWorkflow = DeleteClientWorkflow;

export const moveClientTileToTop = (clientWorkflowId, topClientWorkflowId) => {
  return UpdateClientWorkflowSortOrder(clientWorkflowId, {
    placeBeforeItem: topClientWorkflowId,
  });
};

export const markNextTaskAsCompleted = ({ id, assignedAdvisor }) => {
  return EditTask(id, {
    completed: true,
    assignToMe: !assignedAdvisor?.id,
  });
};

export const assignTask = (taskId, assigneeId) => {
  return EditTask(taskId, {
    assigneeId: assigneeId === "unassigned" ? null : assigneeId,
  });
};

export const assignClientWorkflow = (clientWorkflowId, assigneeId) => {
  return EditClientWorkflow(clientWorkflowId, {
    assignedAdvisorId: assigneeId === "unassigned" ? "" : assigneeId,
  });
};

export const archiveClientWorkflow = (clientWorkflowId) => {
  return EditClientWorkflow(clientWorkflowId, {
    completed: true,
    isHidden: true,
    archived: true,
  });
};

export const submitSortWorkflows = (workflowId, type, sortByLastName) => {
  const sortRequestParams = new Map([
    ["ClientsReminder", { sortType: "REMINDERS" }],
    ["MeetingDate", { sortType: "MEETING_DATE" }],
    [
      "ClientsDateOldest",
      { sortType: "DATE_ADDED", extraParams: { option: "oldest" } },
    ],
    [
      "ClientsDateNewest",
      { sortType: "DATE_ADDED", extraParams: { option: "newest" } },
    ],
    [
      "AlphabeticalOrderAsc",
      {
        sortType: "ALPHABETICAL",
        extraParams: { sortByLastName: sortByLastName, order: "asc" },
      },
    ],
    [
      "AlphabeticalOrderDesc",
      {
        sortType: "ALPHABETICAL",
        extraParams: { sortByLastName: sortByLastName, order: "desc" },
      },
    ],
  ]);

  return UpdateClientWorkflowBulkSortOrder({
    workflowId: workflowId,
    ...sortRequestParams.get(type),
  });
};

export default removeClientWorkflow;
