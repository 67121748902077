import React, { useContext, useState } from "react";
import PropTypes from "prop-types";

import { Icon, Modal } from "semantic-ui-react";

import { useForm } from "hooks";
import AddRemoveButton from "components/AddRemoveButton";
import { ConfirmationModal } from "components/ConfirmationModal";

import {
  HubContext,
  ToasterContext,
} from "scenes/Hubly/components/Workspace/Provider";

import {
  DescriptionMarkup,
  DetailsMarkup,
  LinkMarkup,
  TitleMarkup,
} from "./components";

export function DetailsModal({
  task,
  isCreating,
  client,
  assignHousehold,
  onClose,
  onDetailsUpdated,
  ...props
}) {
  const { editTask } = useContext(HubContext);
  const { fireError } = useContext(ToasterContext);
  const [loading, setLoading] = useState(false);
  const [confirmDeleteButtons, showDeleteConfirmationModal] = useState(null);

  const { register, data, disableButton } = useForm({
    initialValue: {
      title: task?.title ? task.title : "",
      description: !task?.description ? "" : task.description,
      isClientTask: !task?.isClientTask ? false : task.isClientTask,
      links: task?.links ? task.links : [],
    },
    fieldValidator: ({ required, value, errortext = "" }) => {
      return task && required && value === "" ? errortext : "";
    },
    formValidator: ({ title }) => {
      return !task?.id ? true : title !== "";
    },
  });
  const linkProps = register({ name: "links" });

  const updateDetails = (detailsData) => {
    if (!task?.id) {
      onDetailsUpdated(detailsData);
      onClose(null);
    } else {
      setLoading(true);
      editTask(
        task.id,
        {
          title: detailsData.title,
          description: detailsData.description,
          isClientTask: detailsData.isClientTask,
        },
        client?.id
      )
        .then((updatedTask) => {
          onDetailsUpdated(updatedTask);
        })
        .catch((error) => {
          fireError("Failed to update task details", error);
          setLoading(false);
          onClose(null);
        });
    }
  };
  const removeDetails = () => {
    showDeleteConfirmationModal([
      {
        text: "Cancel",
        callBack: () => {
          showDeleteConfirmationModal(null);
        },
      },
      {
        text: "Remove",
        callBack: () => {
          updateDetails({ ...data, description: null });
          showDeleteConfirmationModal(null);
        },
        color: "red",
      },
    ]);
  };

  return (
    <React.Fragment>
      <Modal size="small" on="click" open>
        <Modal.Header as="h4">
          <Icon
            color="grey"
            link
            name="close"
            onClick={() => {
              onClose(null);
            }}
            style={{ float: "right" }}
          />
          Task Details
        </Modal.Header>
        <Modal.Content>
          {task?.id && (
            <TitleMarkup
              {...register({
                name: "title",
                required: true,
                errortext:
                  "Title cannot be empty. Please verify task name and try again.",
              })}
              label="Task Name"
            />
          )}
          <DescriptionMarkup {...register({ name: "description" })} />
          <DetailsMarkup {...register({ name: "isClientTask" })}>
            {assignHousehold}
          </DetailsMarkup>
          <LinkMarkup
            taskId={task?.id}
            links={linkProps.value || []}
            {...props}
            onAdd={(link) => {
              linkProps.onChange(null, {
                name: "links",
                value: [...(linkProps.value || []), link],
              });
            }}
            onEdit={(link) => {
              linkProps.onChange(null, {
                name: "links",
                value: linkProps.value.map((l) => {
                  if (l.id === link.id) {
                    return link;
                  }
                  return l;
                }),
              });
            }}
            onDelete={(link) => {
              linkProps.onChange(null, {
                name: "links",
                value: linkProps.value.filter((l) => {
                  return l.id !== link.id;
                }),
              });
            }}
          />
        </Modal.Content>
        <Modal.Actions>
          <AddRemoveButton
            loading={loading}
            disabled={disableButton || loading}
            isCreating={isCreating}
            onRemove={removeDetails}
            onAdd={() => {
              updateDetails(data);
            }}
            style={{ marginTop: 0 }}
          />
        </Modal.Actions>
      </Modal>
      {confirmDeleteButtons && (
        <ConfirmationModal
          title="Are you sure?"
          message="Are you sure you want to remove all the details on this task? This cannot be undone"
          icon="delete"
          buttons={confirmDeleteButtons}
        />
      )}
    </React.Fragment>
  );
}

DetailsModal.propTypes = {
  task: PropTypes.object,
  isCreating: PropTypes.bool,
  client: PropTypes.object,
  assignHousehold: PropTypes.node,
  onClose: PropTypes.func.isRequired,
  onDetailsUpdated: PropTypes.func.isRequired,
};

DetailsModal.defaultProps = {
  isCreating: true,
  task: null,
  client: null,
  assignHousehold:
    null /* TODO: To be implemented later <AsssignHouseholdMemberMarkup /> */,
};

export default DetailsModal;
