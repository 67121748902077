import React, { useContext, useState } from "react";
import PropTypes from "prop-types";

import { Checkbox, Icon } from "semantic-ui-react";

import { useDelay, useDropdownData } from "hooks";
import CompactButton from "components/CompactButton";
import Selector from "components/Selector";

import { HubContext } from "scenes/Hubly/components/Workspace/Provider";

export function NextWorkflowAdd({ disabled, workflow, onAdd, onClose }) {
  const { fetchWorkflows } = useContext(HubContext);
  const [searchText, setSearchText] = useState("");
  const { loading, data, hasMoreData, loadData, loadMoreData } =
    useDropdownData({
      type: "workflow",
      workflowId: workflow.id,
      fetchFunction: fetchWorkflows,
      searchText: searchText,
      first: 50,
    });

  const [nextWorkflowId, setNextWorkflowId] = useState(null);
  const [applyNextWorkflowsToAll, setApplyNextWorkflowsToAll] = useState(true);

  const delayCallback = useDelay(100);
  const performSearchText = (text) => {
    delayCallback(() => {
      setSearchText(text);
    });
  };

  const nextWorkflowOptions = data.map(({ id, name }) => {
    return {
      text: name,
      key: id,
      value: id,
    };
  });

  return (
    <React.Fragment>
      <div style={{ marginRight: "0.5em", marginTop: "1em" }}>
        <Selector
          name="nextWorkflowId"
          onOpen={() => {
            loadData();
          }}
          showLoadMore={hasMoreData}
          loadMoreData={loadMoreData}
          dropdownProps={{
            disabled: disabled,
            loading: loading,
            selectOnBlur: false,
            placeholder: "Search for Workflow",
            className: "next-workflows-search-dropdown",
            noResultsMessage: "No Workflows to Add",
            onSearchChange: (_, { searchQuery }) => {
              performSearchText(searchQuery);
            },
          }}
          selection={nextWorkflowOptions}
          multiple={false}
          selected={nextWorkflowId}
          onSelect={(selectedId) => {
            performSearchText("");
            setNextWorkflowId(selectedId);
          }}
        />
        <Checkbox
          name="applyNextWorkflowsToAll"
          label="Apply to all clients"
          checked={applyNextWorkflowsToAll}
          onChange={() => {
            setApplyNextWorkflowsToAll(!applyNextWorkflowsToAll);
          }}
          style={{ marginTop: "0.5em" }}
        />
      </div>
      <div style={{ marginTop: "0.5em", marginBottom: "0.5em" }}>
        <CompactButton
          positive
          basic={false}
          minWidth={0}
          disabled={nextWorkflowId === null || disabled}
          onClick={() => {
            onAdd({
              nextWorkflowId: nextWorkflowId,
              applyNextWorkflowsToAll: applyNextWorkflowsToAll,
            });
            setNextWorkflowId(null);
          }}
        >
          Confirm
        </CompactButton>
        <Icon
          name="delete"
          color="grey"
          size="large"
          link
          disabled={disabled}
          onClick={() => {
            onClose();
          }}
        />
      </div>
    </React.Fragment>
  );
}

NextWorkflowAdd.propTypes = {
  disabled: PropTypes.bool,
  workflow: PropTypes.object.isRequired,
  onAdd: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

NextWorkflowAdd.defaultProps = {
  disabled: false,
};

export default NextWorkflowAdd;
