import React from "react";
import PropTypes from "prop-types";

import { useSync } from "data/hub/sync";

import Access from "components/Access";
import Tooltip from "components/Tooltip";

import { Header, Icon, Layout, Placeholder } from "./components";

export function ClientServiceTeam({ onClickSettings, showLoading, children }) {
  const { syncInProgress } = useSync();

  return (
    <Layout data-test="client-service-team">
      <Layout.Header>
        <Header as="h5" style={{ margin: 0 }}>
          Service Team
        </Header>
        <Access required={["Administrator"]}>
          <Tooltip
            title="You cannot manage User Roles while a CRM sync is in progress"
            hidden={!syncInProgress}
          >
            <div
              className="clickable grey_on_hover rounded p25"
              data-test="open-manage-service-roles"
              style={{ marginLeft: "auto" }}
              onClick={() => {
                if (!syncInProgress) onClickSettings();
              }}
            >
              <Icon
                disabled={syncInProgress}
                color="grey"
                name="setting"
                style={{ margin: 0 }}
              />
            </div>
          </Tooltip>
        </Access>
      </Layout.Header>
      {showLoading ? (
        <Placeholder>
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder>
      ) : (
        children
      )}
    </Layout>
  );
}

ClientServiceTeam.propTypes = {
  onClickSettings: PropTypes.func.isRequired,
  showLoading: PropTypes.bool,
  children: PropTypes.node,
};

ClientServiceTeam.defaultProps = {
  showLoading: false,
  children: undefined,
};

export default ClientServiceTeam;
