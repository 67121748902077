export const isSequentialReminder = (task) => {
  return (
    task?.options?.sequentialReminder?.type !== null &&
    task?.options?.sequentialReminder?.type !== undefined
  );
};

export const reminderTypeOptions = [
  {
    key: "futureReminder",
    text: "When a client is added to this workflow",
    value: "futureReminder",
  },
  {
    key: "sequential",
    text: "When the preceding task is completed",
    value: "sequential",
  },
];

export const delayDropdownOptions = [
  {
    key: "immediate",
    text: "immediately",
    value: "immediate",
    content: "immediately",
  },
  {
    key: "delayed",
    text: "after",
    value: "delayed",
    content: "after",
  },
];

export const getFutureReminderOptions = (futureReminderNumber) => {
  const dayString = futureReminderNumber > 1 ? "days" : "day";
  const weekString = futureReminderNumber > 1 ? "weeks" : "week";
  const hourString = futureReminderNumber > 1 ? "hours" : "hour";
  const monthString = futureReminderNumber > 1 ? "months" : "month";

  return [
    {
      key: "hour",
      text: hourString,
      value: "hour",
      content: hourString,
    },
    {
      key: "day",
      text: dayString,
      value: "day",
      content: dayString,
    },
    {
      key: "week",
      text: weekString,
      value: "week",
      content: weekString,
    },
    {
      key: "month",
      text: monthString,
      value: "month",
      content: monthString,
    },
  ];
};

export const getDefaultDate = () => {
  const defaultDate = new Date();
  defaultDate.setHours(8);
  defaultDate.setMinutes(0);
  defaultDate.setSeconds(0);
  defaultDate.setMilliseconds(0);
  return defaultDate;
};

export const initSequentialReminderNode = (sequentialReminder) => {
  const { time, type, number = 1 } = sequentialReminder;

  const futureReminderDelay = type === "immediate" ? "immediate" : "delayed";
  let futureReminderType = type || "day";
  if (futureReminderType === "immediate") futureReminderType = "day";

  return {
    futureReminderControl: {
      futureReminderNumber: number,
      futureReminderType: futureReminderType,
      futureReminderTime: time ? new Date(time) : getDefaultDate(),
    },
    futureReminderDelay: futureReminderDelay,
    reminderTypeSelection: "sequential",
  };
};

export const initLegacy = (task) => {
  const foundReminder = task?.reminders ? task.reminders[0] : task;
  const futureReminderNumber =
    foundReminder && foundReminder.futureReminderNumber
      ? foundReminder.futureReminderNumber
      : 1;
  const futureReminderTime =
    foundReminder && foundReminder.futureReminderTime
      ? new Date(foundReminder.futureReminderTime)
      : getDefaultDate();
  const futureReminderDelay =
    foundReminder && foundReminder.futureReminderType === "immediate"
      ? "immediate"
      : "delayed";
  let futureReminderType =
    foundReminder && foundReminder.futureReminderType
      ? foundReminder.futureReminderType
      : "day";
  if (futureReminderType === "immediate") futureReminderType = "day";

  return {
    futureReminderControl: {
      futureReminderNumber: futureReminderNumber,
      futureReminderType: futureReminderType,
      futureReminderTime: futureReminderTime,
    },
    futureReminderDelay: futureReminderDelay,
    reminderTypeSelection: "futureReminder",
  };
};

export const formatReminderData = ({
  futureReminderTime,
  futureReminderType,
  futureReminderNumber,
  futureReminderDelay,
  ...otherData
}) => {
  return {
    futureReminderType:
      futureReminderDelay === "immediate" ? "immediate" : futureReminderType,
    futureReminderNumber: futureReminderNumber,
    futureReminderTime: futureReminderTime,
    ...otherData,
  };
};

export const formatReminderDataForTaskCreation = ({
  reminderTypeSelection,
  title,
  futureReminderNumber,
  futureReminderTime,
  futureReminderType,
}) => {
  return reminderTypeSelection === "sequential"
    ? {
        options: {
          sequentialReminder: {
            time: futureReminderTime,
            type: futureReminderType,
            number: futureReminderNumber,
          },
        },
      }
    : {
        reminders: [
          {
            title,
            futureReminderNumber,
            futureReminderTime,
            futureReminderType,
          },
        ],
      };
};
