import React from "react";
import PropTypes from "prop-types";

import { useAccessTypeStore } from "scenes/Hubly/components/Workspace/components/hooks";

import { TaskIcon } from "..";
import { DetailsContainer } from "./components";
import { hasDetails } from "./helper";

export function TaskDetails({
  task,
  client,
  expanded,
  onTaskConfigChange,
  onShowDetailProps,
  onShowTaskDetailContents,
  children,
}) {
  const hasTaskDetails = hasDetails(task, client);
  const { hasAccess } = useAccessTypeStore();
  return (
    <React.Fragment>
      <TaskIcon
        visible={hasAccess() || hasTaskDetails}
        expanded={expanded}
        hasValue={hasTaskDetails}
        iconName="align left"
        iconNameOutline="align left"
        expandedColor="blue"
        title={hasTaskDetails ? "Click to show details" : "Task Details"}
        onClick={() => {
          if (!hasTaskDetails || (hasTaskDetails && !task?.id)) {
            onShowDetailProps({
              task: task,
              isCreating: !hasTaskDetails,
            });
          } else {
            onShowTaskDetailContents(
              expanded ? null : (
                <DetailsContainer
                  description={task.description}
                  isClientTask={task.isClientTask}
                  links={task.links}
                />
              )
            );
          }
        }}
      />
      {children({
        onClose: onShowDetailProps,
        onDetailsUpdated: onTaskConfigChange,
      })}
    </React.Fragment>
  );
}

TaskDetails.propTypes = {
  task: PropTypes.object,
  onTaskConfigChange: PropTypes.func.isRequired,
  client: PropTypes.object,
  children: PropTypes.func,
  expanded: PropTypes.bool,
  onShowDetailProps: PropTypes.func.isRequired,
  onShowTaskDetailContents: PropTypes.func,
};
TaskDetails.defaultProps = {
  client: null,
  expanded: false,
  task: null,
  children: () => {},
  onShowTaskDetailContents: () => {},
};

export default TaskDetails;
