import React from "react";
import PropTypes from "prop-types";

import Menu from "components/Menu";

export function RulePopupMenu({ active, onEdit, onDelete, onPause }) {
  return (
    <Menu data-test="automation-rule-popupMenu">
      {({ closeMenu }) => {
        return (
          <React.Fragment>
            <Menu.Button
              icon="edit"
              content="Edit Rule"
              onClick={() => {
                onEdit();
                closeMenu();
              }}
            />
            <Menu.Button
              icon={active ? "pause" : "play"}
              content={active ? "Pause Rule" : "Resume Rule"}
              onClick={() => {
                onPause();
                closeMenu();
              }}
            />
            <Menu.Button
              icon="delete"
              content="Delete Rule"
              onClick={() => {
                onDelete();
                closeMenu();
              }}
            />
          </React.Fragment>
        );
      }}
    </Menu>
  );
}

RulePopupMenu.propTypes = {
  active: PropTypes.bool,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onPause: PropTypes.func.isRequired,
};

RulePopupMenu.defaultProps = {
  active: false,
};

export default RulePopupMenu;
