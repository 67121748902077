import React from "react";
import PropTypes from "prop-types";

import { Header } from "semantic-ui-react";

export function Layout({
  hasReminder,
  reminderTypeSelection,
  futureReminderDelaySelection,
  futureReminderControl,
}) {
  return (
    <div style={{ width: "max-content" }}>
      <div style={{ display: "flex" }}>
        <Header as="h4" style={{ marginBottom: "1em" }}>
          {hasReminder ? "Edit Reminder" : "Add Reminder"}
        </Header>
      </div>
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginBottom: "1em",
          }}
        >
          {reminderTypeSelection}
          set reminder
          {futureReminderDelaySelection}
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          {futureReminderControl}
        </div>
      </div>
    </div>
  );
}

Layout.propTypes = {
  hasReminder: PropTypes.bool,
  reminderTypeSelection: PropTypes.node,
  futureReminderDelaySelection: PropTypes.node,
  futureReminderControl: PropTypes.node,
};

Layout.defaultProps = {
  hasReminder: false,
  reminderTypeSelection: null,
  futureReminderControl: null,
  futureReminderDelaySelection: null,
};

export default Layout;
