export const initialState = {
  clientWorkflowId: "",
  isTaskEmailTemplateOpen: false,
  isTaskFutureReminderOpen: false,
  isTaskConditionOpen: false,
  isTaskDetailsOpen: false,
  isTaskTimeCompletedAtOpen: false,
  isTaskTimeEstimateOpen: false,
  isTaskTimeSpentOpen: false,
  selectedTaskIdForComment: "",
  modalTaskId: "",
  popupRef: {},
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_COMMENT_TASK_ID":
      return {
        ...state,
        selectedTaskIdForComment: action.selectedTaskIdForComment,
        clientWorkflowId: action.clientWorkflowId,
      };
    case "TOGGLE_TASK_CONDITION_POPUP":
      return {
        ...state,
        isTaskConditionOpen: action.isOpen,
        modalTaskId: action.taskId,
      };
    case "TOGGLE_TASK_DETAILS_MODAL":
      return {
        ...state,
        isTaskDetailsOpen: action.isOpen,
        modalTaskId: action.taskId,
      };
    case "TOGGLE_TASK_EMAIL_TEMPLATE_MODAL":
      return {
        ...state,
        isTaskEmailTemplateOpen: action.isOpen,
        modalTaskId: action.taskId,
      };
    case "TOGGLE_TASK_FUTURE_REMINDER_POPUP":
      return {
        ...state,
        isTaskFutureReminderOpen: action.isOpen,
        modalTaskId: action.taskId,
      };
    case "TOGGLE_TASK_TIME_COMPLETED_AT_MODAL":
      return {
        ...state,
        isTaskTimeCompletedAtOpen: action.isOpen,
        modalTaskId: action.taskId,
      };
    case "TOGGLE_TASK_TIME_ESTIMATE_POPUP":
      return {
        ...state,
        isTaskTimeEstimateOpen: action.isOpen,
        modalTaskId: action.taskId,
      };
    case "TOGGLE_TASK_TIME_SPENT_POPUP":
      return {
        ...state,
        isTaskTimeSpentOpen: action.isOpen,
        modalTaskId: action.taskId,
      };
    default:
      return state;
  }
};
export default reducer;
