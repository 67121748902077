export const ConnectWebsocket = () => {
  return {
    type: "WEBSOCKET_CONNECT",
  };
};

export const DisconnectWebsocket = () => {
  return {
    type: "WEBSOCKET_DISCONNECT",
  };
};

export const SendMessage = (message) => {
  return {
    type: "WEBSOCKET_SEND",
    message: message,
  };
};

export const SetWebsocketHubId = (id) => {
  return {
    type: "SET_WEBSOCKET_BOARD",
    id: id,
  };
};

export const SetWebsocketConnection = (isConnected) => {
  return {
    type: "SET_WEBSOCKET_CONNECTION",
    isConnected: isConnected,
  };
};

export const SetUnsyncedUpdates = (unsyncedUpdates) => {
  return {
    type: "SET_UNSYNCED_UPDATES",
    unsyncedUpdates: unsyncedUpdates,
  };
};

export const SendWebsocketSync = (sync) => {
  return {
    type: "SEND_WEBSOCKET_SYNC",
    sync: sync,
  };
};
