import React from "react";
import PropTypes from "prop-types";

export function LayoutBody({ children }) {
  return (
    <div
      style={{
        maxWidth: "calc(100% - 46px)",
        overflow: "hidden",
        overflowY: "auto",
        marginLeft: "2em",
        marginBottom: "1em",
      }}
    >
      {children}
    </div>
  );
}

LayoutBody.propTypes = {
  children: PropTypes.node,
};

LayoutBody.defaultProps = {
  children: null,
};

export default LayoutBody;
