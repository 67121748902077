export const initialState = [];

export const reducer = (state = initialState, action) => {
  if (action.reminders && action.type === "SET_REMINDERS") {
    return action.reminders;
  } else {
    return state;
  }
};

export const verReducer = (state = 0, { type }) => {
  if (type === "SET_REMINDERS") {
    return state + 1;
  }

  return state;
};

export default reducer;
