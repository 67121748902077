import { createSelector } from "reselect";

const getTheHubAdvisors = (state) => {
  return state.hubly.data.hub.selected.hub.advisors;
};

export function makeAdvisorDisplayName() {
  return createSelector([getTheHubAdvisors], (advisors) => {
    return advisors.map((advisor) => {
      return {
        ...advisor,
        displayName: `${advisor.firstName} ${advisor.lastName}`,
      };
    });
  });
}

export default () => {
  return createSelector(makeAdvisorDisplayName(), (displayName) => {
    return [...displayName];
  });
};
