import React, { Component } from "react";
import connect from "react-redux/es/connect/connect";
import { withRouter } from "react-router";
import PropTypes from "prop-types";

import { faGripVertical } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Header, Icon, Label, Segment } from "semantic-ui-react";

import { UpdateAdvisor } from "data/advisor/actions";
import {
  ClearFilters,
  HideShowWorkflow,
  SetFilters,
} from "data/filters/actions";
import { makeGetFilteredClientWorkflows } from "data/hub/clientWorkflows/selector";
import { filterClientWorkflows } from "data/libs/filters";
import { DeleteView } from "data/libs/views";
import { SetCurrentView, SetViews } from "data/views/actions";

import { SetAlert } from "components/Alerts/actions";
import { SetConfirmationModal } from "components/ConfirmationModal/actions";
import PopupMenu from "components/PopupMenu";
import Spinner from "components/Spinner";
import { SetViewModalOpen } from "components/ViewModal/actions";

class ViewTile extends Component {
  static propTypes = {
    advisor: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
    clearFilters: PropTypes.func.isRequired,
    currentView: PropTypes.shape({
      id: PropTypes.string.isRequired,
      description: PropTypes.string,
      name: PropTypes.string.isRequired,
    }).isRequired,
    index: PropTypes.number.isRequired,
    setAlert: PropTypes.func.isRequired,
    setConfirmationModal: PropTypes.func.isRequired,
    setCurrentView: PropTypes.func.isRequired,
    setFilters: PropTypes.func.isRequired,
    setNestedComponentOpen: PropTypes.func.isRequired,
    setViewModalOpen: PropTypes.func.isRequired,
    setViews: PropTypes.func.isRequired,
    updateAdvisor: PropTypes.func.isRequired,
    view: PropTypes.object.isRequired,
    views: PropTypes.array.isRequired,
    workflows: PropTypes.object.isRequired,
    clientWorkflows: PropTypes.object.isRequired,
    activeClients: PropTypes.object.isRequired,
    provided: PropTypes.object.isRequired,
  };

  state = {
    disabled: false,
    popupOpen: false,
    summary: null,
  };

  componentDidMount() {
    this.generateSummary();
  }

  confirmDelete = () => {
    const { setConfirmationModal, view } = this.props;
    const params = {
      title: "Delete View",
      message: `This will delete "${view.name}". This cannot be undone.`,
      icon: "delete",
      buttons: [
        {
          text: "Cancel",
        },
        {
          text: "Delete",
          callBack: this.deleteView,
          color: "red",
        },
      ],
    };
    setConfirmationModal(params);
    this.setPopupOpen(false);
  };

  deleteView = () => {
    const {
      advisor,
      clearFilters,
      currentView,
      index,
      setAlert,
      setViews,
      updateAdvisor,
      view,
      views,
      workflows,
    } = this.props;
    this.setState({ disabled: true });
    DeleteView(view.id)
      .then(() => {
        const viewsCopy = [...views];
        viewsCopy.splice(index, 1);
        setViews(viewsCopy);
        // Update current/toggled view if that is what we are deleting, otherwise can leave it
        if (currentView.id === view.id) {
          updateAdvisor(advisor.id, { defaultView: null });
          clearFilters(Object.values(workflows));
        }
        setAlert({ type: "success", text: "Successfully deleted view." });
        this.setState({ disabled: false });
      })
      .catch((error) => {
        console.warn(error);
        setAlert({ type: "error", text: "Failed to delete process." });
        this.setState({ disabled: false });
      });
  };

  editView = (view) => {
    const { setViewModalOpen } = this.props;
    this.setPopupOpen(false);
    setViewModalOpen(true, view);
  };

  setPopupOpen = (open) => {
    const { setNestedComponentOpen } = this.props;
    this.setState({ popupOpen: open });
    setNestedComponentOpen(open);
  };

  toggleView = () => {
    const {
      advisor,
      clearFilters,
      currentView,
      setCurrentView,
      setFilters,
      updateAdvisor,
      view,
      workflows,
    } = this.props;
    if (currentView.id !== view.id) {
      setCurrentView({ ...view, setCurrentViewTime: new Date().getTime() });
      setFilters(view.filter, false);
      updateAdvisor(advisor.id, { defaultView: view.id });
    } else {
      updateAdvisor(advisor.id, { defaultView: null });
      clearFilters(Object.values(workflows));
    }
  };

  generateSummary = async () => {
    const { view, clientWorkflows, activeClients, workflows, advisor } =
      this.props;
    const { filter } = view;

    const now = new Date().toISOString();
    let tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.setHours(0);
    tomorrow.setMinutes(0);
    tomorrow = tomorrow.toISOString();

    // figure out metrics about view (this seems expensive)
    let numActiveClientWorkflows = 0;
    let numRed = 0;
    let numYellow = 0;
    let numGreen = 0;
    const clientsInView = new Set();
    filter.workflows.forEach((workflowMeta) => {
      const workflow = workflows[workflowMeta.id];
      if (!workflow) return;
      const filteredClientWorkflows = filterClientWorkflows(
        workflow.clients,
        clientWorkflows,
        activeClients,
        filter,
        advisor
      );
      filteredClientWorkflows.forEach((clientWorkflow) => {
        if (!clientWorkflow.completed) numActiveClientWorkflows += 1;
        if (clientWorkflow) clientsInView.add(clientWorkflow.clientId);
        clientWorkflow.tasks.forEach((task) => {
          task.reminders.forEach((reminder) => {
            if (!reminder.completedAt && !reminder.dismissed) {
              if (reminder.time < now) numRed += 1;
              else if (reminder.time <= tomorrow) numYellow += 1;
              else numGreen += 1;
            }
          });
        });
      });
    });
    const numClientsInView = clientsInView.size;
    this.setState({
      summary: {
        numRed,
        numGreen,
        numYellow,
        numClientsInView,
        numActiveClientWorkflows,
      },
    });
  };

  render() {
    const { disabled, popupOpen, summary } = this.state;
    const { currentView, view, provided } = this.props;

    return (
      <Segment.Group
        data-test={`view-${view?.name}`}
        onClick={this.toggleView}
        className={`clickable grey_on_hover no_select ${
          currentView.id === view.id
            ? "highlighted_segment"
            : "disabled_segment"
        }`}
        style={{
          flexGrow: "1",
          boxSizing: "border-container",
          margin: "0px",
          opacity: disabled ? "0.5" : "1",
        }}
      >
        <Segment
          style={{ background: "none", display: "flex", alignItems: "center" }}
        >
          <div {...provided.dragHandleProps}>
            <FontAwesomeIcon
              className="dark_grey_on_hover"
              color="rgb(218, 218, 218)"
              icon={faGripVertical}
              style={{
                marginRight: "1em",
              }}
            />
          </div>
          <div style={{ width: "100%" }}>
            <div style={{ display: "flex" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                {view.color && (
                  <div
                    style={{
                      marginRight: "0.5em",
                      width: "8px",
                      height: "19px",
                      backgroundColor: view.color,
                    }}
                  />
                )}
                <Header style={{ display: "inline", marginTop: 0 }} as="h3">
                  {view.name}
                </Header>
              </div>
              <PopupMenu
                data-test="tile-popup"
                buttons={[
                  {
                    content: "Edit",
                    icon: "edit",
                    onClick: (event) => {
                      event.stopPropagation();
                      this.editView(view);
                    },
                  },
                  {
                    content: "Delete",
                    icon: "delete",
                    onClick: (event) => {
                      event.stopPropagation();
                      this.confirmDelete();
                    },
                  },
                ]}
                onOpen={(event) => {
                  event.stopPropagation();
                  this.setPopupOpen(true);
                }}
                onClose={(event) => {
                  event.stopPropagation();
                  this.setPopupOpen(false);
                }}
                open={popupOpen}
                popperModifiers={{
                  preventOverflow: {
                    boundariesElement: "window",
                    enabled: false,
                  },
                }}
                trigger={
                  <Icon
                    style={{ marginLeft: "auto" }}
                    name="ellipsis horizontal"
                    link
                    color="grey"
                  />
                }
              />
            </div>
            <div style={{ marginTop: "0.5em" }}>{view.description}</div>
          </div>
        </Segment>
        <Segment
          style={{
            background: "none",
            fontWeight: "bold",
            padding: "0.5em 1em",
          }}
        >
          {summary ? (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                fontWeight: "bold",
              }}
            >
              <div>
                <Icon
                  style={{ fontSize: "13pt", transform: "translateY(2px)" }}
                  name="clone outline"
                />
                {`${summary.numClientsInView} Active Client${
                  summary.numClientsInView !== 1 ? "s" : ""
                }`}
              </div>
              <div>
                <Icon
                  style={{
                    fontSize: "13pt",
                    transform: "translateY(3px) rotate(-90deg)",
                  }}
                  name="align right"
                />
                {`${summary.numActiveClientWorkflows} Active Workflow${
                  summary.numActiveClientWorkflows !== 1 ? "s" : ""
                }`}
              </div>
              <div>
                <Icon
                  style={{ fontSize: "13pt", transform: "translateY(2px)" }}
                  name="bell outline"
                />
                <Label
                  circular
                  style={{ background: "#ed6e6e", color: "white" }}
                >
                  {summary.numRed}
                </Label>
                <Label
                  circular
                  style={{ background: "#f4cf65", color: "white" }}
                >
                  {summary.numYellow}
                </Label>
                <Label
                  circular
                  style={{ background: "#49b676", color: "white" }}
                >
                  {summary.numGreen}
                </Label>
              </div>
            </div>
          ) : (
            <Spinner active />
          )}
        </Segment>
      </Segment.Group>
    );
  }
}

const makeMapStateToProps = () => {
  const clientWorkflowSelector = makeGetFilteredClientWorkflows();

  return (state, ownProps) => {
    return {
      advisor: state.hubly.data.advisor,
      currentView: state.hubly.data.views.currentView,
      views: state.hubly.data.views.views,
      workflows: state.hubly.data.hub.workflows,
      activeClients: state.hubly.data.hub.clients.activeClients,
      clientWorkflows: clientWorkflowSelector(state, ownProps),
    };
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearFilters: (workflows) => {
      dispatch(ClearFilters(workflows));
    },
    hideShow: (workflowId, hidden) => {
      dispatch(HideShowWorkflow(workflowId, hidden));
    },
    setAlert: (alert) => {
      dispatch(SetAlert(alert));
    },
    setConfirmationModal: (params) => {
      dispatch(SetConfirmationModal(params));
    },
    setCurrentView: (currentView) => {
      dispatch(SetCurrentView(currentView));
    },
    setFilters: (filters, isFilterUpdated) => {
      dispatch(SetFilters(filters, isFilterUpdated));
    },
    setViewModalOpen: (open, editView) => {
      dispatch(SetViewModalOpen(open, editView));
    },
    setViews: (views) => {
      dispatch(SetViews(views));
    },
    updateAdvisor: (id, request, callback) => {
      dispatch(UpdateAdvisor(id, request, callback));
    },
  };
};

export default withRouter(
  connect(makeMapStateToProps, mapDispatchToProps)(ViewTile)
);
