import React from "react";
import PropTypes from "prop-types";

import { Button } from "semantic-ui-react";

import Modal from "components/Modal";

import { Options, ServiceRoleFilter, TeamNameInput } from "..";

export function ServiceTeamModal({
  loading,
  editMode,
  description,
  duplicateMessage,
  name,
  roles,
  defaultTeamMembers,
  isDefaultTeamSelected,
  disableActionButton,
  actionText,
  onChangeAdvisor,
  onNameChange,
  onSave,
  onClose,
}) {
  return (
    <Modal isModalOpen data-test="manage-service-team-modal" onClose={onClose}>
      <Modal.Header onClose={onClose}>
        {!editMode ? "Add Service Team" : `Edit ${name}`}
      </Modal.Header>
      <Modal.Body>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            minHeight: "450px",
          }}
        >
          <p>{description}</p>

          <div data-test="filter-container">
            <Options data-test="service-role-filter">
              <TeamNameInput
                loading={loading}
                onChange={onNameChange}
                value={name}
                isDefaultTeamSelected={isDefaultTeamSelected}
              />
              {roles.map(
                ({ assignedAdvisors, advisorsPage, ...serviceRole }) => {
                  return (
                    <ServiceRoleFilter
                      loading={loading}
                      key={serviceRole.id}
                      serviceRole={serviceRole}
                      assignedAdvisors={assignedAdvisors}
                      defaultTeamMembers={
                        isDefaultTeamSelected ? [] : defaultTeamMembers
                      }
                      advisorsPage={advisorsPage}
                      editable={
                        !editMode ||
                        !serviceRole.isSynced ||
                        isDefaultTeamSelected
                      }
                      onChangeAdvisor={onChangeAdvisor}
                    />
                  );
                }
              )}
            </Options>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={{ textAlign: "left" }}>{duplicateMessage}</div>
          <div style={{ width: "320px", flexShrink: 0 }}>
            <React.Fragment>
              <Button style={{ width: "150px" }} onClick={onClose}>
                Cancel
              </Button>
              <Button
                style={{ width: "150px" }}
                onClick={onSave}
                loading={loading}
                disabled={loading || disableActionButton}
                positive
              >
                {actionText}
              </Button>
            </React.Fragment>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

ServiceTeamModal.propTypes = {
  loading: PropTypes.bool,
  editMode: PropTypes.bool,
  description: PropTypes.string,
  duplicateMessage: PropTypes.string,
  name: PropTypes.string,
  roles: PropTypes.array,
  isDefaultTeamSelected: PropTypes.bool,
  defaultTeamMembers: PropTypes.array,
  disableActionButton: PropTypes.bool,
  actionText: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onChangeAdvisor: PropTypes.func.isRequired,
  onNameChange: PropTypes.func.isRequired,
};

ServiceTeamModal.defaultProps = {
  loading: false,
  editMode: false,
  description: "No available roles, please create one more service roles.",
  duplicateMessage: "",
  name: "",
  roles: [],
  defaultTeamMembers: [],
  isDefaultTeamSelected: false,
  disableActionButton: false,
  actionText: "Add",
};

export default ServiceTeamModal;
