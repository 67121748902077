import React from "react";
import PropTypes from "prop-types";

import { Icon } from "semantic-ui-react";

import Popper from "components/Popper";

export function StreamPopup({ streams }) {
  return (
    <React.Fragment>
      {streams.map(({ name, color }) => {
        return (
          <Popper
            basic
            inverted
            content={name}
            key={name}
            size="mini"
            style={{
              padding: "0.5em",
            }}
            trigger={<Icon name="circle" style={{ color: color }} />}
          />
        );
      })}
    </React.Fragment>
  );
}

StreamPopup.propTypes = {
  streams: PropTypes.array.isRequired,
};

export default StreamPopup;
