import React, { useCallback } from "react";

const AccessTypeContext = React.createContext();

export function AccessTypeProvider({
  initState = { ready: false },
  children = null,
}) {
  const [store, dispatch] = React.useReducer((state, { type, value }) => {
    switch (type) {
      case "set-access":
        return { ...state, ready: true, readOnly: !value };
      default:
        return initState;
    }
  }, initState);

  return (
    <AccessTypeContext.Provider value={{ dispatch, store }}>
      {children}
    </AccessTypeContext.Provider>
  );
}

export function WithAccessTypeProvider(WrappedComponent) {
  return (props) => {
    return (
      <AccessTypeProvider>
        <WrappedComponent {...props} />
      </AccessTypeProvider>
    );
  };
}

export function useAccessTypeStore() {
  const {
    store: { ready, readOnly },
    dispatch,
  } = React.useContext(AccessTypeContext);

  const calcAccess = () => {
    if (!ready) return false;
    return !readOnly;
  };

  return {
    ready: ready,
    setAccessStatus: useCallback(
      (canAccess) => {
        dispatch({ type: "set-access", value: canAccess });
      },
      [dispatch]
    ),
    hasAccess: calcAccess,
  };
}

export default useAccessTypeStore;
