import React from "react";
import PropTypes from "prop-types";

import { Table } from "@material-ui/core";

function TableContent({ children }) {
  return (
    <Table stickyHeader size="small">
      {children}
    </Table>
  );
}
TableContent.propTypes = {
  children: PropTypes.node.isRequired,
};
export default TableContent;
