import { API } from "aws-amplify";

import SyncedAPI from "data/libs/syncedAPI";

import { getAPIQuery, getAPIReq } from "./authLib";

export const GetStreams = async () => {
  const req = await getAPIReq();
  return API.get("HublyAPI", `/streams/`, req);
};

export const GetStream = async (id) => {
  const req = await getAPIReq();
  return API.get("HublyAPI", `/streams/${id}/`, req);
};

export const CreateStream = async (request) => {
  const req = await getAPIReq(request);
  return SyncedAPI.post("HublyAPI", `/streams/`, req, (response) => {
    return { type: "ADD_STREAM", stream: response };
  });
};

export const EditStream = async (id, request) => {
  const req = await getAPIReq(request);
  return SyncedAPI.put("HublyAPI", `/streams/${id}/`, req, (response) => {
    return { type: "EDIT_STREAM", stream: response };
  });
};

export const DeleteStream = async (id) => {
  const req = await getAPIReq();
  return SyncedAPI.del("HublyAPI", `/streams/${id}/`, req, () => {
    return { type: "REMOVE_STREAM", streamId: id };
  });
};

export const ClientsInStream = async (id) => {
  const req = await getAPIReq();
  return API.get("HublyAPI", `/streams/${id}/clients/`, req);
};

export const ClientsWithStreamsInStream = async (id) => {
  const req = await getAPIReq();
  return API.get("HublyAPI", `/streams/${id}/clientsWithStreams/`, req);
};

export const GetStreamReport = async (id, query) => {
  const req = await getAPIQuery(query);
  req.responseType = "blob";
  req.isBase64Encoded = true;
  return API.get("HublyAPI", `/streams/${id}/report/`, req);
};
