import React, { useEffect } from "react";
import PropTypes from "prop-types";

import ScriptLoader from "components/ScriptLoader";

import { useHubSpot, useHubSpotToken } from "./hooks";

export function HubSpotChatWidget({ email }) {
  const { apiLoaded, loadWidget } = useHubSpot();
  const {
    data: { token, portalId },
  } = useHubSpotToken();

  useEffect(() => {
    if (portalId && apiLoaded && token) loadWidget(token, email);
  }, [portalId, token, email, apiLoaded, loadWidget]);

  if (!portalId) return null;

  return <ScriptLoader scriptSrc={`//js.hs-scripts.com/${portalId}.js`} />;
}

HubSpotChatWidget.propTypes = {
  email: PropTypes.string.isRequired,
};

export default HubSpotChatWidget;
