const handleAction = (action, store, metric) => {
  try {
    metric.emit(action, store);
  } catch (err) {
    if (process.env.REACT_APP_WARN_ENABLED) {
      console.warn("Could not emit action", action, store, metric);
    }
  }
};

export default function createMiddleware(params) {
  const {
    isEnabled = () => {
      return false;
    },
    publishers = [],
  } = params;

  if (!isEnabled()) {
    if (process.env.REACT_APP_LOG_ENABLED) {
      // eslint-disable-next-line no-console
      console.log("Analytics middleware is off");
    }

    return (store) => {
      return (next) => {
        return (action) => {
          return next(action);
        };
      };
    };
  }

  return (store) => {
    return (next) => {
      return (action) => {
        publishers
          .filter((p) => {
            return p.predicate(action, store);
          })
          .forEach((p) => {
            return handleAction(action, store, p.metric);
          });

        return next(action);
      };
    };
  };
}
