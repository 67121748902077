import { useEffect, useRef } from "react";

import { useBooleanState } from ".";

export function useAsyncCall() {
  const mounted = useRef(false);
  const [running, setRunning, stopRunning] = useBooleanState();

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  const makeCall = async (action) => {
    if (mounted.current) {
      setRunning();
      await action();
      if (mounted.current) stopRunning();
    }
  };

  return [makeCall, running];
}

export default useAsyncCall;
