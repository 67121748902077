import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Checkbox } from "semantic-ui-react";

import { ToggleEmptyWorkflows } from "data/filters/actions";

class EmptyWorkflowToggle extends React.Component {
  static propTypes = {
    hub: PropTypes.shape({
      advisors: PropTypes.array.isRequired,
    }).isRequired,
    filters: PropTypes.object.isRequired,
    setFilters: PropTypes.func.isRequired,
  };

  toggleEmptyWorkflows = () => {
    const { filters, setFilters } = this.props;
    const filtersCopy = { ...filters };
    filtersCopy.filterEmptyWorkflows = !filtersCopy.filterEmptyWorkflows;
    setFilters(filtersCopy);
  };

  render() {
    const { filters } = this.props;
    return (
      <Checkbox
        data-test="filter-menu-hide-empty-workflow-toggle"
        toggle
        checked={filters.filterEmptyWorkflows}
        label="Hide workflows with no clients"
        onChange={this.toggleEmptyWorkflows}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    hub: state.hubly.data.hub.selected.hub,
    selectedHub: state.hubly.data.hub.selected.hub,
    filters: state.hubly.data.filters,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setFilters: (filters) => {
      dispatch(ToggleEmptyWorkflows(filters));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmptyWorkflowToggle);
