import React from "react";
import PropTypes from "prop-types";

import { Button, Icon, Popup } from "semantic-ui-react";

export function FilterLayout({
  title,
  showFilterTypeControl,
  filterType,
  onFilterTypeUpdated,
  onClearFilter,
  children,
}) {
  return (
    <React.Fragment>
      <div
        style={{ display: "flex", alignItems: "center", marginTop: "0.5em" }}
      >
        <div style={{ width: "220px", display: "flex", alignItems: "center" }}>
          <div
            style={{
              marginRight: "0.5em",
              flexGrow: 1,
              fontWeight: "bold",
            }}
          >
            {title}
          </div>
          {showFilterTypeControl && (
            <div
              style={{ marginRight: "0.5em" }}
              data-test={`filter-${String(title)
                .toLowerCase()
                .replaceAll(" ", "-")}-all-any`}
            >
              <Button.Group>
                <Button
                  primary={filterType === "ALL"}
                  compact
                  onClick={() => {
                    onFilterTypeUpdated("ALL");
                  }}
                >
                  ALL
                </Button>
                <Button
                  primary={filterType === "ANY"}
                  compact
                  onClick={() => {
                    onFilterTypeUpdated("ANY");
                  }}
                >
                  ANY
                </Button>
              </Button.Group>
            </div>
          )}
          {showFilterTypeControl && (
            <div>
              <Popup
                on="hover"
                wide
                position="right center"
                trigger={<Icon color="grey" name="question circle" />}
              >
                Use ALL to display client cards where all selected filters
                apply. Use ANY to display client cards where any of the selected
                filters apply
              </Popup>
            </div>
          )}
        </div>
        <div
          style={{
            padding: "1px 1em",
            height: "max-content",
          }}
        >
          <div
            style={{
              width: "400px",
              maxHeight: "400px",
            }}
          >
            {children}
          </div>
        </div>
        <div>
          <Popup
            content="Clear"
            on="hover"
            position="top center"
            trigger={
              <Icon
                data-test={`filter-menu-${String(title)
                  .toLowerCase()
                  .replaceAll(" ", "-")}-clear`}
                name="delete"
                onClick={onClearFilter}
                link
                color="grey"
              />
            }
          />
        </div>
      </div>
    </React.Fragment>
  );
}

FilterLayout.propTypes = {
  title: PropTypes.string.isRequired,
  onClearFilter: PropTypes.func,
  children: PropTypes.node,
  filterType: PropTypes.string,
  showFilterTypeControl: PropTypes.bool,
  onFilterTypeUpdated: PropTypes.func,
};

FilterLayout.defaultProps = {
  onClearFilter: () => {},
  showFilterTypeControl: false,
  children: undefined,
  filterType: "",
  onFilterTypeUpdated: () => {},
};

export default FilterLayout;
