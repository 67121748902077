import { API } from "aws-amplify";

import SyncedAPI from "data/libs/syncedAPI";

import { getAPIReq } from "./authLib";

export const GetDateTypes = async (hub) => {
  const req = await getAPIReq();
  return API.get("HublyAPI", `/hubs/${hub.id}/dateTypes/`, req);
};

export const CreateDateType = async (request) => {
  const req = await getAPIReq(request);
  return SyncedAPI.post("HublyAPI", `/dateTypes/`, req);
};

export const EditDateType = async (id, request) => {
  const req = await getAPIReq(request);
  return SyncedAPI.put("HublyAPI", `/dateTypes/${id}/`, req);
};

export const DeleteDateType = async (id) => {
  const req = await getAPIReq();
  return SyncedAPI.del("HublyAPI", `/dateTypes/${id}/`, req);
};

export const BulkUpdateDateTypes = async (request) => {
  const req = await getAPIReq(request);
  return API.put("HublyAPI", `/dateTypes/bulkUpdate/`, req);
};
