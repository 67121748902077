import React from "react";
import PropTypes from "prop-types";

import { MultipleClientDropdown } from "components/ClientDropdown";
import Selector from "components/Selector";

import { attributeTypeOptions, ruleActionOptions } from "../../helper";
import { AddOrRemoveStreamLayout } from "./components";

export function AddOrRemoveStreamRuleForm({ register }) {
  const typeSelectorProps = register({ name: "type" });
  const actionSelectorProps = register({ name: "action" });
  return (
    <AddOrRemoveStreamLayout
      typeSelector={
        <Selector
          name={typeSelectorProps.name}
          selected={typeSelectorProps.value}
          dropdownProps={{
            fluid: true,
            search: false,
            compact: true,
          }}
          selection={attributeTypeOptions}
          multiple={false}
          onSelect={(val) => {
            typeSelectorProps.onChange(null, {
              name: typeSelectorProps.name,
              value: val,
            });
          }}
        />
      }
      streamsTagsSelector={
        <MultipleClientDropdown
          {...register({
            name: "selectedStreamsTags",
            required: true,
            errortext: "Select streams or tags",
          })}
          data-test="ruleModal-dropdown-condition"
          placeholder="Select streams or tags"
          showStreams
          showTags
          dropdownStyle={{
            marginBottom: 0,
            width: "350px",
            marginLeft: "1em",
          }}
        />
      }
      actionSelector={
        <Selector
          name={actionSelectorProps.name}
          selected={actionSelectorProps.value}
          dropdownProps={{
            fluid: true,
            search: false,
            compact: true,
          }}
          selection={ruleActionOptions}
          multiple={false}
          onSelect={(val) => {
            actionSelectorProps.onChange(null, {
              name: actionSelectorProps.name,
              value: val,
            });
          }}
        />
      }
    />
  );
}

AddOrRemoveStreamRuleForm.propTypes = {
  register: PropTypes.func.isRequired,
};

export default AddOrRemoveStreamRuleForm;
