import React from "react";
import PropTypes from "prop-types";

import { Button } from "semantic-ui-react";

export function SortingMenu({ onClickSort, isSorting }) {
  return (
    <React.Fragment>
      <Button
        icon="sort alphabet down"
        content="Name (Ascending)"
        disabled={Boolean(isSorting)}
        loading={isSorting === "AlphabeticalOrderAsc"}
        onClick={() => {
          return onClickSort("AlphabeticalOrderAsc");
        }}
      />
      <Button
        icon="sort alphabet up"
        content="Name (Descending)"
        disabled={Boolean(isSorting)}
        loading={isSorting === "AlphabeticalOrderDesc"}
        onClick={() => {
          return onClickSort("AlphabeticalOrderDesc");
        }}
      />
      <Button
        icon="bell"
        content="Reminders"
        disabled={Boolean(isSorting)}
        loading={isSorting === "ClientsReminder"}
        onClick={() => {
          return onClickSort("ClientsReminder");
        }}
      />
      <Button
        icon="sort amount up"
        content="Date Added (Newest First)"
        disabled={Boolean(isSorting)}
        loading={isSorting === "ClientsDateNewest"}
        onClick={() => {
          return onClickSort("ClientsDateNewest");
        }}
      />
      <Button
        icon="sort amount down"
        content="Date Added (Oldest First)"
        disabled={Boolean(isSorting)}
        loading={isSorting === "ClientsDateOldest"}
        onClick={() => {
          return onClickSort("ClientsDateOldest");
        }}
      />
      <Button
        icon="calendar alternate outline"
        content="Next Meeting Date"
        disabled={Boolean(isSorting)}
        loading={isSorting === "MeetingDate"}
        onClick={() => {
          return onClickSort("MeetingDate");
        }}
      />
    </React.Fragment>
  );
}

SortingMenu.propTypes = {
  onClickSort: PropTypes.func.isRequired,
  isSorting: PropTypes.string,
};

SortingMenu.defaultProps = { isSorting: "" };

export default SortingMenu;
