import React from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { datadogRum } from "@datadog/browser-rum";
import getMiliSecondDuration from "analytics/helper";
import { Card, Icon } from "semantic-ui-react";

import "./Workflow.css";

import { SetFilters } from "data/filters/actions";
import {
  DeleteClientWorkflow,
  SetClientWorkflow,
  SetClientWorkflows,
  sortClientWorkflow,
} from "data/hub/clientWorkflows/actions";
import {
  BulkUpdateWorkflowTasks,
  SetWorkflow,
} from "data/hub/workflows/actions";
import { BulkUpdateClientWorkflows } from "data/libs/clientWorkflows";
import { BulkUpdateTasks } from "data/libs/tasks";

import { SetAlert } from "components/Alerts/actions";

import EditTileSettingsModal from "./components/EditTileSettingsModal";
import HubMenu from "./components/HubMenu";
import NewWorkflow from "./components/HubMenu/NewWorkflow";
import WorkflowLibraryModal from "./components/HubMenu/WorkflowLibrary";
import TemplatesFeatureFlag from "./components/HubMenu/WorkflowLibrary/TemplatesFeatureFlag";
import Workflow from "./components/Workflow";
import {
  CreateNewClientWorkflow,
  MoveClientToNextWorkflow,
  UpdateClientWorkflowOrder,
} from "./actions";

class Workflows extends React.Component {
  static propTypes = {
    hub: PropTypes.object.isRequired,
    bulkUpdateWorkflowTasks: PropTypes.func.isRequired,
    clientWorkflows: PropTypes.object.isRequired,
    currentView: PropTypes.shape({
      id: PropTypes.string.isRequired,
      setCurrentViewTime: PropTypes.string,
    }).isRequired,
    deleteClientWorkflow: PropTypes.func.isRequired,
    filters: PropTypes.shape({
      filterEmptyWorkflows: PropTypes.bool.isRequired,
      workflows: PropTypes.array.isRequired,
    }).isRequired,
    setAlert: PropTypes.func.isRequired,
    setFilters: PropTypes.func.isRequired,
    setClientWorkflow: PropTypes.func.isRequired,
    setClientWorkflows: PropTypes.func.isRequired,
    setWorkflow: PropTypes.func.isRequired,
    workflows: PropTypes.object.isRequired,
    moveClientToNextWorkflow: PropTypes.func.isRequired,
    createNewClientWorkflow: PropTypes.func.isRequired,
    updateClientWorkflowOrder: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.workflowsRef = React.createRef();
  }

  state = {
    editTileWorkflow: null,
  };

  componentDidUpdate(prevProps) {
    const { currentView } = this.props;
    if (
      currentView.setCurrentViewTime &&
      prevProps.currentView.setCurrentViewTime !==
        currentView.setCurrentViewTime
    ) {
      datadogRum.addAction("hubly_timespent_view_display_view", {
        duration: getMiliSecondDuration(currentView.setCurrentViewTime),
      });
    }
  }

  scrollRight = () => {
    const element = this.workflowsRef.current;
    element.scrollIntoView({ behavior: "smooth", inline: "nearest" });
  };

  setEditTilesModal = (workflow) => {
    this.setState({ editTileWorkflow: workflow });
  };

  getMaxOrderValue = (items) => {
    const { clientWorkflows } = this.props;
    let max = 0;
    items.forEach((item) => {
      if (item in clientWorkflows) {
        if (clientWorkflows[item].order && clientWorkflows[item].order > max) {
          max = clientWorkflows[item].order;
        }
      }
    });

    return max;
  };

  simulateAddDummyClientToWorkflow = (request, toWorkflow, clientId) => {
    const { deleteClientWorkflow, setClientWorkflow, setWorkflow } = this.props;
    setClientWorkflow({ clientId: clientId, ...request, id: "dummyId" });
    setWorkflow({ ...toWorkflow, clients: [...toWorkflow.clients, "dummyId"] });

    return () => {
      deleteClientWorkflow("dummyId");
      const updatedClients = toWorkflow?.clients?.filter((clientWorkflowId) => {
        return clientWorkflowId !== "dummyId";
      });

      setWorkflow({ ...toWorkflow, clients: updatedClients });
    };
  };

  onDragEnd = (result) => {
    const {
      bulkUpdateWorkflowTasks,
      clientWorkflows,
      filters,
      setClientWorkflow,
      setFilters,
      setWorkflow,
      workflows,
      deleteClientWorkflow,
      updateClientWorkflowOrder,
      setAlert,
    } = this.props;

    // dropped outside the list
    if (!result.destination) {
      return;
    }

    if (result.type === "WORKFLOWS") {
      const filtersCopy = { ...filters };
      filtersCopy.workflows = this.reorder(
        filtersCopy.workflows,
        result.source.index,
        result.destination.index
      );
      setFilters(filtersCopy);
    } else if (result.type === "TASKS") {
      // moving tasks
      const workflowId = result.destination.droppableId.split("_")[1];
      const workflowCopy = { ...workflows[workflowId] };

      const tasks = this.reorder(
        workflowCopy.tasks,
        result.source.index,
        result.destination.index
      );

      const newTasks = JSON.parse(JSON.stringify(tasks));
      const tasksRequest = {
        tasks: [],
      };
      const max = this.getMaxOrderValue(newTasks);
      newTasks.forEach((task, i) => {
        task.order = max + i + 1;
        tasksRequest.tasks.push({
          id: task.id,
          order: max + i + 1,
        });
      });

      bulkUpdateWorkflowTasks(newTasks); // Updates state
      BulkUpdateTasks(tasksRequest, workflowCopy.id); // API call
    } else if (result.type === "TILES") {
      if (result.destination.droppableId === result.source.droppableId) {
        // dragging within workflows
        const workflowId = result.destination.droppableId.split("_")[1];
        const workflowCopy = { ...workflows[workflowId] };

        const sortClientWorkflows = workflowCopy.clients
          .filter((clientWorkflowId) => {
            return clientWorkflowId in clientWorkflows;
          })
          .sort((a, b) => {
            return sortClientWorkflow(a, b, clientWorkflows);
          });

        const newClientWorkflows = this.reorder(
          sortClientWorkflows,
          result.source.index,
          result.destination.index
        );

        workflowCopy.clients = newClientWorkflows;
        setWorkflow(workflowCopy);

        updateClientWorkflowOrder(
          {
            targetIndex: result.destination.index,
            workflowList: newClientWorkflows,
            workflowId: workflowId,
          },
          (res) => {
            if (!res) {
              setAlert({
                type: "error",
                text: "Failed to reorder client workflow.",
              });
              // Revert the sorting on failure
              workflowCopy.clients = sortClientWorkflows;
              setWorkflow(workflowCopy);
            }
          }
        );
      } else {
        // dragging between workflows
        const toWorkflowId = result.destination.droppableId.split("_")[1];
        const toWorkflowCopy = { ...workflows[toWorkflowId] };

        const id = result.draggableId.split("_")[0];
        const includes = toWorkflowCopy.clients.find((c) => {
          return clientWorkflows[c].clientId === id && !c.isHidden;
        });

        const sourceWorkflowId = result.source.droppableId.split("_")[1];
        const sourceWf = { ...workflows[sourceWorkflowId] };
        const sourceClientWorkflowId = sourceWf?.clients?.find((c) => {
          return clientWorkflows[c].clientId === id && !c.isHidden;
        });

        const foundNextWorklowInSource = clientWorkflows[
          sourceClientWorkflowId
        ]?.nextWorkflows?.find((nwf) => {
          return nwf?.nextWorkflowId === toWorkflowId;
        });

        if (includes && !toWorkflowCopy.options?.multipleTimePerClient) {
          this.props.setAlert({
            type: "warning",
            text: "Unable to add client to this workflow.",
          });

          return;
        }
        const max = this.getMaxOrderValue(toWorkflowCopy.clients);

        const clientWorkflowRequest = {
          clientWorkflows: [],
        };

        let index = 0;
        toWorkflowCopy.clients.forEach((clientWorkflowId) => {
          const clientWorkflow = clientWorkflows[clientWorkflowId];
          if (index === result.destination.index) {
            index += 1;
          }
          clientWorkflow.order = max + index + 1;
          clientWorkflowRequest.clientWorkflows.push({
            id: clientWorkflow.id,
            order: max + index + 1,
          });
          index += 1;
        });

        if (
          clientWorkflowRequest.clientWorkflows &&
          clientWorkflowRequest.clientWorkflows.length > 0
        ) {
          BulkUpdateClientWorkflows(clientWorkflowRequest);
        }

        const { moveClientToNextWorkflow, createNewClientWorkflow } =
          this.props;

        if (foundNextWorklowInSource) {
          const request = {
            nextWorkflowId: foundNextWorklowInSource.id,
            completed: sourceWf?.completed,
            previousWorkflowId: sourceClientWorkflowId,
            order: max + result.destination.index + 1,
          };

          moveClientToNextWorkflow(
            request,
            (removeFromPreviousClientsNextWF) => {
              removeFromPreviousClientsNextWF(
                clientWorkflows,
                sourceClientWorkflowId,
                toWorkflowId,
                setClientWorkflow
              );
            },
            (simulateAddDummyClientToWorkflow) => {
              return simulateAddDummyClientToWorkflow(
                request,
                toWorkflowCopy,
                id,
                setClientWorkflow,
                setWorkflow,
                deleteClientWorkflow
              );
            }
          );
        } else {
          const request = {
            workflowId: toWorkflowCopy.id,
            clientId: id,
            order: max + result.destination.index + 1,
          };

          createNewClientWorkflow(
            request,
            (simulateAddDummyClientToWorkflow) => {
              return simulateAddDummyClientToWorkflow(
                request,
                toWorkflowCopy,
                id,
                setClientWorkflow,
                setWorkflow,
                deleteClientWorkflow
              );
            }
          );
        }
      }
    }
  };

  // a little function to help us with reordering the result
  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  showWorkflows = () => {
    const { filters, workflows } = this.props;
    const shownWorkflows = filters.workflows
      ? filters.workflows.map((filterWorkflow) => {
          return workflows[filterWorkflow.id];
        })
      : [];

    return (
      <DragDropContext onDragEnd={this.onDragEnd} closeIcon>
        <Droppable
          droppableId="droppable"
          direction="horizontal"
          type="WORKFLOWS"
        >
          {(provided, snapshot) => {
            return (
              <div
                ref={provided.innerRef}
                style={{
                  display: "flex",
                  padding: "0px",
                  marginTop: "calc(1.5em + 30px)",
                }}
                {...provided.droppableProps}
              >
                {shownWorkflows.map((workflow, index) => {
                  return (
                    <Workflow
                      key={workflow.id}
                      workflowId={workflow.id}
                      index={index}
                      openEditTileModal={this.setEditTilesModal}
                    />
                  );
                })}
                <div
                  ref={this.workflowsRef}
                  style={{ width: "350px", minWidth: "350px", float: "left" }}
                />
                {provided.placeholder}
              </div>
            );
          }}
        </Droppable>
      </DragDropContext>
    );
  };

  showEmptyState = () => {
    return (
      <div
        style={{
          display: "flex",
          width: "100vw",
          height: "calc(100vh - 100px)",
          top: "100px",
          minHeight: "500px",
          padding: "0 15%",
          alignItems: "center",
          position: "absolute",
          left: "0px",
          justifyContent: "space-around",
        }}
        data-test="new-hub-homepage"
      >
        <TemplatesFeatureFlag>
          <Card style={{ width: "220px", margin: 0 }}>
            <Card.Content header="Select" style={{ textAlign: "center" }} />
            <Card.Content
              style={{
                display: "flex",
                justifyContent: "center",
                paddingLeft: "1.5em",
              }}
            >
              <WorkflowLibraryModal />
            </Card.Content>
            <Card.Content extra style={{ textAlign: "center" }}>
              To generate a group of preconfigured workflows.
            </Card.Content>
          </Card>
        </TemplatesFeatureFlag>

        <TemplatesFeatureFlag showOnEnableWithStyle>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Icon size="huge" name="map signs" />
            <h3>Welcome to Hubly!</h3>
            <span style={{ color: "grey", fontStyle: "italic" }}>
              Let&apos;s get started
            </span>
          </div>

          <Card style={{ margin: 0, width: "220px" }}>
            <Card.Content header="Select" style={{ textAlign: "center" }} />
            <Card.Content
              style={{
                display: "flex",
                justifyContent: "center",
                paddingLeft: "2em",
              }}
            >
              <NewWorkflow scrollRight={this.scrollRight} />
            </Card.Content>
            <Card.Content extra style={{ textAlign: "center" }}>
              To make a workflow from scratch.
            </Card.Content>
          </Card>
        </TemplatesFeatureFlag>
      </div>
    );
  };

  render() {
    const { workflows } = this.props;
    const { editTileWorkflow } = this.state;
    const workflowsLength = Object.keys(workflows).length;
    return (
      <div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <HubMenu scrollRight={this.scrollRight} />
          <div>{workflowsLength > 0 && this.showWorkflows()}</div>
          {workflowsLength === 0 && this.showEmptyState()}
          {editTileWorkflow && (
            <EditTileSettingsModal
              workflow={editTileWorkflow}
              setEditTilesModal={this.setEditTilesModal}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    hub: state.hubly.data.hub.selected.hub,
    clientWorkflows: state.hubly.data.hub.clientWorkflows,
    filters: state.hubly.data.filters,
    workflows: state.hubly.data.hub.workflows,
    currentView: state.hubly.data.views.currentView,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    bulkUpdateWorkflowTasks: (tasks) => {
      dispatch(BulkUpdateWorkflowTasks(tasks));
    },
    deleteClientWorkflow: (clientWorkflowId) => {
      dispatch(DeleteClientWorkflow(clientWorkflowId));
    },
    setAlert: (alert) => {
      dispatch(SetAlert(alert));
    },
    setClientWorkflow: (clientWorkflow) => {
      dispatch(SetClientWorkflow(clientWorkflow));
    },
    setClientWorkflows: (clientWorkflows) => {
      dispatch(SetClientWorkflows(clientWorkflows));
    },
    setFilters: (filters, isFilterUpdated) => {
      dispatch(SetFilters(filters, isFilterUpdated));
    },
    setWorkflow: (workflow) => {
      dispatch(SetWorkflow(workflow));
    },
    updateClientWorkflowOrder: (props, callback) => {
      dispatch(UpdateClientWorkflowOrder(props, callback));
    },
    createNewClientWorkflow: (
      request,
      callSimulateAddDummyClientToWorkflow
    ) => {
      dispatch(
        CreateNewClientWorkflow(request, callSimulateAddDummyClientToWorkflow)
      );
    },
    moveClientToNextWorkflow: (
      request,
      callRemoveFromPreviousClientsNextWF,
      callSimulateAddDummyClientToWorkflow
    ) => {
      dispatch(
        MoveClientToNextWorkflow(
          request,
          callRemoveFromPreviousClientsNextWF,
          callSimulateAddDummyClientToWorkflow
        )
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Workflows);
