import React from "react";
import PropTypes from "prop-types";

export function Layout({ streamsTagsSelector, typeSelector, actionSelector }) {
  return (
    <React.Fragment>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ display: "flex", marginBottom: "1em" }}>
          <div data-test="ruleModal-dropdown-type" style={{ width: "7em" }}>
            {typeSelector}
          </div>
          {streamsTagsSelector}
        </div>
        <div style={{ display: "flex", marginBottom: "1em" }}>
          <div style={{ paddingTop: "0.75em " }}>when a client</div>
          <div
            data-test="ruleModal-dropdown-action"
            style={{ width: "11em", marginLeft: ".5em" }}
          >
            {actionSelector}
          </div>
          <div style={{ marginLeft: "1em", paddingTop: "0.75em " }}>
            this workflow
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

Layout.propTypes = {
  streamsTagsSelector: PropTypes.node,
  typeSelector: PropTypes.node,
  actionSelector: PropTypes.node,
};

Layout.defaultProps = {
  streamsTagsSelector: null,
  typeSelector: null,
  actionSelector: null,
};

export default Layout;
