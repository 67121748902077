import React from "react";
import { useParams } from "react-router-dom";

import Loading from "components/Loading";

import HubLinker from "./HubLinker";
import Hubly from "./Hubly";
import HublyLegacy from "./HublyLegacy";
import useNewHublyApp from "./useNewHublyApp";

function Decider(props) {
  const { loadingConfig, newApp } = useNewHublyApp();
  const { hubId } = useParams();

  if (loadingConfig) {
    return <Loading active messages={["Loading your workflows"]} />;
  }

  const Component = newApp ? Hubly : HublyLegacy;

  return (
    <HubLinker currentHubId={hubId}>
      <Component {...props} />
    </HubLinker>
  );
}

export default Decider;
