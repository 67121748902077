import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Header } from "semantic-ui-react";

import DragDropList from "components/DragDropList";
import DraggableTile from "components/DraggableTile";
import Spinner from "components/Spinner";

import {
  HubContext,
  ToasterContext,
} from "scenes/Hubly/components/Workspace/Provider";

import { useAccessTypeStore } from "../../../hooks";
import {
  isProcessDisabled,
  mapProcessToDraggableTileProps,
  searchInputFilter,
} from "../../helper";
import { ProcessWorkflowsText } from "..";

export function ProcessList({
  processes,
  loading,
  selectedWorkflows,
  placeholder,
  filteredKeyword,
  children,
  onChange,
  onEditProcessClick,
  onDeleteProcessClick,
  onReordered,
}) {
  const { reOrderProcess } = useContext(HubContext);
  const { fireError } = useContext(ToasterContext);

  const [processTileState, setProcessTileState] = useState({});
  const { hasAccess } = useAccessTypeStore();

  const filteredProcesses = processes.filter((process) => {
    return searchInputFilter(filteredKeyword, process);
  });

  useEffect(() => {
    setProcessTileState({});
  }, [filteredKeyword]);

  const isWorkflowListShown = (processId) => {
    if (processId in processTileState) return processTileState[processId];
    return filteredKeyword.length > 0;
  };

  const updateProcessOrder = ({
    draggedItemIndex,
    items,
    previousOrderedItems,
  }) => {
    const targetProcess = items[draggedItemIndex];
    const nextProcess =
      draggedItemIndex + 1 < items.length ? items[draggedItemIndex + 1] : null;

    reOrderProcess(targetProcess.id, nextProcess?.id)
      .then(() => {
        onReordered(items);
      })
      .catch((error) => {
        fireError("Failed to reorder process.", error);
        onReordered(previousOrderedItems);
      });
  };

  const toggleProcess = (showProcess, process) => {
    if (showProcess) {
      const newWorkflows = process.workflows.filter((pWf) => {
        return !selectedWorkflows.some(({ id: wfId }) => {
          return pWf.id === wfId;
        });
      });
      onChange([...selectedWorkflows, ...newWorkflows]);
    } else {
      onChange(
        selectedWorkflows.filter((selectedWf) => {
          return !process.workflows.some((w) => {
            return w.id === selectedWf.id;
          });
        })
      );
    }
  };

  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Header style={{ margin: "0 0 1em 0" }} as="h4">
          Processes
        </Header>
        <Spinner active={loading} size="tiny" />
      </div>
      {!placeholder && filteredProcesses.length === 0 && (
        <div style={{ color: "grey", margin: "auto" }}>
          {filteredKeyword !== ""
            ? "No matching process found."
            : "You have no process."}
        </div>
      )}
      {placeholder}
      {filteredProcesses.length > 0 && (
        <DragDropList
          id="drag-and-drop-process-list"
          items={filteredProcesses}
          onListReordered={updateProcessOrder}
          isDropDisabled={filteredKeyword !== ""}
        >
          {(process, processDragHandleProps) => {
            const active = !isProcessDisabled(
              selectedWorkflows,
              process.workflows
            );
            const expandWorkflowList = isWorkflowListShown(process.id);
            return (
              <DraggableTile
                key={process.id}
                {...mapProcessToDraggableTileProps(process)}
                subTitle={
                  <ProcessWorkflowsText
                    workflows={process.workflows}
                    filteredKeyword={filteredKeyword}
                  />
                }
                filteredKeyword={filteredKeyword}
                dragHandleProps={processDragHandleProps}
                expandSummaryRow={expandWorkflowList}
                active={active}
                disabled={!hasAccess()}
                onEditClick={() => {
                  onEditProcessClick(process);
                }}
                onDeleteClick={() => {
                  onDeleteProcessClick(process);
                }}
                onSummaryRowClick={() => {
                  setProcessTileState((prevState) => {
                    return { ...prevState, [process.id]: !expandWorkflowList };
                  });
                }}
                onClick={() => {
                  toggleProcess(!active, process);
                }}
              >
                {children(process)}
              </DraggableTile>
            );
          }}
        </DragDropList>
      )}
    </React.Fragment>
  );
}

ProcessList.defaultProps = {
  filteredKeyword: "",
  loading: false,
  children: () => {},
  onReordered: () => {},
  placeholder: null,
};

ProcessList.propTypes = {
  loading: PropTypes.bool,
  placeholder: PropTypes.node,
  filteredKeyword: PropTypes.string,
  onReordered: PropTypes.func,
  processes: PropTypes.array.isRequired,
  selectedWorkflows: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onEditProcessClick: PropTypes.func.isRequired,
  onDeleteProcessClick: PropTypes.func.isRequired,
  children: PropTypes.func,
};

export default ProcessList;
