import React from "react";

import { Icon, Popup } from "semantic-ui-react";

import FeedContentFilter from "./FeedContentFilter";

export default function FeedSettingsPopup() {
  const [open, setOpen] = React.useState(false);

  return (
    <Popup
      flowing
      open={open}
      onClose={() => {
        return setOpen(false);
      }}
      onOpen={() => {
        return setOpen(true);
      }}
      on="click"
      position="bottom right"
      trigger={
        <div
          data-test="hubfeed-settings-popup"
          className="clickable grey_on_hover rounded p25"
        >
          <Icon color="grey" name="setting" />
        </div>
      }
    >
      <FeedContentFilter
        onDone={() => {
          return setOpen(false);
        }}
      />
    </Popup>
  );
}
