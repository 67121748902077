import { SetActiveClient } from "data/hub/clients/actions";
import { SetClientWorkflow } from "data/hub/clientWorkflows/actions";
import { AddLink, DeleteLink, EditLink } from "data/libs/links";
import { EditTask } from "data/libs/tasks";

import { SetAlert } from "components/Alerts/actions";

export function EditTaskDetails(clientWorkflow, taskId, taskUpdates, callback) {
  return (dispatch, getState) => {
    const { title, description, isClientTask } = taskUpdates;
    EditTask(
      taskId,
      { title, description, isClientTask },
      clientWorkflow.clientId
    )
      .then((response) => {
        const workflowCopy = { ...clientWorkflow };

        const foundTaskIndex = workflowCopy.tasks.findIndex((task) => {
          return task.id === taskId;
        });
        if (foundTaskIndex < 0) {
          console.warn("EditTaskDetails, Task ID ", taskId, " does not exist");
          return;
        }
        workflowCopy.tasks[foundTaskIndex].description = description;
        workflowCopy.tasks[foundTaskIndex].title = title;
        workflowCopy.tasks[foundTaskIndex].isClientTask = isClientTask;
        dispatch(SetClientWorkflow(workflowCopy));
        if (callback) callback(true);
      })
      .catch((error) => {
        dispatch(SetAlert({ type: "error", text: "Failed to set details" }));
        if (callback) callback(false);
      });
  };
}

export function AddLinkToTask(clientWorkflowId, taskId, title, url) {
  return (dispatch, getState) => {
    const clientWorkflow = {
      ...getState().hubly.data.hub.clientWorkflows[clientWorkflowId],
    };

    const request = {
      title: title,
      url: url,
      taskId: taskId,
    };
    const foundWorkflow = { ...clientWorkflow };
    if (!foundWorkflow) {
      console.warn(
        "AddLinkToTask, Client Workflow ID ",
        clientWorkflowId,
        " does not exist"
      );
      return;
    }

    const foundTaskIndex = foundWorkflow.tasks.findIndex((task) => {
      return task.id === taskId;
    });
    if (foundTaskIndex < 0) {
      console.warn("AddLinkToTask, Task ID ", taskId, " does not exist");
      return;
    }
    AddLink(request)
      .then((response) => {
        foundWorkflow.tasks[foundTaskIndex].links.unshift(response);
        dispatch(SetClientWorkflow(foundWorkflow));
        dispatch(
          SetAlert({ type: "success", text: `Successfully added link` })
        );
      })
      .catch((error) => {
        dispatch(SetAlert({ type: "error", text: `Failed to create link.` }));
      });
  };
}

export function DeleteLinkFromTask(clientWorkflowId, taskId, linkId, callback) {
  return (dispatch, getState) => {
    const clientWorkflow = {
      ...getState().hubly.data.hub.clientWorkflows[clientWorkflowId],
    };
    DeleteLink(linkId)
      .then((response) => {
        const foundWorkflow = { ...clientWorkflow };
        if (!foundWorkflow) {
          console.warn(
            "AddLinkToTask, Client Workflow ID ",
            clientWorkflowId,
            " does not exist"
          );
          return;
        }

        const foundTask = foundWorkflow.tasks.find((task) => {
          return task.id === taskId;
        });
        if (!foundTask) {
          console.warn("AddLinkToTask, Task ID ", taskId, " does not exist");
          return;
        }
        const foundLinkIndex = foundTask.links.findIndex((link) => {
          return link.id === linkId;
        });
        if (foundLinkIndex < 0) {
          console.warn(
            "DeleteLinkFromTask, Link ID ",
            linkId,
            " does not exist"
          );
          return;
        }
        foundTask.links.splice(foundLinkIndex, 1);
        dispatch(SetClientWorkflow(foundWorkflow));
        dispatch(
          SetAlert({ type: "success", text: `Successfully added link` })
        );
      })
      .catch((error) => {
        dispatch(SetAlert({ type: "error", text: `Failed to create link.` }));
      });
  };
}

export function EditLinkInTask(clientWorkflowId, taskId, linkId, title, url) {
  return (dispatch, getState) => {
    const clientWorkflow = {
      ...getState().hubly.data.hub.clientWorkflows[clientWorkflowId],
    };
    const client =
      getState().hubly.data.hub.clients.activeClients[clientWorkflow.clientId];
    const clientCopy = { ...client };
    const oldClient = { ...client };

    if (!clientWorkflow) {
      console.warn(
        "EditLinkInTask, Client Workflow ID ",
        clientWorkflowId,
        " does not exist"
      );
      return;
    }
    const foundTask = clientWorkflow.tasks.find((task) => {
      return task.id === taskId;
    });
    if (!foundTask) {
      console.warn("EditLinkInTask, Task ID ", taskId, " does not exist");
      return;
    }
    const foundLink = foundTask.links.find((link) => {
      return link.id === linkId;
    });
    if (!foundLink) {
      console.warn("EditLinkInTask, Link ID ", linkId, " does not exist");
      return;
    }
    foundLink.title = title;
    foundLink.url = url;
    dispatch(SetActiveClient(clientCopy));

    const request = {
      title: title,
      url: url,
      taskId: taskId,
    };
    EditLink(linkId, request)
      .then((response) => {
        dispatch(
          SetAlert({ type: "success", text: `Successfully edited link` })
        );
      })
      .catch((error) => {
        dispatch(SetActiveClient(oldClient));
        dispatch(SetAlert({ type: "error", text: `Failed to edit link.` }));
      });
  };
}
