import React from "react";

import { getAllHubWorkflows } from "scenes/Hubly/components/Workspace/api/workflows";
import useNewHublyApp from "scenes/Hubly/useNewHublyApp";

async function resolveAllWorkflows(hub) {
  const toReduxObj = ({ multipleTimePerClient, ...w }) => {
    return { clients: [], options: { multipleTimePerClient }, ...w };
  };

  const { data } = await getAllHubWorkflows(hub);
  return [data.map(toReduxObj), true];
}

async function resolveEmpty() {
  return [[], false];
}

export function withWorkflowResolver(Component) {
  return (props) => {
    const { loadingConfig, newApp } = useNewHublyApp();

    if (loadingConfig) return null;

    const resolve = newApp ? resolveAllWorkflows : resolveEmpty;
    return <Component {...props} resolveAllWorkflows={resolve} />;
  };
}

export default withWorkflowResolver;
