import React from "react";
import PropTypes from "prop-types";

import {
  Name,
  ReminderItem,
  Spinner,
  usePrivacyMode,
  UserAvatar,
} from "./components";

export function ReminderTile({ reminder, spinning }) {
  const { piiMask } = usePrivacyMode();

  const { title, dismissed, completedAt, time, task, client } = reminder;
  const { assignee } = task || {};

  return (
    <React.Fragment>
      <ReminderItem
        title={title}
        time={time}
        completed={Boolean(completedAt || dismissed)}
        clientNameSlot={
          client && (
            <Name
              fullName={`${client.firstName} ${client.lastName}`}
              suffixClassName={piiMask("fs-block dd-privacy-mask")}
            />
          )
        }
      >
        <Spinner active={spinning} />
        {assignee && !spinning && <UserAvatar user={assignee} size="large" />}
      </ReminderItem>
    </React.Fragment>
  );
}

ReminderTile.propTypes = {
  reminder: PropTypes.shape({
    title: PropTypes.string.isRequired,
    time: PropTypes.string.isRequired,
    dismissed: PropTypes.bool.isRequired,
    completedAt: PropTypes.string,
    client: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
    }),
    task: PropTypes.shape({
      assignedAdvisor: PropTypes.shape({
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
      }),
    }),
  }).isRequired,
  spinning: PropTypes.bool,
};

ReminderTile.defaultProps = {
  spinning: false,
};

export default ReminderTile;
