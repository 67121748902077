import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import { Auth } from "aws-amplify";
import { Button, Form, Icon, Input, Message, Modal } from "semantic-ui-react";

const EditEmailModal = ({ isModalOpen, closeModal, advisorEmail }) => {
  const history = useHistory();
  const [error, setError] = useState(null);
  const [values, setValues] = useState({
    newEmail: "",
    newEmailConfirm: "",
    code: "",
  });

  const [codeSent, setCodeSent] = useState(false);

  const [isConfirming, setIsConfirming] = useState(false);
  const [isSendingCode, setIsSendingCode] = useState(false);
  const [emailChangeSuccess, setEmailChangeSuccess] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  async function signOut() {
    try {
      await Auth.signOut();
      history.push("/");
    } catch (err) {
      console.error(err);
      setError({
        title: "Something went wrong! Please try again.",
        content: err,
      });
    }
  }

  async function handleEmailChangeSubmit(e) {
    e.preventDefault();

    setIsSendingCode(true);
    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(user, { email: values.newEmail });
      setCodeSent(true);
      setIsSendingCode(false);
      setError(null);
    } catch (err) {
      console.error(err);
      setError({
        title: "Something went wrong!",
        content: err,
      });
      if (err.code === "InvalidParameterException") {
        setError({
          title: "Invalid Email Provided.",
          content: { message: "Email address is invalid" },
        });
      }
      setIsSendingCode(false);
    }
  }

  async function handleCodeSubmit(event) {
    event.preventDefault();

    setIsConfirming(true);

    try {
      await Auth.verifyCurrentUserAttributeSubmit("email", values.code);
      setIsConfirming(false);
      setEmailChangeSuccess(true);
      setError(null);
      setTimeout(() => {
        return signOut();
      }, 3000);
    } catch (err) {
      console.error(err);
      setError({
        title: "Something went wrong!",
        content: err,
      });
      setIsConfirming(false);
    }
  }

  const ErrorMessageComponent = () => {
    return (
      <Message error hidden={!error}>
        <Message.Header>
          {error && error.title ? error.title : null}
        </Message.Header>
        {error && error.content ? (
          <p>{error.content.message || JSON.stringify(error.content)}</p>
        ) : null}
      </Message>
    );
  };

  const renderUpdateForm = () => {
    return (
      <Modal.Content>
        <ErrorMessageComponent />
        <Modal.Description>
          <Form>
            <h4>Current Email Address</h4>
            <p>{advisorEmail}</p>
            <Form.Field>
              <label>New Email Address</label>
              <Input
                name="newEmail"
                type="email"
                value={values.newEmail}
                onChange={handleInputChange}
              />
            </Form.Field>
          </Form>
        </Modal.Description>
        <Modal.Actions style={{ float: "right", padding: "1.5em 0" }}>
          <Button
            content="Discard Changes"
            basic
            disabled={isSendingCode}
            onClick={closeModal}
          />
          <Button
            positive
            content="Save Changes"
            onClick={handleEmailChangeSubmit}
            disabled={isSendingCode || values.newEmail === ""}
            loading={isSendingCode}
          />
        </Modal.Actions>
      </Modal.Content>
    );
  };

  const renderVerifyCode = () => {
    return (
      <Modal.Content>
        <ErrorMessageComponent />
        {!emailChangeSuccess ? (
          <React.Fragment>
            <Modal.Description>
              <Form>
                <Form.Field>
                  <h4>Confirmation Code</h4>
                  <p>
                    A confirmation code has been sent to the provided new email
                    address. Please enter the code below to confirm email
                    change.
                  </p>
                  <Input
                    name="code"
                    type="text"
                    value={values.code}
                    onChange={handleInputChange}
                  />
                </Form.Field>
              </Form>
            </Modal.Description>
            <Modal.Actions style={{ float: "right", padding: "1.5em 0" }}>
              <Button
                content="Resend Code"
                basic
                disabled={isConfirming}
                onClick={() => {
                  return Auth.verifyCurrentUserAttribute("email");
                }}
              />
              <Button
                positive
                content="Submit"
                onClick={handleCodeSubmit}
                disabled={isConfirming}
                loading={isConfirming}
              />
            </Modal.Actions>
          </React.Fragment>
        ) : (
          <Modal.Description>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                padding: "1.5em 0",
              }}
            >
              <h4 style={{ textAlign: "center", marginBottom: "1em" }}>
                Email address updated successfully!
              </h4>
              <div>
                <Icon name="check circle" size="huge" color="green" />
              </div>
              <p
                style={{
                  padding: "2em 1em",
                  color: "grey",
                  textAlign: "center",
                }}
              >
                You will be logged out shortly. Please Sign In with your new
                credentials.
              </p>
              <div>
                <Button primary content="Sign Out Now" onClick={signOut} />
              </div>
            </div>
          </Modal.Description>
        )}
      </Modal.Content>
    );
  };

  return (
    <Modal
      onClose={closeModal}
      size="mini"
      open={isModalOpen}
      closeOnDimmerClick={false}
      style={{ minWidth: "360px" }}
      id="EditEmailModal"
    >
      <Modal.Header>Change Email Address</Modal.Header>
      {!codeSent ? renderUpdateForm() : renderVerifyCode()}
    </Modal>
  );
};

EditEmailModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  advisorEmail: PropTypes.string.isRequired,
};

export default EditEmailModal;
