import React from "react";
import PropTypes from "prop-types";

import { makeStyles, TableContainer } from "@material-ui/core";

const useStyles = makeStyles({
  tableContainer: {
    position: "relative",
    width: "100%",
    height: "100%",
  },
  table: {
    minWidth: 750,
  },
});

const Table = ({ children, ...props }) => {
  const classes = useStyles();

  return (
    <TableContainer className={classes.tableContainer} {...props}>
      {children}
    </TableContainer>
  );
};

Table.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Table;
