import React from "react";
import PropTypes from "prop-types";

import { useFeatures } from "components/FeatureFlag";

import Assigner from "../../../../../Assigner";
import NextTaskAssigner from "./NextTaskAssigner";

function ClientTileAssigner({ data, fetchAssignees, onAssign }) {
  const reassignTask = useFeatures("reassign-tasks");
  const { id, assignedAdvisor, tasksStats } = data;

  if (reassignTask) {
    return (
      <NextTaskAssigner
        tasks={tasksStats?.filteredTasks || []}
        onAssign={onAssign}
      />
    );
  }

  return (
    <Assigner
      currentAssignee={assignedAdvisor}
      fetchAssignees={fetchAssignees}
      onAssign={(newAssignedId) => {
        return onAssign({ assignee: newAssignedId, cwId: id });
      }}
    />
  );
}

ClientTileAssigner.propTypes = {
  data: PropTypes.object.isRequired,
  fetchAssignees: PropTypes.func.isRequired,
  onAssign: PropTypes.func.isRequired,
};

export default ClientTileAssigner;
