import { API } from "aws-amplify";

import { getAPIReq } from "data/libs/authLib";

import toGlobalId from "./helper";

export const getStreams = async ({
  hubId,
  searchText = "",
  hasClients = null,
  first = 100,
  after = "",
}) => {
  const req = await getAPIReq(
    {
      query: `query getStreams($searchText: String, $hasClients: Boolean, $first: Int, $after: String) {
            searchStreams(searchText: $searchText, hasClients: $hasClients, first: $first, after: $after) {
              edges {
                node {
                  id
                  uuid
                  name
                  color
                }
              }
              pageInfo {
                hasNextPage
                endCursor
              }
            }
          }          
            `,
      variables: {
        searchText: searchText,
        hasClients: hasClients,
        first: first,
        after: after,
      },
    },
    { hub: hubId }
  );

  const {
    data: { searchStreams },
  } = await API.post("HublyAPI", `/graphql`, req);
  return {
    data: searchStreams.edges.map(({ node }) => {
      return {
        ...node,
        id: node.uuid,
      };
    }),
    pageInfo: searchStreams.pageInfo,
  };
};

export const getStream = async ({ hubId, streamId }) => {
  const req = await getAPIReq(
    {
      query: `query getStream($streamId: ID!) {
          stream(id: $streamId) {
            uuid
            name
            color
          }
        }`,
      variables: {
        streamId: toGlobalId("Stream", streamId),
      },
    },
    { hub: hubId }
  );

  const {
    data: { stream },
  } = await API.post("HublyAPI", `/graphql`, req);
  return {
    ...stream,
    id: stream.uuid,
  };
};
export const getClientsInStream = async ({
  hubId,
  iDs,
  householdTitle = null,
  pageSize = 100,
  after = "",
  offset = null,
}) => {
  const req = await getAPIReq(
    {
      query: `query getClientsInStream($streamIds: [UUID], $householdTitle: String, $first: Int, $after: String, $offset: Int) {
        clients(streams_Id_In: $streamIds, householdMember_Title:$householdTitle, first: $first, after: $after, offset: $offset) {
          edges {
            node {
              id
              uuid
              firstName
              lastName
              name
              household{
                uuid
              }
              streams {
                edges {
                  node {
                    id
                    uuid
                    name
                    color
                  }
                }
              }
            }
          }
          totalCount
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }`,
      variables: {
        streamIds: iDs,
        householdTitle: householdTitle,
        first: pageSize,
        after: after,
        offset: offset,
      },
    },
    { hub: hubId }
  );

  const {
    data: { clients },
  } = await API.post("HublyAPI", `/graphql`, req);
  return {
    data: clients.edges.map(({ node }) => {
      return {
        ...node,
        id: node.uuid,
        household: node.household
          ? {
              id: node.household.uuid,
            }
          : {},
        name: node.name ? node.name : `${node.firstName} ${node.lastName}`,
        streams: node.streams.edges.map(({ node: sNode }) => {
          return {
            ...sNode,
            id: sNode.uuid,
          };
        }),
      };
    }),
    pageInfo: clients.pageInfo,
    totalCount: clients.totalCount,
  };
};

export const getStreamClientHouseholdCount = async ({
  hubId,
  streamId,
  excludeWorkflowId,
}) => {
  const req = await getAPIReq(
    {
      query: `query getStreamClientAndHouseholdHeadCount($streamId: ID!, $excludeWorkflowId: ID!) {
        streamClientHouseholdCount(streamId: $streamId, excludeWorkflowId: $excludeWorkflowId) {
            numHouseholdHead
            numClient
        }
    }`,
      variables: {
        streamId: toGlobalId("Stream", streamId),
        excludeWorkflowId: toGlobalId("Workflow", excludeWorkflowId),
      },
    },
    { hub: hubId }
  );

  const {
    data: { streamClientHouseholdCount },
  } = await API.post("HublyAPI", `/graphql`, req);
  return streamClientHouseholdCount;
};

export default getStreams;
