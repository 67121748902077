import React, { useState } from "react";
import PropTypes from "prop-types";

import { Button, Popup } from "semantic-ui-react";

export function CreateButtonWithIntegration({
  integrationButtonText,
  loading,
  disabled,
  onClick,
}) {
  const [popupOpen, setPopupOpen] = useState(false);
  return (
    <Popup
      trigger={
        <Button
          disabled={disabled || loading}
          primary
          floated="right"
          loading={loading}
        >
          Create
        </Button>
      }
      flowing
      position="bottom center"
      on="click"
      open={popupOpen}
      onClose={() => {
        setPopupOpen(false);
      }}
      onOpen={() => {
        setPopupOpen(true);
      }}
      style={{ padding: "0" }}
    >
      <Button.Group basic vertical>
        <Button
          content={integrationButtonText}
          onClick={() => {
            onClick({ editInCrm: false });
            setPopupOpen(false);
          }}
          style={{
            textAlign: "left",
            paddingLeft: "1em",
            width: "12em",
          }}
        />
        <Button
          content="Edit in CRM"
          onClick={() => {
            onClick({ editInCrm: true });
            setPopupOpen(false);
          }}
          style={{
            textAlign: "left",
            paddingLeft: "1em",
            width: "12em",
          }}
        />
      </Button.Group>
    </Popup>
  );
}

CreateButtonWithIntegration.defaultProps = {
  integrationButtonText: "Save and Close",
  loading: false,
  disabled: false,
};

CreateButtonWithIntegration.propTypes = {
  integrationButtonText: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default CreateButtonWithIntegration;
