import SetAdvisor from "data/advisor/actions";
import { SetHub, SetInProgress } from "data/hub/actions";
import {
  GetAllClients,
  SetActiveClients,
  SetAllClientNames,
  SetRecentClientNames,
} from "data/hub/clients/actions";
import { SetHouseholds } from "data/hub/households/actions";
import { SetWorkflows } from "data/hub/workflows/actions";
import { GetAdvisor } from "data/libs/advisor";
import { GetAllClientNames, GetRecentClientNames } from "data/libs/clients";
import { GetHouseholds } from "data/libs/households";
import { GetHub } from "data/libs/hubs";
import { GetReminders } from "data/libs/reminders";
import { GetWorkflows } from "data/libs/workflows";
import { SetReminders } from "data/reminders/actions";

export const ReloadDataAfterSync = (hub) => {
  return (dispatch, _) => {
    GetAdvisor().then((resp) => {
      dispatch(SetAdvisor(resp));
    });
    GetWorkflows(hub).then((resp) => {
      dispatch(SetWorkflows(resp));
      dispatch(SetInProgress(false, 0));
    });
    GetAllClients(hub).then((resp) => {
      dispatch(SetActiveClients(resp));
    });
    GetAllClientNames(hub).then((resp) => {
      dispatch(SetAllClientNames(resp));
    });
    GetRecentClientNames(hub).then((resp) => {
      dispatch(SetRecentClientNames(resp));
    });
    GetHouseholds(hub).then((resp) => {
      dispatch(SetHouseholds(resp));
    });
    GetHub(hub.id).then((resp) => {
      dispatch(SetHub(resp));
    });
    // Load reminders with an initial active query, just like the HubFeed
    const query = {
      client_id__isnull: false,
      limit: 999999999,
      ordering: "time",
      active: true,
    };
    GetReminders(hub, query).then((resp) => {
      dispatch(SetReminders(resp.results));
    });
  };
};

export default ReloadDataAfterSync;
