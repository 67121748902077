import React from "react";
import PropTypes from "prop-types";

import { Icon, Modal } from "semantic-ui-react";

import { AppearanceController, ClientTileSample, Layout } from "./components";

export function ClientTileEditor({
  title,
  current,
  submit,
  onSuccess,
  onWarning,
  onError,
  onClose,
}) {
  const [settings, setSettings] = React.useState(current);
  const updateSettings = (newValue) => {
    setSettings(newValue);
    submit(newValue)
      .then(() => {
        onSuccess("Successfully update tile settings.");
      })
      .catch((error) => {
        onError("Failed to update tile setting.", error);
        setSettings(current);
      });
  };

  return (
    <Modal data-test="edit-tile-settings-modal" open style={{ width: "auto" }}>
      <Modal.Header>
        <Icon
          link
          style={{ float: "right" }}
          color="grey"
          name="delete"
          onClick={onClose}
        />
        {`Edit tile for "${title}"`}
      </Modal.Header>
      <Modal.Content image>
        <Layout>
          <Layout.Item>
            <ClientTileSample {...settings} />
          </Layout.Item>
          <Layout.Item empty>
            <AppearanceController
              value={settings}
              onMessage={onWarning}
              onChange={updateSettings}
            />
          </Layout.Item>
        </Layout>
      </Modal.Content>
    </Modal>
  );
}

ClientTileEditor.propTypes = {
  title: PropTypes.string.isRequired,
  current: PropTypes.object.isRequired,
  submit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onWarning: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ClientTileEditor;
