import { isArray, isEqual, sortBy } from "lodash";

import { useFilterContext } from "../../hooks";

export function useFilterComparator(currentView) {
  const [{ filters, workflows }] = useFilterContext();
  if (!currentView) {
    return { modified: Boolean(!currentView) && workflows.length > 0 };
  }
  const { filters: viewFilters, workflows: viewWfs } = currentView;

  const selectSorter = (keepOrder) => {
    return keepOrder
      ? (v) => {
          return v;
        }
      : sortBy;
  };

  const mapValue = ({ toId = false, keepOrder = false } = {}) => {
    const sort = selectSorter(keepOrder);
    return toId
      ? (val) => {
          return sort(
            val.map(({ id }) => {
              return id;
            })
          );
        }
      : (val) => {
          return isArray(val) ? sort(val) : val;
        };
  };

  const toComparatorArray = ({ key, map }) => {
    return [map(viewFilters[key]), map(filters[key])];
  };

  const valueChanged = ([oldVal, newVal]) => {
    return !isEqual(oldVal, newVal);
  };

  return {
    modified: [
      { key: "streams", map: mapValue({ toId: true }) },
      { key: "tags", map: mapValue({ toId: true }) },
      { key: "clients", map: mapValue({ toId: true }) },
      { key: "reminderTypes", map: mapValue() },
      { key: "streamFilterType", map: mapValue() },
      { key: "tagFilterType", map: mapValue() },
      { key: "filterEmptyWorkflows", map: mapValue() },
      { key: "filterNextTaskForAssignee", map: mapValue() },
      { key: "assignee", map: mapValue() },
      { key: "assigneeFilterType", map: mapValue() },
      { key: "filterServiceRoleUnassignedOnly", map: mapValue() },
      { key: "filterAssigneeIncludeServiceRole", map: mapValue() },
    ]
      .map(toComparatorArray)
      .concat([
        [
          mapValue({ toId: true, keepOrder: true })(viewWfs),
          mapValue({ toId: true, keepOrder: true })(workflows),
        ],
      ])
      .some(valueChanged),
  };
}

export default useFilterComparator;
