import React from "react";
import PropTypes from "prop-types";

import { Checkbox, Label } from "semantic-ui-react";

export function MultipleTimePerClientOption({
  disabled,
  multipleTimePerClient,
  onSetToggle,
}) {
  const applyToggle = (enabled) => {
    const toggle = { multipleTimePerClient: enabled };
    return enabled ? { ...toggle, presentWorkflowName: true } : toggle;
  };

  return (
    <div>
      <div>
        <Checkbox
          disabled={disabled}
          data-test="workflow-options-multiple-per-client-check"
          toggle
          checked={multipleTimePerClient}
          label="Allow this workflow to be added more than once for a client"
          onChange={() => {
            onSetToggle(applyToggle(!multipleTimePerClient));
          }}
        />
      </div>
      {multipleTimePerClient && (
        <div style={{ marginTop: "10px" }}>
          <Label>
            It is not possible to add a client to this workflow more than once
            via Automation Rules or via Streams or Tags at this time.
          </Label>
        </div>
      )}
    </div>
  );
}

MultipleTimePerClientOption.propTypes = {
  multipleTimePerClient: PropTypes.bool,
  disabled: PropTypes.bool,
  onSetToggle: PropTypes.func.isRequired,
};

MultipleTimePerClientOption.defaultProps = {
  multipleTimePerClient: false,
  disabled: false,
};

export default MultipleTimePerClientOption;
