import React, { useContext, useState } from "react";
import connect from "react-redux/es/connect/connect";
import PropTypes from "prop-types";

import { Dropdown, Header, Icon } from "semantic-ui-react";

import history from "data/history";
import { SetInProgress } from "data/hub/actions";
import {
  SetLoadRecentClientNames,
  SetRecentClientNames,
} from "data/hub/clients/actions";
import {
  GetClientCardOpened,
  getOrderedFullClientName,
  GetRecentClientNames,
  sortClients,
} from "data/libs/clients";
import { CreateSync, GetSync } from "data/libs/sync";

import { SetAlert } from "components/Alerts/actions";
import { OpenCreateClientModal } from "components/CreateClientModal/actions";
import MarkedText from "components/MarkedText";
import { PrivacyModeContext } from "components/PrivacyMode/Context";

import ChevronOption from "./components/ChevronOption";
import ClientSyncOption from "./components/ClientSyncOption";
import ReloadDataAfterSync from "./actions";
import { fuzzyWordMatch, recordDatadogContactAction } from "./helpers";

export function ContactSearch({
  hub,
  advisor,
  allClientNames,
  allClientNamesLoaded,
  inProgress,
  recentClientNames,
  recentClientNamesLoaded,
  integration,
  openCreateClientModal,
  getRecentClientNames,
  ...props
}) {
  const context = useContext(PrivacyModeContext);
  const { piiMask } = context;
  const [searchInput, setSearchInput] = useState("");
  const [showAllContacts, setShowAllContacts] = useState(false);
  const [showTeamRecentContacts, setShowTeamRecentContacts] = useState(false);
  const [dropdownOpened, setDropdownOpened] = useState(false);
  const [dropdownOpenedTime, setDropdownOpenedTime] = useState(0);
  const [loading, setLoading] = useState(false);

  const noop = () => {};

  const syncClientsComplete = () => {
    const { reloadDataAfterSync } = props;
    reloadDataAfterSync(hub);
  };

  const syncClientsFailed = (error) => {
    const { setInProgress, setAlert } = props;
    console.warn(error);
    setInProgress(false, 0);
    if (error && error.response && error.response.status === 422) {
      setAlert({ type: "warning", text: "Client sync already in progress." });
    } else {
      setAlert({ type: "error", text: "Failed to sync clients from CRM." });
    }
  };

  const checkSyncComplete = (syncId) => {
    const { setInProgress } = props;
    GetSync(syncId)
      .then((response) => {
        if (response.status === "failed") {
          syncClientsFailed();
        } else if (response.status === "completed") {
          setInProgress(true, 100);
          syncClientsComplete();
        } else {
          let percent = 10; // Set to a default value until we know the number of total pages
          if (response.totalPages) {
            percent = (response.currentPage / response.totalPages) * 100;
          }
          setInProgress(true, percent);
          setTimeout(() => {
            checkSyncComplete(syncId);
          }, 1000);
        }
      })
      .catch(() => {
        syncClientsFailed();
      });
  };

  const syncClients = () => {
    const { setInProgress } = props;
    if (integration) {
      const request = {
        type: "contacts",
        advisor_id: advisor.id,
        hub_id: hub.id,
      };
      setInProgress(true, 0);
      CreateSync(request)
        .then((response) => {
          const syncId = response.id;
          setTimeout(() => {
            checkSyncComplete(syncId);
          }, 1000);
        })
        .catch(syncClientsFailed);
    }
  };

  const addClient = (e, { value }) => {
    openCreateClientModal(searchInput);
    setSearchInput("");
  };

  const onDropdownOpened = (e) => {
    setDropdownOpenedTime(new Date().getTime());
    setDropdownOpened(true);
    getRecentClientNames(hub);
  };

  const recordCustomAction = (source, createdTimestamp) => {
    const actionName =
      searchInput !== "" && source === "all_contacts"
        ? "search_by_name"
        : source;
    recordDatadogContactAction(actionName, createdTimestamp);
  };

  const fetchClientCardOpened = (clientId) => {
    setLoading(true);
    GetClientCardOpened(clientId)
      .then(({ clientWorkflowId = null }) => {
        history.push(
          `/hub/${hub.hubId}/clients/${clientId}${
            clientWorkflowId ? `/workflows/${clientWorkflowId}` : ""
          }`
        );
      })
      .catch(() => {
        history.push(`/hub/${hub.hubId}/clients/${clientId}`);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const clientSelected = (e, { value, source }) => {
    if (
      (e.type === "click" || (e.type === "keydown" && e.key === "Enter")) &&
      value !== "recent-contact-header"
    ) {
      if (value === "create") {
        addClient(e, { value });
      } else if (value === "team-recent-contact-header") {
        setShowTeamRecentContacts(!showTeamRecentContacts);
      } else if (value === "all-contact-header") {
        setShowAllContacts(!showAllContacts);
      } else if (value === "sync") {
        syncClients();
        setSearchInput("");
      } else {
        recordCustomAction(source, dropdownOpenedTime);
        setSearchInput("");
        fetchClientCardOpened(value);
      }
    }
  };

  const createContactOptions = (source) => {
    return (a, i) => {
      return {
        fullname: getOrderedFullClientName(a, hub),
        key: `${source}_${a.id}_${i}`,
        value: a.id,
        firstname: a.firstName,
        lastname: a.lastName,
        middlename: a.middleName,
        nickname: a.nickname,
        source: source,
        "data-test": "client-items",
        content: (
          <MarkedText
            text={getOrderedFullClientName(a, hub)}
            keyword={searchInput}
          />
        ),
        onClick: clientSelected,
      };
    };
  };

  const createRecentContactOptions = () => {
    const options = [];

    if (recentClientNames.recentNames?.length > 0) {
      options.push({
        id: "recent-contact-header",
        key: "recent-contact-header",
        value: "recent-contact-header",
        content: <Header as="h5">My Recent Contacts</Header>,
        disabled: true,
        onClick: noop,
      });
      const toOptions = createContactOptions("my_recent_contact");
      options.push(...recentClientNames.recentNames.map(toOptions));
    }

    return options;
  };

  const createTeamRecentContactOptions = () => {
    const options = [];

    if (recentClientNames.teamRecentNames?.length > 0) {
      options.push({
        key: "team-recent-contact-header",
        value: "team-recent-contact-header",
        content: (
          <ChevronOption
            text="All Recent Contacts"
            chevronOpened={showTeamRecentContacts}
          />
        ),
        onClick: () => {
          setShowTeamRecentContacts(!showTeamRecentContacts);
        },
      });

      if (showTeamRecentContacts) {
        const toOptions = createContactOptions("team_recent_contact");
        options.push(...recentClientNames.teamRecentNames.map(toOptions));
      }
    }

    return options;
  };

  const createAllContactOptions = () => {
    const options = [];
    const sortedClientNames = sortClients(allClientNames, hub);

    if (searchInput === "" && allClientNames.length !== 0) {
      options.push({
        key: "all-contact-header",
        value: "all-contact-header",
        "data-test": "client-item-all-contacts",
        content: (
          <ChevronOption text="All Contacts" chevronOpened={showAllContacts} />
        ),
        onClick: () => {
          setShowAllContacts(!showAllContacts);
        },
      });
    }

    if (showAllContacts || searchInput !== "") {
      const toOptions = createContactOptions("all_contacts");
      options.push(...sortedClientNames.map(toOptions));
    }

    return options;
  };

  const createSyncOption = () => {
    const options = [];
    if (integration) {
      options.unshift({
        key: "sync",
        value: "sync",
        style: { fontWeight: "bold" },
        content: (
          <ClientSyncOption
            onClick={syncClients}
            inProgress={inProgress}
            integrationType={integration.type}
          />
        ),
        disabled: inProgress,
        onClick: () => {
          setSearchInput("");
        },
      });
    }
    return options;
  };

  const createAddButton = () => {
    return [
      {
        key: "create",
        value: "create",
        style: { fontWeight: "bold" },
        content: <div>+ Create {searchInput}</div>,
        onClick: addClient,
      },
    ];
  };

  const createOptions = () => {
    if (searchInput === "") {
      return [
        ...createRecentContactOptions(),
        ...createAddButton(),
        ...createSyncOption(),
        ...createTeamRecentContactOptions(),
        ...createAllContactOptions(),
      ];
    } else {
      return [
        ...createAllContactOptions(),
        ...createAddButton(),
        ...createSyncOption(),
      ];
    }
  };

  const isSpecialOption = (value) => {
    return [
      "create",
      "sync",
      "recent-contact-header",
      "team-recent-contact-header",
      "all-contact-header",
    ].includes(value);
  };

  const customSearch = (options, query) => {
    return fuzzyWordMatch(
      options,
      ["firstname", "lastname", "middlename", "nickname", "fullname"],
      query
    )
      .slice(0, 20)
      .concat(
        [
          ...createAllContactOptions(),
          ...createAddButton(),
          ...createSyncOption(),
        ].filter((opt) => {
          return isSpecialOption(opt.value);
        })
      );
  };

  return (
    <div>
      {!dropdownOpened && (
        <div
          style={{
            position: "relative",
            zIndex: 111,
            height: 0,
          }}
        >
          <Icon
            name="search"
            style={{
              position: "absolute",
              zIndex: 111,
              top: "0.5em",
              left: "0.6em",
              color: "#00000033",
            }}
          />
        </div>
      )}
      {dropdownOpened && searchInput === "" && (
        <div
          style={{
            position: "relative",
            zIndex: 109,
            height: 0,
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "0.5em",
              left: "0.6em",
              color: "#00000055",
            }}
            className="default text"
          >
            Type to search contacts
          </div>
        </div>
      )}
      <Dropdown
        id="main_client_search_bar"
        className={`dropdown_icon client_dropdown ${piiMask(
          "fs-block dd-privacy-mask"
        )} `}
        deburr
        loading={
          loading ||
          !allClientNamesLoaded ||
          !recentClientNamesLoaded ||
          inProgress
        }
        onClose={() => {
          setDropdownOpened(false);
        }}
        onBlur={(e) => {
          setSearchInput("");
        }}
        onChange={clientSelected}
        onSearchChange={(e) => {
          setSearchInput(e.target.value);
        }}
        onOpen={onDropdownOpened}
        options={createOptions()}
        closeOnBlur
        placeholder="Contacts"
        search={customSearch}
        selection
        style={{
          minWidth: "150px",
          maxWidth: "300px",
          width: "30vw",
          marginRight: "0.5em",
          zIndex: "110",
          maxHeight: "80vh",
          background: "none",
        }}
        value={searchInput}
      />
    </div>
  );
}

ContactSearch.defaultProps = {
  hub: {},
  advisor: {},
  integration: null,
};

ContactSearch.propTypes = {
  allClientNames: PropTypes.array.isRequired,
  allClientNamesLoaded: PropTypes.bool.isRequired,
  advisor: PropTypes.object,
  hub: PropTypes.object,
  recentClientNames: PropTypes.shape({
    recentNames: PropTypes.array.isRequired,
    teamRecentNames: PropTypes.array.isRequired,
  }).isRequired,
  recentClientNamesLoaded: PropTypes.bool.isRequired,
  inProgress: PropTypes.bool.isRequired,
  integration: PropTypes.shape({
    type: PropTypes.string.isRequired,
  }),
  openCreateClientModal: PropTypes.func.isRequired,
  getRecentClientNames: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  setInProgress: PropTypes.func.isRequired,
  reloadDataAfterSync: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    advisor: state.hubly.data.advisor,
    hub: state.hubly.data.hub.selected.hub,
    loading: state.hubly.data.hub.selected.loading,
    allClientNames: state.hubly.data.hub.clients.allClientNames,
    allClientNamesLoaded: state.hubly.data.hub.clients.allClientNamesLoaded,
    recentClientNames: state.hubly.data.hub.clients.recentClientNames,
    recentClientNamesLoaded:
      state.hubly.data.hub.clients.recentClientNamesLoaded,
    inProgress: state.hubly.data.hub.selected.inProgress,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    openCreateClientModal: (clientName, addClientToWorkflow) => {
      dispatch(OpenCreateClientModal(clientName, addClientToWorkflow));
    },
    getRecentClientNames: (hub) => {
      dispatch(SetLoadRecentClientNames(false));
      GetRecentClientNames(hub)
        .then((resp) => {
          dispatch(SetRecentClientNames(resp));
        })
        .catch((error) => {
          console.error(error);
        });
    },
    reloadDataAfterSync: (hub) => {
      dispatch(ReloadDataAfterSync(hub));
    },
    setInProgress: (inProgress, percent) => {
      dispatch(SetInProgress(inProgress, percent));
    },
    setAlert: (alert) => {
      dispatch(SetAlert(alert));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactSearch);
