import {
  CreateClientStream,
  CreateClientTag,
  DeleteClientStream,
  DeleteClientTag,
} from "data/libs/clients";
import { CreateStream, DeleteStream, EditStream } from "data/libs/streams";
import { CreateTag, DeleteTag, EditTag } from "data/libs/tags";

import colors from "./colors";

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const sortByName = (a, b) => {
  return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
};

export function addStream(hubId, name, existingStreamColors = []) {
  const colorSet = colors.filter((color) => {
    return !existingStreamColors.includes(color);
  });
  let currentColorSet = colorSet;
  const randomIndex = Math.floor(Math.random() * currentColorSet.length);
  const nextColor = currentColorSet[randomIndex];
  currentColorSet.splice(randomIndex, 1);
  // If we used up all the colors, allow any color to be chosen
  if (currentColorSet.length <= 0) {
    currentColorSet = [...colors];
  }

  const request = {
    hubId: hubId,
    name: name,
    color: nextColor,
  };
  return CreateStream(request);
}

export const editStream = EditStream;

export function deleteStream(id) {
  return DeleteStream(id);
}

export function addClientToStream(clientId, streamId) {
  return CreateClientStream(clientId, { id: streamId });
}

export function removeClientFromStream(clientId, streamId) {
  return DeleteClientStream(clientId, streamId);
}

export function addTag(hubId, name) {
  return CreateTag({
    hubId: hubId,
    name: name,
    failedIfExists: true,
  });
}

export const editTag = EditTag;

export function deleteTag(id) {
  return DeleteTag(id);
}

export function addClientToTag(hubId, clientId, tagName) {
  return CreateClientTag(clientId, { hubId: hubId, name: tagName });
}

export function removeClientFromTag(clientId, tagId) {
  return DeleteClientTag(clientId, tagId);
}

export default capitalizeFirstLetter;
