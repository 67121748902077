import { useContext, useEffect, useRef } from "react";

import { EditHub } from "data/libs/hubs";

import { ToasterContext } from "scenes/Hubly/components/Workspace/Provider";

import { useBooleanState } from "../../CrmAdvisorMatcher/components";

export function useAssignmentStrategyAction(onDone = () => {}) {
  const mounted = useRef(false);
  const [updating, setUpdating, stopUpdating] = useBooleanState();
  const { fireSuccess, fireError } = useContext(ToasterContext);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  const makeCall = async (action) => {
    if (mounted.current) {
      setUpdating();
      await action();
      if (mounted.current) {
        stopUpdating();
      }
    }
  };

  return {
    updating: updating,
    updateAssignmentStrategy: (hub, strategy) => {
      return makeCall(async () => {
        try {
          await EditHub(hub.uuid, { assignmentStrategy: strategy });
          fireSuccess("Shared task default settings updated.");
          onDone(strategy);
        } catch (error) {
          fireError("Failed to update shared task default settings.", error);
        }
      });
    },
  };
}

export default useAssignmentStrategyAction;
