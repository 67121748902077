import React from "react";
import PropTypes from "prop-types";

import { Checkbox, Icon, Segment } from "semantic-ui-react";

export function WorkflowCheckbox({
  id,
  active,
  checked,
  name,
  totalClient,
  totalTask,
  onWorkflowChecked,
}) {
  return (
    <Segment
      style={{
        display: "flex",
        margin: "0 0 0.5em 0",
        padding: "0.5em",
        paddingRight: "0em",
        alignItems: "center",
        opacity: active ? 1 : "0.4",
      }}
    >
      <Checkbox
        data-test="workflow-checkbox"
        checked={checked}
        style={{ width: "5%" }}
        onChange={() => {
          onWorkflowChecked(id, !checked);
        }}
        disabled={!active}
      />
      <div
        style={{
          paddingRight: "1em",
          width: "75%",
          fontWeight: "bold",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {name}
      </div>
      <div
        style={{
          width: "20%",
          borderLeft: "1px solid #dedede",
          color: "grey",
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <div>
          <Icon name="user" color="grey" />
          {totalClient}
          &nbsp;Client
          {totalClient > 0 && "s"}
        </div>
      </div>
      <div
        style={{
          width: "20%",
          borderLeft: "1px solid #dedede",
          color: "grey",
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <div>
          <Icon name="check square" color="grey" />
          {totalTask}
          &nbsp;Task
          {totalTask > 0 && "s"}
        </div>
      </div>
    </Segment>
  );
}

WorkflowCheckbox.defaultProps = {
  name: "",
  totalClient: 0,
  totalTask: 0,
  checked: false,
  active: true,
};

WorkflowCheckbox.propTypes = {
  id: PropTypes.string.isRequired,
  active: PropTypes.bool,
  checked: PropTypes.bool,
  name: PropTypes.string,
  totalClient: PropTypes.number,
  totalTask: PropTypes.number,
  onWorkflowChecked: PropTypes.func.isRequired,
};

export default WorkflowCheckbox;
