import React, { Children, cloneElement, useState } from "react";
import PropTypes from "prop-types";

function MultipleStepsWrapper({ children }) {
  const childrenArray = Children.toArray(children);
  const [currentPage, setCurrentPage] = useState(0);
  return Children.map(children, (child, index) => {
    return (
      <React.Fragment>
        {currentPage === index &&
          cloneElement(child, {
            isFirst: index === 0,
            isLast: index === childrenArray.length - 1,
            toPrev: () => {
              setCurrentPage((page) => {
                return page ? page - 1 : 0;
              });
            },
            toNext: () => {
              setCurrentPage((page) => {
                return page < childrenArray.length - 1 ? page + 1 : page;
              });
            },
          })}
      </React.Fragment>
    );
  });
}

MultipleStepsWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MultipleStepsWrapper;
