import React from "react";
import PropTypes from "prop-types";

import { Container } from "semantic-ui-react";

import { useDraggable } from "components/DragDrop";

export function Layout({
  assignedAdvisor,
  nameEditor,
  actionButtons,
  children,
  ...props
}) {
  const { draggableProps, dragHandleProps } = useDraggable();
  return (
    <div {...draggableProps}>
      <div className="ui segment workflow" {...props}>
        <div className="workflow_transition" style={{ width: "500px" }}>
          <Container
            className="workflow_stage edit_workflow"
            style={{
              maxHeight: "calc(100vh - 125px)",
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Container
              className="workflow_name"
              {...dragHandleProps}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "0.8em",
                minHeight: "67px",
              }}
            >
              {assignedAdvisor}
              <div style={{ flexGrow: 1 }}>{nameEditor}</div>
            </Container>
            <div
              className="workflow_content"
              style={{ paddingTop: "0.8em", paddingRight: "1.6em" }}
            >
              {children}
            </div>
            {actionButtons}
          </Container>
        </div>
      </div>
    </div>
  );
}

Layout.propTypes = {
  assignedAdvisor: PropTypes.node,
  nameEditor: PropTypes.node,
  actionButtons: PropTypes.node,
  children: PropTypes.node,
};

Layout.defaultProps = {
  assignedAdvisor: null,
  nameEditor: null,
  actionButtons: null,
  children: null,
};

export default Layout;
