import React from "react";
import PropTypes from "prop-types";

import { Checkbox, Header } from "semantic-ui-react";

export function DetailsMarkup({ name, value, onChange, children, ...props }) {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Header as="h3" style={{ display: "inline" }}>
        Client Details
      </Header>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "1em",
        }}
      >
        The client is responsible for completing this task.
        <Checkbox
          toggle
          checked={value}
          onChange={() => {
            onChange(null, { name: name, value: !value, ...props });
          }}
        />
      </div>
      {children}
    </div>
  );
}

DetailsMarkup.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.node,
};

DetailsMarkup.defaultProps = {
  children: null,
};

export default DetailsMarkup;
