const obtainBoolValue = (key, defaultValue = false) => {
  if (key in process.env) {
    return String(process.env[key]).toLowerCase() === "true";
  }

  return defaultValue;
};

const configWithDatadog = (config, env) => {
  const gatewayUrl = new URL(config.gateway.URL);
  const websocketGatewayUrl = new URL(config.websocketGateway.URL);
  return {
    ...config,
    datadog: {
      applicationId: "00747d82-11f4-4b96-b9a1-008f693b52b0",
      clientToken: "pubc9ed449e75bf778847323bbab6b24f1c",
      site: "datadoghq.com",
      service: "hubly-frontend",
      sampleRate: 100,
      replaySampleRate: 100,
      trackInteractions: true,
      env: env,
      allowedTracingOrigins: [
        `${gatewayUrl.protocol}//${gatewayUrl.host}`,
        `${websocketGatewayUrl.protocol}//${websocketGatewayUrl.host}`,
      ],
    },
  };
};

const selectConfig = (stage) => {
  const local = {
    cognito: {
      REGION: "us-east-1",
      USER_POOL_ID: "us-east-1_ID0gwCmOD",
      APP_CLIENT_ID: "14tb1qjtuv017brtvhtbcl214e",
      IDENTITY_POOL_ID: "us-east-1:d20cdce2-69bd-473d-a82c-bae9af263a0e",
    },
    fullStory: { orgId: "RB177", debug: true },
    gateway: { URL: "http://localhost:8000" },
    websocketGateway: { URL: "ws://localhost:3001" },
    intercom: { appId: "udj7iu0x" },
    clientPortal: "localhost:3002",
    redtailUrl: "https://smf.crm3.redtailtechnology.com",
    canny: { isTestUser: true, appId: "5c98ff885f23672fdb5e3e5f" },
    userflow: { isTestUser: true, token: "ct_q5b4ae3pgna6naee2cfc4uyzye" },
    launchDarkly: { clientSideID: "6056c09582c99b0e4b00f89f" },
    newApp: false,
    hubspot: { isTestUser: true },
  };

  const dockerCompose = {
    cognito: {
      REGION: "us-east-1",
      USER_POOL_ID: "us-east-1_ID0gwCmOD",
      APP_CLIENT_ID: "14tb1qjtuv017brtvhtbcl214e",
      IDENTITY_POOL_ID: "us-east-1:d20cdce2-69bd-473d-a82c-bae9af263a0e",
    },
    fullStory: null,
    gateway: { URL: "http://workflows:8000" },
    websocketGateway: { URL: "ws://serverless:3001" },
    intercom: null,
    clientPortal: "http://client-portal",
    redtailUrl: "https://smf.crm3.redtailtechnology.com",
    canny: { isTestUser: true, appId: "5c98ff885f23672fdb5e3e5f" },
    userflow: { isTestUser: true, token: "ct_q5b4ae3pgna6naee2cfc4uyzye" },
    launchDarkly: { clientSideID: "6056c09582c99b0e4b00f89f" },
    hubspot: { isTestUser: true },
  };

  const configTemplate = {
    cognito: {
      REGION: "us-east-1",
      USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID,
      APP_CLIENT_ID: process.env.REACT_APP_USER_POOL_CLIENT_ID,
      IDENTITY_POOL_ID: process.env.REACT_APP_IDENTITY_POOL_ID,
    },
    fullStory: {
      orgId: "RB177",
      debug: process.env.REACT_APP_STAGE !== "prod",
    },
    gateway: {
      URL: process.env.REACT_APP_SERVICE_ENDPOINT,
    },
    websocketGateway: {
      URL: process.env.REACT_APP_SERVICE_ENDPOINT_WEBSOCKET,
    },
    intercom: { appId: process.env.REACT_APP_INTERCOM_APP_ID },
    userflow: {
      isTestUser: process.env.REACT_APP_STAGE !== "prod",
      token: process.env.REACT_APP_USERFLOW_TOKEN,
    },
    clientPortal: process.env.REACT_APP_CLIENT_PORTAL,
    redtailUrl: process.env.REACT_APP_REDTAIL_BASE_URL,
    canny: {
      isTestUser: obtainBoolValue(
        "REACT_APP_CANNY_IS_TEST_USER",
        process.env.REACT_APP_STAGE !== "prod"
      ),
      appId: process.env.REACT_APP_CANNY_APP_ID,
    },
    launchDarkly: { clientSideID: process.env.REACT_APP_LD_CLIENT_ID },
    isSessionRecordingOn: obtainBoolValue(
      "REACT_APP_ENABLED_SESSION_RECORDING"
    ),
    hubspot: {
      isTestUser: obtainBoolValue(
        `REACT_APP_HUBSPOT_IS_TEST_USER_${stage}`
          .replace("-", "_")
          .toUpperCase(),
        obtainBoolValue(
          "REACT_APP_HUBSPOT_IS_TEST_USER",
          process.env.REACT_APP_STAGE !== "prod"
        )
      ),
    },
  };

  const selectBaseConfig = () => {
    switch (stage) {
      case "local":
        return local;
      case "docker-compose":
        return dockerCompose;
      default:
        return configTemplate;
    }
  };

  const withDatadog = obtainBoolValue(
    `REACT_APP_DATADOG_RUM_ENABLED_${stage}`.replace("-", "_").toUpperCase(),
    obtainBoolValue("REACT_APP_DATADOG_RUM_ENABLED", false)
  )
    ? configWithDatadog
    : (c) => {
        return c;
      };

  return withDatadog(selectBaseConfig(), stage);
};

const config = selectConfig(
  "REACT_APP_STAGE" in process.env && process.env.REACT_APP_STAGE !== ""
    ? process.env.REACT_APP_STAGE
    : "dev"
);

export default { ...config };
