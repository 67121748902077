import { useCallback } from "react";

import { useMutationWithToaster } from "hooks";

import toGlobalId from "scenes/Hubly/components/Workspace/api/helper";

export function useAssignWorkflowEditorsAction(workflowId, onDone = () => {}) {
  const mutationFactory = useCallback(() => {
    const query = `mutation assignEditorsToWorkflow($workflowId: ID!, $advisorIds: [ID]!) {
      assignEditorsToWorkflow(workflowId: $workflowId, advisorIds: $advisorIds) {
          workflow {
            id
          }
      }
  }
  `;

    const resolver = (resp) => {
      return resp;
    };

    return { query, resolver };
  }, []);

  const [assignEditors, { loading: isAssigning }] = useMutationWithToaster(
    mutationFactory,
    { onDone: useCallback(onDone, []) }
  );

  return {
    assignEditors: (advisorIds) => {
      return assignEditors(
        {
          workflowId: toGlobalId("Workflow", workflowId),
          advisorIds: advisorIds,
        },
        {
          successMsg: "Workflow editors successfully updated.",
          defaultErrorMsg: "Failed to update workflow editors.",
        }
      );
    },
    isAssigning: isAssigning,
  };
}

export default useAssignWorkflowEditorsAction;
