import { groupBy, mapValues, sortBy, values } from "lodash";

export function useAssignees(tasks) {
  const tasksByAssignee = groupBy(tasks, "assignee.id");
  const assignees = mapValues(tasksByAssignee, (assigneeTasks) => {
    return {
      order: sortBy(assigneeTasks, "order")[0],
      assignee: assigneeTasks[0].assignee,
      allTasksCompleted: assigneeTasks.every(({ completed }) => {
        return completed;
      }),
      numOfIncomplete: assigneeTasks.filter(({ completed }) => {
        return !completed;
      }).length,
      numOfComplete: assigneeTasks.filter(({ completed }) => {
        return completed;
      }).length,
    };
  });

  return sortBy(values(assignees), "order");
}

export default useAssignees;
