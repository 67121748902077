import React from "react";
import PropTypes from "prop-types";

import { Button, Responsive } from "semantic-ui-react";

export function CompactButton({ disabled, onClick, children, ...props }) {
  return (
    <Responsive
      data-test="menu-button"
      minWidth={1051}
      as={Button}
      basic
      compact
      content={children}
      disabled={disabled}
      onClick={onClick}
      style={{ marginRight: "0.5em", minWidth: "max-content" }}
      {...props}
    />
  );
}

CompactButton.propTypes = {
  disabled: PropTypes.bool,
  children: PropTypes.node,
  onClick: PropTypes.func,
};

CompactButton.defaultProps = {
  disabled: false,
  children: undefined,
  onClick: () => {},
};

export default CompactButton;
