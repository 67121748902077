import React from "react";
import PropTypes from "prop-types";

import { ConfirmationModal } from "components/ConfirmationModal";

export function DeleteWorkflowModal({
  name,
  numActiveClients,
  onClose,
  onRemove,
}) {
  const message = numActiveClients
    ? `What would you like to do with the ${numActiveClients} ${
        numActiveClients > 1 ? "clients that are" : "client that is"
      } currently in the "${name}"? This cannot be undone.`
    : `Are you sure you wish to delete "${name}"? This cannot be undone.`;

  const buttons = numActiveClients
    ? [
        {
          text: "Archive",
          callBack: () => {
            onRemove(false);
          },
        },
        {
          text: "Delete From Workflow",
          callBack: () => {
            onRemove(true);
          },
          color: "red",
        },
      ]
    : [
        {
          text: "Delete Workflow",
          callBack: () => {
            onRemove(false);
          },
          color: "red",
        },
      ];

  return (
    <ConfirmationModal
      title="Delete Workflow"
      message={message}
      icon="archive"
      buttons={[
        {
          text: "Cancel",
          callBack: onClose,
        },
        ...buttons,
      ]}
    />
  );
}

DeleteWorkflowModal.propTypes = {
  numActiveClients: PropTypes.number,
  name: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

DeleteWorkflowModal.defaultProps = {
  numActiveClients: 0,
};

export default DeleteWorkflowModal;
