import { combineReducers } from "redux";

import { reducer as hublyReducer } from "./Hubly/reducer";
import { reducer as loginReducer } from "./Login/reducer";
import { reducer as modalReducer } from "./Modals/reducer";

export const reducer = combineReducers({
  hubly: hublyReducer,
  login: loginReducer,
  modals: modalReducer,
});
export default reducer;
