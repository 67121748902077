import React, { useState } from "react";
import PropTypes from "prop-types";

import { Calendar, Dialog } from "./components";

export function SchedulerDialog({ open, title, value, onSave, onCancel }) {
  const [dateValue, setDateValue] = useState(value);

  return (
    <Dialog
      open={open}
      header="Reschedule"
      title={title}
      onSave={() => {
        onSave(dateValue);
      }}
      onCancel={() => {
        onCancel();
      }}
    >
      <Calendar
        value={dateValue}
        onSelect={(d, t) => {
          setDateValue(d);
          if (t !== "date-picker") onSave(d);
        }}
      />
    </Dialog>
  );
}

SchedulerDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

SchedulerDialog.defaultProps = {
  open: true,
};

export default SchedulerDialog;
