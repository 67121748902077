import { API } from "aws-amplify";

import { getAPIReq } from "data/libs/authLib";

import { mapUUID, toGlobalId } from "./helper";

export const getAllClientames = async ({
  hubId,
  workflowId,
  workflowIds,
  filterInput,
  searchText = "",
  first = 50,
  after = "",
}) => {
  const req = await getAPIReq(
    {
      query: `query getAllClientsNames(
          $searchText: String,
          $filterInput: FilterInput,
          $workflowIds: [ID],
          $workflowId: ID,
          $first: Int,
          $after: String
        ) {
          searchClients(
            searchText: $searchText,
            filterInput: $filterInput,
            workflowIds: $workflowIds,
            excludeWorkflowId: $workflowId,
            first: $first,
            after: $after
          ) {
            edges {
              node {
                id
                uuid
                name
                household {
                  id
                  uuid
                }
              }
            }
            pageInfo {
              hasNextPage
              endCursor
            }
          }
        }
          `,
      variables: {
        searchText: searchText,
        filterInput: filterInput
          ? {
              streams: filterInput.streams.map(({ id }) => {
                return toGlobalId("Stream", id);
              }),
              streamFilterType: filterInput.streamFilterType,
              tags: filterInput.tags.map(({ id }) => {
                return toGlobalId("Tag", id);
              }),
              tagFilterType: filterInput.tagFilterType,
              filterNextTaskForAssignee: filterInput.filterNextTaskForAssignee,
              assignee: filterInput.assignee
                ? toGlobalId("Advisor", filterInput.assignee)
                : null,
              assigneeFilterType: filterInput.assigneeFilterType,
              filterAssigneeIncludeServiceRole:
                filterInput.filterAssigneeIncludeServiceRole,
              filterServiceRoleUnassignedOnly:
                filterInput.filterServiceRoleUnassignedOnly,
              reminderTypes: filterInput.reminderTypes,
              clientFilter: [],
            }
          : null,
        workflowIds: workflowIds?.map((id) => {
          return toGlobalId("Workflow", id);
        }),
        workflowId: workflowId ? toGlobalId("Workflow", workflowId) : null,
        first: first,
        after: after,
      },
    },
    { hub: hubId }
  );

  const {
    data: { searchClients },
  } = await API.post("HublyAPI", `/graphql`, req);
  return {
    data: searchClients.edges.map(({ node }) => {
      return {
        ...node,
        id: node.uuid,
        household: node.household ? { id: node.household.uuid } : {},
      };
    }),
    pageInfo: searchClients.pageInfo,
  };
};

export const getRecentClients = async ({ hubId }) => {
  const req = await getAPIReq(
    {
      query: `query getRecentClients {
            recentClients {
                teamRecentlyOpened {
                    id
                    uuid
                    name
                    household {
                      id
                      uuid
                    }
                }
                recentlyOpened {
                    id
                    name
                    uuid
                    household {
                      id
                      uuid
                    }
                }
            }
        }
      `,
    },
    { hub: hubId }
  );

  const {
    data: { recentClients },
  } = await API.post("HublyAPI", `/graphql`, req);
  return {
    recentNames: recentClients.recentlyOpened.map((c) => {
      return {
        ...c,
        id: c.uuid,
        household: c.household ? { id: c.household.uuid } : {},
      };
    }),
    teamRecentNames: recentClients.teamRecentlyOpened.map((c) => {
      return {
        ...c,
        id: c.uuid,
        household: c.household ? { id: c.household.uuid } : {},
      };
    }),
  };
};

export const getClientWorkflowsByClientId = async ({ hubId, clientId }) => {
  const req = await getAPIReq(
    {
      query: `query getClient($id: ID!) {
        client(id: $id) {
          workflows {
            edges {
              node {
                uuid
                name
                hideCompletedTasks
                completed
                completedAt
                createdAt
                workflow {
                  uuid
                }
                nextWorkflows {
                  edges {
                    node {
                      uuid
                      nextWorkflow {
                        uuid
                        name
                      }
                      clientWorkflow {
                        uuid
                      }
                      createdAt
                      startDate
                      task {
                        uuid
                        title
                        assignedAdvisor {
                          uuid
                          firstName
                          lastName
                        }
                        reminders {
                          edges {
                            node {
                              uuid
                              time
                              futureReminderNumber
                              futureReminderType
                              futureReminderTime
                              task {
                                uuid
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                tasks {
                  edges {
                    node {
                      uuid
                      title
                      clientWorkflow {
                        uuid
                      }
                      advisorDefinedCompletedAt
                      description
                      options
                      completed
                      completedAt
                      isClientTask
                      assignee {
                        uuid
                        name
                        group
                      }
                      assignedAdvisor {
                        uuid
                        firstName
                        lastName
                      }
                      order
                      householdMembers {
                        edges {
                          node {
                            uuid
                            title
                            type
                            client {
                              uuid
                              firstName
                              lastName
                              name
                            }
                            household {
                              uuid
                            }
                          }
                        }
                      }
                      timeEstimates {
                        edges {
                          node {
                            uuid
                            autoAddedInd
                            timeEstimate
                            createdAt
                            addedBy {
                              uuid
                              firstName
                              lastName
                            }
                          }
                        }
                      }
                      emailTemplate {
                        uuid
                        to
                        subject
                        body
                      }
                      reminders {
                        edges {
                          node {
                            uuid
                            time
                            futureReminderNumber
                            futureReminderType
                            futureReminderTime
                            task {
                              uuid
                            }
                            dismissed
                            completedAt
                          }
                        }
                      }
                      links {
                        edges {
                          node {
                            uuid
                            title
                            url
                          }
                        }
                      }
                      comments {
                        edges {
                          node {
                            uuid
                          }
                        }
                      }
                    }
                  }
                }
                isHidden
                isNew
                archived
                assignedAdvisor {
                  uuid
                  firstName
                  lastName
                }
                previousWorkflow
                startedBy {
                  operatingEntityId
                  operationType
                  startedByAdvisor {
                    firstName
                    lastName
                  }
                }
              }
            }
          }
        }
      }      
      `,
      variables: {
        id: toGlobalId("Client", clientId),
      },
    },
    { hub: hubId }
  );

  const {
    data: { client },
  } = await API.post("HublyAPI", `/graphql`, req);
  return client.workflows.edges.map(({ node }) => {
    return {
      ...node,
      id: node.uuid,
      workflowId: node.workflow.uuid,
      clientId: clientId,
      nextWorkflows: node.nextWorkflows.edges.map(({ node: n }) => {
        return {
          ...n,
          id: n.uuid,
          nextWorkflowName: n.nextWorkflow.name,
          nextWorkflowId: n.nextWorkflow.uuid,
          clientWorkflowId: n.clientWorkflow.uuid,
          task: n.task
            ? {
                ...n.task,
                id: n.task.uuid,
                assignedAdvisor: n.task.assignedAdvisor
                  ? {
                      ...n.task.assignedAdvisor,
                      id: n.task.assignedAdvisor.uuid,
                    }
                  : undefined,
                reminders: n.task.reminders.edges.map(({ node: r }) => {
                  return {
                    ...r,
                    id: r.uuid,
                    taskId: r.task.uuid,
                    clientWorkflowId: node.uuid,
                  };
                }),
              }
            : undefined,
        };
      }),
      tasks: node.tasks.edges.map(({ node: tNode }) => {
        return {
          ...tNode,
          id: tNode.uuid,
          clientWorkflowId: tNode.clientWorkflow
            ? tNode.clientWorkflow.uuid
            : undefined,
          householdMembers: tNode.householdMembers.edges.map(({ node: h }) => {
            return {
              ...h,
              id: h.uuid,
              householdId: h.household.uuid,
              clientId: h.client.uuid,
              client: {
                ...h.client,
                id: h.client.uuid,
                name: h.client.name
                  ? h.client.name
                  : `${h.client.firstName} ${h.client.lastName}`,
              },
            };
          }),
          emailTemplate: tNode.emailTemplate
            ? { ...tNode.emailTemplate, id: tNode.emailTemplate.uuid }
            : null,
          timeEstimates: tNode.timeEstimates.edges.map(({ node: timeNode }) => {
            return {
              ...timeNode,
              id: timeNode.uuid,
              addedBy: timeNode.addedBy
                ? {
                    ...timeNode.addedBy,
                    id: timeNode.addedBy.uuid,
                    name: `${timeNode.addedBy.firstName} ${timeNode.addedBy.lastName}`,
                  }
                : undefined,
            };
          }),
          assignee: mapUUID(tNode.assignee),
          assignedAdvisor: tNode.assignedAdvisor
            ? { ...tNode.assignedAdvisor, id: tNode.assignedAdvisor.uuid }
            : null,
          assignedAdvisorId: tNode.assignedAdvisor
            ? tNode.assignedAdvisor.uuid
            : undefined,
          reminders: tNode.reminders.edges.map(({ node: rNode }) => {
            return {
              ...rNode,
              id: rNode.uuid,
              taskId: rNode.task.uuid,
              clientWorkflowId: node.uuid,
            };
          }),
          links: tNode.links.edges.map(({ node: lNode }) => {
            return {
              ...lNode,
              id: lNode.uuid,
            };
          }),
          comments: tNode.comments.edges.map(({ node: cNode }) => {
            return {
              ...cNode,
              id: cNode.uuid,
            };
          }),
        };
      }),
      assignedAdvisorId: node.assignedAdvisor
        ? node.assignedAdvisor.uuid
        : undefined,
      assignedAdvisor: node.assignedAdvisor
        ? { ...node.assignedAdvisor, id: node.assignedAdvisor.uuid }
        : undefined,
      startedBy: node.startedBy
        ? {
            operationType: node.startedBy.operationType,
            operatingEntityId: node.startedBy.operatingEntityId,
          }
        : undefined,
      startedByText: node.startedBy?.startedByAdvisor
        ? `${node.startedBy.startedByAdvisor.firstName} ${node.startedBy.startedByAdvisor.lastName}`
        : undefined,
    };
  });
};

export default getRecentClients;
