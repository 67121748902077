import React from "react";
import PropTypes from "prop-types";

import { Label } from "semantic-ui-react";

export function NumericBadge({ value, color, ...props }) {
  const { style = {} } = props || {};

  return (
    <Label
      style={{ margin: "auto", display: "inline-block", ...style }}
      circular
      {...props}
      color={color}
    >
      {value < 100 ? value : "99+"}
    </Label>
  );
}

NumericBadge.propTypes = {
  value: PropTypes.number.isRequired,
  color: PropTypes.string,
};

NumericBadge.defaultProps = {
  color: "grey",
};

export default NumericBadge;
