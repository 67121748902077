import { useCallback } from "react";

import { API } from "aws-amplify";

import { useFetch } from "./useFetch";

export function useAnonymousQuery(queryFactory, more) {
  const fetch = useCallback(
    async (variables) => {
      const { query, resolver } = queryFactory();
      const response = await API.post("HublyAPI", `/graphql`, {
        body: { query, variables },
      });

      return resolver(response);
    },
    [queryFactory]
  );

  return useFetch(fetch, more);
}

export default useAnonymousQuery;
