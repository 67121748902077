import React from "react";
import PropTypes from "prop-types";

import { Button, Icon, Popup } from "semantic-ui-react";

import "./AddClientPopUp.css";

export function AddClientPopUp({ open, onPopupClosed, children }) {
  const cloneChild = (child) => {
    return child
      ? React.cloneElement(child, {
          onClearIconClicked: () => {
            onPopupClosed();
          },
        })
      : child;
  };

  return (
    <Popup
      on="click"
      open={open}
      flowing
      position="right center"
      onClose={(event) => {
        // Clicking button in the confirmation modal should not close the popup
        if (event.target.closest("div.ui.small.modal.visible.active")) {
          return;
        }
        onPopupClosed();
      }}
      style={{ zIndex: "1000" }}
      trigger={
        <Button id="add-client-button" icon>
          <Icon name="add" style={{ fontSize: "12pt" }} />
        </Button>
      }
    >
      <div id="add-client-popup" data-test="add-client-dropdown-popup">
        {cloneChild(children)}
      </div>
    </Popup>
  );
}

AddClientPopUp.defaultProps = {
  open: false,
  onPopupClosed: () => {},
  children: null,
};

AddClientPopUp.propTypes = {
  children: PropTypes.element,
  open: PropTypes.bool,
  onPopupClosed: PropTypes.func,
};

export default AddClientPopUp;
