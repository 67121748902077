import { API } from "aws-amplify";

import { getAPIReq } from "data/libs/authLib";

export const addServiceRole = async ({ hubId, name, syncableName = null }) => {
  const req = await getAPIReq(
    {
      query: `mutation addServiceRole($name: String!, $syncableName: String) {
        addServiceRole(name: $name, syncableName: $syncableName) {
          serviceRole {
            id
            name
            syncableName
            isSynced
            showMore
          }
        }
      }`,
      variables: { name, syncableName },
    },

    { hub: hubId }
  );
  const {
    data: { addServiceRole: addResult },
    errors,
  } = await API.post("HublyAPI", `/graphql`, req);

  if (errors?.length > 0) throw errors;

  return addResult.serviceRole;
};

export const renameServiceRole = async ({ hubId, id, name }) => {
  const req = await getAPIReq(
    {
      query: `mutation renameServiceRole($id: ID!, $name: String!) {
        renameServiceRole(id: $id, name: $name) {
          serviceRole {
            id
            name
            syncableName
            isSynced
            showMore
          }
        }
      }`,
      variables: {
        id: id,
        name: name,
      },
    },

    { hub: hubId }
  );
  const {
    data: { renameServiceRole: renameResult },
    errors,
  } = await API.post("HublyAPI", `/graphql`, req);

  if (errors?.length > 0) throw errors;

  return renameResult.serviceRole;
};

export async function reorderServiceRoles({ hubId, visible, showMore }) {
  const req = await getAPIReq(
    {
      query: `mutation reorderServiceRoles($roleIdsAboveShowMore: [ID]!, $roleIdsBelowShowMore: [ID]!) {
        reorderServiceRoles(roleIdsAboveShowMore: $roleIdsAboveShowMore, roleIdsBelowShowMore: $roleIdsBelowShowMore) {
          serviceRoles {
            id
            name
            isSynced
            showMore
          }
        }
      }`,
      variables: {
        roleIdsAboveShowMore: visible.map(({ id }) => {
          return id;
        }),
        roleIdsBelowShowMore: showMore.map(({ id }) => {
          return id;
        }),
      },
    },

    { hub: hubId }
  );
  const {
    data: { reorderServiceRoles: reorderResult },
    errors,
  } = await API.post("HublyAPI", `/graphql`, req);

  if (errors?.length > 0) throw errors;

  return reorderResult.serviceRoles.map((serviceRole) => {
    return {
      ...serviceRole,
      id: serviceRole.uuid,
    };
  });
}

export async function deleteServiceRoles({ hubId, serviceRoleIds }) {
  const req = await getAPIReq(
    {
      query: `mutation deleteServiceRoles($roleIds: [ID]!) {
        deleteServiceRoles(roleIds: $roleIds) {
          result
        }
      }`,
      variables: {
        roleIds: serviceRoleIds.map((id) => {
          return id;
        }),
        isHidden: true,
      },
    },

    { hub: hubId }
  );
  const {
    data: { deleteServiceRoles: hideResult },
    errors,
  } = await API.post("HublyAPI", `/graphql`, req);

  if (errors?.length > 0) throw errors;

  return hideResult.result;
}

export default addServiceRole;
