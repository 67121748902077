import { createSelector } from "reselect";

import { makeAssignee } from "data/filters/actions";

const getTasks = (_, { tasks = [] }) => {
  return [...tasks];
};
const getCurrentAdvisor = (state) => {
  return state?.hubly?.data?.advisor;
};
const getAssignee = (state) => {
  return state?.hubly?.data?.filters?.assignee;
};

export function byAssignee(assignee) {
  const taskByAssignee = ({ assignedAdvisorId }) => {
    return assignedAdvisorId === assignee?.id;
  };
  const unassigned = ({ assignedAdvisorId }) => {
    return !assignedAdvisorId;
  };
  const all = () => {
    return true;
  };

  switch (assignee?.state) {
    case "ALL":
    case null:
    case undefined:
      return all;
    case "UNASSIGNED":
      return unassigned;
    case "CURRENT":
      return taskByAssignee;
    default:
      return assignee?.id === assignee?.state ? taskByAssignee : all;
  }
}

export default function makeGetAssigneeTasks() {
  return createSelector(
    [getAssignee, getCurrentAdvisor, getTasks],
    (assignee, currentUser, tasks) => {
      return (tasks || []).filter(
        byAssignee(makeAssignee(assignee, currentUser))
      );
    }
  );
}
