import React from "react";

import { Dropdown, Header, Icon, Placeholder, Table } from "semantic-ui-react";

import useAssignmentStrategy from "./hooks/useAssignmentStrategy";

export function SharedTaskDefaultSettings() {
  const { loading, strategies, selected, isAdmin, updateAssignmentStrategy } =
    useAssignmentStrategy();

  return (
    <div style={{ paddingTop: "3em" }} data-test="assignment-strategy-table">
      <Header as="h3">
        <Icon name="users" /> Shared Task Default Settings
      </Header>
      <Table stackable style={{ maxWidth: "700px" }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width="9">
              Tasks Assigned to Shared Roles
            </Table.HeaderCell>
            <Table.HeaderCell width="7">Description</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell verticalAlign="top">
              {selected ? (
                <Dropdown
                  data-test="assignment-strategy-dropdown"
                  value={selected.key}
                  onChange={(_, { value }) => {
                    updateAssignmentStrategy(value);
                  }}
                  options={strategies.map(({ key, shortDesc }) => {
                    return {
                      key: key,
                      value: key,
                      text: shortDesc,
                    };
                  })}
                  disabled={loading || !isAdmin}
                  loading={loading}
                  fluid
                  selection
                  style={{ minWidth: "200px" }}
                />
              ) : (
                <Placeholder>
                  <Placeholder.Line />
                  <Placeholder.Line />
                </Placeholder>
              )}
            </Table.Cell>
            <Table.Cell data-test="assignment-strategy-description">
              {selected?.description || ""}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </div>
  );
}

export default SharedTaskDefaultSettings;
