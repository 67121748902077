import React, { useContext } from "react";

import { saveIndex } from "utils";

import { useNotify } from "data/messages/hooks";

import CompactButton from "components/CompactButton";
import { createDroppable, DraggableFragment } from "components/DragDrop";
import Spinner from "components/Spinner";

import { AccessTypeProvider } from "./components/hooks/useAccessType";
import {
  Provider as ClientWorkflowStore,
  useClientWorkflowFetch,
  useClientWorkflowReducer,
} from "./components/hooks/useClientWorkflows";
import {
  isEmptyFilter,
  Provider as FilterStore,
  useFilterState,
} from "./components/hooks/useFilters";
import useView from "./components/hooks/useView";
import useViewAction from "./components/hooks/useViewAction";
import {
  DragDropHub,
  Filters,
  GettingStarted,
  MenuLayout,
  NewWorkflow,
  SaveView,
  StreamManager,
  TagManager,
  Views,
  Workflow,
  WorkflowMenu,
  WorkspaceLayout,
} from "./components";
import { HubContext, ToasterContext } from "./Provider";

const DroppableWorkflow = createDroppable("Workflows");

export function WorkspaceContainer() {
  const notify = useNotify("WORKFLOWS");
  const { fireSuccess, fireError } = useContext(ToasterContext);
  const { moveClientWorkflow, moveClientTileToTop } = useContext(HubContext);

  const [allCwsFetch, fetchDispatch] = useClientWorkflowFetch();
  const [cwData, cwDispatch] = useClientWorkflowReducer();
  const [current, dispatch] = useFilterState();

  const {
    loading: loadingView,
    currentView,
    fetchView,
    clearView,
    updateCurrentView,
  } = useView({ updateFilter: dispatch });
  const { updateDefaultView } = useViewAction(() => {
    fireSuccess("Your default view has been updated.");
  });

  const handleMovedIntoWorkflow = async (cwId, wfId, des) => {
    try {
      const { id: newCwfId } = await moveClientWorkflow(cwId, wfId);
      await moveClientTileToTop(newCwfId, cwData[wfId][des]?.id);
      fetchDispatch({ type: "set", value: { workflowId: wfId } });
    } catch (error) {
      fireError(
        error?.response?.data?.[0] || "Failed to add client to workflow"
      );
    }
  };

  const activeWorkflows = ({ filterEmptyWorkflows }) => {
    if (filterEmptyWorkflows) {
      return ({ numActiveClientWorkflows }) => {
        return numActiveClientWorkflows > 0;
      };
    }

    return () => {
      return true;
    };
  };

  return (
    <FilterStore value={[current, dispatch]}>
      <WorkspaceLayout
        header={
          <MenuLayout>
            <MenuLayout.Left>
              <Views
                loading={loadingView}
                disabled={loadingView}
                selectedView={currentView}
                onUpdateView={(updatedView) => {
                  if (currentView?.id === updatedView.id) {
                    updateCurrentView(updatedView);
                  }
                }}
                onDeselectView={() => {
                  updateDefaultView();
                  clearView();
                }}
                onSelectView={(view) => {
                  updateDefaultView(view.id);
                  fetchView(view.id);
                }}
              />
              <WorkflowMenu
                selectedWorkflows={current.workflows}
                onChange={(w) => {
                  dispatch({ type: "workflows", value: w });
                }}
              />
              {current.workflows.length > 0 && (
                <Filters
                  selectedFilters={current.filters}
                  onChange={(f) => {
                    dispatch({ type: "filters", value: f });
                  }}
                  highlight={!isEmptyFilter(current.filters)}
                />
              )}
              {(current.workflows.length > 0 || currentView) && (
                <CompactButton
                  data-test="hub-menu-clear-all"
                  disabled={
                    isEmptyFilter(current.filters) &&
                    current.workflows.length === 0
                  }
                  onClick={() => {
                    if (currentView) updateDefaultView();
                    clearView();
                  }}
                >
                  Clear All
                </CompactButton>
              )}
              {current.workflows.length > 0 && (
                <SaveView
                  selectedWorkflows={current.workflows}
                  filters={current.filters}
                  currentView={currentView}
                  onUpdateView={updateCurrentView}
                  onCreateView={(view) => {
                    updateDefaultView(view.id);
                    fetchView(view.id);
                  }}
                />
              )}
            </MenuLayout.Left>

            <MenuLayout.Right>
              <TagManager
                onModifyClient={() => {
                  notify(
                    current.workflows.map(({ id }) => {
                      return id;
                    })
                  );
                }}
                onUpdate={(tag) => {
                  dispatch({
                    type: "update-tag-filter",
                    value: { type: "tags", data: tag },
                  });
                }}
                onDelete={(tagId) => {
                  dispatch({
                    type: "remove-tag-filter",
                    value: { type: "tags", data: { id: tagId } },
                  });
                }}
              />
              <StreamManager
                onModifyClient={() => {
                  notify(
                    current.workflows.map(({ id }) => {
                      return id;
                    })
                  );
                }}
                onUpdate={(stream) => {
                  dispatch({
                    type: "update-stream-filter",
                    value: { type: "streams", data: stream },
                  });
                }}
                onDelete={(streamId) => {
                  dispatch({
                    type: "remove-stream-filter",
                    value: { type: "streams", data: { id: streamId } },
                  });
                }}
              />
              <NewWorkflow
                onCreate={(newWf) => {
                  dispatch({
                    type: "add-workflow",
                    value: { ...newWf, editMode: true },
                  });
                }}
              />
            </MenuLayout.Right>
          </MenuLayout>
        }
      >
        {current.workflows.length === 0 && (
          <GettingStarted>
            {loadingView && (
              <Spinner active size="large">
                Loading your view
              </Spinner>
            )}
          </GettingStarted>
        )}
        {current.workflows.length > 0 && (
          <ClientWorkflowStore
            value={[
              [cwData, cwDispatch],
              [allCwsFetch, fetchDispatch],
            ]}
          >
            <DragDropHub
              onTileMovedIntoWorkflow={handleMovedIntoWorkflow}
              onWorkflowPositionChanged={(src, des) => {
                dispatch({ type: "swap-workflows", value: { src, des } });
              }}
              onTilePositionChanged={async (cwId, wfId, src, des) => {
                try {
                  cwDispatch({ type: "swap-cw", value: { wfId, src, des } });

                  const beforeItem = src > des ? des : des + 1;
                  await moveClientTileToTop(cwId, cwData[wfId][beforeItem]?.id);
                } catch {
                  fireError("Failed to move client workflows");
                }
              }}
            >
              <DroppableWorkflow
                id="Workflow-drop-space"
                direction="horizontal"
                style={{
                  display: "flex",
                  padding: "0px",
                  marginTop: "calc(1.5em + 30px)",
                }}
              >
                {current.workflows
                  .map(saveIndex("idx"))
                  .filter(activeWorkflows(current.filters))
                  .map((w) => {
                    return (
                      <DraggableFragment id={w.id} key={w.id} index={w.idx}>
                        <AccessTypeProvider
                          initState={{ ready: true, readOnly: !w.canEdit }}
                        >
                          <Workflow
                            workflow={w}
                            filters={current.filters}
                            onHide={() => {
                              dispatch({ type: "remove-workflow", value: w });
                            }}
                            onDuplicated={(newWf) => {
                              dispatch({ type: "add-workflow", value: newWf });
                            }}
                          />
                        </AccessTypeProvider>
                      </DraggableFragment>
                    );
                  })}
              </DroppableWorkflow>
            </DragDropHub>
          </ClientWorkflowStore>
        )}
      </WorkspaceLayout>
    </FilterStore>
  );
}

WorkspaceContainer.propTypes = {};

WorkspaceContainer.defaultProps = {};

export default WorkspaceContainer;
