import React from "react";
import PropTypes from "prop-types";

export function Layout({ children }) {
  return (
    <div
      style={{
        display: "flex",
        minHeight: "297px",
        flexDirection: "row",
        alignItems: "center",
        padding: "1em 3em",
      }}
    >
      {children}
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node,
};

Layout.defaultProps = {
  children: null,
};

export default Layout;
