import React from "react";
import PropTypes from "prop-types";
import { range } from "lodash";

import {
  makeStyles,
  TableBody as OriginalTableBody,
  TableCell,
  TableRow,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

const TableBodySkeleton = ({ columnLength }) => {
  return range(5).map((n) => {
    return (
      <TableRow hover tabIndex={-1} key={`sk_${n}_key`}>
        {range(columnLength).map((column) => {
          return (
            <TableCell component="td" key={`sk_${n}-column-${column}`}>
              <Skeleton variant="text" />
            </TableCell>
          );
        })}
      </TableRow>
    );
  });
};

TableBodySkeleton.propTypes = {
  columnLength: PropTypes.number,
};

const useStyles = makeStyles(() => {
  return {
    bodyCell: {
      fontSize: "0.875rem",
    },
  };
});

function TableBody({ isLoading, columns, data }) {
  const classes = useStyles();
  return (
    <OriginalTableBody>
      {isLoading ? (
        <TableBodySkeleton columnLength={columns.length} />
      ) : (
        data.map((row) => {
          return (
            <TableRow hover tabIndex={-1} key={row.id}>
              {columns.map((column) => {
                return (
                  <TableCell
                    component="td"
                    scope="row"
                    key={`${row.id}-column-${column.id}`}
                    className={classes.bodyCell}
                  >
                    {row[column.id]}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })
      )}
    </OriginalTableBody>
  );
}

TableBody.propTypes = {
  isLoading: PropTypes.bool,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    })
  ).isRequired,
  data: PropTypes.array.isRequired,
};

TableBody.defaultProps = {
  isLoading: false,
};

export default TableBody;
