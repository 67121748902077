import { createContext } from "react";

export const ScrollContext = createContext({
  scrollToItem: null,
  refs: [],
  setScrollToItem: () => {},
  setRefs: () => {},
});

export default ScrollContext;
