import React, { useContext } from "react";
import PropTypes from "prop-types";

import { Header } from "semantic-ui-react";

import PrivacyModeContext from "components/PrivacyMode/Context";

export function ClientName({
  topName,
  bottomName,
  hasTopNamePii,
  hasBottomNamePii,
}) {
  const { piiMask } = useContext(PrivacyModeContext);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Header
        as="h2"
        className={hasTopNamePii ? piiMask("fs-block dd-privacy-mask") : ""}
        style={{
          marginBottom: "0px",
          fontWeight: "300",
          fontSize: "16pt",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          width: "208px",
          overflow: "hidden",
        }}
      >
        {topName}
      </Header>

      <Header
        as="h2"
        className={hasBottomNamePii ? piiMask("fs-block dd-privacy-mask") : ""}
        style={{
          marginTop: "2px",
          marginBottom: "0px",
          fontWeight: "300",
          fontSize: "11pt",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          width: "208px",
          overflow: "hidden",
        }}
      >
        {bottomName}
      </Header>
    </div>
  );
}

ClientName.propTypes = {
  topName: PropTypes.string,
  bottomName: PropTypes.string,
  hasTopNamePii: PropTypes.bool,
  hasBottomNamePii: PropTypes.bool,
};

ClientName.defaultProps = {
  topName: undefined,
  bottomName: undefined,
  hasTopNamePii: undefined,
  hasBottomNamePii: undefined,
};

export default ClientName;
