import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Button, Icon, Input, List } from "semantic-ui-react";

class TaskEdit extends React.Component {
  static propTypes = {
    cancelEdit: PropTypes.func.isRequired,
    dragHandleProps: PropTypes.object.isRequired,
    handleKeyDown: PropTypes.func.isRequired,
    isDragging: PropTypes.bool.isRequired,
    task: PropTypes.object.isRequired,
    updateTask: PropTypes.bool.isRequired,
  };

  state = {
    taskTitle: "",
  };

  componentDidMount() {
    const { task } = this.props;
    this.setState({ taskTitle: task.title });
  }

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };

  render() {
    const { taskTitle } = this.state;
    const {
      task,
      handleKeyDown,
      isDragging,
      cancelEdit,
      dragHandleProps,
      updateTask,
    } = this.props;
    return (
      <React.Fragment>
        <List.Content>
          <Input
            name="taskTitle"
            fluid
            value={taskTitle}
            placeholder="Edit Task"
            onKeyDown={(e) => {
              handleKeyDown(e, task.id, taskTitle);
            }}
            onChange={this.handleChange}
            autoFocus
          />
          {/* Need a handle of some kind or react beautiful dnd will error, creating fake handle */}
          <div {...dragHandleProps} />
          {!isDragging && (
            <div style={{ marginBottom: "0.5em" }}>
              <Button
                content="Save"
                color="green"
                onClick={() => {
                  updateTask(task.id, taskTitle);
                }}
                style={{ marginTop: "9px" }}
              />
              <Icon
                link
                name="delete"
                color="grey"
                size="large"
                onClick={cancelEdit}
              />
            </div>
          )}
        </List.Content>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskEdit);
