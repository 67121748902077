import React from "react";
import PropTypes from "prop-types";

import { Icon } from "semantic-ui-react";

import Popper from "components/Popper";

export function FilterLabel({ isSynced, name, syncDesc, ...props }) {
  return (
    <div {...props}>
      {isSynced && (
        <Icon
          name="rss"
          color="blue"
          style={{
            paddingRight: "0.4rem",
            fontSize: "1.0em",
          }}
        />
      )}
      {name}
      {isSynced && (
        <Popper
          on="hover"
          content={syncDesc}
          trigger={
            <Icon
              name="info circle"
              color="grey"
              size="small"
              style={{
                paddingLeft: "0.4rem",
                fontSize: "1.0em",
              }}
            />
          }
        />
      )}
    </div>
  );
}

FilterLabel.propTypes = {
  isSynced: PropTypes.bool,
  name: PropTypes.string,
  syncDesc: PropTypes.string,
};

FilterLabel.defaultProps = {
  isSynced: false,
  name: "",
  syncDesc: "This list of users is managed by your CRM",
};

export default FilterLabel;
