import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { v4 } from "uuid";

import {
  Button,
  Checkbox,
  Header,
  Icon,
  Modal,
  Popup,
} from "semantic-ui-react";

import { PrivacyModeContext } from "components/PrivacyMode/Context";

export function ConfirmationModal({
  icon,
  title,
  message,
  children,
  buttons,
  checks,
}) {
  const context = useContext(PrivacyModeContext);
  const { piiMask } = context;
  const [state, setState] = useState({});
  return (
    <Modal open basic size="small" data-test="confirmation-modal">
      <Header
        icon={icon}
        className={piiMask("fs-block dd-privacy-mask")}
        content={title}
      />
      <Modal.Content className={piiMask("fs-block dd-privacy-mask")}>
        {message ? ( // show a message or children
          <p>{message}</p>
        ) : (
          children
        )}
      </Modal.Content>
      <Modal.Actions>
        {buttons.map((b) => {
          return (
            <Button
              className={piiMask("fs-block dd-privacy-mask")}
              key={v4()}
              inverted
              color={b.color}
              icon={b.icon}
              data-test="confirmation-modal-button"
              onClick={(e) => {
                const checksParam = {};
                if (checks) {
                  checks.forEach((c) => {
                    checksParam[c.name] = !!state[c.name];
                  });
                }

                if (b.callBack) {
                  b.callBack(checksParam);
                  e.stopPropagation();
                }
              }}
              content={b.text}
            />
          );
        })}
      </Modal.Actions>
      {checks && (
        <Modal.Actions style={{ textAlign: "left" }}>
          {checks.map((check) => {
            return (
              <div
                key={check.name}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "0.5em",
                }}
              >
                <Checkbox
                  name={check.name}
                  onChange={(e, { name, checked }) => {
                    setState((prevState) => {
                      return {
                        ...prevState,
                        [name]: checked,
                      };
                    });
                  }}
                />
                <div style={{ color: "white", marginLeft: "0.5em" }}>
                  {check.text}
                </div>
                {check.info && (
                  <Popup
                    trigger={
                      <Icon
                        style={{ marginLeft: "0.5em" }}
                        name="question circle"
                      />
                    }
                    on="hover"
                    position="bottom center"
                    content={check.info}
                  />
                )}
              </div>
            );
          })}
        </Modal.Actions>
      )}
    </Modal>
  );
}

ConfirmationModal.defaultProps = {
  icon: "",
  message: "",
  checks: [],
  children: React.createElement("div"),
};

ConfirmationModal.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  message: PropTypes.string,
  children: PropTypes.node,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      icon: PropTypes.string,
      text: PropTypes.string.isRequired,
      callBack: PropTypes.func,
    })
  ).isRequired,
  checks: PropTypes.array,
};

export default ConfirmationModal;
