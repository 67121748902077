import React from "react";
import PropTypes from "prop-types";
import { v4 } from "uuid";

import { Label, List } from "semantic-ui-react";

import Popper from "components/Popper";

export function TagPopup({ tags }) {
  return (
    <React.Fragment>
      <Popper
        basic
        disabled={tags.length === 0}
        trigger={
          <Label className="clickable">
            {tags.length === 1 ? `${tags.length} Tag` : `${tags.length} Tags`}
          </Label>
        }
      >
        <List>
          {tags.map(({ name }) => {
            return <List.Item key={`${v4()}-${name}`}>{name}</List.Item>;
          })}
        </List>
      </Popper>
    </React.Fragment>
  );
}

TagPopup.propTypes = {
  tags: PropTypes.array.isRequired,
};

export default TagPopup;
