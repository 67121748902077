import React from "react";
import PropTypes from "prop-types";

import { Icon } from "semantic-ui-react";

import Popper from "components/Popper";

import { MemberItemLayout, StreamPopup, TagPopup } from "./components";

export function MemberItem({ member, type, onDeleteClick, onViewClick }) {
  const { name, tags = [], streams = [] } = member;

  return (
    <MemberItemLayout
      name={<div>{name}</div>}
      popup={
        type === "tag" ? (
          <TagPopup tags={tags} />
        ) : (
          <StreamPopup streams={streams} />
        )
      }
      data-test={`${type}-member`}
      action={
        <React.Fragment>
          <Popper
            basic
            inverted
            content="View Client Card"
            size="mini"
            style={{
              padding: "0.5em",
            }}
            trigger={
              <Icon
                link
                name="user"
                onClick={onViewClick}
                style={{ marginRight: "0.5em" }}
              />
            }
          />
          <Popper
            basic
            inverted
            content={`Remove from ${type}`}
            size="mini"
            style={{
              padding: "0.5em",
            }}
            trigger={<Icon link name="delete" onClick={onDeleteClick} />}
          />
        </React.Fragment>
      }
    />
  );
}

MemberItem.propTypes = {
  member: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  onViewClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
};

export default MemberItem;
