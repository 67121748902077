import React from "react";
import PropTypes from "prop-types";

import { Icon } from "semantic-ui-react";

export function CRMIcon({ crmType }) {
  return <Icon name="rss" className={`${crmType}_color`} title={crmType} />;
}

CRMIcon.propTypes = {
  crmType: PropTypes.string.isRequired,
};

export default CRMIcon;
