import { useCallback, useEffect, useReducer, useRef, useState } from "react";

import { set } from "utils";

function usePageInfo(pageSizeScale = [10]) {
  const initialState = {
    cursor: "",
    fetchCount: 0,
    totalSize: 0,
    hasNextPage: true,
    totalCount: 0,
    pageSize: pageSizeScale[0],
    pageSizeIdx: 0,
  };

  const reducer = (state, { type, value }) => {
    switch (type) {
      case "hasNextPage":
        return set(state, "hasNextPage", value);
      case "updateCursor":
        return set(state, "cursor", value);
      case "addOneFetchCount":
        return set(state, "fetchCount", state.fetchCount + 1);
      case "addSize":
        return set(state, "totalSize", state.totalSize + value);
      case "setSize":
        return set(state, "totalSize", value);
      case "setTotalCount":
        return set(state, "totalCount", value);
      case "increasePageSizeScale": {
        if (state.pageSizeIdx + 1 > pageSizeScale.length - 1) return state;
        const s = set(state, "pageSizeIdx", state.pageSizeIdx + 1);
        return set(s, "pageSize", pageSizeScale[s.pageSizeIdx]);
      }
      case "reset":
        return initialState;
      default:
        return state;
    }
  };

  const [
    { cursor, fetchCount, hasNextPage, pageSize, totalSize, totalCount },
    dispatch,
  ] = useReducer(reducer, initialState);

  const updatePage = useCallback((newCursor, hasNext, data, tCount) => {
    dispatch({ type: "hasNextPage", value: hasNext });
    dispatch({ type: "updateCursor", value: newCursor });
    dispatch({ type: "addOneFetchCount" });
    dispatch({ type: "setSize", value: data?.length || 0 });
    dispatch({ type: "setTotalCount", value: tCount });
  }, []);

  const updateNextPage = useCallback((newCursor, hasNext, data, tCount) => {
    dispatch({ type: "hasNextPage", value: hasNext });
    dispatch({ type: "updateCursor", value: newCursor });
    dispatch({ type: "addOneFetchCount" });
    dispatch({ type: "increasePageSizeScale" });
    dispatch({ type: "addSize", value: data?.length || 0 });
    dispatch({ type: "setTotalCount", value: tCount });
  }, []);

  const resetPage = useCallback(() => {
    dispatch({ type: "reset" });
  }, []);

  return [
    { cursor, fetchCount, hasNextPage, pageSize, totalSize, totalCount },
    { updatePage, updateNextPage, resetPage },
  ];
}

function useFetchPage(fetch, stateManager) {
  const [current, { updatePage, updateNextPage }] = stateManager;

  const refetch = (run) => {
    return async (variables) => {
      const pageSize = Math.min(
        Math.max(current.pageSize, current.totalSize + 1),
        200
      );

      return run({ ...variables, after: "", pageSize: pageSize });
    };
  };

  const fetchNextPage = (run) => {
    return async (variables) => {
      const { cursor, hasNextPage, pageSize } = current;
      if (!hasNextPage) return [];

      return run({ ...variables, after: cursor, pageSize: pageSize });
    };
  };

  const call = (update) => {
    return async (params) => {
      const { pageInfo, data, totalCount } = await fetch(params);
      update(pageInfo.endCursor, pageInfo.hasNextPage, data, totalCount);

      return data;
    };
  };

  return {
    refetch: useCallback(refetch(call(updatePage)), [
      fetch,
      current,
      updatePage,
    ]),
    fetchNextPage: useCallback(fetchNextPage(call(updateNextPage)), [
      fetch,
      current,
      updateNextPage,
    ]),
  };
}

export function usePagination(req, args, dataEvent) {
  const { variables = null, options = {} } = args || {};
  const { onAddPageData, onSetData, onClearData } = dataEvent;

  const mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  const pageState = usePageInfo(options.pageSizes);
  const [{ hasNextPage, fetchCount, totalCount }, { resetPage }] = pageState;
  const { fetchNextPage, refetch } = useFetchPage(req, pageState);

  const [initLoad, setInitLoad] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);
  const [refetching, setRefetching] = useState(false);

  const readNextPage = useCallback(() => {
    if (!hasNextPage) return;

    setLoadingPage(true);
    fetchNextPage(variables).then((pageData) => {
      if (mounted.current) {
        onAddPageData(pageData, initLoad);
        setLoadingPage(false);
      }
    });
  }, [fetchNextPage, initLoad, onAddPageData, variables, hasNextPage]);

  const readAll = useCallback(() => {
    setRefetching(true);
    refetch(variables).then((pageData) => {
      if (mounted.current) {
        onSetData(pageData);
        setRefetching(false);
      }
    });
  }, [refetch, onSetData, variables]);

  useEffect(() => {
    resetPage();
    onClearData();
    setInitLoad(true);
  }, [variables, onClearData, resetPage]);

  useEffect(() => {
    if (initLoad && !options.disabled) {
      readNextPage();
      setInitLoad(false);
    }
  }, [initLoad, options, readNextPage]);

  return {
    totalCount: totalCount,
    fetchCount: fetchCount,
    loading: loadingPage,
    hasMorePages: hasNextPage,
    readNext: readNextPage,
    refetch: readAll,
    refetching: refetching,
  };
}

export function usePaginationWithState(req, args) {
  const [data, setData] = useState([]);

  const page = usePagination(req, args, {
    onAddPageData: useCallback((pageData, isInitLoad) => {
      setData((d) => {
        return isInitLoad ? pageData : d.concat(pageData);
      });
    }, []),
    onSetData: useCallback(setData, []),
    onClearData: useCallback(() => {
      setData([]);
    }, []),
  });

  return {
    ...page,
    isEmpty: data.length === 0,
    data: data,
  };
}

export default usePaginationWithState;
