import { useContext, useMemo, useState } from "react";
import { uniqBy } from "lodash";

import { useDelay, usePagination } from "hooks";

import { useFilterContext } from "scenes/Hubly/components/Workspace/components/hooks";
import { HubContext } from "scenes/Hubly/components/Workspace/Provider";

export function useClients() {
  const toId = ({ id }) => {
    return id;
  };
  const toOptions = ({ id, name }) => {
    return { key: id, value: name };
  };

  const { searchClients } = useContext(HubContext);
  const [{ filters, workflows }] = useFilterContext();

  const [searchText, setSearchText] = useState("");
  const delayCallback = useDelay(250);

  const page = usePagination(searchClients, {
    variables: useMemo(() => {
      return {
        searchText: searchText,
        filterInput: filters,
        workflowIds: workflows.map(toId),
      };
    }, [searchText, filters, workflows]),
    options: { pageSizes: [30, 50] },
  });

  const selectedOptions = filters.clients.map(toOptions);
  const options = page.data.map(toOptions);

  return {
    setSearchText: (text) => {
      delayCallback(() => {
        setSearchText(text);
      });
    },
    page: page,
    options: uniqBy(options.concat(selectedOptions), "key"),
    selected: selectedOptions.map(({ key }) => {
      return key;
    }),
  };
}

export default useClients;
