import React from "react";

const QueryClientContext = React.createContext();

export const QueryClientContextProvider = QueryClientContext.Provider;

export function useQueryClient() {
  return React.useContext(QueryClientContext);
}

export default useQueryClient;
