import React, { useContext } from "react";

import PrivacyModeContext from "./Context";

export function withPrivacyMode(WrappedComponent) {
  return ({ className = "", ...props }) => {
    const { piiMask } = useContext(PrivacyModeContext);

    return <WrappedComponent {...props} className={piiMask(className)} />;
  };
}

export default withPrivacyMode;
