import React from "react";
import PropTypes from "prop-types";

import CRMPermissionWarning from "scenes/Hubly/components/Workspace/components/CRMPermissionWarning";

import useManageClient from "./hooks/useManageClients";
import { Modal, StreamPopup, Table, TagPopup } from "./components";

const tableColumns = [
  { id: "name", label: "Contact Name" },
  { id: "serviceTeam", label: "Team Name" },
  { id: "household", label: "Household" },
  { id: "relationship", label: "Relationship" },
  { id: "streams", label: "Streams" },
  { id: "tags", label: "Tags" },
  { id: "emailAddress", label: "Email Address" },
];

const withFormattedProps = ({ streams, tags, email, ...data }) => {
  return {
    ...data,
    streams: <StreamPopup streams={streams} />,
    tags: <TagPopup tags={tags} />,
    emailAddress: <a href={`mailto:${email}`}>{email}</a>,
  };
};

function ManageClientsModal({ serviceTeamId, crmPermissionWarning, onClose }) {
  const {
    condition,
    updateCondition,
    loading: isLoading,
    clients,
    totalCount,
  } = useManageClient({ serviceTeamId });

  const onModalClose = () => {
    onClose();
  };

  return (
    <Modal
      style={{ width: "85%" }}
      data-test="service-team-client-list-modal"
      isLoading={false}
      isModalOpen
      onClose={onModalClose}
    >
      <Modal.Header titleAlign="center" onClose={onModalClose}>
        Manage Contacts
        {crmPermissionWarning && (
          <CRMPermissionWarning permissionSubject="contacts assigned to this Service Team" />
        )}
      </Modal.Header>
      <Modal.Body>
        <React.Fragment>
          <p>Quickly view contacts filtered by each Service Team below:</p>
          <div
            data-test="service-team-client-list-table"
            style={{
              position: "relative",
              height: "350px",
              marginBottom: "52px",
            }}
          >
            <Table>
              <Table.Content>
                <Table.Header columns={tableColumns} />
                <Table.Body
                  isLoading={isLoading}
                  columns={tableColumns}
                  data={clients.map(withFormattedProps)}
                />
              </Table.Content>
            </Table>
            <Table.PaginationControl
              style={{ position: "absolute", right: 0, top: "100%" }}
              isLoading={isLoading}
              totalCount={totalCount || 0}
              page={condition.page || 0}
              pageSize={condition.pageSize || 0}
              pageSizeOptions={[25, 50, 100]}
              onPageChange={updateCondition}
              onPageSizeChange={updateCondition}
            />
          </div>
        </React.Fragment>
      </Modal.Body>
    </Modal>
  );
}

ManageClientsModal.propTypes = {
  serviceTeamId: PropTypes.string.isRequired,
  crmPermissionWarning: PropTypes.bool,
  onClose: PropTypes.func,
};
ManageClientsModal.defaultProps = {
  crmPermissionWarning: false,
  onClose: () => {},
};

export default ManageClientsModal;
