import React from "react";
import PropTypes from "prop-types";

import { Input } from "semantic-ui-react";

export default function NumberSelector({
  initValue,
  label,
  placeholder,
  onClose,
}) {
  const [error, setError] = React.useState();
  const [numericValue, setNumericValue] = React.useState(initValue);
  const [buttonStatus, setButtonStatus] = React.useState({
    enabled: false,
    color: "gray",
    content: "Close",
  });

  const handleChange = (_, { value }) => {
    setNumericValue(value);
    setButtonStatus(() => {
      return value === initValue
        ? { enabled: false, color: "gray", content: "Close" }
        : { enabled: true, color: "blue", content: "Save" };
    });
    if (error) setError(null);
  };

  const handleSave = () => {
    if (numericValue <= 0 || !numericValue) {
      setError("Value must be positive");
    } else {
      onClose(numericValue, buttonStatus.enabled);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSave();
    }
  };

  const renderLabel = () => {
    if (label) {
      return {
        label: { basic: true, content: label },
        labelPosition: "left",
      };
    }
    return {};
  };

  return (
    <React.Fragment>
      <Input
        data-test="number-selector-input"
        display="inline"
        min="0"
        name={label || "positiveNumberInput"}
        maxLength="3"
        type="number"
        error={Boolean(error)}
        placeholder={placeholder}
        {...renderLabel()}
        action={{ ...buttonStatus, onClick: handleSave }}
        value={numericValue}
        onChange={handleChange}
        onKeyPress={handleKeyPress}
      />
      {error && (
        <p
          data-test="number-selector-error-message"
          style={{ paddingTop: "10px" }}
        >
          {error}
        </p>
      )}
    </React.Fragment>
  );
}

NumberSelector.propTypes = {
  initValue: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

NumberSelector.defaultProps = {
  initValue: undefined,
  label: undefined,
  placeholder: undefined,
};
