import React from "react";
import PropTypes from "prop-types";

import { Icon } from "semantic-ui-react";

export function Chevron({ pointRight, ...props }) {
  return (
    <Icon
      name="chevron right"
      style={{
        transformOrigin: "50% 50%",
        margin: 0,
        transform: !pointRight ? "rotate(180deg)" : "",
        transition: "transform 0.65s",
      }}
      {...props}
    />
  );
}

Chevron.propTypes = {
  pointRight: PropTypes.bool,
};

Chevron.defaultProps = {
  pointRight: true,
};

export default Chevron;
