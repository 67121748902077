import React, { Component } from "react";
import connect from "react-redux/es/connect/connect";
import { withRouter } from "react-router";
import PropTypes from "prop-types";

import { Icon, Segment } from "semantic-ui-react";

import { SetUnsyncedUpdates } from "data/websockets/actions";

import Anchor from "components/Anchor";

class RefreshRequiredOverlay extends Component {
  static propTypes = {
    unsyncedUpdates: PropTypes.number.isRequired,
    dismissUpdates: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      dismissed: true,
    };
    setTimeout(() => {
      this.setState({ dismissed: false });
    }, 500);
  }

  refresh = () => {
    window.location.reload();
  };

  dismiss = () => {
    const { dismissUpdates } = this.props;
    this.setState({ dismissed: true });
    setTimeout(() => {
      dismissUpdates();
    }, 1500);
  };

  render() {
    const { unsyncedUpdates } = this.props;
    const { dismissed } = this.state;
    return (
      <div
        style={{
          position: "absolute",
          bottom: "1em",
          left: "1em",
          opacity: dismissed ? "0" : "0.9",
          transition: "opacity 1s",
          color: "#555",
          zIndex: 10,
        }}
      >
        <Segment style={{ background: "#f9f9f9" }}>
          <Icon
            link
            color="grey"
            name="delete"
            style={{
              float: "right",
              position: "relative",
              top: "-10px",
              right: "-10px",
            }}
            onClick={this.dismiss}
          />
          <span style={{ fontWeight: "bold" }}>
            {unsyncedUpdates}
            &nbsp;new change
            {unsyncedUpdates > 1 ? "s have" : " has"}
            &nbsp;been made by others in this Hub.
          </span>
          <br />
          <span>
            Please&nbsp;
            <Anchor onClick={this.refresh}>Refresh</Anchor>
            &nbsp;the page to load changes.
          </span>
        </Segment>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    dismissUpdates: () => {
      dispatch(SetUnsyncedUpdates(0));
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RefreshRequiredOverlay)
);
