import React from "react";

import {
  useFlags as useLdFlags,
  useLDClient,
} from "launchdarkly-react-client-sdk";

import { useBooleanState } from "hooks";

export function useFlags() {
  const client = useLDClient();
  const flags = useLdFlags();
  const [clientIsReady, setReady] = useBooleanState();

  React.useEffect(() => {
    let mounted = true;

    if (client) {
      client.waitUntilReady().then(() => {
        if (mounted) setReady();
      });
    }

    return () => {
      mounted = false;
    };
  }, [client, setReady]);

  if (!clientIsReady) {
    return { loadingConfig: true };
  }

  return { loadingConfig: false, flags: flags };
}

export default useFlags;
