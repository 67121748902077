import React from "react";
import PropTypes from "prop-types";

import { clientOptionProps } from "../../helper";
import { DropdownItemSection } from "..";

export function RecentContactsSection({
  filterKeyword,
  loading,
  recentClients,
  sectionOpened,
  loadData,
  onClientClick,
  onSectionExpanded,
}) {
  if (filterKeyword !== "" && !loading) {
    return null;
  }

  const recentNames = recentClients.recentNames.map(clientOptionProps);
  const teamRecentNames = recentClients.teamRecentNames.map(clientOptionProps);
  return (
    <React.Fragment>
      {(recentNames.length > 0 || loading) && (
        <DropdownItemSection
          openList
          loading={loading}
          headerText="Recent Contacts"
          type="client"
          collapsible={false}
          items={recentNames}
          onSectionExpanded={(section) => {
            if (section !== "") loadData();
            onSectionExpanded(section);
          }}
          onItemClick={(clientId) => {
            onClientClick(
              recentClients.recentNames.find(({ id }) => {
                return id === clientId;
              }),
              "my_recent_contact"
            );
          }}
        />
      )}
      {teamRecentNames.length > 0 && (
        <DropdownItemSection
          openList={sectionOpened === "All Recent Contacts"}
          loading={loading}
          headerText="All Recent Contacts"
          type="client"
          collapsible
          items={teamRecentNames}
          onSectionExpanded={onSectionExpanded}
          onItemClick={(clientId) => {
            onClientClick(
              recentClients.teamRecentNames.find(({ id }) => {
                return id === clientId;
              }),
              "team_recent_contact"
            );
          }}
        />
      )}
    </React.Fragment>
  );
}

RecentContactsSection.defaultProps = {
  loading: false,
  filterKeyword: "",
  sectionOpened: "",
};

RecentContactsSection.propTypes = {
  recentClients: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  filterKeyword: PropTypes.string,
  sectionOpened: PropTypes.string,
  loadData: PropTypes.func.isRequired,
  onClientClick: PropTypes.func.isRequired,
  onSectionExpanded: PropTypes.func.isRequired,
};

export default RecentContactsSection;
