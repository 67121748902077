export const initialState = {
  createHouseholdModalOpen: false,
  householdId: "",
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "OPEN_MANAGE_HOUSEHOLD_MODAL":
      return {
        ...state,
        createHouseholdModalOpen: true,
        householdId: action.householdId,
      };
    case "CLOSE_MANAGE_HOUSEHOLD_MODAL":
      return {
        ...state,
        createHouseholdModalOpen: false,
      };
    default:
      return state;
  }
};
export default reducer;
