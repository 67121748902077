import React from "react";

import { Loader } from "semantic-ui-react";

export function TaskAddLoader() {
  return (
    <div
      data-test="task-add-loader"
      style={{
        display: "flex",
        color: "grey",
        marginTop: "0.5em",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div style={{ marginRight: "1em" }}>Adding Task</div>
      <Loader active size="small" inline />
    </div>
  );
}

export default TaskAddLoader;
