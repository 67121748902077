export const initialState = {
  items: [],
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_ALERT_TO_QUEUE":
      return {
        ...state,
        items: state.items.concat([action.alert]),
      };

    case "REMOVE_ALERT_FROM_QUEUE_BY_ID":
      return {
        ...state,
        items: state.items.filter((item) => {
          return item.id !== action.alertId;
        }),
      };
    default:
      return state;
  }
};
export default reducer;
