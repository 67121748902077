import { useMemo } from "react";

import { useBooleanState, usePagination } from "hooks";

export function useMembers({ fetchMembers, id }) {
  const [isModified, setModified] = useBooleanState();

  const page = usePagination(fetchMembers, {
    variables: useMemo(() => {
      return {
        iDs: [id],
      };
    }, [id]),
    options: { pageSizes: [30, 50] },
  });

  return {
    ...page,
    isModified,
    setModified,
  };
}

export default useMembers;
