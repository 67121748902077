import React from "react";
import PropTypes from "prop-types";

import { Label } from "semantic-ui-react";

class InitialsCircle extends React.Component {
  static defaultProps = {
    first: null,
    last: null,
    onClick: () => {},
    properties: {
      circleFontSize: "10px",
      circleSize: "20px",
      bold: false,
      paddingTop: "2.9px",
      color: "#767676", // "#767676",
    },
  };

  static propTypes = {
    first: PropTypes.string,
    last: PropTypes.string,
    onClick: PropTypes.func,
    properties: PropTypes.shape({
      circleFontSize: PropTypes.string,
      circleSize: PropTypes.string,
      color: PropTypes.string,
      paddingTop: PropTypes.string,
      bold: PropTypes.bool,
    }),
  };

  render() {
    const { first, last, onClick, properties } = this.props;
    let content = "";
    if (first && first.length > 0) content = `${first.charAt(0)}`;
    if (last && last.length > 0) content = `${content}${last.charAt(0)}`;
    content = content.toUpperCase();

    return (
      <Label
        circular
        content={content}
        title="Assigned Advisor"
        className="clickable"
        onClick={onClick}
        style={{
          backgroundColor: properties.color || "#767676",
          color: "white",
          borderRadius: "100px",
          wordBreak: "normal",
          display: "flex",
          fontSize: properties.circleFontSize,
          height: properties.circleSize,
          width: properties.circleSize,
          justifyContent: "center",
          overflow: "hidden",
          userSelect: "none",
          fontWeight: properties.bold ? "bold" : "normal",
          paddingTop: properties.paddingTop, // 2.9 fixes a subpixel rendering round-off BS issue
        }}
      />
    );
  }
}

export default InitialsCircle;
