export const initialState = {
  households: {},
  isEmpty: true,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_HOUSEHOLDS":
      return {
        ...action.households,
      };
    case "SET_HOUSEHOLD": {
      const newState = { ...state };
      newState[action.household.id] = action.household;
      return newState;
    }
    case "ADD_HOUSEHOLD":
      try {
        return {
          ...state,
          [action.household.id]: action.household,
        };
      } catch (e) {
        console.error(e);
        return state;
      }
    case "EDIT_HOUSEHOLD":
      try {
        const newState = { ...state };
        newState[action.household.id] = action.household;
        return newState;
      } catch (e) {
        console.error(e);
        return state;
      }
    case "REMOVE_HOUSEHOLD":
      try {
        const newState = { ...state };
        delete newState[action.householdId];
        return newState;
      } catch (e) {
        console.error(e);
        return state;
      }
    case "ADD_HOUSEHOLD_MEMBER":
      try {
        const newState = { ...state };
        newState[action.householdMember.householdId].householdMembers.push(
          action.householdMember
        );
        return newState;
      } catch (e) {
        console.error(e);
        return state;
      }
    case "EDIT_HOUSEHOLD_MEMBER":
      try {
        const newState = { ...state };
        Object.assign(
          newState[action.householdMember.householdId].householdMembers.find(
            (hm) => {
              return hm.id === action.householdMember.id;
            }
          ),
          action.householdMember
        );
        return newState;
      } catch (e) {
        console.error(e);
        return state;
      }
    case "REMOVE_HOUSEHOLD_MEMBER":
      try {
        const newState = { ...state };
        newState[action.householdId].householdMembers = newState[
          action.householdId
        ].householdMembers.filter((hm) => {
          return hm.id !== action.householdMemberId;
        });
        return newState;
      } catch (e) {
        console.error(e);
        return state;
      }
    default:
      return state;
  }
};

export const householdLoader = (state = { fetching: false }, action) => {
  switch (action.type) {
    case "FETCHING_HUB_HOUSEHOLDS": {
      return { fetching: action.value };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
