import React from "react";
import PropTypes from "prop-types";

import { Button, Container } from "semantic-ui-react";

export function ActionButtons({
  loading,
  deleteText,
  doneText,
  onClickDelete,
  onDoneClick,
  viewOnly,
}) {
  return (
    <Container
      style={{
        borderTop: "1px #e0e1e2 solid",
        paddingTop: "0.5em",
        paddingBottom: "0.5em",
        minHeight: "4em",
        height: "3em",
        margin: "0.5em 0 0.5em 0",
        textAlign: "center",
        backgroundColor: "white",
        display: "flex",
        justifyContent: "space-around",
      }}
    >
      {!viewOnly && (
        <Button
          data-test="workflow-edit-button-delete"
          negative
          basic
          style={{ width: "45%" }}
          title={deleteText}
          loading={loading}
          disabled={loading}
          onClick={onClickDelete}
        >
          {deleteText}
        </Button>
      )}
      <Button
        basic
        positive={!viewOnly}
        loading={loading}
        disabled={loading}
        data-test="workflow-edit-button-done"
        style={{ width: "45%" }}
        title={doneText}
        onClick={onDoneClick}
      >
        {doneText}
      </Button>
    </Container>
  );
}

ActionButtons.propTypes = {
  loading: PropTypes.bool,
  deleteText: PropTypes.string,
  doneText: PropTypes.string,
  onClickDelete: PropTypes.func.isRequired,
  onDoneClick: PropTypes.func.isRequired,
  viewOnly: PropTypes.bool,
};

ActionButtons.defaultProps = {
  deleteText: "Delete",
  doneText: "Done",
  loading: false,
  viewOnly: false,
};

export default ActionButtons;
