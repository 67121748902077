import React from "react";
import PropTypes from "prop-types";

import { useSync } from "data/hub/sync";

import ManageListModal from "components/ManageListModal";

import { useManageServiceRoles, useSyncDecider } from "./hooks";

const ManageUserRolesModal = ({ open, onClose }) => {
  const {
    add,
    isAdding,
    reorder,
    loading,
    visibleItems,
    showMoreItems,
    dispatchVisibleItems,
    dispatchShowMoreItems,
    syncableNameOptions,
    defaultOption,
  } = useManageServiceRoles();

  const { runFullSync } = useSync();

  const selectDispatch = (showMore) => {
    return showMore ? dispatchShowMoreItems : dispatchVisibleItems;
  };

  const { shouldSync, updateDecision, clearDecision } = useSyncDecider();

  return (
    <ManageListModal
      data-test="manage-user-roles-modal"
      header="Manage User Roles"
      open={open}
      onClose={() => {
        if (shouldSync()) {
          runFullSync();
        }
        clearDecision();
        onClose();
      }}
      visibleItems={visibleItems}
      showMoreItems={showMoreItems}
      isAdding={isAdding}
      onRename={(item, newName) => {
        return item.rename(newName).then(() => {
          const dispatch = selectDispatch(item.showMore);
          dispatch({ type: "edit", value: { id: item.id, name: newName } });
        });
      }}
      onChangeOption={(item, selected) => {
        const dispatch = selectDispatch(item.showMore);
        dispatch({
          type: "edit",
          value: { id: item.id, syncableName: selected?.key },
        });
        return item.updateSyncable(selected?.key).then(() => {
          updateDecision(item.id, selected?.key);
        });
      }}
      onDelete={(item) => {
        return item.delete().then(() => {
          const dispatch = selectDispatch(item.showMore);
          dispatch({ type: "remove", value: item.id });
        });
      }}
      onAdd={(name, selected) => {
        return add(name, selected?.key).then((id) => {
          updateDecision(id, selected?.key);
        });
      }}
      onReorder={({ visible, showMore }) => {
        dispatchVisibleItems({ type: "set", value: visible });
        dispatchShowMoreItems({ type: "set", value: showMore });
        return reorder({ visible, showMore });
      }}
      icons={["users"]}
      maxItems={20}
      maxItemsMessage="There is a limit of 20 service roles. If you need more, please contact customer support."
      loading={loading}
      itemOptions={syncableNameOptions}
      defaultOption={defaultOption}
    />
  );
};

ManageUserRolesModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

ManageUserRolesModal.defaultProps = {
  open: false,
};

export default ManageUserRolesModal;
