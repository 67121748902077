import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {
  Button,
  Checkbox,
  Container,
  Dropdown,
  Icon,
  List,
} from "semantic-ui-react";

import "../Workflow.css";

import { SetAlert } from "components/Alerts/actions";

import {
  AddNextWorkflow,
  AddNextWorkflowsToAll,
  RemoveNextWorkflow,
  RemoveNextWorkflowsFromAll,
} from "../actions";

class NextWorkflows extends React.Component {
  static propTypes = {
    addNextWorkflow: PropTypes.func.isRequired,
    addNextWorkflowsToAll: PropTypes.func.isRequired,
    workflow: PropTypes.shape({
      nextWorkflows: PropTypes.array.isRequired,
      id: PropTypes.string.isRequired,
    }).isRequired,
    workflows: PropTypes.arrayOf(
      PropTypes.shape({
        nextWorkflows: PropTypes.array.isRequired,
        id: PropTypes.string.isRequired,
      }).isRequired
    ).isRequired,
    removeNextWorkflow: PropTypes.func.isRequired,
    removeNextWorkflowsFromAll: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      nextWorkflowId: null,
      applyNextWorkflowsToAll: true,
      showQueueForm: false,
    };
  }

  createNextWorkflowsList = () => {
    const { workflow, workflows } = this.props;
    const options = [];
    Object.values(workflows).forEach((currentWorkflow) => {
      // Ignore the current workflow and any workflows that already exist in the next workflows list
      const nextWorkflows = workflow.nextWorkflows || [];
      const nextWorkflowMatch = nextWorkflows.find((nextWorkflow) => {
        return nextWorkflow.nextWorkflowId === currentWorkflow.id;
      });
      if (currentWorkflow.id !== workflow.id && !nextWorkflowMatch) {
        options.push({
          text: currentWorkflow.name,
          key: currentWorkflow.id,
          value: currentWorkflow.id,
        });
      }
    });
    return options;
  };

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };

  toggle = () => {
    this.setState((state) => {
      return { applyNextWorkflowsToAll: !state.applyNextWorkflowsToAll };
    });
  };

  enterTyped = (e) => {
    // handles enter key press for adding tasks
    if (e.keyCode === 13) {
      // Enter pressed
      this.addNextWorkflow();
    }
  };

  // This function takes in the entire row, not just the nextWorkflowId column in the next workflow table
  // The add function takes the opposite
  removeNextWorkflow = (nextWorkflowObject) => {
    const {
      removeNextWorkflow,
      removeNextWorkflowsFromAll,
      setAlert,
      workflow,
      workflows,
    } = this.props;
    const { applyNextWorkflowsToAll } = this.state;
    const nextWorkflow = { ...workflows[nextWorkflowObject.nextWorkflowId] };
    if (!nextWorkflow) {
      console.warn(
        `Could not find next workflow to remove with ID: ${nextWorkflowObject.id}`
      );
      setAlert({
        type: "error",
        text: `Next workflow not found. Please select a different workflow`,
      });
      return;
    }

    removeNextWorkflow(workflow, nextWorkflowObject.id, nextWorkflow.name);

    // Need the nextWorkflowId, rather than the id of the row for finding and removing from all client workflows
    if (applyNextWorkflowsToAll) {
      removeNextWorkflowsFromAll(
        workflow,
        nextWorkflowObject.nextWorkflowId,
        nextWorkflow.name
      );
    }
  };

  // This function uses nextWorkflowId column in the next workflow table, not entire row
  // The remove function takes the entire row, rather than the just the column nextWorkflowId
  addNextWorkflow = () => {
    const {
      addNextWorkflow,
      addNextWorkflowsToAll,
      setAlert,
      workflow,
      workflows,
    } = this.props;
    const { applyNextWorkflowsToAll, nextWorkflowId } = this.state;

    const nextWorkflow = { ...workflows[nextWorkflowId] };
    if (!nextWorkflow) {
      console.warn(`Could not find workflow to add with ID: ${nextWorkflowId}`);
      setAlert({
        type: "error",
        text: `Next workflow not found. Please select a different workflow`,
      });
      return;
    }

    addNextWorkflow(workflow, nextWorkflowId, nextWorkflow.name);
    this.setState({ nextWorkflowId: null });

    if (applyNextWorkflowsToAll) {
      addNextWorkflowsToAll(workflow, nextWorkflowId, nextWorkflow.name);
    }
  };

  toggleShowQueueForm = () => {
    this.setState((state) => {
      return { showQueueForm: !state.showQueueForm };
    });
  };

  render() {
    const { applyNextWorkflowsToAll, nextWorkflowId, showQueueForm } =
      this.state;
    const { workflow, workflows } = this.props;
    const nextWorkflows = workflow.nextWorkflows || [];
    const showList = nextWorkflows.length > 0;
    const nextWorkflowOptions = this.createNextWorkflowsList();
    return (
      <Container>
        <List style={{ marginTop: "0em", marginBottom: "0em" }}>
          {showList ? (
            nextWorkflows.map((nextWorkflowObject) => {
              const nextWorkflow = workflows[nextWorkflowObject.nextWorkflowId];
              if (nextWorkflow) {
                return (
                  <List.Item
                    className="grey_on_hover rounded p25"
                    key={`${workflow.id}_${nextWorkflow.id}`}
                  >
                    <Icon
                      color="grey"
                      link
                      name="close"
                      onClick={() => {
                        this.removeNextWorkflow(nextWorkflowObject);
                      }}
                      style={{ float: "right" }}
                    />
                    {nextWorkflow.name}
                  </List.Item>
                );
              } else {
                return null;
              }
            })
          ) : (
            <span style={{ color: "grey" }}>No workflows queued</span>
          )}
        </List>
        {showQueueForm ? (
          <React.Fragment>
            <div
              style={{
                display: "flex",
                marginTop: "5px",
                flexDirection: "column",
              }}
            >
              <Dropdown
                placeholder="Search for Workflow"
                fluid
                search
                selection
                clearable
                scrolling
                className="next-workflows-search-dropdown"
                name="nextWorkflowId"
                onChange={this.handleChange}
                onKeyUp={this.enterTyped}
                options={nextWorkflowOptions}
                value={nextWorkflowId}
                style={{
                  minHeight: "auto !important",
                  height: "2em",
                }}
                upward
                noResultsMessage="No Workflows to Add"
                selectOnBlur={false}
              />
              <Checkbox
                name="applyNextWorkflowsToAll"
                checked={applyNextWorkflowsToAll}
                label="Apply to all clients"
                onChange={this.toggle}
                style={{ marginTop: "0.5em" }}
              />
            </div>
            <div style={{}}>
              <Button
                compact
                content="Confirm"
                onClick={this.addNextWorkflow}
                color="green"
              />
              <Icon
                color="grey"
                size="large"
                name="delete"
                link
                onClick={this.toggleShowQueueForm}
              />
            </div>
          </React.Fragment>
        ) : (
          <div>
            <Button
              compact
              basic
              content="Add Workflow"
              onClick={this.toggleShowQueueForm}
            />
          </div>
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    advisor: state.hubly.data.advisor,
    workflow: state.hubly.data.hub.workflows[ownProps.workflowId],
    workflows: state.hubly.data.hub.workflows,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addNextWorkflow: (workflow, nextWorkflowId, nextWorkflowName) => {
      dispatch(AddNextWorkflow(workflow, nextWorkflowId, nextWorkflowName));
    },
    removeNextWorkflow: (workflow, nextWorkflowObject, nextWorkflowName) => {
      dispatch(
        RemoveNextWorkflow(workflow, nextWorkflowObject, nextWorkflowName)
      );
    },
    addNextWorkflowsToAll: (workflow, nextWorkflowId, nextWorkflowName) => {
      dispatch(
        AddNextWorkflowsToAll(workflow, nextWorkflowId, nextWorkflowName)
      );
    },
    removeNextWorkflowsFromAll: (
      workflow,
      nextWorkflowId,
      nextWorkflowName
    ) => {
      dispatch(
        RemoveNextWorkflowsFromAll(workflow, nextWorkflowId, nextWorkflowName)
      );
    },
    setAlert: (alert) => {
      dispatch(SetAlert(alert));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NextWorkflows);
