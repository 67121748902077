import React from "react";
import PropTypes from "prop-types";

import { Popper } from "components/Popper";

export function Tooltip({ title, hidden, children, ...popperProps }) {
  if (hidden || !title) return children;

  return (
    <Popper data-test="tooltip" on="hover" trigger={children} {...popperProps}>
      {title}
    </Popper>
  );
}

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  hidden: PropTypes.bool,
};

Tooltip.defaultProps = {
  title: undefined,
  hidden: false,
};

export default Tooltip;
