import { useQuery } from "hooks";

const getRolesQueryFactory = () => {
  const query = `query hubRoles {
    roles {
      edges {
        node {
          id
          title
        }
      }
    }
  }
  `;

  const resolver = ({ data }) => {
    return data.roles.edges.map(({ node }) => {
      return node;
    });
  };

  return { query, resolver };
};

const useGetRoles = () => {
  return useQuery(getRolesQueryFactory);
};

export default useGetRoles;
