import React, { useContext, useState } from "react";
import PropTypes from "prop-types";

import { Icon, Modal } from "semantic-ui-react";

import { ConfirmationModal } from "components/ConfirmationModal";
import ExportStreamOrTagButton from "components/ExportStreamOrTagButton/ExportStreamOrTagButton";

import { HubContext } from "scenes/Hubly/components/Workspace/Provider";

import capitalizeFirstLetter from "../../helper";
import { useMembers } from "../../hooks";
import {
  MembersSection,
  ModalActions,
  ModalInput,
  StreamColorSelector,
} from "./components";

export function ManyToManyModal({
  obj,
  type,
  disableCRMCheck,
  updateData,
  deleteData,
  onViewClick,
  onClose,
  onModifyClient,
  exportProps,
  fetchMembers,
  ...otherProps
}) {
  const { integration } = useContext(HubContext);

  const [color, setColor] = useState(obj.color);
  const [name, setName] = useState(obj.name);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [updating, setUpdating] = useState(false);
  const isWealthboxUser =
    integration && integration.type === "wealthbox" && !disableCRMCheck;
  const members = useMembers({ fetchMembers: fetchMembers, id: obj.id });

  const closeModal = () => {
    if (members.isModified) onModifyClient();
    onClose();
  };

  const update = () => {
    const updateSelector = () => {
      return name !== obj.name || (color && color !== obj.color)
        ? updateData
        : () => {
            return Promise.resolve();
          };
    };

    const processUpdate = updateSelector();
    setUpdating(true);
    processUpdate(obj.id, {
      name: name,
      color: color,
      failedIfExists: true,
    }).finally(() => {
      closeModal();
    });
  };

  return (
    <React.Fragment>
      <Modal data-test={`${type}-modal`} onClose={closeModal} open>
        <Modal.Header>
          <Icon
            name="delete"
            link
            color="grey"
            style={{ float: "right", position: "relative" }}
            onClick={() => {
              closeModal();
            }}
          />
          Manage {capitalizeFirstLetter(type)}
        </Modal.Header>
        <Modal.Content>
          <React.Fragment>
            <ModalInput
              isWealthboxUser={isWealthboxUser}
              isExternalIdNull={isWealthboxUser && !obj.externalId}
              defaultValue={name}
              onChange={setName}
              onEdit={update}
            />
            {type === "stream" && (
              <StreamColorSelector streamColor={color} onChange={setColor} />
            )}
          </React.Fragment>
          <MembersSection
            obj={obj}
            members={members}
            type={type}
            hasIntegration={integration !== null}
            onViewClick={(clientId) => {
              onViewClick(clientId);
              closeModal();
            }}
            {...otherProps}
          />
        </Modal.Content>
        <Modal.Actions>
          <ModalActions
            isWealthboxUser={isWealthboxUser}
            loading={updating}
            disabled={updating || name === ""}
            onDeleteClick={() => {
              setShowConfirmationModal(true);
            }}
            onEditClick={update}
          />
          <ExportStreamOrTagButton
            disabled={updating}
            style={{ float: "left" }}
            {...exportProps}
          />
        </Modal.Actions>
      </Modal>
      {showConfirmationModal && (
        <ConfirmationModal
          title={`Delete "${obj.name}" ${capitalizeFirstLetter(type)}`}
          message={`This will delete "${obj.name}" and remove this ${type} from all associated clients. This cannot be undone.`}
          icon="delete"
          buttons={[
            {
              text: "Cancel",
              callBack: () => {
                setShowConfirmationModal(false);
              },
            },
            {
              text: `Delete ${capitalizeFirstLetter(type)}`,
              color: "red",
              callBack: () => {
                setShowConfirmationModal(null);
                setUpdating(true);
                deleteData(obj.id).finally(() => {
                  closeModal();
                });
              },
            },
          ]}
        />
      )}
    </React.Fragment>
  );
}

ManyToManyModal.propTypes = {
  obj: PropTypes.object.isRequired,
  exportProps: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  fetchMembers: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  updateData: PropTypes.func.isRequired,
  deleteData: PropTypes.func.isRequired,
  onViewClick: PropTypes.func.isRequired,
  onModifyClient: PropTypes.func.isRequired,
  disableCRMCheck: PropTypes.bool,
};

ManyToManyModal.defaultProps = {
  disableCRMCheck: false,
};

export default ManyToManyModal;
