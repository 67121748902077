import React from "react";
import PropTypes from "prop-types";

import { Container, Header } from "semantic-ui-react";

import { MultipleTimePerClientOption } from "scenes/Hubly/components/Workflows/components/WorkflowOptions/MultipleTimePerClientOption";

export function OptionsEditor({ options, disabled, onUpdate }) {
  return (
    <Container style={{ marginTop: "1em" }}>
      <Header as="h5" style={{ marginBottom: "0.5em" }}>
        Options
      </Header>
      <MultipleTimePerClientOption
        disabled={disabled}
        multipleTimePerClient={options.multipleTimePerClient}
        onSetToggle={(value) => {
          onUpdate({ options: { ...options, ...value } });
        }}
      />
    </Container>
  );
}

OptionsEditor.propTypes = {
  options: PropTypes.object,
  disabled: PropTypes.bool,
  onUpdate: PropTypes.func.isRequired,
};

OptionsEditor.defaultProps = {
  options: {},
  disabled: false,
};

export default OptionsEditor;
