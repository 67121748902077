import React, { useContext, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import clone from "rfdc";
import { Button, Dropdown, Header, Icon } from "semantic-ui-react";

import "./AddClientDropdown.css";

import { SetInProgress } from "data/hub/actions";
import {
  GetAllClients,
  SetActiveClient,
  SetActiveClients,
} from "data/hub/clients/actions";
import {
  GetAllClientWorkflows,
  SetClientWorkflow,
  SetClientWorkflows,
} from "data/hub/clientWorkflows/actions";
import { SetWorkflow, SetWorkflows } from "data/hub/workflows/actions";
import {
  GetClient,
  getOrderedFullClientName,
  GetRecentClientNames,
  sortClients,
  UpdateClientCardOpened,
} from "data/libs/clients";
import {
  BulkAddClientWorkflows,
  CreateClientWorkflow,
} from "data/libs/clientWorkflows";
import { GetReminders } from "data/libs/reminders";
import { ClientsInStream } from "data/libs/streams";
import { CreateSync, GetSync } from "data/libs/sync";
import { ClientsInTag } from "data/libs/tags";
import { GetWorkflows } from "data/libs/workflows";
import { InsertReminder, SetReminders } from "data/reminders/actions";

import { SetAlert } from "components/Alerts/actions";
import { SetConfirmationModal } from "components/ConfirmationModal/actions";
import { OpenCreateClientModal } from "components/CreateClientModal/actions";
import MarkedText from "components/MarkedText";
import { PrivacyModeContext } from "components/PrivacyMode/Context";
import { Spinner } from "components/Spinner";

import ReloadDataAfterSync from "scenes/Hubly/components/TopMenu/components/ContactSearch/actions";
import ChevronOption from "scenes/Hubly/components/TopMenu/components/ContactSearch/components/ChevronOption";
import ClientSyncOption from "scenes/Hubly/components/TopMenu/components/ContactSearch/components/ClientSyncOption";
import recordDatadogContactAction, {
  clientNameFilter,
} from "scenes/Hubly/components/TopMenu/components/ContactSearch/helpers";

import { filterNameByKeyword } from "../../HubMenu/WorkflowFilters/helpers";
import { AddClientToWorkflow } from "../actions";

const deepClone = clone();

export function AddClientDropdown({
  workflow,
  allClientNames,
  clientWorkflows,
  hub,
  inProgress,
  ...props
}) {
  const context = useContext(PrivacyModeContext);
  const [loading, setLoading] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [adding, setAdding] = useState(false);
  const [showStreams, setShowStreams] = useState(false);
  const [showTags, setShowTags] = useState(false);
  const [showAllContacts, setShowAllContacts] = useState(false);
  const [showTeamRecentContacts, setShowTeamRecentContacts] = useState(false);
  const [recentClientNames, setRecentClientNames] = useState({
    recentNames: [],
    teamRecentNames: [],
  });
  const [loadingInProgress, setLoadingInProgress] = useState(false);
  const [dropdownOpenedTime, setDropdownOpenedTime] = useState(0);

  const syncClientsComplete = () => {
    const { reloadDataAfterSync } = props;
    reloadDataAfterSync(hub);
  };

  const syncClientsFailed = (error) => {
    const { setInProgress, setAlert } = props;
    console.warn(error);
    setInProgress(false, 0);
    if (error && error.response && error.response.status === 422) {
      setAlert({ type: "warning", text: "Client sync already in progress." });
    } else {
      setAlert({ type: "error", text: "Failed to sync clients from CRM." });
    }
  };

  const checkSyncComplete = (syncId) => {
    const { setInProgress } = props;
    GetSync(syncId)
      .then((response) => {
        if (response.status === "failed") {
          syncClientsFailed();
        } else if (response.status === "completed") {
          setInProgress(true, 100);
          syncClientsComplete();
        } else {
          let percent = 10; // Set to a default value until we know the number of total pages
          if (response.totalPages) {
            percent = (response.currentPage / response.totalPages) * 100;
          }
          setInProgress(true, percent);
          setTimeout(() => {
            checkSyncComplete(syncId);
          }, 1000);
        }
      })
      .catch(() => {
        syncClientsFailed();
      });
  };

  const getIntegration = () => {
    const { advisor } = props;
    return !hub
      ? null
      : advisor.integrations.find((i) => {
          return i.hubId === hub.id;
        });
  };

  const syncClients = () => {
    const { setInProgress, advisor } = props;

    if (getIntegration()) {
      const request = {
        type: "contacts",
        advisor_id: advisor.id,
        hub_id: hub.id,
      };
      setInProgress(true, 0);
      CreateSync(request)
        .then((response) => {
          const syncId = response.id;
          setTimeout(() => {
            checkSyncComplete(syncId);
          }, 1000);
        })
        .catch(syncClientsFailed);
    }
  };

  const recordCustomAction = (source, createdTimestamp) => {
    const actionName =
      searchInput !== "" && source === "all_contacts"
        ? "search_by_name"
        : source;
    recordDatadogContactAction(
      actionName,
      createdTimestamp,
      "hubly_add_client_dropdown"
    );
  };

  const getMinOrderValue = (items = []) => {
    let min = Number.MAX_VALUE;

    if (items.length === 0) {
      return 1;
    }

    items.forEach((i) => {
      const item = clientWorkflows[i];
      if (item.order != null && item.order < min) {
        min = item.order;
      }
    });

    return min;
  };

  const clientOptionProps = (client, i) => {
    return {
      fullname: getOrderedFullClientName(client, hub),
      key: `${client.id}_${i}`,
      value: client.id,
      firstname: client.firstName,
      lastname: client.lastName,
      middlename: client.middleName,
      nickname: client.nickname,
      content: (
        <MarkedText
          text={getOrderedFullClientName(client, hub)}
          keyword={searchInput}
        />
      ),
    };
  };

  const filterClientNames = ({ recentNames, teamRecentNames }) => {
    // Get array of client IDs already in workflow and not hidden and exclude from the sorted list
    const visibleClientWorkflows = workflow.clients.filter(
      (clientWorkflowId) => {
        const clientWorkflow = clientWorkflows[clientWorkflowId];
        return clientWorkflow && !clientWorkflow.isHidden;
      }
    );
    const workflowClientIds = visibleClientWorkflows.map((clientWorkflowId) => {
      const clientWorkflow = clientWorkflows[clientWorkflowId];
      return clientWorkflow.clientId;
    });
    return {
      recentNames: recentNames
        .filter((client) => {
          return !workflowClientIds.includes(client.id);
        })
        .map(clientOptionProps),
      teamRecentNames: teamRecentNames
        .filter((client) => {
          return !workflowClientIds.includes(client.id);
        })
        .map(clientOptionProps),
    };
  };

  const getRecentClientNames = () => {
    const { setAlert } = props;
    setLoadingInProgress(true);
    GetRecentClientNames(hub)
      .then((resp) => {
        setRecentClientNames(filterClientNames(resp));
      })
      .catch((error) => {
        console.error(error);
        setAlert({
          type: "error",
          text: `Failed to load recent client list`,
        });
      })
      .finally(() => {
        setLoadingInProgress(false);
      });
  };

  const onDropdownOpened = () => {
    setDropdownOpenedTime(new Date().getTime());
    if (!loadingInProgress) getRecentClientNames();
  };

  const addClientToWorkflow = (client, householdClient = false) => {
    const {
      setClientWorkflow,
      setActiveClient,
      workflows,
      setWorkflow,
      reminders,
      setReminders,
      onClientAdded,
      setAlert,
    } = props;
    setLoading(true);
    setAdding(false);

    const clientId = householdClient ? client.client.id : client.id;
    const name = householdClient ? client.client.name : client.name;

    if (
      workflow.clients.find((id) => {
        return id === client.id;
      })
    ) {
      onClientAdded(true);
      setAlert({
        text: `${name} is already added to the workflow`,
        type: "success",
      });

      setLoading(false);
      return;
    }
    const nextWorkflows = Object.assign([], workflow.nextWorkflows);
    nextWorkflows.forEach((nextWorkflow) => {
      delete nextWorkflow.clientWorkflowId;
    });
    const request = {
      workflowId: workflow.id,
      clientId: clientId,
      order: getMinOrderValue(workflow.clients) - 1,
    };
    CreateClientWorkflow(request)
      .then((response) => {
        setSearchInput("");
        setLoading(false);
        setClientWorkflow(response);
        GetClient(clientId).then((clientResponse) => {
          clientResponse.workflows.push(response.id);
          setActiveClient(clientResponse);
        });

        const workflowCopy = { ...workflows[workflow.id] };
        workflowCopy.clients.push(response.id);
        setWorkflow(workflowCopy);
        const remindersCopy = reminders.slice();
        (response.tasks || []).forEach((task) => {
          task.reminders.forEach((reminder) => {
            InsertReminder(reminder, remindersCopy);
          });
        });
        setReminders(remindersCopy);
        setAlert({
          text: `Successfully added ${name} to the workflow`,
          type: "success",
        });
        UpdateClientCardOpened(clientId, {
          clientWorkflow: response.id,
        }).catch((error) => {
          console.error(error);
        });
        onClientAdded(true);
      })
      .catch((error) => {
        setAlert({
          type: "error",
          text: `Failed to add ${name} to the workflow`,
        });
        onClientAdded(false);
        setLoading(false);
      });
  };

  const isHouseholdMember = (client) => {
    const { households, setConfirmationModal } = props;
    let head;
    let householdMember;
    if (client.householdId in households) {
      households[client.householdId].householdMembers.forEach((member) => {
        if (member.client.id === client.id && member.title !== "Head") {
          householdMember = member;
        }
        if (member.title === "Head") {
          head = member;
        }
      });
      if (!householdMember || !head) {
        addClientToWorkflow(client);
        return;
      }
      if (workflow.clients) {
        if (
          workflow.clients.find((_client) => {
            return _client.clientId === head.client.id;
          })
        ) {
          const params = {
            title: `${head.client.name} is the head of this household and already in this workflow`,
            message: `Are you sure that you want to add ${householdMember.client.name} as well?`,
            icon: "tasks",
            buttons: [
              { text: "Cancel" },
              {
                text: `Add ${householdMember.client.name}`,
                callBack: () => {
                  addClientToWorkflow(householdMember, true);
                },
              },
            ],
          };
          setConfirmationModal(params);
        } else {
          const params = {
            title: `${head.client.name} is currently the head of this household`,
            message: `Are you sure that you want to add ${householdMember.client.name} instead?`,
            icon: "tasks",
            buttons: [
              { text: "Cancel" },
              {
                text: `Add ${householdMember.client.name}`,
                callBack: () => {
                  addClientToWorkflow(householdMember, true);
                },
              },
              {
                text: `Add ${head.client.name}`,
                callBack: () => {
                  addClientToWorkflow(head, true);
                },
                color: "green",
              },
            ],
          };
          setConfirmationModal(params);
        }
      }
    } else {
      addClientToWorkflow(client);
    }
  };

  const addClient = (e, { value }) => {
    const { openCreateClientModal } = props;
    // Setting isClientWorkflow to true and adding function to add client to workflow as this will add the client
    //  to workflow after creating client
    openCreateClientModal(searchInput, true, isHouseholdMember);
    setSearchInput("");
    setAdding(false);
  };

  const createClientList = () => {
    let sortedClients = sortClients(allClientNames, hub);
    if (!workflow.options?.multipleTimePerClient) {
      // Get array of client IDs already in workflow and not hidden and exclude from the sorted list
      const visibleClientWorkflows = workflow.clients.filter(
        (clientWorkflowId) => {
          const clientWorkflow = clientWorkflows[clientWorkflowId];
          return clientWorkflow && !clientWorkflow.isHidden;
        }
      );
      const workflowClientIds = visibleClientWorkflows.map(
        (clientWorkflowId) => {
          const clientWorkflow = clientWorkflows[clientWorkflowId];
          return clientWorkflow.clientId;
        }
      );
      sortedClients = sortedClients.filter((client) => {
        return !workflowClientIds.includes(client.id);
      });
    }

    return sortedClients.map(clientOptionProps);
  };

  const refreshHub = (object, type, success) => {
    const {
      setActiveClients,
      setAlert,
      setReminders,
      setWorkflows,
      setClientWorkflows,
      onClientAdded,
    } = props;

    GetAllClients(hub)
      .then((clientsResponse) => {
        setActiveClients(clientsResponse);

        GetAllClientWorkflows(hub).then((workflows) => {
          setClientWorkflows(workflows);

          GetWorkflows(hub).then((response) => {
            setWorkflows(response);

            if (success) {
              setAlert({
                text: `Successfully added all clients with ${type} ${object.name}`,
                type: "success",
              });
              onClientAdded(true);
            } else {
              setAlert({
                text: `Unable to add some clients with ${type} ${object.name}. Please verify and try again.`,
                type: "warning",
              });
              onClientAdded(false);
            }

            setLoading(false);
            setSearchInput("");
          });
        });
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
        setSearchInput("");
        setAlert({
          text: `Failed to add clients into workflow`,
          type: "error",
        });
        onClientAdded(false);
      })
      .finally(() => {
        GetReminders(hub, {
          client_id__isnull: false,
          limit: 999999999,
          ordering: "time",
          active: true,
        }).then((response) => {
          setReminders(response.results);
        });
      });
  };

  const isHeadOfHousehold = (client) => {
    const { households } = props;
    try {
      return (
        client.householdId &&
        households[client.householdId].householdMembers.filter(
          (householdMember) => {
            return (
              householdMember.clientId === client.id &&
              householdMember.title === "Head"
            );
          }
        ).length > 0
      );
    } catch (e) {
      console.error(
        "An unknown error occurred determining if client is a head of household"
      );
      console.error(e);
      return false;
    }
  };

  const bulkAddClientsToWorkflow = async (
    clients,
    object,
    type,
    onlyHouseholdHeads
  ) => {
    setLoading(true);
    let initialOrder = getMinOrderValue(workflow.clients) - 1;

    const req = {
      client_workflows: [],
    };
    const requests = [];
    let count = 0;

    clients.forEach((client) => {
      // Batch clients into groups of 25
      if (count > 0 && count % 25 === 0 && req.client_workflows.length > 0) {
        requests.push(deepClone(req));
        req.client_workflows = [];
      }

      // If onlyHouseholdHeads specified then filter out the non-household heads
      if (
        !onlyHouseholdHeads ||
        (onlyHouseholdHeads && isHeadOfHousehold(client))
      ) {
        req.client_workflows.push({
          workflow_id: workflow.id,
          client_id: client.id,
          order: initialOrder,
        });

        count += 1;
        initialOrder -= 1;
      }
    });

    // Push the leftover in
    requests.push(deepClone(req));
    const responses = [];

    for (let i = 0; i < requests.length; i += 1) {
      try {
        // eslint-disable-next-line no-await-in-loop
        await BulkAddClientWorkflows(requests[i]);
      } catch (e) {
        responses.push(e);
      }
    }

    if (responses.length >= 1) {
      // Shit went down
      refreshHub(object, type, false);
    } else {
      refreshHub(object, type, true);
    }
  };

  const addClientsByStream = (stream) => {
    const { setConfirmationModal, setAlert } = props;

    ClientsInStream(stream.id).then((clientResponse) => {
      const allClients = clientResponse.length;
      if (allClients === 0) {
        setAlert({
          type: "warning",
          text: "There are no clients with that stream",
        });
        return;
      }
      const householdHeads = clientResponse.filter((client) => {
        return isHeadOfHousehold(client);
      }).length;
      const buttons = [{ text: "Cancel" }];
      if (allClients > 0) {
        buttons.push({
          text: `Add all ${allClients} clients to workflow`,
          callBack: async () => {
            await bulkAddClientsToWorkflow(
              clientResponse,
              stream,
              "stream",
              false
            );
          },
        });
      }
      if (householdHeads > 0) {
        buttons.push({
          text: `Add ${householdHeads} household heads to workflow`,
          callBack: async () => {
            await bulkAddClientsToWorkflow(
              clientResponse,
              stream,
              "stream",
              true
            );
          },
        });
      }
      const params = {
        title: `Add Clients by Stream`,
        message:
          `Are you sure you wish to add all clients with stream ${stream.text}?` +
          ` This will add ${householdHeads} household head${
            householdHeads !== 1 ? "s" : ""
          }` +
          ` or ${allClients} client${
            allClients !== 1 ? "s" : ""
          } into this workflow.` +
          ` This process will take a few moments to complete.`,
        icon: "tasks",
        buttons: buttons,
      };
      setConfirmationModal(params);
    });
  };

  const addClientsByTag = (tag) => {
    const { setConfirmationModal, setAlert } = props;

    ClientsInTag(tag.id).then((clientResponse) => {
      const allClients = clientResponse.length;
      if (allClients === 0) {
        setAlert({
          type: "warning",
          text: "There are no clients with that tag",
        });
        return;
      }

      const householdHeads = clientResponse.filter((client) => {
        return isHeadOfHousehold(client);
      }).length;
      const buttons = [{ text: "Cancel" }];
      if (allClients > 0) {
        buttons.push({
          text: `Add all ${allClients} clients to workflow`,
          callBack: async () => {
            await bulkAddClientsToWorkflow(clientResponse, tag, "tag", false);
          },
        });
      }
      if (householdHeads > 0) {
        buttons.push({
          text: `Add ${householdHeads} household heads to workflow`,
          callBack: async () => {
            await bulkAddClientsToWorkflow(clientResponse, tag, "tag", true);
          },
        });
      }
      const params = {
        title: `Add Clients by Tag`,
        message:
          `Are you sure you wish to add all clients with tag ${tag.text}?` +
          ` This will add ${householdHeads} household head${
            householdHeads !== 1 ? "s" : ""
          }` +
          ` or ${allClients} client${
            allClients !== 1 ? "s" : ""
          } into this workflow.` +
          ` This process will take a few moments to complete.`,
        icon: "tasks",
        buttons: buttons,
      };
      setConfirmationModal(params);
    });
  };

  const clientSelected = (_, { value, source }) => {
    const { activeClients, setActiveClient } = props;
    recordCustomAction(source, dropdownOpenedTime);
    const client = activeClients[value];
    if (!client) {
      GetClient(value)
        .then((response) => {
          setActiveClient(response);
          isHouseholdMember(response);
          // this.setState({ adding: false });
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      isHouseholdMember(client);
    }
  };

  const onDropdownChanged = (e, params) => {
    const { tags } = props;
    const { value } = params;
    if (
      !loading &&
      (e.type === "click" || (e.type === "keydown" && e.key === "Enter"))
    ) {
      if (value === "create") {
        addClient(e, { value });
      } else if (value === "team-recent-contact-header") {
        setShowTeamRecentContacts(!showTeamRecentContacts);
      } else if (value === "all-contact-header") {
        setShowAllContacts(!showAllContacts);
      } else if (value === "sync") {
        syncClients();
        setSearchInput("");
      } else if (value === "tags-header") {
        setShowTags(!showTags);
      } else if (value === "streams-header") {
        setShowStreams(showStreams);
      } else if (value.startsWith("stream-option")) {
        // We need to differentiate between stream, tags and clients. For the selections,
        // from the keyboard event, we only have access to the value props (no other props
        // is available). Therefore, as workaround, we modified the value of the stream/tags
        // to include type, value="{type}_{id}"
        const streamParams = value.split("_");
        const stream = hub.streams.find((s) => {
          return streamParams.length === 2 && s.id === streamParams[1];
        });
        if (stream) addClientsByStream(stream);
      } else if (value.startsWith("tag-option")) {
        const tagParams = value.split("_");
        const tag = tagParams.length === 2 ? tags[tagParams[1]] : null;
        if (tag) addClientsByTag(tag);
      } else {
        clientSelected(e, params);
      }
    }
  };

  const setAddingClient = (addingClient) => {
    setAdding(addingClient);
  };

  const getStreamData = () => {
    return hub.streams.sort((a, b) => {
      return a.name > b.name ? 1 : -1;
    });
  };
  const getTagData = () => {
    const { tags } = props;
    return Object.values(tags).sort((a, b) => {
      return a.name > b.name ? 1 : -1;
    });
  };

  const { piiMask } = context;
  const { allClientNamesLoaded, style, opened } = props;
  const clientFilteredOptions = createClientList().filter((clientProps) => {
    return clientNameFilter({ ...clientProps }, searchInput);
  });
  const filteredStreamData = getStreamData().filter((stream) => {
    return filterNameByKeyword(stream.name, searchInput);
  });
  const filteredTagData = getTagData().filter((tag) => {
    return filterNameByKeyword(tag.name, searchInput);
  });

  const noop = () => {};
  const integration = getIntegration();
  if (loading || (!adding && !opened)) {
    return (
      <div
        style={{
          display: "flex",
          ...style,
        }}
      >
        <Button
          style={{
            backgroundColor: "rgb(249, 249, 249)",
            textAlign: "center",
            marginTop: 0,
          }}
          fluid
          onClick={() => {
            setAddingClient(true);
          }}
          className="add_client_button"
        >
          {loading ? (
            <React.Fragment>
              <Spinner
                active
                size="tiny"
                style={{
                  marginRight: "6px",
                  transform: "translate(0, -2px)",
                }}
              />
              <span style={{ color: "rgba(0,0,0,0.4)" }}>Adding</span>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Icon name="plus" />
              Add Client(s)
            </React.Fragment>
          )}
        </Button>
      </div>
    );
  }

  const createRecentContactOptions = () => {
    const options = [];
    if (recentClientNames.recentNames.length !== 0) {
      options.push({
        id: "recent-contact-header",
        key: "recent-contact-header",
        value: "recent-contact-header",
        content: <Header as="h5">My Recent Contacts</Header>,
        disabled: true,
        onClick: noop,
      });

      recentClientNames.recentNames.forEach((clientProps) => {
        options.push({
          ...clientProps,
          source: "my_recent_contact",
          onClick: clientSelected,
        });
      });
    }
    return options;
  };
  const createTeamRecentContactOptions = () => {
    const options = [];
    if (recentClientNames.teamRecentNames.length !== 0) {
      options.push({
        key: "team-recent-contact-header",
        value: "team-recent-contact-header",
        content: (
          <ChevronOption
            text="All Recent Contacts"
            chevronOpened={showTeamRecentContacts}
          />
        ),
        onClick: () => {
          setShowTeamRecentContacts(!showTeamRecentContacts);
        },
      });

      if (showTeamRecentContacts) {
        recentClientNames.teamRecentNames.forEach((clientProps) => {
          options.push({
            ...clientProps,
            source: "team_recent_contact",
            onClick: clientSelected,
          });
        });
      }
    }
    return options;
  };
  const createAddButton = () => {
    return [
      {
        key: "create",
        value: "create",
        style: { fontWeight: "bold" },
        content: <div>+ Create {searchInput}</div>,
        onClick: addClient,
      },
    ];
  };

  const createSyncOption = () => {
    const options = [];
    if (integration) {
      options.push({
        key: "sync",
        value: "sync",
        style: { fontWeight: "bold" },
        content: (
          <ClientSyncOption
            onClick={syncClients}
            inProgress={inProgress}
            integrationType={integration.type}
          />
        ),
        disabled: inProgress,
        onClick: () => {
          setSearchInput("");
        },
      });
    }
    return options;
  };

  const createAllContactOptions = () => {
    const options = [];
    if (searchInput === "" && clientFilteredOptions.length !== 0) {
      options.push({
        key: "all-contact-header",
        value: "all-contact-header",
        content: (
          <ChevronOption text="All Contacts" chevronOpened={showAllContacts} />
        ),
        onClick: () => {
          setShowAllContacts(!showAllContacts);
        },
      });
    }
    if (showAllContacts || searchInput !== "") {
      clientFilteredOptions.forEach((clientProps) => {
        options.push({
          ...clientProps,
          source: "all_contacts",
          onClick: clientSelected,
        });
      });
    }
    return options;
  };

  const createStreamOptions = () => {
    const options = [];

    if (showStreams || searchInput !== "") {
      filteredStreamData.forEach((stream) => {
        options.push({
          color: stream.color,
          key: stream.id,
          text: stream.name,
          value: `stream-option_${stream.id}`,
          onClick: () => {
            addClientsByStream(stream);
          },
          content: (
            <div style={{ display: "flex" }}>
              <Icon name="circle" style={{ color: stream.color }} />
              <div style={{ marginLeft: "0.5em" }}>
                <MarkedText text={stream.name} keyword={searchInput} />
              </div>
            </div>
          ),
        });
      });
    }
    if (options.length === 0 && (showStreams || searchInput !== "")) {
      options.push({
        key: "no-matching-streams",
        value: "no-matching-streams",
        content: (
          <span style={{ color: "grey" }}>
            {searchInput !== "" ? "No matching streams" : "No streams"}
          </span>
        ),
        disabled: true,
      });
    }
    const header =
      clientFilteredOptions.length === 0 &&
      filteredStreamData.length > 0 &&
      filteredTagData.length === 0
        ? null
        : {
            key: "streams-header",
            value: "streams-header",
            content: (
              <ChevronOption
                text="Streams"
                chevronOpened={showStreams || searchInput !== ""}
              />
            ),
            onClick: () => {
              setShowStreams(!showStreams);
            },
          };
    if (header) options.unshift(header);
    return options;
  };

  const createTagOptions = () => {
    const options = [];

    if (showTags || searchInput !== "") {
      filteredTagData.forEach((tag) => {
        options.push({
          color: tag.color,
          key: tag.id,
          text: tag.name,
          value: `tag-option_${tag.id}`,
          onClick: () => {
            addClientsByTag(tag);
          },
          content: (
            <div style={{ display: "flex" }}>
              <Icon name="hashtag" />
              <div style={{ marginLeft: "0.5em" }}>
                <MarkedText text={tag.name} keyword={searchInput} />
              </div>
            </div>
          ),
        });
      });
    }
    if (options.length === 0 && (showTags || searchInput !== "")) {
      options.push({
        key: "no-matching-tags",
        value: "no-matching-tags",
        content: (
          <span style={{ color: "grey" }}>
            {searchInput !== "" ? "No matching tags" : "No tags"}
          </span>
        ),
        disabled: true,
      });
    }

    const header =
      clientFilteredOptions.length === 0 &&
      filteredStreamData.length === 0 &&
      filteredTagData.length > 0
        ? null
        : {
            key: "tags-header",
            value: "tags-header",
            content: (
              <ChevronOption
                text="Tags"
                chevronOpened={showTags || searchInput !== ""}
              />
            ),
            onClick: () => {
              setShowTags(!showTags);
            },
          };
    if (header) options.unshift(header);
    return options;
  };

  const getOptions = () => {
    if (searchInput === "") {
      return [
        ...createRecentContactOptions(),
        ...createAddButton(),
        ...createSyncOption(),
        ...createTeamRecentContactOptions(),
        ...createStreamOptions(),
        ...createTagOptions(),
        ...createAllContactOptions(),
      ];
    } else if (
      clientFilteredOptions.length === 0 &&
      filteredTagData.length === 0 &&
      filteredStreamData.length > 0
    ) {
      return [
        ...createStreamOptions(),
        ...createAddButton(),
        ...createSyncOption(),
      ];
    } else if (
      clientFilteredOptions.length === 0 &&
      filteredTagData.length > 0 &&
      filteredStreamData.length === 0
    ) {
      return [
        ...createTagOptions(),
        ...createAddButton(),
        ...createSyncOption(),
      ];
    } else if (
      clientFilteredOptions.length === 0 &&
      filteredTagData.length > 0 &&
      filteredStreamData.length > 0
    ) {
      return [
        ...createStreamOptions(),
        ...createTagOptions(),
        ...createAddButton(),
        ...createSyncOption(),
      ];
    } else {
      return [
        ...createAllContactOptions(),
        ...createAddButton(),
        ...createSyncOption(),
        ...createStreamOptions(),
        ...createTagOptions(),
      ];
    }
  };

  const customSearch = (options, query) => {
    return getOptions();
  };

  return (
    <div style={{ display: "flex", flexFlow: "row", ...style }}>
      <div style={{ display: "flex", width: "100%" }}>
        <Dropdown
          className={piiMask("fs-block dd-privacy-mask")}
          loading={!allClientNamesLoaded || loadingInProgress}
          options={getOptions()}
          onChange={onDropdownChanged}
          onSearchChange={(e) => {
            setSearchInput(e.target.value);
          }}
          placeholder="Search for a client"
          search={customSearch}
          searchInput={{ autoFocus: true }}
          selection
          scrolling
          fluid
          value={searchInput}
          onBlur={(event) => {
            if (event.target.closest("#client-sync-button")) {
              return;
            }
            setAddingClient(false);
            setSearchInput("");
          }}
          onClose={() => {
            setSearchInput("");
          }}
          onOpen={() => {
            onDropdownOpened();
          }}
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginRight: "2px",
          }}
        >
          <Icon
            name="delete"
            color="grey"
            link
            style={{
              transform: "translate(3px, -1px)",
              fontSize: "12pt",
            }}
            onClick={(e) => {
              e.stopPropagation();
              const { onClearIconClicked } = props;
              onClearIconClicked(e);
            }}
          />
        </div>
      </div>
    </div>
  );
}

AddClientDropdown.defaultProps = {
  style: {},
  onClientAdded: () => {},
  onClearIconClicked: () => {},
  opened: false,
};

AddClientDropdown.propTypes = {
  activeClients: PropTypes.object.isRequired,
  allClientNames: PropTypes.array.isRequired,
  allClientNamesLoaded: PropTypes.bool.isRequired,
  advisor: PropTypes.object.isRequired,
  hub: PropTypes.shape({
    id: PropTypes.string.isRequired,
    streams: PropTypes.array.isRequired,
    tags: PropTypes.array.isRequired,
  }).isRequired,
  clientWorkflows: PropTypes.object.isRequired,
  households: PropTypes.object.isRequired,
  openCreateClientModal: PropTypes.func.isRequired,
  setActiveClient: PropTypes.func.isRequired,
  setActiveClients: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  setConfirmationModal: PropTypes.func.isRequired,
  setClientWorkflow: PropTypes.func.isRequired,
  setClientWorkflows: PropTypes.func.isRequired,
  setReminders: PropTypes.func.isRequired,
  setWorkflow: PropTypes.func.isRequired,
  setWorkflows: PropTypes.func.isRequired,
  tags: PropTypes.object.isRequired,
  workflow: PropTypes.shape({
    id: PropTypes.string.isRequired,
    options: PropTypes.object,
    clients: PropTypes.array.isRequired,
    nextWorkflows: PropTypes.array.isRequired,
  }).isRequired,
  workflows: PropTypes.object.isRequired,
  reminders: PropTypes.array.isRequired,
  style: PropTypes.object,
  opened: PropTypes.bool,
  onClientAdded: PropTypes.func,
  onClearIconClicked: PropTypes.func,
  setInProgress: PropTypes.func.isRequired,
  reloadDataAfterSync: PropTypes.func.isRequired,
  inProgress: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, props) => {
  const { workflowId } = props;
  return {
    advisor: state.hubly.data.advisor,
    activeClients: state.hubly.data.hub.clients.activeClients,
    allClientNames: state.hubly.data.hub.clients.allClientNames,
    allClientNamesLoaded: state.hubly.data.hub.clients.allClientNamesLoaded,
    clientWorkflows: state.hubly.data.hub.clientWorkflows,
    households: state.hubly.data.hub.households,
    hub: state.hubly.data.hub.selected.hub,
    tags: state.hubly.data.hub.tags,
    workflows: state.hubly.data.hub.workflows,
    workflow: state.hubly.data.hub.workflows[workflowId],
    reminders: state.hubly.data.reminders,
    inProgress: state.hubly.data.hub.selected.inProgress,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addClientToWorkflow: (clientWorkflow) => {
      dispatch(AddClientToWorkflow(clientWorkflow));
    },
    openCreateClientModal: (
      clientName,
      isClientWorkflow,
      addClientToWorkflow
    ) => {
      dispatch(
        OpenCreateClientModal(clientName, isClientWorkflow, addClientToWorkflow)
      );
    },
    setActiveClient: (client) => {
      dispatch(SetActiveClient(client));
    },
    setActiveClients: (clients) => {
      dispatch(SetActiveClients(clients));
    },
    setAlert: (alert) => {
      dispatch(SetAlert(alert));
    },
    setConfirmationModal: (id) => {
      dispatch(SetConfirmationModal(id));
    },
    setWorkflow: (workflow) => {
      dispatch(SetWorkflow(workflow));
    },
    setWorkflows: (workflows) => {
      dispatch(SetWorkflows(workflows));
    },
    setReminders: (reminders) => {
      dispatch(SetReminders(reminders));
    },
    updateClient: (client) => {
      dispatch(SetActiveClient(client));
    },
    setClientWorkflow: (clientWorkflow) => {
      dispatch(SetClientWorkflow(clientWorkflow));
    },
    setClientWorkflows: (clientWorkflows) => {
      dispatch(SetClientWorkflows(clientWorkflows));
    },
    reloadDataAfterSync: (hub) => {
      dispatch(ReloadDataAfterSync(hub));
    },
    setInProgress: (inProgress, percent) => {
      dispatch(SetInProgress(inProgress, percent));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddClientDropdown);
