import { useCallback } from "react";

import { useMutation } from "hooks";

export function useServiceRoleSyncableAction() {
  const mutationFactory = useCallback(() => {
    const query = `mutation updateFieldType($serviceRoleId: ID!, $syncableName: String) {
        updateServiceRoleFieldType(serviceRoleId: $serviceRoleId, syncableName: $syncableName) {
          serviceRole {
            id
            name
            syncableName
            isSynced
            showMore
          }
        }                    
      }
        `;

    const resolver = (resp) => {
      return resp;
    };

    return { query, resolver };
  }, []);

  const mutationFunc = useMutation(mutationFactory);

  return { updateServiceRoleSyncable: mutationFunc };
}

export default useServiceRoleSyncableAction;
