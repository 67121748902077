import React, { useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

import { Header, Input } from "semantic-ui-react";

import { FeatureFlag } from "components/FeatureFlag";

import {
  HubContext,
  ToasterContext,
} from "scenes/Hubly/components/Workspace/Provider";

import {
  useAccessTypeStore,
  useAssignWorkflowEditorsAction,
  useAvailableEditors,
} from "../../hooks";
import {
  ActionButtons,
  Assigner,
  AttachmentsEditor,
  DeleteWorkflowModal,
  EditorDropdown,
  EditorPlaceholder,
  NextWorkflowsEditor,
  OptionsEditor,
  RulesEditor,
  TasksEditor,
  WorkflowEditorLayout,
} from "./components";

export function WorkflowEditor({
  loading,
  reloadData,
  workflow,
  onClose,
  onDeleted,
}) {
  const { fetchAssignees, advisors, editWorkflow, deleteWorkflow } =
    useContext(HubContext);
  const { fireSuccess, fireError } = useContext(ToasterContext);
  const { hasAccess } = useAccessTypeStore();
  const { fetch, loadingEditors, editors } = useAvailableEditors(workflow?.id);
  const { isAssigning, assignEditors } = useAssignWorkflowEditorsAction(
    workflow?.id,
    reloadData
  );

  const [updateLoader, setUpdateLoader] = useState(false);

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const wfName = useRef();

  const updateWorkflow = (request) => {
    setUpdateLoader(true);
    return editWorkflow(workflow.id, request)
      .then(() => {
        reloadData();
      })
      .catch((error) => {
        fireError("Failed to update workflow", error);
        setUpdateLoader(false);
      });
  };

  const removeWorkflow = (deleteClients) => {
    setUpdateLoader(true);
    setShowDeleteConfirmation(false);
    return deleteWorkflow(workflow.id, deleteClients)
      .then(() => {
        fireSuccess(`Removed workflow ${workflow.name} succeeded`);
        onClose();
        onDeleted(workflow);
      })
      .catch((error) => {
        fireError("Failed to delete workflow", error);
        setUpdateLoader(false);
      });
  };

  const onCloseEditMode = () => {
    const isWorkflowNameUpdated = (name) => {
      return name && name !== workflow?.name;
    };

    const preClosingAction = isWorkflowNameUpdated(wfName.current)
      ? updateWorkflow
      : () => {
          reloadData();
          return Promise.resolve();
        };

    preClosingAction({ name: wfName.current }).then(() => {
      onClose();
    });
  };

  useEffect(() => {
    if (!workflow) reloadData();
  }, [workflow, reloadData]);

  if (loading && !workflow) {
    return <EditorPlaceholder numLines={workflow?.numTasks || 4} />;
  }

  return (
    <WorkflowEditorLayout
      data-test={`workflow-${workflow.name}`}
      assignedAdvisor={
        <FeatureFlag id="bulk-reassign-workflow-template-tasks" showOnDisabled>
          {advisors.length > 1 && (
            <Assigner
              disabled={!hasAccess()}
              loading={loading || updateLoader}
              size="big"
              style={{ margin: "0.25em 0.25em 0 0" }}
              currentAssignee={workflow?.assignedAdvisor}
              fetchAssignees={fetchAssignees}
              onAssign={(newAssignedId) => {
                updateWorkflow({
                  assignedAdvisorId:
                    newAssignedId === "unassigned" ? "" : newAssignedId,
                }).then(() => {
                  setUpdateLoader(false);
                });
              }}
            />
          )}
        </FeatureFlag>
      }
      nameEditor={
        hasAccess() ? (
          <Input
            loading={updateLoader}
            defaultValue={workflow.name}
            placeholder="Name of Workflow"
            floated="left"
            size="large"
            fluid
            onChange={(_, { value }) => {
              wfName.current = value;
            }}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                onCloseEditMode();
              }
            }}
          />
        ) : (
          <Header as="h3">{workflow.name}</Header>
        )
      }
      actionButtons={
        <ActionButtons
          viewOnly={!hasAccess()}
          loading={updateLoader}
          onClickDelete={() => {
            setShowDeleteConfirmation(true);
          }}
          onDoneClick={onCloseEditMode}
          doneText={!hasAccess() ? "Close" : "Done"}
        />
      }
    >
      <TasksEditor
        showAssignedAdvisor={advisors.length > 1}
        workflow={workflow}
      />
      <RulesEditor workflow={workflow} />
      <NextWorkflowsEditor workflow={workflow} />
      <AttachmentsEditor workflowId={workflow.id} />
      <FeatureFlag id="admin-permissions-settings">
        <EditorDropdown
          onOpen={fetch}
          selected={workflow.editors}
          disabled={!hasAccess()}
          loading={loading || loadingEditors || isAssigning}
          editors={editors}
          onChange={assignEditors}
        />
      </FeatureFlag>
      <OptionsEditor
        disabled={updateLoader || !hasAccess()}
        options={{
          presentWorkflowName: workflow.clientTileOptions?.presentWorkflowName,
          multipleTimePerClient: workflow.multipleTimePerClient,
        }}
        onUpdate={(request) => {
          updateWorkflow(request).then(() => {
            setUpdateLoader(false);
          });
        }}
      />
      {showDeleteConfirmation && (
        <DeleteWorkflowModal
          name={workflow.name}
          numActiveClients={workflow.numActiveClients}
          onClose={() => {
            setShowDeleteConfirmation(false);
          }}
          onRemove={removeWorkflow}
        />
      )}
    </WorkflowEditorLayout>
  );
}

WorkflowEditor.propTypes = {
  workflow: PropTypes.object,
  loading: PropTypes.bool,
  reloadData: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onDeleted: PropTypes.func.isRequired,
};

WorkflowEditor.defaultProps = {
  workflow: null,
  loading: false,
};

export default WorkflowEditor;
