import { API } from "aws-amplify";

import { getAPIReq } from "data/libs/authLib";

export const getProcessesAndWorkflowsWithNoProcess = async (hubId) => {
  const req = await getAPIReq(
    {
      query: `query getProcesses {
      processes {
        edges {
          node {
            uuid
            name
            description
            color
            numWorkflows
            numRed
            numGreen
            numYellow
            numActiveClients
            numActiveClientWorkflows
            workflows {
              edges {
                node {
                  uuid
                  name
                  numTasks
                  numActiveClients
                  numActiveClientWorkflows
                  numWorkflowRules
                  isNewTemplate
                  canEdit
                  clientTileOptions {
                    showProgress
                    showIcons
                    showStreams
                    numberOfTasks
                    presentWorkflowName
                  }
                  process {
                    uuid
                    color
                    name
                  }
                }
              }
            }
          }
        }
      }
      workflowsWithNoProcess {
        edges {
          node {
            uuid
            name
            numTasks
            numActiveClients
            numActiveClientWorkflows
            numWorkflowRules
            isNewTemplate
            canEdit
            clientTileOptions {
              showProgress
              showIcons
              showStreams
              numberOfTasks
              presentWorkflowName
            }
          }
        }
      }
    }`,
    },
    { hub: hubId }
  );
  const {
    data: { processes, workflowsWithNoProcess },
  } = await API.post("HublyAPI", `/graphql`, req);

  return [
    processes.edges.map(({ node }) => {
      return {
        ...node,
        id: node.uuid,
        workflows: node.workflows.edges.map(({ node: wNode }) => {
          return {
            ...wNode,
            id: wNode.uuid,
            process: wNode.process
              ? { ...wNode.process, id: wNode.process.uuid }
              : null,
          };
        }),
      };
    }),
    workflowsWithNoProcess.edges.map(({ node }) => {
      return {
        ...node,
        id: node.uuid,
      };
    }),
  ];
};

export default getProcessesAndWorkflowsWithNoProcess;
