export function toGlobalId(model, id) {
  return window.btoa(`${model}:${id}`);
}

export function mapUUID(node) {
  if (!node) return null;
  return { ...node, id: node.uuid };
}

export default toGlobalId;
