import React from "react";
import PropTypes from "prop-types";

import { Icon, Label } from "semantic-ui-react";

const ExcludeItemsLabels = ({
  tagsExclude,
  streamsExclude,
  clientsExclude,
}) => {
  if (
    tagsExclude?.length + streamsExclude?.length + clientsExclude?.length ===
    0
  ) {
    return null;
  }

  return (
    <span>
      (excluding:{" "}
      {clientsExclude.map((client) => {
        return (
          <Label
            key={client.key || client.id}
            style={{ margin: "0.1em", padding: "0.3em 0.4em" }}
          >
            {client.text || client.name}
          </Label>
        );
      })}
      {streamsExclude.map((stream) => {
        return (
          <Label
            key={stream.id}
            style={{
              backgroundColor: stream.color,
              color: "#fff",
              margin: "0.1em",
              padding: "0.3em 0.4em",
            }}
          >
            {stream.name}
          </Label>
        );
      })}
      {tagsExclude.map((tag) => {
        return (
          <Label
            key={tag.id}
            style={{
              backgroundColor: "grey",
              color: "#fff",
              margin: "0.1em",
              padding: "0.3em 0.4em",
            }}
          >
            <Icon fitted name="hashtag" />
            {tag.text || tag.name}
          </Label>
        );
      })}
      )
    </span>
  );
};

ExcludeItemsLabels.propTypes = {
  tagsExclude: PropTypes.array,
  streamsExclude: PropTypes.array,
  clientsExclude: PropTypes.array,
};

ExcludeItemsLabels.defaultProps = {
  tagsExclude: [],
  streamsExclude: [],
  clientsExclude: [],
};

export default ExcludeItemsLabels;
