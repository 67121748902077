import React from "react";
import PropTypes from "prop-types";

export function Item({ children, empty }) {
  const s = empty
    ? {}
    : {
        marginRight: "6em",
        flexGrow: 1,
        justifyContent: "center",
        alignItems: "center",
        width: "225px",
      };

  return <div style={s}>{children}</div>;
}

Item.propTypes = {
  children: PropTypes.node,
  empty: PropTypes.bool,
};

Item.defaultProps = {
  children: null,
  empty: false,
};

export default Item;
