export const initialState = {
  viewModalOpen: false,
  editView: { id: "", name: "", description: "" },
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_VIEW_MODAL_OPEN":
      return {
        ...state,
        viewModalOpen: action.viewModalOpen,
        editView: action.editView,
      };
    default:
      return state;
  }
};
export default reducer;
