import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Auth } from "aws-amplify";
import { Button, Modal, Step } from "semantic-ui-react";

import "./style.css";

import { AcceptPolicy } from "data/libs/policy";

import AgreementArea from "./components/AgreementArea";
import { SetPolicyModal } from "./actions";

class PrivacyPolicyModal extends Component {
  static propTypes = {
    setPolicyModal: PropTypes.func.isRequired,
    allPolicies: PropTypes.array.isRequired,
    advisor: PropTypes.object.isRequired,
  };

  state = {
    policies: [],
    numAgreed: 0,
  };

  componentDidMount = () => {
    const { allPolicies, advisor } = this.props;
    const policies = [];
    allPolicies.forEach((policy) => {
      if (
        advisor.policies.find((p) => {
          return p.id === policy.id;
        })
      )
        return;

      const policyMarkup = { __html: policy.text };

      const blob = new Blob([policy.text], { type: "text/html" });
      const blobLink = URL.createObjectURL(blob);

      policies.push({
        blob: blobLink,
        name: policy.name,
        type: policy.type,
        version: policy.version,
        markup: policyMarkup,
        id: policy.id,
        text: policy.text,
      });
    });
    this.setState({ policies: policies });
  };

  agree = (policy, callback) => {
    const { setPolicyModal } = this.props;
    const { policies, numAgreed } = this.state;
    AcceptPolicy(policy.id)
      .then((response) => {
        if (callback) callback();
        this.setState({ numAgreed: numAgreed + 1 });
        if (numAgreed + 1 === policies.length) {
          // all of them have been agreed to
          setPolicyModal(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  render() {
    const { policies, numAgreed } = this.state;
    return (
      <Modal open dimmer="inverted" id="privacy_policy_modal">
        {numAgreed < policies.length && (
          <React.Fragment>
            <Modal.Header style={{ padding: 0, borderBottom: "none" }}>
              <div style={{ width: "100%", padding: "1em" }}>
                <span>We&apos;ve Updated Our Terms & Conditions</span>
                <Button
                  content="Sign out"
                  basic
                  floated="right"
                  onClick={() => {
                    Auth.signOut();
                  }}
                  style={{ transform: "translate(0px, -5px)" }}
                />
              </div>
              <div>
                <Step.Group
                  fluid
                  style={{
                    borderRadius: "0px",
                    borderRight: "none",
                    borderLeft: "none",
                  }}
                >
                  {policies.map((p, index) => {
                    return (
                      <Step active={index === numAgreed}>
                        <Step.Content>
                          <Step.Title style={{ padding: "0" }}>
                            {p.name}
                          </Step.Title>
                        </Step.Content>
                      </Step>
                    );
                  })}
                </Step.Group>
              </div>
            </Modal.Header>
            {policies.map((p, index) => {
              return (
                <AgreementArea
                  policy={p}
                  agree={this.agree}
                  index={index}
                  numAgreed={numAgreed}
                />
              );
            })}
          </React.Fragment>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    advisor: state.hubly.data.advisor,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPolicyModal: (open) => {
      dispatch(SetPolicyModal(open));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicyModal);
