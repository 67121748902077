import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Button, Dropdown, Icon, Modal } from "semantic-ui-react";

import { SetEditWorkflowRule } from "../actions";
import {
  filterOptionsFromSelectedItems,
  getClientsFromSelectedItems,
  getStreamsFromSelectedItems,
  getTagsFromSelectedItems,
  parseBackendStreams,
  parseBackendTags,
  parseEditWorkflowClients,
  parseEditWorkflowTags,
} from "../helpers";
import ExcludeItems from "./ExcludeItems";
import ImportantDateMeetingTypeDropdown from "./ImportantDateMeetingTypeDropdown";
import IncludeInfoPopup from "./IncludeInfoPopup";
import RuleClientsDropdown from "./RuleClientsDropdown";

class ImportantClientDatesRuleModal extends React.Component {
  static propTypes = {
    hub: PropTypes.shape({
      id: PropTypes.string.isRequired,
      streams: PropTypes.array.isRequired,
      dateTypes: PropTypes.array.isRequired,
    }).isRequired,
    editWorkflowRule: PropTypes.shape({
      id: PropTypes.string,
      dateStart: PropTypes.string,
      importantDate: PropTypes.string,
      interval: PropTypes.number,
      frequency: PropTypes.string,
      dateTypeId: PropTypes.string,
      streams: PropTypes.array,
      tags: PropTypes.array,
      streamsExclude: PropTypes.array,
      tagsExclude: PropTypes.array,
      clientsExclude: PropTypes.array,
      meetingDateTypeId: PropTypes.string,
    }).isRequired,
    save: PropTypes.func.isRequired,
    setEditWorkflowRule: PropTypes.func.isRequired,
    setWorkflowRuleModal: PropTypes.func.isRequired,
    tags: PropTypes.object.isRequired,
    update: PropTypes.func.isRequired,
    allClients: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);

    const { editWorkflowRule, allClients } = this.props;
    const {
      streams = [],
      tags = [],
      streamsExclude = [],
      tagsExclude = [],
      clientsExclude = [],
    } = editWorkflowRule;

    this.tomorrow = new Date();
    this.tomorrow.setDate(this.tomorrow.getDate() + 1);

    const defaultStreams = parseBackendStreams(streams);
    const defaultTags = parseEditWorkflowTags(tags, props.tags);

    const defaultExcludeStreams = parseBackendStreams(streamsExclude);
    const defaultExcludeTags = parseEditWorkflowTags(tagsExclude, props.tags);
    const defaultExcludeClients = parseEditWorkflowClients(
      clientsExclude,
      allClients
    );

    this.dateOptions = props.hub.dateTypes.map((dt) => {
      return {
        value: dt.id,
        key: dt.id,
        text: dt.name,
        icon: `grey ${dt.icon}`,
      };
    });

    this.state = {
      beforeOrAfter: null,
      dateStart: null,
      editedFrequency: false,
      editedImportantDate: false,
      editedInterval: false,
      frequency: "WEEKLY",
      importantDate: editWorkflowRule.dateTypeId || "meeting",
      selectedMeetingDateType: undefined,
      interval: 1,
      loading: false,
      selectedItems: [...defaultStreams, ...defaultTags],
      selectedExcludeItems: [
        ...defaultExcludeClients,
        ...defaultExcludeStreams,
        ...defaultExcludeTags,
      ],
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (!state.beforeOrAfter) {
      const formattedInterval = props.editWorkflowRule
        ? props?.editWorkflowRule?.interval
        : state.interval;
      if (formattedInterval > 0) {
        return { beforeOrAfter: "after" };
      } else if (formattedInterval === 0) {
        return { beforeOrAfter: "on" };
      } else {
        return { beforeOrAfter: "before" };
      }
    }

    return null;
  }

  getStreamOptions = () => {
    const { hub } = this.props;
    return parseBackendStreams(
      hub?.streams?.sort((a, b) => {
        return a.name > b.name ? 1 : -1;
      })
    );
  };

  getTagOptions = () => {
    const { tags } = this.props;
    return parseBackendTags(
      Object.values(tags)?.sort((a, b) => {
        return a.name > b.name ? 1 : -1;
      })
    );
  };

  handleChange = (e, { name, value }) => {
    this.setState((state) => {
      const changingFromOnToBeforeAfter =
        name === "beforeOrAfter" && state.beforeOrAfter === "on";

      return {
        [name]: value,
        editedFrequency:
          state.editedFrequency ||
          name === "frequency" ||
          changingFromOnToBeforeAfter,
        editedImportantDate:
          state.editedImportantDate || name === "importantDate",
        editedInterval:
          state.editedInterval ||
          name === "interval" ||
          changingFromOnToBeforeAfter,
        editedStreams: state.editedStream || name === "streams",
      };
    });
  };

  isValidInput = () => {
    const { selectedItems } = this.state;

    // Differing valid input if editing or adding
    return selectedItems && selectedItems.length > 0;
  };

  formatIntervalFromBeforeAfter = (interval) => {
    const { beforeOrAfter } = this.state;
    switch (beforeOrAfter) {
      case "before":
        return -1 * interval;
      case "after":
        return interval;
      case "on":
        return 0;
      default:
        return -1 * interval;
    }
  };

  addRule = () => {
    const { save, setEditWorkflowRule, setWorkflowRuleModal, hub } = this.props;
    const {
      dateStart,
      frequency,
      importantDate,
      interval,
      selectedItems,
      selectedMeetingDateType,
      selectedExcludeItems,
    } = this.state;
    this.setState({ loading: true });

    const foundDateType = hub.dateTypes.find((dt) => {
      return dt.id === importantDate;
    });

    // Set the time to be before the lambda cron, so it will run that morning
    const dateBeforeLambda = dateStart || this.tomorrow;
    dateBeforeLambda.setHours(7, 15, 0, 0);
    const request = {
      dateStart: dateBeforeLambda.toISOString(),
      frequency: frequency || "WEEKLY",
      importantDate: foundDateType?.name || "meeting",
      interval: this.formatIntervalFromBeforeAfter(interval),
      streams: getStreamsFromSelectedItems(selectedItems),
      tags: getTagsFromSelectedItems(selectedItems),
      streamsExclude: getStreamsFromSelectedItems(selectedExcludeItems),
      tagsExclude: getTagsFromSelectedItems(selectedExcludeItems),
      clientsExclude: getClientsFromSelectedItems(selectedExcludeItems),
      type: "IMPORTANT_DATE_RULE",
      dateTypeId:
        importantDate && importantDate !== "meeting"
          ? importantDate
          : undefined,
      meetingDateTypeId:
        importantDate === "meeting" ? selectedMeetingDateType?.id : undefined,
    };

    save(request, (success) => {
      if (success) {
        setWorkflowRuleModal(false);
        setEditWorkflowRule({});
      }
      this.setState({ loading: false });
    });
  };

  updateRule = () => {
    const {
      editWorkflowRule,
      setEditWorkflowRule,
      setWorkflowRuleModal,
      update,
      hub,
    } = this.props;
    const {
      dateStart,
      editedFrequency,
      editedImportantDate,
      editedInterval,
      selectedExcludeItems,
      frequency,
      importantDate,
      interval,
      selectedItems,
      selectedMeetingDateType,
    } = this.state;
    this.setState({ loading: true });

    const foundDateType = hub.dateTypes.find((dt) => {
      return dt.id === importantDate;
    });

    // Set the time to be before the lambda cron, so it will run that morning
    const dateBeforeLambda = dateStart || new Date(editWorkflowRule.dateStart);
    dateBeforeLambda.setHours(7, 15, 0, 0);

    const formattedInterval = this.formatIntervalFromBeforeAfter(
      editedInterval ? interval : Math.abs(editWorkflowRule.interval)
    );
    const workflowRule = {
      id: editWorkflowRule.id,
      dateStart: dateBeforeLambda.toISOString(),
      frequency: editedFrequency ? frequency : editWorkflowRule.frequency,
      importantDate: editedImportantDate
        ? foundDateType?.name || "meeting"
        : editWorkflowRule.importantDate,
      interval: formattedInterval,
      streams: getStreamsFromSelectedItems(selectedItems),
      tags: getTagsFromSelectedItems(selectedItems),
      streamsExclude: getStreamsFromSelectedItems(selectedExcludeItems),
      tagsExclude: getTagsFromSelectedItems(selectedExcludeItems),
      clientsExclude: getClientsFromSelectedItems(selectedExcludeItems),
      type: "IMPORTANT_DATE_RULE",
      dateTypeId:
        importantDate && importantDate !== "meeting" ? importantDate : null,
      meetingDateTypeId:
        importantDate === "meeting" ? selectedMeetingDateType?.id : undefined,
    };

    update(workflowRule, (success) => {
      if (success) {
        setWorkflowRuleModal(false);
        setEditWorkflowRule({});
      }
      this.setState({ loading: false });
    });
  };

  render() {
    const { editWorkflowRule, setEditWorkflowRule, setWorkflowRuleModal } =
      this.props;
    const {
      editedInterval,
      interval,
      loading,
      selectedItems,
      importantDate,
      selectedMeetingDateType,
      beforeOrAfter,
      selectedExcludeItems,
    } = this.state;

    const intervalOptions = [];
    for (let i = 1; i <= 30; i += 1) {
      intervalOptions.push({ key: i, text: i, value: i });
    }

    const streamOptions = this.getStreamOptions();
    const tagOptions = this.getTagOptions();

    const formattedInterval = editWorkflowRule
      ? Math.abs(editWorkflowRule.interval)
      : Math.abs(interval);

    const allDateOptions = [...this.dateOptions];
    allDateOptions.unshift({
      value: "meeting",
      key: "meeting",
      icon: "grey calendar alternate",
      text: `${beforeOrAfter === "after" ? "Last" : "Next"} Meeting Date`,
    });

    return (
      <Modal open size="tiny" data-test="importantClientDatesRule-modal">
        <Modal.Header>
          <Icon
            name="delete"
            link
            color="grey"
            style={{ float: "right", position: "relative" }}
            onClick={() => {
              setWorkflowRuleModal(false);
              setEditWorkflowRule({});
            }}
          />
          Important Client Date Rule
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ marginRight: "0.5em", width: "150px" }}>
                  Add clients with
                </div>
                <RuleClientsDropdown
                  tagOptions={filterOptionsFromSelectedItems(
                    tagOptions,
                    selectedExcludeItems,
                    "tag"
                  )}
                  streamOptions={filterOptionsFromSelectedItems(
                    streamOptions,
                    selectedExcludeItems,
                    "stream"
                  )}
                  selectedItems={selectedItems}
                  setSelectedItems={(newSelectedItems) => {
                    this.setState({ selectedItems: newSelectedItems });
                  }}
                  data-test="ruleModal-dropdown-condition"
                  fluid
                />
                <div style={{ marginLeft: "0.5em" }}>
                  <IncludeInfoPopup />
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {beforeOrAfter !== "on" && (
                    <React.Fragment>
                      <div style={{ marginTop: "1em" }}>
                        <Dropdown
                          placeholder="Interval"
                          selection
                          options={intervalOptions}
                          onChange={this.handleChange}
                          name="interval"
                          compact
                          style={{ marginRight: "1em" }}
                          defaultValue={formattedInterval || 1}
                          data-test="ruleModal-dropdown-interval"
                        />
                      </div>
                      <div style={{ marginTop: "1em" }}>
                        <Dropdown
                          placeholder="Week or Month"
                          selection
                          options={[
                            {
                              value: "DAILY",
                              key: "DAILY",
                              text:
                                interval >= 2 ||
                                (!editedInterval && formattedInterval >= 2)
                                  ? "Days"
                                  : "Day",
                            },
                            {
                              value: "MONTHLY",
                              key: "MONTHLY",
                              text:
                                interval >= 2 ||
                                (!editedInterval && formattedInterval >= 2)
                                  ? "Months"
                                  : "Month",
                            },
                            {
                              value: "WEEKLY",
                              key: "WEEKLY",
                              text:
                                interval >= 2 ||
                                (!editedInterval && formattedInterval >= 2)
                                  ? "Weeks"
                                  : "Week",
                            },
                            {
                              value: "YEARLY",
                              key: "YEARLY",
                              text:
                                interval >= 2 ||
                                (!editedInterval && formattedInterval >= 2)
                                  ? "Years"
                                  : "Year",
                            },
                          ]}
                          onChange={this.handleChange}
                          name="frequency"
                          compact
                          style={{ marginRight: "1em" }}
                          defaultValue={editWorkflowRule.frequency || "WEEKLY"}
                          data-test="ruleModal-dropdown-frequency"
                        />
                      </div>
                    </React.Fragment>
                  )}
                  <div style={{ marginTop: "1em" }}>
                    <Dropdown
                      placeholder="Before or After"
                      selection
                      options={[
                        { value: "on", key: "on", text: "On" },
                        { value: "before", key: "before", text: "Before" },
                        { value: "after", key: "after", text: "After" },
                      ]}
                      onChange={this.handleChange}
                      name="beforeOrAfter"
                      data-test="ruleModal-dropdown-beforeOrAfter"
                      compact
                      style={{ marginRight: "1em" }}
                      defaultValue={beforeOrAfter}
                    />
                    <span style={{ marginRight: "1em" }}>their</span>
                  </div>
                </div>
                <div style={{ marginTop: "1em", width: "300px" }}>
                  <Dropdown
                    fluid
                    value={importantDate}
                    name="importantDate"
                    onChange={this.handleChange}
                    options={allDateOptions}
                    placeholder="Important Date"
                    selection
                    style={{}}
                    data-test="ruleModal-dropdown-importantDate"
                  />
                  {importantDate === "meeting" && (
                    <ImportantDateMeetingTypeDropdown
                      editWorkflowRule={editWorkflowRule}
                      selectedMeetingDateType={selectedMeetingDateType}
                      setSelectedMeetingDateType={(value) => {
                        this.setState({ selectedMeetingDateType: value });
                      }}
                    />
                  )}
                </div>
                <ExcludeItems
                  tagOptions={filterOptionsFromSelectedItems(
                    tagOptions,
                    selectedItems,
                    "tag"
                  )}
                  streamOptions={filterOptionsFromSelectedItems(
                    streamOptions,
                    selectedItems,
                    "stream"
                  )}
                  selectedItems={selectedExcludeItems}
                  setSelectedItems={(newSelectedItems) => {
                    this.setState({ selectedExcludeItems: newSelectedItems });
                  }}
                  data-test="ruleModal-dropdown-exclude"
                />
              </div>
            </div>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            disabled={!this.isValidInput() || loading}
            loading={loading}
            content={editWorkflowRule.id ? "Edit Rule" : "Add Rule"}
            onClick={editWorkflowRule.id ? this.updateRule : this.addRule}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    hub: state.hubly.data.hub.selected.hub,
    editWorkflowRule: state.hubly.scenes.hubly.workflowRules.editWorkflowRule,
    tags: state.hubly.data.hub.tags,
    workflows: state.hubly.data.hub.workflows,
    allClients: state.hubly.data.hub.clients.allClientNames,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setEditWorkflowRule: (workflowRule) => {
      dispatch(SetEditWorkflowRule(workflowRule));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImportantClientDatesRuleModal);
