import React from "react";
import PropTypes from "prop-types";

import { Card, Segment } from "semantic-ui-react";

export function Grid({ itemsPerRow, children }) {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Segment basic>
        <Card.Group itemsPerRow={itemsPerRow}>{children}</Card.Group>
      </Segment>
    </div>
  );
}

Grid.propTypes = {
  children: PropTypes.node,
  itemsPerRow: PropTypes.number,
};

Grid.defaultProps = {
  children: <React.Fragment />,
  itemsPerRow: 3,
};

export default Grid;
