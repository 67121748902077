import { useCallback, useEffect } from "react";

import { useQuery } from "hooks";

import toGlobalId from "scenes/Hubly/components/Workspace/api/helper";

export function useTotalMembers(id, type) {
  if (!["stream", "tag"].includes(type)) {
    throw new Error(
      `Invalid type. Expected 'stream' or 'tag', received: ${type}`
    );
  }

  const tagFactory = useCallback(() => {
    const query = `query countClientsInTag {
        clientsInTagCount(tagId: "${toGlobalId("Tag", id)}") {
          totalMembers
        }
      }
    `;

    const resolver = ({ data }) => {
      const { totalMembers } = data.clientsInTagCount;

      return totalMembers;
    };

    return { query, resolver };
  }, [id]);

  const streamFactory = useCallback(() => {
    const query = `query countClientsInStream {
      clientsInStreamCount(streamId: "${toGlobalId("Stream", id)}") {
        totalMembers
      }
    }
  `;

    const resolver = ({ data }) => {
      const { totalMembers } = data.clientsInStreamCount;

      return totalMembers;
    };

    return { query, resolver };
  }, [id]);

  const queryFactory = type === "stream" ? streamFactory : tagFactory;

  const { data, reloadData } = useQuery(queryFactory, {
    options: { fetchOnInit: false, cached: true },
  });

  useEffect(() => {
    if (id) reloadData();
  }, [id, reloadData]);

  return data;
}

export default useTotalMembers;
