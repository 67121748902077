import React from "react";

import { Placeholder, Segment } from "semantic-ui-react";

export function DraggableTilePlaceholder() {
  return (
    <Segment.Group
      data-test={`process-${process?.name}`}
      style={{
        flexGrow: "1",
        boxSizing: "border-container",
        margin: "0px",
        marginBottom: "1em",
        minWidth: "100%",
      }}
    >
      <Segment style={{ background: "none" }}>
        <Placeholder>
          <Placeholder.Header>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Header>
        </Placeholder>
      </Segment>
      <Segment style={{ background: "none" }}>
        <div style={{ display: "flex" }}>
          <div style={{ flexGrow: 1 }}>
            <Placeholder>
              <Placeholder.Header>
                <Placeholder.Line />
              </Placeholder.Header>
            </Placeholder>
          </div>
          <div style={{ flexGrow: 1 }}>
            <Placeholder>
              <Placeholder.Header>
                <Placeholder.Line />
              </Placeholder.Header>
            </Placeholder>
          </div>
          <div style={{ flexGrow: 1 }}>
            <Placeholder>
              <Placeholder.Header>
                <Placeholder.Line />
              </Placeholder.Header>
            </Placeholder>
          </div>
        </div>
      </Segment>
    </Segment.Group>
  );
}

export default DraggableTilePlaceholder;
