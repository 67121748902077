import { useCallback, useMemo } from "react";

import { useQuery } from "hooks";

function useQueryNotInvitedUsers() {
  const queryFactory = useCallback(() => {
    const query = `query notInvited {
      advisors(isInvited: false) {
        edges {
          node {
            id
            firstName
            lastName
            email
            role {
              id
            }
          }
        }
      }
    }`;

    const resolver = ({
      data: {
        advisors: { edges },
      },
    }) => {
      return edges.map(({ node }) => {
        return { ...node };
      });
    };

    return { query, resolver };
  }, []);

  const {
    loading,
    data,
    errors,
    reloadData: reloadNotInvitedUsers,
  } = useQuery(queryFactory, {
    options: { fetchOnInit: false },
  });

  const userList = useMemo(() => {
    return data.map(({ id, role: { id: roleId }, ...rest }) => {
      return {
        roleId: roleId,
        inviteNow: false,
        advisorId: id,
        ...rest,
      };
    });
  }, [data]);

  return {
    userList,
    loading,
    errors,
    reloadNotInvitedUsers,
  };
}

export default useQueryNotInvitedUsers;
