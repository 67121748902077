import React, { useContext, useState } from "react";
import PropTypes from "prop-types";

import { useDropdownData } from "hooks";
import { ClientDropdown } from "components/ClientDropdown";
import { useRecentClients } from "components/ClientDropdown/hooks";

import { HubContext } from "scenes/Hubly/components/Workspace/Provider";

export function ContactSearch({ style, onSyncProgressUpdated }) {
  const {
    integration,
    viewClientCard,
    viewClientInCrm,
    searchClients,
    fetchClientCardOpened,
  } = useContext(HubContext);

  const [searchText, setSearchText] = useState("");

  const recentClientData = useRecentClients();
  const clientsData = useDropdownData({
    searchText: searchText,
    type: "client",
    fetchFunction: searchClients,
  });

  return (
    <React.Fragment>
      <div style={{ display: "flex", width: "100%", ...style }}>
        <ClientDropdown
          opened={false}
          integrationButtonText={integration ? "Save and Close" : ""}
          recentClientData={recentClientData}
          clientsData={clientsData}
          onSearchChange={setSearchText}
          icon="search"
          searchPlaceholder="Type to search contacts"
          dropdownProps={{
            id: "main_client_search_bar",
            className: "dropdown_icon client_dropdown",
            style: {
              minWidth: "150px",
              maxWidth: "300px",
              width: "30vw",
              marginRight: "0.5em",
              zIndex: "110",
              maxHeight: "80vh",
              background: "none",
            },
          }}
          placeholder="Contacts"
          onSyncProgressUpdated={onSyncProgressUpdated}
          onClientClick={(client, editInCrm) => {
            const openInCrm = ({ externalId }) => {
              viewClientInCrm(externalId);
            };

            const openInClientCard = ({ id }) => {
              fetchClientCardOpened(id)
                .then(({ clientWorkflowId = null }) => {
                  viewClientCard(id, clientWorkflowId);
                })
                .catch(() => {
                  viewClientCard(id);
                });
            };

            const open = editInCrm ? openInCrm : openInClientCard;
            open(client);
          }}
          closeOnClick
        />
      </div>
    </React.Fragment>
  );
}

ContactSearch.defaultProps = {
  style: {},
  onSyncProgressUpdated: () => {},
};

ContactSearch.propTypes = {
  style: PropTypes.object,
  onSyncProgressUpdated: PropTypes.func,
};

export default ContactSearch;
