import { API } from "aws-amplify";

import SyncedAPI from "data/libs/syncedAPI";

import { getAPIReq } from "./authLib";

export const AddComment = async (request) => {
  const req = await getAPIReq(request);
  return SyncedAPI.post("HublyAPI", `/comments/`, req);
};

export const GetComment = async (id) => {
  const req = await getAPIReq();
  return API.get("HublyAPI", `/comments/${id}/`, req);
};

export const EditComment = async (id, request) => {
  const req = await getAPIReq(request);
  return SyncedAPI.put("HublyAPI", `/comments/${id}/`, req);
};

export const DeleteComment = async (id) => {
  const req = await getAPIReq();
  return SyncedAPI.del("HublyAPI", `/comments/${id}/`, req);
};

export const HideComment = async (id, request) => {
  const req = await getAPIReq(request);
  return SyncedAPI.put("HublyAPI", `/comments/${id}/hide/`, req);
};
