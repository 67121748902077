import React, { useContext, useState } from "react";
import PropTypes from "prop-types";

import { useForm } from "hooks";
import AddRemoveButton from "components/AddRemoveButton";
import Popper from "components/Popper";

import { useAccessTypeStore } from "scenes/Hubly/components/Workspace/components/hooks";
import {
  HubContext,
  ToasterContext,
} from "scenes/Hubly/components/Workspace/Provider";

import {
  getDuration,
  getEmptyTaskParams,
  getTaskTimeCaption,
  getTotalTimeEstimate,
} from "../../helper";
import { TaskIcon } from "..";
import TaskTimeAdd from "../TaskTimeAdd";
import { TaskTimeEstimateLayout } from "./components";

export function TaskTimeEstimate({
  task,
  clientWorkflowId,
  onTaskConfigChange,
}) {
  const { addTimeEstimate, editTimeEstimate, deleteAllTimeEstimate } =
    useContext(HubContext);
  const { fireError } = useContext(ToasterContext);
  const { hasAccess } = useAccessTypeStore();
  const [loading, setLoading] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);

  const hasTimeEstimates = () => {
    if (!task || !task.timeEstimates || task.timeEstimates.length === 0)
      return false;
    return true;
  };

  const { register, data, disableButton } = useForm({
    initialValue: hasTimeEstimates()
      ? { duration: getTotalTimeEstimate(task.timeEstimates, true) }
      : {},
    formValidator: ({ duration }) => {
      return parseInt(duration) > 0;
    },
  });

  const saveTaskEstimate = async () => {
    const hourMinuteParam = getDuration(data.duration);

    if (!task?.id) {
      onTaskConfigChange({
        timeEstimates: [getEmptyTaskParams(data.duration)],
      });
      setPopupOpen(false);
    } else {
      try {
        setLoading(true);
        const resp = !hasTimeEstimates()
          ? await addTimeEstimate(task.id, hourMinuteParam)
          : await editTimeEstimate(
              task.id,
              task.timeEstimates[0].id,
              hourMinuteParam
            );
        setPopupOpen(false);
        setLoading(false);
        onTaskConfigChange({ ...task, timeEstimates: [resp] });
      } catch (error) {
        fireError("Failed to save time estimate", error);
        setLoading(false);
        setPopupOpen(false);
      }
    }
  };
  const removeTaskEstimate = () => {
    if (!task?.id) {
      onTaskConfigChange({ timeEstimates: [] });
      setPopupOpen(false);
      return;
    }
    const timeEstimateIds = task.timeEstimates.map(({ id }) => {
      return id;
    });
    setLoading(true);
    deleteAllTimeEstimate(task.id, timeEstimateIds)
      .then(() => {
        setPopupOpen(false);
        setLoading(false);
        onTaskConfigChange({ ...task, timeEstimates: [] });
      })
      .catch((e) => {
        fireError("Failed to delete time estimate", e);
        setLoading(false);
        setPopupOpen(false);
      });
  };
  return (
    <Popper
      on="click"
      position="bottom center"
      open={popupOpen}
      onOpen={() => {
        setPopupOpen(true);
      }}
      onClose={() => {
        setPopupOpen(false);
      }}
      trigger={
        <TaskIcon
          expanded={popupOpen}
          hasValue={hasTimeEstimates()}
          iconName="clock"
          title={getTaskTimeCaption()}
          onClick={() => {}}
          visible={hasAccess() || hasTimeEstimates()}
        />
      }
    >
      <TaskTimeEstimateLayout hasTimeEstimates={hasTimeEstimates()}>
        <TaskTimeAdd
          disabled={!hasAccess()}
          {...register({ name: "duration" })}
          addTimeEstimate={addTimeEstimate}
        />
      </TaskTimeEstimateLayout>
      <AddRemoveButton
        loading={loading}
        disabled={!hasAccess() || disableButton || loading}
        isCreating={!hasTimeEstimates()}
        onRemove={removeTaskEstimate}
        onAdd={saveTaskEstimate}
      />
    </Popper>
  );
}

TaskTimeEstimate.propTypes = {
  task: PropTypes.object,
  clientWorkflowId: PropTypes.string,
  onTaskConfigChange: PropTypes.func.isRequired,
};

TaskTimeEstimate.defaultProps = {
  task: null,
  clientWorkflowId: null,
};

export default TaskTimeEstimate;
