import React from "react";
import PropTypes from "prop-types";

export function MenuLayoutRight({ children }) {
  return (
    <div
      style={{
        display: "flex",
        padding: "0.25em 0.5em",
        marginLeft: "auto",
      }}
    >
      {children}
    </div>
  );
}

MenuLayoutRight.propTypes = {
  children: PropTypes.node,
};

MenuLayoutRight.defaultProps = {
  children: null,
};

export default MenuLayoutRight;
