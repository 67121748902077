import React, { Component } from "react";
import { FullStoryAPI } from "react-fullstory";
import Intercom from "react-intercom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Canny from "components/Canny";
import FeatureFlagProvider, { FeatureFlag } from "components/FeatureFlag";
import FeedbackForm from "components/FeedbackForm";
import HubSpotChatWidget from "components/HubSpotChatWidget";
import PrivacyMode from "components/PrivacyMode";
import UserFlow, { makeUserflowUser } from "components/UserFlow";

import config from "../../config";
import TopMenu from "./components/TopMenu";

class Layout extends Component {
  static propTypes = {
    advisor: PropTypes.shape({
      clients: PropTypes.array,
      email: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      avatarURL: PropTypes.string,
      createdAt: PropTypes.string,
      workflows: PropTypes.array,
      hubs: PropTypes.arrayOf(
        PropTypes.shape({
          hubId: PropTypes.string,
        })
      ),
    }).isRequired,
    children: PropTypes.object.isRequired,
  };

  componentDidMount() {
    const { advisor } = this.props;
    if (config.fullStory) {
      FullStoryAPI("identify", advisor.id, {
        displayName: `${advisor.firstName} ${advisor.lastName}`,
        email: advisor.email,
      });
    }
  }

  getUser = ({ isTestUser }) => {
    if (isTestUser) {
      return {
        id: `test${process.env.REACT_APP_STAGE}`,
        email: `${process.env.REACT_APP_STAGE}-test@myhubly.com`,
        name: `Test on ${process.env.REACT_APP_STAGE}`,
        avatarURL: "",
        created: undefined,
      };
    } else {
      const { advisor } = this.props;
      return {
        id: advisor.id,
        email: advisor.email,
        name: `${advisor.firstName} ${advisor.lastName}`,
        avatarURL: advisor?.avatarURL,
        created: advisor?.createdAt,
      };
    }
  };

  render() {
    const { advisor, children } = this.props;
    const userData = {
      user_id: advisor.id,
      email: advisor.email,
      name: `${advisor.firstName} ${advisor.lastName}`,
      hubId: advisor.hubs?.[0]?.hubId,
    };

    return (
      <React.Fragment>
        <FeatureFlagProvider advisor={advisor}>
          <PrivacyMode>
            <TopMenu />
            {children}
            <UserFlow
              appToken={config.userflow?.token}
              user={makeUserflowUser(this.getUser(config.userflow))}
            />
            <FeatureFlag id="hubspot-chat-widget" showOnDisabled>
              <Intercom appID={config?.intercom?.appId} {...userData} />
            </FeatureFlag>
            <FeatureFlag id="hubspot-chat-widget">
              {!config?.hubspot?.isTestUser && (
                <HubSpotChatWidget email={userData.email} />
              )}
            </FeatureFlag>
            <Canny config={config.canny} user={this.getUser(config.canny)} />
            <FeedbackForm rightMargin="80px" />
          </PrivacyMode>
        </FeatureFlagProvider>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    advisor: state.hubly.data.advisor,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
