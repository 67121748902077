/* eslint-disable react/prop-types */
import React from "react";
import { Draggable } from "react-beautiful-dnd";
import ReactDOM from "react-dom";

export function WithFloatingDraggable(WrappedComponent) {
  return ({ id, index, ...props }) => {
    const getPortal = (portalId) => {
      const createPortal = () => {
        const divContainer = document.createElement("div");
        divContainer.classList.add(`${portalId}-DND-portal`);
        document.body.appendChild(divContainer);
        return divContainer;
      };

      const portals = document.getElementsByClassName(`${portalId}-DND-portal`);
      return portals.length === 1 ? portals[0] : createPortal();
    };

    return (
      <Draggable draggableId={id} index={index}>
        {(p, { isDragging }) => {
          const wrappedComp = (
            <WrappedComponent {...props} draggableContext={p} />
          );
          return isDragging
            ? ReactDOM.createPortal(wrappedComp, getPortal(id))
            : wrappedComp;
        }}
      </Draggable>
    );
  };
}

export function WithDraggable(WrappedComponent) {
  return ({ disabled = false, id, index, ...props }) => {
    return (
      <Draggable isDragDisabled={disabled} draggableId={id} index={index}>
        {(p) => {
          return <WrappedComponent {...props} draggableContext={p} />;
        }}
      </Draggable>
    );
  };
}

const DraggableContext = React.createContext({
  draggableProps: { style: {} },
  dragHandleProps: { style: {} },
});

export function useDraggable() {
  return React.useContext(DraggableContext);
}

export function WithContext(WrappedComponent) {
  return ({ draggableContext, ...props }) => {
    return (
      <DraggableContext.Provider value={draggableContext}>
        <div ref={draggableContext.innerRef}>
          <WrappedComponent {...props} />
        </div>
      </DraggableContext.Provider>
    );
  };
}

export function WithContainer(WrappedComponent) {
  return ({ draggableContext, ...props }) => {
    const { innerRef, draggableProps, dragHandleProps } = draggableContext;

    return (
      <div ref={innerRef} {...draggableProps} {...dragHandleProps}>
        <WrappedComponent {...props} />
      </div>
    );
  };
}

export const FloatingDraggableFragment = WithFloatingDraggable(
  WithContext(React.Fragment)
);
export const DraggableFragment = WithDraggable(WithContext(React.Fragment));
export const DraggableContainer = WithDraggable(WithContainer(React.Fragment));

export default DraggableContainer;
