import React from "react";
import PropTypes from "prop-types";

import { Icon, List, Loader } from "semantic-ui-react";

export function SimpleListContainer({ children }) {
  return (
    <List style={{ marginTop: "0em", marginBottom: "0em" }}>{children}</List>
  );
}

export function SimpleListItem({ children }) {
  return (
    <List.Item className="grey_on_hover rounded p25">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {children}
      </div>
    </List.Item>
  );
}

export function DeleteButton({ onClick }) {
  return <Icon color="grey" link name="close" onClick={onClick} />;
}

export function Spinner() {
  return (
    <Loader
      active
      inline
      style={{ float: "right", marginRight: "3px" }}
      size="tiny"
    />
  );
}

export function EmptyContainer({ children }) {
  return (
    <div style={{ color: "grey", marginBottom: "0.5em", marginTop: "1em" }}>
      {children}
    </div>
  );
}

export const SimpleListConnector = ({ items, emptyMessage, onDelete }) => {
  if (items.length === 0) {
    return <EmptyContainer>{emptyMessage}</EmptyContainer>;
  }

  return (
    <SimpleListContainer>
      {items.map(({ id, name }) => {
        return (
          <SimpleListItem key={id}>
            {name}
            <DeleteButton
              onClick={() => {
                return onDelete(id);
              }}
            />
          </SimpleListItem>
        );
      })}
    </SimpleListContainer>
  );
};

SimpleListConnector.propTypes = {
  items: PropTypes.array,
  emptyMessage: PropTypes.string,
  onDelete: PropTypes.func,
};

SimpleListConnector.defaultProps = {
  items: [],
  emptyMessage: "The list is empty",
  onDelete: () => {},
};

SimpleListItem.propTypes = {
  children: PropTypes.node.isRequired,
};

SimpleListContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

EmptyContainer.propTypes = {
  children: PropTypes.string,
};

EmptyContainer.defaultProps = {
  children: "The list is empty",
};

DeleteButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

const SimpleList = SimpleListConnector;
SimpleList.Container = SimpleListContainer;
SimpleList.Item = SimpleListItem;
SimpleList.Empty = EmptyContainer;
SimpleList.DeleteButton = DeleteButton;
SimpleList.Spinner = Spinner;

export default SimpleList;
