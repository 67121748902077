import { API } from "aws-amplify";

import { getAPIReq } from "./authLib";

export const CreateIntegration = async (request) => {
  const req = await getAPIReq(request);
  return API.post("HublyAPI", `/integrations/`, req);
};

export const DeleteIntegration = async (integrationId) => {
  const req = await getAPIReq();
  return API.del("HublyAPI", `/integrations/${integrationId}/`, req);
};
