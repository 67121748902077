import React from "react";
import PropTypes from "prop-types";

import { MultipleClientDropdown } from "components/ClientDropdown";

import { DateSelector, ImportantClientDatesLayout } from "./components";

export function ImportantClientDatesRuleForm({
  dateTypes,
  meetingDateTypes,
  register,
  data,
}) {
  return (
    <ImportantClientDatesLayout
      dateSelector={
        <DateSelector
          dateTypes={dateTypes}
          meetingDateTypes={meetingDateTypes}
          {...register({ name: "dateSelector" })}
        />
      }
      ruleClientDropdown={
        <MultipleClientDropdown
          {...register({
            name: "selectedStreamsTags",
            required: true,
            errortext: "Select streams or tags",
          })}
          exclude={data.excludeItems.map(({ id }) => {
            return id;
          })}
          data-test="ruleModal-dropdown-condition"
          placeholder="Select streams or tags"
          showStreams
          showTags
          dropdownStyle={{ marginBottom: 0 }}
        />
      }
    />
  );
}

ImportantClientDatesRuleForm.propTypes = {
  register: PropTypes.func.isRequired,
  data: PropTypes.object,
  dateTypes: PropTypes.array,
  meetingDateTypes: PropTypes.array,
};

ImportantClientDatesRuleForm.defaultProps = {
  data: null,
  dateTypes: [],
  meetingDateTypes: [],
};

export default ImportantClientDatesRuleForm;
