export const initialState = {
  currentView: { id: "", description: "", filter: {}, name: "" },
  views: [],
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_CURRENT_VIEW":
      return {
        ...state,
        currentView: action.currentView,
      };
    case "SET_VIEWS":
      return {
        ...state,
        views: action.views,
      };
    default:
      return state;
  }
};

export default reducer;
