import React, { useContext } from "react";
import PropTypes from "prop-types";

import { Popup } from "semantic-ui-react";

import { MeetingDateTypeContext } from "components/MeetingDateTypeDropdown";

import { getFormattedDate, getMeetingDateTypeNameFromId } from "./helpers";

export const MeetingDateField = ({ title, event }) => {
  const eventDate = event ? new Date(event.date) : null;
  const formattedDate = getFormattedDate(eventDate);

  const { meetingDateTypes } = useContext(MeetingDateTypeContext);
  const meetingDateTypeText = getMeetingDateTypeNameFromId(
    meetingDateTypes,
    event?.meetingDateTypeId
  );
  return (
    <Popup
      position="right center"
      offset="4px 0px"
      popperModifiers={{
        preventOverflow: { boundariesElement: "window", enabled: false },
      }}
      data-test="meeting-date-field-popup"
      disabled={!(meetingDateTypeText?.length > 0)}
      style={{ minWidth: "260px" }}
      trigger={
        <div
          data-test={`meeting-date-field-${title}`}
          style={{
            display: "flex",
            fontWeight: "normal",
            height: "20px",
            marginBottom: "0.5em",
          }}
        >
          {title && (
            <span
              style={{ fontWeight: "700", marginRight: "0.5em" }}
            >{`${title}:`}</span>
          )}
          <span style={{ color: formattedDate === "None" ? "grey" : "black" }}>
            {formattedDate}
          </span>
        </div>
      }
    >
      <Popup.Content>
        <b>Meeting Type: </b>
        {meetingDateTypeText}
      </Popup.Content>
    </Popup>
  );
};

MeetingDateField.propTypes = {
  title: PropTypes.string.isRequired,
  event: PropTypes.object.isRequired,
};

export default MeetingDateField;
