import { API } from "aws-amplify";

import { getAPIReq } from "data/libs/authLib";

class Client {
  constructor(factory, callPost) {
    this.factory = factory;
    this.callPost = callPost;
  }

  async query(variables, { query, resolver }) {
    const request = await this.factory({ query, variables });
    const response = await this.callPost(request);
    return resolver(response);
  }
}

export const createProdClient = (headers) => {
  const callPost = async (req) => {
    return API.post("HublyAPI", `/graphql`, req);
  };

  const factory = async (body) => {
    return getAPIReq(body, headers);
  };

  return new Client(factory, callPost);
};

export default createProdClient;
