export const initialState = {
  auditOpen: false,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "TOGGLE_AUDIT_OPEN":
      return {
        ...state,
        auditOpen: !state.auditOpen,
      };
    default:
      return state;
  }
};
export default reducer;
