import React from "react";
import PropTypes from "prop-types";

import { Button, Icon } from "semantic-ui-react";

export function ButtonAddTime({ valueInMin, text, onClick, disabled }) {
  return (
    <div style={{ maxWidth: "23%", flexGrow: 1 }}>
      <Button
        disabled={disabled}
        basic
        compact
        fluid
        onClick={() => {
          onClick(valueInMin);
        }}
        style={{ fontWeight: "bold", padding: "0.9em 0" }}
      >
        <Icon name="plus" style={{ margin: 0 }} />
        {text}
      </Button>
    </div>
  );
}

ButtonAddTime.propTypes = {
  valueInMin: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

ButtonAddTime.defaultProps = {
  disabled: false,
};

export default ButtonAddTime;
