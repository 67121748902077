export function getServiceTeams() {
  const query = `query getServiceTeams {
        serviceTeams {
            edges {
                node {
                    id
                    name
                    numActiveClients
                    totalClients
                    serviceTeamRoleIds
                    isDefaultSettings
                    serviceTeamMembers {
                        edges {
                            node {
                                id
                                name
                                serviceRole {
                                  id
                                  name
                                  isSynced
                                  listType
                                }
                                crmListUser {
                                  id
                                }
                                advisor {
                                  id
                                }
                            }
                        }
                    }
                }
            }
        }
        serviceRoles {
            edges {
                node {
                    id
                    name
                    isSynced
                    listType
                    syncableName
                }
            }
        }
        hub {
          integrationSet (first: 1) {
            edges {
              node {
                type
              }
            }
          }
        }
    }`;

  const resolver = ({ data }) => {
    return [
      data?.serviceTeams.edges.map(({ node }) => {
        return {
          ...node,
          serviceTeamMembers: node.serviceTeamMembers.edges.map(
            ({ node: tNode }) => {
              return {
                ...tNode,
                id: tNode.advisor ? tNode.advisor.id : tNode.id,
                roleName: tNode.serviceRole.name,
              };
            }
          ),
        };
      }) || [],
      data?.serviceRoles.edges.map(({ node }) => {
        return { ...node };
      }) || [],
      data?.hub?.integrationSet?.edges.map(({ node }) => {
        return node.type;
      }),
    ];
  };

  return { query, resolver };
}

export default getServiceTeams;
