import React from "react";

import { Popup } from "semantic-ui-react";

export function Popper(props) {
  return (
    <Popup
      on="click"
      popperModifiers={{
        preventOverflow: { boundariesElement: "window", enabled: true },
        hide: { enabled: false },
      }}
      {...props}
    />
  );
}

export default Popper;
