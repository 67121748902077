export const OpenReminderRescheduleModal = (reminder) => {
  return {
    type: "OPEN_REMINDER_RESCHEDULE_MODAL",
    reminder: reminder,
  };
};
export const CloseReminderRescheduleModal = () => {
  return {
    type: "CLOSE_REMINDER_RESCHEDULE_MODAL",
  };
};
