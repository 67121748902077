export const initialState = {
  reminder: {},
  reminderRescheduleModalOpen: false,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "OPEN_REMINDER_RESCHEDULE_MODAL":
      return {
        ...state,
        reminder: action.reminder,
        reminderRescheduleModalOpen: true,
      };
    case "CLOSE_REMINDER_RESCHEDULE_MODAL":
      return {
        ...state,
        reminderRescheduleModalOpen: false,
      };
    default:
      return state;
  }
};
export default reducer;
