import React from "react";

import { Icon } from "semantic-ui-react";

import Tooltip from "components/Tooltip";

function InviteNowTooltip() {
  return (
    <Tooltip
      position="top center"
      title="Choose whether to send account activation emails to users immediately. 
You can always send them later."
    >
      <Icon color="grey" name="info circle" />
    </Tooltip>
  );
}

export default InviteNowTooltip;
