import React from "react";
import PropTypes from "prop-types";

import { Label } from "semantic-ui-react";

import { getOnBeforeAfterValue } from "../../../../helper";

export function ImportantClientDatesRuleText({
  workflowRule,
  dateTypes,
  meetingDateTypes,
}) {
  if (dateTypes.length === 0 || meetingDateTypes === 0) {
    return <div>...</div>;
  }

  const { importantDate, dateType, meetingDateType } = workflowRule;

  const renderFrequency = ({ frequency }) => {
    if (!frequency) return "";

    const frequencyTypes = {
      daily: { text: "day", plural: "days" },
      weekly: { text: "week", plural: "weeks" },
      monthly: { text: "month", plural: "months" },
      yearly: { text: "year", plural: "years" },
    };

    return frequencyTypes[frequency.toLowerCase()];
  };

  const beforeOrAfter = getOnBeforeAfterValue(workflowRule.interval);
  const renderText = (interval, frequency) => {
    const intervalVal = Math.abs(interval);

    if (intervalVal === 0) return " will next be added on their ";

    const { text, plural } = frequency;
    const freq = interval >= 2 ? plural : text;

    return ` will next be added ${intervalVal} ${freq} ${beforeOrAfter.toLocaleLowerCase()} their`;
  };

  const impDate =
    importantDate === "meeting"
      ? {
          icon: "calendar alternate",
          name: `${beforeOrAfter === "AFTER" ? "Last" : "Next"} Meeting`,
        }
      : dateTypes.find(({ id }) => {
          return id === dateType?.id;
        });

  const meetingDate = meetingDateTypes.find(({ id }) => {
    return id === meetingDateType?.id;
  });
  return (
    <React.Fragment>
      {renderText(workflowRule.interval, renderFrequency(workflowRule))}{" "}
      <Label
        content={`${impDate?.name}`}
        icon={impDate?.icon}
        style={{
          backgroundColor: "#828282",
          color: "white",
          marginTop: "0.4em",
          padding: "0.3em 0.4em",
        }}
      />
      &nbsp;Date&nbsp;
      {meetingDate && (
        <React.Fragment>
          <span>of type&nbsp;</span>
          <Label
            content={meetingDate?.name}
            style={{ marginTop: "0.4em", padding: "0.3em 0.4em" }}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
ImportantClientDatesRuleText.propTypes = {
  workflowRule: PropTypes.object,
  dateTypes: PropTypes.array,
  meetingDateTypes: PropTypes.array,
};

ImportantClientDatesRuleText.defaultProps = {
  workflowRule: {},
  dateTypes: [],
  meetingDateTypes: [],
};
export default ImportantClientDatesRuleText;
