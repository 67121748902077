export const initialState = {
  tag: {},
  confirmAddDisabled: false,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "TOGGLE_TAG_MODAL":
      return {
        ...state,
        tag: action.tag,
      };
    case "DISABLE_TAG_CONFIRMATION":
      return {
        ...state,
        confirmAddDisabled: true,
      };
    default:
      return state;
  }
};
export default reducer;
