import { withFeatureFlag } from "components/FeatureFlag";

import { UserFlow } from "./UserFlow";

export function makeUserflowUser({ id, name, email, created }) {
  return {
    userId: id,
    name: name,
    email: email,
    signed_up_at: created,
  };
}

export default withFeatureFlag(UserFlow, "userflow-module");
