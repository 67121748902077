import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import config from "config";

import { Button, Form, Icon, Message, Modal, Popup } from "semantic-ui-react";
import { isEmail, isMobilePhone } from "validator";

import history from "data/history";
import {
  AddClientToAllClientNames,
  SetActiveClient,
} from "data/hub/clients/actions";
import { CreateClient } from "data/libs/clients";

import { SetAlert } from "components/Alerts/actions";

import { CloseCreateClientModal } from "./actions";

class CreateClientModal extends React.Component {
  static defaultProps = {
    addClientToWorkflow: () => {},
    clientName: "",
    isClientWorkflow: false,
    onCreateCallback: null,
  };

  static propTypes = {
    addClientToAllNames: PropTypes.func.isRequired,
    addClientToWorkflow: PropTypes.func,
    advisor: PropTypes.shape({
      integrations: PropTypes.array.isRequired,
    }).isRequired,
    hub: PropTypes.object.isRequired,
    clientName: PropTypes.string,
    closeCreateClientModal: PropTypes.func.isRequired,
    createClientModalOpen: PropTypes.bool.isRequired,
    isClientWorkflow: PropTypes.bool,
    setAlert: PropTypes.func.isRequired,
    setActiveClient: PropTypes.func.isRequired,
    onCreateCallback: PropTypes.func,
  };

  constructor(props) {
    super(props);
    const { searchFirstName, searchLastName } = this.getSearchName(
      props.clientName
    );
    this.state = {
      firstName: searchFirstName,
      lastName: searchLastName,
      middleName: "",
      nickname: "",
      email: "",
      errorList: [],
      phone: "",
      firstAlert: false,
      lastAlert: false,
      emailAlert: false,
      phoneAlert: false,
      loading: false,
      popupOpen: false,
    };
  }

  togglePopup = () => {
    this.setState((state) => {
      return { popupOpen: !state.popupOpen };
    });
  };

  close = () => {
    const { closeCreateClientModal } = this.props;
    this.setState({
      firstName: "",
      lastName: "",
      email: "",
      errorList: [],
      phone: "",
      firstAlert: false,
      lastAlert: false,
      emailAlert: false,
      phoneAlert: false,
      loading: false,
    });
    closeCreateClientModal();
  };

  createClient = (editInWealthbox) => {
    this.setState({ loading: true });
    const { firstName, email, lastName, middleName, nickname, phone } =
      this.state;
    const {
      addClientToAllNames,
      addClientToWorkflow,
      advisor,
      hub,
      isClientWorkflow,
      setAlert,
      setActiveClient,
      onCreateCallback,
    } = this.props;
    const changes = {
      firstAlert: false,
      lastAlert: false,
      emailAlert: false,
      phoneAlert: false,
      extAlert: false,
      errorList: [],
    };
    let valid = true;
    if (firstName.trim().length === 0) {
      changes.firstAlert = true;
      changes.errorList.push("First name is required.");
      valid = false;
    }
    if (lastName.trim().length === 0) {
      changes.lastAlert = true;
      changes.errorList.push("Last name required.");
      valid = false;
    }
    if (email.trim().length > 0 && !isEmail(email.toLowerCase())) {
      // Check if email is required and is valid
      changes.emailAlert = true;
      changes.errorList.push("Email address is invalid.");
      valid = false;
    }
    if (phone.trim().length > 0 && !isMobilePhone(phone)) {
      changes.phoneAlert = true;
      changes.errorList.push("Phone number is invalid.");
      valid = false;
    }
    this.setState(changes);
    this.togglePopup();
    if (valid) {
      const request = {
        clientPoolId: hub.clientPool.id,
        email: email,
        firstName: firstName,
        lastName: lastName,
        middleName: middleName,
        nickname: nickname,
        phoneNumber: phone,
      };
      CreateClient(request)
        .then((response) => {
          this.setState({ loading: false });
          setActiveClient(response);
          addClientToAllNames(response);
          // Based on what is calling create client, either create and edit in wealthbox, or create and add to workflow
          if (editInWealthbox) {
            const wealthboxId = response.externalId; // Wait for create client and fill in actual wealthboxId

            const isRedtailIntegrated =
              advisor.integrations.find((integration) => {
                return (
                  integration.hubId === hub.id && integration.type === "redtail"
                );
              }) !== undefined;

            if (isRedtailIntegrated) {
              window.open(
                `${config.redtailUrl}/contacts/${wealthboxId}`,
                "_blank"
              );
            } else {
              window.open(
                `https://app.crmworkspace.com/contacts/${wealthboxId}/edit`,
                "_blank"
              );
            }
          }
          if (isClientWorkflow) {
            addClientToWorkflow(response);
          }

          if (!isClientWorkflow && !editInWealthbox) {
            history.push(`/hub/${hub.hubId}/clients/${response.id}`);
          }

          if (onCreateCallback) onCreateCallback(response); // callback if required, such as to the household modal
          this.close();
        })
        .catch(() => {
          this.setState({ loading: false });
          setAlert({ type: "error", text: "Failed to create client." });
        });
    } else {
      this.setState({ loading: false });
    }
  };

  // Get the name from the client search dropdown when clicking on add
  getSearchName = (clientName) => {
    // If there is only one name entered, populate first name field
    // If more than one, populate first word into first name and remainder into last name
    let searchFirstName = "";
    let searchLastName = "";
    if (clientName.indexOf(" ") === -1) {
      searchFirstName = clientName;
    } else {
      searchFirstName = clientName.substr(0, clientName.indexOf(" "));
      searchLastName = clientName.substr(clientName.indexOf(" ") + 1);
    }
    return { searchFirstName, searchLastName };
  };

  render() {
    const {
      email,
      emailAlert,
      errorList,
      firstAlert,
      firstName,
      lastAlert,
      lastName,
      middleName,
      nickname,
      loading,
      phone,
      phoneAlert,
      popupOpen,
    } = this.state;
    const {
      advisor,
      hub,
      createClientModalOpen,
      isClientWorkflow,
      onCreateCallback,
    } = this.props;
    return (
      <Modal
        id="create_new_client_modal"
        size="mini"
        open={createClientModalOpen}
        centered={false}
      >
        <Modal.Header>
          <Icon name="user plus" style={{ marginRight: "1em" }} />
          Create a New Client
        </Modal.Header>
        <Modal.Content>
          <Form error>
            <Message
              error
              negative
              header="Error"
              hidden={errorList.length === 0}
              list={errorList}
            />
            <Form.Input
              label="First Name"
              required
              onChange={(e) => {
                this.setState({ firstName: e.target.value });
              }}
              error={firstAlert}
              value={firstName}
            />
            <Form.Input
              label="Last Name"
              required
              onChange={(e) => {
                this.setState({ lastName: e.target.value });
              }}
              error={lastAlert}
              value={lastName}
            />
            <Form.Input
              label="Middle Name"
              onChange={(e) => {
                this.setState({ middleName: e.target.value });
              }}
              error={lastAlert}
              value={middleName}
            />
            <Form.Input
              label="Nickname"
              onChange={(e) => {
                this.setState({ nickname: e.target.value });
              }}
              error={lastAlert}
              value={nickname}
            />
            <Form.Input
              label="Email address"
              error={emailAlert}
              onChange={(e) => {
                this.setState({ email: e.target.value });
              }}
              value={email}
            />
            <Form.Input
              label="Phone Number"
              onChange={(e) => {
                this.setState({ phone: e.target.value });
              }}
              value={phone}
              error={phoneAlert}
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            basic
            icon="close"
            onClick={() => {
              this.close();
            }}
          />
          {advisor.integrations.find((i) => {
            return i.hubId === hub.id;
          }) ? (
            <Popup
              trigger={
                <Button primary floated="right" loading={loading}>
                  Create
                </Button>
              }
              flowing
              position="bottom center"
              on="click"
              open={popupOpen}
              onClose={this.togglePopup}
              onOpen={this.togglePopup}
              style={{ padding: "0" }}
            >
              <Button.Group basic vertical>
                {isClientWorkflow ? (
                  <Button
                    content="Save and Add"
                    onClick={() => {
                      this.createClient(false);
                    }}
                    style={{
                      textAlign: "left",
                      paddingLeft: "1em",
                      width: "12em",
                    }}
                  />
                ) : (
                  <Button
                    content={
                      onCreateCallback ? "Save and Return" : "Save and Close"
                    }
                    onClick={() => {
                      this.createClient(false);
                    }}
                    style={{
                      textAlign: "left",
                      paddingLeft: "1em",
                      width: "12em",
                    }}
                  />
                )}
                <Button
                  content="Edit in CRM"
                  onClick={() => {
                    this.createClient(true);
                  }}
                  style={{
                    textAlign: "left",
                    paddingLeft: "1em",
                    width: "12em",
                  }}
                />
              </Button.Group>
            </Popup>
          ) : (
            <Button
              content="Create"
              disabled={loading}
              floated="right"
              loading={loading}
              onClick={() => {
                this.createClient(false);
              }}
              primary
            />
          )}
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    activeClients: state.hubly.data.hub.clients.activeClients,
    advisor: state.hubly.data.advisor,
    hub: state.hubly.data.hub.selected.hub,
    clientName: state.hubly.createClient.clientName,
    onCreateCallback: state.hubly.createClient.createCallback,
    isClientWorkflow: state.hubly.createClient.isClientWorkflow,
    addClientToWorkflow: state.hubly.createClient.addClientToWorkflow,
    createClientModalOpen: state.hubly.createClient.createClientModalOpen,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addClientToAllNames: (client) => {
      dispatch(AddClientToAllClientNames(client));
    },
    closeCreateClientModal: () => {
      dispatch(CloseCreateClientModal());
    },
    setAlert: (alert) => {
      dispatch(SetAlert(alert));
    },
    setActiveClient: (clients) => {
      dispatch(SetActiveClient(clients));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateClientModal);
