import { useDispatch, useSelector } from "react-redux";

import { SetHub } from "./actions";

export const useHub = () => {
  return useSelector(({ hubly }) => {
    return hubly.data.hub.selected.hub;
  });
};

export const useSetHub = () => {
  const dispatch = useDispatch();

  return (hub) => {
    return dispatch(SetHub(hub));
  };
};

export default useHub;
