import React from "react";

import { Divider, Header } from "semantic-ui-react";

import useCurrentUser from "data/advisor/useCurrentUser";
import useHub from "data/hub/useHub";

import {
  HubOptions,
  IntegrationSettings,
  UserInformationForm,
} from "./components";

export function HubSettings() {
  const { name } = useHub();
  const user = useCurrentUser();

  return (
    <React.Fragment>
      <Header as="h1">{`${name} Settings`}</Header>
      <HubOptions />
      <UserInformationForm advisor={user} />
      <Divider style={{ marginTop: "2em", marginBottom: "1em" }} />
      <IntegrationSettings />
    </React.Fragment>
  );
}

export default HubSettings;
