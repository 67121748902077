import { useContext, useEffect, useRef, useState } from "react";

import { HubContext } from "scenes/Hubly/components/Workspace/Provider";

export function useClientAction() {
  const { createClient } = useContext(HubContext);
  const mounted = useRef(false);
  const [isRunningAction, setRunning] = useState(false);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  const call = async (action) => {
    setRunning(true);
    const resp = await action();
    if (mounted.current) {
      setRunning(false);
      return resp;
    }
    return null;
  };

  return {
    isRunningAction: isRunningAction,
    createClient: (clientData) => {
      return call(() => {
        return createClient(clientData);
      });
    },
  };
}

export default useClientAction;
