import React, { useEffect } from "react";
import PropTypes from "prop-types";

import {
  AssigneeSelector,
  Icon,
  Loader,
  useAssignees,
  useBooleanState,
} from "./components";

export function AssigneeFilter({ selected, onSelect, onClear }) {
  const [isOpen, open, close] = useBooleanState();
  const [fetch, loadingAssignees, assignees] = useAssignees();

  useEffect(() => {
    if (isOpen) fetch();
  }, [isOpen, fetch]);

  if (loadingAssignees) {
    return <Loader size="tiny" active inline />;
  }

  return (
    <AssigneeSelector
      data-test="hubfeed-assignee-filter-popup"
      position="bottom right"
      selected={selected}
      loading={loadingAssignees}
      assignees={assignees}
      onOpen={open}
      onClose={close}
      open={isOpen}
      onSelect={({ id, group }) => {
        onSelect(id, group);
        close();
      }}
      onClear={() => {
        onClear();
        close();
      }}
    >
      <div
        data-test="hubfeed-settings-popup"
        className="clickable grey_on_hover rounded p25"
      >
        <Icon color="grey" name="setting" />
      </div>
    </AssigneeSelector>
  );
}

AssigneeFilter.propTypes = {
  selected: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
};

export default AssigneeFilter;
