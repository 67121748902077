import clone from "rfdc";

import { HideShowWorkflow, SetFilters } from "data/filters/actions";
import { SetHub } from "data/hub/actions";
import {
  SetClientWorkflow,
  SetClientWorkflows,
  sortClientWorkflow,
} from "data/hub/clientWorkflows/actions";
import {
  AddWorkflow,
  SetWorkflow,
  SetWorkflows,
} from "data/hub/workflows/actions";
import {
  CreateClientWorkflow,
  MoveClientWorkflow,
  UpdateClientWorkflowBulkSortOrder,
  UpdateClientWorkflowSortOrder,
} from "data/libs/clientWorkflows";
import {
  CreateNextWorkflow,
  DeleteNextWorkflow,
} from "data/libs/nextWorkflows";
import { CreateProcess } from "data/libs/processes";
import { AddReminder } from "data/libs/reminders";
import { AddTask } from "data/libs/tasks";
import {
  CreateWorkflow,
  DuplicateWorkflow,
  EditWorkflow,
  GetWorkflows,
} from "data/libs/workflows";

import { SetAlert } from "components/Alerts/actions";

import { AddClientToWorkflow } from "./components/ClientTile/actions";

const deepClone = clone();

export const CreateNewWorkflow = (callback, name) => {
  return (dispatch, getState) => {
    const { filters } = { ...getState().hubly.data };
    const { workflows } = { ...getState().hubly.data.hub };
    const { hub } = getState().hubly.data.hub.selected;
    const workflowsList = Object.values(workflows);
    const maxWorkflowOrder = Math.max(
      ...workflowsList.map((wf) => {
        return wf.order || 0;
      }),
      0
    );
    const request = {
      hubId: hub.id,
      name: name || "Untitled",
      order: maxWorkflowOrder + 1,
    };

    CreateWorkflow(request)
      .then((response) => {
        dispatch(AddWorkflow(response));
        dispatch(
          SetAlert({ type: "success", text: `Successfully created workflow` })
        );

        const filtersCopy = { ...filters };
        if (filtersCopy.workflows) {
          filtersCopy.workflows.push(response);
          dispatch(SetFilters(filtersCopy, true));
        }

        if (callback) {
          callback(response);
        }
      })
      .catch((error) => {
        dispatch(
          SetAlert({ type: "error", text: `Failed to create workflow.` })
        );
        console.error(error);
        callback(null);
      });
  };
};

export const AddTemplateWorkflows = (template, callback) => {
  return (dispatch, getState) => {
    const { hub } = getState().hubly.data.hub.selected;
    // Workflows
    const { workflows } = { ...getState().hubly.data.hub };
    const workflowsList = Object.values(workflows);
    const maxWorkflowOrder = Math.max(
      ...workflowsList.map((wf) => {
        return wf.order;
      }),
      0
    );

    const createdWorkflows = [];
    // Run the promise sequentially to avoid backend creating the same sort_order
    // for all the template workflows.
    const workflowPromises = template.workflows.reduce(
      (p, templateWorkflow, i) => {
        const workflowRequest = {
          hubId: hub.id,
          isNewTemplate: true,
          name: templateWorkflow.name,
          order: maxWorkflowOrder + i + 1,
        };
        return p.then(() => {
          return CreateWorkflow(workflowRequest)
            .then((response) => {
              createdWorkflows.push(response);
            })
            .catch(() => {
              createdWorkflows.push(null);
            });
        });
      },
      Promise.resolve()
    );

    workflowPromises.then(() => {
      const taskPromises = [];
      createdWorkflows.forEach((createdWorkflow) => {
        if (createdWorkflow === null) {
          return;
        }

        const templateWorkflow = template.workflows.filter((workflow) => {
          return workflow.name === createdWorkflow.name;
        })[0];

        templateWorkflow.tasks.forEach((task, i) => {
          const taskRequest = {
            title: task.name,
            workflowId: createdWorkflow.id,
            order: i,
            description: task.description,
            links: task.links?.map((l, index) => {
              return { ...l, id: index };
            }), // links need unique fake ids
          };

          taskPromises.push(
            AddTask(taskRequest)
              .then((response) => {
                return {
                  task: response,
                  workflow: templateWorkflow,
                };
              })
              .catch(() => {
                return null;
              })
          );
        });
      });

      // Then do all of the tasks
      Promise.all(taskPromises).then((createdTasks) => {
        const reminderPromises = [];

        createdTasks.forEach((createdTask) => {
          if (createdTask === null) {
            return;
          }
          const templateTask = createdTask.workflow.tasks.filter((task) => {
            return task.name === createdTask.task.title;
          })[0];

          templateTask.reminders.forEach((reminder, i) => {
            const defaultDate = new Date();
            defaultDate.setHours(reminder.hour);
            defaultDate.setMinutes(0);
            defaultDate.setSeconds(0);
            defaultDate.setMilliseconds(0);

            const reminderRequest = {
              futureReminderType: reminder.type,
              futureReminderNumber: reminder.number,
              futureReminderTime: defaultDate,
              taskId: createdTask.task.id,
            };

            reminderPromises.push(AddReminder(reminderRequest));
          });
        });

        // Now do all the reminders
        Promise.all(reminderPromises)
          .then(() => {
            // Processes
            const processPromises = [];

            template.processes.forEach((templateProcess, i) => {
              const processWorkflows = [];
              templateProcess.workflows.forEach((workflow) => {
                const foundWorkflow = createdWorkflows.find((wf) => {
                  return wf.name === workflow;
                });
                if (foundWorkflow) {
                  processWorkflows.push(foundWorkflow.id);
                }
              });

              const processRequest = {
                hubId: hub.id,
                name: templateProcess.name,
                color: templateProcess.color,
                workflows: processWorkflows,
              };

              processPromises.push(
                CreateProcess(processRequest)
                  .then((response) => {
                    return response;
                  })
                  .catch(() => {
                    return null;
                  })
              );
            });
            // Create Processes
            Promise.all(processPromises).then((createdProcesses) => {
              const selectedHub = deepClone(
                getState().hubly.data.hub.selected.hub
              );
              selectedHub.processes =
                selectedHub.processes.concat(createdProcesses);
              dispatch(SetHub(selectedHub));
              GetWorkflows(hub).then((getWorkflowResponse) => {
                dispatch(SetWorkflows(getWorkflowResponse));
                createdWorkflows.forEach((wf) => {
                  dispatch(HideShowWorkflow(wf, false));
                });
                callback();
              });
            });
          })
          // Refresh the hub even if a request fails
          .catch(() => {
            GetWorkflows(hub).then((getWorkflowResponse) => {
              dispatch(SetWorkflows(getWorkflowResponse));
              callback();
            });
          });
      });
    });
  };
};

export const CreateDuplicateWorkflow = (
  workflowId,
  destinationHubId,
  callback
) => {
  return (dispatch, getState) => {
    const { filters } = Object.assign([], getState().hubly.data);
    const selectedHub = getState().hubly.data.hub.selected.hub;

    DuplicateWorkflow(workflowId, destinationHubId)
      .then((response) => {
        if (selectedHub.id === destinationHubId) {
          dispatch(AddWorkflow(response));
          const filtersCopy = { ...filters };
          filtersCopy.workflows.push(response);
          dispatch(SetFilters(filtersCopy, true));
        }
        if (callback) callback(true);
      })
      .catch((error) => {
        console.error(error);
        if (callback) callback(false);
      });
  };
};

// This function uses nextWorkflowId column in the next workflow table, not the ID of the row
// The remove function takes the opposite, the ID of the row, rather than the column nextWorkflowId
export function AddNextWorkflow(workflow, nextWorkflowId, nextWorkflowName) {
  return (dispatch, getState) => {
    const request = {
      workflowId: workflow.id,
      nextWorkflowId: nextWorkflowId,
    };
    CreateNextWorkflow(request)
      .then((response) => {
        const foundWorkflow = {
          ...getState().hubly.data.hub.workflows[workflow.id],
        };
        if (!foundWorkflow) {
          console.error(
            "Error adding next workflow, could not find current workflow."
          );
          dispatch(
            SetAlert({
              type: "warning",
              text: `Failed to create next workflow.`,
            })
          );
          return;
        }

        foundWorkflow.nextWorkflows.push(response);
        dispatch(SetWorkflow(foundWorkflow));
      })
      .catch((error) => {
        dispatch(
          SetAlert({ type: "error", text: `Failed to create next workflow.` })
        );
      });
  };
}

// This function takes in the ID of the row (nextWorkflowObjectId) in the next workflows table, not the nextWorkflowId
// The add function takes the opposite, the column nextWorkflowId, rather than the ID of the row
export function RemoveNextWorkflow(
  workflow,
  nextWorkflowObjectId,
  nextWorkflowName
) {
  return (dispatch, getState) => {
    DeleteNextWorkflow(nextWorkflowObjectId)
      .then((response) => {
        const foundWorkflow = {
          ...getState().hubly.data.hub.workflows[workflow.id],
        };
        foundWorkflow.nextWorkflows = foundWorkflow.nextWorkflows.filter(
          (nextWorkflow) => {
            return nextWorkflow.id !== nextWorkflowObjectId;
          }
        );
        dispatch(SetWorkflow(foundWorkflow));
      })
      .catch((error) => {
        dispatch(
          SetAlert({ type: "error", text: `Failed to delete next workflow.` })
        );
      });
  };
}

function simulateAddDummyClientToWorkflow(
  request,
  toWorkflow,
  clientId,
  setClientWorkflow,
  setWorkflow,
  deleteClientWorkflow
) {
  setClientWorkflow({ clientId: clientId, ...request, id: "dummyId" });
  setWorkflow({ ...toWorkflow, clients: [...toWorkflow.clients, "dummyId"] });

  return () => {
    deleteClientWorkflow("dummyId");
    const updatedClients = toWorkflow?.clients?.filter((clientWorkflowId) => {
      return clientWorkflowId !== "dummyId";
    });

    setWorkflow({ ...toWorkflow, clients: updatedClients });
  };
}

export function CreateNewClientWorkflow(
  request,
  callSimulateAddDummyClientToWorkflow
) {
  return (dispatch) => {
    const removeDummyClientWorkflow = callSimulateAddDummyClientToWorkflow(
      simulateAddDummyClientToWorkflow
    );

    CreateClientWorkflow(request)
      .then((response) => {
        removeDummyClientWorkflow();
        dispatch(AddClientToWorkflow(response));
      })
      .catch((error) => {
        removeDummyClientWorkflow();
      });
  };
}

function removeFromPreviousClientsNextWF(
  clientWorkflows,
  sourceClientWorkflowId,
  toWorkflowId,
  setClientWorkflow
) {
  const updatedNextWorkflows = (
    clientWorkflows[sourceClientWorkflowId].nextWorkflows || []
  ).filter((nwf) => {
    return nwf?.nextWorkflowId !== toWorkflowId;
  });

  setClientWorkflow({
    ...clientWorkflows[sourceClientWorkflowId],
    nextWorkflows: updatedNextWorkflows,
  });
}

export function MoveClientToNextWorkflow(
  request,
  callRemoveFromPreviousClientsNextWF,
  callSimulateAddDummyClientToWorkflow
) {
  return (dispatch) => {
    const removeDummyClientWorkflow = callSimulateAddDummyClientToWorkflow(
      simulateAddDummyClientToWorkflow
    );
    const { previousWorkflowId: sourceClientWorkflowId } = request;

    MoveClientWorkflow(sourceClientWorkflowId, request)
      .then((response) => {
        removeDummyClientWorkflow();
        callRemoveFromPreviousClientsNextWF(removeFromPreviousClientsNextWF);
        dispatch(AddClientToWorkflow(response));
      })
      .catch((e) => {
        removeDummyClientWorkflow();
      });
  };
}

export function AddNextWorkflowsToAll(
  workflow,
  nextWorkflowId,
  nextWorkflowName
) {
  return (dispatch, getState) => {
    const { clientWorkflows } = getState().hubly.data.hub;
    const updatedClientWorkflows = [];

    let isError = false;
    Object.values(clientWorkflows).forEach((clientWorkflow) => {
      if (clientWorkflow.workflowId === workflow.id) {
        const nextWorkflows = clientWorkflow.nextWorkflows || [];
        // Make sure it hasn't already been added to the client workflow
        const nextWorkflowMatch = nextWorkflows.find((nextWorkflow) => {
          return nextWorkflow.id === nextWorkflowId;
        });

        if (!nextWorkflowMatch) {
          const request = {
            clientWorkflowId: clientWorkflow.id,
            nextWorkflowId: nextWorkflowId,
          };
          CreateNextWorkflow(request)
            .then((response) => {
              nextWorkflows.push(response);
              updatedClientWorkflows.push(clientWorkflow);
            })
            .catch((error) => {
              isError = true;
            });
        }
      }
    });

    if (!isError) {
      updatedClientWorkflows.forEach((clientWorkflow) => {
        dispatch(SetClientWorkflow(clientWorkflow));
      });
      dispatch(
        SetAlert({
          type: "success",
          text: `Successfully added ${nextWorkflowName} to all clients`,
        })
      );
    } else {
      dispatch(
        SetAlert({
          type: "error",
          text: `Failed to add next workflow to some clients in workflow.`,
        })
      );
    }
  };
}

export function RemoveNextWorkflowsFromAll(
  workflow,
  nextWorkflowId,
  nextWorkflowName
) {
  return (dispatch, getState) => {
    const { clientWorkflows } = getState().hubly.data.hub;
    workflow.clients.forEach((clientWorkflowId) => {
      try {
        const clientWorkflow = { ...clientWorkflows[clientWorkflowId] };
        const nextWorkflow = clientWorkflow.nextWorkflows.find((nw) => {
          return nw.nextWorkflowId === nextWorkflowId;
        });
        DeleteNextWorkflow(nextWorkflow.id).then(() => {
          clientWorkflow.nextWorkflows = clientWorkflow.nextWorkflows.filter(
            (nw) => {
              return nw.nextWorkflowId !== nextWorkflowId;
            }
          );
          dispatch(SetClientWorkflow(clientWorkflow));
        });
      } catch (error) {
        console.error(error);
        dispatch(
          SetAlert({ type: "error", text: `Failed to delete next workflow.` })
        );
      }
    });
    dispatch(
      SetAlert({
        type: "success",
        text: `Successfully removed ${nextWorkflowName} from all clients`,
      })
    );
  };
}

export function SetTileSettings(workflowId, settings) {
  return (dispatch, getState) => {
    const workflowCopy = { ...getState().hubly.data.hub.workflows[workflowId] };
    if (!workflowCopy) {
      console.warn(
        "SetTileSettings, Workflow ID ",
        workflowId,
        " does not exist"
      );
      return;
    }

    // First two are boolean so cannot use || operator, need to check if key exists
    workflowCopy.showIcons =
      "showIcons" in settings ? settings.showIcons : workflowCopy.showIcons;
    workflowCopy.showStreams =
      "showStreams" in settings
        ? settings.showStreams
        : workflowCopy.showStreams;
    workflowCopy.showProgress =
      "showProgress" in settings
        ? settings.showProgress
        : workflowCopy.showProgress;
    workflowCopy.numberOfTasks =
      settings.numberOfTasks || workflowCopy.numberOfTasks;
    workflowCopy.options =
      "showName" in settings
        ? { ...workflowCopy.options, presentWorkflowName: settings.showName }
        : workflowCopy.options;

    EditWorkflow(workflowId, {
      options: { ...workflowCopy.options },
      ...settings,
    })
      .then((returnedWorkflow) => {
        dispatch(SetWorkflow(returnedWorkflow));
        dispatch(
          SetAlert({
            type: "success",
            text: "Successfully update the client settings",
          })
        );
      })
      .catch((e) => {
        dispatch(
          SetAlert({
            type: "error",
            text: "Failed to update client tile settings",
          })
        );
      });
  };
}

export function UpdateClientWorkflowBulkOrder(request, callback) {
  return (dispatch, getState) => {
    const { clientWorkflows } = getState().hubly.data.hub;
    const { workflows } = { ...getState().hubly.data.hub };

    const { workflowId } = request;
    const workflowCopy = { ...workflows[workflowId] };
    UpdateClientWorkflowBulkSortOrder(request)
      .then((response) => {
        const clientWorkflowsCopy = { ...clientWorkflows };
        response.forEach(({ id, sortOrder }) => {
          clientWorkflowsCopy[id].sortOrder = sortOrder;
        });

        const updatedClientWorkflow = workflowCopy.clients
          .filter((clientWorkflowId) => {
            return clientWorkflowId in clientWorkflows;
          })
          .sort((a, b) => {
            return sortClientWorkflow(a, b, clientWorkflowsCopy);
          });

        workflowCopy.clients = updatedClientWorkflow;
        dispatch(SetWorkflow(workflowCopy));
        dispatch(SetClientWorkflows(Object.values(clientWorkflowsCopy)));
        callback(true);
      })
      .catch((error) => {
        console.error(error);
        callback(false);
      });
  };
}

export function UpdateClientWorkflowOrder(
  { targetIndex, workflowId, workflowList },
  callback
) {
  return (dispatch, getState) => {
    const { clientWorkflows } = getState().hubly.data.hub;
    const { workflows } = { ...getState().hubly.data.hub };

    const workflowCopy = { ...workflows[workflowId] };
    const targetClientWorkflowId = workflowList[targetIndex];
    const nextClientWorkflowId =
      targetIndex + 1 < workflowList.length
        ? workflowList[targetIndex + 1]
        : null;

    UpdateClientWorkflowSortOrder(targetClientWorkflowId, {
      placeBeforeItem: nextClientWorkflowId,
    })
      .then((response) => {
        const clientWorkflowsCopy = { ...clientWorkflows };
        clientWorkflowsCopy[targetClientWorkflowId].sortOrder =
          response.sortOrder;

        if (response.nextItemSortOrder && nextClientWorkflowId) {
          clientWorkflowsCopy[nextClientWorkflowId].sortOrder =
            response.sortOrder;
        }
        const updatedClientWorkflow = workflowCopy.clients
          .filter((clientWorkflowId) => {
            return clientWorkflowId in clientWorkflows;
          })
          .sort((a, b) => {
            return sortClientWorkflow(a, b, clientWorkflowsCopy);
          });

        workflowCopy.clients = updatedClientWorkflow;
        dispatch(SetWorkflow(workflowCopy));
        dispatch(SetClientWorkflows(Object.values(clientWorkflowsCopy)));
        callback(true);
      })
      .catch((error) => {
        console.error(error);
        callback(false);
      });
  };
}
