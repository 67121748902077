import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Form, TextArea } from "semantic-ui-react";

import { PrivacyModeContext } from "components/PrivacyMode/Context";

import { UpdateClientNote } from "../actions";

class CardNotes extends React.Component {
  static contextType = PrivacyModeContext;

  static propTypes = {
    client: PropTypes.shape({
      note: PropTypes.string,
      id: PropTypes.string.isRequired,
    }).isRequired,
    updateClientNote: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      note: props.client.note || "",
      tmpNote: props.client.note || "",
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { editing } = this.state;
    if (!prevState.editing && editing) {
      this.textAreaRef.focus();
    }
  }

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };

  handleFocus = (event) => {
    const { length } = this.state.note;
    event.target.setSelectionRange(length, length);
  };

  handleRef = (ref) => {
    this.textAreaRef = ref;
  };

  beginEditing = () => {
    this.setState({
      editing: true,
    });
  };

  saveNote = () => {
    const { client, updateClientNote } = this.props;
    const { tmpNote } = this.state;
    updateClientNote(client.id, tmpNote);

    this.setState((state) => {
      return {
        editing: false,
        note: state.tmpNote,
      };
    });
  };

  render() {
    const { piiMask } = this.context;
    const { editing, note, tmpNote } = this.state;

    return (
      <Form>
        <Form.Field>
          {editing ? (
            <div>
              <div style={{ flexGrow: 1 }}>
                <TextArea
                  className={piiMask("fs-block dd-privacy-mask")}
                  name="tmpNote"
                  rows="2"
                  value={tmpNote}
                  onChange={this.handleChange}
                  onFocus={this.handleFocus}
                  onBlur={this.saveNote}
                  ref={this.handleRef}
                  placeholder="Add a note..."
                />
              </div>
            </div>
          ) : (
            <div
              className={piiMask("fs-block dd-privacy-mask")}
              style={{
                backgroundColor: "#f5f5f5",
                borderRadius: "5px",
                padding: "0.5em 1em",
              }}
              onClick={this.beginEditing}
            >
              {note.length > 0 ? note : "Add a note..."}
            </div>
          )}
        </Form.Field>
      </Form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    advisor: state.hubly.data.advisor,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateClientNote: (clientId, note) => {
      dispatch(UpdateClientNote(clientId, note));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CardNotes);
