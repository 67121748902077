import React from "react";

import { Segment } from "semantic-ui-react";

class WorkflowEmptyState extends React.Component {
  render() {
    return (
      <Segment
        basic
        textAlign="center"
        style={{
          border: "2px dashed #D3D3D3",
          marginTop: "2em",
          color: "#959799",
        }}
      >
        Drag a client tile here
      </Segment>
    );
  }
}

export default WorkflowEmptyState;
