import React from "react";
import PropTypes from "prop-types";

export function MenuLayout({ children }) {
  return (
    <div
      data-test="hub-menu"
      style={{
        width: "calc(100vw - 40px - 1.5em)",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        position: "absolute",
        flexWrap: "nowrap",
        height: "50px",
      }}
    >
      {children}
    </div>
  );
}

MenuLayout.propTypes = {
  children: PropTypes.node,
};

MenuLayout.defaultProps = {
  children: null,
};

export default MenuLayout;
