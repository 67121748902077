import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import makeGetAssigneeTasks from "data/tasks/selector";

import TileIcon from "./TileIcon";

export function TaskStatusTileIcon({ assigneeTasks }) {
  const text = () => {
    const numUncompletedTasks = assigneeTasks.filter(({ completed }) => {
      return !completed;
    }).length;
    const numOfTotalTasks = assigneeTasks.length;

    return `${numOfTotalTasks - numUncompletedTasks}/${numOfTotalTasks}`;
  };

  return <TileIcon icon="check square outline" text={text()} />;
}

TaskStatusTileIcon.propTypes = {
  assigneeTasks: PropTypes.array,
};

TaskStatusTileIcon.defaultProps = {
  assigneeTasks: [],
};

const makeMapStateToProps = () => {
  const getAssigneeTasks = makeGetAssigneeTasks();
  return (state, props) => {
    return {
      assigneeTasks: getAssigneeTasks(state, props),
    };
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(TaskStatusTileIcon);
