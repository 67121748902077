import { useNotify } from "data/messages/hooks";

export function useNotifyWorkflowUpdate() {
  const notify = useNotify("WORKFLOWS");

  return (r) => {
    notify([r.task?.clientWorkflow.workflow.id]);
  };
}

export default useNotifyWorkflowUpdate;
