import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {
  Button,
  Dropdown,
  Grid,
  Header,
  Icon,
  Input,
  Modal,
} from "semantic-ui-react";

import history from "data/history";
import {
  GetClient,
  getOrderedClientName,
  getOrderedFullClientName,
  sortClients,
} from "data/libs/clients";
import { ClientsWithStreamsInStream } from "data/libs/streams";

import { SetConfirmationModal } from "components/ConfirmationModal/actions";
import { PrivacyModeContext } from "components/PrivacyMode/Context";
import colors from "components/StreamModal/colors";

import ClientInStream from "./components/ClientInStream";
import ExportStreamOrTagButton from "../ExportStreamOrTagButton/ExportStreamOrTagButton";
import {
  AddClientToStream,
  DisableConfirmationModal,
  EditStreamAction,
  RemoveClientFromStream,
  RemoveStream,
  ToggleStreamModal,
} from "./actions";

export function StreamModal({ stream, hub, ...props }) {
  const context = useContext(PrivacyModeContext);
  const { piiMask } = context;

  const [adding, setAdding] = useState(false);
  const [numLoading, setNumLoading] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const [streamName, setStreamName] = useState(stream.name);
  const [streamColor, setStreamColor] = useState(stream.color);
  const [clientsInStream, setClientsInStream] = useState([]);

  useEffect(() => {
    setNumLoading(1);
    ClientsWithStreamsInStream(stream.id).then((clientResponse) => {
      setNumLoading(0);
      setClientsInStream(sortClients(clientResponse));
    });
  }, [stream]);

  const updateClientInStreams = ({ targetClient, added = true }) => {
    setClientsInStream((prevData) => {
      if (
        added &&
        prevData.some((c) => {
          return c.id === targetClient.id;
        })
      ) {
        // making sure no duplciate entries are added
        return prevData;
      }
      return sortClients(
        added
          ? [...prevData, targetClient]
          : prevData.filter((client) => {
              return client.id !== targetClient.id;
            })
      );
    });
  };

  const onClientStreamRemoved = (client) => {
    const { removeClientFromStream } = props;
    removeClientFromStream(client, stream, (result) => {
      if (result) {
        updateClientInStreams({ targetClient: client, added: false });
      }
    });
  };

  const addClientCallback = (client) => {
    setSearchInput("");
    setNumLoading(numLoading - 1);
    if (client) {
      updateClientInStreams({ targetClient: client });
    }
  };

  const householdConfirm = (client) => {
    const {
      households,
      setConfirmationModal,
      confirmAddIsDisabled,
      disableConfirmation,
      addClientStream,
    } = props;
    // skip the intercept
    if (confirmAddIsDisabled) {
      setNumLoading(numLoading + 1);
      addClientStream(client.id, stream, addClientCallback);
      return;
    }

    if (client.householdId) {
      const name = client.lastName
        ? `${client.firstName} ${client.lastName}`
        : client.firstName;
      const params = {
        title: `Apply Stream to Household`,
        message: `Would you like to apply this stream to all household members or only to ${name}?`,
        icon: "users",
        buttons: [
          { text: "Cancel" },
          {
            text: `Apply to ${name}`,
            callBack: (checks) => {
              if (checks.disabled) {
                disableConfirmation();
              }
              setNumLoading(numLoading + 1);
              addClientStream(client.id, stream, addClientCallback);
            },
          },
          {
            color: "green",
            text: `Apply to All`,
            callBack: (checks) => {
              if (checks.disabled) {
                disableConfirmation();
              }
              const householdMembers =
                client.householdId in households
                  ? households[client.householdId].householdMembers
                  : [];
              householdMembers.forEach((member) => {
                setNumLoading(numLoading + 1);
                addClientStream(member.client.id, stream, addClientCallback);
              });
            },
          },
        ],
        checks: [
          {
            text: "Add the selected client and don’t ask me again for this session",
            info: "Checking this option will add selected clients immediately to this stream during this browsing session.",
            name: "disabled",
          },
        ],
      };
      setConfirmationModal(params);
    } else {
      setNumLoading(numLoading + 1);
      addClientStream(client.id, stream, addClientCallback);
    }
  };

  const householdIntercept = (clientId) => {
    const { activeClients } = props;
    // Add to active clients if they're not already in the array
    const foundClient = activeClients[clientId];
    if (!foundClient) {
      GetClient(clientId).then((r) => {
        householdConfirm(r);
      });
    } else {
      householdConfirm(foundClient);
    }
  };

  const clientSelected = (e, { value }) => {
    if (e.type === "click" || (e.type === "keydown" && e.key === "Enter")) {
      householdIntercept(value);
    }
  };

  const createClientList = (clients) => {
    const { allClientNames } = props;
    const options = [];
    let sortedClients = sortClients(allClientNames, hub);
    sortedClients = sortedClients.filter((client) => {
      const clientIds = (clients || []).map((c) => {
        return c.id;
      });
      return !clientIds.includes(client.id);
    });
    sortedClients.forEach((client) => {
      options.push({
        text: getOrderedFullClientName(client, hub),
        key: client.id,
        value: client.id,
        onClick: clientSelected,
      });
    });
    return options;
  };

  const deleteStream = () => {
    const { removeStream, toggleStreamModal } = props;

    setNumLoading(1);
    removeStream(stream, (result) => {
      if (result) {
        toggleStreamModal();
      } else {
        setNumLoading(0);
      }
    });
  };

  const confirmDelete = () => {
    const { setConfirmationModal } = props;
    const params = {
      title: `Delete "${stream.name}" Stream`,
      message: `This will delete "${stream.name}" and remove this stream from all associated clients. This cannot be undone.`,
      icon: "delete",
      buttons: [
        { text: "Cancel" },
        {
          text: "Delete Stream",
          callBack: deleteStream,
          color: "red",
        },
      ],
    };
    setConfirmationModal(params);
  };

  const editStream = () => {
    const { toggleStreamModal, editStreamAction } = props;

    setNumLoading(1);
    editStreamAction(stream, { streamName, streamColor }, (result) => {
      if (result) {
        toggleStreamModal();
      } else {
        setNumLoading(0);
      }
    });
  };

  const handleKeyUp = (e) => {
    // handles key press for adding streams
    if (e.keyCode === 13) {
      // Enter pressed
      editStream();
    }
  };

  const sortClientStreams = (a, b) => {
    if (a.id === stream.id) return -1;
    if (b.id === stream.id) return 1;
    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
    return 0;
  };

  const viewClientCard = (clientId) => {
    const { toggleStreamModal } = props;
    history.push(`/hub/${hub.hubId}/clients/${clientId}`);
    toggleStreamModal();
  };

  const { isManageStream, toggleStreamModal } = props;
  const clientOptions = createClientList(clientsInStream);
  const disableSaving =
    numLoading > 0 || !streamName || streamName.match(/^ *$/);
  return (
    <Modal
      data-test="stream-modal"
      size="small"
      onClose={() => {
        toggleStreamModal();
      }}
      open
    >
      <Modal.Header>
        <Icon
          name="delete"
          link
          color="grey"
          style={{ float: "right", position: "relative" }}
          onClick={() => {
            toggleStreamModal();
          }}
        />
        {isManageStream ? "Manage Streams" : "Edit Stream"}
      </Modal.Header>
      <Modal.Content style={{ minHeight: "65vh" }}>
        <Header as="h5" content="Name" />
        <Input
          className="search"
          fluid
          value={streamName}
          onChange={(e) => {
            setStreamName(e.target.value);
          }}
          onKeyUp={handleKeyUp}
        />
        <Header as="h5" content="Color" style={{ marginBottom: "0.5em" }} />
        <Grid data-test="stream-color-list" columns={10}>
          {colors.map((color) => {
            return (
              <Grid.Column key={color}>
                {color === streamColor ? (
                  <Icon
                    bordered
                    link
                    name="check"
                    style={{
                      color: "#FFFFFF",
                      backgroundColor: color,
                      borderRadius: "0.5em",
                    }}
                  />
                ) : (
                  <Icon
                    bordered
                    link
                    style={{ backgroundColor: color, borderRadius: "0.5em" }}
                    onClick={() => {
                      setStreamColor(color);
                    }}
                  />
                )}
              </Grid.Column>
            );
          })}
        </Grid>
        {isManageStream && (
          <React.Fragment>
            <Header as="h5" style={{ marginBottom: "0.5em" }}>
              Members ({clientsInStream.length})
            </Header>
            <div style={{ paddingBottom: "1.5em" }}>
              {adding ? (
                <React.Fragment>
                  <Dropdown
                    className={piiMask("fs-block dd-privacy-mask")}
                    disabled={numLoading > 0}
                    loading={numLoading > 0}
                    onBlur={() => {
                      setSearchInput("");
                    }}
                    onChange={clientSelected}
                    onSearchChange={(e) => {
                      setSearchInput(e.target.value);
                    }}
                    options={clientOptions}
                    placeholder="Search for a client"
                    search
                    searchInput={{
                      autoComplete: "no-autofill",
                      autoFocus: true,
                    }}
                    selection
                    value={searchInput}
                  />
                  <Icon
                    color="grey"
                    link
                    name="cancel"
                    onClick={() => {
                      setAdding(false);
                    }}
                    style={{
                      transform: "translate(3px, 0px)",
                      fontSize: "12pt",
                    }}
                  />
                </React.Fragment>
              ) : (
                <Button
                  basic
                  compact
                  disabled={numLoading > 0}
                  loading={numLoading > 0}
                  content="Add"
                  onClick={() => {
                    setAdding(true);
                  }}
                />
              )}
            </div>
            <Grid
              data-test="stream-member-list"
              columns="equal"
              style={{ maxHeight: "25em", overflowY: "auto" }}
            >
              {clientsInStream.map((client) => {
                const clientCopy = { ...client };
                clientCopy.fullName = getOrderedClientName(client, hub);
                clientCopy.streams.sort(sortClientStreams);
                return (
                  <ClientInStream
                    className={piiMask("fs-block dd-privacy-mask")}
                    client={clientCopy}
                    key={client.id}
                    viewClientCard={viewClientCard}
                    onClientStreamRemoved={onClientStreamRemoved}
                  />
                );
              })}
            </Grid>
          </React.Fragment>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button
          compact
          negative
          onClick={() => {
            confirmDelete();
          }}
          loading={numLoading > 0}
          disabled={numLoading > 0}
        >
          Delete
        </Button>
        <Button
          compact
          positive
          onClick={() => {
            editStream();
          }}
          loading={numLoading > 0}
          disabled={disableSaving}
        >
          Save
        </Button>
        <ExportStreamOrTagButton style={{ float: "left" }} stream={stream} />
      </Modal.Actions>
    </Modal>
  );
}

StreamModal.propTypes = {
  activeClients: PropTypes.object.isRequired,
  allClientNames: PropTypes.array.isRequired,
  households: PropTypes.object.isRequired,
  hub: PropTypes.shape({
    hubId: PropTypes.string.isRequired,
    streams: PropTypes.array.isRequired,
  }).isRequired,
  setConfirmationModal: PropTypes.func.isRequired,
  isManageStream: PropTypes.bool.isRequired,
  stream: PropTypes.object.isRequired,
  toggleStreamModal: PropTypes.func.isRequired,
  confirmAddIsDisabled: PropTypes.bool.isRequired,
  disableConfirmation: PropTypes.func.isRequired,
  removeClientFromStream: PropTypes.func.isRequired,
  addClientStream: PropTypes.func.isRequired,
  removeStream: PropTypes.func.isRequired,
  editStreamAction: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    activeClients: state.hubly.data.hub.clients.activeClients,
    allClientNames: state.hubly.data.hub.clients.allClientNames,
    households: state.hubly.data.hub.households,
    hub: state.hubly.data.hub.selected.hub,
    isManageStream: state.hubly.streams.isManageStream,
    stream: state.hubly.streams.stream,
    confirmAddIsDisabled: state.hubly.streams.confirmAddDisabled,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addClientStream: (clientId, stream, callback) => {
      dispatch(AddClientToStream(clientId, stream, callback));
    },
    editStreamAction: (stream, updatedParams, callback) => {
      dispatch(EditStreamAction(stream, updatedParams, callback));
    },
    removeStream: (stream, callback) => {
      dispatch(RemoveStream(stream, callback));
    },
    removeClientFromStream: (client, stream, callback) => {
      dispatch(RemoveClientFromStream(client, stream, callback));
    },
    setConfirmationModal: (id) => {
      dispatch(SetConfirmationModal(id));
    },
    toggleStreamModal: (streamId) => {
      dispatch(ToggleStreamModal(streamId));
    },
    disableConfirmation: () => {
      dispatch(DisableConfirmationModal());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StreamModal);
