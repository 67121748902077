export const ToggleAssignedAdvisorPopup = (
  objectId,
  isOpen = false,
  objectType = "TASK",
  defaultDropdownValue = undefined
) => {
  return {
    type: "TOGGLE_ASSIGNED_ADVISOR_POPUP",
    objectId: objectId,
    isOpen: isOpen,
    objectType: objectType,
    defaultDropdownValue: defaultDropdownValue,
  };
};
export default ToggleAssignedAdvisorPopup;
