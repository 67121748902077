import React from "react";
import PropTypes from "prop-types";

import { Icon } from "semantic-ui-react";

import FilterLayout from "components/FilterLayout";
import Selector from "components/Selector";

import reminderOptions, { filterReminder } from "./helper";

export function ReminderFilters({ reminderTypes, onUpdateFilter }) {
  const filteredOptions = reminderOptions
    .map(({ icon, color, ...options }) => {
      return {
        ...options,
        color: color,
        icon: <Icon name={icon} style={{ color: color }} />,
      };
    })
    .filter((option) => {
      return filterReminder(option, reminderTypes);
    });
  return (
    <FilterLayout
      title="Status"
      onClearFilter={() => {
        onUpdateFilter({ reminderTypes: [] });
      }}
    >
      <Selector
        name="Reminders"
        dropdownProps={{
          placeholder: "Select workflow status",
          noResultsMessage: "No matching reminder types",
          renderLabel: (item, _, defaultLabelProps) => {
            defaultLabelProps.content = item.text;
            defaultLabelProps.style = {
              backgroundColor: item.color,
              color: "white",
            };
            return defaultLabelProps;
          },
        }}
        selection={filteredOptions}
        selected={reminderTypes}
        onSelect={(selected) => {
          onUpdateFilter({ reminderTypes: selected });
        }}
      />
    </FilterLayout>
  );
}

ReminderFilters.defaultProps = {
  reminderTypes: [],
};

ReminderFilters.propTypes = {
  reminderTypes: PropTypes.arrayOf(PropTypes.string),
  onUpdateFilter: PropTypes.func.isRequired,
};

export default ReminderFilters;
