import React from "react";
import PropTypes from "prop-types";

import { Button, Icon } from "semantic-ui-react";

export function WorkflowMenuButton({ totalWorkflow }) {
  return (
    <Button
      id="filter-workflow-button"
      basic
      primary={totalWorkflow > 0}
      style={{ margin: "0 0.5em 0 0", minWidth: "max-content" }}
    >
      <Icon name="list ul" />
      {totalWorkflow > 0
        ? `${totalWorkflow} Workflow${totalWorkflow !== 1 ? "s" : ""}`
        : "Workflows"}
    </Button>
  );
}

WorkflowMenuButton.propTypes = {
  totalWorkflow: PropTypes.number.isRequired,
};

export default WorkflowMenuButton;
