import React from "react";
import PropTypes from "prop-types";

import { Header, Icon } from "semantic-ui-react";

import { Popper, WithPopper } from "components/Popper";

import { useAssigneeOptions } from "scenes/Hubly/components/Workspace/components/Assigner/components";
import { ConfirmationDropdown } from "scenes/Hubly/components/Workspace/components/Assigner/components/UserSelector/components";

export function AssigneeSelector({
  selected,
  loading,
  assignees,
  onSelect,
  onClear,
}) {
  const assigneeOptions = useAssigneeOptions(assignees);
  const options = [
    {
      key: "SHOW_ALL",
      value: "SHOW_ALL",
      text: "Any User",
      icon: "user circle outline",
    },
    {
      key: "CURRENT_USER",
      value: "CURRENT_USER",
      text: "Current User",
      icon: "user circle outline",
    },
  ].concat(assigneeOptions);

  return (
    <div style={{ width: "max-content" }}>
      <div style={{ display: "flex" }}>
        <Header as="h4" style={{ marginBottom: "1em" }}>
          Assignee
        </Header>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ marginRight: "0.5em" }}>
          <ConfirmationDropdown
            data-test="assign-advisor-popup-dropdown"
            placeholder="Filter by assignee"
            position="bottom right"
            loading={loading}
            showSaveButton={false}
            initValue={
              options.find(({ key }) => {
                return key === selected;
              })?.key || "CURRENT_USER"
            }
            onSelect={(selectedKey) => {
              onSelect(
                assignees.find(({ id }) => {
                  return id === selectedKey;
                }) || { id: selectedKey }
              );
            }}
            items={options}
          />
        </div>
        <Popper
          content="Clear"
          on="hover"
          position="top center"
          trigger={
            <Icon
              name="delete"
              link
              color="grey"
              disabled={loading}
              onClick={onClear}
            />
          }
        />
      </div>
    </div>
  );
}

AssigneeSelector.defaultProps = {
  selected: "CURRENT_USER",
  loading: false,
};

AssigneeSelector.propTypes = {
  selected: PropTypes.string,
  loading: PropTypes.bool,
  assignees: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      group: PropTypes.bool,
    })
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
};

export default WithPopper(AssigneeSelector);
