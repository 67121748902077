import React from "react";
import PropTypes from "prop-types";

import { Icon } from "semantic-ui-react";

export function ReminderIcon({
  lineThrough,
  highlightColor,
  iconColor,
  textColor,
  text,
}) {
  return (
    <span
      style={{
        backgroundColor: highlightColor,
        padding: "0.1em 0.5em",
        borderRadius: "3px",
      }}
    >
      <Icon name="bell outline" color={iconColor} />
      <span
        style={{
          color: textColor,
          textDecoration: lineThrough ? "line-through" : "none",
          fontWeight: "bold",
        }}
      >
        {text}
      </span>
    </span>
  );
}

ReminderIcon.propTypes = {
  highlightColor: PropTypes.string.isRequired,
  iconColor: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  lineThrough: PropTypes.bool,
};
ReminderIcon.defaultProps = {
  lineThrough: false,
};

export default ReminderIcon;
