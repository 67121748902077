import { keyBy, remove, values } from "lodash";

import { SetActiveClient } from "data/hub/clients/actions";
import {
  EditClientTask,
  GetAllClientWorkflowsByClientId,
  SetClientWorkflow,
  UpdateClientWorkFlows,
  UpdateTaskReminder,
} from "data/hub/clientWorkflows/actions";
import { SetWorkflow } from "data/hub/workflows/actions";
import { EditClientWorkflow } from "data/libs/clientWorkflows";
import { AddReminder, GetReminders } from "data/libs/reminders";
import {
  AddTaskCondition,
  DeleteTaskCondition,
  EditTaskCondition,
} from "data/libs/taskConditions";
import {
  BulkUpdateTasks,
  CreateTaskTimeEstimate,
  DeleteTaskTimeEstimate,
  EditTask,
  EditTaskTimeEstimate,
} from "data/libs/tasks";
import {
  InsertReminder,
  RemoveReminder,
  SetReminders,
} from "data/reminders/actions";

import { SetAlert } from "components/Alerts/actions";

export const SetCommentTaskId = (taskId, clientWorkflowId = "") => {
  return {
    type: "SET_COMMENT_TASK_ID",
    selectedTaskIdForComment: taskId,
    clientWorkflowId: clientWorkflowId,
  };
};

export const ToggleTaskConditionPopup = (taskId = "", isOpen = false) => {
  return {
    type: "TOGGLE_TASK_CONDITION_POPUP",
    taskId: taskId,
    isOpen: isOpen,
  };
};

export const ToggleTaskDetailsModal = (taskId = "", isOpen = false) => {
  return {
    type: "TOGGLE_TASK_DETAILS_MODAL",
    taskId: taskId,
    isOpen: isOpen,
  };
};

export const ToggleTaskEmailTemplateModal = (taskId = "", isOpen = false) => {
  return {
    type: "TOGGLE_TASK_EMAIL_TEMPLATE_MODAL",
    taskId: taskId,
    isOpen: isOpen,
  };
};

export const ToggleTaskFutureReminderPopup = (taskId = "", isOpen = false) => {
  return {
    type: "TOGGLE_TASK_FUTURE_REMINDER_POPUP",
    taskId: taskId,
    isOpen: isOpen,
  };
};

export const ToggleTaskTimeCompletedAtModal = (taskId = "", isOpen = false) => {
  return {
    type: "TOGGLE_TASK_TIME_COMPLETED_AT_MODAL",
    taskId: taskId,
    isOpen: isOpen,
  };
};

export const ToggleTaskTimeEstimatePopup = (taskId = "", isOpen = false) => {
  return {
    type: "TOGGLE_TASK_TIME_ESTIMATE_POPUP",
    taskId: taskId,
    isOpen: isOpen,
  };
};

export const ToggleTaskTimeSpentPopup = (taskId = "", isOpen = false) => {
  return {
    type: "TOGGLE_TASK_TIME_SPENT_POPUP",
    taskId: taskId,
    isOpen: isOpen,
  };
};

export function SetAllTasksComplete(clientWorkflow, callback) {
  return (dispatch, getState) => {
    const clientWorkflowCopy = { ...clientWorkflow };

    const updates = [];
    clientWorkflowCopy.tasks.forEach((task) => {
      const update = {
        id: task.id,
        completed: true,
      };
      if (!task.assignedAdvisor) {
        // assign advisor to task if none already assigned
        update.assignedAdvisorId = getState().hubly.data.advisor.id;
      }
      updates.push(update);
    });

    const request = { tasks: updates };

    BulkUpdateTasks(request, null, clientWorkflow.clientId)
      .then((tasks) => {
        const { reminders } = getState().hubly.data;
        const remindersCopy = Object.assign([], reminders);
        tasks.forEach((task) => {
          task.reminders.forEach((reminder) => {
            RemoveReminder(reminder.id, remindersCopy);
            InsertReminder(reminder, remindersCopy);
          });
        });
        clientWorkflowCopy.tasks = tasks;
        dispatch(SetClientWorkflow(clientWorkflowCopy));
        dispatch(SetReminders(remindersCopy));
        dispatch(
          SetAlert({
            type: "success",
            text: `Successfully completed all tasks.`,
          })
        );
        if (callback) callback(true);
      })
      .catch((error) => {
        console.error(error);
        dispatch(
          SetAlert({
            type: "error",
            text: `Failed to mark all task complete.`,
          })
        );
        if (callback) callback(false);
      });
  };
}

export function conditionallyUpdateAllReminders(
  // TODO change based on response - will be covered as phase 2 of 1795
  { fetchAll = true },
  { clientId, getState, dispatch = () => {} },
  onElse = () => {}
) {
  return fetchAll
    ? GetReminders(getState()?.hubly?.data?.hub?.selected?.hub, {
        client_id__isnull: false,
        limit: 999999999,
        ordering: "time",
        active: true,
      }).then(({ results }) => {
        dispatch(SetReminders(results));
        dispatch(
          SetActiveClient({
            ...getState()?.hubly?.data?.hub?.clients?.activeClients?.[clientId],
            reminders: [
              ...results?.filter((r) => {
                return r.clientId === clientId;
              }),
            ],
          })
        );
      })
    : onElse();
}

export function SetTaskComplete(
  clientWorkflow,
  taskId,
  editedTime = null,
  onSuccess
) {
  return (dispatch, getState) => {
    const { hub } = getState().hubly.data.hub.selected;
    const foundTask = clientWorkflow.tasks.find((task) => {
      return task.id === taskId;
    });

    if (!foundTask) {
      console.warn("SetTaskComplete, Task ID ", taskId, " does not exist");
      return;
    }

    const request = {
      completed: true,
      advisorDefinedCompletedAt: editedTime,
    };

    if (!foundTask.assignedAdvisor) {
      // assign advisor to task if none already assigned
      request.assignedAdvisorId = getState().hubly.data.advisor.id;
    }
    const { clientId } = clientWorkflow;
    EditTask(taskId, request, clientId)
      .then((response) => {
        dispatch(EditClientTask(response));

        conditionallyUpdateAllReminders(
          response,
          { clientId, dispatch, getState },
          () => {
            const taskReminders = response.reminders;
            const { reminders } = getState().hubly.data;
            const remindersCopy = Object.assign([], reminders);
            taskReminders.forEach((reminder) => {
              RemoveReminder(reminder.id, remindersCopy);
              InsertReminder(reminder, remindersCopy);
            });
            dispatch(SetReminders(remindersCopy));
          }
        );
        GetAllClientWorkflowsByClientId(hub, clientId).then((cws) => {
          dispatch(UpdateClientWorkFlows(cws));
        });

        dispatch(
          SetAlert({ type: "success", text: `Successfully updated task.` })
        );
        if (onSuccess) onSuccess();
      })
      .catch((error) => {
        console.error(error);
        dispatch(
          SetAlert({ type: "error", text: `Failed to mark task complete.` })
        );
      });
  };
}

export function SetTaskIncomplete(clientWorkflow, taskId) {
  return (dispatch, getState) => {
    const { hub } = getState().hubly.data.hub.selected;
    const foundClientWorkflow = { ...clientWorkflow };
    const foundTaskIndex = foundClientWorkflow.tasks.findIndex((task) => {
      return task.id === taskId;
    });
    if (foundTaskIndex < 0) {
      console.warn("SetTaskIncomplete, Task ID ", taskId, " does not exist");
      return;
    }
    foundClientWorkflow.tasks[foundTaskIndex].completed = false;
    foundClientWorkflow.tasks[foundTaskIndex].completedAt = null;
    foundClientWorkflow.tasks[foundTaskIndex].advisorDefinedCompletedAt = null;
    dispatch(SetClientWorkflow(foundClientWorkflow));
    const request = {
      completed: false,
      advisorDefinedCompletedAt: null,
    };
    const { clientId } = clientWorkflow;
    EditTask(taskId, request, clientId)
      .then((response) => {
        conditionallyUpdateAllReminders(
          response,
          { clientId, dispatch, getState },
          () => {
            const taskReminders = response.reminders;
            const { reminders } = getState().hubly.data;
            const remindersCopy = Object.assign([], reminders);
            taskReminders.forEach((reminder) => {
              RemoveReminder(reminder.id, remindersCopy);
              InsertReminder(reminder, remindersCopy);
            });
            dispatch(SetReminders(remindersCopy));
          }
        );
        GetAllClientWorkflowsByClientId(hub, clientId).then((cws) => {
          dispatch(UpdateClientWorkFlows(cws));
        });
      })
      .catch((error) => {
        console.error(error);
        dispatch(SetClientWorkflow(clientWorkflow));
        dispatch(
          SetAlert({ type: "error", text: `Failed to mark task as complete.` })
        );
      });

    //  Also need to set client workflow to incomplete, if currently completed, since we set one task to incomplete
    if (foundClientWorkflow.completed) {
      foundClientWorkflow.completed = false;
      dispatch(SetClientWorkflow(foundClientWorkflow));
      EditClientWorkflow(clientWorkflow.id, { complete: false })
        .then((response) => {
          dispatch(
            SetAlert({
              type: "success",
              text: `Successfully set ${foundClientWorkflow.name} to incomplete`,
            })
          );
        })
        .catch((error) => {
          console.error(error);
          dispatch(SetClientWorkflow(clientWorkflow));
          dispatch(
            SetAlert({
              type: "error",
              text: `Failed to set workflow incomplete.`,
            })
          );
        });
    }
  };
}

export function AddReminderToTask(
  clientWorkflowId,
  taskId,
  reminderTime,
  title
) {
  return (dispatch, getState) => {
    const clientWorkflow = {
      ...getState().hubly.data.hub.clientWorkflows[clientWorkflowId],
    };
    const client =
      getState().hubly.data.hub.clients.activeClients[clientWorkflow.clientId];
    const clientCopy = { ...client };
    const oldClient = { ...client };
    const request = {
      taskId: taskId,
      time: reminderTime,
      title: title,
      clientId: clientCopy.id,
      clientWorkflowId: clientWorkflowId,
    };
    AddReminder(request)
      .then((response) => {
        clientCopy.reminders.push(response);
        try {
          const foundTask = clientWorkflow.tasks.find((task) => {
            return task.id === taskId;
          });
          if (foundTask) {
            foundTask.reminders.push(response);
          }
        } catch (e) {
          console.error(e);
        }

        // add the new reminder to the state
        const { reminders } = getState().hubly.data;
        const remindersCopy = Object.assign([], reminders);
        InsertReminder(response, remindersCopy);
        dispatch(SetReminders(remindersCopy));
        dispatch(SetActiveClient(clientCopy));
        dispatch(SetClientWorkflow(clientWorkflow));
        dispatch(UpdateTaskReminder(response));
        dispatch(
          SetAlert({ type: "success", text: `Successfully added reminder` })
        );
      })
      .catch((error) => {
        console.error(error);
        dispatch(SetActiveClient(oldClient));
        dispatch(
          SetAlert({ type: "error", text: `Failed to create reminder.` })
        );
      });
  };
}

export function RemoveCommentTaskId(comment, task) {
  return (dispatch, getState) => {
    const clientWorkflow = {
      ...getState().hubly.data.hub.clientWorkflows[task.clientWorkflowId],
    };
    if (clientWorkflow && clientWorkflow.tasks) {
      const foundTaskIndex = clientWorkflow.tasks.findIndex((foundTask) => {
        return foundTask.id === task?.id;
      });
      if (foundTaskIndex < 0) {
        console.warn(
          "RemoveCommentTaskId, Task ID ",
          task?.id,
          " does not exist"
        );
        return;
      }
      clientWorkflow.tasks[foundTaskIndex].comments = values(
        remove(
          keyBy(clientWorkflow.tasks[foundTaskIndex].comments, "id"),
          keyBy([comment], "id")
        )
      );
      dispatch(SetClientWorkflow(clientWorkflow));
    }
  };
}

export function ApplyTaskCondition(
  workflowId,
  taskId,
  streams,
  tags,
  conditionType,
  months,
  callback
) {
  return (dispatch, getState) => {
    const request = {
      streams,
      tags,
      conditionType,
      months,
    };
    AddTaskCondition(taskId, request)
      .then((response) => {
        try {
          const foundWorkflow = {
            ...getState().hubly.data.hub.workflows[workflowId],
          };
          foundWorkflow.tasks.find((task) => {
            return taskId === task.id;
          }).taskCondition = response;
          dispatch(SetWorkflow(foundWorkflow));
        } catch (e) {
          console.error(e);
          console.warn("Failed to add condition");
          dispatch(
            SetAlert({ type: "error", text: "Failed to add condition" })
          );
          if (callback) callback(false);
        }
        if (callback) callback(true);
      })
      .catch((e) => {
        console.warn(e);
        if (callback) callback(false);
      });
  };
}

export function AlterTaskCondition(
  workflowId,
  taskId,
  conditionId,
  streams,
  tags,
  conditionType,
  months,
  callback
) {
  return (dispatch, getState) => {
    const request = {
      streams,
      tags,
      conditionType,
      months,
    };
    EditTaskCondition(taskId, conditionId, request)
      .then((response) => {
        try {
          const foundWorkflow = {
            ...getState().hubly.data.hub.workflows[workflowId],
          };
          foundWorkflow.tasks.find((task) => {
            return taskId === task.id;
          }).taskCondition = response;
          dispatch(SetWorkflow(foundWorkflow));
        } catch (e) {
          console.error(e);
          console.warn("Failed to edit condition");
          dispatch(
            SetAlert({ type: "error", text: "Failed to edit condition" })
          );
          if (callback) callback(false);
        }
        if (callback) callback(true);
      })
      .catch((e) => {
        console.warn(e);
        if (callback) callback(false);
      });
  };
}

export function RemoveTaskCondition(
  workflowId,
  taskId,
  conditionTypeId,
  callback
) {
  return (dispatch, getState) => {
    DeleteTaskCondition(taskId, conditionTypeId)
      .then((response) => {
        try {
          const foundWorkflow = {
            ...getState().hubly.data.hub.workflows[workflowId],
          };
          foundWorkflow.tasks.find((task) => {
            return taskId === task.id;
          }).taskCondition = null;
          dispatch(SetWorkflow(foundWorkflow));
        } catch (e) {
          console.error(e);
          console.warn("Failed to remove condition.");
          dispatch(
            SetAlert({ type: "error", text: "Failed to remove condition" })
          );
          if (callback) callback(false);
        }
        if (callback) callback(true);
      })
      .catch((e) => {
        console.warn(e);
        if (callback) callback(false);
      });
  };
}

export const AddTimeEstimateToTask = (
  task,
  workflow,
  hours,
  minutes,
  callback
) => {
  return (dispatch, getState) => {
    const request = {
      hours: parseInt(hours) || 0,
      minutes: parseInt(minutes) || 0,
    };

    CreateTaskTimeEstimate(task.id, request)
      .then((response) => {
        const workflowCopy = {
          ...getState().hubly.data.hub.workflows[workflow.id],
        };
        if (!workflowCopy) {
          console.warn(
            "AddTimeEstimateToTask, Workflow ID ",
            workflow.id,
            " does not exist"
          );
          return;
        }

        const foundTask = workflowCopy.tasks.find((t) => {
          return t.id === task.id;
        });

        if (!foundTask) {
          console.warn(
            "AddTimeEstimateToTask, Task ID ",
            task.id,
            " does not exist"
          );
          return;
        }

        foundTask.timeEstimates = [response];
        dispatch(SetWorkflow(workflowCopy));

        if (callback) {
          callback();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const UpdateTimeEstimate = (
  task,
  workflow,
  estimate,
  hours,
  minutes,
  callback
) => {
  return (dispatch, getState) => {
    const request = {
      hours: parseInt(hours) || 0,
      minutes: parseInt(minutes) || 0,
    };

    EditTaskTimeEstimate(task.id, estimate.id, request)
      .then((response) => {
        const workflowCopy = {
          ...getState().hubly.data.hub.workflows[workflow.id],
        };
        if (!workflowCopy) {
          console.warn(
            "UpdateTimeEstimate, Workflow ID ",
            workflow.id,
            " does not exist"
          );
          return;
        }

        const foundTask = workflowCopy.tasks.find((t) => {
          return t.id === task.id;
        });

        if (!foundTask) {
          console.warn(
            "UpdateTimeEstimate, Task ID ",
            task.id,
            " does not exist"
          );
          return;
        }

        foundTask.timeEstimates = [response];
        dispatch(SetWorkflow(workflowCopy));

        if (callback) {
          callback();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const RemoveAllTimeEstimates = (task, workflow, callback) => {
  return (dispatch, getState) => {
    const deletePromises = [];

    (task.timeEstimates || []).forEach((estimate) => {
      deletePromises.push(
        DeleteTaskTimeEstimate(task.id, estimate.id)
          .then((response) => {
            return response;
          })
          .catch(() => {
            return null;
          })
      );
    });

    Promise.all(deletePromises).then(() => {
      const workflowCopy = {
        ...getState().hubly.data.hub.workflows[workflow.id],
      };
      if (!workflowCopy) {
        console.warn(
          "RemoveAllTimeEstimates, Workflow ID ",
          workflow.id,
          " does not exist"
        );
        return;
      }

      const foundTask = workflowCopy.tasks.find((t) => {
        return t.id === task.id;
      });

      if (!foundTask) {
        console.warn(
          "RemoveAllTimeEstimates, Task ID ",
          task.id,
          " does not exist"
        );
        return;
      }

      foundTask.timeEstimates = [];
      dispatch(SetWorkflow(workflowCopy));

      if (callback) {
        callback();
      }
    });
  };
};
