const toInitials = (fullName, len = 3) => {
  const cleanFullName = fullName.trim();

  if (!cleanFullName.includes(" ")) {
    return cleanFullName
      .split("")
      .filter((_, i) => {
        return i < len;
      })
      .map((c) => {
        return c.toUpperCase();
      })
      .join("");
  }

  return cleanFullName
    .split(" ")
    .filter((n) => {
      return n;
    })
    .filter((_, i) => {
      return i < len;
    })
    .map((n) => {
      return n.charAt(0);
    })
    .map((i) => {
      return i.toUpperCase();
    })
    .join("");
};

const toFullName = (person) => {
  if (!person) return "";
  if ("firstName" in person || "lastName" in person) {
    return `${person.firstName?.trim() || ""} ${person.lastName?.trim() || ""}`;
  }
  return person?.name || "";
};

export function useInitials(name) {
  const fullName = toFullName(name);
  const initials = toInitials(fullName);

  return {
    empty: !initials,
    initials: initials,
    fullName: fullName,
  };
}

export default useInitials;
