import { API } from "aws-amplify";

import { getAPIReq } from "./authLib";

export const CreateSync = async (request) => {
  const req = await getAPIReq(request);
  return API.post("HublyAPI", `/sync/`, req);
};

export const GetSync = async (id) => {
  const req = await getAPIReq();
  return API.get("HublyAPI", `/sync/${id}/`, req);
};
