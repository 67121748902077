import React from "react";
import DatePicker from "react-datepicker/es";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Button, Header, Icon, Input, Popup, Select } from "semantic-ui-react";

import "react-datepicker/dist/react-datepicker.css";

import { SetAlert } from "components/Alerts/actions";
import { ToggleTaskFutureReminderPopup } from "components/Task/actions";

import {
  AddReminderToWorkflow,
  AddSequentialReminderToWorkflow,
  DeleteWorkflowReminder,
  DeleteWorkflowSequentialReminder,
  EditWorkflowReminder,
} from "./actions";

class TaskFutureReminder extends React.Component {
  static defaultProps = {
    addingTask: false,
    editTaskState: () => {},
    modalTaskId: "",
    isNextWorkflow: false,
    popupToLeft: false,
    editNextWorkflowReminder: () => {},
    addNextWorkflowReminder: () => {},
    removeNextWorkflowReminder: () => {},
  };

  static propTypes = {
    addingTask: PropTypes.bool,
    addReminderToWorkflow: PropTypes.func.isRequired,
    editReminder: PropTypes.func.isRequired,
    editTaskState: PropTypes.func,
    isTaskFutureReminderOpen: PropTypes.bool.isRequired,
    modalTaskId: PropTypes.string,
    removeReminderFromWorkflow: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired,
    task: PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      reminders: PropTypes.array,
      order: PropTypes.string.isRequired,
      options: PropTypes.object,
    }).isRequired,
    toggleTaskFutureReminderPopup: PropTypes.func.isRequired,
    workflow: PropTypes.shape({
      id: PropTypes.string.isRequired,
      reminders: PropTypes.array,
    }).isRequired,
    isNextWorkflow: PropTypes.bool,
    popupToLeft: PropTypes.bool,
    editNextWorkflowReminder: PropTypes.func,
    addNextWorkflowReminder: PropTypes.func,
    removeNextWorkflowReminder: PropTypes.func,
  };

  constructor(props) {
    super(props);
    const { task } = props;
    // Set default values, but set with values from first reminder if a reminder has already been added
    const defaultDate = new Date();
    defaultDate.setHours(8);
    defaultDate.setMinutes(0);
    defaultDate.setSeconds(0);
    defaultDate.setMilliseconds(0);

    const initSequentialReminderNode = () => {
      const { time, type, number = 1 } = task.options?.sequentialReminder;

      const futureReminderDelay =
        type === "immediate" ? "immediate" : "delayed";
      let futureReminderType = type || "day";
      if (futureReminderType === "immediate") futureReminderType = "day";

      return {
        futureReminderNumber: number,
        futureReminderType: futureReminderType,
        futureReminderTime: time ? new Date(time) : defaultDate,
        loading: false,
        futureReminderDelay: futureReminderDelay,
        reminderTypeSelection: "sequential",
      };
    };

    const initLegacy = () => {
      const foundReminder = task.reminders ? task.reminders[0] : task;
      const futureReminderNumber =
        foundReminder && foundReminder.futureReminderNumber
          ? foundReminder.futureReminderNumber
          : 1;
      const futureReminderTime =
        foundReminder && foundReminder.futureReminderTime
          ? new Date(foundReminder.futureReminderTime)
          : defaultDate;
      const futureReminderDelay =
        foundReminder && foundReminder.futureReminderType === "immediate"
          ? "immediate"
          : "delayed";
      let futureReminderType =
        foundReminder && foundReminder.futureReminderType
          ? foundReminder.futureReminderType
          : "day";
      if (futureReminderType === "immediate") futureReminderType = "day";

      return {
        futureReminderNumber: futureReminderNumber,
        futureReminderType: futureReminderType,
        futureReminderTime: futureReminderTime,
        loading: false,
        futureReminderDelay: futureReminderDelay,
      };
    };

    const initState = task.options?.sequentialReminder?.type
      ? initSequentialReminderNode
      : initLegacy;

    this.state = {
      reminderTypeSelection: "futureReminder",
      ...initState(task, defaultDate),
    };
  }

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };

  handleTimeChange = (date) => {
    this.setState({
      futureReminderTime: date,
    });
  };

  isOpen = () => {
    const { isTaskFutureReminderOpen, modalTaskId, task } = this.props;
    return task.id === modalTaskId && isTaskFutureReminderOpen;
  };

  callback = (success, message) => {
    const { setAlert, toggleTaskFutureReminderPopup } = this.props;
    this.setState({ loading: false });
    if (!success) {
      setAlert({
        type: "error",
        text: message,
      });
    } else {
      toggleTaskFutureReminderPopup();
    }
  };

  addReminder = () => {
    const {
      addingTask,
      addReminderToWorkflow,
      editReminder,
      editTaskState,
      task,
      workflow,
      isNextWorkflow,
      addNextWorkflowReminder,
      editNextWorkflowReminder,
    } = this.props;
    const {
      futureReminderNumber,
      futureReminderTime,
      futureReminderDelay,
      reminderTypeSelection,
    } = this.state;
    let { futureReminderType } = this.state;
    const immediate = futureReminderDelay === "immediate";

    if (!immediate && futureReminderNumber < 1) {
      // Make sure you can't have 0 or negative days after
      return;
    }
    // Make sure you can only set days/weeks, shouldn't be able to hit this state unless there's an issue or new feature
    if (
      !immediate &&
      futureReminderType !== "hour" &&
      futureReminderType !== "day" &&
      futureReminderType !== "week" &&
      futureReminderType !== "month"
    ) {
      return;
    }

    if (immediate) {
      futureReminderType = "immediate";
    }

    this.setState({ loading: true });
    if (addingTask) {
      editTaskState(
        "reminders",
        [{ futureReminderType, futureReminderNumber, futureReminderTime }],
        "edit"
      );
      this.callback(true);
    } else if (!addingTask && task.reminders && task.reminders[0]) {
      if (isNextWorkflow) {
        editNextWorkflowReminder(
          task.reminders[0].id,
          immediate ? null : futureReminderNumber,
          futureReminderType,
          immediate ? null : futureReminderTime,
          this.callback
        );
      } else {
        editReminder(
          workflow.id,
          task.id,
          task.reminders[0].id,
          immediate ? null : futureReminderNumber,
          futureReminderType,
          immediate ? null : futureReminderTime,
          this.callback
        );
      }
    } else if (isNextWorkflow) {
      addNextWorkflowReminder(
        immediate ? null : futureReminderNumber,
        futureReminderType,
        immediate ? null : futureReminderTime,
        this.callback
      );
    } else {
      addReminderToWorkflow(
        workflow.id,
        task.id,
        futureReminderNumber,
        futureReminderType,
        futureReminderTime,
        this.callback,
        reminderTypeSelection
      );
    }
  };

  removeReminders = () => {
    const {
      addingTask,
      editTaskState,
      removeReminderFromWorkflow,
      task,
      workflow,
      isNextWorkflow,
      removeNextWorkflowReminder,
    } = this.props;
    const { reminderTypeSelection } = this.state;

    this.setState({ loading: true });
    if (addingTask) {
      editTaskState("reminders", [], "edit");
      this.callback(true);
    } else if (isNextWorkflow) {
      removeNextWorkflowReminder(task.reminders[0].id, this.callback);
    } else {
      removeReminderFromWorkflow(
        workflow.id,
        task.id,
        task.reminders?.[0]?.id,
        this.callback,
        reminderTypeSelection
      );
    }
  };

  taskHasSequentialReminderSet = () => {
    const { task } = this.props;
    return task.options?.sequentialReminder?.type;
  };

  taskHasFutureReminder = () => {
    const { task } = this.props;
    return task.reminders.length > 0;
  };

  renderTrigger() {
    const { task, toggleTaskFutureReminderPopup } = this.props;

    if (this.taskHasFutureReminder() || this.taskHasSequentialReminderSet()) {
      return (
        <Icon.Group
          onClick={() => {
            toggleTaskFutureReminderPopup(task.id, true);
          }}
          title="This task has a reminder saved"
        >
          <Icon
            color="grey"
            link
            name="bell"
            title="This task has a reminder saved"
          />
          {this.taskHasSequentialReminderSet() && (
            <Icon
              link
              corner
              name="clock"
              style={{ color: "#aaaaaa", textShadow: "none" }}
            />
          )}
        </Icon.Group>
      );
    } else {
      const isOpen = this.isOpen();
      return (
        <Icon.Group
          className={!isOpen && "show_on_hover dark_grey_on_hover"}
          onClick={() => {
            toggleTaskFutureReminderPopup(task.id, true);
          }}
          style={{ fontSize: "13pt" }}
          title="Reminder"
        >
          <Icon
            link
            name="bell outline"
            style={isOpen ? { color: "#aaaaaa", margin: 0 } : { margin: 0 }}
          />
          <Icon
            link
            corner
            name="add"
            style={
              isOpen
                ? { color: "#aaaaaa" }
                : { color: "#aaaaaa", textShadow: "none" }
            }
          />
        </Icon.Group>
      );
    }
  }

  render() {
    const { task, toggleTaskFutureReminderPopup, popupToLeft } = this.props;
    const reminderExists =
      task?.reminders?.[0] || task.options?.sequentialReminder?.type;
    const isSequentialReminder = Boolean(
      task.options?.sequentialReminder?.type
    );

    const {
      futureReminderNumber,
      futureReminderType,
      loading,
      futureReminderTime,
      futureReminderDelay,
      reminderTypeSelection,
    } = this.state;

    let dayString = "day";
    let weekString = "week";
    let hourString = "hour";
    let monthString = "month";

    if (futureReminderNumber > 1) {
      dayString = "days";
      weekString = "weeks";
      hourString = "hours";
      monthString = "months";
    }

    const dropdownOptions = [
      {
        key: "hour",
        text: hourString,
        value: "hour",
        content: hourString,
      },
      {
        key: "day",
        text: dayString,
        value: "day",
        content: dayString,
      },
      {
        key: "week",
        text: weekString,
        value: "week",
        content: weekString,
      },
      {
        key: "month",
        text: monthString,
        value: "month",
        content: monthString,
      },
    ];

    const delayDropdownOptions = [
      {
        key: "immediate",
        text: "immediately",
        value: "immediate",
        content: "immediately",
      },
      {
        key: "delayed",
        text: "after",
        value: "delayed",
        content: "after",
      },
    ];

    if (!this.isOpen()) {
      return this.renderTrigger();
    } else {
      return (
        <Popup
          on="click"
          open
          offset={popupToLeft && "13px"}
          onClose={() => {
            toggleTaskFutureReminderPopup();
          }}
          onOpen={() => {
            toggleTaskFutureReminderPopup(task.id, true);
          }}
          popperModifiers={{
            preventOverflow: { boundariesElement: "window", enabled: false },
          }}
          position={popupToLeft ? "top right" : "bottom center"}
          trigger={this.renderTrigger()}
        >
          <div style={{ width: "max-content" }}>
            <div style={{ display: "flex" }}>
              <Header as="h4" style={{ marginBottom: "1em" }}>
                {reminderExists ? "Edit Reminder" : "Add Reminder"}
              </Header>
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: "1em",
                }}
              >
                <Select
                  data-test="reminder-settings-type-selector"
                  name="reminderTypeSelection"
                  options={[
                    {
                      key: "futureReminder",
                      text: "When a client is added to this workflow",
                      value: "futureReminder",
                    },
                    {
                      key: "sequential",
                      text: "When the preceding task is completed",
                      value: "sequential",
                    },
                  ]}
                  defaultValue={reminderTypeSelection}
                  onChange={this.handleChange}
                  disabled={loading || reminderExists || task?.order === 1}
                  style={{ marginRight: "0.5em", minWidth: "120px" }}
                />
                set reminder
                <Select
                  name="futureReminderDelay"
                  compact
                  options={delayDropdownOptions}
                  defaultValue={futureReminderDelay}
                  onChange={this.handleChange}
                  style={{
                    marginLeft: "0.5em",
                    marginRight: "0.5em",
                    minWidth: "120px",
                  }}
                  disabled={loading || isSequentialReminder}
                />
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                {futureReminderDelay === "delayed" && (
                  <div style={{ marginBottom: "1em" }}>
                    <Input
                      name="futureReminderNumber"
                      type="number"
                      onChange={this.handleChange}
                      defaultValue={futureReminderNumber}
                      style={{ width: "5em", marginRight: "0.5em" }}
                      min="1"
                      disabled={loading || isSequentialReminder}
                    />
                    <Select
                      name="futureReminderType"
                      compact
                      options={dropdownOptions}
                      defaultValue={futureReminderType}
                      onChange={this.handleChange}
                      style={{ marginRight: "0.5em" }}
                      disabled={loading || isSequentialReminder}
                    />
                  </div>
                )}
                <div style={{ marginBottom: "1em" }}>
                  {futureReminderDelay === "delayed" &&
                    futureReminderType !== "hour" && (
                      <React.Fragment>
                        <strong>at&nbsp;</strong>
                        <DatePicker
                          name="futureReminderTime"
                          selected={futureReminderTime}
                          onChange={this.handleTimeChange}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          dateFormat="h:mm aa"
                          timeCaption="Time"
                          customInput={<Input style={{ maxWidth: "7em" }} />}
                          disabled={loading || isSequentialReminder}
                        />
                      </React.Fragment>
                    )}
                </div>
              </div>
            </div>
            <div>
              {reminderExists && (
                <Button
                  negative
                  onClick={this.removeReminders}
                  style={{ marginRight: "0.5em", marginTop: "0.5em" }}
                  disabled={loading}
                  floated="left"
                >
                  Remove
                </Button>
              )}
              {!isSequentialReminder && (
                <Button
                  data-test="reminder-settings-button-save"
                  color="green"
                  disabled={loading}
                  floated="right"
                  loading={loading}
                  onClick={() => {
                    this.addReminder();
                  }}
                  style={{ marginTop: "0.5em" }}
                >
                  {reminderExists ? "Save" : "Add"}
                </Button>
              )}
            </div>
          </div>
        </Popup>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    isTaskFutureReminderOpen: state.hubly.task.isTaskFutureReminderOpen,
    modalTaskId: state.hubly.task.modalTaskId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addReminderToWorkflow: (
      workflowId,
      taskId,
      futureReminderNumber,
      futureReminderType,
      futureReminderTime,
      callback,
      reminderTypeSelection
    ) => {
      const reminderActionBySettings = () => {
        return reminderTypeSelection === "sequential"
          ? AddSequentialReminderToWorkflow
          : AddReminderToWorkflow;
      };

      dispatch(
        reminderActionBySettings()(
          workflowId,
          taskId,
          futureReminderNumber,
          futureReminderType,
          futureReminderTime,
          callback
        )
      );
    },
    editReminder: (
      workflowId,
      taskId,
      reminderId,
      futureReminderNumber,
      futureReminderType,
      futureReminderTime,
      callback
    ) => {
      dispatch(
        EditWorkflowReminder(
          workflowId,
          taskId,
          reminderId,
          futureReminderNumber,
          futureReminderType,
          futureReminderTime,
          callback
        )
      );
    },
    removeReminderFromWorkflow: (
      workflowId,
      taskId,
      reminderId,
      callback,
      reminderTypeSelection
    ) => {
      const reminderActionBySettings = () => {
        return reminderTypeSelection === "sequential"
          ? DeleteWorkflowSequentialReminder
          : DeleteWorkflowReminder;
      };

      dispatch(
        reminderActionBySettings()(workflowId, taskId, reminderId, callback)
      );
    },
    setAlert: (alert) => {
      dispatch(SetAlert(alert));
    },
    toggleTaskFutureReminderPopup: (taskId, isOpen) => {
      dispatch(ToggleTaskFutureReminderPopup(taskId, isOpen));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskFutureReminder);
