import { useContext } from "react";

import { HubContext } from "scenes/Hubly/components/Workspace/Provider";

export function useFilterProcessor() {
  const { advisorId: a } = useContext(HubContext);

  const toFilter = (key) => {
    if (key === "SHOW_ALL") return { assignee: null, includeUnassigned: true };
    if (key === "CURRENT_USER") return { assignee: a, includeUnassigned: true };
    return { assignee: key, includeUnassigned: false };
  };

  const fromFilter = ({ assignee, includeUnassigned }) => {
    if (!assignee) return "SHOW_ALL";
    if (assignee === a && includeUnassigned) return "CURRENT_USER";
    return assignee;
  };

  return { toFilter, fromFilter };
}

export default useFilterProcessor;
