import React from "react";

import { Grid, Header } from "semantic-ui-react";

import { usePolicies } from "./hooks";
import { PrivacyPolicyRow, PrivacyPolicyRowPlaceholder } from "./components";

export function PrivacyPolicy() {
  return (
    <div style={{ marginTop: "1em" }} data-test="privacy-policy-list">
      <div>
        <Header as="h1">Privacy Policy</Header>
      </div>
      <PrivacyPolicyList />
    </div>
  );
}

function PrivacyPolicyList() {
  const { data: policies, loading } = usePolicies();

  if (loading) {
    return <PrivacyPolicyRowPlaceholder />;
  }

  return (
    <Grid columns={2} style={{ marginTop: "1em" }}>
      <Grid.Row style={{ padding: ".25em 0" }}>
        <Grid.Column>
          <Header as="h3">Version</Header>
        </Grid.Column>
        <Grid.Column>
          <Header as="h3">Accepted On</Header>
        </Grid.Column>
      </Grid.Row>
      {policies.map((policy) => {
        return <PrivacyPolicyRow key={policy.id} {...policy} />;
      })}
    </Grid>
  );
}

export default PrivacyPolicy;
