import moment from "moment";

import { sortWorkflow } from "data/hub/workflows/actions";

import { wordsStartWith } from "components/MarkedText";

export const sortProcess = (a, b) => {
  const aSortOrder = a.process.sortOrder;
  const bSortOrder = b.process.sortOrder;
  if (aSortOrder > bSortOrder) {
    return 1;
  }
  if (aSortOrder < bSortOrder) {
    return -1;
  }
  return 0;
};

export const filterNameByKeyword = (workflowName, keyword) => {
  if (keyword === "") return true;
  return wordsStartWith(workflowName, keyword);
};

export const getCombinedWorkflows = (processes, workflows) => {
  const noProcessWorkflows = Object.values(workflows)
    .filter((workflow) => {
      return !workflow.process;
    })
    .sort(sortWorkflow);
  const combinedProcessWorkflows = processes.reduce(
    (combined, { processWorkflows }) => {
      return [...combined, ...processWorkflows];
    },
    []
  );
  return [...combinedProcessWorkflows, ...noProcessWorkflows];
};

export const getNumberOfClientFromWorkflow = (workflow) => {
  return workflow.clients.reduce((accumulator, currentValue) => {
    return !currentValue.isHidden ? accumulator + 1 : accumulator;
  }, 0);
};

export const isWorkflowHidden = (workflow, filters) => {
  const filteredWorkflows = filters.workflows || [];
  return !filteredWorkflows.some(({ id }) => {
    return id === workflow.id;
  });
};

export const getProcessWorkflowsAndTotalClient = (
  workflows,
  filters,
  processId
) => {
  const processWorkflows = [];
  let numClients = 0;
  let active = true;
  let empty = true;
  Object.values(workflows).forEach((workflow) => {
    if (workflow.process && workflow.process.id === processId) {
      empty = false;
      numClients += workflow.clients.length;
      processWorkflows.push({
        ...workflow,
      });

      if (
        !filters.workflows.some((filterWorkflow) => {
          return filterWorkflow.id === workflow.id;
        })
      ) {
        active = false;
      }
    }
  });
  return [processWorkflows, numClients, active && !empty];
};

export const isReminderQualifiedForCount = (
  reminder,
  workflows,
  clientWorkflows,
  processId
) => {
  if (!reminder || reminder.dismissed || reminder.completedAt) return false;
  if (clientWorkflows && workflows && reminder.clientWorkflowId) {
    const clientWf = clientWorkflows[reminder.clientWorkflowId];
    const workflow = workflows[clientWf?.workflowId];
    return (
      workflow?.process &&
      workflow?.process.id === processId &&
      !clientWf?.completed &&
      !clientWf?.archived
    );
  }
  return false;
};

export const getFilteredReminderCounts = (
  reminders,
  workflows,
  clientWorkflows,
  processId
) => {
  const now = new Date().toISOString();
  const initial = { totalOverdue: 0, totalComingDue: 0, totalUpcoming: 0 };
  if (!reminders) return initial;
  return reminders
    .filter((reminder) => {
      return isReminderQualifiedForCount(
        reminder,
        workflows,
        clientWorkflows,
        processId
      );
    })
    .reduce((total, reminder) => {
      let { totalOverdue, totalComingDue, totalUpcoming } = total;
      const duration = moment.duration(moment(reminder.time).diff());
      if (reminder.time < now && !reminder.dismissed && !reminder.completedAt) {
        totalOverdue += 1;
      } else if (
        duration.days() === 0 &&
        duration.hours() <= 12 &&
        duration > 0
      ) {
        totalComingDue += 1;
      } else if (duration.days() > 0 || duration.hours() > 12) {
        totalUpcoming += 1;
      }
      return { totalOverdue, totalComingDue, totalUpcoming };
    }, initial);
};

export const extractWF = (
  accumulatedProcessesWorkFlows,
  processWorkflows,
  searchInput
) => {
  if (
    !Array.isArray(accumulatedProcessesWorkFlows) &&
    !Array.isArray(processWorkflows)
  ) {
    return [];
  }

  const matchingProcessWorkflows = processWorkflows.filter(({ name }) => {
    return filterNameByKeyword(name, searchInput);
  });

  return accumulatedProcessesWorkFlows.concat(
    matchingProcessWorkflows.length > 0
      ? matchingProcessWorkflows
      : processWorkflows
  );
};

export default isWorkflowHidden;
