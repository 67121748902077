import React from "react";

import config from "config";

import { useFlags, useLDClient } from "launchdarkly-react-client-sdk";

export function useNewHublyApp() {
  const client = useLDClient();
  const flags = useFlags();
  const [clientIsReady, setReady] = React.useState(false);

  React.useEffect(() => {
    let mounted = true;

    if (client) {
      client.waitUntilReady().then(() => {
        if (mounted) setReady(true);
      });
    }

    return () => {
      mounted = false;
    };
  }, [client]);

  if (!clientIsReady) {
    return { loadingConfig: true };
  }

  return {
    loadingConfig: false,
    newApp: flags["new-hubly-app"] || Boolean(config?.newApp),
  };
}

export default useNewHublyApp;
