import { useCallback } from "react";

import { useQuery } from "hooks";

import toGlobalId from "scenes/Hubly/components/Workspace/api/helper";

export function useClientServiceTeam(clientId) {
  const queryFactory = useCallback(() => {
    const query = `query getClientServiceTeam {
      serviceRoles {
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            id
            name
            syncableName
            isSynced
            showMore
          }
        }
      }
      client(id: "${toGlobalId("Client", clientId)}") {
        serviceTeam {
          id
          name
          serviceTeamMembers {
            edges {
              node {
                id
                name
                advisor {
                  id
                  name
                }
                crmListUser {
                  id
                }
                serviceRole {
                  id
                  name
                  showMore
                  isSynced
                }
              }
            }
          }
        }
      }
      findServiceTeam(serviceTeamName: "Default Settings") {
        id
        name
        serviceTeamMembers {
          edges {
            node {
              name
              serviceRole {
                id
                name
              }
            }
          }
        }
      }
    }
    `;

    const resolver = ({ data }) => {
      const { serviceTeam } = data.client;

      return {
        serviceRoles: data.serviceRoles.edges.map(({ node }) => {
          return { ...node };
        }),
        serviceTeam: serviceTeam
          ? {
              ...serviceTeam,
              serviceTeamMembers: serviceTeam.serviceTeamMembers?.edges?.map(
                ({ node }) => {
                  return {
                    ...node,
                    serviceRole: node.serviceRole
                      ? { ...node.serviceRole }
                      : undefined,
                  };
                }
              ),
            }
          : undefined,
        defaultTeam: data.findServiceTeam
          ? {
              ...data.findServiceTeam,
              serviceTeamMembers:
                data.findServiceTeam.serviceTeamMembers?.edges?.map(
                  ({ node }) => {
                    return {
                      ...node,
                      serviceRole: node.serviceRole
                        ? { ...node.serviceRole }
                        : undefined,
                    };
                  }
                ),
            }
          : undefined,
      };
    };

    return { query, resolver };
  }, [clientId]);

  return useQuery(queryFactory);
}

export default useClientServiceTeam;
