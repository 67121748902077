import { useCallback, useMemo } from "react";

import { useQuery } from "hooks";

function useCheckAdvisorEmailExist(variables) {
  const queryFactory = useCallback(() => {
    const query = `query advisorEmail($advisorEmail: String!) {
      advisorEmailExist(advisorEmail: $advisorEmail)
    }`;

    const resolver = (resp) => {
      return resp;
    };

    return { query, resolver };
  }, []);

  const { loading, data, errors, reloadData } = useQuery(queryFactory, {
    variables: variables,
    options: { fetchOnInit: false, refetchOnVarChange: true },
  });

  const emailExists = useMemo(() => {
    return Boolean(data?.data?.advisorEmailExist);
  }, [data]);

  return {
    emailExists,
    loading,
    errors,
    reloadData,
  };
}

export default useCheckAdvisorEmailExist;
