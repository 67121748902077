import React from "react";
import PropTypes from "prop-types";

import WithScrollToLoad from "components/WithScrollToLoad";

export function WorkflowList({ children, ...props }) {
  return (
    <div
      style={{
        width: "100%",
        minHeight: "163px",
        maxHeight: "300px",
        overflowY: "scroll",
      }}
      {...props}
    >
      {children}
    </div>
  );
}

WorkflowList.propTypes = {
  children: PropTypes.node.isRequired,
};

export const WorkflowListWithScrollToLoad = WithScrollToLoad(WorkflowList);

export default WorkflowListWithScrollToLoad;
