import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Icon, Label } from "semantic-ui-react";

import { SetConfirmationModal } from "components/ConfirmationModal/actions";
import { MeetingDateTypeContext } from "components/MeetingDateTypeDropdown";
import PopupMenu from "components/PopupMenu";

import {
  RemoveWorkflowRule,
  SetEditWorkflowRule,
  UpdateWorkflowRule,
} from "../actions";
import { parseEditWorkflowClients, parseEditWorkflowTags } from "../helpers";
import ExcludeItemsLabels from "./ExcludeItemsLabels";

class ImportantClientDateRule extends React.Component {
  static propTypes = {
    removeWorkflowRule: PropTypes.func.isRequired,
    rule: PropTypes.object.isRequired,
    setConfirmationModal: PropTypes.func.isRequired,
    setEditWorkflowRule: PropTypes.func.isRequired,
    setWorkflowRuleModal: PropTypes.func.isRequired,
    tags: PropTypes.object.isRequired,
    workflow: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
    updateWorkflowRule: PropTypes.func.isRequired,
    hub: PropTypes.object.isRequired,
    allClients: PropTypes.func.isRequired,
  };

  state = {
    popupOpen: false,
  };

  confirmDeleteRule = () => {
    const { removeWorkflowRule, rule, setConfirmationModal, workflow } =
      this.props;
    const remove = () => {
      removeWorkflowRule(workflow, rule.id);
    };
    const params = {
      title: "Delete Rule",
      message: `Are you sure you wish to delete this rule and its configurations? This cannot be undone.`,
      icon: "delete",
      buttons: [
        {
          text: "Cancel",
        },
        {
          text: "Delete Rule",
          callBack: remove,
          color: "red",
        },
      ],
    };
    setConfirmationModal(params);
  };

  toggleActive = () => {
    const { rule, workflow, updateWorkflowRule } = this.props;
    updateWorkflowRule(
      workflow.id,
      { active: !rule.active, id: rule.id },
      () => {}
    );
  };

  openEditRuleModal = () => {
    const { rule, setEditWorkflowRule, setWorkflowRuleModal } = this.props;

    setEditWorkflowRule(rule);
    setWorkflowRuleModal(true, "important_date");
  };

  render() {
    const { popupOpen } = this.state;
    const { rule, hub, tags, allClients } = this.props;
    let text = "";
    let beforeOrAfter = "after";
    let { interval } = rule;
    if (rule.interval < 0) {
      beforeOrAfter = "before";
      interval = -interval;
    } else if (rule.interval === 0) {
      beforeOrAfter = "on";
    }

    const frequencyTypes = {
      daily: { text: "day", plural: "days" },
      weekly: { text: "week", plural: "weeks" },
      monthly: { text: "month", plural: "months" },
      yearly: { text: "year", plural: "years" },
    };
    const frequency = frequencyTypes[rule?.frequency?.toLowerCase()];
    if (interval >= 2) {
      text = ` will next be added ${interval} ${frequency?.plural} ${beforeOrAfter} their `;
    } else if (interval === 0) {
      text = ` will next be added on their `;
    } else {
      text = ` will next be added ${interval} ${frequency?.text} ${beforeOrAfter} their `;
    }

    const importantDate =
      rule.importantDate === "meeting"
        ? {
            icon: "calendar alternate",
            name: `${beforeOrAfter === "after" ? "Last" : "Next"} Meeting`,
          }
        : hub.dateTypes.find((dt) => {
            return dt.id === rule.dateTypeId;
          });
    return (
      <div
        className="grey_on_hover rounded p25"
        style={{ display: "flex", marginBottom: "0.5em", cursor: "pointer" }}
        data-test="automation-rule-item-importantDate"
        onDoubleClick={this.openEditRuleModal}
      >
        <div style={{ opacity: rule.active ? "1" : "0.4" }}>
          Clients with &nbsp;
          {rule.streams.length <= 0 && rule.tags.length <= 0 && (
            <span
              style={{ color: "grey" }}
              title="No stream(s) or tag(s) found. Please specify new stream(s) or tag(s) to resume rule."
            >
              N/A
            </span>
          )}
          {rule.streams.map((stream) => {
            return (
              <Label
                style={{
                  backgroundColor: stream.color,
                  color: "#fff",
                  margin: "0.1em",
                  padding: "0.3em 0.4em",
                }}
              >
                {stream.name}
              </Label>
            );
          })}
          {rule.tags.map((tag) => {
            return (
              <Label
                style={{
                  backgroundColor: "grey",
                  color: "#fff",
                  margin: "0.1em",
                  padding: "0.3em 0.4em",
                }}
              >
                <Icon fitted name="hashtag" />
                &nbsp;
                {this.props.tags[tag].name}
              </Label>
            );
          })}
          {text}
          <Label
            content={`${importantDate?.name}`}
            icon={importantDate?.icon}
            style={{
              backgroundColor: "#828282",
              color: "white",
              marginTop: "0.4em",
              padding: "0.3em 0.4em",
            }}
          />
          &nbsp;Date&nbsp;
          {rule?.meetingDateTypeId && (
            <MeetingDateTypeContext.Consumer>
              {({ getMeetingDateTypeFromId }) => {
                const meetingDateType = getMeetingDateTypeFromId(
                  rule?.meetingDateTypeId
                );
                return (
                  <React.Fragment>
                    <span>of type&nbsp;</span>
                    <Label
                      content={meetingDateType?.name}
                      style={{ marginTop: "0.4em", padding: "0.3em 0.4em" }}
                    />
                  </React.Fragment>
                );
              }}
            </MeetingDateTypeContext.Consumer>
          )}
          <span>&nbsp;</span>
          <ExcludeItemsLabels
            streamsExclude={rule?.streamsExclude}
            tagsExclude={parseEditWorkflowTags(rule?.tagsExclude, tags)}
            clientsExclude={parseEditWorkflowClients(
              rule?.clientsExclude,
              allClients
            )}
          />
          {!rule.active && " (Paused)"}
        </div>
        <div style={{ display: "flex", marginLeft: "0.25em" }}>
          <PopupMenu
            buttons={[
              {
                content: "Edit Rule",
                icon: "edit",
                onClick: () => {
                  this.openEditRuleModal();
                  this.setState({ popupOpen: false });
                },
              },
              {
                content: rule.active ? "Pause Rule" : "Resume Rule",
                icon: rule.active ? "pause" : "play",
                onClick: () => {
                  this.toggleActive();
                  this.setState({ popupOpen: false });
                },
              },
              {
                content: "Delete Rule",
                icon: "delete",
                onClick: () => {
                  this.confirmDeleteRule();
                  this.setState({ popupOpen: false });
                },
              },
            ]}
            onOpen={() => {
              this.setState({ popupOpen: true });
            }}
            onClose={() => {
              this.setState({ popupOpen: false });
            }}
            open={popupOpen}
            popperModifiers={{
              preventOverflow: { boundariesElement: "window", enabled: false },
            }}
            trigger={<Icon name="ellipsis horizontal" link color="grey" />}
            data-test="automation-rule-popupMenu"
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tags: state.hubly.data.hub.tags,
    hub: state.hubly.data.hub.selected.hub,
    allClients: state.hubly.data.hub.clients.allClientNames,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    removeWorkflowRule: (workflow, workflowRule, callback) => {
      dispatch(RemoveWorkflowRule(workflow, workflowRule, callback));
    },
    setConfirmationModal: (id) => {
      dispatch(SetConfirmationModal(id));
    },
    setEditWorkflowRule: (workflowRule) => {
      dispatch(SetEditWorkflowRule(workflowRule));
    },
    updateWorkflowRule: (workflow, workflowRule, callback) => {
      dispatch(UpdateWorkflowRule(workflow, workflowRule, callback));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImportantClientDateRule);
