import React from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {
  Button,
  Container,
  Header,
  Icon,
  Input,
  Popup,
} from "semantic-ui-react";

import "../../Workflow.css";

import { HideShowWorkflow } from "data/filters/actions";
import {
  SetClientWorkflow,
  sortClientWorkflow,
} from "data/hub/clientWorkflows/actions";
import { makeGetFilteredClientWorkflows } from "data/hub/clientWorkflows/selector";
import {
  RemoveWorkflow,
  SetWorkflow,
  SetWorkflowTitle,
} from "data/hub/workflows/actions";
import { BulkUpdateClientWorkflows } from "data/libs/clientWorkflows";
import { filterClientWorkflows } from "data/libs/filters";
import { EditWorkflow } from "data/libs/workflows";

import { SetAlert } from "components/Alerts/actions";
import AssignedAdvisorPopup from "components/AssignedAdvisorPopup";
import { SetConfirmationModal } from "components/ConfirmationModal/actions";
import DuplicateModal from "components/DuplicateModal";
import FeatureExperiment from "components/FeatureExperiment";

import { getConfirmModalButtons } from "./helpers/workflowClient";
import {
  UpdateClientWorkflowBulkOrder,
  UpdateClientWorkflowOrder,
} from "../../actions";
import ClientTile from "../ClientTile";
import AddClientDropdown from "../ClientTile/components/AddClientDropdown";
import NextWorkflows from "../NextWorkflows";
import WorkflowAttachments from "../WorkflowAttachments";
import WorkflowEditTasks from "../WorkflowEditTasks";
import WorkflowEmptyState from "../WorkflowEmptyState";
import WorkflowOptions from "../WorkflowOptions";
import WorkflowRules from "../WorkflowRules";

const NORMAL_STAGE = 0;
const SETTINGS_STAGE = 1;

class Workflow extends React.Component {
  popupRef = React.createRef();

  static defaultProps = {
    tileSettings: { showProgress: true, showIcons: true, numberOfTasks: 2 },
  };

  static propTypes = {
    activeClients: PropTypes.array.isRequired,
    advisor: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
    clientWorkflows: PropTypes.object.isRequired,
    selectedClientWorkflow: PropTypes.object.isRequired,
    createDuplicateWorkflow: PropTypes.func.isRequired,
    getNextReminderInfo: PropTypes.func.isRequired,
    filters: PropTypes.object.isRequired,
    hideShow: PropTypes.func.isRequired,
    hub: PropTypes.shape({
      id: PropTypes.string.isRequired,
      advisors: PropTypes.array.isRequired,
      practice: PropTypes.string,
      sortByLastName: PropTypes.bool.isRequired,
    }).isRequired,
    index: PropTypes.number.isRequired,
    openEditTileModal: PropTypes.func.isRequired,
    removeWorkflow: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired,
    setClientWorkflow: PropTypes.func.isRequired,
    setConfirmationModal: PropTypes.func.isRequired,
    setWorkflow: PropTypes.func.isRequired,
    setWorkflowTitle: PropTypes.func.isRequired,
    updateClientWorkflowOrder: PropTypes.func.isRequired,
    updateClientWorkflowBulkOrder: PropTypes.func.isRequired,
    tileSettings: PropTypes.object,
    workflow: PropTypes.shape({
      clients: PropTypes.array.isRequired,
      tasks: PropTypes.array.isRequired,
      name: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      isNewTemplate: PropTypes.bool,
      workflowRules: PropTypes.array.isRequired,
      process: PropTypes.object.isRequired,
      assignedAdvisorId: PropTypes.string.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    const { workflow } = this.props;

    this.state = {
      stage:
        workflow.name === "Untitled" &&
        !workflow.clients.length &&
        !workflow.tasks.length
          ? SETTINGS_STAGE
          : NORMAL_STAGE,
      titleInput: workflow.name,
      menuOpen: false,
      subMenuOpen: false,
      duplicateModalOpen: false,
      sortByNameAscInProgress: false,
      sortByNameDescInProgress: false,
      sortByDateNewestInProgress: false,
      sortByDateOldestInProgress: false,
      sortByNextMeetingInProgress: false,
      sortByRemindersInProgress: false,
    };
  }

  componentDidMount() {
    const { workflow } = this.props;
    const { stage } = this.state;
    if (stage === SETTINGS_STAGE && workflow.name === "Untitled") {
      if (this.workflowInput) {
        this.workflowInput.focus();
        this.workflowInput.select();
      }
    }
  }

  setStage = (stage) => {
    this.setState((state, props) => {
      return { stage: stage, menuOpen: false, subMenuOpen: false };
    });
  };

  getMaxOrderValue = (items) => {
    let max = 0;
    items.forEach((item) => {
      if (item.order && item.order > max) {
        max = item.order;
      }
    });
    return max;
  };

  saveTitleChange = () => {
    const { setAlert, workflow, setWorkflowTitle } = this.props;
    const { titleInput } = this.state;
    const val = titleInput;
    if (val === workflow.name) {
      return true;
    }

    if (val.length > 0) {
      setWorkflowTitle(val, workflow.id);

      return true;
    } else {
      setAlert({
        type: "warning",
        text: "Unable to save empty workflow name. Please verify workflow name and try again.",
      });
      return false;
    }
  };

  checkTitleChange = () => {
    if (this.saveTitleChange()) {
      this.setStage(NORMAL_STAGE);
    } else {
      this.workflowInput.focus();
    }
  };

  deleteWorkflow = (deletePermanently) => {
    const { workflow, removeWorkflow } = this.props;
    removeWorkflow(workflow.id, deletePermanently);
  };

  confirmDelete = () => {
    const { setConfirmationModal, workflow } = this.props;

    const params = getConfirmModalButtons({
      workflow: workflow,
      cbFunc: this.deleteWorkflow,
    });
    setConfirmationModal(params);
  };

  editTile = () => {
    const { openEditTileModal, workflow } = this.props;
    this.setState({ menuOpen: false });
    openEditTileModal(workflow);
  };

  hideWorkflow = () => {
    const { hideShow, workflow } = this.props;

    hideShow(workflow, true);
  };

  setIsNewTemplate = (isNewTemplate) => {
    const { setWorkflow, workflow } = this.props;
    if (workflow.isNewTemplate) {
      EditWorkflow(workflow.id, { isNewTemplate: isNewTemplate })
        .then((response) => {
          setWorkflow(response);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  sortClientWorkflows = (clientWorkflowIds) => {
    const { clientWorkflows } = this.props;
    return clientWorkflowIds
      .filter((clientWorkflowId) => {
        return clientWorkflowId in clientWorkflows;
      })
      .sort((a, b) => {
        return sortClientWorkflow(a, b, clientWorkflows);
      });
  };

  sortClientsReminder = () => {
    const { setAlert, workflow, updateClientWorkflowBulkOrder } = this.props;
    this.setState({ sortByRemindersInProgress: true });
    updateClientWorkflowBulkOrder(
      {
        workflowId: workflow.id,
        sortType: "REMINDERS",
      },
      (result) => {
        this.setState({ sortByRemindersInProgress: false });
        if (!result) {
          setAlert({
            type: "error",
            text: "Failed to sort by reminders.",
          });
        }
      }
    );
  };

  sortByMeetingDate = () => {
    const { setAlert, workflow, updateClientWorkflowBulkOrder } = this.props;
    this.setState({ sortByNextMeetingInProgress: true });
    updateClientWorkflowBulkOrder(
      {
        workflowId: workflow.id,
        sortType: "MEETING_DATE",
      },
      (result) => {
        this.setState({ sortByNextMeetingInProgress: false });
        if (!result) {
          setAlert({
            type: "error",
            text: "Failed to sort by meeting date.",
          });
        }
      }
    );
  };

  sortClientsDate = (option = "oldest") => {
    const { setAlert, workflow, updateClientWorkflowBulkOrder } = this.props;
    this.setState({
      [option === "oldest"
        ? "sortByDateOldestInProgress"
        : "sortByDateNewestInProgress"]: true,
    });
    updateClientWorkflowBulkOrder(
      {
        workflowId: workflow.id,
        sortType: "DATE_ADDED",
        extraParams: { option },
      },
      (result) => {
        this.setState({
          [option === "oldest"
            ? "sortByDateOldestInProgress"
            : "sortByDateNewestInProgress"]: false,
        });
        if (!result) {
          setAlert({
            type: "error",
            text: "Failed to sort by date added.",
          });
        }
      }
    );
  };

  sortByAlphabeticalOrder = (order = "asc") => {
    const { setAlert, workflow, updateClientWorkflowBulkOrder, hub } =
      this.props;
    const { sortByLastName = false } = hub;

    this.setState({
      [order === "asc"
        ? "sortByNameAscInProgress"
        : "sortByNameDescInProgress"]: true,
    });

    updateClientWorkflowBulkOrder(
      {
        workflowId: workflow.id,
        sortType: "ALPHABETICAL",
        extraParams: { sortByLastName: sortByLastName, order: order },
      },
      (result) => {
        this.setState({
          [order === "asc"
            ? "sortByNameAscInProgress"
            : "sortByNameDescInProgress"]: false,
        });
        if (!result) {
          setAlert({
            type: "error",
            text: "Failed to sort by alphabetical order.",
          });
        }
      }
    );
  };

  saveSortedClients = (workflowCopy) => {
    const { clientWorkflows, setClientWorkflow, setWorkflow } = this.props;

    const workflowRequest = {
      clientWorkflows: [],
    };

    const max = this.getMaxOrderValue(
      workflowCopy.clients.map((clientWorkflowId) => {
        return clientWorkflows[clientWorkflowId];
      })
    );

    workflowCopy.clients.forEach((clientWorkflowId, i) => {
      const order = max + i + 1;
      const clientWorkflow = clientWorkflows[clientWorkflowId];
      clientWorkflow.order = order;
      setClientWorkflow(clientWorkflow);
      workflowRequest.clientWorkflows.push({
        id: clientWorkflowId,
        order: max + i + 1,
      });
    });

    setWorkflow(workflowCopy);
    BulkUpdateClientWorkflows(workflowRequest);
  };

  handleEditWorkflow = () => {
    this.setIsNewTemplate(false);
    this.setStage(SETTINGS_STAGE);
  };

  moveClientTileToTop = (clientWorkflowId) => {
    if (!clientWorkflowId) return;
    const {
      workflow,
      clientWorkflows,
      setWorkflow,
      setAlert,
      updateClientWorkflowOrder,
    } = this.props;

    const workflowCopy = { ...workflow };
    const clientWorkflowBeforeSorting = workflowCopy.clients;
    // Move clientWorkflowId to the top
    const sortClientWorkflows = workflowCopy.clients
      .filter((cwId) => {
        return cwId in clientWorkflows;
      })
      .sort((a, b) => {
        if (a === clientWorkflowId) return -1;
        if (b === clientWorkflowId) return 1;
        return 0;
      });
    workflowCopy.clients = sortClientWorkflows;
    setWorkflow(workflowCopy);
    updateClientWorkflowOrder(
      {
        targetIndex: 0,
        workflowList: sortClientWorkflows,
        workflowId: workflow.id,
      },
      (result) => {
        if (!result) {
          setAlert({
            type: "error",
            text: "Failed to move to the top.",
          });
          // Revert the sorting on failure
          workflowCopy.clients = clientWorkflowBeforeSorting;
          setWorkflow(workflowCopy);
        }
      }
    );
    // this.saveSortedClients({ ...workflow, clients: [...clients] });
  };

  render() {
    const {
      advisor,
      hub,
      index,
      filters,
      workflow,
      selectedClientWorkflow,
      activeClients,
    } = this.props;
    const workflowName = workflow ? workflow.name : "New Workflow";
    const {
      menuOpen,
      subMenuOpen,
      stage,
      titleInput,
      duplicateModalOpen,
      sortByNameAscInProgress,
      sortByNameDescInProgress,
      sortByDateNewestInProgress,
      sortByDateOldestInProgress,
      sortByRemindersInProgress,
      sortByNextMeetingInProgress,
    } = this.state;

    const filteredClientWorkflows = filterClientWorkflows(
      workflow.clients,
      selectedClientWorkflow,
      activeClients,
      filters,
      advisor
    );

    const hideWorkflow =
      filters.filterEmptyWorkflows && !filteredClientWorkflows.length;
    if (hideWorkflow) {
      return <React.Fragment />;
    }
    return (
      <Draggable key={workflow.id} draggableId={workflow.id} index={index}>
        {(provided) => {
          return (
            <div
              data-test={`workflow-${workflow.name}`}
              className="ui segment workflow"
              id={workflow.id}
              ref={provided.innerRef}
              {...provided.draggableProps}
              style={{
                marginBottom: "0",
                maxHeight: "calc(100vh - 125px)",
                ...provided.draggableProps.style,
              }}
            >
              {/* --------------------------- normal workflow stage ---------------------------*/}
              <div
                className="workflow_transition"
                style={
                  stage === NORMAL_STAGE
                    ? { width: "252px", backgroundColor: "rgb(249, 249, 249)" }
                    : { width: "500px" }
                }
              >
                {duplicateModalOpen && (
                  <DuplicateModal
                    workflowId={workflow.id}
                    closeModal={() => {
                      this.setState({ duplicateModalOpen: false });
                    }}
                  />
                )}
                <Container
                  className="workflow_stage view_workflows"
                  style={{
                    backgroundColor: "rgb(249, 249, 249)",
                    display: stage === NORMAL_STAGE ? "flex" : "none",
                    flexDirection: "column",
                    maxHeight: "100%",
                    width: "252px",
                  }}
                >
                  <Container
                    className="workflow_name"
                    {...provided.dragHandleProps}
                    style={{ flexShrink: "0" }}
                    onDoubleClick={this.handleEditWorkflow}
                    data-test={`workflow-view-${workflowName}`}
                  >
                    <div
                      style={{ position: "relative", top: "7px", left: "-5px" }}
                      ref={this.popupRef}
                    />
                    <Popup
                      className="hubly_bars_menu"
                      context={this.popupRef}
                      on="click"
                      onClose={() => {
                        this.setState((state) => {
                          return { subMenuOpen: false };
                        });
                      }}
                      open={subMenuOpen}
                      popperModifiers={{
                        preventOverflow: {
                          boundariesElement: "window",
                          enabled: false,
                        },
                      }}
                      offset="10px 10px"
                      position="bottom right"
                      data-test="workflow-menu-sorting-popup"
                    >
                      <Button.Group
                        basic
                        vertical
                        labeled
                        icon
                        style={{ border: "none" }}
                      >
                        <Button
                          icon="long arrow alternate left"
                          content="Back to Main Menu"
                          onClick={() => {
                            this.setState((state) => {
                              return { subMenuOpen: false, menuOpen: true };
                            });
                          }}
                        />
                        <Button
                          icon="sort alphabet down"
                          disabled={sortByNameAscInProgress}
                          loading={sortByNameAscInProgress}
                          content="Name (Ascending)"
                          onClick={() => {
                            this.sortByAlphabeticalOrder("asc");
                          }}
                        />
                        <Button
                          icon="sort alphabet up"
                          disabled={sortByNameDescInProgress}
                          loading={sortByNameDescInProgress}
                          content="Name (Descending)"
                          onClick={() => {
                            this.sortByAlphabeticalOrder("desc");
                          }}
                        />
                        <Button
                          icon="bell"
                          content="Reminders"
                          disabled={sortByRemindersInProgress}
                          loading={sortByRemindersInProgress}
                          onClick={() => {
                            this.sortClientsReminder();
                          }}
                        />
                        <Button
                          icon="sort amount up"
                          disabled={sortByDateNewestInProgress}
                          loading={sortByDateNewestInProgress}
                          content="Date Added (Newest First)"
                          onClick={() => {
                            this.sortClientsDate("newest");
                          }}
                        />
                        <Button
                          icon="sort amount down"
                          disabled={sortByDateOldestInProgress}
                          loading={sortByDateOldestInProgress}
                          content="Date Added (Oldest First)"
                          onClick={() => {
                            this.sortClientsDate("oldest");
                          }}
                        />
                        <Button
                          icon="calendar alternate outline"
                          disabled={sortByNextMeetingInProgress}
                          loading={sortByNextMeetingInProgress}
                          content="Next Meeting Date"
                          onClick={() => {
                            this.sortByMeetingDate();
                          }}
                        />
                      </Button.Group>
                    </Popup>
                    <Popup
                      className="hubly_bars_menu"
                      hideOnScroll
                      on="click"
                      onClose={() => {
                        this.setState((state) => {
                          return { menuOpen: false };
                        });
                      }}
                      open={menuOpen}
                      popperModifiers={{
                        preventOverflow: {
                          boundariesElement: "window",
                          enabled: false,
                        },
                      }}
                      offset="10px"
                      position="bottom right"
                      data-test="workflow-menu-popup"
                      trigger={
                        <Icon
                          data-test={`workflow-menu-${workflowName}`}
                          link
                          name="ellipsis vertical"
                          color="grey"
                          style={{ float: "right", fontSize: "12pt" }}
                          onClick={() => {
                            this.setState((state) => {
                              return { menuOpen: true };
                            });
                          }}
                        />
                      }
                    >
                      <Button.Group
                        basic
                        vertical
                        labeled
                        icon
                        style={{ border: "none" }}
                      >
                        <Button
                          icon="edit"
                          content="Edit Workflow"
                          onClick={this.handleEditWorkflow}
                        />
                        <Button
                          icon="sort content ascending"
                          content="Sort By..."
                          onClick={() => {
                            this.setState((state) => {
                              return { subMenuOpen: true, menuOpen: false };
                            });
                          }}
                        />
                        <Button
                          icon="th"
                          content="Edit Tiles"
                          onClick={this.editTile}
                        />
                        <Button
                          icon="copy"
                          content="Duplicate Workflow"
                          onClick={() => {
                            this.setState({
                              menuOpen: false,
                              duplicateModalOpen: true,
                            });
                          }}
                        />
                        <Button
                          icon="hide"
                          content="Hide From View"
                          onClick={() => {
                            this.hideWorkflow();
                          }}
                        />
                        <FeatureExperiment
                          id="workflow-menu-delete-button"
                          contextId={hub.practice}
                          enabledOnMissing
                        >
                          <Button
                            icon="delete"
                            content="Delete Workflow"
                            onClick={() => {
                              this.confirmDelete();
                              this.setState({ menuOpen: false });
                            }}
                            className="red_background"
                          />
                        </FeatureExperiment>
                      </Button.Group>
                    </Popup>
                    {workflow.workflowRules &&
                      workflow.workflowRules.length > 0 && (
                        <Popup
                          content={
                            "This workflow has active rules applied to it." +
                            " View rules by selecting 'Edit Workflow' in the workflow header menu."
                          }
                          popperModifiers={{
                            preventOverflow: {
                              boundariesElement: "window",
                              enabled: false,
                            },
                          }}
                          position="right center"
                          wide
                          trigger={
                            <Icon
                              name="settings"
                              style={{
                                float: "right",
                                fontSize: "12pt",
                                margin: "2px 0 0px .25em",
                                transform: "scaleX(-1) translateY(-3px)",
                                color: "darkgrey",
                              }}
                            />
                          }
                        />
                      )}
                    {workflow.isNewTemplate && (
                      <Popup
                        content="This is a templated workflow. Personalize this workflow by selecting ‘Edit Workflow’ in the
                            overflow on the right side of the header."
                        position="right center"
                        wide
                        popperModifiers={{
                          preventOverflow: {
                            boundariesElement: "window",
                            enabled: false,
                          },
                        }}
                        trigger={
                          <Icon
                            style={{
                              float: "right",
                              fontSize: "12pt",
                              margin: "2px 0 0px .25em",
                            }}
                            size="tiny"
                            color="blue"
                            name="info circle"
                          />
                        }
                      />
                    )}
                    <div>
                      {workflow.process && (
                        <Popup
                          on={["hover"]}
                          position="top center"
                          popperModifiers={{
                            preventOverflow: {
                              boundariesElement: "window",
                              enabled: false,
                            },
                          }}
                          trigger={
                            <div
                              style={{
                                width: "6px",
                                height: "18px",
                                backgroundColor: workflow.process.color,
                                position: "absolute",
                                left: "0px",
                                transform: "translateY(3px)",
                              }}
                            />
                          }
                        >
                          {workflow.process.name}
                        </Popup>
                      )}
                      <Header
                        data-test={`workflow-view-title-${workflowName}`}
                        style={{ display: "inline" }}
                        as="h3"
                      >
                        {workflowName}
                      </Header>
                    </div>
                  </Container>
                  <div data-test={`workflow-add-clients-${workflowName}`}>
                    <AddClientDropdown
                      style={{
                        width: "100%",
                        alignItems: "center",
                        minHeight: "60px",
                      }}
                      workflowId={workflow.id}
                      toggleAddClient={this.toggleAddClient}
                    />
                  </div>

                  <Droppable
                    droppableId={`tiles_${workflow.id}`}
                    direction="vertical"
                    type="TILES"
                  >
                    {(p) => {
                      return (
                        <div
                          className="workflow_content"
                          style={{
                            padding: "0em 0.8em 0.8em 0.8em",
                            overflowX: "hidden",
                            overflowY: "overlay",
                            backgroundColor: "#f9f9f9",
                          }}
                          data-test={`workflow-droppable-${workflowName}`}
                        >
                          <div
                            style={{ minHeight: "3em" }}
                            ref={p.innerRef}
                            {...p.droppableProps}
                          >
                            {filteredClientWorkflows.length === 0 && (
                              <WorkflowEmptyState />
                            )}
                            {filteredClientWorkflows.map(
                              (clientWorkflow, i) => {
                                return (
                                  <ClientTile
                                    key={`${clientWorkflow.id}_${workflowName}`}
                                    workflow={workflow}
                                    index={i}
                                    clientWorkflow={clientWorkflow}
                                    moveClientTileToTop={
                                      this.moveClientTileToTop
                                    }
                                  />
                                );
                              }
                            )}
                            <div
                              key={`spacer_${workflow.id}`}
                              style={{
                                clear: "both",
                                width: "100%",
                                height: "1em",
                              }}
                              ref={(element) => {
                                this.addClientTileEnd = element;
                              }}
                            />
                            {p.placeholder}
                          </div>
                        </div>
                      );
                    }}
                  </Droppable>
                </Container>

                {/* --------------------------- Settings stage ---------------------------*/}
                <Container
                  className="workflow_stage edit_workflow"
                  style={{
                    maxHeight: "100%",
                    overflow: "hidden",
                    display: stage === SETTINGS_STAGE ? "flex" : "none",
                    flexDirection: "column",
                    width: "500px",
                  }}
                >
                  <Container
                    className="workflow_name"
                    style={{ display: "flex", flexDirection: "row" }}
                    {...provided.dragHandleProps}
                  >
                    {hub.advisors.length >= 2 && (
                      <div style={{ margin: "0.25em 0.25em 0 0" }}>
                        <AssignedAdvisorPopup
                          currentObject={workflow}
                          currentObjectType="WORKFLOW"
                          iconProperties={{
                            addIconSize: "35px",
                            circleFontSize: "18px",
                            circleSize: "30px",
                          }}
                        />
                      </div>
                    )}
                    <div style={{ flexGrow: 1 }}>
                      <Input
                        placeholder="Name of Workflow"
                        floated="left"
                        size="large"
                        fluid
                        value={titleInput}
                        onChange={(e) => {
                          this.setState({ titleInput: e.target.value });
                        }}
                        onKeyUp={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                            this.saveTitleChange();
                          }
                        }}
                        ref={(input) => {
                          this.workflowInput = input;
                        }}
                      />
                    </div>
                  </Container>
                  <div
                    className="workflow_content"
                    style={{ overflowY: "auto" }}
                  >
                    <Container className="configure_tasks">
                      <Header as="h5">Default Tasks</Header>
                      <WorkflowEditTasks
                        workflowId={workflow.id}
                        key={workflow.id}
                      />
                    </Container>
                    {stage === SETTINGS_STAGE && (
                      <Container style={{ marginTop: "1em" }}>
                        <Header as="h5" style={{ marginBottom: "0.5em" }}>
                          Automation Rules
                        </Header>
                        <WorkflowRules
                          workflowId={workflow.id}
                          key={`${workflow.id}_next`}
                        />
                      </Container>
                    )}
                    <Container
                      className="next_workflows"
                      style={{ marginTop: "1em" }}
                    >
                      <Header as="h5" style={{ marginBottom: "0.5em" }}>
                        Next Workflows
                      </Header>
                      <NextWorkflows
                        workflowId={workflow.id}
                        key={`${workflow.id}_next`}
                      />
                    </Container>
                    <Container style={{ marginTop: "1em" }}>
                      <Header as="h5" style={{ marginBottom: "0.5em" }}>
                        Attachments/Templates
                      </Header>
                      <WorkflowAttachments
                        workflowId={workflow.id}
                        key={`${workflow.id}_next`}
                      />
                    </Container>
                    <Container style={{ marginTop: "1em" }}>
                      <Header as="h5" style={{ marginBottom: "0.5em" }}>
                        Options
                      </Header>
                      <WorkflowOptions
                        workflowId={workflow.id}
                        key={workflow.id}
                      />
                    </Container>
                  </div>
                  <Container
                    style={{
                      borderTop: "1px #e0e1e2 solid",
                      paddingTop: "0.5em",
                      paddingBottom: "0.5em",
                      minHeight: "4em",
                      height: "3em",
                      margin: "0.5em 0 0.5em 0",
                      textAlign: "center",
                      backgroundColor: "white",
                      display: "flex",
                      justifyContent: "space-around",
                    }}
                  >
                    <Button
                      data-test="workflow-edit-button-delete"
                      className="ui basic button negative"
                      style={{ width: "45%" }}
                      title="Delete"
                      onClick={() => {
                        this.confirmDelete();
                        this.setState({ menuOpen: false });
                      }}
                    >
                      Delete
                    </Button>
                    <Button
                      data-test="workflow-edit-button-done"
                      className="ui basic button positive"
                      style={{ width: "45%" }}
                      title="Done"
                      onClick={this.checkTitleChange}
                    >
                      Done
                    </Button>
                  </Container>
                </Container>
              </div>
            </div>
          );
        }}
      </Draggable>
    );
  }
}

const makeMapStateToProps = () => {
  const clientWorkflowSelector = makeGetFilteredClientWorkflows();
  return (state, ownProps) => {
    return {
      activeClients: state.hubly.data.hub.clients.activeClients,
      advisor: state.hubly.data.advisor,
      clientWorkflows: state.hubly.data.hub.clientWorkflows,
      selectedClientWorkflow: clientWorkflowSelector(state, ownProps),
      hub: state.hubly.data.hub.selected.hub,
      filters: state.hubly.data.filters,
      workflow: state.hubly.data.hub.workflows[ownProps.workflowId],
    };
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateClientWorkflowOrder: (props, callback) => {
      dispatch(UpdateClientWorkflowOrder(props, callback));
    },
    updateClientWorkflowBulkOrder: (props, callback) => {
      dispatch(UpdateClientWorkflowBulkOrder(props, callback));
    },
    hideShow: (workflow, hidden) => {
      dispatch(HideShowWorkflow(workflow, hidden));
    },
    removeWorkflow: (id, deleteClients) => {
      dispatch(RemoveWorkflow(id, deleteClients));
    },
    setAlert: (alert) => {
      dispatch(SetAlert(alert));
    },
    setClientWorkflow: (workflow) => {
      dispatch(SetClientWorkflow(workflow));
    },
    setConfirmationModal: (params) => {
      dispatch(SetConfirmationModal(params));
    },
    setWorkflow: (workflow) => {
      dispatch(SetWorkflow(workflow));
    },
    setWorkflowTitle: (title, id) => {
      dispatch(SetWorkflowTitle(title, id));
    },
  };
};

export default connect(makeMapStateToProps, mapDispatchToProps)(Workflow);
